import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, Form, FormInstance, Input, message } from 'antd';
import axios from 'axios';
import classnames from 'classnames';

import { isEmailValidator } from '../../../validators/isEmailValidator';

import styles from './LoginForm.module.css';

export type LoginFormValues = {
  email: string;
  password: string;
  ipToken?: string;
};

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation(['error', 'form']);
  const formRef = useRef<FormInstance<LoginFormValues>>(null);
  const [isLoading, setLoading] = useState(false);
  const [mustConfirmIp, setMustConfirmIp] = useState(false);
  const { resetStore } = useApolloClient();

  const handleFormSubmit = async (values: LoginFormValues) => {
    if (!formRef.current) return;

    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_HTTP_ENDPOINT}/v1/authentication/login`,
        {
          email: values.email.trim().toLowerCase(),
          password: values.password,
          ipToken: values.ipToken,
        },
        {
          withCredentials: true,
        },
      );

      await resetStore();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.data.code) {
          case 'auth/ip-confirmation-missing':
            setMustConfirmIp(true);
            message.info(t('error:auth/ip-confirmation-missing'));
            break;
          default:
            message.error(t('error:default'));
            break;
        }
      } else {
        message.error(t('error:default'));
      }
      setLoading(false);
    }
  };

  return (
    <div className={classnames(styles.root, className)}>
      <Form
        ref={formRef}
        onFinish={handleFormSubmit}
        name="login-form"
        layout="vertical"
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          label={t('form:email.label')}
          rules={[
            {
              required: true,
              message: t('form:email.rules.required'),
            },
            {
              validator: isEmailValidator,
              message: t('form:email.rules.valid'),
            },
          ]}
        >
          <Input
            type="email"
            disabled={mustConfirmIp}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t('form:email.placeholder')}
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('form:password.label')}
          rules={[
            { required: true, message: t('form:password.rules.required') },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            disabled={mustConfirmIp}
            autoComplete="current-password"
            placeholder={t('form:password.placeholder')}
          />
        </Form.Item>
        {mustConfirmIp && (
          <Form.Item
            name="ipToken"
            label={t('form:ipToken.label')}
            rules={[
              { required: true, message: t('form:ipToken.rules.required') },
            ]}
          >
            <Input placeholder={t('form:ipToken.placeholder')} />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            className={styles.submit}
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            {t('form:login.submit')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export type LoginFormProps = {
  className?: string;
};
