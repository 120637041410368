import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './BooleanTag.module.css';

export const BooleanTag: React.FC<BooleanTagProps> = (props) => {
  const { className, value } = props;
  const { t } = useTranslation('common');

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={value ? 'green' : 'red'}
    >
      {value ? t('BooleanTag.true') : t('BooleanTag.false')}
    </Tag>
  );
};

export type BooleanTagProps = {
  className?: string;
  value: boolean;
};
