import { SplashView } from '../../SplashView/SplashView';

import { LoginForm } from '@forms/LoginForm/LoginForm';

import styles from './AuthLoginView.module.css';

export const AuthLoginView: React.FC = () => (
  <SplashView className={styles.root}>
    <LoginForm />
  </SplashView>
);
