import { MangopayPayOutsTable } from '@components/tables/MangopayPayOutsTable/MangopayPayOutsTable';

export const PayOutsView: React.FC = () => {
  return (
    <MangopayPayOutsTable
      activeColumns={[
        'id',
        'updatedAt',
        'mangopayId',
        'amount',
        'status',
        'resultMessage',
        'link',
      ]}
    />
  );
};
