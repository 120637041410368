import IFrameResizer from 'iframe-resizer-react';

type MetabaseEmbedProps = {
  token: string;
};

export function MetabaseEmbed(props: MetabaseEmbedProps) {
  const { token } = props;

  return (
    <IFrameResizer
      src={`https://metabase.happypal.fr/embed/dashboard/${token}`}
      frameBorder="0"
      title="metabase embed"
      allowTransparency
      widthCalculationMethod="taggedElement"
      style={{ width: '1px', minWidth: '100%' }}
    />
  );
}
