import { Checkbox } from 'antd';

import { CampaignStatus } from '@graphql/generated/types';

import {
  useCampaignPromoteCheckboxDemoteCampaignMutation,
  useCampaignPromoteCheckboxPromoteCampaignMutation,
  useCampaignPromoteCheckboxQuery,
} from './CampaignPromoteCheckbox.generated';

type Props = {
  campaignId: string;
};

const CampaignPromoteCheckbox = ({ campaignId }: Props) => {
  const { data } = useCampaignPromoteCheckboxQuery({
    variables: { id: campaignId },
  });

  const [promoteCampaign] = useCampaignPromoteCheckboxPromoteCampaignMutation({
    variables: { campaignId },
    optimisticResponse: {
      __typename: 'Mutation',
      campaignPromote: {
        __typename: 'CampaignPromoteOutput',
        campaign: {
          __typename: 'Campaign',
          id: campaignId,
          isHighlighted: true,
        },
      },
    },
  });

  const [demoteCampaign] = useCampaignPromoteCheckboxDemoteCampaignMutation({
    variables: { campaignId },
    optimisticResponse: {
      __typename: 'Mutation',
      campaignDemote: {
        __typename: 'CampaignDemoteOutput',
        campaign: {
          __typename: 'Campaign',
          id: campaignId,
          isHighlighted: false,
        },
      },
    },
  });

  return (
    <div>
      <Checkbox
        checked={data?.campaign?.isHighlighted || false}
        onChange={() =>
          data?.campaign?.isHighlighted ? demoteCampaign() : promoteCampaign()
        }
        disabled={data?.campaign?.status !== CampaignStatus.Published}
      >
        À la une
      </Checkbox>
    </div>
  );
};

export default CampaignPromoteCheckbox;
