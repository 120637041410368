import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { CompanyFeesCollectionMethod } from '@graphql/generated/types';

import styles from './FormSelectCompanyFeesCollectionMethod.module.css';

export const FormSelectCompanyFeesCollectionMethod: React.FC<FormSelectCompanyFeesCollectionMethodProps> =
  (props) => {
    const { className, name } = props;
    const { t } = useTranslation('companyFees');

    const options: { label: string; value: CompanyFeesCollectionMethod }[] = [
      {
        value: CompanyFeesCollectionMethod.Company,
        label: t('collectionMethod.company'),
      },
      {
        value: CompanyFeesCollectionMethod.User,
        label: t('collectionMethod.user'),
      },
    ];

    return (
      <FormSelect
        name={name}
        className={classnames(className, styles.root)}
        options={options}
      />
    );
  };

export type FormSelectCompanyFeesCollectionMethodProps = {
  name: string;
  className?: string;
};
