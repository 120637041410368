import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Image, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';

import { Typography } from '../Typography/Typography';

import { FileType } from '@graphql/generated/types';

import {
  convertFileToPayload,
  postFormData,
} from '@utils/extractImageInformations/extractImageInformations';

import styles from './FormPictureCrop.module.css';

import { useFormPictureCropCreateMutation } from './FormPictureCrop.generated';

const MAX_UPLOADED_PICTURES = 1; // This component currently handle only one picture

export function FormPictureCrop(props: FormPictureCropProps) {
  const { aspect, name, info } = props;

  const { field } = useController({ name });
  const { t } = useTranslation('picturesStock');

  const [mutation] = useFormPictureCropCreateMutation();
  const [fileList, setFileList] = useState<(RcFile & { thumbUrl?: string })[]>(
    [],
  );
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const handleBeforeUpload = (file: RcFile) => {
    setFileList([file]);
    return false;
  };

  const handleUpload: ImgCropProps['onUploadFail'] = async () => {
    const file = fileList[0];

    if (file) {
      const variables = await convertFileToPayload(file, props.type);
      const { data } = await mutation({ variables });
      await postFormData(file, data!.pictureCreate);
      field.onChange(data!.pictureCreate.picture.id);

      file.thumbUrl = data?.pictureCreate.picture.file.url;

      setFileList([file]);
    }
  };

  const handleChange: UploadProps['onChange'] = ({ file }) => {
    // If trash button is clicked
    if (file.status === 'removed') {
      field.onChange(undefined);
      file.thumbUrl = undefined;
      setFileList([]);
    }
  };

  return (
    <>
      <ImgCrop
        aspect={aspect}
        modalTitle={t('modal.title', "Editer l'image")}
        onUploadFail={handleUpload}
      >
        <Upload
          beforeUpload={handleBeforeUpload}
          onPreview={() => setPreviewVisible(true)}
          className={styles.upload}
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
        >
          {fileList.length < MAX_UPLOADED_PICTURES &&
            `+ ${t('picture.upload', 'Importer')}`}
        </Upload>
      </ImgCrop>
      <Image
        style={{ display: 'none' }}
        src={fileList?.[0]?.thumbUrl}
        preview={{
          visible: isPreviewVisible,
          src: fileList?.[0]?.thumbUrl,
          onVisibleChange: (value) => {
            setPreviewVisible(value);
          },
        }}
      />
      <Typography.Text type="secondary">{info}</Typography.Text>
    </>
  );
}

export type FormPictureCropProps = {
  name: string;
  type: FileType;
  info?: string;
  className?: string;
  aspect?: number;
};
