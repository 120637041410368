import { useTranslation } from 'react-i18next';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductCatalogsTableQueryVariables,
  ProductCatalogsTableRecordFragment,
  useProductCatalogsTableQuery,
} from './ProductCatalogsTable.generated';

type RecordType = ProductCatalogsTableRecordFragment;

type Column =
  | 'name'
  | 'identifier'
  | 'provider'
  | 'synchronizationCount'
  | 'productCount';

export function ProductCatalogsTable(props: ProductCatalogsTableProps) {
  const { className, filter, ...rest } = props;

  const { t } = useTranslation('productCatalogs');

  const query = useProductCatalogsTableQuery({
    variables: {
      skip: 0,
      take: 10,
      filter,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/products/catalogs/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'identifier',
      title: t('table.headers.identifier'),
      dataIndex: ['identifier'],
    },
    {
      key: 'provider',
      title: t('table.headers.provider'),
      dataIndex: ['provider', 'name'],
      render: (name, node) => (
        <I18nLink to={`/products/providers/list/${node.provider.id}`}>
          {name}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'provider.name', 'search'),
    },
    {
      key: 'synchronizationCount',
      title: t('table.headers.synchronizationCount'),
      dataIndex: ['synchronizationsPagination', 'totalCount'],
    },
    {
      key: 'productCount',
      title: t('table.headers.productCount'),
      dataIndex: ['productPagination', 'totalCount'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductCatalogsTable"
        query={query}
        columns={columns}
        data={query.data?.productCatalogPagination}
        {...rest}
      />
    </div>
  );
}

export type ProductCatalogsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: ProductCatalogsTableQueryVariables['filter'];
};
