import { Card, Space } from 'antd';

import { OfferIdSubventionSchemaView } from '../OfferIdSubventionSchemaView/OfferIdSubventionSchemaView';
import { OfferIdVoucherView } from '../OfferIdVoucherView/OfferIdVoucherView';

import { OfferUpdateCategoriesForm } from '@components/forms/OfferUpdateCategoriesForm/OfferUpdateCategoriesForm';
import { OfferStatusUpdateButton } from '@components/organisms/OfferStatusUpdateButton/OfferStatusUpdateButton';

import { OfferIdSettingsViewFragment } from './OfferIdSettingsView.generated';

type Props = {
  offer: OfferIdSettingsViewFragment;
};

const OfferIdSettingsView = ({ offer }: Props) => {
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Publication">
        <OfferStatusUpdateButton offer={offer} />
      </Card>

      <Card title="Catégories">
        <OfferUpdateCategoriesForm offer={offer} />
      </Card>

      {!['OfferGiftCard', 'OfferTicket'].includes(offer.__typename) ? (
        <Card title="Réductions">
          <OfferIdVoucherView offerId={offer.id} />
        </Card>
      ) : (
        <Card title="Schémas de subventions">
          <OfferIdSubventionSchemaView offerId={offer.id} />
        </Card>
      )}
    </Space>
  );
};

export default OfferIdSettingsView;
