import { useTranslation } from 'react-i18next';
import { QueryResult } from '@apollo/client';
import isoLanguages from '@cospired/i18n-iso-languages';
import { Spin } from 'antd';

import { supportedLocales } from '../../../i18n';

import { Card } from '@components/atoms/Card/Card';

import {
  TranslationGeneratorRow,
  TranslationGeneratorRowProps,
} from './TranslationGeneratorRow';

export const TranslationGenerator = (props: TranslationGeneratorProps) => {
  const [_, { language }] = useTranslation();
  const { countQuery, useMissingQuery, useGeneratedQuery, generateMutation } =
    props;

  const locales = supportedLocales.map((lang) => ({
    code: lang,
    name: isoLanguages.getNames(language)[lang],
  }));

  if (countQuery.loading || !countQuery?.data) {
    return <Spin />;
  }

  const totalCount = countQuery.data.entities.totalCount;

  return (
    <Card>
      {locales.map((locale) => (
        <TranslationGeneratorRow
          locale={locale}
          key={locale.code}
          totalCount={totalCount}
          useMissingQuery={useMissingQuery}
          useGeneratedQuery={useGeneratedQuery}
          generateMutation={generateMutation}
        />
      ))}
    </Card>
  );
};

export type TranslationGeneratorProps = {
  countQuery: Pick<
    QueryResult<{
      entities: { totalCount: number };
    }>,
    'loading' | 'data' | 'error' | 'refetch'
  >;
  useMissingQuery: TranslationGeneratorRowProps['useMissingQuery'];
  useGeneratedQuery: TranslationGeneratorRowProps['useGeneratedQuery'];
  generateMutation: TranslationGeneratorRowProps['generateMutation'];
};
