import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { SearchSynchronizationType } from '@graphql/generated/types';

import styles from './FormSelectSearchSynchronizationType.module.css';

export const FormSelectSearchSynchronizationType: React.FC<FormSelectSearchSynchronizationTypeProps> =
  (props) => {
    const { className, name } = props;
    const { t } = useTranslation('searchSynchronizations');

    const options: { label: string; value: SearchSynchronizationType }[] = [
      { value: SearchSynchronizationType.Campaign, label: t('type.campaign') },
    ];

    return (
      <FormSelect
        name={name}
        className={classnames(className, styles.root)}
        options={options}
      />
    );
  };

export type FormSelectSearchSynchronizationTypeProps = {
  name: string;
  className?: string;
};
