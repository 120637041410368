import { useRef, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';

import { FileType } from '@graphql/generated/types';

import { MIME_TYPES } from '@utils/mimetypes';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { InputFileUpload } from '@components/atoms/InputFile/InputFileUpload';

import styles from './FormInputMultipleUpload.module.css';

const ACCEPTED_MIME_TYPES = [
  MIME_TYPES.PDF,
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.MOV,
];

export const FormInputMultipleUpload = <
  T extends FieldValues = Record<string, unknown>,
>({
  control,
  name,
  max,
  type,
}: FormInputMultipleUploadProps<T>) => {
  const [progress, setProgress] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const formattedProgress = Math.max(0, progress || 0);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value: attachments } }) => (
        <div>
          <ul className={styles.attachments}>
            {attachments?.map((v: AttachmentItemFragment, i: number) => (
              <li key={`attachment-${i}`} className={styles.attachmentItem}>
                <a href={v.url ? v.url : ''}>{v.originalName}</a>
                <Button
                  type="primary"
                  shape="round"
                  className={styles.delete}
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    onChange(
                      attachments.filter(
                        (deleteable: AttachmentItemFragment) =>
                          deleteable.id !== v.id,
                      ),
                    )
                  }
                />
              </li>
            ))}
          </ul>
          {max !== undefined && attachments?.length >= max ? null : (
            <>
              {progress !== null ? (
                <Progress percent={formattedProgress} />
              ) : (
                <Button className={styles.root} onClick={triggerFileSelector}>
                  + Ajouter un fichier
                </Button>
              )}

              <InputFileUpload
                ref={inputRef}
                multiple={true}
                accept={ACCEPTED_MIME_TYPES}
                disabled={max !== undefined && attachments?.length >= max}
                type={type}
                uploadOnChange
                onProgress={setProgress}
                onChange={(files) =>
                  onChange([
                    ...(attachments || []),
                    ...(files as AttachmentItemFragment[]),
                  ])
                }
              />
            </>
          )}
        </div>
      )}
    />
  );

  function triggerFileSelector() {
    if (!inputRef?.current) return;
    inputRef.current.click();
  }
};

export type FormInputMultipleUploadProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  max?: number;
  type: FileType;
};
