import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { CompanyCustomFieldType } from '@graphql/generated/types';

import { FormSelectCompanyCustomFieldType } from '@components/atoms/FormSelectCompanyCustomFieldType/FormSelectCompanyCustomFieldType';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormSelectLocaleProps } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyCustomFieldForm.module.css';

import {
  CompanyCustomFieldFormCreateMutation,
  CompanyCustomFieldFormCreateMutationVariables,
  CompanyCustomFieldFormDeleteMutation,
  CompanyCustomFieldFormDeleteMutationVariables,
  CompanyCustomFieldFormFragment,
  CompanyCustomFieldFormUpdateMutation,
  CompanyCustomFieldFormUpdateMutationVariables,
  useCompanyCustomFieldFormCreateMutation,
  useCompanyCustomFieldFormDeleteMutation,
  useCompanyCustomFieldFormUpdateMutation,
} from './CompanyCustomFieldForm.generated';

export type CompanyCustomFieldFormValues = {
  key: string;
  label: string;
  type: CompanyCustomFieldType;
};

export function CompanyCustomFieldForm(props: CompanyCustomFieldFormProps) {
  const {
    className,
    companyId,
    customField,
    onCreated,
    onUpdated,
    onDeleted,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    key: Yup.string().required(),
    label: Yup.string().required(),
    type: Yup.mixed().oneOf(Object.values(CompanyCustomFieldType)).required(),
  }).required();

  const form = useForm<CompanyCustomFieldFormValues>({
    validationSchema,
    defaultValues: {
      key: customField?.key,
      label: customField?.label,
      type: customField?.type,
    },
  });

  const [createMutation, createMutationState] =
    useCompanyCustomFieldFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useCompanyCustomFieldFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useCompanyCustomFieldFormDeleteMutation();

  const handleCreate = async (values: CompanyCustomFieldFormValues) => {
    if (customField) return;

    const variables: CompanyCustomFieldFormCreateMutationVariables = {
      companyId,
      input: {
        key: values.key,
        label: values.label,
        type: values.type,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.companyCustomFieldCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: CompanyCustomFieldFormValues) => {
    if (!customField) return;

    const variables: CompanyCustomFieldFormUpdateMutationVariables = {
      companyCustomFieldId: customField.id,
      input: {
        label: values.label,
        type: values.type,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.companyCustomFieldUpdate);
    }
  };

  const handleDelete = async () => {
    if (!customField) return;

    const variables: CompanyCustomFieldFormDeleteMutationVariables = {
      companyCustomFieldId: customField.id,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.companyCustomFieldDelete);
    }
  };

  const handleSubmit: FormProps<CompanyCustomFieldFormValues>['onValid'] =
    async (values) => {
      if (customField) {
        return handleUpdate(values);
      } else {
        return handleCreate(values);
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyCustomFieldUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem className={styles.label} required label="Label" name="label">
          <FormInput name="label" />
        </FormItem>
        <FormItem className={styles.key} required label="Clé" name="key">
          <FormInput name="key" disabled={!!customField} />
        </FormItem>
        <FormItem className={styles.type} required label="Type" name="type">
          <FormSelectCompanyCustomFieldType name="type" />
        </FormItem>
        <div className={styles.footer}>
          {!customField ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              Créer le champ
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              Mettre à jour le champ
            </Button>
          )}
          {!!customField && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer ce champ ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                Supprimer
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type CompanyCustomFieldFormProps = ChildFormProps<
  CompanyCustomFieldFormValues,
  void
> & {
  className?: string;
  companyId: string;
  customField?: CompanyCustomFieldFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: CompanyCustomFieldFormCreateMutation['companyCustomFieldCreate'],
  ) => void;
  onUpdated?: (
    v: CompanyCustomFieldFormUpdateMutation['companyCustomFieldUpdate'],
  ) => void;
  onDeleted?: (
    v: CompanyCustomFieldFormDeleteMutation['companyCustomFieldDelete'],
  ) => void;
};
