import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { ArticleAccessMode } from '@graphql/generated/types';

import styles from './ArticleAccessModeTag.module.css';

import { ArticleAccessModeTagFragment } from './ArticleAccessModeTag.generated';

export const ArticleAccessModeTag: React.FC<ArticleAccessModeTagProps> = (
  props,
) => {
  const { className, fragment } = props;
  const { t } = useTranslation('communication');

  const colors: Record<ArticleAccessMode, string> = {
    [ArticleAccessMode.Whitelist]: 'green',
    [ArticleAccessMode.Blacklist]: 'blue',
  };

  const labels: Record<ArticleAccessMode, string> = {
    [ArticleAccessMode.Whitelist]: t('articles.accessMode.Whitelist'),
    [ArticleAccessMode.Blacklist]: t('articles.accessMode.Blacklist'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[fragment.accessMode]}
    >
      {labels[fragment.accessMode]}
    </Tag>
  );
};

export type ArticleAccessModeTagProps = {
  className?: string;
  fragment: ArticleAccessModeTagFragment;
};
