import React from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { FileType } from '@graphql/generated/types';

import { PictureUploadForm } from '@components/forms/PictureUploadForm/PictureUploadForm';
import { Modal } from '@components/organisms/Modal/Modal';

import { PictureUploadModalFragment } from './PictureUploadModal.generated';

export type PictureUploadModalProps = {
  onClose: () => void;
  picture?: PictureUploadModalFragment | null;
  onUploadPicture: (pictureId: string) => Promise<void>;
  isOpen?: boolean;
  fileType: FileType;
  title: string;
  info?: string;
  aspect?: number;
  crop?: boolean;
};

const PictureUploadModal = ({
  onClose,
  isOpen,
  fileType,
  onUploadPicture,
  title,
  picture,
  info,
  aspect,
  crop,
}: PictureUploadModalProps) => {
  const { t } = useTranslation(['error']);

  return (
    <Modal
      title={title}
      onClose={onClose}
      isOpen={!!isOpen}
      form="PictureUploadForm"
    >
      <PictureUploadForm
        aspect={aspect}
        type={fileType}
        defaultValues={picture ? { pictureId: picture.id } : undefined}
        onSubmit={async (values) => {
          try {
            await onUploadPicture(values.pictureId);

            onClose();
          } catch (e) {
            message.error(t('picture/upload'));
          }
        }}
        info={info}
        crop={crop}
      />
    </Modal>
  );
};

export default PictureUploadModal;
