import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { OfferCouponPublic_OfferCouponPublic_Fragment } from '@components/views/Offers/OfferIdCouponPublicView/OfferIdCouponPublicView.generated';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferCouponPublicUpdateForm.module.css';

import {
  OfferCouponPublicUpdateFormMutation,
  OfferCouponPublicUpdateFormMutationVariables,
  useOfferCouponPublicUpdateFormMutation,
} from './OfferCouponPublicUpdateForm.generated';

export type OfferCouponPublicUpdateFormValues = {
  value: string;
  expiresAt?: Date | null;
};

export function OfferCouponPublicUpdateForm(
  props: OfferCouponPublicUpdateFormProps,
) {
  const { className, offer, ...rest } = props;

  const { t } = useTranslation('couponPublic');

  const validationSchema = Yup.object({
    value: Yup.string().required(),
    expiresAt: Yup.date().optional(),
  }).required();

  const form = useForm<OfferCouponPublicUpdateFormValues>({
    validationSchema,
    defaultValues: {
      value: offer.coupon?.value || '',
      expiresAt: offer.coupon?.expiresAt || null,
    },
  });

  const [mutation] = useOfferCouponPublicUpdateFormMutation();

  const handleSubmit: FormProps<OfferCouponPublicUpdateFormValues>['onValid'] =
    async (values) => {
      if (!offer.coupon) return;

      const variables: OfferCouponPublicUpdateFormMutationVariables = {
        couponId: offer.coupon?.id,
        input: {
          value: values.value,
          expiresAt: values.expiresAt,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferCouponPublicUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.value}
          label={t('fields.value')}
          name="value"
        >
          <FormInput name="value" />
        </FormItem>
        <FormItem
          className={styles.expiresAt}
          label={t('fields.expiresAt')}
          name="expiresAt"
        >
          <FormInputDate name="expiresAt" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type OfferCouponPublicUpdateFormProps = ChildFormProps<
  OfferCouponPublicUpdateFormValues,
  OfferCouponPublicUpdateFormMutation
> & {
  className?: string;
  offer: OfferCouponPublic_OfferCouponPublic_Fragment;
};
