import { useState } from 'react';
import { Button } from 'antd';

import { PaymentLimitUpdateModal } from '@components/modals/PaymentLimitUpdateModal/PaymentLimitUpdateModal';
import { UserIdViewDocument } from '@components/views/Users/UserIdView/UserIdView.generated';

import {
  useUserPaymentLimitDeleteMutation,
  useUserPaymentLimitUserQuery,
} from './UserPaymentLimitUpdate.generated';

type Props = {
  userId: string;
};

const UserPaymentLimit = ({ userId }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { data } = useUserPaymentLimitUserQuery({ variables: { userId } });

  const [mutate] = useUserPaymentLimitDeleteMutation({
    refetchQueries: [{ query: UserIdViewDocument, variables: { userId } }],
  });

  const deleteLimit = () => {
    if (data?.user?.paymentLimit) {
      mutate({ variables: { paymentLimitId: data.user.paymentLimit.id } });
    }
  };

  return (
    <div>
      {!data?.user?.paymentLimit && (
        <Button onClick={() => setIsModalOpened(true)}>
          Modifier la limite de paiement
        </Button>
      )}

      {data?.user?.paymentLimit && (
        <Button onClick={deleteLimit}>Supprimer la limite de paiement</Button>
      )}

      <PaymentLimitUpdateModal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        userId={userId}
      />
    </div>
  );
};

export default UserPaymentLimit;
