import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import countries from 'i18n-iso-countries';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  AdminCommentTargetType,
  UserRole,
  UserStatus,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { UserRoleTag } from '@components/atoms/UserRoleTag/UserRoleTag';
import { UserStatusTag } from '@components/atoms/UserStatusTag/UserStatusTag';
import { TableRendererAvatar } from '@components/molecules/TableRendererAvatar/TableRendererAvatar';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  UsersTableQueryVariables,
  UsersTableRecordFragment,
  useUsersTableQuery,
} from './UsersTable.generated';

type RecordType = UsersTableRecordFragment;

type Column =
  | 'avatar'
  | 'fullName'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'status'
  | 'role'
  | 'company'
  | 'nationality'
  | 'orderCount'
  | 'paymentLimitBalance'
  | 'paymentLimitCeiling'
  | 'lastLoginAt'
  | 'lastActiveAt'
  | 'birthdate'
  | 'newsletterAcceptedGlobal'
  | 'deactivated';

export function UsersTable(props: UsersTableProps) {
  const { className, filter, sorter, ...rest } = props;
  const { i18n, t } = useTranslation('users');

  const query = useUsersTableQuery({
    variables: {
      skip: 0,
      take: 10,
      filter,
      sorter,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'avatar',
      title: t('table.headers.avatar'),
      dataIndex: ['avatar'],
      width: 64,
      render: (avatar, node) => (
        <TableRendererAvatar
          picture={avatar}
          placeholder={`${node.firstName[0]}${node.lastName[0]}`}
        />
      ),
    },
    {
      key: 'fullName',
      title: t('table.headers.fullName'),
      sorter: true,
      dataIndex: ['fullName'],
      render: (_, node) => (
        <I18nLink to={`/users/list/${node.id}`}>
          {node.firstName} {node.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `fullName`, 'search'),
    },
    {
      key: 'firstName',
      title: t('table.headers.firstName'),
      sorter: true,
      dataIndex: ['firstName'],
      render: (firstName, node) => (
        <I18nLink to={`/users/list/${node.id}`}>{firstName}</I18nLink>
      ),
      ...generateColumnFilter(query, `firstName`, 'search'),
    },
    {
      key: 'lastName',
      title: t('table.headers.lastName'),
      sorter: true,
      dataIndex: ['lastName'],
      render: (lastName, node) => (
        <I18nLink to={`/users/list/${node.id}`}>{lastName}</I18nLink>
      ),
      ...generateColumnFilter(query, `lastName`, 'search'),
    },
    {
      key: 'email',
      title: t('table.headers.email'),
      dataIndex: ['email'],
      render: (email, node) => (
        <I18nLink copyable ellipsis={true} to={`/users/list/${node.id}`}>
          {email}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `email`, 'search'),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      sorter: true,
      dataIndex: ['status'],
      render: (status, node) => <UserStatusTag user={node} />,
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          value: UserStatus.Created,
          label: t('userStatusTag.CREATED'),
        },
        {
          value: UserStatus.Invited,
          label: t('userStatusTag.INVITED'),
        },
        {
          value: UserStatus.Activated,
          label: t('userStatusTag.ACTIVATED'),
        },
        {
          value: UserStatus.Banned,
          label: t('userStatusTag.BANNED'),
        },
        {
          value: UserStatus.Archived,
          label: t('userStatusTag.ARCHIVED'),
        },
      ]),
    },
    {
      key: 'role',
      sorter: true,
      title: t('table.headers.role'),
      render: (user) => <UserRoleTag user={user} />,
      ...generateColumnFilter(query, 'role', 'enum', [
        {
          value: UserRole.User,
          label: t('userRole.User'),
        },
        {
          value: UserRole.Superadmin,
          label: t('userRole.Superadmin'),
        },
      ]),
    },
    {
      key: 'company',
      sorter: true,
      title: t('table.headers.company'),
      dataIndex: ['company'],
      render: (company) =>
        company && (
          <I18nLink to={`/companies/list/${company.id}`}>
            {company.name}
          </I18nLink>
        ),
      ...generateColumnFilter(
        query,
        `companyMembership.company.name`,
        'search',
      ),
    },
    {
      key: 'nationality',
      title: t('table.headers.nationality'),
      sorter: true,
      dataIndex: ['nationality'],
      ...generateColumnFilter(
        query,
        `nationality`,
        'searchableEnum',
        Object.entries(countries.getNames(i18n.language.slice(0, 2))).map(
          ([value, label]) => ({
            value,
            label,
          }),
        ),
      ),
    },
    {
      key: 'orderCount',
      sorter: true,
      title: t('table.headers.orderCount'),
      dataIndex: ['ordersConnection', 'totalCount'],
      ...generateColumnFilter(query, `orders.count`, 'number'),
    },
    {
      key: 'paymentLimitBalance',
      title: t('table.headers.paymentLimitBalance'),
      dataIndex: ['paymentLimitBalance'],
    },
    {
      key: 'paymentLimitCeiling',
      title: t('table.headers.paymentLimitCeiling'),
      dataIndex: ['paymentLimitCeiling'],
    },
    {
      key: 'birthdate',
      title: t('table.headers.birthdate'),
      dataIndex: ['birthdate'],
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `birthdate`, 'date'),
    },
    {
      key: 'lastLoginAt',
      title: t('table.headers.lastLoginAt'),
      sorter: true,
      dataIndex: ['lastLoginAt'],
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `lastLoginAt`, 'datetime'),
    },
    {
      key: 'lastActiveAt',
      title: t('table.headers.lastActiveAt'),
      sorter: true,
      dataIndex: ['lastActiveAt'],
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `lastActiveAt`, 'datetime'),
    },
    {
      key: 'newsletterAcceptedGlobal',
      title: t('table.headers.newsletterAcceptedGlobal'),
      dataIndex: ['preferences', 'properties', 'newsletters', 'global'],
      render: (newsletterAcceptedGlobal) => (
        <Checkbox checked={newsletterAcceptedGlobal} disabled />
      ),
    },
    {
      key: 'deactivated',
      title: t('table.headers.deactivated'),
      dataIndex: ['companyMembership', 'deactivatedAt'],
      render: (deactivatedAt) => <Checkbox checked={deactivatedAt} disabled />,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="UsersTable"
        query={query}
        columns={columns}
        data={query.data?.usersPagination}
        adminCommentTargetType={AdminCommentTargetType.User}
        {...rest}
      />
    </div>
  );
}

export type UsersTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  filter?: UsersTableQueryVariables['filter'];
  sorter?: UsersTableQueryVariables['sorter'];
};
