import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType, Scalars } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormPicture } from '@atoms/FormPicture/FormPicture';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './BrandCreateForm.module.css';

import {
  BrandCreateFormMutation,
  BrandCreateFormMutationVariables,
  useBrandCreateFormMutation,
} from './BrandCreateForm.generated';

export type BrandCreateFormValues = {
  localeId: string;
  name: string;
  description?: Scalars['Delta'];
  logoId: string;
  coverId?: string;
};

export function BrandCreateForm(props: BrandCreateFormProps) {
  const { className, ...rest } = props;
  const { t } = useTranslation('common');

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.object().optional(),
    logoId: Yup.string().required(),
    coverId: Yup.string(),
  }).required();

  const form = useForm<BrandCreateFormValues>({
    validationSchema,
    defaultValues: {
      localeId: 'fr',
    },
  });

  const [mutation] = useBrandCreateFormMutation();

  const handleSubmit: FormProps<BrandCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: BrandCreateFormMutationVariables = {
      input: {
        localeId: values.localeId,
        name: values.name,
        description: values.description,
        logoId: values.logoId,
        coverId: values.coverId,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="BrandCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem className={styles.logo} required label="Logo" name="logoId">
          <FormPicture
            name="logoId"
            previewRatio="1:1"
            type={FileType.BrandLogo}
            info={t('picture.advice', {
              resolution: '512x512',
              size: 1,
            })}
          />
        </FormItem>
        <FormItem
          className={styles.cover}
          required
          label="Image de couverture"
          name="coverId"
        >
          <FormPicture
            name="coverId"
            type={FileType.BrandCover}
            info={t('picture.advice', {
              resolution: COVER_RESOLUTION_INFO,
              size: COVER_SIZE_INFO,
            })}
          />
        </FormItem>
        <FormItem
          className={styles.description}
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la marque
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type BrandCreateFormProps = ChildFormProps<
  BrandCreateFormValues,
  BrandCreateFormMutation
> & {
  className?: string;
};
