import { SortDirection } from '@graphql/generated/types';

import { MangopayTransferTable } from '@components/tables/MangopayTransfersTable/MangopayTransfersTable';

export const TransfersView: React.FC = () => {
  return (
    <MangopayTransferTable
      activeColumns={[
        'id',
        'updatedAt',
        'mangopayId',
        'amount',
        'status',
        'resultMessage',
        'link',
      ]}
      sorter={{ updatedAt: SortDirection.Desc }}
    />
  );
};
