import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './TaxRateUpdateForm.module.css';

import {
  TaxRateUpdateFormFragment,
  TaxRateUpdateFormMutation,
  TaxRateUpdateFormMutationVariables,
  useTaxRateUpdateFormMutation,
} from './TaxRateUpdateForm.generated';

export type TaxRateUpdateFormValues = {
  displayName: string;
};

export function TaxRateUpdateForm(props: TaxRateUpdateFormProps) {
  const { className, hideFooter, taxRate, ...rest } = props;
  const { t } = useTranslation('taxRates');

  const validationSchema = Yup.object({
    displayName: Yup.string().required(),
  }).required();

  const form = useForm<TaxRateUpdateFormValues>({
    validationSchema,
    defaultValues: {
      displayName: taxRate.displayName,
    },
  });

  const [mutation] = useTaxRateUpdateFormMutation();

  const handleSubmit: FormProps<TaxRateUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: TaxRateUpdateFormMutationVariables = {
      taxRateId: taxRate.id,
      input: {
        displayName: values.displayName,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="TaxRateUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.displayName}
          required
          label={t('fields.displayName')}
          name="displayName"
        >
          <FormInput name="displayName" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.update.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type TaxRateUpdateFormProps = ChildFormProps<
  TaxRateUpdateFormValues,
  TaxRateUpdateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
  taxRate: TaxRateUpdateFormFragment;
};
