import { Spin } from 'antd';

import { UserSubventionsTable } from '@components/tables/UserSubventionsTable/UserSubventionsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useUserIdSubventionViewQuery } from './UserIdSubventionView.generated';

export const UserIdSubventionView: React.FC<UserIdSubventionViewProps> = (
  props,
) => {
  const { userId } = props;

  const query = useUserIdSubventionViewQuery({
    variables: {
      userId,
      take: 10,
      skip: 0,
      filter: {},
    },
  });

  const pagination = query.data?.user?.subventionPagination;

  if (query.loading && !pagination) return <Spin />;
  else if (!pagination) return <NotFoundView />;

  return (
    <UserSubventionsTable
      activeColumns={[
        'name',
        'startsAt',
        'endsAt',
        'unitAmount',
        'consumption',
      ]}
      query={query}
      data={pagination}
    />
  );
};

export type UserIdSubventionViewProps = {
  userId: string;
};
