import { ReactNode, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { QueryResult } from '@apollo/client';
import { Checkbox, Skeleton } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import styles from './FilterDropdownEnum.module.css';

const PAGE_SIZE = 25;

export function FilterDropdownEnum(props: FilterDropdownEnumProps) {
  const {
    options,
    onChange,
    value,
    name,
    filterQuery,
    filterHasMore = false,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange: CheckboxGroupProps['onChange'] = (values) => {
    onChange(values as string[]);
  };

  const loadMore = () => {
    filterQuery?.fetchMore({
      variables: { skip: PAGE_SIZE * currentPage, take: PAGE_SIZE },
    });
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className={styles.root} id={name}>
      <Checkbox.Group value={value} onChange={handleChange}>
        <InfiniteScroll
          dataLength={options.length}
          next={loadMore}
          hasMore={filterHasMore}
          loader={<Skeleton paragraph={{ rows: 1 }} active />}
          scrollableTarget={name}
        >
          {options.map((option) => (
            <div key={option.value}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </div>
          ))}
        </InfiniteScroll>
      </Checkbox.Group>
    </div>
  );
}

export type FilterDropdownEnumProps = {
  options: { value?: string | number; label: ReactNode }[];
  onChange: (checked: string[]) => void;
  value: string[];
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterQuery?: QueryResult<any, any>;
  filterHasMore?: boolean;
};
