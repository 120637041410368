import { useTranslation } from 'react-i18next';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import {
  LinkProps as TypoLinkProps,
  Typography,
} from '../Typography/Typography';

export function I18nLink(props: LinkProps & TypoLinkProps) {
  const { i18n } = useTranslation();
  const { to, ...rest } = props;

  return (
    <RouterLink
      component={Typography.Link}
      to={i18nTo(to, i18n.language)}
      {...rest}
    />
  );
}

function i18nTo(to: LinkProps['to'], locale: string): LinkProps['to'] {
  if (typeof to === 'string') {
    return `/${locale}/${to}`.replace(/\/\//g, '/');
  } else if (typeof to === 'object') {
    return {
      ...to,
      pathname: to.pathname
        ? `/${locale}/${to.pathname}`.replace(/\/\//g, '/')
        : undefined,
    };
  } else {
    throw new Error('to format not supported');
  }
}
