import { useTranslation } from 'react-i18next';
import { Button, Card, message, Space, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import * as CSV from 'csv-string';
import isEmail from 'validator/es/lib/isEmail';

import { InvitationLinkData } from '@graphql/generated/types';

import {
  useInvitationEmailSendBulkMutation,
  useInvitationLinkGenerateBulkMutation,
} from './CompanyIdCareView.generated';

type CompanyIdCareViewProps = {
  companyId: string;
};

export const CompanyIdCareView = (props: CompanyIdCareViewProps) => {
  const { companyId } = props;
  const [generateLinksBulk, { loading: generateLinksBulkLoading }] =
    useInvitationLinkGenerateBulkMutation();

  const [sendInvitationsBulk, { loading: useGenerateLinksBulkLoading }] =
    useInvitationEmailSendBulkMutation();

  const { t } = useTranslation('companies');

  const downloadCSV = (data: InvitationLinkData[]) => {
    const csvRows = data.map((row) => `${row.email};${row.invitationLink}`);
    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'invitationLinks.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateLinks = async (userEmails: string[]) => {
    try {
      const result = await generateLinksBulk({
        variables: { companyId, input: { userEmails } },
      });

      message.success(
        t(
          'care.actions.generate_links_bulk.success',
          'Le fichier de lien a été généré et va etre téléchargé',
        ),
      );
      downloadCSV(
        result?.data?.invitationLinkGenerateBulk.invitationLinksData || [],
      );
    } catch (err) {
      message.error(
        t(
          'care.actions.generate_links_bulk.error.process',
          "Une erreur est survenue lors de la génération des liens d'invitations",
        ) + err,
      );
    }
  };

  const sendInvitations = async (userEmails: string[]) => {
    try {
      const result = await sendInvitationsBulk({
        variables: { companyId, input: { userEmails } },
      });

      const invitationsSent =
        result.data?.invitationEmailSendBulk.invitations.length;

      if (invitationsSent) {
        message.success(
          t('care.actions.send_invitations_bulk.success', {
            count: invitationsSent,
          }),
        );
      }
    } catch (err) {
      message.error(
        t(
          'care.actions.send_invitations_bulk.error.process',
          "Une erreur est survenue lors de l'envoi des invitations",
        ) + err,
      );
    }
  };

  const handleGenerationInvitationLinks = async (file: RcFile) => {
    const emailArray = await extractEmails(file);
    await generateLinks(emailArray);
  };

  const handleSendInvitations = async (file: RcFile) => {
    const emailArray = await extractEmails(file);
    await sendInvitations(emailArray);
  };

  const extractEmails = async (file: RcFile): Promise<string[]> => {
    const rows = CSV.parse(await file.text());

    const emails = rows.flatMap((row) => row).filter((email) => isEmail(email));

    return emails;
  };

  const beforeGenerationInvitationLinks = (file: RcFile) => {
    if (file.type !== 'text/csv') {
      message.error('Le fichier doit être au format .csv');
      throw new Error('Le fichier doit être au format .csv');
    }

    handleGenerationInvitationLinks(file);
    return false;
  };

  const beforeSendInvitationLinks = (file: RcFile) => {
    if (file.type !== 'text/csv') {
      message.error('Le fichier doit être au format .csv');
      throw new Error('Le fichier doit être au format .csv');
    }

    handleSendInvitations(file);
    return false;
  };

  return (
    <Space direction="vertical" size={'middle'}>
      <Card>
        <Upload beforeUpload={beforeGenerationInvitationLinks} accept=".csv">
          <Button loading={generateLinksBulkLoading}>
            {t(
              'care.actions.generate_links_bulk.label',
              "Générer les liens d'invitations à partir d'un .csv",
            )}
          </Button>
        </Upload>
      </Card>
      <Card>
        <Upload beforeUpload={beforeSendInvitationLinks} accept=".csv">
          <Button loading={useGenerateLinksBulkLoading}>
            {t(
              'care.actions.send_invitations_bulk.label',
              "Envoyer un ensemble d'invitations à partir d'un .csv",
            )}
          </Button>
        </Upload>
      </Card>
    </Space>
  );
};
