import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, FormItemProps as AntdFormItemProps } from 'antd';
import classnames from 'classnames';
import { get } from 'lodash';

import styles from './FormItem.module.css';

export const FormItem: React.FC<FormItemProps> = (props) => {
  const { className, name, help, dependsOn, hideFeedback, ...rest } = props;
  const { formState, watch, setValue } = useFormContext();

  useEffect(() => {
    if (!dependsOn?.length) return;

    const subscription = watch((values, info) => {
      if (
        info.name &&
        dependsOn.includes(info.name) &&
        info.type === 'change'
      ) {
        setValue(name, undefined, {
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [dependsOn]);

  let validateStatus: AntdFormItemProps['validateStatus'];

  if (get(formState.errors, name)) {
    validateStatus = 'error';
  } else if (!get(formState.touchedFields, name)) {
    validateStatus = undefined;
  } else if (formState.isValidating) {
    validateStatus = 'validating';
  } else {
    validateStatus = 'success';
  }

  return (
    <Form.Item
      validateStatus={validateStatus}
      help={get(formState.errors, name)?.message || help}
      className={classnames(className, styles.root)}
      {...rest}
    />
  );
};

export type FormItemProps = Pick<
  AntdFormItemProps,
  'label' | 'children' | 'help' | 'required'
> & {
  name: string;
  hideFeedback?: boolean;
  className?: string;
  dependsOn?: string[];
};
