import { useState } from 'react';

import { OfferType } from '@graphql/generated/types';

import { OfferBaseForm } from './subForms/OfferBaseForm/OfferBaseForm';
import {
  OfferCommonForm,
  OfferCommonFormValues,
} from './subForms/OfferCommonForm/OfferCommonForm';
import { OfferCouponClaimableForm } from './subForms/OfferCouponClaimableForm/OfferCouponClaimableForm';
import { OfferCouponPublicForm } from './subForms/OfferCouponPublicForm/OfferCouponPublicForm';
import { OfferGiftCardForm } from './subForms/OfferGiftCardForm/OfferGiftCardForm';
import { OfferTicketForm } from './subForms/OfferTicketForm/OfferTicketForm';
import { OfferUrlForm } from './subForms/OfferUrlForm/OfferUrlForm';

export function OfferCreateForm(props: OfferCreateFormProps) {
  const { onSuccess, defaultValues = null } = props;

  const [commonValues, setCommonValues] =
    useState<OfferCommonFormValues | null>(defaultValues);
  const [step, setStep] = useState(0);

  if (step === 0 || !commonValues) {
    return (
      <OfferCommonForm
        value={commonValues}
        onSubmit={(data) => {
          setCommonValues(data);
          setStep(1);
        }}
      />
    );
  }

  switch (commonValues?.type) {
    case OfferType.Base:
      return (
        <OfferBaseForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerBaseCreate.offer)}
        />
      );
    case OfferType.Url:
      return (
        <OfferUrlForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerUrlCreate.offer)}
        />
      );
    case OfferType.CouponClaimable:
      return (
        <OfferCouponClaimableForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerCouponClaimableCreate.offer)}
        />
      );
    case OfferType.CouponPublic:
      return (
        <OfferCouponPublicForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerCouponPublicCreate.offer)}
        />
      );
    case OfferType.GiftCard:
      return (
        <OfferGiftCardForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerGiftCardCreate.offer)}
        />
      );
    case OfferType.Ticket:
      return (
        <OfferTicketForm
          onBack={() => setStep(0)}
          commonValues={commonValues}
          onSuccess={(data) => onSuccess(data.offerTicketCreate.offer)}
        />
      );
  }
}

export type OfferCreateFormProps = {
  className?: string;
  defaultValues?: OfferCommonFormValues;
  onSuccess: (offer: { id: string }) => void;
};
