import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from 'antd';
import classnames from 'classnames';
import Dinero from 'dinero.js';

import styles from './ProductVariantPriceTag.module.css';

import { ProductVariantPriceTagFragment } from './ProductVariantPriceTag.generated';

export const ProductVariantPriceTag: React.FC<ProductVariantPriceTagProps> = (
  props,
) => {
  const { className, productVariant } = props;
  const { t } = useTranslation('prices');

  const colors: Record<typeof productVariant.__typename, string> = {
    ProductVariantPriceRange: 'blue',
    ProductVariantPriceFixed: 'orange',
    ProductVariantPriceDynamic: 'green',
  };

  const labels: Record<typeof productVariant.__typename, string> = {
    ProductVariantPriceRange: t('type.custom'),
    ProductVariantPriceFixed: t('type.fixed'),
    ProductVariantPriceDynamic: t('type.dynamic'),
  };

  const formatPrice = (productVariant: ProductVariantPriceTagFragment) => {
    if (productVariant.__typename === 'ProductVariantPriceRange') {
      const min = Dinero({
        currency: productVariant.priceCurrency,
        amount: productVariant.priceMin,
      });

      const max = Dinero({
        currency: productVariant.priceCurrency,
        amount: productVariant.priceMax,
      });

      return t('priceRange', { min, max });
    }

    if (productVariant.__typename === 'ProductVariantPriceFixed') {
      const value = Dinero({
        currency: productVariant.priceCurrency,
        amount: productVariant.priceValue,
      });

      return t('priceFixed', { value });
    }

    if (productVariant.__typename === 'ProductVariantPriceDynamic') {
      return t('priceDynamic');
    }
  };

  return (
    <Tooltip
      title={labels[productVariant.__typename]}
      color={colors[productVariant.__typename]}
      key={colors[productVariant.__typename]}
    >
      <Tag
        className={classnames(className, styles.root)}
        color={colors[productVariant.__typename]}
      >
        {formatPrice(productVariant)}
      </Tag>
    </Tooltip>
  );
};

export type ProductVariantPriceTagProps = {
  className?: string;
  productVariant: ProductVariantPriceTagFragment;
};
