import { RouteComponentProps, useRouteMatch } from 'react-router';
import { Layout, Spin } from 'antd';

import { CampaignIdOffersView } from '../CampaignIdOffersView/CampaignIdOffersView';
import { CampaignIdTranslationsView } from '../CampaignIdTranslationsView/CampaignIdTranslationsView';
import { CampaignIdUpdateView } from '../CampaignIdUpdateView/CampaignIdUpdateView';

import { AdminCommentTargetType } from '@graphql/generated/types';

import AdminCommentsDrawerOpenButton from '@components/organisms/AdminCommentsDrawerOpenButton/AdminCommentsDrawerOpenButton';
import CampaignIdSettingsView from '@components/views/Campaigns/CampaignIdSettingsView/CampaignIdSettingsView';
import { CatalogIncludedView } from '@components/views/CatalogIncludedView/CatalogIncludedView';
import { CampaignHeader } from '@organisms/CampaignHeader/CampaignHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CampaignIdView.module.css';

import { useCampaignIdViewQuery } from './CampaignIdView.generated';

export const CampaignIdView: React.FC<CampaignIdViewProps> = (props) => {
  const {
    className,
    match: {
      params: { campaignId },
    },
  } = props;

  const match = useRouteMatch();

  const campaignQuery = useCampaignIdViewQuery({
    variables: { campaignId },
    notifyOnNetworkStatusChange: true,
  });

  const campaign = campaignQuery?.data?.campaign;

  if (campaignQuery.loading && !campaign) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!campaign) {
    return <NotFoundView />;
  }

  return (
    <Layout className={className}>
      <CampaignHeader className={styles.header} campaign={campaign} showNav />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route path={`${match.url}`} exact label="Traductions">
          <CampaignIdTranslationsView campaignId={campaign.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/settings`} label="Paramètres">
          <CampaignIdSettingsView campaignId={campaign.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/update`} label="Modifier">
          <CampaignIdUpdateView campaignId={campaign.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/offers`} exact label="Offres">
          <CampaignIdOffersView
            brandId={campaign.brand.id}
            campaignId={campaign.id}
          />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/statistics`} label="Statistiques">
          Statistics
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/include`} label="Exclusivité">
          <CatalogIncludedView entityId={campaignId} entityType={'campaign'} />
        </TabsRouter.Route>
      </TabsRouter>

      <AdminCommentsDrawerOpenButton
        targetId={campaign.id}
        targetType={AdminCommentTargetType.Campaign}
      />
    </Layout>
  );
};

export type CampaignIdViewProps = RouteComponentProps<{
  campaignId: string;
}> & {
  className?: string;
};
