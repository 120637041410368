import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType, Scalars } from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormRichTextEditor } from '@components/atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { CoverLibrarySelect } from '@components/organisms/CoverLibrarySelect/CoverLibrarySelect';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './HappeningCreateForm.module.css';

import {
  HappeningCreateFormMutation,
  HappeningCreateFormMutationVariables,
  useHappeningCreateFormMutation,
} from './HappeningCreateForm.generated';

// Note: Having no company ID means that the category is global
export type HappeningCreateFormValues = {
  companyId: string;
  name: string;
  description: Scalars['Delta'];
  // TODO: address
  cover?: ProgressivePictureFragment;
  attachments?: AttachmentItemFragment[];
  happeningStartsAt: Date;
  happeningEndsAt: Date;
  registrationStartsAt: Date;
  registrationEndsAt: Date;
  maxRegistrationCount?: number;
};

export function HappeningCreateForm(props: HappeningCreateFormProps) {
  const { className, companyId, ...rest } = props;
  const { t } = useTranslation('happenings');

  const validationSchema = Yup.object({
    companyId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.object().required(),
    cover: Yup.mixed<ProgressivePictureFragment>().required(),
    attachments: Yup.mixed<AttachmentItemFragment[]>().optional(),
    happeningStartsAt: Yup.date().required(),
    happeningEndsAt: Yup.date().required(),
    registrationStartsAt: Yup.date().required(),
    registrationEndsAt: Yup.date().required(),
    maxRegistrationCount: Yup.number().optional(),
  }).required();

  const form = useForm<HappeningCreateFormValues>({
    validationSchema,
    defaultValues: { companyId },
  });

  const [mutation] = useHappeningCreateFormMutation();

  const handleSubmit: FormProps<HappeningCreateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!values.cover?.id) {
      return;
    }

    const variables: HappeningCreateFormMutationVariables = {
      companyId: values.companyId,
      input: {
        name: values.name,
        description: values.description,
        coverId: values.cover?.id,
        attachmentIds: values.attachments?.map((a) => a.id),
        happeningStartsAt: values.happeningStartsAt,
        happeningEndsAt: values.happeningEndsAt,
        registrationStartsAt: values.registrationStartsAt,
        registrationEndsAt: values.registrationEndsAt,
        maxRegistrationCount: values.maxRegistrationCount,
        address: {
          address1: '',
          country: 'FR',
          zip: '75000',
        },
      },
    };

    try {
      const { data } = await mutation({ variables });
      message.success(t('HappeningUpdateForm.successMessage'));
      return data;
    } catch (err) {
      handleMutationErrors(err, {});
    }
  };

  const { happeningStartsAt, happeningEndsAt, registrationStartsAt } =
    form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="HappeningCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.company}
          required
          label={t('fields.company')}
          name="companyId"
        >
          <FormSelectCompany name="companyId" />
        </FormItem>

        <FormItem
          className={styles.name}
          required
          label={t('fields.name')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.description}
          required
          label={t('fields.description')}
          name="description"
        >
          <FormRichTextEditor name="description" />
        </FormItem>

        <FormItem
          className={styles.cover}
          required
          label={t('fields.cover')}
          name="cover"
        >
          <CoverLibrarySelect control={form.control} name="cover" />
        </FormItem>

        <FormItem
          className={styles.attachments}
          label={t('fields.attachments')}
          name="attachments"
        >
          <FormInputMultipleUpload
            control={form.control}
            name="attachments"
            max={5}
            type={FileType.HappeningAttachment}
          />
        </FormItem>

        <FormItem
          className={styles.happeningStartsAt}
          required
          label={t('fields.happeningStartsAt')}
          name="happeningStartsAt"
        >
          <FormInputDate
            name="happeningStartsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
          />
        </FormItem>

        <FormItem
          className={styles.happeningEndsAt}
          required
          label={t('fields.happeningEndsAt')}
          name="happeningEndsAt"
        >
          <FormInputDate
            name="happeningEndsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(happeningStartsAt)}
          />
        </FormItem>

        <FormItem
          className={styles.registrationStartsAt}
          required
          label={t('fields.registrationStartsAt')}
          name="registrationStartsAt"
        >
          <FormInputDate
            name="registrationStartsAt"
            showTime={true}
            disabled={!happeningStartsAt || !happeningEndsAt}
            disabledDate={(date) =>
              date.isBefore(new Date()) || date.isAfter(happeningEndsAt)
            }
          />
        </FormItem>

        <FormItem
          className={styles.registrationEndsAt}
          required
          label={t('fields.registrationEndsAt')}
          name="registrationEndsAt"
        >
          <FormInputDate
            name="registrationEndsAt"
            showTime={true}
            disabled={!happeningStartsAt || !happeningEndsAt}
            disabledDate={(date) =>
              date.isBefore(registrationStartsAt) ||
              date.isAfter(happeningEndsAt)
            }
          />
        </FormItem>

        <FormItem
          className={styles.maxRegistrationCount}
          label={t('fields.maxRegistrationCount')}
          name="maxRegistrationCount"
        >
          <FormInputNumber name="maxRegistrationCount" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('HappeningCreateForm.create')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type HappeningCreateFormProps = ChildFormProps<
  HappeningCreateFormValues,
  HappeningCreateFormMutation
> & {
  className?: string;
  companyId?: string;
};
