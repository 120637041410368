import { TranslationGenerator } from '@components/organisms/TranslationGenerator/TranslationGenerator';

import {
  useCampaignTranslationGenerateMutation,
  useGeneratedTranslationCampaignsCountQuery,
  useMissingTranslationCampaignsCountQuery,
  useTranslationCampaignsCountQuery,
} from './TranslationsCampaignsView.generated';

export const TranslationsCampaignsView = () => {
  const countQuery = useTranslationCampaignsCountQuery();
  const [translationMutation] = useCampaignTranslationGenerateMutation();

  return (
    <TranslationGenerator
      useMissingQuery={useMissingTranslationCampaignsCountQuery}
      useGeneratedQuery={useGeneratedTranslationCampaignsCountQuery}
      countQuery={countQuery}
      generateMutation={translationMutation}
    />
  );
};
