import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { ApiKeyModal } from '@components/modals/ApiKeyModal/ApiKeyModal';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import styles from './ApiKeyTable.module.css';

import {
  ApiKeyTableRecordFragment,
  useApiKeyDeleteMutation,
} from './ApiKeyTable.generated';

type RecordType = ApiKeyTableRecordFragment;

type Column = 'name' | 'clientId';

export function ApiKeyTable(props: ApiKeyTableProps) {
  const { className, data, query, companyId, ...rest } = props;

  const [modalState, setModalState] = useState<boolean | RecordType>(false);
  const [deleteMutation] = useApiKeyDeleteMutation();

  if (!query) {
    return null;
  }

  const { t } = useTranslation('apiKey');

  const handleDelete = async (apiKeyId: string) => {
    if (!confirm('Confirmez la suppression de la clé API')) return;

    await deleteMutation({ variables: { apiKeyId } });
    await query.refetch(query.variables);
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
    },
    {
      key: 'clientId',
      title: t('table.headers.clientId'),
      dataIndex: ['clientId'],
    },
  ];

  const title = (
    <div>
      <Button onClick={() => setModalState(true)}>Ajouter une clé API</Button>
    </div>
  );

  return (
    <div className={className}>
      <TablePagination
        id="ApiKeyTableTable"
        query={query}
        columns={columns}
        data={data}
        title={() => title}
        action={(record) => (
          <div className={styles.action}>
            <Button type="text" onClick={() => setModalState(record)}>
              <EditOutlined />
            </Button>
            <Button type="text" danger onClick={() => handleDelete(record.id)}>
              <DeleteOutlined />
            </Button>
          </div>
        )}
        {...rest}
      />
      <ApiKeyModal
        isOpen={!!modalState}
        onClose={() => setModalState(false)}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
        apiKey={
          modalState && typeof modalState !== 'boolean' ? modalState : undefined
        }
        companyId={companyId}
      />
    </div>
  );
}

export type ApiKeyTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  companyId: string;
  // filter?: CompanyIdSsoViewQueryVariables['filter'];
  // sorter?: CompanyIdSsoViewQueryVariables['sorter'];
};
