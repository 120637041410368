import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { Locale } from '@graphql/generated/types';

import { SelectLocale } from '@components/molecules/SelectLocale/SelectLocale';
import { OffersTable } from '@components/tables/OffersTable/OffersTable';

import styles from '../Redbin.module.css';

import { useRedBinOffersMissingTranslationQuery } from './OffersMissingTranslation.generated';

export const OffersMissingTranslation: React.FC<OffersMissingTranslationProps> =
  (props) => {
    const { className } = props;

    const { t } = useTranslation('redbin');

    const [missingLocaleId, setMissingLocaleId] = useState<Locale['id']>('fr');

    const query = useRedBinOffersMissingTranslationQuery({
      variables: { missingLocaleId, take: 10, skip: 0 },
    });

    return (
      <div className={classnames(className, styles.root)}>
        <PageHeader ghost={false} title={t('OffersMissingTranslation.title')} />

        <OffersTable
          query={query}
          data={query.data?.redBinOffersMissingTranslation}
          activeColumns={[
            'name',
            'type',
            'status',
            'brand',
            'campaign',
            'startsAt',
            'endsAt',
            'createdAt',
            'updatedAt',
          ]}
          title={() => (
            <>
              {`${t('OffersMissingTranslation.missingLocale')} `}
              <SelectLocale
                onSelect={(value) => setMissingLocaleId(value as string)}
                defaultValue={'fr'}
              />
            </>
          )}
        />
      </div>
    );
  };

export type OffersMissingTranslationProps = {
  className?: string;
};
