import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import classnames from 'classnames';

import styles from './CampaignDeleteButton.module.css';

import {
  CampaignDeleteButtonFragment,
  CampaignDeleteButtonMutationResult,
  useCampaignDeleteButtonMutation,
} from './CampaignDeleteButton.generated';

export const CampaignDeleteButton: React.FC<CampaignDeleteButtonProps> = (
  props,
) => {
  const { className, campaign, mode, onSuccess: onSucccess } = props;
  const { t } = useTranslation('campaigns');

  const [isSubmitting, setSubmitting] = useState(false);

  const [mutation] = useCampaignDeleteButtonMutation();

  const handleDelete = async () => {
    if (isSubmitting) return;

    setSubmitting(true);

    try {
      const { data } = await mutation({
        variables: {
          campaignId: campaign.id,
        },
      });

      message.success(
        t('CampaignDeleteButton.success', { name: campaign.name }),
      );

      if (onSucccess) {
        onSucccess(data);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            case 'campaign/has-offers':
              message.error(t(`error:campaign/has-offers`));
              break;

            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
    setSubmitting(false);
  };

  const renderButton = () => {
    switch (mode) {
      case 'icon':
        return <DeleteOutlined />;
      case 'text':
        return t('CampaignDeleteButton.delete.label');
      case 'iconAndText':
        return (
          <>
            <DeleteOutlined /> {t('CampaignDeleteButton.delete.label')}
          </>
        );
    }
  };

  return (
    <Popconfirm
      onConfirm={handleDelete}
      title={t('CampaignDeleteButton.delete.confirm', { name: campaign.name })}
    >
      <Button
        className={classnames(className, styles.root)}
        loading={isSubmitting}
        danger
      >
        {renderButton()}
      </Button>
    </Popconfirm>
  );
};

export type CampaignDeleteButtonProps = {
  className?: string;
  campaign: CampaignDeleteButtonFragment;
  mode: 'icon' | 'text' | 'iconAndText';
  onSuccess?: (data: CampaignDeleteButtonMutationResult['data']) => void;
};
