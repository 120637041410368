import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './OrderItemStatusTag.module.css';

import { OrderItemStatusTagFragment } from './OrderItemStatusTag.generated';

export const OrderItemStatusTag: React.FC<OrderItemStatusTagProps> = (
  props,
) => {
  const { className, order } = props;
  const { t } = useTranslation('orderItems');

  const colors: Record<typeof order.status, string> = {
    DRAFT: 'grey',
    CREATED: 'green',
    PROCESSING: 'blue',
    CANCELED: 'red',
    DELIVERED_FULL: 'green',
    DELIVERED_PARTIAL: 'orange',
    REFUNDED_FULL: 'orange',
    REFUNDED_PARTIAL: 'orange',
  };

  const labels: Record<typeof order.status, string> = {
    DRAFT: t('status.draft'),
    CREATED: t('status.created'),
    PROCESSING: t('status.processing'),
    CANCELED: t('status.canceled'),
    DELIVERED_FULL: t('status.deliveredFull'),
    DELIVERED_PARTIAL: t('status.deliveredPartial'),
    REFUNDED_FULL: t('status.refundedFull'),
    REFUNDED_PARTIAL: t('status.refundedPartial'),
  };

  return (
    <Tag
      title={labels[order.status]}
      className={classnames(className, styles.root)}
      color={colors[order.status]}
    >
      {labels[order.status]}
    </Tag>
  );
};

export type OrderItemStatusTagProps = {
  className?: string;
  order: OrderItemStatusTagFragment;
};
