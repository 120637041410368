import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// import countries from 'country-region-data';
import countries from 'i18n-iso-countries';

import { FormSelect } from '../FormSelect/FormSelect';

export const FormSelectCountry: React.FC<FormSelectCountryProps> = (props) => {
  const { className, name, clearable } = props;
  const { i18n } = useTranslation();

  return (
    <FormSelect
      name={name}
      className={classnames(className)}
      showSearch
      optionFilterProp="label"
      clearable={clearable}
      options={Object.entries(
        countries.getNames(i18n.language.slice(0, 2)),
      ).map(([value, label]) => ({
        value,
        label,
      }))}
    />
  );
};

export type FormSelectCountryProps = {
  name: string;
  clearable?: boolean;
  className?: string;
};
