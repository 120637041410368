import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { ArticleCategoriesTable } from '@components/tables/ArticleCategoriesTable/ArticleCategoriesTable';

import styles from './ArticleCategoriesView.module.css';

export const ArticleCategoriesView: React.FC<ArticleCategoriessViewProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation('communication');

  return (
    <div className={classnames(className, styles.root)}>
      <ArticleCategoriesTable
        activeColumns={['name', 'articlesCount', 'createdAt', 'updatedAt']}
        filter={{ company: { id: { is: null } } }}
        title={() => (
          <>
            <I18nLink
              to={{
                pathname: `/communication/articles/categories/create`,
              }}
            >
              <Button>
                {t('articles.category.table.create', 'Ajouter une catégorie')}
              </Button>
            </I18nLink>
          </>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/communication/articles/categories/create`,
              }}
            >
              <Button>
                {t('articles.category.table.create', 'Ajouter une catégorie')}
              </Button>
            </I18nLink>
          ),
        }}
      />
    </div>
  );
};

export type ArticleCategoriessViewProps = {
  className?: string;
};
