import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { ArticleIdDetailedView } from '../ArticleIdDetailedView/ArticleIdDetailedView';
import { ArticleIdTranslationsView } from '../ArticleIdTranslationsView/ArticleIdTranslationsView';
import { ArticleUpdateView } from '../ArticleUpdateView/ArticleUpdateView';

import { ArticleHeader } from '@components/organisms/ArticleHeader/ArticleHeader';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';
import { CompanyExcludedView } from '@components/views/CompanyExcludedView/CompanyExcludedView';
import { CompanyIncludedView } from '@components/views/CompanyIncludedView/CompanyIncludedView';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ArticleIdView.module.css';

import { useArticleIdViewQuery } from './ArticleIdView.generated';

export const ArticleIdView: React.FC<ArticleIdViewProps> = (props) => {
  const { className, match } = props;

  const {
    params: { articleId },
  } = match;

  const articleQuery = useArticleIdViewQuery({
    variables: { articleId },
    notifyOnNetworkStatusChange: true,
  });

  const article = articleQuery?.data?.article;

  if (articleQuery.loading && !article) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!article) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <ArticleHeader article={article} />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label="Description/Configuration"
        >
          <ArticleIdTranslationsView articleId={articleId} />
        </TabsRouter.Route>

        <TabsRouter.Route path={`${match.url}/update`} exact label="Modifier">
          <ArticleUpdateView article={article} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/preview`}
          label="Aperçu du contenu"
        >
          <ArticleIdDetailedView
            article={article}
            loading={articleQuery.loading}
          />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/include`}
          label="Whitelist (include)"
        >
          <CompanyIncludedView entityId={article.id} entityType={'article'} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/exclude`}
          label="Blacklist (exclude)"
        >
          <CompanyExcludedView entityId={article.id} entityType={'article'} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type ArticleIdViewProps = RouteComponentProps<{
  articleId: string;
}> & {
  className?: string;
};
