import { InvitationsTable } from '@components/tables/InvitationsTable/InvitationsTable';
import { useInvitationsCompanyTableQuery } from '@components/tables/InvitationsTable/InvitationsTable.generated';

type CompanyInvitationsViewProps = {
  companyId: string;
};

export const CompanyInvitationsView = (props: CompanyInvitationsViewProps) => {
  const { companyId } = props;

  const query = useInvitationsCompanyTableQuery({
    variables: {
      companyId,
      skip: 0,
      take: 20,
    },
  });

  return (
    <div>
      <InvitationsTable
        activeColumns={[
          'id',
          'claimedAt',
          'revokedAt',
          'createdAt',
          'expiredAt',
          'sendAt',
          'sender',
          'status',
          'receiver',
        ]}
        query={query}
        data={query.data?.company?.invitationsReceived || null}
      />
    </div>
  );
};
