import classnames from 'classnames';
import { data } from 'currency-codes';

import { FormSelect } from '../FormSelect/FormSelect';

export const FormSelectCurrency: React.FC<FormSelectCurrencyProps> = (
  props,
) => {
  const { className, name } = props;

  return (
    <FormSelect
      name={name}
      className={classnames(className)}
      showSearch
      optionFilterProp="label"
      options={data.map(({ code, currency }) => ({
        value: code,
        label: currency,
      }))}
    />
  );
};

export type FormSelectCurrencyProps = {
  name: string;
  className?: string;
};
