import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classnames from 'classnames';

import PictureUploadModal, {
  PictureUploadModalProps,
} from '@components/modals/PictureUploadModal/PictureUploadModal';
import {
  ProgressivePicture,
  ProgressivePictureProps,
} from '@atoms/ProgressivePicture/ProgressivePicture';
import { ProgressivePictureFragment } from '@atoms/ProgressivePicture/ProgressivePicture.generated';

import styles from './PageHeader.module.css';

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const {
    className,
    cover,
    coverProps = {},
    logo,
    logoProps = {},
    children,
    showNav,
    uploadLogoProps,
    uploadCoverProps,
  } = props;
  const [logoModalIsOpen, setLogoModalIsOpen] = useState(false);
  const [coverModalIsOpen, setCoverModalIsOpen] = useState(false);

  return (
    <>
      <div className={classnames(className, styles.root)}>
        {cover ? (
          <ProgressivePicture
            {...coverProps}
            className={styles.cover}
            picture={cover}
          />
        ) : (
          <div className={classnames(styles.cover, styles.emptyBackground)} />
        )}

        <div
          className={classnames(styles.content, {
            [styles.hoverable]: !!uploadCoverProps,
          })}
        >
          <Button
            type="default"
            className={styles.editCoverButton}
            onClick={() => uploadCoverProps && setCoverModalIsOpen(true)}
          >
            Editer la couverture
          </Button>
          {children}
        </div>

        {!!(logo || uploadLogoProps) && (
          <button
            className={styles.logo}
            onClick={() => uploadLogoProps && setLogoModalIsOpen(true)}
            disabled={!uploadLogoProps}
          >
            <div className={styles.logoMask}>
              <EditOutlined className={styles.editLogoIcon} />
            </div>
            {logo && <ProgressivePicture {...logoProps} picture={logo} />}
          </button>
        )}
      </div>
      {showNav && (
        <div
          className={classnames(styles.nav, 'page-header__nav', {
            '--has-logo': !!uploadLogoProps || logo !== undefined,
          })}
        />
      )}

      {uploadLogoProps && (
        <PictureUploadModal
          onClose={() => setLogoModalIsOpen(false)}
          isOpen={logoModalIsOpen}
          {...uploadLogoProps}
        />
      )}
      {uploadCoverProps && (
        <PictureUploadModal
          onClose={() => setCoverModalIsOpen(false)}
          isOpen={coverModalIsOpen}
          {...uploadCoverProps}
        />
      )}
    </>
  );
};

export type PageHeaderProps = {
  className?: string;
  showNav?: boolean;
  cover?: ProgressivePictureFragment | null;
  coverProps?: Omit<Partial<ProgressivePictureProps>, 'className' | 'picture'>;
  logo?: ProgressivePictureFragment | null;
  logoProps?: Omit<Partial<ProgressivePictureProps>, 'className' | 'picture'>;
  uploadLogoProps?: Omit<
    PictureUploadModalProps,
    'fragment' | 'isOpen' | 'onClose'
  >;
  uploadCoverProps?: Omit<
    PictureUploadModalProps,
    'fragment' | 'isOpen' | 'onClose'
  >;
};
