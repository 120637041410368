import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import classnames from 'classnames';

import styles from './OrderInvoiceCard.module.css';

import {
  OrderInvoiceCardFragment,
  useOrderInvoiceCardGenerateInvoiceMutation,
} from './OrderInvoiceCard.generated';

export const OrderInvoiceCard: React.FC<OrderInvoiceCardProps> = (props) => {
  const { className, order } = props;
  const { t } = useTranslation('orders');

  const [generateInvoice, { loading }] =
    useOrderInvoiceCardGenerateInvoiceMutation({
      variables: { orderId: order.id },
    });

  return (
    <Card
      title={t('orderInvoiceCard.title')}
      extra={
        order.invoice?.file?.url ? (
          <Button
            className={classnames(className, styles.root)}
            type="primary"
            href={order.invoice.file.url}
            target="_blank"
          >
            {t('orderInvoiceCard.invoiceButton.label')}
          </Button>
        ) : (
          <Button
            className={classnames(className, styles.root)}
            type="primary"
            loading={loading}
            onClick={() => generateInvoice()}
          >
            {t('orderInvoiceCard.generate')}
          </Button>
        )
      }
      className={classnames(className, styles.root)}
    />
  );
};

export type OrderInvoiceCardProps = {
  className?: string;
  order: OrderInvoiceCardFragment;
};
