import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { CompanyFeesCollectionMethod } from '@graphql/generated/types';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectCompanyFeesCollectionMethod } from '@components/atoms/FormSelectCompanyFeesCollectionMethod/FormSelectCompanyFeesCollectionMethod';
import { FormSelectCurrency } from '@components/atoms/FormSelectCurrency/FormSelectCurrency';
import { FormSelectTaxRate } from '@components/atoms/FormSelectTaxRate/FormSelectTaxRate';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyFeesForm.module.css';

import {
  CompanyFeesFormCreateMutation,
  CompanyFeesFormCreateMutationVariables,
  CompanyFeesFormDeleteMutation,
  CompanyFeesFormDeleteMutationVariables,
  CompanyFeesFormFragment,
  CompanyFeesFormUpdateMutation,
  CompanyFeesFormUpdateMutationVariables,
  useCompanyFeesFormCreateMutation,
  useCompanyFeesFormDeleteMutation,
  useCompanyFeesFormUpdateMutation,
} from './CompanyFeesForm.generated';

export type CompanyFeesFormValues = {
  currency: string;
  flat: number;
  percent: number;
  min: number;
  max: number;
  collectionMethod: CompanyFeesCollectionMethod;
  taxRateId?: string | null;
};

export function CompanyFeesForm(props: CompanyFeesFormProps) {
  const { className, company, onCreated, onUpdated, onDeleted, ...rest } =
    props;
  const { t } = useTranslation('companyFees');

  const validationSchema = Yup.object({
    currency: Yup.string().required(),
    flat: Yup.number().required(),
    percent: Yup.number().required(),
    min: Yup.number().required(),
    max: Yup.number().required(),
    collectionMethod: Yup.mixed()
      .oneOf(Object.values(CompanyFeesCollectionMethod))
      .required(),
    taxRateId: Yup.string().nullable(),
  }).required();

  const form = useForm<CompanyFeesFormValues>({
    validationSchema,
    defaultValues: {
      currency: company.activeCompanyFees?.currency,
      flat: company.activeCompanyFees?.flat,
      percent: company.activeCompanyFees?.percent,
      min: company.activeCompanyFees?.min,
      max: company.activeCompanyFees?.max,
      collectionMethod: company.activeCompanyFees?.collectionMethod,
      taxRateId: company.activeCompanyFees?.taxRate?.id,
    },
  });

  const [createMutation, createMutationState] =
    useCompanyFeesFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useCompanyFeesFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useCompanyFeesFormDeleteMutation();

  const handleCreate = async (values: CompanyFeesFormValues) => {
    if (company.activeCompanyFees) return;

    const variables: CompanyFeesFormCreateMutationVariables = {
      companyId: company.id,
      input: {
        currency: values.currency,
        flat: values.flat,
        percent: values.percent,
        min: values.min,
        max: values.max,
        collectionMethod: values.collectionMethod,
        taxRateId: values.taxRateId,
      },
    };

    const { data } = await createMutation({ variables });

    if (onCreated && data) {
      onCreated(data.companyFeesCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: CompanyFeesFormValues) => {
    if (!company.activeCompanyFees) return;

    const variables: CompanyFeesFormUpdateMutationVariables = {
      companyFeesId: company.activeCompanyFees.id,
      input: {
        currency: values.currency,
        flat: values.flat,
        percent: values.percent,
        min: values.min,
        max: values.max,
        collectionMethod: values.collectionMethod,
        taxRateId: values.taxRateId,
      },
    };

    const { data } = await updateMutation({ variables });

    if (onUpdated && data) {
      onUpdated(data.companyFeesUpdate);
    }
  };

  const handleDelete = async () => {
    if (!company.activeCompanyFees) return;

    const variables: CompanyFeesFormDeleteMutationVariables = {
      companyFeesId: company.activeCompanyFees.id,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.companyFeesDelete);
    }
  };

  const handleSubmit: FormProps<CompanyFeesFormValues>['onValid'] = async (
    values,
  ) => {
    if (company.activeCompanyFees) {
      return handleUpdate(values);
    } else {
      return handleCreate(values);
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyFeesUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.currency}
          required
          label={t('companyFeesForm.fields.currency.label')}
          name="currency"
        >
          <FormSelectCurrency name="currency" />
        </FormItem>
        <FormItem
          className={styles.flat}
          required
          label={t('companyFeesForm.fields.flat.label')}
          name="flat"
        >
          <FormInputNumber name="flat" />
        </FormItem>
        <FormItem
          className={styles.percent}
          required
          label={t('companyFeesForm.fields.percent.label')}
          name="percent"
        >
          <FormInputNumber addonAfter="%" name="percent" />
        </FormItem>
        <FormItem
          className={styles.min}
          required
          label={t('companyFeesForm.fields.min.label')}
          name="min"
        >
          <FormInputNumber name="min" />
        </FormItem>
        <FormItem
          className={styles.max}
          required
          label={t('companyFeesForm.fields.max.label')}
          name="max"
        >
          <FormInput name="max" type="number" />
        </FormItem>
        <FormItem
          className={styles.collectionMethod}
          required
          label={t('companyFeesForm.fields.collectionMethod.label')}
          name="collectionMethod"
        >
          <FormSelectCompanyFeesCollectionMethod name="collectionMethod" />
        </FormItem>
        <FormItem
          className={styles.taxRate}
          required
          label={t('companyFeesForm.fields.taxRate.label')}
          name="taxRateId"
        >
          <FormSelectTaxRate
            name="taxRateId"
            clearable={true}
            allowClear={true}
          />
        </FormItem>

        <div className={styles.footer}>
          {!company.activeCompanyFees ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              {t('companyFeesForm.submits.create.label')}
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              {t('companyFeesForm.submits.update.label')}
            </Button>
          )}
          {!!company.activeCompanyFees && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette traduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                {t('companyFeesForm.submits.delete.label')}
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type CompanyFeesFormProps = ChildFormProps<
  CompanyFeesFormValues,
  void
> & {
  className?: string;
  company: CompanyFeesFormFragment;
  onCreated?: (v: CompanyFeesFormCreateMutation['companyFeesCreate']) => void;
  onUpdated?: (v: CompanyFeesFormUpdateMutation['companyFeesUpdate']) => void;
  onDeleted?: (v: CompanyFeesFormDeleteMutation['companyFeesDelete']) => void;
};
