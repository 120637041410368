import { Spin } from 'antd';
import classnames from 'classnames';

import { AuditTrailList } from '@components/organisms/AuditTrailList/AuditTrailList';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './CompanyIdTrailView.module.css';

import { useCompanyIdTrailViewQuery } from './CompanyIdTrailView.generated';

export const CompanyIdTrailView: React.FC<CompanyIdTrailViewProps> = (
  props,
) => {
  const { className, companyId } = props;

  const query = useCompanyIdTrailViewQuery({
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
  });

  const company = query?.data?.company;

  if (query.loading && !company) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!company) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <AuditTrailList auditTrailPagination={company.auditTrailPagination} />
    </div>
  );
};

export type CompanyIdTrailViewProps = {
  companyId: string;
  className?: string;
};
