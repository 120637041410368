import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormPicture } from '@atoms/FormPicture/FormPicture';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ArticleCategoryCreateForm.module.css';

import {
  ArticleCategoryCreateFormMutation,
  ArticleCategoryCreateFormMutationVariables,
  useArticleCategoryCreateFormMutation,
} from './ArticleCategoryCreateForm.generated';

// Note: Having no company ID means that the category is global
export type ArticleCategoryCreateFormValues = {
  localeId: string;
  name: string;
  coverId: string;
  companyId?: string;
};

export function ArticleCategoryCreateForm(
  props: ArticleCategoryCreateFormProps,
) {
  const { className, companyId, ...rest } = props;
  const { t } = useTranslation('common');

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    coverId: Yup.string().required(),
    companyId: Yup.string(),
  }).required();

  const form = useForm<ArticleCategoryCreateFormValues>({
    validationSchema,
    defaultValues: {
      localeId: 'fr',
    },
  });

  const [mutation] = useArticleCategoryCreateFormMutation();

  const handleSubmit: FormProps<ArticleCategoryCreateFormValues>['onValid'] =
    async (values) => {
      const variables: ArticleCategoryCreateFormMutationVariables = {
        input: {
          coverId: values.coverId,
          companyId: companyId,
          localeId: values.localeId,
          name: values.name,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ArticleCategoryCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.cover}
          required
          label="Couverture"
          name="coverId"
        >
          <FormPicture
            name="coverId"
            type={FileType.ArticleCategoryCover}
            info={t('picture.advice', {
              resolution: COVER_RESOLUTION_INFO,
              size: COVER_SIZE_INFO,
            })}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la catégorie
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ArticleCategoryCreateFormProps = ChildFormProps<
  ArticleCategoryCreateFormValues,
  ArticleCategoryCreateFormMutation
> & {
  className?: string;
  companyId?: string;
};
