import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { AdminCommentTargetType } from '@graphql/generated/types';

import { BooleanTag } from '@components/atoms/BooleanTag/BooleanTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { TableRendererAvatar } from '@components/molecules/TableRendererAvatar/TableRendererAvatar';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CompaniesTableQueryVariables,
  CompaniesTableRecordFragment,
  useCompaniesTableQuery,
} from './CompaniesTable.generated';

type RecordType = CompaniesTableRecordFragment;

type Column = 'logo' | 'name' | 'displayName' | 'membershipCount' | 'isDemo';

export function CompaniesTable(props: CompaniesTableProps) {
  const { className, sorter, filter, ...rest } = props;
  let { query } = props;

  const { t } = useTranslation(['companies', 'common']);

  if (!query) {
    query = useCompaniesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });
  }

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'logo',
      title: t('table.headers.logo'),
      dataIndex: ['logo'],
      width: 64,
      render: (logo, node) => (
        <TableRendererAvatar picture={logo} placeholder={node.name[0]} />
      ),
    },
    {
      key: 'name',
      title: t('table.headers.name'),
      sorter: true,
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/companies/list/${node.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, `name`, 'search'),
    },
    {
      key: 'email',
      title: t('table.headers.email'),
      sorter: true,
      dataIndex: ['email'],
      render: (email, node) => (
        <I18nLink copyable ellipsis={true} to={`/companies/list/${node.id}`}>
          {email}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `email`, 'search'),
    },
    {
      key: 'displayName',
      title: t('table.headers.displayName'),
      sorter: true,
      dataIndex: ['displayName'],
      render: (displayName, node) => (
        <I18nLink to={`/companies/list/${node.id}`}>{displayName}</I18nLink>
      ),
      ...generateColumnFilter(query, `displayName`, 'search'),
    },
    {
      key: 'membershipCount',
      title: t('table.headers.membershipCount'),
      sorter: true,
      dataIndex: ['membershipsPagination', 'totalCount'],
      ...generateColumnFilter(query, `membershipCount`, 'number'),
    },
    {
      key: 'isDemo',
      title: t('fields.isDemo'),
      ellipsis: true,
      render: (_, node) => <BooleanTag value={node.isDemo} />,
      ...generateColumnFilter(query, `isDemo`, 'boolean', [
        {
          label: t('common:table.filter.boolean.yes'),
          value: 'true',
        },
        {
          label: t('common:table.filter.boolean.no'),
          value: 'false',
        },
        {
          label: t('common:table.filter.boolean.all'),
          value: 'all',
        },
      ]),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CompaniesTable"
        query={query}
        columns={columns}
        data={query.data?.companiesPagination}
        title={() => (
          <I18nLink to="/companies/create">
            <Button>{t('table.title.createCompany')}</Button>
          </I18nLink>
        )}
        adminCommentTargetType={AdminCommentTargetType.Company}
        {...rest}
      />
    </div>
  );
}

export type CompaniesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: CompaniesTableQueryVariables['filter'];
  sorter?: CompaniesTableQueryVariables['sorter'];
};
