import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Popconfirm } from 'antd';

import { Modal } from '@components/organisms/Modal/Modal';
import {
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { OrderItemArticlesAddForm } from './OrderItemArticlesAddForm';
import {
  OrderItemArticleRecordFragment,
  useOrderitemArticlesPaginationTableQuery,
  useOrderitemArticlesPaginationTableRemoveArticleMutation,
} from './OrderItemArticlesPagination.generated';

type OrderItemArticlesPaginationProps = {
  orderItemId: string;
  className?: string;
};

type RecordType = OrderItemArticleRecordFragment;

export const OrderItemArticlesPagination = (
  props: OrderItemArticlesPaginationProps,
) => {
  const { orderItemId } = props;
  const { t } = useTranslation('orderItems');
  const [isModalVisible, setModalVisible] = useState(false);
  const [deletingArticleId, setDeletingArticleId] = useState<string | null>(
    null,
  );

  const query = useOrderitemArticlesPaginationTableQuery({
    variables: { orderItemId, skip: 0, take: 10 },
  });
  const [removeMutation] =
    useOrderitemArticlesPaginationTableRemoveArticleMutation();

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      dataIndex: ['file', 'originalName'],
      title: 'Nom',
      render: (name) => name || 'billet généré',
    },
    {
      key: 'download',
      dataIndex: 'id',
      title: 'Article',
      render: (_, node) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            node.url
              ? window.open(node.url.href, '_blank')
              : handleDownload(node.file?.url || '');
          }}
          type="dashed"
        >
          {t('table.button.attachment.label')}
        </Button>
      ),
    },
  ];

  const handleDelete = async (articleId: string) => {
    if (deletingArticleId) return;
    setDeletingArticleId(articleId);

    try {
      await removeMutation({
        variables: {
          orderItemId,
          input: {
            articleIds: [articleId],
          },
        },
      });
      query.refetch();
    } catch (err) {
      message.error((err as Error).message);
    } finally {
      setDeletingArticleId(null);
    }
  };

  return (
    <div>
      <TablePagination
        id="OrderItemArticlesTable"
        title={() => (
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Ajouter des articles
          </Button>
        )}
        // @ts-expect-error typing is garbage
        query={query}
        data={query.data?.orderItem?.productArticlePagination}
        columns={columns}
        activeColumns={['id', 'download']}
        action={(article) => (
          <Popconfirm
            onConfirm={() => handleDelete(article.id)}
            title="Attention cette action est irréversible"
          >
            <Button
              type="primary"
              danger
              loading={deletingArticleId === article.id}
            >
              Supprimer
            </Button>
          </Popconfirm>
        )}
      />
      <Modal
        isOpen={isModalVisible}
        onClose={() => setModalVisible(false)}
        title="Ajouter un article"
        form="OrderItemArticlesAddForm"
      >
        <OrderItemArticlesAddForm
          onSubmit={() => {
            setModalVisible(false);
            query.refetch();
          }}
          orderItemId={orderItemId}
        />
      </Modal>
    </div>
  );
};

const handleDownload = async (url: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = '';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      message.error('Impossible de télécharger le fichier');
      console.error(err);
    });
};
