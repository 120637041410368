import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'qs';

import {
  SubventionSchemaCreateForm,
  SubventionSchemaCreateFormProps,
} from '@components/forms/SubventionSchemaCreateForm/SubventionSchemaCreateForm';

export const SubventionSchemasCreateView = () => {
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation('subvention-schemas');

  const defaultValues = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const handleSuccess: SubventionSchemaCreateFormProps['onSuccess'] = (
    result,
  ) => {
    const subventionSchemaId =
      result.subventionSchemaCreate.subventionSchema.id;

    history.push(`/${i18n.language}/subventions/schemas/${subventionSchemaId}`);
  };

  return (
    <div>
      <SubventionSchemaCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
      />
    </div>
  );
};
