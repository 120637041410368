import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormSelectCategory } from '@components/atoms/FormSelectCategory/FormSelectCategory';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferUpdateCategoriesForm.module.css';

import {
  OfferUpdateCategoriesFormFragment,
  OfferUpdateCategoriesFormUpdateMutation,
  OfferUpdateCategoriesFormUpdateMutationVariables,
  useOfferUpdateCategoriesFormUpdateMutation,
} from './OfferUpdateCategoriesForm.generated';

export type OfferUpdateCategoriesFormValues = {
  categoryIds: string[];
};

export function OfferUpdateCategoriesForm(
  props: OfferUpdateCategoriesFormProps,
) {
  const { className, offer, ...rest } = props;

  const { t } = useTranslation('offers');

  const validationSchema = Yup.object({
    categoryIds: Yup.array(Yup.string().required()).min(1).required(),
  }).required();

  const categoryIds = offer.categoriesPagination.nodes.map(
    (category) => category.id,
  );

  const form = useForm<OfferUpdateCategoriesFormValues>({
    validationSchema,
    defaultValues: { categoryIds },
  });

  const [mutation] = useOfferUpdateCategoriesFormUpdateMutation();

  const handleSubmit: FormProps<OfferUpdateCategoriesFormValues>['onValid'] =
    async (values) => {
      if (!offer) return;

      const variables: OfferUpdateCategoriesFormUpdateMutationVariables = {
        offerId: offer.id,
        input: { categoryIds: values.categoryIds },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <div className={classnames(className, styles.root)}>
      <Form
        id="OfferUpdateCategoriesForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.categories}
          label={t('fields.categories')}
          name="categoryIds"
          required
        >
          <FormSelectCategory multiple name="categoryIds" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export type OfferUpdateCategoriesFormProps = ChildFormProps<
  OfferUpdateCategoriesFormValues,
  OfferUpdateCategoriesFormUpdateMutation
> & {
  className?: string;
  offer: OfferUpdateCategoriesFormFragment;
};
