import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { PublicationsTable } from '@components/tables/PublicationsTable/PublicationsTable';

import styles from './PublicationsView.module.css';

export const PublicationsView: React.FC<PublicationsViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('communication');

  return (
    <div className={classnames(className, styles.root)}>
      <PublicationsTable
        activeColumns={['title', 'author', 'target', 'status']}
        filter={{ company: { id: { is: null } } }}
        title={() => (
          <>
            <I18nLink
              to={{
                pathname: `/communication/publications/create`,
              }}
            >
              <Button>
                {t('publications.table.create', 'Ajouter une publication')}
              </Button>
            </I18nLink>
          </>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/communication/publications/create`,
              }}
            >
              <Button>
                {t('publications.table.create', 'Ajouter une publication')}
              </Button>
            </I18nLink>
          ),
        }}
      />
    </div>
  );
};

export type PublicationsViewProps = {
  className?: string;
};
