import { useTranslation } from 'react-i18next';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { AdminCommentTargetType, OrderStatus } from '@graphql/generated/types';

import { CampaignList } from '@components/atoms/CampaignList/CampaignList';
import { MangopayTransferStatusTag } from '@components/atoms/MangopayTransferStatusTag/MangopayTransferStatusTag';
import { MangopayTransferStatusTagFragment } from '@components/atoms/MangopayTransferStatusTag/MangopayTransferStatusTag.generated';
import { OrderStatusTag } from '@components/atoms/OrderStatusTag/OrderStatusTag';
import { OrderProductArticleDownload } from '@components/molecules/OrderProductArticleDownload/OrderProductArticleDownload';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  OrdersTableQueryVariables,
  OrdersTableRecordFragment,
  useOrdersTableQuery,
} from './OrdersTable.generated';

type RecordType = OrdersTableRecordFragment;

type Column =
  | 'invoice.identifier'
  | 'user'
  | 'amount'
  | 'status'
  | 'mangopayTransfers'
  | 'campaigns';

export function OrdersTable(props: OrdersTableProps) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    sorter,
    ...rest
  } = props;

  const { t } = useTranslation('orders');

  const query =
    propQuery ||
    useOrdersTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'invoice.identifier',
      title: t('table.headers.invoice.identifier'),
      dataIndex: ['invoice', 'identifier'],
      ellipsis: true,
      render: (name, node) => (
        <I18nLink to={`/orders/list/${node.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, `invoice.identifier`, 'search'),
    },
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
      ellipsis: true,
      render: (name, node) => (
        <I18nLink to={`/orders/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'user',
      title: t('table.headers.user'),
      dataIndex: ['user', 'email'],
      render: (name, node) =>
        node.user ? (
          <I18nLink copyable ellipsis={true} to={`/users/list/${node.user.id}`}>
            {name}
          </I18nLink>
        ) : (
          'N/A'
        ),
      ...generateColumnFilter(query, 'user.email', 'search'),
    },
    {
      key: 'amountPaid',
      title: t('table.headers.amountPaid'),
      render: (_, { paymentIntent, currency }) => (
        <TableRendererCurrency
          value={{
            amount: paymentIntent?.amountReceived ?? 0,
            currency,
          }}
        />
      ),
    },
    {
      key: 'amountPurchased',
      title: t('table.headers.amountPurchased'),
      render: (
        _,
        { paymentIntent, subventionsAmount, discountsAmount, currency },
      ) => (
        <TableRendererCurrency
          value={{
            amount: paymentIntent?.amountReceived
              ? paymentIntent.amountReceived +
                subventionsAmount +
                discountsAmount
              : 0,
            currency,
          }}
        />
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      render: (_, node) => <OrderStatusTag order={node} />,
      ...generateColumnFilter(query, `status`, 'enum', [
        {
          value: OrderStatus.Canceled,
          label: t('orders:status.canceled'),
        },
        {
          value: OrderStatus.Draft,
          label: t('orders:status.draft'),
        },
        {
          value: OrderStatus.Paid,
          label: t('orders:status.paid'),
        },
        {
          value: OrderStatus.DeliveredFull,
          label: t('orders:status.deliveredFull'),
        },
        {
          value: OrderStatus.DeliveredPartial,
          label: t('orders:status.deliveredPartial'),
        },
        {
          value: OrderStatus.RefundedFull,
          label: t('orders:status.refundedFull'),
        },
        {
          value: OrderStatus.RefundedPartial,
          label: t('orders:status.refundedPartial'),
        },
      ]),
    },
    {
      key: 'mangopayTransfers',
      title: t('table.headers.mangopayTransfers'),
      dataIndex: ['mangopayTransferPagination', 'nodes'],
      ellipsis: true,
      render: (mangopayTransfers: MangopayTransferStatusTagFragment[]) =>
        mangopayTransfers.map((mangopayTransfer) => (
          <MangopayTransferStatusTag
            key={mangopayTransfer.id}
            MangopayTransfer={mangopayTransfer}
          />
        )),
    },
    {
      key: 'campaigns',
      title: t('table.headers.campaigns'),
      dataIndex: ['campaigns'],
      render: (campaigns) => <CampaignList campaigns={campaigns} />,
      ...generateColumnFilter(query, 'campaign.name', 'search'),
    },
    {
      key: 'attachments',
      title: t('table.headers.attachments'),
      dataIndex: ['orderItemPagination'],
      render: (orderItems, node) => {
        if (node.orderItemPagination.totalCount === 0) {
          return null;
        }
        return (
          <OrderProductArticleDownload orderItemsPagination={orderItems} />
        );
      },
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="OrdersTable"
        query={query}
        columns={columns}
        data={propData || query.data?.orderPagination}
        adminCommentTargetType={AdminCommentTargetType.Order}
        {...rest}
      />
    </div>
  );
}

export type OrdersTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  filter?: OrdersTableQueryVariables['filter'];
  sorter?: OrdersTableQueryVariables['sorter'];
};
