import * as Yup from 'yup';

import { CatalogViewSelectionType } from '../../CatalogView';

import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelectBrand } from '@components/atoms/FormSelectBrand/FormSelectBrand';
import { FormSelectCampaign } from '@components/atoms/FormSelectCampaign/FormSelectCampaign';
import { FormSelectCategory } from '@components/atoms/FormSelectCategory/FormSelectCategory';
import { FormSelectOffer } from '@components/atoms/FormSelectOffer/FormSelectOffer';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

export type CatalogEntitySelectionFormValues = {
  entityIds: string[];
};

export type CatalogEntitySelectionFormProps = ChildFormProps<
  CatalogEntitySelectionFormValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> & {
  hideFooter?: boolean;
  selectionType: CatalogViewSelectionType;
  formItemLabel: string;
};

export const CatalogEntitySelectionForm = (
  props: CatalogEntitySelectionFormProps,
) => {
  const { selectionType, formItemLabel, hideFooter, onSubmit, ...rest } = props;

  const selectComponent = (() => {
    switch (selectionType) {
      case 'excludedCategory':
        return <FormSelectCategory name="entityIds" multiple />;
      case 'excludedBrand':
      case 'includedBrand':
        return <FormSelectBrand name="entityIds" multiple />;
      case 'excludedCampaign':
      case 'includedCampaign':
        return <FormSelectCampaign name="entityIds" multiple />;
      case 'excludedOffer':
      case 'includedOffer':
        return <FormSelectOffer name="entityIds" multiple />;
      default:
        return <div>Unsupported</div>;
    }
  })();

  const form = useForm<CatalogEntitySelectionFormValues>({
    validationSchema: Yup.object({
      entityIds: Yup.array()
        .of(Yup.string().uuid().required())
        .required()
        .min(1),
    }).required(),
  });

  const onValid: FormProps<CatalogEntitySelectionFormValues>['onValid'] =
    async (values) => {
      onSubmit?.(values);
    };

  return (
    <Form<CatalogEntitySelectionFormValues>
      form={form}
      onValid={onValid}
      id="CatalogEntitySelectionForm"
      {...rest}
    >
      <FormItem required label={formItemLabel} name="entityIds">
        {selectComponent}
      </FormItem>
    </Form>
  );
};
