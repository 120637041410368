import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { Modal } from '@components/organisms/Modal/Modal';
import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';

import styles from './CompanySelectModal.module.css';

export const CompanySelectModal = (props: CompanySelectModalProps) => {
  const { exclude = [], isOpen, onSubmit } = props;
  const [submitLoading, setLoading] = useState(false);

  const [companyIds, setCompanyIds] = useState<React.Key[]>([]);

  const { t } = useTranslation('companies');

  const handleSubmit = async () => {
    if (!onSubmit) return;

    try {
      await onSubmit?.(companyIds.map((id) => id.toString()));
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title=""
      onClose={console.log}
      width="100%"
      footer={
        <Button type="primary" loading={submitLoading} onClick={handleSubmit}>
          Sélectionner
        </Button>
      }
      className={styles.root}
    >
      <CompaniesTable
        activeColumns={['name']}
        selectable={{
          type: 'checkbox',
          onChange: setCompanyIds,
          value: companyIds,
          isDisabled: (company) => exclude.includes(company.id),
        }}
        title={() => t('companiesSelectedCount', { count: companyIds.length })}
      />
    </Modal>
  );
};

export type CompanySelectModalProps = {
  onSubmit?: (companyIds: string[]) => void | Promise<void>;
  multiple?: boolean;
  exclude?: string[];
  isOpen: boolean;
};
