import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import * as Yup from 'yup';

import { OrderItemStatus } from '@graphql/generated/types';

import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import {
  OrderItemStatusUpdateFormMutation,
  OrderItemStatusUpdateFormMutationVariables,
  OrderItemUpdateFormFragment,
  useOrderItemStatusUpdateFormMutation,
} from './OrderStatusUpdateForm.generated';

export type OrderItemStatusUpdateFormValues = {
  status: OrderItemStatus;
};

export const OrderItemStatusUpdateForm = (
  props: OrderStatusUpdateFormProps,
) => {
  const { orderItem, ...rest } = props;

  const { t } = useTranslation('orderItems');

  const validationSchema = Yup.object({
    status: Yup.mixed<OrderItemStatus>()
      .oneOf(Object.values(OrderItemStatus))
      .required(),
  }).required();

  const form = useForm<OrderItemStatusUpdateFormValues>({
    validationSchema,
    defaultValues: {
      status: orderItem.status,
    },
  });

  const [mutation] = useOrderItemStatusUpdateFormMutation();

  const handleSubmit: FormProps<OrderItemStatusUpdateFormValues>['onValid'] =
    async (values) => {
      const variables: OrderItemStatusUpdateFormMutationVariables = {
        orderItemId: orderItem.id,
        status: values.status,
      };

      const { data } = await mutation({
        variables,
        refetchQueries: ['OrderIdView'],
      });

      return data;
    };

  const orderItemStatusType: { [key in OrderItemStatus]?: string } = {
    CANCELED: t('status.canceled'),
    CREATED: t('status.created'),
    DELIVERED_FULL: t('status.deliveredFull'),
    DELIVERED_PARTIAL: t('status.deliveredPartial'),
    DRAFT: t('status.draft'),
    PROCESSING: t('status.processing'),
    REFUNDED_FULL: t('status.refundedFull'),
    REFUNDED_PARTIAL: t('status.refundedPartial'),
  };
  const options = Object.entries(orderItemStatusType).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  return (
    <Form
      onValid={handleSubmit}
      id="OrderStatusUpdateForm"
      layout="inline"
      form={form}
      {...rest}
    >
      <FormItem required label="Status" name="status">
        <FormSelect name="status" options={options} />
      </FormItem>
      <Button
        loading={form.formState.isSubmitting}
        type="primary"
        htmlType="submit"
      >
        Submit
      </Button>
    </Form>
  );
};

export type OrderStatusUpdateFormProps = ChildFormProps<
  OrderItemStatusUpdateFormValues,
  OrderItemStatusUpdateFormMutation
> & {
  className?: string;
  orderItem: OrderItemUpdateFormFragment;
};
