import { Spin } from 'antd';
import classnames from 'classnames';

import { BeneficiariesTable } from '@components/tables/BeneficiariesTable/BeneficiariesTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './UserIdBeneficiariesView.module.css';

import { useUserIdBeneficiariesViewQuery } from './UserIdBeneficiariesView.generated';

export const UserIdBeneficiariesView: React.FC<UserIdBeneficiariesViewProps> = (
  props,
) => {
  const { className, userId } = props;

  const query = useUserIdBeneficiariesViewQuery({
    variables: {
      userId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = query?.data?.user;

  if (query.loading && !user) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!user) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <BeneficiariesTable
        activeColumns={['id', 'firstName', 'lastName', 'birthDate']}
        query={query}
        data={query.data?.user?.beneficiariesPagination}
      />
    </div>
  );
};

export type UserIdBeneficiariesViewProps = {
  userId: string;
  className?: string;
};
