import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './QuestionCategoryCreateForm.module.css';

import {
  QuestionCategoryCreateFormMutation,
  QuestionCategoryCreateFormMutationVariables,
  useQuestionCategoryCreateFormMutation,
} from './QuestionCategoryCreateForm.generated';

export type QuestionCategoryCreateFormValues = {
  companyId: string;
  name: string;
  description?: string;
  active?: boolean;
};

export function QuestionCategoryCreateForm(
  props: QuestionCategoryCreateFormProps,
) {
  const { className, defaultValues, hideFooter, ...rest } = props;
  const { t } = useTranslation('questionCategory');

  const validationSchema = Yup.object({
    companyId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.string().optional(),
    active: Yup.boolean().optional(),
  }).required();

  const form = useForm<QuestionCategoryCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useQuestionCategoryCreateFormMutation();

  const handleSubmit: FormProps<QuestionCategoryCreateFormValues>['onValid'] =
    async (values) => {
      const variables: QuestionCategoryCreateFormMutationVariables = {
        companyId: values.companyId,
        input: {
          name: values.name,
          description: values.description,
          active: !!values.active,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="QuestionCategoryCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.company}
          required
          label={t('fields.company')}
          name="companyId"
        >
          <FormSelectCompany name="companyId" />
        </FormItem>

        <FormItem
          className={styles.name}
          required
          label={t('fields.name')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.description}
          required
          label={t('fields.description')}
          name="description"
        >
          <FormInput name="description" />
        </FormItem>

        <FormItem
          className={styles.active}
          required
          label={t('fields.active')}
          name="active"
        >
          <FormCheckbox name="active" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type QuestionCategoryCreateFormProps = ChildFormProps<
  QuestionCategoryCreateFormValues,
  QuestionCategoryCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<QuestionCategoryCreateFormValues>;
  hideFooter?: boolean;
};
