import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { CampaignSelectionStatus } from '@graphql/generated/types';

import styles from './CampaignSelectionStatusTag.module.css';

export const CampaignSelectionStatusTag: React.FC<CampaignSelectionStatusTagProps> =
  (props) => {
    const { className, status } = props;
    const { t } = useTranslation('campaignSelections');

    const options: Record<
      CampaignSelectionStatus,
      {
        label: string;
        color: string;
      }
    > = {
      DRAFT: { label: t('status.draft'), color: 'default' },
      PUBLISHED: { label: t('status.published'), color: 'success' },
      UNPUBLISHED: { label: t('status.unpublished'), color: 'error' },
    };

    return (
      <Tag
        className={classnames(className, styles.root)}
        color={options[status].color}
      >
        {options[status].label}
      </Tag>
    );
  };

export type CampaignSelectionStatusTagProps = {
  className?: string;
  status: CampaignSelectionStatus;
};
