import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import classnames from 'classnames';

import { NotFoundView } from '../../Errors/NotFoundView/NotFoundView';

import { CompanyFeesModal } from '@components/modals/CompanyFeesModal/CompanyFeesModal';
import { CompanyFeesTable } from '@components/tables/CompanyFeesTable/CompanyFeesTable';

import styles from './CompanyIdFeesView.module.css';

import {
  useCompanyIdFeesDeleteMutation,
  useCompanyIdFeesViewQuery,
} from './CompanyIdFeesView.generated';

export const CompanyIdFeesView: React.FC<CompanyIdFeesViewProps> = (props) => {
  const { className, companyId } = props;
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation('companyFees');

  const [deleteMutation] = useCompanyIdFeesDeleteMutation();

  const query = useCompanyIdFeesViewQuery({
    variables: { companyId, take: 50, skip: 0 },
    notifyOnNetworkStatusChange: true,
  });

  const handleDelete = (companyFeesId: string) => async () => {
    await deleteMutation({
      variables: { companyFeesId },
    });
    await query.refetch();
  };

  const company = query?.data?.company;

  if (query.loading && !company) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!company) {
    return <NotFoundView />;
  }

  const renderedActions = () => (
    <>
      <Button type="default" onClick={() => setOpen(true)}>
        {company.activeCompanyFees
          ? t('buttons.update.label')
          : t('buttons.create.label')}
      </Button>
      {company.activeCompanyFees && (
        <Popconfirm
          title={t('buttons.delete.confirm')}
          okText={t('buttons.delete.ok')}
          okType="danger"
          onConfirm={handleDelete(company.activeCompanyFees.id)}
        >
          <Button type="default" danger>
            {t('buttons.delete.label')}
          </Button>
        </Popconfirm>
      )}
    </>
  );

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title level={3}>
        {t('companyIdFeesView.title')}
      </Typography.Title>
      <CompanyFeesTable
        title={() => renderedActions()}
        query={query}
        data={query.data?.company?.companyFeesPagination}
        activeColumns={[
          'createdAt',
          'flat',
          'percent',
          'min',
          'max',
          'collectionMethod',
          'deletedAt',
          'deletedBy',
        ]}
        companyId={companyId}
      />
      <CompanyFeesModal
        company={company}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
      />
    </div>
  );
};

export type CompanyIdFeesViewProps = {
  companyId: string;
  className?: string;
};
