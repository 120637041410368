import { useTranslation } from 'react-i18next';

import { CampaignSelectionStatusTag } from '@components/atoms/CampaignSelectionStatusTag/CampaignSelectionStatusTag';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CampaignSelectionsTableQueryVariables,
  CampaignSelectionsTableRecordFragment,
  useCampaignSelectionsTableQuery,
} from './CampaignSelectionsTable.generated';

type RecordType = CampaignSelectionsTableRecordFragment;

type Column = 'name' | 'status' | 'campaignCount';

export function CampaignSelectionsTable(props: CampaignSelectionsTableProps) {
  const { className, filter, sorter, ...rest } = props;

  const { t } = useTranslation('campaignSelections');

  const query = useCampaignSelectionsTableQuery({
    variables: {
      skip: 0,
      take: 10,
      filter,
      sorter,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/campaign-selections/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      sorter: true,
      render: (status) => <CampaignSelectionStatusTag status={status} />,
    },
    {
      key: 'campaignCount',
      title: t('table.headers.campaignCount'),
      dataIndex: ['campaignPagination', 'totalCount'],
      sorter: true,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CampaignSelectionsTable"
        query={query}
        columns={columns}
        data={query.data?.campaignSelectionsPagination}
        {...rest}
      />
    </div>
  );
}

export type CampaignSelectionsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: CampaignSelectionsTableQueryVariables['filter'];
  sorter?: CampaignSelectionsTableQueryVariables['sorter'];
};
