import { Tooltip } from 'antd';
import cx from 'classnames';
import Dinero, { Currency } from 'dinero.js';

import styles from './AmountRepartitionBar.module.css';

type AmountRepartitionBarProps = {
  values: {
    amount: number;
    currency: Currency;
    label: string;
    color: string;
  }[];
  className?: string;
};

export const AmountRepartitionBar = (props: AmountRepartitionBarProps) => {
  const { values, className } = props;

  return (
    <div className={cx(styles.root, className)}>
      {values
        .filter((v) => v.amount > 0)
        .map((value, index) => {
          const width =
            (value.amount /
              props.values.reduce((acc, value) => acc + value.amount, 0)) *
            100;

          return (
            <Tooltip
              key={index}
              title={`${value.label} : ${Dinero({
                amount: value.amount,
                currency: value.currency,
              }).toFormat()}`}
            >
              <div
                key={index}
                className={styles.bar}
                style={{ width: `${width}%`, backgroundColor: value.color }}
              />
            </Tooltip>
          );
        })}
    </div>
  );
};
