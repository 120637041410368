import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { PicturesStockTable } from '@components/tables/PicturesStockTable/PicturesStockTable';

import styles from './PicturesStockView.module.css';

export const PicturesStockView: React.FC<ProductArticlesViewProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation('picturesStock');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('picturesStock.title')}</Typography.Title>
      <PicturesStockTable activeColumns={['picture', 'id']} />
    </div>
  );
};

export type ProductArticlesViewProps = {
  className?: string;
};
