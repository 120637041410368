import { useState } from 'react';
import { Collapse, Spin } from 'antd';

import { Typography } from '@components/atoms/Typography/Typography';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './CatalogView.module.css';

import { useCatalogViewQuery } from './CatalogView.generated';
import { CatalogCreateForm } from './components/CatalogCreateForm/CatalogCreateForm';
import { CatalogEntitySelection } from './components/CatalogEntitySelection/CatalogEntitySelection';
import { CatalogEntitySelectionHeader } from './components/CatalogEntitySelectionHeader/CatalogEntitySelectionHeader';
import { CatalogEntitySelectionModal } from './components/CatalogEntitySelectionModal/CatalogEntitySelectionModal';
import { CatalogHeader } from './components/CatalogHeader/CatalogHeader';

type CatalogViewProps = {
  companyId: string;
};

export type CatalogViewSelectionType =
  | 'excludedCategory'
  | 'excludedBrand'
  | 'excludedCampaign'
  | 'excludedOffer'
  | 'includedBrand'
  | 'includedCampaign'
  | 'includedOffer'
  | null;

export const CatalogView = (props: CatalogViewProps) => {
  const { companyId } = props;

  const [modal, setModal] = useState<CatalogViewSelectionType>(null);
  const { data, loading, refetch } = useCatalogViewQuery({
    variables: { companyId },
  });
  const { company } = data || {};

  if (!company && loading) return <Spin />;
  if (!company) return <NotFoundView />;

  const { catalog } = company;

  if (!catalog) return <CatalogCreateForm companyId={companyId} />;

  const {
    excludedCategoryPagination,
    excludedBrandPagination,
    excludedCampaignPagination,
    excludedOfferPagination,
    includedBrandPagination,
    includedCampaignPagination,
    includedOfferPagination,
  } = catalog;

  const exclusions = [
    {
      name: 'Catégories exclues',
      cta: 'Exclure une catégorie',
      count: excludedCategoryPagination?.totalCount,
      type: 'excludedCategory' as const,
      onCtaClick: () => setModal('excludedCategory'),
    },
    {
      name: 'Marques exclues',
      cta: 'Exclure une marque',
      count: excludedBrandPagination?.totalCount,
      type: 'excludedBrand' as const,
      onCtaClick: () => setModal('excludedBrand'),
    },
    {
      name: 'Campagnes exclues',
      cta: 'Exclure une campagne',
      count: excludedCampaignPagination?.totalCount,
      type: 'excludedCampaign' as const,
      onCtaClick: () => setModal('excludedCampaign'),
    },
    {
      name: 'Offres exclues',
      cta: 'Exclure une offre',
      count: excludedOfferPagination?.totalCount,
      type: 'excludedOffer' as const,
      onCtaClick: () => setModal('excludedOffer'),
    },
  ];

  const inclusions = [
    {
      name: 'Marques inclues',
      cta: 'Inclure une marque',
      count: includedBrandPagination?.totalCount,
      type: 'includedBrand' as const,
      onCtaClick: () => setModal('includedBrand'),
    },
    {
      name: 'Campagnes inclues',
      cta: 'Inclure une campagne',
      count: includedCampaignPagination?.totalCount,
      type: 'includedCampaign' as const,
      onCtaClick: () => setModal('includedCampaign'),
    },
    {
      name: 'Offres inclues',
      cta: 'Inclure une offre',
      count: includedOfferPagination?.totalCount,
      type: 'includedOffer' as const,
      onCtaClick: () => setModal('includedOffer'),
    },
  ];

  const modalProps: Record<
    Exclude<CatalogViewSelectionType, null>,
    { title: string; formItemLabel: string }
  > = {
    excludedBrand: {
      formItemLabel: 'Marques',
      title: 'Exclure une ou plusieurs marques',
    },
    excludedCategory: {
      formItemLabel: 'Catégories',
      title: 'Exclure une ou plusieurs catégories',
    },
    excludedCampaign: {
      formItemLabel: 'Campagnes',
      title: 'Exclure une ou plusieurs campagnes',
    },
    excludedOffer: {
      formItemLabel: 'Offres',
      title: 'Exclure une ou plusieurs offres',
    },
    includedBrand: {
      formItemLabel: 'Marques',
      title: 'Inclure une ou plusieurs marques',
    },
    includedCampaign: {
      formItemLabel: 'Campagnes',
      title: 'Inclure une ou plusieurs campagnes',
    },
    includedOffer: {
      formItemLabel: 'Offres',
      title: 'Inclure une ou plusieurs offres',
    },
  };

  return (
    <div className={styles.root}>
      <CatalogHeader catalog={catalog} />
      <br />
      <div className={styles.sections}>
        <div className={styles.section}>
          <Typography.Title level={4}>Exclusions</Typography.Title>
          <Collapse className={styles.collapse}>
            {exclusions.map(({ name, count, cta, onCtaClick, type }, i) => (
              <Collapse.Panel
                key={i}
                header={
                  <CatalogEntitySelectionHeader
                    cta={cta}
                    label={name}
                    count={count}
                    onCtaClick={onCtaClick}
                  />
                }
              >
                <CatalogEntitySelection companyId={companyId} type={type} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
        <div className={styles.section}>
          <Typography.Title level={4}>Inclusions</Typography.Title>
          <Collapse className={styles.collapse}>
            {inclusions.map(({ name, count, cta, onCtaClick, type }, i) => (
              <Collapse.Panel
                key={i}
                header={
                  <CatalogEntitySelectionHeader
                    cta={cta}
                    label={name}
                    count={count}
                    onCtaClick={onCtaClick}
                  />
                }
              >
                <CatalogEntitySelection companyId={companyId} type={type} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <CatalogEntitySelectionModal
        onClose={() => setModal(null)}
        selectionType={modal}
        catalogId={catalog.id}
        onSuccess={() => {
          refetch({ companyId });
        }}
        {...(modal === null
          ? {
              title: '',
              formItemLabel: '',
            }
          : modalProps[modal])}
      />
    </div>
  );
};
