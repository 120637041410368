import { Spin } from 'antd';
import classnames from 'classnames';

import { ArticlesTable } from '@components/tables/ArticlesTable/ArticlesTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ArticlesCategoryIdArticlesView.module.css';

import { useArticlesCategoryIdArticlesViewQuery } from './ArticlesCategoryIdArticlesView.generated';

export const ArticlesCategoryIdArticlesView: React.FC<ArticlesCategoryIdArticlesViewProps> =
  (props) => {
    const { className, articleCategoryId } = props;

    const query = useArticlesCategoryIdArticlesViewQuery({
      variables: {
        articleCategoryId,
        take: 10,
        skip: 0,
      },
      notifyOnNetworkStatusChange: true,
    });

    const category = query?.data?.articleCategory;

    if (query.loading && !category) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!category) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <ArticlesTable
          activeColumns={['title', 'author', 'category']}
          query={query}
          data={query.data?.articleCategory?.articlesPagination}
        />
      </div>
    );
  };

export type ArticlesCategoryIdArticlesViewProps = {
  className?: string;
  articleCategoryId: string;
};
