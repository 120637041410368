import { ApolloError } from '@apollo/client';
import { message } from 'antd';

import { CatalogViewSelectionType } from '../../CatalogView';
import {
  CatalogEntitySelectionForm,
  CatalogEntitySelectionFormProps,
} from '../CatalogEntitySelectionForm/CatalogEntitySelectionForm';

import { Modal } from '@components/organisms/Modal/Modal';

import {
  useCatalogBrandExcludeAddMutation,
  useCatalogBrandIncludeAddMutation,
  useCatalogCampaignExcludeAddMutation,
  useCatalogCampaignIncludeAddMutation,
  useCatalogCategoryExcludeAddMutation,
  useCatalogOfferExcludeAddMutation,
  useCatalogOfferIncludeAddMutation,
} from './CatalogEntitySelectionModal.generated';

type CatalogEntitySelectionModalProps = CatalogEntitySelectionFormProps & {
  onClose: () => void;
  selectionType: CatalogViewSelectionType;
  title: string;
  formItemLabel: string;
  catalogId: string;
};

export const CatalogEntitySelectionModal = (
  props: CatalogEntitySelectionModalProps,
) => {
  const {
    selectionType,
    onClose,
    title,
    formItemLabel,
    catalogId,
    onSuccess,
    onFailure,
  } = props;

  const [categoryExcludeMutation] = useCatalogCategoryExcludeAddMutation();
  const [brandExcludeMutation] = useCatalogBrandExcludeAddMutation();
  const [campaignExcludeMutation] = useCatalogCampaignExcludeAddMutation();
  const [offerExcludeMutation] = useCatalogOfferExcludeAddMutation();
  const [brandIncludeMutation] = useCatalogBrandIncludeAddMutation();
  const [campaignIncludeMutation] = useCatalogCampaignIncludeAddMutation();
  const [offerIncludeMutation] = useCatalogOfferIncludeAddMutation();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (values: { entityIds: string[] }) => {
    let method;

    switch (selectionType) {
      case 'excludedCategory':
        method = categoryExcludeMutation;
        break;
      case 'excludedBrand':
        method = brandExcludeMutation;
        break;
      case 'excludedCampaign':
        method = campaignExcludeMutation;
        break;
      case 'excludedOffer':
        method = offerExcludeMutation;
        break;
      case 'includedBrand':
        method = brandIncludeMutation;
        break;
      case 'includedCampaign':
        method = campaignIncludeMutation;
        break;
      case 'includedOffer':
        method = offerIncludeMutation;
        break;
      default:
        throw new Error('Unsupported selection type');
    }

    try {
      await method({
        variables: { catalogId, entityIds: values.entityIds },
        refetchQueries: [
          'CatalogView',
          'CatalogExcludedCategories',
          'CatalogExcludedBrands',
          'CatalogExcludedCampaigns',
          'CatalogExcludedOffers',
          'CatalogIncludedBrands',
          'CatalogIncludedCampaigns',
          'CatalogIncludedOffers',
        ],
        awaitRefetchQueries: true,
      });
      onSuccess?.({}, values);
    } catch (err) {
      if (err instanceof Error) {
        message.error(err);
      } else {
        message.error('Something went wrong');
        console.log(err);
      }

      onFailure?.(err as ApolloError, values);
    }
  };

  return (
    <Modal
      isOpen={selectionType !== null}
      onClose={handleClose}
      title={title}
      form="CatalogEntitySelectionForm"
    >
      <CatalogEntitySelectionForm
        selectionType={selectionType}
        formItemLabel={formItemLabel}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
