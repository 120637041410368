import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectCurrency } from '@components/atoms/FormSelectCurrency/FormSelectCurrency';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductVariantCreateForm.module.css';

import {
  ProductVariantCreateFormMutation,
  useProductVariantCreateFormMutation,
} from './ProductVariantCreateForm.generated';

export type ProductVariantCreateFormValues = {
  SKU: string;
  priceCurrency: string;
  priceValue: number;
};

export function ProductVariantCreateForm(props: ProductVariantCreateFormProps) {
  const { className, productId, defaultValues, hideFooter, ...rest } = props;
  const { t } = useTranslation('productVariants');

  const validationSchema = Yup.object({
    SKU: Yup.string().required(),
    priceCurrency: Yup.string().required(),
    priceValue: Yup.number().min(0).required(),
  }).required();

  const form = useForm<ProductVariantCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useProductVariantCreateFormMutation();

  const handleSubmit: FormProps<ProductVariantCreateFormValues>['onValid'] =
    async (values) => {
      try {
        const { data } = await mutation({
          variables: {
            productId,
            input: {
              SKU: values.SKU,
              priceCurrency: values.priceCurrency,
              priceValue: values.priceValue,
            },
          },
        });

        return data;
      } catch (err) {
        if (err instanceof ApolloError)
          message.error(err.message.replace('GraphQL error: ', ''));
        else message.error('Une erreur est survenue');

        throw err;
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductVariantCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem className={styles.SKU} label={t('fields.SKU')} name="SKU">
          <FormInput name="SKU" />
        </FormItem>

        <FormItem
          className={styles.priceCurrency}
          label={t('fields.priceCurrency')}
          name="priceCurrency"
        >
          <FormSelectCurrency name="priceCurrency" />
        </FormItem>

        <FormItem
          className={styles.priceValue}
          label={t('fields.priceValue')}
          name="priceValue"
        >
          <FormInputNumber name="priceValue" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type ProductVariantCreateFormProps = ChildFormProps<
  ProductVariantCreateFormValues,
  ProductVariantCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<ProductVariantCreateFormValues>;
  hideFooter?: boolean;
  productId: string;
};
