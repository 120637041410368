import { Spin } from 'antd';
import classnames from 'classnames';

import { NotFoundView } from '../../Errors/NotFoundView/NotFoundView';

import { Company } from '@graphql/generated/types';

import { QuestionCategoryTable } from '@components/tables/QuestionCategoryTable/QuestionCategoryTable';

import styles from './CompanyIdQuestionCategoryView.module.css';

import { useCompanyIdQuestionCategoryViewQuery } from './CompanyIdQuestionCategoryView.generated';

export const CompanyIdQuestionCategoryView: React.FC<CompanyIdQuestionCategoryViewProps> =
  (props) => {
    const { className, companyId } = props;

    const query = useCompanyIdQuestionCategoryViewQuery({
      variables: { companyId, take: 50, skip: 0 },
      notifyOnNetworkStatusChange: true,
    });

    const company = query?.data?.company;

    if (query.loading && !company) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!company) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <QuestionCategoryTable
          query={query}
          data={query.data?.company?.questionCategoryPagination}
          activeColumns={[
            'createdAt',
            'name',
            'description',
            'active',
            'actions',
          ]}
          companyId={companyId}
        />
      </div>
    );
  };

export type CompanyIdQuestionCategoryViewProps = {
  companyId: Company['id'];
  className?: string;
};
