import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { GameAccessView } from '../GameAccessView/GameAccessView';
import { GameUpdateView } from '../GameUpdateView/GameUpdateView';

import { GameHeader } from '@components/organisms/GameHeader/GameHeader';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './GameIdView.module.css';

import { useGameIdViewQuery } from './GameIdView.generated';

export const GameIdView: React.FC<GameIdViewProps> = (props) => {
  const { className, match } = props;

  const {
    params: { gameId },
  } = match;

  const { t } = useTranslation('games');
  const GameQuery = useGameIdViewQuery({
    variables: { gameId },
    notifyOnNetworkStatusChange: true,
  });

  const game = GameQuery?.data?.game;

  if (GameQuery.loading && !game) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!game) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <GameHeader game={game} />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label={t('GameIdView.tabs.details')}
        >
          Details WIP
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/update`}
          exact
          label={t('GameIdView.tabs.update')}
        >
          <GameUpdateView game={game} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/access`}
          exact
          label={t('GameIdView.tabs.access')}
        >
          <GameAccessView game={game} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type GameIdViewProps = RouteComponentProps<{
  gameId: string;
}> & {
  className?: string;
};
