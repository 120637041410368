import { useTranslation } from 'react-i18next';
import { Table, TableProps, Typography } from 'antd';
import classNames from 'classnames';
import Dinero from 'dinero.js';
import { set } from 'lodash';

import { SortDirection } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { generateColumnFilter } from '@components/tables/_Filters/FilterDropdown/FilterDropdown';

import styles from './SubventionBeneficiaries.module.css';

import {
  SubventionBeneficiariesQuery,
  useSubventionBeneficiariesQuery,
} from './SubventionBeneficiaries.generated';

type SubventionBeneficiariesProps = {
  className?: string;
  subventionId: string;
};

type RecordType = NonNullable<
  SubventionBeneficiariesQuery['subvention']
>['userPagination']['edges'][number];

export function SubventionBeneficiaries(props: SubventionBeneficiariesProps) {
  const { className, subventionId } = props;
  const { t } = useTranslation('subventions');

  const query = useSubventionBeneficiariesQuery({
    variables: {
      subventionId,
      skip: 0,
      take: 10,
    },
  });

  const { data, loading } = query;

  const amountByGrantee =
    data?.subvention?.__typename === 'SubventionDiscount'
      ? data?.subvention.amountDiscounted * data?.subvention.productQuantity
      : data?.subvention?.__typename === 'SubventionFund'
      ? data?.subvention?.amountByGrantee
      : 0;

  const columns: TableProps<RecordType>['columns'] = [
    {
      key: 'fullName',
      title: 'Utilisateurs',
      render: (_, record) => (
        <I18nLink to={`/users/list/${record?.node?.id}`}>
          {record?.node?.firstName} {record?.node?.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `fullName`, 'search'),
    },
    {
      key: 'email',
      title: 'Adresse email',
      sorter: true,
      render: (_, record) => (
        <Typography.Text copyable> {record?.node?.email}</Typography.Text>
      ),
      ...generateColumnFilter(query, `email`, 'search'),
    },
    data?.subvention?.__typename === 'SubventionDiscount'
      ? {
          title: 'Articles consommés',
          sorter: true,
          key: 'quantityConsumed',
          render: (_, record) =>
            t('subventionBeneficiaries.availableArticles', {
              consumed: record?.quantityConsumed,
              total:
                record.grantees.length *
                (data?.subvention?.__typename === 'SubventionDiscount'
                  ? data?.subvention?.productQuantity
                  : 0),
            }),
        }
      : {},
    {
      key: 'amountConsumed',
      title: 'Consommation',
      sorter: true,
      render: (_, record) =>
        t('subventionBeneficiaries.consumption', {
          consumed: Dinero({
            amount: record?.amountConsumed || 0,
            currency: 'EUR',
          }),
          total: Dinero({
            amount: record.grantees.length * amountByGrantee || 0,
            currency: 'EUR',
          }),
        }),
    },
  ];

  const handleChange: TableProps<RecordType>['onChange'] = (
    { pageSize = 10, current = 1 },
    _,
    sorters,
  ) => {
    const variables = {
      skip: (current - 1) * pageSize,
      take: pageSize,
      sorter: {},
    };

    (Array.isArray(sorters) ? sorters : [sorters])
      .filter((sorter) => sorter.order && sorter.columnKey)
      .forEach((sorter) => {
        if (sorter.order === 'ascend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Asc);
        } else if (sorter.order === 'descend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Desc);
        }
      });

    query.refetch({
      ...(query.variables || {}),
      ...variables,
    });
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Table
        loading={loading}
        className={styles.table}
        onChange={handleChange}
        columns={columns.filter((c) => 'title' in c)}
        rowKey={(record) => record?.cursor}
        dataSource={data?.subvention?.userPagination.edges || []}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              {record.grantees
                .filter((g) => g.target.__typename === 'Beneficiary')
                .map(
                  (g) =>
                    g.target.__typename === 'Beneficiary' && (
                      <p>{`${g.target.firstName} ${g.target.lastName}`}</p>
                    ),
                )}
            </div>
          ),
          rowExpandable: (record) => record.grantees.length > 1,
        }}
        pagination={{
          total: data?.subvention?.userPagination.totalCount || 0,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          defaultCurrent: 1,
          pageSize: query.variables?.take,
          current:
            (query.variables?.skip || 0) / (query.variables?.take || 10) + 1,
          defaultPageSize: 25,
        }}
      />
    </div>
  );
}
