/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldPolicy, FieldReadFunction } from '@apollo/client';

import { TypedTypePolicies } from './generated/apollo-helpers';

const mergePolicyEdges = (existing: any, incoming: any) => {
  if (!incoming || !incoming.edges) return existing;
  if (!existing || !existing.edges) return incoming; // existing will be empty the first time

  const { edges, ...rest } = incoming;

  // We filter new nodes to avoid duplicates
  const newEdges = edges.filter(
    (edge: any) =>
      !existing.edges
        ?.map((existingEdge: any) => existingEdge.__ref)
        .includes(edge.__ref),
  );

  const result = rest;
  result.edges = [...existing.edges, ...newEdges]; // Merge existing items with the items from incoming

  return result;
};

const mergePolicy = (existing: any, incoming: any) => {
  if (!incoming || !incoming.nodes) return existing;
  if (!existing || !existing.nodes) return incoming; // existing will be empty the first time

  const { nodes, ...rest } = incoming;

  // We filter new nodes to avoid duplicates
  const newNodes = nodes.filter(
    (node: any) =>
      !existing.nodes
        ?.map((existingNode: any) => existingNode.__ref)
        .includes(node.__ref),
  );

  const result = rest;
  result.nodes = [...existing.nodes, ...newNodes]; // Merge existing items with the items from incoming

  return result;
};

const commonPolicy:
  | FieldPolicy<any, any, any>
  | FieldReadFunction<any, any>
  | undefined = {
  keyArgs: ['filter'],
  merge: mergePolicy,
};

export const typePolicies: TypedTypePolicies = {
  Edge: {
    keyFields: ['cursor'],
  },
  Query: {
    fields: {
      adminCommentPagination: commonPolicy,
      offersPagination: commonPolicy,
      brandsPagination: commonPolicy,
      campaignPagination: {
        keyArgs: ['filter'],
        merge: mergePolicy,
      },
      articlesPagination: commonPolicy,
      article: commonPolicy,
      happening: commonPolicy,
      happeningPagination: commonPolicy,
    },
  },
  Catalog: {
    fields: {
      excludedCategoryPagination: {
        keyArgs: ['filter'],
        merge: mergePolicyEdges,
      },
      excludedBrandPagination: { keyArgs: ['filter'], merge: mergePolicyEdges },
      excludedCampaignPagination: {
        keyArgs: ['filter'],
        merge: mergePolicyEdges,
      },
      excludedOfferPagination: { keyArgs: ['filter'], merge: mergePolicyEdges },
      includedBrandPagination: { keyArgs: ['filter'], merge: mergePolicyEdges },
      includedCampaignPagination: {
        keyArgs: ['filter'],
        merge: mergePolicyEdges,
      },
      includedOfferPagination: { keyArgs: ['filter'], merge: mergePolicyEdges },
    },
  },
};
