import { Button } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import { SortDirection } from '@graphql/generated/types';

import { I18nLink } from '@atoms/Link/Link';
import { OffersTable } from '@tables/OffersTable/OffersTable';

import styles from './CampaignIdOffersView.module.css';

export const CampaignIdOffersView: React.FC<CampaignIdOffersViewProps> = (
  props,
) => {
  const { className, campaignId, brandId } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <OffersTable
        activeColumns={[
          'position',
          'name',
          'categories',
          'status',
          'type',
          'startsAt',
          'endsAt',
        ]}
        title={() => (
          <I18nLink
            to={{
              pathname: `/offers/create`,
              search: qs.stringify({ brandId, campaignId }),
            }}
          >
            <Button>Ajouter une offre</Button>
          </I18nLink>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/offers/create`,
                search: qs.stringify({ brandId, campaignId }),
              }}
            >
              <Button>Créer une offre</Button>
            </I18nLink>
          ),
        }}
        filter={{ campaign: { id: { is: campaignId } } }}
        sorter={{ position: SortDirection.Asc }}
      />
    </div>
  );
};

export type CampaignIdOffersViewProps = {
  campaignId: string;
  brandId: string;
  className?: string;
};
