import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form, Input, Spin, Table, Tooltip, Typography } from 'antd';

import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useReducCeOrderStateViewQuery } from './ReducCeOrderStateView.generated';

type ReducCeOrderStateViewProps = {
  orderId?: string;
};

export const ReducCeOrderStateView = (props: ReducCeOrderStateViewProps) => {
  const { orderId } = props;
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const { data, loading } = useReducCeOrderStateViewQuery({
    skip: !orderId,
    variables: { reducCeOrderId: orderId as string },
  });

  if (!orderId) {
    return (
      <div>
        <Form
          layout="vertical"
          onFinish={({ orderId }) => history.push(`${url}/${orderId}`)}
        >
          <Form.Item name="orderId" required label="Numéro de commande">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form>
      </div>
    );
  }

  if (!data && loading) return <Spin />;
  else if (!data?.reducCeOrderState)
    return <NotFoundView returnTo={path.split('/').slice(0, -1).join('/')} />;

  return (
    <div>
      <Typography.Title level={2}>
        Commande {data.reducCeOrderState.orderId}
      </Typography.Title>
      <Typography.Title level={4}>
        {data.reducCeOrderState.state}
      </Typography.Title>
      <Table
        dataSource={data.reducCeOrderState.items}
        columns={[
          {
            title: 'Id',
            dataIndex: ['attributes', 'diffgr:id'],
          },
          {
            title: 'Type',
            dataIndex: 'type_billet',
          },
          {
            title: 'Référence',
            dataIndex: 'code_article',
          },
          {
            title: 'Code barres',
            dataIndex: 'code_barres',
          },
          {
            title: 'État',
            dataIndex: 'info',
          },
          {
            dataIndex: 'data',
            render: (base64?: string | null) =>
              base64 ? (
                <a
                  href={`data:application/pdf;base64,${base64}`}
                  download="billet.pdf"
                >
                  <Button>Télécharger</Button>
                </a>
              ) : (
                <Tooltip title="Aucune information renvoyée par ReducCE nous permettant de générer un billet. Merci de les contacter avec le numéro de comande ci-dessus">
                  <Button disabled>Téléchargement impossible</Button>
                </Tooltip>
              ),
          },
        ]}
      />
    </div>
  );
};
