import { useTranslation } from 'react-i18next';
import { Descriptions, Typography } from 'antd';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { I18nLink } from '@components/atoms/Link/Link';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import {
  HappeningHeaderFragment,
  useHappeningHeaderUpdateMutation,
} from './HappeningHeader.generated';

export const HappeningHeader: React.FC<HappeningHeaderProps> = (props) => {
  const { className, happening } = props;

  const { t } = useTranslation(['happenings', 'common']);
  const [mutation] = useHappeningHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        happeningId: happening.id,
        input: { coverId: pictureId },
      },
    });
  };

  return (
    <PageHeader
      cover={happening.cover}
      logoProps={{ mode: 'contain' }}
      className={className}
      showNav
      uploadCoverProps={{
        fileType: FileType.HappeningCover,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Couverture',
        picture: happening.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
          ns: 'common',
        }),
      }}
    >
      <Typography.Text>{t('happening')}</Typography.Text>

      <Typography.Title level={2} ellipsis={{ rows: 2 }}>
        {happening.name}
      </Typography.Title>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('fields.company')}>
          <I18nLink to={`/companies/list/${happening.company?.id}`}>
            {happening.company?.name}
          </I18nLink>
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export type HappeningHeaderProps = {
  className?: string;
  happening: HappeningHeaderFragment;
};
