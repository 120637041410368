import * as Yup from 'yup';

import { ApiKeyScope } from '@graphql/generated/types';

import { downloadObjectToJson } from '@utils/csv';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import {
  ApiKeyCreateMutation,
  ApiKeyFormFragment,
  ApiKeyUpdateMutation,
  useApiKeyCreateMutation,
  useApiKeyUpdateMutation,
} from './ApiKeyForm.generated';

export type ApiKeyFormValues = {
  name: string;
  scopes: ApiKeyScope[];
};

export const ApiKeyForm = (props: ApiKeyFormProps) => {
  const { companyId, apiKey, ...rest } = props;

  const [createMutation] = useApiKeyCreateMutation();
  const [updateMutation] = useApiKeyUpdateMutation();

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    scopes: Yup.array().min(1).required(),
  }).required();

  const form = useForm<ApiKeyFormValues>({
    validationSchema,
    defaultValues: apiKey || {
      scopes: [ApiKeyScope.UserAll],
    },
  });

  const handleCreate: FormProps<ApiKeyFormValues>['onValid'] = async (
    values,
  ) => {
    try {
      const { data } = await createMutation({
        variables: {
          companyId,
          input: values,
        },
      });

      downloadObjectToJson(
        {
          name: data?.apiKeyCreate.apiKey.name,
          clientId: data?.apiKeyCreate.apiKey.clientId,
          clientSecret: data?.apiKeyCreate.clientSecret,
        },
        'api_key.csv',
      );

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdate: FormProps<ApiKeyFormValues>['onValid'] = async (
    values,
  ) => {
    if (!apiKey) return;

    try {
      const { data } = await updateMutation({
        variables: {
          apiKeyId: apiKey.id,
          input: values,
        },
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  // const handleDelete = (values) => {};

  return (
    <Form
      id="ApiKeyForm"
      form={form}
      onValid={apiKey ? handleUpdate : handleCreate}
      {...rest}
    >
      <FormItem label="Nom de la clé" name="name" required>
        <FormInput name="name" />
      </FormItem>
    </Form>
  );
};

export type ApiKeyFormProps = ChildFormProps<
  ApiKeyFormValues,
  ApiKeyUpdateMutation | ApiKeyCreateMutation
> & {
  companyId: string;
  apiKey?: ApiKeyFormFragment;
};
