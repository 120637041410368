import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';

import { I18nLink } from '@components/atoms/Link/Link';
import { ProductTypeTag } from '@components/atoms/ProductTypeTag/ProductTypeTag';
import { Typography } from '@components/atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './ProductHeader.module.css';

import { ProductHeaderFragment } from './ProductHeader.generated';

export const ProductHeader: React.FC<ProductHeaderProps> = (props) => {
  const { className, product } = props;
  const { t } = useTranslation('products');

  return (
    <PageHeader showNav className={className}>
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {product.name}
      </Typography.Title>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('fields.catalog')}>
          <I18nLink to={`/products/catalogs/list/${product.catalog?.id}`}>
            {product.catalog?.name}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.provider')}>
          <I18nLink
            to={`/products/providers/list/${product.catalog?.provider.id}`}
          >
            {product.catalog?.provider.name}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.brand')}>
          <I18nLink to={`/brands/list/${product.brand?.id}`}>
            {product.brand?.name}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.offer')}>
          <I18nLink to={`/offers/list/${product.offer?.id}`}>
            {product.offer?.name ?? 'N/A'}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.type')}>
          <ProductTypeTag fragment={product} />
        </Descriptions.Item>

        {product.expiresAt && (
          <Descriptions.Item label={t('fields.expiresAt')}>
            {product.expiresAt.toLocaleDateString()}
          </Descriptions.Item>
        )}

        {product.expiresInDays && (
          <Descriptions.Item label={t('fields.expiresInDays')}>
            {product.expiresInDays}
          </Descriptions.Item>
        )}

        {product.taxRatePagination &&
          product.taxRatePagination.nodes.length && (
            <Descriptions.Item label={t('fields.taxRates')}>
              {product.taxRatePagination.nodes
                .map((taxRate) => taxRate.displayName)
                .join(', ')}
            </Descriptions.Item>
          )}
      </Descriptions>
    </PageHeader>
  );
};

export type ProductHeaderProps = {
  className?: string;
  product: ProductHeaderFragment;
};
