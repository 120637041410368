import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignUpdateForm.module.css';

import {
  CampaignUpdateFormFragment,
  CampaignUpdateFormUpdateMutation,
  CampaignUpdateFormUpdateMutationVariables,
  useCampaignUpdateFormUpdateMutation,
} from './CampaignUpdateForm.generated';

export type CampaignUpdateFormValues = {
  startsAt?: Date | null;
  endsAt?: Date | null;
};

export function CampaignUpdateForm(props: CampaignUpdateFormProps) {
  const { className, campaign, ...rest } = props;

  const { t } = useTranslation('campaigns');

  const validationSchema = Yup.object({
    startsAt: Yup.date().nullable(),
    endsAt: Yup.date().nullable(),
  }).required();

  const form = useForm<CampaignUpdateFormValues>({
    validationSchema,
    defaultValues: {
      startsAt: campaign.startsAt,
      endsAt: campaign.endsAt,
    },
  });

  const [mutation] = useCampaignUpdateFormUpdateMutation();

  const handleSubmit: FormProps<CampaignUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!campaign) return;

    const variables: CampaignUpdateFormUpdateMutationVariables = {
      campaignId: campaign.id,
      input: {
        startsAt: values.startsAt ?? null,
        endsAt: values.endsAt ?? null,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  const { startsAt, endsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.startsAt}
          label={t('CampaignUpdateForm.fields.startsAt.label')}
          name="startsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => (endsAt ? date.isAfter(endsAt) : false)}
            name="startsAt"
          />
        </FormItem>
        <FormItem
          className={styles.endsAt}
          label={t('CampaignUpdateForm.fields.endsAt.label')}
          name="endsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) =>
              startsAt ? date.isBefore(startsAt) : false
            }
            name="endsAt"
          />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('CampaignUpdateForm.buttons.submit.label')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CampaignUpdateFormProps = ChildFormProps<
  CampaignUpdateFormValues,
  CampaignUpdateFormUpdateMutation
> & {
  className?: string;
  campaign: CampaignUpdateFormFragment;
};
