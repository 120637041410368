import { useState } from 'react';
import { Button, Spin, TreeSelect } from 'antd';

import {
  useOfferIdSubventionSchemaViewAddSchemaMutation,
  useOfferIdSubventionSchemaViewRemoveSchemaMutation,
  useOfferIdSubventionSchemaViewSubventionOfferQuery,
  useOfferIdSubventionSchemaViewSubventionSchemasQuery,
  useOfferIdSubventionSchemaViewSubventionViewerQuery,
} from './OfferIdSubventionSchemaView.generated';

type OfferIdSubventionSchemaViewProps = {
  offerId: string;
};

export const OfferIdSubventionSchemaView = (
  props: OfferIdSubventionSchemaViewProps,
) => {
  const { offerId } = props;

  const [value, setValue] = useState<string[]>([]);
  const viewerQuery = useOfferIdSubventionSchemaViewSubventionViewerQuery();

  const isSuperCare = viewerQuery.data?.viewer?.isSuperCare === true;
  const { loading, data } = useOfferIdSubventionSchemaViewSubventionOfferQuery({
    variables: { offerId },
    onCompleted: (data) => {
      const subventionSchemas = data.offer?.subventionSchemas ?? [];
      const subventionSchemaIds = subventionSchemas.map((s) => s.id);

      setValue(subventionSchemaIds);
    },
  });
  const subventionSchemaQuery =
    useOfferIdSubventionSchemaViewSubventionSchemasQuery();
  const [addMutation, addState] =
    useOfferIdSubventionSchemaViewAddSchemaMutation();
  const [removeMutation, removeState] =
    useOfferIdSubventionSchemaViewRemoveSchemaMutation();
  const mutateLoading = addState.loading || removeState.loading;

  const offer = data?.offer ?? null;
  const subventionSchemas =
    subventionSchemaQuery.data?.subventionSchemaPagination.edges.map(
      (e) => e.node,
    ) ?? [];

  if (loading && !offer) return <Spin />;
  else if (!offer) return <div>Not found</div>;

  const treeData = subventionSchemas.map((subventionSchema) => {
    const children =
      subventionSchema.childrenPagination.edges.map((e) => e.node) ?? [];

    return {
      value: subventionSchema.id,
      title: subventionSchema.name,
      children: children.map((child) => ({
        value: child.id,
        title: child.name,
      })),
    };
  });

  const handleSubmit = async () => {
    if (!isSuperCare) return;

    const oldSchemaIds = offer.subventionSchemas.map((s) => s.id);
    const addedSchemaIds = value.filter((id) => !oldSchemaIds.includes(id));
    const removedSchemaIds = oldSchemaIds.filter((id) => !value.includes(id));

    await Promise.all(
      addedSchemaIds.map((id) =>
        addMutation({
          variables: { subventionSchemaId: id, input: { offerIds: [offerId] } },
        }),
      ),
    );

    await Promise.all(
      removedSchemaIds.map((id) =>
        removeMutation({
          variables: { subventionSchemaId: id, input: { offerIds: [offerId] } },
        }),
      ),
    );
  };

  return (
    <div>
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Please select"
        allowClear
        multiple
        treeDefaultExpandAll
        onChange={setValue}
        treeData={treeData}
      />
      <Button
        style={{ marginTop: 16 }}
        type="primary"
        disabled={isSuperCare === false}
        onClick={handleSubmit}
        loading={mutateLoading}
      >
        Enregistrer
      </Button>
    </div>
  );
};
