import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { ArticleCategoryIdTranslationsView } from '../ArticleCategoryIdTranslationsView/ArticleCategoryIdTranslationsView';
import { ArticlesCategoryIdArticlesView } from '../ArticlesCategoryIdArticlesView/ArticlesCategoryIdArticlesView';

import { ArticleCategoryHeader } from '@components/organisms/ArticleCategoryHeader/ArticleCategoryHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ArticleCategoryIdView.module.css';

import { useArticleCategoryIdViewQuery } from './ArticleCategoryIdView.generated';

export const ArticleCategoryIdView: React.FC<ArticleCategoryIdViewProps> = (
  props,
) => {
  const { className, match } = props;
  const { t } = useTranslation('communication');
  const {
    params: { articleCategoryId },
  } = match;

  const articleCategoryQuery = useArticleCategoryIdViewQuery({
    variables: { articleCategoryId },
    notifyOnNetworkStatusChange: true,
  });

  const articleCategory = articleCategoryQuery?.data?.articleCategory;

  if (articleCategoryQuery.loading && !articleCategory) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!articleCategory) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <ArticleCategoryHeader articleCategory={articleCategory} />
      <TabsRouter match={match}>
        <TabsRouter.Route
          label={t('articles.category.nav.translations')}
          exact
          path={match.url}
        >
          <ArticleCategoryIdTranslationsView
            articleCategoryId={articleCategoryId}
          />
        </TabsRouter.Route>
        <TabsRouter.Route
          label={t('articles.category.nav.articles', {
            count: articleCategory.articlesPagination.totalCount,
          })}
          path={`${match.url}/articles`}
        >
          <ArticlesCategoryIdArticlesView
            articleCategoryId={articleCategoryId}
          />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type ArticleCategoryIdViewProps = RouteComponentProps<{
  articleCategoryId: string;
}> & {
  className?: string;
};
