import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Descriptions } from 'antd';

import { Typography } from '@components/atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './StoreHeader.module.css';

import { StoreHeaderFragment } from './StoreHeader.generated';

export const StoreHeader: React.FC<StoreHeaderProps> = (props) => {
  const { className, store, showNav } = props;
  const { t, i18n } = useTranslation('stores');

  return (
    <PageHeader showNav={showNav} className={className}>
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {store.address.formattedAddress}
      </Typography.Title>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('header.brand')}>
          <Link to={`/${i18n.language}/brands/list/${store.brand.id}`}>
            {store.brand.name}
          </Link>
        </Descriptions.Item>

        <Descriptions.Item label={t('header.offerCount')}>
          {store.offerPagination.totalCount}
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export type StoreHeaderProps = {
  className?: string;
  store: StoreHeaderFragment;
  showNav?: boolean;
};
