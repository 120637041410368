import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  ProductArticleCreateForm,
  ProductArticleCreateFormProps,
} from '@forms/ProductArticleCreateForm/ProductArticleCreateForm';

import styles from './ProductArticleCreateView.module.css';

export const ProductArticleCreateView: React.FC<ProductArticleCreateViewProps> =
  (props) => {
    const { className } = props;
    const { t, i18n } = useTranslation('productArticles');
    const history = useHistory();
    const location = useLocation();

    const handleSuccess: ProductArticleCreateFormProps['onSuccess'] = ({
      productArticleCreate,
    }) => {
      history.push(
        `/${i18n.language}/product-articles/list/${productArticleCreate.productArticle.id}`,
      );
    };

    const defaultValues = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as ProductArticleCreateFormProps['defaultValues'];

    return (
      <div className={classnames(className, styles.root)}>
        <div className={styles.wrapper}>
          <Typography.Title>{t('create.title')}</Typography.Title>
          <ProductArticleCreateForm
            defaultValues={defaultValues}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
    );
  };

export type ProductArticleCreateViewProps = {
  className?: string;
};
