import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import * as Yup from 'yup';

import { SubventionReimbursementRequest } from '@graphql/generated/types';

import { FormTextArea } from '@components/atoms/FormTextArea/FormTextArea';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './SubventionReimbursementRejectForm.module.css';

import {
  SubventionReimbursementRequestRejectMutation,
  SubventionReimbursementRequestRejectMutationVariables,
  useSubventionReimbursementRequestRejectMutation,
} from './SubventionReimbursementRejectForm.generated';

export type SubventionReimbursementRejectFormValues = {
  rejectedReason: string;
};

export function SubventionReimbursementRejectForm(
  props: SubventionReimbursementRejectFormProps,
) {
  const { className, hideFooter, subventionReimbursementRequestId, ...rest } =
    props;
  const { t } = useTranslation('subventionReimbursements');

  const validationSchema = Yup.object({
    rejectedReason: Yup.string().required(),
  }).required();

  const form = useForm<SubventionReimbursementRejectFormValues>({
    validationSchema,
  });

  const [mutation] = useSubventionReimbursementRequestRejectMutation();

  const handleSubmit: FormProps<SubventionReimbursementRejectFormValues>['onValid'] =
    async (values) => {
      const variables: SubventionReimbursementRequestRejectMutationVariables = {
        subventionReimbursementRequestId,
        input: {
          rejectedReason: values.rejectedReason,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="SubventionReimbursementRejectForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          required
          label={t('fields.rejectedReason')}
          name="rejectedReason"
        >
          <FormTextArea name="rejectedReason" />
        </FormItem>
      </Form>
    </Card>
  );
}

export type SubventionReimbursementRejectFormProps = ChildFormProps<
  SubventionReimbursementRejectFormValues,
  SubventionReimbursementRequestRejectMutation
> & {
  className?: string;
  hideFooter?: boolean;
  subventionReimbursementRequestId: SubventionReimbursementRequest['id'];
};
