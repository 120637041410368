import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import {
  FormSelectLocale,
  FormSelectLocaleProps,
} from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductTranslationForm.module.css';

import {
  ProductTranslationFormCreateMutation,
  ProductTranslationFormCreateMutationVariables,
  ProductTranslationFormDeleteMutation,
  ProductTranslationFormDeleteMutationVariables,
  ProductTranslationFormFragment,
  ProductTranslationFormUpdateMutation,
  ProductTranslationFormUpdateMutationVariables,
  useProductTranslationFormCreateMutation,
  useProductTranslationFormDeleteMutation,
  useProductTranslationFormUpdateMutation,
} from './ProductTranslationForm.generated';

export type ProductTranslationFormValues = {
  localeId: string;
  name: string;
};

export function ProductTranslationForm(props: ProductTranslationFormProps) {
  const {
    className,
    productId,
    translation,
    onCreated,
    onUpdated,
    onDeleted,
    localeFilter,
    ...rest
  } = props;
  const { t } = useTranslation('products');

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
  }).required();

  const form = useForm<ProductTranslationFormValues>({
    validationSchema,
    defaultValues: {
      localeId: translation?.locale,
      name: translation?.name,
    },
  });

  const [createMutation, createMutationState] =
    useProductTranslationFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useProductTranslationFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useProductTranslationFormDeleteMutation();

  const handleCreate = async (values: ProductTranslationFormValues) => {
    if (translation) return;

    const variables: ProductTranslationFormCreateMutationVariables = {
      productId,
      input: {
        localeId: values.localeId,
        name: values.name,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.productTranslationCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: ProductTranslationFormValues) => {
    if (!translation) return;

    const variables: ProductTranslationFormUpdateMutationVariables = {
      productTranslationId: translation.id,
      input: {
        name: values.name,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.productTranslationUpdate);
    }
  };

  const handleDelete = async () => {
    if (!translation) return;

    const variables: ProductTranslationFormDeleteMutationVariables = {
      productTranslationId: translation.id,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.productTranslationDelete);
    }
  };

  const handleSubmit: FormProps<ProductTranslationFormValues>['onValid'] =
    async (values) => {
      if (translation) {
        return handleUpdate(values);
      } else {
        return handleCreate(values);
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductTranslationUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label={t('ProductTranslationForm.fields.locale.label')}
          name="localeId"
        >
          <FormSelectLocale
            disabled={!!translation}
            filter={localeFilter}
            name="localeId"
          />
        </FormItem>
        <FormItem
          className={styles.name}
          required
          label={t('ProductTranslationForm.fields.name.label')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>
        <div className={styles.footer}>
          {!translation ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              {t('ProductTranslationForm.submits.create.label')}
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              {t('ProductTranslationForm.submits.update.label')}
            </Button>
          )}
          {!!translation && (
            <Popconfirm
              title={t('ProductTranslationForm.confirm.deleteTitle.label')}
              okText={t('ProductTranslationForm.confirm.deleteOk.label')}
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                {t('ProductTranslationForm.confirm.delete.label')}
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type ProductTranslationFormProps = ChildFormProps<
  ProductTranslationFormValues,
  void
> & {
  className?: string;
  productId: string;
  translation?: ProductTranslationFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: ProductTranslationFormCreateMutation['productTranslationCreate'],
  ) => void;
  onUpdated?: (
    v: ProductTranslationFormUpdateMutation['productTranslationUpdate'],
  ) => void;
  onDeleted?: (
    v: ProductTranslationFormDeleteMutation['productTranslationDelete'],
  ) => void;
};
