import { useTranslation } from 'react-i18next';

import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { BeneficiaryProofRecordFragment } from './BeneficiaryProofsTable.generated';

type RecordType = BeneficiaryProofRecordFragment;

type Column = 'id' | 'originalName';

type BaseProps = ChildTablePaginationProps<RecordType, Column>;

export type BeneficiaryProofsTableProps = BaseProps & {
  className?: string;
  query: NonNullable<BaseProps['query']>;
};

export function BeneficiaryProofsTable(props: BeneficiaryProofsTableProps) {
  const { className, query, data, ...rest } = props;

  const { t } = useTranslation('beneficiary');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
    },
    {
      key: 'originalName',
      title: t('table.headers.originalName'),
      dataIndex: ['originalName'],
      render: (name, node) => (
        <a href={node.url} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
  ];

  return (
    <>
      <TablePagination
        id="BeneficiaryProofsTable"
        query={query}
        columns={columns}
        data={data}
        {...rest}
      />
    </>
  );
}
