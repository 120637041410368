import { Spin } from 'antd';
import classnames from 'classnames';

import { OfferCouponPublicUpdateForm } from '@components/forms/OfferCouponPublicUpdateForm/OfferCouponPublicUpdateForm';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdCouponPublicView.module.css';

import { useOfferIdCouponPublicViewQuery } from './OfferIdCouponPublicView.generated';

export const OfferIdCouponPublicView: React.FC<OfferIdCouponPublicViewProps> = (
  props,
) => {
  const { className, offerId } = props;

  const query = useOfferIdCouponPublicViewQuery({
    variables: {
      offerId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const offer =
    query?.data?.offer?.__typename === 'OfferCouponPublic' && query.data.offer;

  if (query.loading && !offer) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!offer) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <OfferCouponPublicUpdateForm offer={offer} />
    </div>
  );
};

export type OfferIdCouponPublicViewProps = {
  offerId: string;
  className?: string;
};
