import { DiscoverTargetType } from '@graphql/generated/types';

import { DiscoverEditor } from '@components/organisms/DiscoverEditor/DiscoverEditor';

export const ParametersDiscoverView = () => {
  return (
    <div>
      <DiscoverEditor
        title="Catégories à la une"
        targetType={DiscoverTargetType.Category}
      />
      <DiscoverEditor
        title="Campagnes à la une"
        targetType={DiscoverTargetType.Campaign}
      />
    </div>
  );
};
