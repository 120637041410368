import { memo } from 'react';
import { Image, ImageProps } from 'antd';
import classnames from 'classnames';

import styles from './Picture.module.css';

import { PictureFragment } from './Picture.generated';

export const Picture: React.FC<PictureProps> = memo((props) => {
  const {
    picture,
    className,
    fit = 'cover',
    shape = 'square',
    width,
    height,
    ...rest
  } = props;

  return (
    <Image
      width={width}
      height={height}
      src={picture?.file?.url}
      className={classnames(styles.root, className, {
        [styles['-fit-contain']]: fit === 'contain',
        [styles['-fit-cover']]: fit === 'cover',
        [styles['-fit-none']]: fit === 'none',
        [styles['-fit-fill']]: fit === 'fill',
        [styles['-shape-square']]: shape === 'square',
        [styles['-shape-rounded']]: shape === 'rounded',
        [styles['-shape-circle']]: shape === 'circle',
      })}
      fallback={`https://placehold.co/${[width, height]
        .filter(Boolean)
        .join('x')}`}
      {...rest}
    />
  );
});

export type PictureProps = {
  picture?: PictureFragment | null;
  fit?: 'contain' | 'cover' | 'none' | 'fill';
  shape?: 'square' | 'rounded' | 'circle';
} & Omit<ImageProps, 'src'>;
