import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { GamesTable } from '@components/tables/GamesTable/GamesTable';

import styles from './GamesView.module.css';

export const GamesView: React.FC<GamesViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('games');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('GamesView.title')}</Typography.Title>

      <GamesTable
        activeColumns={['name', 'url', 'startsAt', 'endsAt', 'isExclusive']}
        title={() => (
          <I18nLink to="/games/create">
            <Button type="primary">{t('GamesView.create')}</Button>
          </I18nLink>
        )}
      />
    </div>
  );
};

export type GamesViewProps = {
  className?: string;
};
