import classNames from 'classnames';

import { ProgressivePicture } from '@components/atoms/ProgressivePicture/ProgressivePicture';

import styles from './CampaignCard.module.css';

import { CampaignCardFragment } from './CampaignCard.generated';

export const CampaignCard = (props: CampaignCardProps) => {
  const { campaign, className } = props;

  const picture = campaign.thumbnail || campaign.cover || campaign.brand.cover;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        {picture && (
          <ProgressivePicture
            mode="cover"
            picture={picture}
            className={styles.picture}
          />
        )}
        <div className={styles.overlay} />
        <div className={styles.texts}>
          {campaign.categoriesPagination.nodes.length > 0 && (
            <div className={styles.category}>
              {campaign.categoriesPagination.nodes
                .map((n) => n.name)
                .join(', ')}
            </div>
          )}
          <div className={styles.name} title={campaign.name}>
            {campaign.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export type CampaignCardProps = {
  campaign: CampaignCardFragment;
  className?: string;
};
