import { Avatar } from 'antd';
import classnames from 'classnames';

import styles from './TableRendererAvatar.module.css';

import { TableRendererAvatarFragment } from './TableRendererAvatar.generated';

export function TableRendererAvatar(props: TableRendererAvatarProps) {
  const { className, picture, placeholder = '' } = props;

  return (
    <Avatar
      className={classnames(className, styles.root)}
      src={picture?.file.url}
      size={32}
      shape="square"
    >
      {placeholder}
    </Avatar>
  );
}

export type TableRendererAvatarProps = {
  className?: string;
  picture?: TableRendererAvatarFragment | null;
  placeholder?: string;
};
