import classnames from 'classnames';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectProductCatalog.module.css';

import {
  FormSelectProductCatalogQueryVariables,
  useFormSelectProductCatalogQuery,
} from './FormSelectProductCatalog.generated';

export const FormSelectProductCatalog: React.FC<FormSelectProductCatalogProps> =
  (props) => {
    const { className, filter, ...rest } = props;

    const { data, loading } = useFormSelectProductCatalogQuery({
      variables: {
        filter,
      },
      notifyOnNetworkStatusChange: true,
    });

    return (
      <FormSelect
        className={classnames(className, styles.root)}
        loading={loading}
        filterOption={false}
        options={data?.productCatalogPagination.nodes.map((node) => ({
          value: node.id,
          label: node.name,
        }))}
        {...rest}
      />
    );
  };

export type FormSelectProductCatalogProps = FormSelectProps & {
  filter?: FormSelectProductCatalogQueryVariables['filter'];
};
