import { RouteProps } from 'react-router';

export const TabsRouterRoute: React.FC<TabsRouterRouteProps> = () => {
  return null;
};

export type TabsRouterRouteProps = RouteProps & {
  label: string;
  path: string;
  disabled?: boolean;
};
