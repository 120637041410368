import classnames from 'classnames';

import { ProductProvidersTable } from '@tables/ProductProvidersTable/ProductProvidersTable';

import styles from './ProductProvidersView.module.css';

export const ProductProvidersView: React.FC<ProductProvidersViewProps> = (
  props,
) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <ProductProvidersTable
        activeColumns={[
          'name',
          'identifier',
          'productCount',
          'synchronizationCount',
        ]}
      />
    </div>
  );
};

export type ProductProvidersViewProps = {
  className?: string;
};
