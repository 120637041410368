import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './GameCreateForm.module.css';

import {
  GameCreateFormMutation,
  GameCreateFormMutationVariables,
  useGameCreateFormMutation,
} from './GameCreateForm.generated';

// Note: Having no company ID means that the category is global
export type GameCreateFormValues = {
  name: string;
  url: string;
  startsAt: Date;
  endsAt: Date;
  isExclusive: boolean;
  includedCompanies: Array<string>;
  excludedCompanies: Array<string>;
};

export function GameCreateForm(props: GameCreateFormProps) {
  const { className, ...rest } = props;
  const { t } = useTranslation('games');

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    url: Yup.string().required().url(),
    startsAt: Yup.date().required(),
    endsAt: Yup.date().required(),
    isExclusive: Yup.boolean().required(),
    includedCompanies: Yup.array(),
    excludedCompanies: Yup.array(),
  }).required();

  const form = useForm<GameCreateFormValues>({
    validationSchema,
    defaultValues: {
      isExclusive: false,
    },
  });

  const [mutation] = useGameCreateFormMutation();

  const handleSubmit: FormProps<GameCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: GameCreateFormMutationVariables = {
      input: {
        name: values.name,
        url: values.url,
        startsAt: values.startsAt,
        endsAt: values.endsAt,
        isExclusive: values.isExclusive,
        includedCompanyIds: values.includedCompanies,
        excludedCompanyIds: values.excludedCompanies,
      },
    };

    try {
      const { data } = await mutation({ variables });
      message.success(t('GameCreateForm.successMessage'));
      return data;
    } catch (err) {
      handleMutationErrors(err, {});
    }
  };

  const { startsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="GameCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('fields.name')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.description}
          required
          label={t('fields.url')}
          name="url"
        >
          <FormInput name="url" />
        </FormItem>

        <FormItem
          className={styles.startsAt}
          required
          label={t('fields.startsAt')}
          name="startsAt"
        >
          <FormInputDate
            name="startsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
          />
        </FormItem>

        <FormItem
          className={styles.endsAt}
          required
          label={t('fields.endsAt')}
          name="endsAt"
        >
          <FormInputDate
            name="endsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(startsAt)}
          />
        </FormItem>
        <FormItem
          className={styles.isExclusive}
          required
          label={t('fields.isExclusive')}
          name="isExclusive"
        >
          <FormCheckbox name="isExclusive" />
        </FormItem>
        <FormItem
          className={styles.includedCompanies}
          required
          label={t('fields.includedCompanies')}
          name="includedCompanies"
        >
          <FormSelectCompany multiple name="includedCompanies" />
        </FormItem>
        <FormItem
          className={styles.excludedCompanies}
          required
          label={t('fields.excludedCompanies')}
          name="excludedCompanies"
        >
          <FormSelectCompany multiple name="excludedCompanies" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('GameCreateForm.create')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type GameCreateFormProps = ChildFormProps<
  GameCreateFormValues,
  GameCreateFormMutation
> & {
  className?: string;
};
