import axios from 'axios';

import {
  FileCreateInput,
  FileType,
  PictureCreateInput,
} from '@graphql/generated/types';

import { extractImageInformations } from '@utils/extractImageInformations/extractImageInformations';

import { InputFileUploadFragment } from '@components/atoms/InputFile/InputFileUpload.generated';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';

import {
  AttachmentItemFragment,
  useAttachmentFileCreateMutation,
  useAttachmentPictureCreateMutation,
} from './attachmentCreate.hooks.generated';

export type FileCreateOptions<
  T extends AttachmentCreateType = AttachmentItemFragment,
> = {
  type: FileType;
  onProgress?: (progress: number | null) => void;
} & (T extends ProgressivePictureFragment
  ? { isPictureUpload: true }
  : { isPictureUpload?: false });

type AttachmentInput = PictureCreateInput | FileCreateInput;
type AttachmentCreateType = AttachmentItemFragment | ProgressivePictureFragment;

function isPictureCreateInput(
  input: AttachmentInput,
): input is PictureCreateInput {
  return !!(input as PictureCreateInput)?.hash;
}

export const useAttachmentCreate = <
  T extends AttachmentCreateType = InputFileUploadFragment,
>({
  type,
  onProgress,
  isPictureUpload,
}: FileCreateOptions<T>) => {
  const [pictureCreateMutation] = useAttachmentPictureCreateMutation();
  const [fileCreateMutation] = useAttachmentFileCreateMutation();

  return async function fileCreate(inputFileValue: File) {
    function createMutation(input: AttachmentInput) {
      return isPictureCreateInput(input)
        ? pictureCreateMutation({ variables: { input } })
        : fileCreateMutation({ variables: { input } });
    }

    const pictureInput =
      isPictureUpload && (await extractImageInformations(inputFileValue));

    const input = {
      fileName: inputFileValue.name,
      mimetype: inputFileValue.type,
      type,
      size: inputFileValue.size,
    };

    const result = await createMutation({
      ...input,
      ...(pictureInput || {}),
    });

    if (!result.data) {
      throw new Error('FileCreate mutation failed');
    }

    const { fields, url, attachment } = result.data.attachmentCreate;
    const formData = new FormData();

    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('file', inputFileValue, inputFileValue.name);

    await axios({
      url,
      method: 'POST',
      data: formData,
      onUploadProgress: (p) =>
        onProgress?.(Math.floor((p.loaded / p.total) * 100)),
    });

    return attachment as T;
  };
};
