import classNames from 'classnames';

import { ProgressivePicture } from '@components/atoms/ProgressivePicture/ProgressivePicture';
import { PublicationItemContentFragment } from '@components/views/Communication/Publications/PublicationIdView/PublicationIdView.generated';

import styles from './PublicationCard.module.css';

export const PublicationCard = (props: PublicationCardProps) => {
  const { publication, className } = props;

  const picture = publication.cover;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        {picture && (
          <ProgressivePicture
            mode="cover"
            picture={picture}
            className={styles.picture}
          />
        )}
        <div className={styles.overlay} />
        <div className={styles.texts}>
          <div className={styles.name} title={publication.title}>
            {publication.title}
          </div>
        </div>
      </div>
    </div>
  );
};

export type PublicationCardProps = {
  publication: PublicationItemContentFragment;
  className?: string;
};
