import { HappeningRegistrationsTable } from '@components/tables/HappeningRegistrationsTable/HappeningRegistrationsTable';

import { useHappeningIdRegistrationsViewQuery } from './HappeningIdRegistrationsView.generated';

export type HappeningIdRegistrationsViewProps = {
  happeningId: string;
};

export const HappeningIdRegistrationsView = (
  props: HappeningIdRegistrationsViewProps,
) => {
  const { happeningId } = props;

  const query = useHappeningIdRegistrationsViewQuery({
    variables: { happeningId, take: 20, skip: 0 },
  });

  return (
    <div>
      <HappeningRegistrationsTable
        activeColumns={['id', 'user', 'status']}
        happeningId={happeningId}
        data={query.data?.happening?.registrationsPagination}
        query={query}
      />
    </div>
  );
};
