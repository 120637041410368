import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';

import styles from './StripePaymentIntentUrlButton.module.css';

import { StripePaymentIntentUrlButtonFragment } from './StripePaymentIntentUrlButton.generated';

export const StripePaymentIntentUrlButton: React.FC<StripePaymentIntentUrlButtonProps> =
  (props) => {
    const { className, stripePaymentIntent } = props;
    const { t } = useTranslation('stripePaymentIntents');

    return (
      <Button
        className={classnames(className, styles.root)}
        type="dashed"
        href={`https://dashboard.stripe.com/payments/${stripePaymentIntent.stripeUrl}`}
        target="_blank"
      >
        {t('stripePaymentIntentUrlButton.label')}
      </Button>
    );
  };

export type StripePaymentIntentUrlButtonProps = {
  className?: string;
  stripePaymentIntent: StripePaymentIntentUrlButtonFragment;
};
