import { CheckCircleFilled } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

import { UserKycStatus } from '@graphql/generated/types';

import { UserReimbursementStatusTagFragment } from './UserReimbursementStatusTag.generated';

export const UserReimbursementStatusTag: React.FC<UserReimbursementStatusTagProps> =
  (props) => {
    const { user } = props;
    const style = { color: 'red' };
    const haveBankAccount =
      user.mangopayUser?.bankAccountsPagination.totalCount ?? 0;
    const isKycValidated = user.kycStatus === UserKycStatus.Validated;
    const statuses = (
      <>
        <Tag key={'kyc'} color={isKycValidated ? 'green' : 'red'}>
          KYC
        </Tag>
        <Tag key={'bankAccount'} color={haveBankAccount ? 'green' : 'red'}>
          Bank Account
        </Tag>
      </>
    );

    if (isKycValidated && haveBankAccount) {
      style.color = 'green';
    } else if (isKycValidated || haveBankAccount) {
      style.color = 'orange';
    }
    return (
      <Tooltip title={statuses} color="white">
        <CheckCircleFilled style={style} />
      </Tooltip>
    );
  };

export type UserReimbursementStatusTagProps = {
  className?: string;
  user: UserReimbursementStatusTagFragment;
};
