import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Scalars } from '@graphql/generated/types';

import { OfferMetadataPreviewButton } from '@components/organisms/OfferMetadataPreviewButton/OfferMetadataPreviewButton';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import {
  FormSelectLocale,
  FormSelectLocaleProps,
} from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferTranslationForm.module.css';

import {
  OfferTranslationFormCreateMutation,
  OfferTranslationFormCreateMutationVariables,
  OfferTranslationFormDeleteMutation,
  OfferTranslationFormDeleteMutationVariables,
  OfferTranslationFormFragment,
  OfferTranslationFormUpdateMutation,
  OfferTranslationFormUpdateMutationVariables,
  useOfferTranslationFormCreateMutation,
  useOfferTranslationFormDeleteMutation,
  useOfferTranslationFormUpdateMutation,
} from './OfferTranslationForm.generated';

export type OfferTranslationFormValues = {
  localeId: string;
  name: string;
  description?: Scalars['Delta'] | null;
};

export function OfferTranslationForm(props: OfferTranslationFormProps) {
  const {
    className,
    offerId,
    translation,
    onCreated,
    onUpdated,
    onDeleted,
    localeFilter,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.object().nullable(),
  }).required();

  const form = useForm<OfferTranslationFormValues>({
    validationSchema,
    defaultValues: {
      localeId: translation?.locale,
      description: translation?.descriptionDelta,
      name: translation?.name,
    },
  });

  const [createMutation, createMutationState] =
    useOfferTranslationFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useOfferTranslationFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useOfferTranslationFormDeleteMutation();

  const handleCreate = async (values: OfferTranslationFormValues) => {
    if (translation) return;

    const variables: OfferTranslationFormCreateMutationVariables = {
      offerId,
      localeId: values.localeId,
      input: {
        name: values.name,
        description: values.description,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.offerTranslationCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: OfferTranslationFormValues) => {
    if (!translation) return;

    const variables: OfferTranslationFormUpdateMutationVariables = {
      offerId,
      localeId: translation.locale,
      input: {
        name: values.name,
        description: values.description || null,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.offerTranslationUpdate);
    }
  };

  const handleDelete = async () => {
    if (!translation) return;

    const variables: OfferTranslationFormDeleteMutationVariables = {
      offerId,
      localeId: translation.locale,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.offerTranslationDelete);
    }
  };

  const handleSubmit: FormProps<OfferTranslationFormValues>['onValid'] = async (
    values,
  ) => {
    if (translation) {
      return handleUpdate(values);
    } else {
      return handleCreate(values);
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferTranslationUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale
            disabled={!!translation}
            filter={localeFilter}
            name="localeId"
          />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <FormItem name="provider" required label="Info fournisseur">
          <OfferMetadataPreviewButton
            offerId={offerId}
            className={styles.preview}
          />
        </FormItem>
        <div className={styles.footer}>
          {!translation ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              Créer la traduction
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              Mettre à jour la traduction
            </Button>
          )}
          {!!translation && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette traduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                Supprimer
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type OfferTranslationFormProps = ChildFormProps<
  OfferTranslationFormValues,
  void
> & {
  className?: string;
  offerId: string;
  translation?: OfferTranslationFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: OfferTranslationFormCreateMutation['offerTranslationCreate'],
  ) => void;
  onUpdated?: (
    v: OfferTranslationFormUpdateMutation['offerTranslationUpdate'],
  ) => void;
  onDeleted?: (
    v: OfferTranslationFormDeleteMutation['offerTranslationDelete'],
  ) => void;
};
