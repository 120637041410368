import { RouteComponentProps, useRouteMatch } from 'react-router';
import { Layout, Spin } from 'antd';

import { CampaignSelectionIdCampaignsView } from '../CampaignSelectionIdCampaignsView/CampaignSelectionIdCampaignsView';
import { CampaignSelectionIdTranslationsView } from '../CampaignSelectionIdTranslationsView/CampaignSelectionIdTranslationsView';

import { CampaignSelectionHeader } from '@components/organisms/CampaignSelectionHeader/CampaignSelectionHeader';
import CampaignSelectionIdSettingsView from '@components/views/CampaignSelections/CampaignSelectionIdSettingsView/CampaignSelectionIdSettingsView';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { CompanyIncludedView } from '@views/CompanyIncludedView/CompanyIncludedView';
import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CampaignSelectionIdView.module.css';

import { useCampaignSelectionIdViewQuery } from './CampaignSelectionIdView.generated';

export const CampaignSelectionIdView: React.FC<CampaignSelectionIdViewProps> = (
  props,
) => {
  const {
    className,
    match: {
      params: { campaignSelectionId },
    },
  } = props;

  const match = useRouteMatch();

  const campaignSelectionQuery = useCampaignSelectionIdViewQuery({
    variables: { campaignSelectionId },
    notifyOnNetworkStatusChange: true,
  });

  const campaignSelection = campaignSelectionQuery?.data?.campaignSelection;

  if (campaignSelectionQuery.loading && !campaignSelection) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!campaignSelection) {
    return <NotFoundView />;
  }

  return (
    <Layout className={className}>
      <CampaignSelectionHeader
        className={styles.header}
        campaignSelection={campaignSelection}
      />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label="Description/Configuration"
        >
          <CampaignSelectionIdTranslationsView
            campaignSelectionId={campaignSelection.id}
          />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/campaigns`}
          exact
          label="Campagnes"
        >
          <CampaignSelectionIdCampaignsView
            campaignSelectionId={campaignSelection.id}
          />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/settings`} label="Paramètres">
          <CampaignSelectionIdSettingsView
            campaignSelectionId={campaignSelection.id}
          />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/include`} label="Exclusivité">
          <CompanyIncludedView
            entityId={campaignSelection.id}
            entityType={'campaignSelection'}
          />
        </TabsRouter.Route>
      </TabsRouter>
    </Layout>
  );
};

export type CampaignSelectionIdViewProps = RouteComponentProps<{
  campaignSelectionId: string;
}> & {
  className?: string;
};
