import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Empty } from 'antd';

import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

import style from './CompanyFeatureConfigView.module.css';

import { CompanyFeatureConfigBeneficiaryView } from './views/CompanyFeatureConfigBeneficiaryView/CompanyFeatureConfigBeneficiaryView';
import { CompanyFeatureConfigGatheringView } from './views/CompanyFeatureConfigGatheringView/CompanyFeatureConfigGatheringView';
import { CompanyFeatureConfigSurveyView } from './views/CompanyFeatureConfigSurveyView/CompanyFeatureConfigSurveyView';
import { CompanyFeatureConfigUserIntegrationConnectorIdView } from './views/CompanyFeatureConfigUserIntegrationConnectorIdView/CompanyFeatureConfigUserIntegrationConnectorIdView';
import { CompanyFeatureConfigUserIntegrationView } from './views/CompanyFeatureConfigUserIntegrationView/CompanyFeatureConfigUserIntegrationView';

interface CompanyFeatureConfigViewProps {
  companyId: string;
}

export const CompanyFeatureConfigView = (
  props: CompanyFeatureConfigViewProps,
) => {
  const { companyId } = props;
  const match = useRouteMatch();

  return (
    <div className={style.root}>
      <TabsRouter replace match={match} tabPosition="left">
        <TabsRouter.Route
          label="UserIntegration"
          path={`${match.url}/user-integration`}
        >
          <Switch>
            <Route path={`${match.url}/user-integration/:connectorId`}>
              <CompanyFeatureConfigUserIntegrationConnectorIdView
                companyId={companyId}
              />
            </Route>
            <Route path={`${match.url}/user-integration`}>
              <CompanyFeatureConfigUserIntegrationView companyId={companyId} />
            </Route>
          </Switch>
        </TabsRouter.Route>
        <TabsRouter.Route
          disabled
          label="Personnalisation"
          path={`${match.url}/customisation`}
        >
          <Empty />
        </TabsRouter.Route>
        <TabsRouter.Route label="Événements" path={`${match.url}/gathering`}>
          <CompanyFeatureConfigGatheringView companyId={companyId} />
        </TabsRouter.Route>
        <TabsRouter.Route
          label="Ayants-droit"
          path={`${match.url}/beneficiary`}
        >
          <CompanyFeatureConfigBeneficiaryView companyId={companyId} />
        </TabsRouter.Route>
        <TabsRouter.Route label="Sondages" path={`${match.url}/surveys`}>
          <CompanyFeatureConfigSurveyView companyId={companyId} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};
