import { Spin } from 'antd';
import classnames from 'classnames';

import { OrdersTable } from '@components/tables/OrdersTable/OrdersTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './UserIdOrdersView.module.css';

import { useUserIdOrdersViewQuery } from './UserIdOrdersView.generated';

export const UserIdOrdersView: React.FC<UserIdOrdersViewProps> = (props) => {
  const { className, userId } = props;

  const query = useUserIdOrdersViewQuery({
    variables: {
      userId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = query?.data?.user;

  if (query.loading && !user) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!user) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <OrdersTable
        activeColumns={[
          'invoice.identifier',
          'campaigns',
          'createdAt',
          'updatedAt',
          'amount',
          'status',
        ]}
        query={query}
        data={query.data?.user?.orderPagination}
      />
    </div>
  );
};

export type UserIdOrdersViewProps = {
  userId: string;
  className?: string;
};
