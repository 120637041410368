import { initReactI18next } from 'react-i18next';
import languages from '@cospired/i18n-iso-languages';
import languagesEn from '@cospired/i18n-iso-languages/langs/en.json';
import languagesFr from '@cospired/i18n-iso-languages/langs/fr.json';
import { format as dateFormat, formatDistanceToNow } from 'date-fns';
import { enUS as dateEn, fr as dateFr } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { isDineroObject } from '@utils/isDineroObject';

export const supportedLocales = <const>['fr', 'en'];

const dateLocales: Record<typeof supportedLocales[number], Locale> = {
  fr: dateFr,
  en: dateEn,
};

// Support german & english languages.
languages.registerLocale(languagesFr);
languages.registerLocale(languagesEn);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: supportedLocales[0],
    supportedLngs: supportedLocales,
    returnEmptyString: false,
    debug: false,
    interpolation: {
      escapeValue: false,
      alwaysFormat: true,
      format: function (value, format, lng = 'fr') {
        const locale =
          supportedLocales.find((v) => v === lng) || supportedLocales[0];

        if (value instanceof Date && format?.startsWith('distance')) {
          return formatDistanceToNow(value, {
            addSuffix: true,
            locale: dateLocales[locale],
          });
        } else if (value instanceof Date) {
          return dateFormat(value, format || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
            locale: dateLocales[locale],
          });
        } else if (isDineroObject(value)) {
          return value.setLocale(locale).toFormat(format);
        } else if (typeof value === 'number') {
          return Intl.NumberFormat(locale).format(value);
        }

        return value;
      },
    },
    defaultNS: 'common',
    ns: ['common', 'error', 'data', 'form'],
    detection: {
      order: ['path', 'navigator'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
