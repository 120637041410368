import { Button, message } from 'antd';
import { startOfTomorrow } from 'date-fns';
import * as Yup from 'yup';

import { SubventionState } from '@graphql/generated/types';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';
import { SubventionTimelineCardFragment } from '@components/views/Subventions/SubventionIdView/components/SubventionTimelineCard/SubventionTimelineCard.generated';

import {
  SubventionDatesUpdateFormMutationVariables,
  SubventionExtendMutationVariables,
  useSubventionDatesUpdateFormMutation,
  useSubventionExtendMutation,
} from './SubventionDatesUpdateForm.generated';

export type SubventionDatesUpdateFormValues = {
  startsAt?: Date;
  endsAt?: Date;
};

export type SubventionDatesUpdateFormProps =
  ChildFormProps<SubventionDatesUpdateFormValues> & {
    subvention: SubventionTimelineCardFragment;
    className?: string;
    defaultValues?: SubventionDatesUpdateFormValues;
    hideFooter?: boolean;
  };

export const SubventionDatesUpdateForm = (
  props: SubventionDatesUpdateFormProps,
) => {
  const { className, defaultValues, hideFooter, subvention, ...rest } = props;

  const validationSchema = Yup.object({
    startsAt: Yup.date(),
    endsAt: Yup.date(),
  }).required();

  const form = useForm<SubventionDatesUpdateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useSubventionDatesUpdateFormMutation();
  const [extendMutation] = useSubventionExtendMutation();

  const handleExtend = async (values: SubventionDatesUpdateFormValues) => {
    const { endsAt } = values;
    if (!endsAt) {
      message.error('La date de fin est obligatoire');
      return;
    }

    const variables: SubventionExtendMutationVariables = {
      subventionId: subvention.id,
      input: {
        endsAt,
      },
    };

    try {
      const result = await extendMutation({ variables });
      return result;
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message);
        throw new Error(error.message);
      }
    }
  };

  const handleUpdate = async (values: SubventionDatesUpdateFormValues) => {
    const variables: SubventionDatesUpdateFormMutationVariables = {
      subventionId: subvention.id,
      input: {
        endsAt: values.endsAt,
      },
    };

    if (
      [
        SubventionState.Draft,
        SubventionState.Confirmed,
        SubventionState.Ready,
      ].includes(subvention.state)
    ) {
      variables.input.startsAt = values.startsAt;
    }

    try {
      const result = await mutation({ variables });
      return result;
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message);
        throw new Error(error.message);
      }
    }
  };

  const handleSubmit: FormProps<SubventionDatesUpdateFormValues>['onValid'] =
    async (values) => {
      if (subvention.state === SubventionState.Completed) {
        await handleExtend(values);
      } else {
        await handleUpdate(values);
      }
    };

  return (
    <Form
      id="SubventionDatesUpdateForm"
      onValid={handleSubmit}
      form={form}
      {...rest}
    >
      {![SubventionState.Published, SubventionState.Completed].includes(
        subvention.state,
      ) && (
        <FormItem required label="Commence le" name="startsAt">
          <FormInputDate
            name="startsAt"
            disabledDate={(date) => date.isSameOrBefore(startOfTomorrow())}
          />
        </FormItem>
      )}

      <FormItem required label="Termine le" name="endsAt">
        <FormInputDate
          name="endsAt"
          disabledDate={(date) =>
            date.isSameOrBefore(form.getValues().startsAt || startOfTomorrow())
          }
        />
      </FormItem>

      {!hideFooter && (
        <Button
          loading={form.formState.isSubmitting}
          type="primary"
          htmlType="submit"
        >
          Valider
        </Button>
      )}
    </Form>
  );
};
