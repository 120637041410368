import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Spin } from 'antd';
import qs from 'qs';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { CompanyMembershipRole, UserStatus } from '@graphql/generated/types';

import { CompanyMembershipRoleTag } from '@components/atoms/CompanyMembershipRoleTag/CompanyMembershipRoleTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { UserStatusTag } from '@components/atoms/UserStatusTag/UserStatusTag';
import { TableRendererAvatar } from '@components/molecules/TableRendererAvatar/TableRendererAvatar';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CompanyMembershipsTableQueryResult,
  CompanyMembershipsTableQueryVariables,
  CompanyMembershipsTableRecordFragment,
  useCompanyMembershipsTableQuery,
} from './CompanyMembershipsTable.generated';

type RecordType = CompanyMembershipsTableRecordFragment;

type Column =
  | 'avatar'
  | 'fullName'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'status'
  | 'nationality'
  | 'birthdate'
  | 'role'
  | 'lastLoginAt'
  | 'lastActiveAt'
  | 'joinedAt'
  | 'leftAt'
  | 'customFields'
  | 'deactivated';

export function CompanyMembershipsTable(props: CompanyMembershipsTableProps) {
  const { companyId, filter, sorter } = props;

  const query = useCompanyMembershipsTableQuery({
    variables: {
      companyId,
      skip: 0,
      take: 10,
      filter,
      sorter,
    },
  });

  if (query.loading && !query.data) {
    return <Spin />;
  } else {
    return <CompanyMembershipsTableInner query={query} {...props} />;
  }
}

function CompanyMembershipsTableInner(
  props: CompanyMembershipsTableProps & {
    query: CompanyMembershipsTableQueryResult;
  },
) {
  const { className, filter, sorter, companyId, query, ...rest } = props;

  const company = (query as CompanyMembershipsTableQueryResult).data!.company!;

  const { t } = useTranslation('companyMemberships');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'avatar',
      title: t('table.headers.avatar'),
      dataIndex: ['user', 'avatar'],
      width: 64,
      render: (avatar, node) => (
        <TableRendererAvatar
          picture={avatar}
          placeholder={`${node.user.firstName[0]}${node.user.lastName[0]}`}
        />
      ),
    },
    {
      key: 'fullName',
      title: t('table.headers.fullName'),
      render: (_, node) => (
        <I18nLink to={`/companies/list/${company.id}/members/${node.id}`}>
          {node.user.firstName} {node.user.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `user.fullName`, 'search'),
    },
    {
      key: 'firstName',
      title: t('table.headers.firstName'),
      dataIndex: ['user', 'firstName'],
      render: (firstName, node) => (
        <I18nLink to={`/companies/list/${company.id}/members/${node.id}`}>
          {firstName}
        </I18nLink>
      ),
      sorter: true,
      ...generateColumnFilter(query, `user.firstName`, 'search'),
    },
    {
      key: 'lastName',
      title: t('table.headers.lastName'),
      dataIndex: ['user', 'lastName'],
      render: (email, node) => (
        <I18nLink to={`/companies/list/${company.id}/members/${node.id}`}>
          {email}
        </I18nLink>
      ),
      sorter: true,
      ...generateColumnFilter(query, `user.lastName`, 'search'),
    },
    {
      key: 'email',
      title: t('table.headers.email'),
      dataIndex: ['user', 'email'],
      render: (email, node) => (
        <I18nLink
          copyable
          ellipsis={true}
          to={`/companies/list/${company.id}/members/${node.id}`}
        >
          {email}
        </I18nLink>
      ),
      sorter: true,
      ...generateColumnFilter(query, `user.email`, 'search'),
    },
    {
      key: 'birthdate',
      title: t('table.headers.birthdate'),
      dataIndex: ['user', 'birthdate'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['user'],
      render: (user) => <UserStatusTag user={user} />,
      ...generateColumnFilter(query, 'user.status', 'enum', [
        {
          value: UserStatus.Created,
          label: t('users:userStatusTag.CREATED'),
        },
        {
          value: UserStatus.Invited,
          label: t('users:userStatusTag.INVITED'),
        },
        {
          value: UserStatus.Activated,
          label: t('users:userStatusTag.ACTIVATED'),
        },
        {
          value: UserStatus.Banned,
          label: t('users:userStatusTag.BANNED'),
        },
        {
          value: UserStatus.Archived,
          label: t('users:userStatusTag.ARCHIVED'),
        },
      ]),
    },
    {
      key: 'role',
      title: t('table.headers.role'),
      render: (membership) => (
        <CompanyMembershipRoleTag membership={membership} />
      ),
      ...generateColumnFilter(query, 'role', 'enum', [
        {
          value: CompanyMembershipRole.Admin,
          label: t('companies:companyMembershipRoleTag.ADMIN'),
        },
        {
          value: CompanyMembershipRole.Member,
          label: t('companies:companyMembershipRoleTag.USER'),
        },
      ]),
    },
    {
      key: 'lastLoginAt',
      title: t('table.headers.lastLoginAt'),
      dataIndex: ['user', 'lastLoginAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'lastActiveAt',
      title: t('table.headers.lastActiveAt'),
      dataIndex: ['user', 'lastActiveAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'joinedAt',
      title: t('table.headers.joinedAt'),
      dataIndex: ['joinedAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `joinedAt`, 'date'),
    },
    {
      key: 'leftAt',
      title: t('table.headers.leftAt'),
      dataIndex: ['leftAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `leftAt`, 'date'),
    },
    {
      key: 'deactivated',
      title: t('table.headers.deactivated'),
      dataIndex: ['deactivatedAt'],
      render: (deactivatedAt) => <Checkbox checked={deactivatedAt} disabled />,
    },
  ];

  if (company.customFieldsPagination.totalCount) {
    company.customFieldsPagination.nodes.forEach((node) => {
      columns.push({
        key: `customFields.${node.key}`,
        title: node.label,
        dataIndex: ['customFields', node.key],
        sorter: true,
        ...generateColumnFilter(query, `customFields.${node.key}`, 'search'),
      });
    });
  }

  return (
    <div className={className}>
      <TablePagination
        id="CompanyMembershipsTable"
        query={query}
        columns={columns}
        sorterConverter={(sorter) => {
          const convertedSorter: CompanyMembershipsTableQueryVariables['sorter'] =
            sorter;

          if (sorter?.customFields) {
            const customFields = Object.keys(sorter.customFields).map(
              (key) => ({
                key,
                direction: sorter.customFields[key],
              }),
            );

            sorter.customFields = customFields;
          }

          return convertedSorter;
        }}
        title={() => (
          <I18nLink
            to={{
              pathname: `/users/create`,
              search: qs.stringify({ companyId: company.id }),
            }}
          >
            <Button>Ajouter un membre</Button>
          </I18nLink>
        )}
        data={company.membershipsPagination}
        {...rest}
      />
    </div>
  );
}

export type CompanyMembershipsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  companyId: string;
  className?: string;
  filter?: CompanyMembershipsTableQueryVariables['filter'];
  sorter?: CompanyMembershipsTableQueryVariables['sorter'];
};
