import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { SubventionReimbursementRequestStatusTagFragment } from './SubventionReimbursementStatusTag.generated';

type SubventionReimbursementStateTagProps = {
  subventionReimbursementRequest: SubventionReimbursementRequestStatusTagFragment;
};

export function SubventionReimbursementStatusTag(
  props: SubventionReimbursementStateTagProps,
) {
  const { subventionReimbursementRequest } = props;
  const { t } = useTranslation('subventionReimbursements');

  const colors: Record<
    SubventionReimbursementRequestStatusTagFragment['status'],
    string
  > = {
    APPROVED: 'yellow',
    CANCELLED: 'grey',
    PAY_OUT_CREATED: 'yellow',
    PAY_OUT_FAILED: 'red',
    PAY_OUT_SUCCEEDED: 'green',
    REJECTED: 'red',
    WAITING_FOR_APPROVAL: 'blue',
  };

  const labels: Record<
    SubventionReimbursementRequestStatusTagFragment['status'],
    string
  > = {
    APPROVED: t('ReimbursementRequestStatus.Approved'),
    CANCELLED: t('ReimbursementRequestStatus.Cancelled'),
    PAY_OUT_CREATED: t('ReimbursementRequestStatus.PayOutCreated'),
    PAY_OUT_FAILED: t('ReimbursementRequestStatus.PayOutFailed'),
    PAY_OUT_SUCCEEDED: t('ReimbursementRequestStatus.PayOutSucceeded'),
    REJECTED: t('ReimbursementRequestStatus.Rejected'),
    WAITING_FOR_APPROVAL: t('ReimbursementRequestStatus.WaitingForApproval'),
  };

  return (
    <Tag color={colors[subventionReimbursementRequest.status]}>
      {labels[subventionReimbursementRequest.status]}
    </Tag>
  );
}
