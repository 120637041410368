import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Text from 'antd/lib/typography/Text';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  AdminCommentTargetType,
  OfferStatus,
  OfferType,
  SortDirection,
} from '@graphql/generated/types';

import { TableRendererDateTime } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { OfferStatusUpdateButton } from '@components/organisms/OfferStatusUpdateButton/OfferStatusUpdateButton';
import { I18nLink } from '@atoms/Link/Link';
import { OfferStatusTag } from '@atoms/OfferStatusTag/OfferStatusTag';
import { OfferTypeTag } from '@atoms/OfferTypeTag/OfferTypeTag';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import styles from './OffersTable.module.css';

import {
  OffersTableQueryVariables,
  OffersTableRecordFragment,
  useOffersTableOfferUpdateMutation,
  useOffersTableQuery,
} from './OffersTable.generated';

type RecordType = OffersTableRecordFragment;

type Column =
  | 'name'
  | 'brand'
  | 'campaign'
  | 'provider'
  | 'catalog'
  | 'categories'
  | 'type'
  | 'status'
  | 'position'
  | 'endsAt'
  | 'startsAt'
  | 'isExclusive'
  | 'publish';

export function OffersTable(props: OffersTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('offers');

  const [mutation] = useOffersTableOfferUpdateMutation();
  const [isUpdatingOrder, setUpdatingOrder] = useState(false);
  const handleUpdateOrder = async (offerId: string, index: number) => {
    if (isUpdatingOrder) return;
    setUpdatingOrder(true);

    try {
      await mutation({
        variables: {
          offerId,
          input: {
            position: index,
          },
        },
      });
      await query.refetch();
    } catch (err) {
      message.error('error:default');
    }

    setUpdatingOrder(false);
  };

  const query =
    propQuery ||
    useOffersTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });

  const isPositionable = () => {
    if (!query.variables) return false;

    const { filter, sorter } = query.variables;

    return (
      sorter?.position === 'ASC' &&
      Object.values(sorter).length === 1 &&
      filter?.campaign?.id &&
      Object.values(filter).length === 1
    );
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      sorter: true,
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/offers/list/${node.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, `name`, 'search'),
    },
    {
      key: 'type',
      title: t('table.headers.type'),
      sorter: true,
      dataIndex: ['type'],
      render: (_, node) => <OfferTypeTag offer={node} />,
      ...generateColumnFilter(query, 'type', 'enum', [
        {
          label: t('offers:type.couponClaimable'),
          value: OfferType.CouponClaimable,
        },
        {
          label: t('offers:type.couponPublic'),
          value: OfferType.CouponPublic,
        },
        {
          label: t('offers:type.giftCard'),
          value: OfferType.GiftCard,
        },
        {
          label: t('offers:type.ticket'),
          value: OfferType.Ticket,
        },
        {
          label: t('offers:type.url'),
          value: OfferType.Url,
        },
        {
          label: t('offers:type.base'),
          value: OfferType.Base,
        },
      ]),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      sorter: true,
      dataIndex: ['status'],
      render: (_, node) => <OfferStatusTag offer={node} />,
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('offers:status.draft'),
          value: OfferStatus.Draft,
        },
        {
          label: t('offers:status.published'),
          value: OfferStatus.Published,
        },
        {
          label: t('offers:status.unpublished'),
          value: OfferStatus.Unpublished,
        },
      ]),
    },
    {
      key: 'endsAt',
      title: t('table.headers.endsAt'),
      render: (_, node) =>
        node.endsAt && <TableRendererDateTime value={node.endsAt} />,
    },
    {
      key: 'startsAt',
      title: t('table.headers.startsAt'),
      render: (_, node) =>
        node.startsAt && <TableRendererDateTime value={node.startsAt} />,
    },
    {
      key: 'availableOnline',
      title: t('table.headers.availableOnline'),
      render: (_, node) => (
        <Text>
          {' '}
          {node.availableOnline
            ? t('table.availability.yes')
            : t('table.availability.no')}
        </Text>
      ),
      ...generateColumnFilter(query, 'availableOnline', 'boolean', [
        {
          label: t('table.availability.yes'),
          value: 'true',
        },
        {
          label: t('table.availability.no'),
          value: 'false',
        },
        {
          label: t('table.availability.all'),
          value: 'all',
        },
      ]),
    },
    {
      key: 'availableCountry',
      title: t('table.headers.availableCountry'),
      render: (_, node) => (
        <Text>
          {' '}
          {node.availableCountry
            ? t('table.availability.yes')
            : t('table.availability.no')}
        </Text>
      ),
      ...generateColumnFilter(query, 'availableCountry', 'boolean', [
        {
          label: t('table.availability.yes'),
          value: 'true',
        },
        {
          label: t('table.availability.no'),
          value: 'false',
        },
        {
          label: t('table.availability.all'),
          value: 'all',
        },
      ]),
    },
    {
      key: 'brand',
      title: t('table.headers.brand'),
      sorter: true,
      dataIndex: ['brand', 'name'],
      render: (name, node) =>
        node.brand ? (
          <I18nLink to={`/brands/list/${node.brand.id}`}>{name}</I18nLink>
        ) : (
          'N/A'
        ),
      ...generateColumnFilter(query, 'brand.name', 'search'),
    },
    {
      key: 'campaign',
      title: t('table.headers.campaign'),
      sorter: true,
      dataIndex: ['campaign', 'name'],
      render: (name, node) =>
        node.campaign ? (
          <I18nLink to={`/campaigns/list/${node.campaign.id}`}>{name}</I18nLink>
        ) : (
          'N/A'
        ),
      ...generateColumnFilter(query, 'campaign.name', 'search'),
    },
    {
      key: 'categories',
      title: t('table.headers.categories'),
      render: (_, node) =>
        node.categoriesPagination.nodes.map((cat, i, arr) => (
          <span key={cat.id}>
            <I18nLink key={cat.id} to={`/categories/list/${cat.id}`}>
              {cat.name}
            </I18nLink>
            {i !== arr.length - 1 && ', '}
          </span>
        )),
    },
    {
      key: 'provider',
      title: t('table.headers.provider'),
      sorter: true,
      dataIndex: ['product', 'catalog', 'provider', 'name'],
      render: (name, node) =>
        node.__typename === 'OfferGiftCard' ||
        node.__typename === 'OfferTicket' ? (
          <I18nLink
            to={`/products/catalogs/list/${node.product.catalog.provider.id}`}
          >
            {name}
          </I18nLink>
        ) : (
          'N/A'
        ),
      ...generateColumnFilter(query, 'product.catalog.provider.name', 'search'),
    },
    {
      key: 'catalog',
      title: t('table.headers.catalog'),
      sorter: true,
      dataIndex: ['product', 'catalog', 'name'],
      render: (name, node) =>
        node.__typename === 'OfferGiftCard' ||
        node.__typename === 'OfferTicket' ? (
          <I18nLink
            to={`/products/providers/list/${node.product.catalog.provider.id}`}
          >
            {name}
          </I18nLink>
        ) : (
          'N/A'
        ),
      ...generateColumnFilter(query, 'product.catalog.name', 'search'),
    },
    {
      key: 'position',
      title: t('table.headers.position'),
      dataIndex: ['position'],
      width: 30,
      sorter: true,
      defaultSortOrder: sorter?.position
        ? sorter.position === SortDirection.Asc
          ? 'ascend'
          : 'descend'
        : undefined,
      render: (position, offer) =>
        isPositionable() && (
          <div
            className={styles.orderCell}
            onClick={(e) => e.stopPropagation()}
          >
            {position > 0 && (
              <UpOutlined
                onClick={() => handleUpdateOrder(offer.id, position - 1)}
                className={styles.orderIcon}
              />
            )}
            {position + 1 <
              ((query.data?.offersPagination || propData).totalCount || 0) && (
              <DownOutlined
                onClick={() => handleUpdateOrder(offer.id, position + 1)}
                className={styles.orderIcon}
              />
            )}
          </div>
        ),
    },
    {
      key: 'isExclusive',
      title: t('table.headers.isExclusive'),
      render: (_, node) => (
        <Text>
          {' '}
          {node.isExclusive
            ? t('table.isExlusive.yes')
            : t('table.isExlusive.no')}
        </Text>
      ),
      ...generateColumnFilter(query, 'isExclusive', 'boolean', [
        {
          label: t('table.isExlusive.yes'),
          value: 'true',
        },
        {
          label: t('table.isExlusive.no'),
          value: 'false',
        },
        {
          label: t('table.isExlusive.all'),
          value: 'all',
        },
      ]),
    },
    {
      key: 'publish',
      title: 'Publication',
      render: (_, node) => <OfferStatusUpdateButton offer={node} />,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="OffersTable"
        query={query}
        columns={columns}
        data={propData || query.data?.offersPagination}
        adminCommentTargetType={AdminCommentTargetType.Offer}
        sorter={sorter}
        {...rest}
      />
    </div>
  );
}

export type OffersTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  sorter?: OffersTableQueryVariables['sorter'];
  filter?: OffersTableQueryVariables['filter'];
};
