import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { ProductSynchronizationIdCreatedView } from '../ProductSynchronizationIdCreatedView/ProductSynchronizationIdCreatedView';
import { ProductSynchronizationIdDeletedView } from '../ProductSynchronizationIdDeletedView/ProductSynchronizationIdDeletedView';
import { ProductSynchronizationIdUpdatedView } from '../ProductSynchronizationIdUpdatedView/ProductSynchronizationIdUpdatedView';

import { ProductSynchronizationStatus } from '@graphql/generated/types';

import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ProductSynchronizationIdView.module.css';

import { useProductSynchronizationIdViewQuery } from './ProductSynchronizationIdView.generated';

export const ProductSynchronizationIdView: React.FC<ProductSynchronizationIdViewProps> =
  (props) => {
    const { className, match } = props;
    const {
      params: { productSynchronizationId },
    } = match;

    const [isRunning, setRunning] = useState(false);

    const query = useProductSynchronizationIdViewQuery({
      variables: { productSynchronizationId },
      notifyOnNetworkStatusChange: true,
      pollInterval: isRunning ? 1000 : undefined,
      onCompleted(data) {
        if (
          data?.productSynchronization?.status &&
          [
            ProductSynchronizationStatus.Active,
            ProductSynchronizationStatus.Pending,
          ].includes(data?.productSynchronization?.status)
        ) {
          setRunning(true);
        } else {
          setRunning(false);
        }
      },
    });

    const productSynchronization = query?.data?.productSynchronization;

    if (query.loading && !productSynchronization) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!productSynchronization) {
      return <NotFoundView />;
    }

    const createdCount = [
      query.data?.productSynchronization?.brandsCreatedPagination,
      query.data?.productSynchronization?.createdCampaignPagination,
      query.data?.productSynchronization?.offersCreatedPagination,
      query.data?.productSynchronization?.productsCreatedPagination,
      query.data?.productSynchronization?.productVariantsCreatedPagination,
    ].reduce((sum, cur) => sum + (cur?.totalCount || 0), 0);

    const updatedCount = [
      query.data?.productSynchronization?.offersUpdatedPagination,
      query.data?.productSynchronization?.productsUpdatedPagination,
      query.data?.productSynchronization?.productVariantsUpdatedPagination,
    ].reduce((sum, cur) => sum + (cur?.totalCount || 0), 0);

    const deletedCount = [
      query.data?.productSynchronization?.offersDeletedPagination,
      query.data?.productSynchronization?.productsDeletedPagination,
      query.data?.productSynchronization?.productVariantsDeletedPagination,
    ].reduce((sum, cur) => sum + (cur?.totalCount || 0), 0);

    return (
      <div className={classnames(className, styles.root)}>
        {isRunning ? (
          <div className={styles.placeholder}>
            <Spin size="large" className={styles.spinner} />
            <div className={styles.placeholderTitle}>
              Synchro en cours (la page va se rafraichir automatiquement)
            </div>
          </div>
        ) : (
          <TabsRouter replace match={match} className={styles.router}>
            <TabsRouter.Route
              path={`${match.url}`}
              exact
              label={`Créations (${createdCount})`}
            >
              <ProductSynchronizationIdCreatedView
                productSynchronizationId={productSynchronizationId}
                showBrands={
                  (query.data?.productSynchronization?.brandsCreatedPagination
                    .totalCount || 0) > 0
                }
                showCampaigns={
                  (query.data?.productSynchronization?.createdCampaignPagination
                    .totalCount || 0) > 0
                }
                showOffers={
                  (query.data?.productSynchronization?.offersCreatedPagination
                    .totalCount || 0) > 0
                }
                showProducts={
                  (query.data?.productSynchronization
                    ?.productVariantsCreatedPagination.totalCount || 0) > 0
                }
                showProductVariants={
                  (query.data?.productSynchronization
                    ?.productVariantsCreatedPagination.totalCount || 0) > 0
                }
              />
            </TabsRouter.Route>
            <TabsRouter.Route
              path={`${match.url}/updated`}
              label={`Mises à jours (${updatedCount})`}
            >
              <ProductSynchronizationIdUpdatedView
                productSynchronizationId={productSynchronizationId}
                showOffers={
                  (query.data?.productSynchronization?.offersUpdatedPagination
                    .totalCount || 0) > 0
                }
                showProducts={
                  (query.data?.productSynchronization
                    ?.productVariantsUpdatedPagination.totalCount || 0) > 0
                }
                showProductVariants={
                  (query.data?.productSynchronization
                    ?.productVariantsUpdatedPagination.totalCount || 0) > 0
                }
              />
            </TabsRouter.Route>
            <TabsRouter.Route
              path={`${match.url}/deleted`}
              label={`Suppressions (${deletedCount})`}
            >
              <ProductSynchronizationIdDeletedView
                productSynchronizationId={productSynchronizationId}
                showOffers={
                  (query.data?.productSynchronization?.offersDeletedPagination
                    .totalCount || 0) > 0
                }
                showProducts={
                  (query.data?.productSynchronization?.productsDeletedPagination
                    .totalCount || 0) > 0
                }
                showProductVariants={
                  (query.data?.productSynchronization
                    ?.productVariantsDeletedPagination.totalCount || 0) > 0
                }
              />
            </TabsRouter.Route>
          </TabsRouter>
        )}
      </div>
    );
  };

export type ProductSynchronizationIdViewProps = RouteComponentProps<{
  productSynchronizationId: string;
}> & {
  className?: string;
};
