import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionSchemasTable } from '@components/tables/SubventionSchemasTable/SubventionSchemasTable';

import { useSubventionSchemasListViewQuery } from './SubventionSchemasListView.generated';

export const SubventionSchemasListView = () => {
  const { t } = useTranslation('subvention-schemas');

  const query = useSubventionSchemasListViewQuery({
    variables: {
      take: 10,
      skip: 0,
    },
  });

  return (
    <SubventionSchemasTable
      activeColumns={['name', 'status']}
      query={query}
      data={query.data?.subventionSchemaPagination || null}
      title={() => (
        <I18nLink to="/subventions/schemas/create">
          <Button>{t('create')}</Button>
        </I18nLink>
      )}
    />
  );
};
