import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import classnames from 'classnames';

import { CompanyExcludeEntityModal } from '@components/modals/CompanyExcludeEntityModal/CompanyExcludeEntityModal';
import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';
import { useCompaniesTableQuery } from '@components/tables/CompaniesTable/CompaniesTable.generated';

import styles from './CompanyExcludedView.module.css';

import {
  useAddExcludedArticleMutation,
  useRemoveExcludedArticleMutation,
} from './CompanyExcludedView.generated';

type EntityType = 'article';

type ActionType = 'create' | 'remove';

export const excludeEntityActions: Record<
  EntityType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<ActionType, any>
> = {
  article: {
    create: useAddExcludedArticleMutation,
    remove: useRemoveExcludedArticleMutation,
  },
};

export const CompanyExcludedView: React.FC<CompanyExcludedViewProps> = (
  props,
) => {
  const { className, entityId, entityType } = props;
  const [isOpen, setOpen] = useState(false);
  const [removeMutation] = excludeEntityActions[entityType].remove();
  const { t } = useTranslation('companies');
  let filter;

  if (entityType === 'article') {
    filter = { excludedArticles: { SOME: { id: { is: entityId } } } };
  }

  const query = useCompaniesTableQuery({
    variables: {
      filter,
      take: 10,
      skip: 0,
    },
  });

  const handleDelete = (companyId: string) => async () => {
    let input;

    if (entityType === 'article') {
      input = {
        articleIds: [entityId],
      };
    }

    await removeMutation({
      variables: {
        companyId,
        input,
      },
    });

    await query.refetch();
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div>
        <CompaniesTable
          title={() => (
            <Space>
              <Button
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => setOpen(true)}
              >
                Exclure une société
              </Button>
            </Space>
          )}
          activeColumns={['id', 'name', 'createdAt']}
          query={query}
          action={(record) => (
            <Popconfirm
              title={t('excludedCompanies.action.delete.warning')}
              okText={t('excludedCompanies.action.delete.button')}
              okType="danger"
              onConfirm={handleDelete(record.id)}
            >
              <Button type="text" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        />
        <CompanyExcludeEntityModal
          entityId={entityId}
          entityType={entityType}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      </div>
    </div>
  );
};

export type CompanyExcludedViewProps = {
  className?: string;
  entityId: string;
  entityType: EntityType;
};
