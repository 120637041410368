import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import classnames from 'classnames';

import { OfferStatus } from '@graphql/generated/types';

import styles from './OfferStatusUpdateButton.module.css';

import {
  OfferStatusUpdateButtonFragment,
  useOfferStatusUpdateButtonPublishMutation,
  useOfferStatusUpdateButtonUnpublishMutation,
} from './OfferStatusUpdateButton.generated';

export const OfferStatusUpdateButton: React.FC<OfferStatusUpdateButtonProps> = (
  props,
) => {
  const { className, offer } = props;
  const { t } = useTranslation('offers');

  const [publishMutation] = useOfferStatusUpdateButtonPublishMutation();
  const [unpublishMutation] = useOfferStatusUpdateButtonUnpublishMutation();
  const [isSubmitting, setSubmitting] = useState(false);

  const handlePublish = async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await publishMutation({ variables: { offerId: offer.id } });
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            case 'offer/no-categories':
              message.error(t(`error:offer/no-categories`));
              break;
            case 'offer/no-voucher':
              message.error(t(`error:offer/no-voucher`));
              break;
            case 'offer/invalid-status':
              message.error(t(`error:offer/invalid-status`));
              break;
            case 'offer/campaign-not-published':
              message.error(t(`error:offer/campaign-not-published`));
              break;
            case 'product/no-variants':
              message.error(t(`error:product/no-variants`));
              break;
            case 'product/has-different-variant-price-types':
              message.error(
                t(`error:product/has-different-variant-price-types`),
              );
              break;
            case 'coupon-claimable/out-of-stock':
              message.error(t(`error:coupon-claimable/out-of-stock`));
              break;
            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
    setSubmitting(false);
  };

  const handleUnpublish = async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await unpublishMutation({ variables: { offerId: offer.id } });
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            case 'offer/invalid-status':
              message.error(t(`error:offer/invalid-status`));
              break;
            default:
              message.error(t(`error:default`));
          }
        });
      } else {
        message.error('error:default');
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      {offer.status !== OfferStatus.Published ? (
        <Popconfirm
          onConfirm={handlePublish}
          title={t('OfferStatusUpdate.publish.confirm')}
        >
          <Button
            loading={isSubmitting}
            type="primary"
            className={classnames(className, styles.root)}
          >
            {t('OfferStatusUpdate.publish.label')}
          </Button>
        </Popconfirm>
      ) : (
        <Popconfirm
          onConfirm={handleUnpublish}
          title={t('OfferStatusUpdate.unpublish.confirm')}
        >
          <Button
            loading={isSubmitting}
            className={classnames(className, styles.root)}
          >
            {t('OfferStatusUpdate.unpublish.label')}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export type OfferStatusUpdateButtonProps = {
  className?: string;
  offer: OfferStatusUpdateButtonFragment;
};
