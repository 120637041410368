import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { CountryCode, FileType, Scalars } from '@graphql/generated/types';

import {
  COVER_ASPECT_RATIO,
  COVER_RESOLUTION_INFO,
  COVER_SIZE_INFO,
} from '@utils/constants';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormPictureCrop } from '@components/atoms/FormPictureCrop/FormPictureCrop';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectBrand } from '@atoms/FormSelectBrand/FormSelectBrand';
import { FormSelectCountry } from '@atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignCreateForm.module.css';

import {
  CampaignCreateFormMutation,
  CampaignCreateFormMutationVariables,
  useCampaignCreateFormMutation,
} from './CampaignCreateForm.generated';

export type CampaignCreateFormValues = {
  country: CountryCode;
  brandId: string;
  localeId: string;
  description?: Scalars['Delta'] | null;
  instructions?: Scalars['Delta'] | null;
  name: string;
  startsAt?: Date | null;
  endsAt?: Date | null;
  coverId: string;
};

export function CampaignCreateForm(props: CampaignCreateFormProps) {
  const { className, defaultValues = {}, ...rest } = props;
  const { t } = useTranslation('common');

  const validationSchema = Yup.object({
    country: Yup.mixed().oneOf(Object.values(CountryCode)).required(),
    brandId: Yup.string().required(),
    localeId: Yup.string().required(),
    description: Yup.object().nullable(),
    instructions: Yup.object().nullable(),
    name: Yup.string().required(),
    startsAt: Yup.date().nullable(),
    endsAt: Yup.date().nullable(),
    coverId: Yup.string().required(),
  }).required();

  const form = useForm<CampaignCreateFormValues>({
    validationSchema,
    defaultValues: {
      country: CountryCode.Fr,
      localeId: 'fr',
      ...defaultValues,
    },
  });

  const [mutation] = useCampaignCreateFormMutation();

  const handleSubmit: FormProps<CampaignCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: CampaignCreateFormMutationVariables = {
      input: {
        country: values.country,
        brandId: values.brandId,
        localeId: values.localeId,
        description: values.description,
        instructions: values.instructions,
        name: values.name,
        startsAt: values.startsAt,
        endsAt: values.endsAt,
        coverId: values.coverId,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  const { startsAt, endsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.brand}
          required
          label="Marque"
          name="brandId"
        >
          <FormSelectBrand name="brandId" />
        </FormItem>
        <FormItem
          className={styles.country}
          required
          label="Pays"
          name="country"
        >
          <FormSelectCountry name="country" />
        </FormItem>
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.startsAt} label="Début" name="startsAt">
          <FormInputDate
            showTime={true}
            disabledDate={(date) => (endsAt ? date.isAfter(endsAt) : false)}
            name="startsAt"
          />
        </FormItem>
        <FormItem className={styles.endsAt} label="Fin" name="endsAt">
          <FormInputDate
            showTime={true}
            disabledDate={(date) =>
              startsAt ? date.isBefore(startsAt) : false
            }
            name="endsAt"
          />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          required
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <FormItem
          className={styles.instructions}
          required
          label="Mode d'emploi"
          name="instructions"
        >
          <FormRichTextEditor
            className={styles.instructionsInput}
            name="instructions"
          />
        </FormItem>
        <FormItem
          className={styles.cover}
          required
          label="Image de couverture"
          name="coverId"
        >
          <FormPictureCrop
            aspect={COVER_ASPECT_RATIO}
            name="coverId"
            type={FileType.CampaignCover}
            info={t('picture.advice', {
              resolution: COVER_RESOLUTION_INFO,
              size: COVER_SIZE_INFO,
            })}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la campagne
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CampaignCreateFormProps = ChildFormProps<
  CampaignCreateFormValues,
  CampaignCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<CampaignCreateFormValues>;
};
