import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectBrand.module.css';

import {
  FormSelectBrandQueryVariables,
  useFormSelectBrandQuery,
} from './FormSelectBrand.generated';

export const FormSelectBrand: React.FC<FormSelectBrandProps> = (props) => {
  const { className, filter, sorter, ...rest } = props;

  const { data, loading, variables, refetch } = useFormSelectBrandQuery({
    variables: {
      filter,
      sorter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = debounce((value: string) => {
    refetch({
      ...(variables || {}),
      filter: {
        ...(variables?.filter || {}),
        name: { search: value },
      },
    });
  }, 300);

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      options={data?.brandsPagination.nodes.map((node) => ({
        value: node.id,
        label: node.name,
      }))}
      {...rest}
    />
  );
};

export type FormSelectBrandProps = FormSelectProps & {
  filter?: FormSelectBrandQueryVariables['filter'];
  sorter?: FormSelectBrandQueryVariables['sorter'];
};
