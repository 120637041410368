import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import {
  HappeningUpdateForm,
  HappeningUpdateFormProps,
} from '@components/forms/HappeningUpdateForm/HappeningUpdateForm';

import styles from './HappeningUpdateView.module.css';

import { HappeningUpdateViewFragment } from './HappeningUpdateView.generated';

export const HappeningUpdateView: React.FC<HappeningUpdateViewProps> = (
  props,
) => {
  const { className, happening } = props;

  const { t, i18n } = useTranslation('happenings');
  const history = useHistory();

  const handleSuccess: HappeningUpdateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/happenings/list/${result.happeningUpdate.happening.id}/update`,
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('HappeningUpdateView.title')}</Typography.Title>
        <HappeningUpdateForm happening={happening} onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type HappeningUpdateViewProps = {
  className?: string;
  happening: HappeningUpdateViewFragment;
};
