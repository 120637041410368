import { useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import classNames from 'classnames';

import { CampaignStatus, DiscoverTargetType } from '@graphql/generated/types';

import { CampaignSearchModal } from '@components/modals/CampaignSearchModal/CampaignSearchModal';
import { CategorySearchModal } from '@components/modals/CategorySearchModal/CategorySearchModal';
import {
  CampaignCard,
  CampaignCardProps,
} from '@components/molecules/CampaignCard/CampaignCard';
import { CampaignCardFragment } from '@components/molecules/CampaignCard/CampaignCard.generated';
import {
  CategoryCard,
  CategoryCardProps,
} from '@components/molecules/CategoryCard/CategoryCard';
import { CategoryCardFragment } from '@components/molecules/CategoryCard/CategoryCard.generated';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './DiscoverEditor.module.css';

import {
  useDiscoverEditorCreateMutation,
  useDiscoverEditorDeleteMutation,
  useDiscoverEditorPositionUpdateMutation,
  useDiscoverEditorQuery,
} from './DiscoverEditor.generated';

export const DiscoverEditor = (props: DiscoverEditorProps) => {
  const { companyId, targetType, title } = props;
  const nbToTake = targetType === DiscoverTargetType.Campaign ? 10 : 6;

  const { data, loading, refetch } = useDiscoverEditorQuery({
    variables: {
      take: nbToTake,
      filter: {
        targetType: { is: targetType },
        company: {
          id: {
            is: companyId || null,
          },
        },
      },
    },
    onCompleted: (data) => setNodes(data?.discoverPagination?.nodes || []),
  });

  const [nodes, setNodes] = useState(data?.discoverPagination.nodes || []);
  const [isAdding, setAdding] = useState(false);

  const [updatePositionMutation] = useDiscoverEditorPositionUpdateMutation();
  const [createMutation] = useDiscoverEditorCreateMutation();
  const [deleteMutation] = useDiscoverEditorDeleteMutation();

  const sensors = useSensors(useSensor(PointerSensor));

  if (loading && !data?.discoverPagination) {
    return <Spin />;
  } else if (!data?.discoverPagination) {
    return <NotFoundView />;
  }

  const handleDragEnd = async (evt: DragEndEvent) => {
    const { active, over } = evt;

    if (!over || active.id === over.id) return;

    const oldIndex = nodes.findIndex((n) => n.id === active.id);
    const newIndex = nodes.findIndex((n) => n.id === over.id);

    setNodes((nodes) => arrayMove(nodes, oldIndex, newIndex));

    try {
      await updatePositionMutation({
        variables: {
          discoverId: active.id,
          input: {
            position: newIndex,
          },
        },
      });
    } catch (err) {
      setNodes((nodes) => arrayMove(nodes, newIndex, oldIndex));
    }
  };

  const handleDelete = async (discoverId: string) => {
    await deleteMutation({
      variables: {
        discoverId,
      },
    });

    await refetch();
  };

  const handleCreate = async (campaignId: string) => {
    setAdding(false);

    await createMutation({
      variables: {
        companyId,
        input: {
          targetType,
          targetId: campaignId,
        },
      },
    });

    await refetch();
  };

  return (
    <div>
      <Typography.Title level={3}>{title}</Typography.Title>
      <div className={styles.cards}>
        <div className={styles.cards__wrapper}>
          {nodes.length < nbToTake && (
            <div onClick={() => setAdding(true)} className={styles.cta}>
              <PlusOutlined size={32} />
            </div>
          )}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={nodes}
              strategy={horizontalListSortingStrategy}
            >
              {targetType === DiscoverTargetType.Campaign &&
                nodes.map((node) => (
                  <CampaignCardSortable
                    campaign={node.target as CampaignCardFragment}
                    sortableId={node.id}
                    key={node.id}
                    onDelete={() => handleDelete(node.id)}
                  />
                ))}
              {targetType === DiscoverTargetType.Category &&
                nodes.map((node) => (
                  <CategoryCardSortable
                    category={node.target as CategoryCardFragment}
                    sortableId={node.id}
                    key={node.id}
                    onDelete={() => handleDelete(node.id)}
                  />
                ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
      {targetType === DiscoverTargetType.Campaign && (
        <CampaignSearchModal
          onSelect={handleCreate}
          onClose={() => setAdding(false)}
          disabledCampaignIds={nodes.map(
            (n) => (n.target as CampaignCardFragment).id,
          )}
          isOpen={isAdding}
          filter={{
            status: { in: [CampaignStatus.Published] },
          }}
        />
      )}
      {targetType === DiscoverTargetType.Category && (
        <CategorySearchModal
          onSelect={handleCreate}
          onClose={() => setAdding(false)}
          disabledCategoryIds={nodes.map(
            (n) => (n.target as CategoryCardFragment).id,
          )}
          isOpen={isAdding}
        />
      )}
    </div>
  );
};

export type DiscoverEditorProps = {
  title: string;
  companyId?: string;
  targetType: DiscoverTargetType;
};

const CampaignCardSortable = (props: CampaignCardSortableProps) => {
  const { campaign, sortableId, onDelete, ...rest } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: sortableId || campaign.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.card, isDragging && styles['-isDragging'])}
      style={style}
    >
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        <CampaignCard campaign={campaign} {...rest} />
      </div>
      <Popconfirm
        title="Êtes-vous sûr de vouloir retirer cette campagne de la découverte"
        okText="Supprimer"
        okType="danger"
        onConfirm={handleDelete}
      >
        <Button
          type="primary"
          shape="circle"
          danger
          className={styles.deleteButton}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  );
};

export type CampaignCardSortableProps = CampaignCardProps & {
  sortableId: string;
  onDelete: () => void;
};

const CategoryCardSortable = (props: CategoryCardSortable) => {
  const { category, sortableId, onDelete, ...rest } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: sortableId || category.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.card, isDragging && styles['-isDragging'])}
      style={style}
    >
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        <CategoryCard category={category} {...rest} />
      </div>
      <Popconfirm
        title="Êtes-vous sûr de vouloir retirer cette categorie de la découverte"
        okText="Supprimer"
        okType="danger"
        onConfirm={handleDelete}
      >
        <Button
          type="primary"
          shape="circle"
          danger
          className={styles.deleteButton}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  );
};

export type CategoryCardSortable = CategoryCardProps & {
  sortableId: string;
  onDelete: () => void;
};
