import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { ProductType } from '@graphql/generated/types';

import styles from './FormSelectProductType.module.css';

export const FormSelectProductType: React.FC<FormSelectProductTypeProps> = (
  props,
) => {
  const { className, name } = props;
  const { t } = useTranslation('products');

  const options: { label: string; value: ProductType }[] = [
    { value: ProductType.Ticket, label: t('productType.Ticket') },
    { value: ProductType.GiftCard, label: t('productType.GiftCard') },
  ];

  return (
    <FormSelect
      name={name}
      className={classnames(className, styles.root)}
      options={options}
    />
  );
};

export type FormSelectProductTypeProps = {
  name: string;
  className?: string;
};
