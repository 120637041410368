import { useEffect, useRef, useState } from 'react';
import { decode } from 'blurhash';
import classnames from 'classnames';

import { extractImageComponents } from '@utils/convertImageToHash';

import styles from './ProgressivePicture.module.css';

import { ProgressivePictureFragment } from './ProgressivePicture.generated';

export const ProgressivePicture: React.FC<ProgressivePictureProps> = (
  props,
) => {
  const { className, picture, mode = 'cover', alt } = props;

  const [pictureLoaded, setPictureLoaded] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { componentX, componentY } = extractImageComponents(
    picture.width,
    picture.height,
  );

  useEffect(() => {
    if (canvasRef.current) {
      const pixels = decode(picture.hash, componentX, componentY, 1);
      const ctx = canvasRef.current.getContext('2d')!;
      const imageData = ctx.createImageData(componentX, componentY);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
    }
  }, [picture.id]);

  return (
    <div
      className={classnames(className, styles.root, {
        [styles.loaded]: pictureLoaded,
      })}
    >
      <canvas
        style={{ objectFit: mode }}
        className={styles.placeholder}
        width={componentX}
        height={componentY}
        ref={canvasRef}
      />
      <img
        alt={alt}
        style={{ objectFit: mode }}
        className={styles.picture}
        src={picture.file.url}
        onLoad={() => setPictureLoaded(true)}
      />
    </div>
  );
};

export type ProgressivePictureProps = {
  alt?: string;
  className?: string;
  picture: ProgressivePictureFragment;
  mode?: 'cover' | 'contain';
};
