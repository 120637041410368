import { ProductVariantActivateDeactivateButton } from '@components/organisms/ProductVariantActivateDeactivateButton/ProductVariantActivateDeactivateButton';
import { ProductVariantActivateDeactivateButtonFragment } from '@components/organisms/ProductVariantActivateDeactivateButton/ProductVariantActivateDeactivateButton.generated';

type Props = {
  productVariant: ProductVariantActivateDeactivateButtonFragment;
};

const ProductVariantIdSettingsView = ({ productVariant }: Props) => {
  return (
    <div>
      <ProductVariantActivateDeactivateButton productVariant={productVariant} />
    </div>
  );
};

export default ProductVariantIdSettingsView;
