import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { ArticleAccessMode } from '@graphql/generated/types';

import { ArticleAccessModeTag } from '@components/atoms/ArticleAccessModeTag/ArticleAccessModeTag';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ArticlesTableQueryVariables,
  ArticlesTableRecordFragment,
  useArticlesTableQuery,
  useArticlesTableRemoveMutation,
} from './ArticlesTable.generated';

type RecordType = ArticlesTableRecordFragment;

type Column = 'title' | 'isHighlighted' | 'author' | 'category' | 'accessMode';

export function ArticlesTable(props: ArticlesTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('communication');

  const [removeMutation] = useArticlesTableRemoveMutation();

  const query =
    propQuery ||
    useArticlesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'title',
      title: t('articles.table.headers.title'),
      dataIndex: ['title'],
      render: (title, node) => (
        <I18nLink to={`/communication/articles/list/${node.id}`}>
          {title}
        </I18nLink>
      ),
    },
    {
      key: 'isHighlighted',
      title: t('articles.table.headers.isHighlighted'),
      dataIndex: ['isHighlighted'],
    },
    {
      key: 'author',
      title: t('articles.table.headers.author'),
      dataIndex: ['author'],
      render: (_, node) => `${node.author?.firstName} ${node.author?.lastName}`,
    },
    {
      key: 'category',
      title: t('articles.table.headers.category'),
      dataIndex: ['category'],
      render: (_, node) => (
        <I18nLink to={`/communication/articles/categories/${node.category.id}`}>
          {node.category.name}
        </I18nLink>
      ),
    },
    {
      key: 'accessMode',
      title: t('articles.table.headers.accessMode'),
      dataIndex: ['accessMode'],
      ellipsis: true,
      render: (_, node) => <ArticleAccessModeTag fragment={node} />,
      sorter: true,
      ...generateColumnFilter(query, 'accessMode', 'enum', [
        {
          label: t('articles.accessMode.Whitelist'),
          value: ArticleAccessMode.Whitelist,
        },
        {
          label: t('articles.accessMode.Blacklist'),
          value: ArticleAccessMode.Blacklist,
        },
      ]),
    },
  ];

  // TODO DELETE

  return (
    <div className={className}>
      <TablePagination
        id="ArticlesTable"
        query={query}
        columns={columns}
        data={propData || query.data?.articlesPagination}
        action={(record) => (
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer cet article ?"
            okText="Supprimer"
            okType="danger"
            onConfirm={handleDelete(record.id)}
          >
            <Button type="text" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
        {...rest}
      />
    </div>
  );

  function handleDelete(recordId: string) {
    return async () => {
      await removeMutation({
        variables: { articleId: recordId },
      });
      await query.refetch();
    };
  }
}

export type ArticlesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: ArticlesTableQueryVariables['filter'];
  sorter?: ArticlesTableQueryVariables['sorter'];
};
