import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { ProductSynchronizationStatus } from '@graphql/generated/types';

import { I18nLink } from '@atoms/Link/Link';
import { ProductSynchronizationStatusTag } from '@atoms/ProductSynchronizationStatusTag/ProductSynchronizationStatusTag';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductSynchronizationsTableQueryVariables,
  ProductSynchronizationsTableRecordFragment,
  useProductCataogPaginationForProductSynchronizationQuery,
  useProductProviderPaginationForProductSynchronizationQuery,
  useProductSynchronizationsTableQuery,
  useSynchronizationAuthorsQuery,
} from './ProductSynchronizationsTable.generated';

type RecordType = ProductSynchronizationsTableRecordFragment;

type Column =
  | 'author'
  | 'provider'
  | 'catalog'
  | 'productVariantCreatedCount'
  | 'productVariantUpdatedCount'
  | 'productVariantDeletedCount'
  | 'status'
  | 'error';

export function ProductSynchronizationsTable(
  props: ProductSynchronizationsTableProps,
) {
  const { className, sorter, filter, ...rest } = props;

  const [catalogEnum, setCatalogEnum] = useState<
    { label: string; value: string }[]
  >([]);

  const [providerEnum, setProviderEnum] = useState<
    { label: string; value: string }[]
  >([]);

  const [authorEnum, setAuthorEnum] = useState<
    { label: string; value: string }[]
  >([]);

  const { t } = useTranslation('productSynchronizations');

  const query = useProductSynchronizationsTableQuery({
    variables: {
      skip: 0,
      take: 10,
      sorter,
      filter,
    },
  });

  const catalogQuery = useProductCataogPaginationForProductSynchronizationQuery(
    {
      nextFetchPolicy: 'cache-first',
    },
  );

  const providerQuery =
    useProductProviderPaginationForProductSynchronizationQuery({
      nextFetchPolicy: 'cache-first',
    });

  const authorsQuery = useSynchronizationAuthorsQuery({
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (catalogQuery.data) {
      const catalogs = catalogQuery.data.productCatalogPagination.nodes.map(
        (catalog) => ({
          label: catalog.name,
          value: catalog.id,
        }),
      );

      setCatalogEnum(catalogs);
    }
  }, [catalogQuery.data]);

  useEffect(() => {
    if (providerQuery.data) {
      const providers = providerQuery.data.productProviderPagination.nodes.map(
        (provider) => ({
          label: provider.name,
          value: provider.id,
        }),
      );

      setProviderEnum(providers);
    }
  }, [providerQuery.data]);

  useEffect(() => {
    if (
      authorsQuery?.data?.authorsSynchronizations.authors &&
      authorsQuery?.data?.authorsSynchronizations.authors.length > 0
    ) {
      const authors = authorsQuery?.data?.authorsSynchronizations.authors.map(
        (author) => {
          return {
            label: `${author.firstName} ${author.lastName}`,
            value: author.id,
          };
        },
      );

      setAuthorEnum(authors);
    }
  }, [query.data]);

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
      render: (id) => (
        <I18nLink to={`/products/synchronizations/${id}`}>{id}</I18nLink>
      ),
    },
    {
      key: 'author',
      title: t('table.headers.author'),
      dataIndex: ['author'],
      render: (author) => (
        <I18nLink to={`/users/list/${author.id}`}>
          {author.firstName} {author.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'author', 'enum', authorEnum, query),
    },
    {
      key: 'provider',
      title: t('table.headers.provider'),
      dataIndex: ['provider'],
      render: (provider) => (
        <I18nLink to={`/products/providers/list/${provider.id}`}>
          {provider.name}
        </I18nLink>
      ),
      ...generateColumnFilter(
        query,
        'provider',
        'enum',
        providerEnum,
        catalogQuery,
        catalogQuery.data &&
          catalogQuery.data.productCatalogPagination.nodes.length <
            catalogQuery.data.productCatalogPagination.totalCount,
      ),
    },
    {
      key: 'catalog',
      title: t('table.headers.catalog'),
      dataIndex: ['catalog'],
      render: (catalog) => (
        <I18nLink to={`/products/catalogs/list/${catalog.id}`}>
          {catalog.name}
        </I18nLink>
      ),
      ...generateColumnFilter(
        query,
        'catalog',
        'enum',
        catalogEnum,
        providerQuery,
        providerQuery.data &&
          providerQuery.data.productProviderPagination.nodes.length <
            providerQuery.data.productProviderPagination.totalCount,
      ),
    },
    {
      key: 'productVariantCreatedCount',
      title: t('table.headers.elementsCreatedCount'),
      render: (_, record) =>
        [
          record.brandsCreatedPagination.totalCount,
          record.campaignsCreatedPagination.totalCount,
          record.offersCreatedPagination.totalCount,
          record.productsCreatedPagination.totalCount,
          record.productVariantsCreatedPagination.totalCount,
        ].reduce((sum, cur) => sum + cur, 0),
    },
    {
      key: 'productVariantUpdatedCount',
      title: t('table.headers.elementsUpdatedCount'),
      render: (_, record) =>
        [
          record.offersUpdatedPagination.totalCount,
          record.productsUpdatedPagination.totalCount,
          record.productVariantsUpdatedPagination.totalCount,
        ].reduce((sum, cur) => sum + cur, 0),
    },
    {
      key: 'productVariantDeletedCount',
      title: t('table.headers.elementsDeleteCount'),
      render: (_, record) =>
        [
          record.offersDeletedPagination.totalCount,
          record.productsDeletedPagination.totalCount,
          record.productVariantsDeletedPagination.totalCount,
        ].reduce((sum, cur) => sum + cur, 0),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      sorter: true,
      dataIndex: ['status'],
      render: (_, node) => (
        <ProductSynchronizationStatusTag productSynchronization={node} />
      ),

      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('status.active'),
          value: ProductSynchronizationStatus.Active,
        },
        {
          label: t('status.canceled'),
          value: ProductSynchronizationStatus.Canceled,
        },
        {
          label: t('status.completed'),
          value: ProductSynchronizationStatus.Completed,
        },
        {
          label: t('status.failed'),
          value: ProductSynchronizationStatus.Failed,
        },
        {
          label: t('status.pending'),
          value: ProductSynchronizationStatus.Pending,
        },
      ]),
    },
    {
      key: 'error',
      title: t('table.headers.error'),
      dataIndex: ['error'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductSynchronizationsTable"
        query={query}
        columns={columns}
        data={query.data?.productSynchronizationsPagination}
        title={() => (
          <I18nLink to="/products/synchronizations/create">
            <Button>Déclencher une synchronization</Button>
          </I18nLink>
        )}
        {...rest}
      />
    </div>
  );
}

export type ProductSynchronizationsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: ProductSynchronizationsTableQueryVariables['sorter'];
  filter?: ProductSynchronizationsTableQueryVariables['filter'];
};
