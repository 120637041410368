import classnames from 'classnames';

import { UsersTable } from '@components/tables/UsersTable/UsersTable';

import styles from './UsersView.module.css';

export const UsersView: React.FC<UsersViewProps> = (props) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <UsersTable
        activeColumns={[
          'avatar',
          'fullName',
          'email',
          'status',
          'company',
          'lastLoginAt',
          'lastActiveAt',
          'createdAt',
        ]}
      />
    </div>
  );
};

export type UsersViewProps = {
  className?: string;
};
