import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectUser.module.css';

import {
  FormSelectUserQueryVariables,
  useFormSelectUserQuery,
} from './FormSelectUser.generated';

export const FormSelectUser: React.FC<FormSelectUserProps> = (props) => {
  const { className, filter, ...rest } = props;

  const { data, loading, variables, refetch } = useFormSelectUserQuery({
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = debounce((value: string) => {
    refetch({
      ...(variables || {}),
      filter: {
        ...(variables?.filter || {}),
        search: value,
      },
    });
  }, 300);

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      options={data?.usersPagination.nodes.map((node) => ({
        value: node.id,
        label: `${node.lastName} ${node.firstName} (${node.email})`,
      }))}
      {...rest}
    />
  );
};

export type FormSelectUserProps = FormSelectProps & {
  filter?: FormSelectUserQueryVariables['filter'];
};
