import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import QueryString from 'qs';

import {
  ArticleCategoryCreateForm,
  ArticleCategoryCreateFormProps,
} from '@components/forms/ArticleCategoryCreateForm/ArticleCategoryCreateForm';

import styles from './ArticleCategoryCreateView.module.css';

export const ArticleCategoryCreateView: React.FC<ArticleCategoryCreateViewProps> =
  (props) => {
    const { className } = props;
    const { t, i18n } = useTranslation('communication');
    const history = useHistory();
    const location = useLocation();

    const companyId = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    }).companyId as string | undefined;

    const handleSuccess: ArticleCategoryCreateFormProps['onSuccess'] = (
      result,
    ) => {
      history.push(
        `/${i18n.language}/communication/articles/categories/${result.articleCategoryCreate.articleCategory.id}`,
      );
    };
    return (
      <div className={classnames(className, styles.root)}>
        <div className={styles.wrapper}>
          <Typography.Title>
            {t('articles.category.create.title')}
          </Typography.Title>
          <ArticleCategoryCreateForm
            companyId={companyId}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
    );
  };

export type ArticleCategoryCreateViewProps = {
  className?: string;
};
