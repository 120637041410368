import classnames from 'classnames';

import { CompanyCampaignSelectionExcludeForm } from '@components/forms/CompanyCampaignSelectionExcludeForm/CompanyCampaignSelectionExcludeForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './CompanyCampaignSelectionExcludeModal.module.css';

export type CompanyCampaignSelectionExcludeModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  companyId: string;
};

export function CompanyCampaignSelectionExcludeModal(
  props: CompanyCampaignSelectionExcludeModalProps,
) {
  const { className, onClose, onSuccess, isOpen, companyId } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="CompanyCampaignSelectionExcludeModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="CompanyCampaignSelectionExcludeForm"
    >
      <CompanyCampaignSelectionExcludeForm
        onSuccess={handleSuccess}
        companyId={companyId}
      />
    </Modal>
  );
}
