import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { ArticleTranslationForm } from '@forms/ArticleTranslationForm/ArticleTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './ArticleIdTranslationsView.module.css';

import { useArticleIdTranslationsViewQuery } from './ArticleIdTranslationsView.generated';

export const ArticleIdTranslationsView: React.FC<ArticleIdTranslationsViewProps> =
  (props) => {
    const { className, articleId } = props;

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
      undefined,
    );
    const localesQuery = useLocales();
    const query = useArticleIdTranslationsViewQuery({
      variables: { articleId },
      notifyOnNetworkStatusChange: true,
    });

    const article = query?.data?.article;
    const locales = localesQuery?.data?.localesPagination.nodes;

    if ((localesQuery.loading || query.loading) && !article) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!article) {
      return <NotFoundView />;
    } else if (!locales) {
      return <ServerErrorView />;
    }

    const { viewer } = useAuth();

    const userLanguage = viewer?.preferences?.properties.language || 'en';

    return (
      <div className={classnames(className, styles.root)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          defaultActiveKey={userLanguage}
        >
          {article?.translationsPagination.nodes.map((node) => {
            const locale = locales.find((locale) => locale.id === node.locale);

            return (
              <Tabs.TabPane
                tab={locale ? `${locale.name} (${locale.id})` : node.locale}
                key={node.locale}
              >
                <ArticleTranslationForm
                  articleId={article.id}
                  translation={node}
                  onDeleted={async ({ article }) => {
                    await query.refetch();
                    setActiveTabKey(article.locale);
                  }}
                />
              </Tabs.TabPane>
            );
          })}
          {article.translationsPagination.totalCount < locales.length && (
            <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
              <ArticleTranslationForm
                onCreated={async ({ articleTranslation }) => {
                  await query.refetch();
                  setActiveTabKey(articleTranslation.locale);
                }}
                localeFilter={{
                  locale: {
                    exclude: article.translationsPagination.nodes.map(
                      (node) => node.locale,
                    ),
                  },
                }}
                articleId={article.id}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };

export type ArticleIdTranslationsViewProps = {
  className?: string;
  articleId: string;
};
