import { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import { SSOProviderOIDCModal } from '@components/modals/SSOProviderOIDCModal/SSOProviderOIDCModal';
import { SSOProviderSAMLModal } from '@components/modals/SSOProviderSAMLModal/SSOProviderSAMLModal';

type SSOProviderType = 'OIDC' | 'SAML';

export const SSOProviderCreateButton = (
  props: SSOProviderCreateButtonProps,
) => {
  const { companyId } = props;

  const [type, setType] = useState<SSOProviderType | null>(null);

  const menu = (
    <Menu onClick={(i) => setType(i.key as SSOProviderType)}>
      <Menu.Item key="OIDC">OIDC</Menu.Item>
      <Menu.Item key="SAML">SAML</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <Button>Ajouter une connexion SSO</Button>
      </Dropdown>
      <SSOProviderOIDCModal
        companyId={companyId}
        isOpen={type === 'OIDC'}
        onClose={() => setType(null)}
      />
      <SSOProviderSAMLModal
        companyId={companyId}
        isOpen={type === 'SAML'}
        onClose={() => setType(null)}
      />
    </>
  );
};

export type SSOProviderCreateButtonProps = {
  companyId: string;
};
