import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Spin } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import { ProductArticle } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { ProductArticlesTable } from '@components/tables/ProductArticlesTable/ProductArticlesTable';
import { ProductArticlesTableQueryVariables } from '@components/tables/ProductArticlesTable/ProductArticlesTable.generated';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './ProductVariantIdArticlesView.module.css';

import {
  useProductVariantIdArticlesDeleteMutation,
  useProductVariantIdArticlesViewQuery,
} from './ProductVariantIdArticlesView.generated';

export const ProductVariantIdArticlesView: React.FC<ProductVariantIdArticlesViewProps> =
  (props) => {
    const { className, productVariantId, sorter } = props;
    const { t } = useTranslation([
      'productVariants',
      'productArticles',
      'common',
    ]);

    const query = useProductVariantIdArticlesViewQuery({
      variables: {
        productVariantId,
        take: 10,
        skip: 0,
        sorter,
      },
      notifyOnNetworkStatusChange: true,
    });

    const [productArticleDelete] = useProductVariantIdArticlesDeleteMutation();

    const productVariant = query?.data?.productVariant;

    if (query.loading && !productVariant) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!productVariant) {
      return <NotFoundView />;
    }

    const handleDelete =
      (productArticleId: ProductArticle['id']) => async () => {
        await productArticleDelete({
          variables: { productArticleId },
        });

        await query.refetch();
      };

    return (
      <div className={classnames(className, styles.root)}>
        <ProductArticlesTable
          activeColumns={['id', 'createdAt', 'expiresAt']}
          query={query}
          sorter={sorter}
          data={query.data?.productVariant?.productArticlePagination}
          title={() => (
            <Space direction="horizontal">
              <I18nLink
                to={{
                  pathname: `/product-articles/create`,
                  search: qs.stringify({ productVariantId }),
                }}
              >
                <Button>{t('ProductVariantIdArticlesView.create')}</Button>
              </I18nLink>
              <I18nLink
                to={{
                  pathname: `/product-articles/create-bulk`,
                  search: qs.stringify({ productVariantId }),
                }}
              >
                <Button>{t('ProductVariantIdArticlesView.create-bulk')}</Button>
              </I18nLink>
            </Space>
          )}
          action={(record) => {
            return (
              <Space direction="vertical">
                {record.file && (
                  <Button
                    className={classnames(className, styles.root)}
                    href={record.file.url}
                    target="_blank"
                    icon={<DownloadOutlined />}
                  >
                    {t('productArticles:productArticles.download')}
                  </Button>
                )}
                {!record.orderItem && (
                  <Popconfirm
                    title={t(
                      'productArticles:table.headers.action.deleteConfirm',
                    )}
                    okText={t('common:BooleanTag.true')}
                    cancelText={t('common:BooleanTag.false')}
                    onConfirm={handleDelete(record.id)}
                  >
                    <Button danger icon={<DeleteOutlined />}>
                      {t('productArticles:table.headers.action.delete')}
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            );
          }}
        />
      </div>
    );
  };

export type ProductVariantIdArticlesViewProps = {
  productVariantId: string;
  className?: string;
  sorter?: ProductArticlesTableQueryVariables['sorter'];
};
