import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import QueryString from 'qs';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionSchemasTable } from '@components/tables/SubventionSchemasTable/SubventionSchemasTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useSubventionSchemaChildrenViewQuery } from './SubventionSchemaChildrenView.generated';

export const SubventionSchemaChildrenView = (
  props: SubventionSchemaChildrenViewProps,
) => {
  const { subventionSchemaId } = props;
  const { t } = useTranslation('subvention-schemas');

  const query = useSubventionSchemaChildrenViewQuery({
    variables: {
      subventionSchemaId,
      take: 10,
      skip: 0,
    },
  });

  const subventionSchema = query.data?.subventionSchema;

  if (query.loading && !subventionSchema) {
    return <Spin size="large" />;
  } else if (!subventionSchema) {
    return <NotFoundView />;
  }

  return (
    <div>
      <SubventionSchemasTable
        activeColumns={['name', 'status']}
        query={query}
        data={subventionSchema.childrenPagination}
        title={() => (
          <I18nLink
            to={{
              pathname: '/subventions/schemas/create',
              search: QueryString.stringify({ parentId: subventionSchemaId }),
            }}
          >
            <Button>{t('create-children')}</Button>
          </I18nLink>
        )}
      />
    </div>
  );
};

export type SubventionSchemaChildrenViewProps = {
  subventionSchemaId: string;
};
