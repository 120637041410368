import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { Locale } from '@graphql/generated/types';

import { SelectLocale } from '@components/molecules/SelectLocale/SelectLocale';
import { BrandsTable } from '@components/tables/BrandsTable/BrandsTable';

import styles from '../Redbin.module.css';

import { useRedBinBrandsMissingTranslationQuery } from './BrandsMissingTranslation.generated';

export const BrandsMissingTranslation: React.FC<BrandsMissingTranslationProps> =
  (props) => {
    const { className } = props;

    const { t } = useTranslation('redbin');

    const [missingLocaleId, setMissingLocaleId] = useState<Locale['id']>('fr');

    const query = useRedBinBrandsMissingTranslationQuery({
      variables: { missingLocaleId, take: 10, skip: 0 },
    });

    return (
      <div className={classnames(className, styles.root)}>
        <PageHeader ghost={false} title={t('BrandsMissingTranslation.title')} />

        <BrandsTable
          query={query}
          data={query.data?.redBinBrandsMissingTranslation}
          activeColumns={[
            'name',
            'campaignCount',
            'offerCount',
            'productCount',
            'createdAt',
            'updatedAt',
          ]}
          title={() => (
            <>
              {`${t('BrandsMissingTranslation.missingLocale')} `}
              <SelectLocale
                onSelect={(value) => setMissingLocaleId(value as string)}
                defaultValue={'fr'}
              />
            </>
          )}
        />
      </div>
    );
  };

export type BrandsMissingTranslationProps = {
  className?: string;
};
