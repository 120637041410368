import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { PageHeader } from '@molecules/PageHeader/PageHeader';

import { GameHeaderFragment } from './GameHeader.generated';

export const GameHeader: React.FC<GameHeaderProps> = (props) => {
  const { className, game } = props;

  const { t } = useTranslation(['games']);

  return (
    <PageHeader className={className} showNav>
      <Typography.Text>{t('game')}</Typography.Text>

      <Typography.Title level={2} ellipsis={{ rows: 2 }}>
        {game.name}
      </Typography.Title>
    </PageHeader>
  );
};

export type GameHeaderProps = {
  className?: string;
  game: GameHeaderFragment;
};
