import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Divider, Layout, Space, Spin } from 'antd';
import classnames from 'classnames';

import { CompanyFeatureConfigView } from '../CompanyFeatureConfigView/CompanyFeatureConfigView';
import { CompanyIdApiView } from '../CompanyIdApiView/CompanyIdApiView';
import { CompanyIdCampaignSelectionsIncludedView } from '../CompanyIdCampaignSelectionsIncludedView/CompanyIdCampaignSelectionsIncludedView';
import { CompanyIdCampaignSelectionsView } from '../CompanyIdCampaignSelectionsView/CompanyIdCampaignSelectionsView';
import { CompanyIdCareView } from '../CompanyIdCareView/CompanyIdCareView';
import { CompanyIdCatalogView } from '../CompanyIdCatalogView/CompanyIdCatalogView';
import { CompanyIdCommunicationView } from '../CompanyIdCommunicationView/CompanyIdCommunicationView';
import { CompanyIdHappeningsView } from '../CompanyIdHappeningsView/CompanyIdHappeningsView';
import { CompanyIdMangopayView } from '../CompanyIdMangopayView/CompanyIdMangopayView';
import { CompanyIdSSOView } from '../CompanyIdSSOView/CompanyIdSSOView';
import { CompanyIdStatsView } from '../CompanyIdStatsView/CompanyIdStatsView';
import { CompanyIdStripeView } from '../CompanyIdStripeView/CompanyIdStripeView';
import { CompanyIdSubsidiariesView } from '../CompanyIdSubsidiariesView/CompanyIdSubsidiariesView';
import { CompanyIdSubventionView } from '../CompanyIdSubventionView/CompanyIdSubventionView';
import { CompanyIdTrailView } from '../CompanyIdTrailView/CompanyIdTrailView';
import { CompanyInvitationsView } from '../CompanyInvitationsView/CompanyInvitationsView';

import {
  AdminCommentTargetType,
  DiscoverTargetType,
} from '@graphql/generated/types';

import { CompanyFeatureUpdateForm } from '@components/forms/CompanyFeatureUpdateForm/CompanyFeatureUpdateForm';
import {
  CompanyUpdateForm,
  CompanyUpdateFormProps,
} from '@components/forms/CompanyUpdateForm/CompanyUpdateForm';
import AdminCommentsDrawerOpenButton from '@components/organisms/AdminCommentsDrawerOpenButton/AdminCommentsDrawerOpenButton';
import { CompanyHeader } from '@components/organisms/CompanyHeader/CompanyHeader';
import { DiscoverEditor } from '@components/organisms/DiscoverEditor/DiscoverEditor';
import { CompanyIdFeesView } from '@components/views/Companies/CompanyIdFeesView/CompanyIdFeesView';
import { CompanyIdCustomFieldsView } from '@components/views/CompanyIdCustomFieldsView/CompanyIdCustomFieldsView';
import { CompanyIdMembersView } from '@components/views/CompanyIdMembersView/CompanyIdMembersView';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CompanyIdView.module.css';

import { useCompanyIdViewQuery } from './CompanyIdView.generated';

export const CompanyIdView: React.FC<CompanyIdViewProps> = (props) => {
  const { className, match } = props;
  const {
    params: { companyId },
  } = match;

  const { t } = useTranslation('companies');

  const query = useCompanyIdViewQuery({
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
  });

  const company = query?.data?.company;

  if (query.loading && !company) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!company) {
    return <NotFoundView />;
  }

  const handleSuccess: CompanyUpdateFormProps['onSuccess'] = async () => {
    await query.refetch();
  };

  return (
    <Layout className={classnames(className, styles.root)}>
      <CompanyHeader className={styles.header} company={company} showNav />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}/settings`}
          exact
          label="Paramètres"
        >
          <CompanyUpdateForm company={company} onSuccess={handleSuccess} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/history`} label="Historique">
          <CompanyIdTrailView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/stats`} label="Stats">
          <CompanyIdStatsView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/members`} label="Utilisateurs">
          <CompanyIdMembersView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/discover`} label="Découvrir">
          <DiscoverEditor
            title="Campagnes à la une"
            targetType={DiscoverTargetType.Campaign}
            companyId={company.id}
          />
          <DiscoverEditor
            title="Catégorie à la une"
            targetType={DiscoverTargetType.Category}
            companyId={company.id}
          />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/features`}
          exact
          label="Fonctionalités"
        >
          <CompanyFeatureUpdateForm company={company} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/feature-config`}
          label="Fonctionalités config"
        >
          <CompanyFeatureConfigView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/catalog`} label="Catalogue">
          <CompanyIdCatalogView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/exclusions`}
          exact
          label={`Exclusions`}
        >
          <Space direction="vertical" split={<Divider type="horizontal" />}>
            <CompanyIdCampaignSelectionsView companyId={company.id} />
          </Space>
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/include`}
          exact
          label={`Exclusivité`}
        >
          <>
            <CompanyIdCampaignSelectionsIncludedView companyId={company.id} />
          </>
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/custom-fields`}
          exact
          label={`Champs personnalisés`}
        >
          <CompanyIdCustomFieldsView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/fees`} exact label="Frais">
          <CompanyIdFeesView companyId={company.id} />
        </TabsRouter.Route>
        {!!company.features.communication && (
          <TabsRouter.Route
            path={`${match.url}/communication`}
            label="Communication"
          >
            <CompanyIdCommunicationView companyId={company.id} />
          </TabsRouter.Route>
        )}
        {!!company.features.subventions && (
          <TabsRouter.Route
            path={`${match.url}/subventions`}
            exact
            label={'Subventions'}
          >
            <CompanyIdSubventionView companyId={company.id} />
          </TabsRouter.Route>
        )}
        {!!company.features.happenings && (
          <TabsRouter.Route path={`${match.url}/happenings`} label="Evènements">
            <CompanyIdHappeningsView companyId={company.id} />
          </TabsRouter.Route>
        )}
        <TabsRouter.Route path={`${match.url}/sso`} exact label="SSO">
          <CompanyIdSSOView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/api`} exact label="Api">
          <CompanyIdApiView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/invitations`}
          exact
          label={t('tabs.invitations')}
        >
          <CompanyInvitationsView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/care`}
          exact
          label={t('tabs.care')}
        >
          <CompanyIdCareView companyId={company.id} />
        </TabsRouter.Route>
        {!!company.features.subventions && (
          <TabsRouter.Route
            path={`${match.url}/mangopay`}
            exact
            label="Mangopay"
          >
            <CompanyIdMangopayView companyId={company.id} />
          </TabsRouter.Route>
        )}
        <TabsRouter.Route
          path={`${match.url}/subsidiaries`}
          exact
          label="Filiales"
        >
          <CompanyIdSubsidiariesView companyId={company.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/stripe`} exact label="Stripe">
          <CompanyIdStripeView companyId={company.id} />
        </TabsRouter.Route>
      </TabsRouter>

      <AdminCommentsDrawerOpenButton
        targetId={company.id}
        targetType={AdminCommentTargetType.Company}
      />
    </Layout>
  );
};

export type CompanyIdViewProps = RouteComponentProps<{ companyId: string }> & {
  className?: string;
};
