import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectCampaignSelection.module.css';

import {
  FormSelectCampaignSelectionQueryVariables,
  useFormSelectCampaignSelectionQuery,
} from './FormSelectCampaignSelection.generated';

export const FormSelectCampaignSelection: React.FC<FormSelectCampaignSelectionProps> =
  (props) => {
    const { className, filter, sorter, ...rest } = props;

    const { data, loading, variables, refetch } =
      useFormSelectCampaignSelectionQuery({
        variables: {
          filter,
          sorter,
        },
        notifyOnNetworkStatusChange: true,
      });

    const handleSearch = debounce((value: string) => {
      refetch({
        ...(variables || {}),
        filter: {
          ...(variables?.filter || {}),
          name: { search: value },
        },
      });
    }, 300);

    return (
      <FormSelect
        className={classnames(className, styles.root)}
        loading={loading}
        filterOption={false}
        showSearch
        onSearch={handleSearch}
        options={data?.campaignSelectionsPagination.nodes.map((node) => ({
          value: node.id,
          label: node.name,
        }))}
        {...rest}
      />
    );
  };

export type FormSelectCampaignSelectionProps = FormSelectProps & {
  filter?: FormSelectCampaignSelectionQueryVariables['filter'];
  sorter?: FormSelectCampaignSelectionQueryVariables['sorter'];
};
