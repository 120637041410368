import classnames from 'classnames';

import { ProductSynchronizationsTable } from '@tables/ProductSynchronizationsTable/ProductSynchronizationsTable';

import styles from './ProductSynchronizationsView.module.css';

export const ProductSynchronizationsView: React.FC<ProductSynchronizationsViewProps> =
  (props) => {
    const { className } = props;

    return (
      <div className={classnames(className, styles.root)}>
        <ProductSynchronizationsTable
          activeColumns={[
            'id',
            'catalog',
            'provider',
            'status',
            'productVariantCreatedCount',
            'productVariantUpdatedCount',
            'productVariantDeletedCount',
            'createdAt',
          ]}
        />
      </div>
    );
  };

export type ProductSynchronizationsViewProps = {
  className?: string;
};
