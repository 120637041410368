import { useState } from 'react';
import { QueryResult } from '@apollo/client/react';
import { Spin, Switch } from 'antd';
import classNames from 'classnames';

import {
  Brand,
  Campaign,
  CampaignSelection,
  Offer,
} from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './IsExclusiveToggle.module.css';

import {
  useBrandForIsExclusiveToggleQuery,
  useBrandIsExclusiveToggleMutation,
  useCampaignForIsExclusiveToggleQuery,
  useCampaignIsExclusiveToggleMutation,
  useCampaignSelectionForIsExclusiveToggleQuery,
  useCampaignSelectionIsExclusiveToggleMutation,
  useOfferForIsExclusiveToggleQuery,
  useOfferIsExclusiveToggleMutation,
} from './IsExclusiveToggle.generated';

type EntityType =
  | 'brand'
  | 'campaign'
  | 'offer'
  | 'campaignSelection'
  | 'article';

export const IsExclusiveToggle: React.FC<IsExclusiveToggleProps> = ({
  className,
  entityType,
  entityId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let entityQuery: QueryResult<any, { entityId: string }> | undefined;
  let entity: Brand | Campaign | Offer | CampaignSelection | undefined;

  switch (entityType) {
    case 'brand':
      entityQuery = useBrandForIsExclusiveToggleQuery({
        variables: { entityId },
        notifyOnNetworkStatusChange: true,
      });
      entity = entityQuery?.data?.brand;
      break;

    case 'campaign':
      entityQuery = useCampaignForIsExclusiveToggleQuery({
        variables: { entityId },
        notifyOnNetworkStatusChange: true,
      });
      entity = entityQuery?.data?.campaign;
      break;

    case 'offer':
      entityQuery = useOfferForIsExclusiveToggleQuery({
        variables: { entityId },
        notifyOnNetworkStatusChange: true,
      });
      entity = entityQuery?.data?.offer;
      break;

    case 'campaignSelection':
      entityQuery = useCampaignSelectionForIsExclusiveToggleQuery({
        variables: { entityId },
        notifyOnNetworkStatusChange: true,
      });
      entity = entityQuery?.data?.campaignSelection;
      break;

    default:
      entityQuery = undefined;
      entity = undefined;
  }

  const [isSubmitting, setSubmitting] = useState(false);

  const [brandMutation] = useBrandIsExclusiveToggleMutation();
  const [campaignMutation] = useCampaignIsExclusiveToggleMutation();
  const [offerMutation] = useOfferIsExclusiveToggleMutation();
  const [campaignSelectionMutation] =
    useCampaignSelectionIsExclusiveToggleMutation();

  if (entityQuery?.loading) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!entity) {
    return <NotFoundView />;
  }

  const brandToggle = async () => {
    if (!entity) return;
    return await brandMutation({
      variables: {
        brandId: entity.id,
        isExclusive: !entity.isExclusive,
      },
    });
  };

  const campaignToggle = async () => {
    if (!entity) return;
    return await campaignMutation({
      variables: {
        campaignId: entity.id,
        isExclusive: !entity.isExclusive,
      },
    });
  };

  const offerToggle = async () => {
    if (!entity) return;
    return await offerMutation({
      variables: {
        offerId: entity.id,
        isExclusive: !entity.isExclusive,
      },
    });
  };

  const campaignSelectionToggle = async () => {
    if (!entity) return;
    return await campaignSelectionMutation({
      variables: {
        campaignSelectionId: entity.id,
        isExclusive: !entity.isExclusive,
      },
    });
  };

  const handleToggle = async () => {
    if (isSubmitting) return;
    setSubmitting(true);

    try {
      switch (entityType) {
        case 'brand':
          await brandToggle();
          break;
        case 'campaign':
          await campaignToggle();
          break;
        case 'offer':
          await offerToggle();
          break;
        case 'campaignSelection':
          await campaignSelectionToggle();
          break;
      }
    } catch (err) {
      handleMutationErrors(err, {});
    }

    setSubmitting(false);
  };

  return (
    <div className={classNames(className, styles.root)}>
      <Switch
        checked={entity.isExclusive}
        onChange={handleToggle}
        disabled={isSubmitting}
        checkedChildren={'Activé'}
        unCheckedChildren={'Désactivé'}
      />
    </div>
  );
};

export type IsExclusiveToggleProps = {
  className?: string;
  entityId: string;
  entityType: EntityType;
};
