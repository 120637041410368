import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  OfferCreateForm,
  OfferCreateFormProps,
} from '@forms/OfferCreateForm/OfferCreateForm';

import styles from './OfferCreateView.module.css';

export const OfferCreateView: React.FC<OfferCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('offers');
  const history = useHistory();
  const location = useLocation();

  const handleSuccess: OfferCreateFormProps['onSuccess'] = (offer) => {
    history.push(`/${i18n.language}/offers/list/${offer.id}`);
  };

  const defaultValues = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <OfferCreateForm
          defaultValues={defaultValues}
          onSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};

export type OfferCreateViewProps = {
  className?: string;
};
