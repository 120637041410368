import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Scalars } from '@graphql/generated/types';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import {
  FormSelectLocale,
  FormSelectLocaleProps,
} from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignSelectionTranslationForm.module.css';

import {
  CampaignSelectionTranslationFormCreateMutation,
  CampaignSelectionTranslationFormCreateMutationVariables,
  CampaignSelectionTranslationFormDeleteMutation,
  CampaignSelectionTranslationFormDeleteMutationVariables,
  CampaignSelectionTranslationFormFragment,
  CampaignSelectionTranslationFormUpdateMutation,
  CampaignSelectionTranslationFormUpdateMutationVariables,
  useCampaignSelectionTranslationFormCreateMutation,
  useCampaignSelectionTranslationFormDeleteMutation,
  useCampaignSelectionTranslationFormUpdateMutation,
} from './CampaignSelectionTranslationForm.generated';

export type CampaignSelectionTranslationFormValues = {
  localeId: string;
  name: string;
  description: Scalars['Delta'];
};

export function CampaignSelectionTranslationForm(
  props: CampaignSelectionTranslationFormProps,
) {
  const {
    className,
    campaignSelectionId,
    translation,
    onCreated,
    onUpdated,
    onDeleted,
    localeFilter,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.object().required(),
  }).required();

  const form = useForm<CampaignSelectionTranslationFormValues>({
    validationSchema,
    defaultValues: {
      localeId: translation?.locale,
      description: translation?.descriptionDelta,
      name: translation?.name,
    },
  });

  const [createMutation, createMutationState] =
    useCampaignSelectionTranslationFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useCampaignSelectionTranslationFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useCampaignSelectionTranslationFormDeleteMutation();

  const handleCreate = async (
    values: CampaignSelectionTranslationFormValues,
  ) => {
    if (translation) return;

    const variables: CampaignSelectionTranslationFormCreateMutationVariables = {
      campaignSelectionId,
      localeId: values.localeId,
      input: {
        name: values.name,
        description: values.description,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.campaignSelectionTranslationCreate);
    }

    form.reset();
  };

  const handleUpdate = async (
    values: CampaignSelectionTranslationFormValues,
  ) => {
    if (!translation) return;

    const variables: CampaignSelectionTranslationFormUpdateMutationVariables = {
      campaignSelectionId,
      localeId: translation.locale,
      input: {
        name: values.name,
        description: values.description,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.campaignSelectionTranslationUpdate);
    }
  };

  const handleDelete = async () => {
    if (!translation) return;

    const variables: CampaignSelectionTranslationFormDeleteMutationVariables = {
      campaignSelectionId,
      localeId: translation.locale,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.campaignSelectionTranslationDelete);
    }
  };

  const handleSubmit: FormProps<CampaignSelectionTranslationFormValues>['onValid'] =
    async (values) => {
      if (translation) {
        return handleUpdate(values);
      } else {
        return handleCreate(values);
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignTranslationUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale
            disabled={!!translation}
            filter={localeFilter}
            name="localeId"
          />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          required
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <div className={styles.footer}>
          {!translation ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              Créer la traduction
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              Mettre à jour la traduction
            </Button>
          )}
          {!!translation && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette traduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                Supprimer
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type CampaignSelectionTranslationFormProps = ChildFormProps<
  CampaignSelectionTranslationFormValues,
  void
> & {
  className?: string;
  campaignSelectionId: string;
  translation?: CampaignSelectionTranslationFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: CampaignSelectionTranslationFormCreateMutation['campaignSelectionTranslationCreate'],
  ) => void;
  onUpdated?: (
    v: CampaignSelectionTranslationFormUpdateMutation['campaignSelectionTranslationUpdate'],
  ) => void;
  onDeleted?: (
    v: CampaignSelectionTranslationFormDeleteMutation['campaignSelectionTranslationDelete'],
  ) => void;
};
