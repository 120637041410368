import { Spin } from 'antd';
import classnames from 'classnames';

import { StripePaymentIntentsTable } from '@components/tables/StripePaymentIntentsTable/StripePaymentIntentsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './UserIdStripePaymentIntentsView.module.css';

import { useUserIdStripePaymentIntentsViewQuery } from './UserIdStripePaymentIntentsView.generated';

export const UserIdStripePaymentIntentsView: React.FC<UserIdStripePaymentIntentsViewProps> =
  (props) => {
    const { className, userId } = props;

    const query = useUserIdStripePaymentIntentsViewQuery({
      variables: {
        userId,
        take: 10,
        skip: 0,
      },
      notifyOnNetworkStatusChange: true,
    });

    const user = query?.data?.user;

    if (query.loading && !user) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!user) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <StripePaymentIntentsTable
          activeColumns={[
            'id',
            'createdAt',
            'updatedAt',
            'stripeUrl',
            'currency',
            'amount',
            'amountReceived',
            'status',
          ]}
          query={query}
          data={query.data?.user?.stripePaymentIntentsPagination}
        />
      </div>
    );
  };

export type UserIdStripePaymentIntentsViewProps = {
  userId: string;
  className?: string;
};
