import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from 'antd';

import {
  BeneficiaryRelationship,
  BeneficiaryStatus,
} from '@graphql/generated/types';

import { BeneficiaryProofsModal } from '@components/modals/BeneficiaryProofsModal/BeneficiaryProofsModal';
import {
  TableRendererDate,
  TableRendererDateFactory,
} from '@components/molecules/TableRendererDate/TableRendererDate';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { BeneficiaryRecordFragment } from './BeneficiariesTables.generated';

type RecordType = BeneficiaryRecordFragment;

type Column = 'firstName' | 'lastName' | 'birthDate' | 'documentsCount';

type BaseProps = ChildTablePaginationProps<RecordType, Column>;

export type BeneficiaryTableProps = BaseProps & {
  className?: string;
  query: NonNullable<BaseProps['query']>;
};

export function BeneficiariesTable(props: BeneficiaryTableProps) {
  const { className, query, data, ...rest } = props;

  const { t } = useTranslation('beneficiary');

  const [selectedBeneficiary, setSelectedBeneficiary] = useState<string>();

  const relationships: Record<BeneficiaryRelationship, string> = {
    [BeneficiaryRelationship.Child]: t('relationship.CHILD'),
    [BeneficiaryRelationship.Partner]: t('relationship.PARTNER'),
  };

  const statuses: Record<BeneficiaryStatus, string> = {
    [BeneficiaryStatus.Pending]: t('status.PENDING'),
    [BeneficiaryStatus.Accepted]: t('status.ACCEPTED'),
    [BeneficiaryStatus.Rejected]: t('status.REJECTED'),
  };

  const statusColors: Record<BeneficiaryStatus, string> = {
    [BeneficiaryStatus.Pending]: 'orange',
    [BeneficiaryStatus.Accepted]: 'green',
    [BeneficiaryStatus.Rejected]: 'red',
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
      render: (id) => (
        <Button type="link" onClick={() => setSelectedBeneficiary(id)}>
          {id}
        </Button>
      ),
    },
    {
      key: 'firstName',
      title: t('table.headers.firstName'),
      dataIndex: ['firstName'],
    },
    {
      key: 'lastName',
      title: t('table.headers.lastName'),
      dataIndex: ['lastName'],
    },
    {
      key: 'birthDate',
      title: t('table.headers.birthDate'),
      dataIndex: ['birthDate'],
      render: TableRendererDateFactory(),
    },
    {
      key: 'relationship',
      title: t('table.headers.relationship'),
      dataIndex: ['relationship'],
      render: (relationship: BeneficiaryRelationship) =>
        relationships[relationship],
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      render: (status: BeneficiaryStatus) => (
        <Tag color={statusColors[status]}>{statuses[status]}</Tag>
      ),
    },
    {
      key: 'unionDate',
      title: t('table.headers.unionDate'),
      dataIndex: ['unionDate'],
      render: (unionDate) => <TableRendererDate value={unionDate} />,
    },
    {
      key: 'documentsCount',
      title: t('table.headers.documentsCount'),
      dataIndex: ['proofsPagination', 'totalCount'],
    },
  ];

  return (
    <>
      <TablePagination
        id="BeneficiaryTable"
        query={query}
        columns={columns}
        data={data}
        {...rest}
      />

      <BeneficiaryProofsModal
        isOpen={!!selectedBeneficiary}
        beneficiaryId={selectedBeneficiary}
        onClose={() => setSelectedBeneficiary(undefined)}
      />
    </>
  );
}
