import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './CompanyFeesCollectionMethodTag.module.css';

import { CompanyFeesCollectionMethodTagFragment } from './CompanyFeesCollectionMethodTag.generated';

export const CompanyFeesCollectionMethodTag: React.FC<CompanyFeesCollectionMethodTagProps> =
  (props) => {
    const { className, companyFees } = props;
    const { t } = useTranslation('companyFees');

    const colors: Record<typeof companyFees.collectionMethod, string> = {
      USER: 'blue',
      COMPANY: 'grey',
    };

    const labels: Record<typeof companyFees.collectionMethod, string> = {
      USER: t('collectionMethod.user'),
      COMPANY: t('collectionMethod.company'),
    };

    return (
      <Tag
        className={classnames(className, styles.root)}
        color={colors[companyFees.collectionMethod]}
      >
        {labels[companyFees.collectionMethod]}
      </Tag>
    );
  };

export type CompanyFeesCollectionMethodTagProps = {
  className?: string;
  companyFees: CompanyFeesCollectionMethodTagFragment;
};
