import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';

import {
  useAddArticleAttachmentsMutation,
  useAddPublicationAttachmentsMutation,
  useRemoveArticleAttachmentsMutation,
  useRemovePublicationAttachmentsMutation,
} from './attachmentUpdate.hooks.generated';

function getAttachmentIds(attachments: AttachmentItemFragment[]) {
  return attachments.map((d) => d.id);
}

function getNewAttachmentIds(
  dataFetched: AttachmentItemFragment[],
  inputValues: AttachmentItemFragment[],
) {
  return getAttachmentIds(
    inputValues.filter(
      (newValue) =>
        !dataFetched.find((previousValue) => previousValue.id === newValue.id),
    ),
  );
}

function getRemovedAttachmentIds(
  dataFetched: AttachmentItemFragment[],
  inputValues: AttachmentItemFragment[],
) {
  return getAttachmentIds(
    dataFetched.filter(
      (previousValue) =>
        !inputValues.find((newValue) => previousValue.id === newValue.id),
    ),
  );
}

enum EntityWithAttachments {
  PUBLICATION = 'publication',
  ARTICLE = 'article',
}

export function useAttachmentUpdate(
  type: keyof typeof EntityWithAttachments,
  entityId: string,
) {
  const [articleAddAttachment] = useAddArticleAttachmentsMutation();
  const [articleRemoveAttachment] = useRemoveArticleAttachmentsMutation();
  const [publicationAddAttachment] = useAddPublicationAttachmentsMutation();
  const [publicationRemoveAttachment] =
    useRemovePublicationAttachmentsMutation();

  return function updateAttachments(
    prevValues: AttachmentItemFragment[],
    newValues: AttachmentItemFragment[],
  ) {
    const updateAttachmentPromises = [];

    const newAttachments = getNewAttachmentIds(prevValues, newValues);
    const removedAttachments = getRemovedAttachmentIds(prevValues, newValues);

    switch (type) {
      case 'ARTICLE': {
        if (newAttachments.length) {
          updateAttachmentPromises.push(
            articleAddAttachment({
              variables: {
                articleId: entityId,
                input: { attachmentIds: newAttachments },
              },
            }),
          );
        }

        if (removedAttachments.length) {
          updateAttachmentPromises.push(
            articleRemoveAttachment({
              variables: {
                articleId: entityId,
                input: { attachmentIds: removedAttachments },
              },
            }),
          );
        }
        break;
      }
      case 'PUBLICATION': {
        if (newAttachments.length) {
          updateAttachmentPromises.push(
            publicationAddAttachment({
              variables: {
                publicationId: entityId,
                input: { attachmentIds: newAttachments },
              },
            }),
          );
        }

        if (removedAttachments.length) {
          updateAttachmentPromises.push(
            publicationRemoveAttachment({
              variables: {
                publicationId: entityId,
                input: { attachmentIds: removedAttachments },
              },
            }),
          );
        }
        break;
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error weird error
    return Promise.all(updateAttachmentPromises);
  };
}
