import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { ArticlesTable } from '@components/tables/ArticlesTable/ArticlesTable';

import styles from './ArticlesView.module.css';

export const ArticlesView: React.FC<ArticlesViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('communication');

  return (
    <div className={classnames(className, styles.root)}>
      <ArticlesTable
        activeColumns={['title', 'author', 'category']}
        filter={{ category: { company: { id: { is: null } } } }}
        title={() => (
          <>
            <I18nLink
              to={{
                pathname: `/communication/articles/create`,
              }}
            >
              <Button>
                {t('articles.table.create', 'Ajouter un article')}
              </Button>
            </I18nLink>
          </>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/communication/articles/create`,
              }}
            >
              <Button>
                {t('articles.table.create', 'Ajouter un article')}
              </Button>
            </I18nLink>
          ),
        }}
      />
    </div>
  );
};

export type ArticlesViewProps = {
  className?: string;
};
