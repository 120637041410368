import React from 'react';
import classnames from 'classnames';

import { MangopayKycDocumentsTable } from '@components/tables/MangopayKycDocumentsTable/MangopayKycDocumentsTable';

import styles from './KycView.module.css';

export const KycView: React.FC<KycViewProps> = (props) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <MangopayKycDocumentsTable
        activeColumns={[
          'id',
          'createdAt',
          'status',
          'type',
          'processedAt',
          'mangopayUserType',
          'mangopayUser',
        ]}
      />
    </div>
  );
};

export type KycViewProps = {
  className?: string;
};
