import { useTranslation } from 'react-i18next';

import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductProvidersTableRecordFragment,
  useProductProvidersTableQuery,
} from './ProductProvidersTable.generated';

type RecordType = ProductProvidersTableRecordFragment;

type Column = 'name' | 'identifier' | 'synchronizationCount' | 'productCount';

export function ProductProvidersTable(props: ProductProvidersTableProps) {
  const { className, ...rest } = props;

  const { t } = useTranslation('productProviders');

  const query = useProductProvidersTableQuery({
    variables: {
      skip: 0,
      take: 10,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/products/providers/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'identifier',
      title: t('table.headers.identifier'),
      dataIndex: ['identifier'],
    },
    {
      key: 'synchronizationCount',
      title: t('table.headers.synchronizationCount'),
      dataIndex: ['synchronizationsPagination', 'totalCount'],
    },
    {
      key: 'productCount',
      title: t('table.headers.productCount'),
      dataIndex: ['productPagination', 'totalCount'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductProvidersTable"
        query={query}
        columns={columns}
        data={query.data?.productProviderPagination}
        {...rest}
      />
    </div>
  );
}

export type ProductProvidersTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
};
