import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from 'antd';
import classnames from 'classnames';

import {
  MangopayTransactionStatus,
  OrderPaginationArgsFilter,
} from '@graphql/generated/types';

import { OrdersTable } from '@components/tables/OrdersTable/OrdersTable';

import styles from './OrdersView.module.css';

const { Panel } = Collapse;

enum FilterType {
  MangopayTransferFailed = 'mangopayTransferFailed',
}

const queryFilters: Record<FilterType, OrderPaginationArgsFilter> = {
  [FilterType.MangopayTransferFailed]: {
    mangopayTransfer: {
      NONE: {
        status: {
          is: MangopayTransactionStatus.Succeeded,
        },
      },
      SOME: {
        status: { is: MangopayTransactionStatus.Failed },
      },
    },
  },
};

export const OrdersView: React.FC<OrdersViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('orders');
  const [filter, setFilter] = useState<FilterType>();

  const applyFilters = (filterType: FilterType) => () => {
    if (filterType === filter) {
      setFilter(undefined);
    } else {
      setFilter(filterType);
    }
  };

  return (
    <div className={classnames(className, styles.root)}>
      <Collapse>
        <Panel key="filters" header={t('filters.title')}>
          <Button
            type={
              filter === FilterType.MangopayTransferFailed
                ? 'primary'
                : 'default'
            }
            danger
            onClick={applyFilters(FilterType.MangopayTransferFailed)}
          >
            {t('filters.mangopay.tranfer.failed')}
          </Button>
        </Panel>
      </Collapse>
      <OrdersTable
        filter={(filter && queryFilters[filter]) || undefined}
        activeColumns={[
          'invoice.identifier',
          'campaigns',
          'createdAt',
          'updatedAt',
          'user',
          'amount',
          'status',
          'mangopayTransfers',
        ]}
      />
    </div>
  );
};

export type OrdersViewProps = {
  className?: string;
};
