import cx from 'classnames';

import { OffersTable } from '@components/tables/OffersTable/OffersTable';
import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';
import { ProductVariantsTable } from '@components/tables/ProductVariantsTable/ProductVariantsTable';

import styles from './ProductSynchronizationIdUpdatedView.module.css';

import {
  useProductSynchronizationIdUpdatedOffersViewQuery,
  useProductSynchronizationIdUpdatedProductsViewQuery,
  useProductSynchronizationIdUpdatedProductVariantsViewQuery,
} from './ProductSynchronizationIdUpdatedView.generated';

export const ProductSynchronizationIdUpdatedView: React.FC<ProductSynchronizationIdUpdatedViewProps> =
  (props) => {
    const {
      className,
      productSynchronizationId,
      showOffers = false,
      showProducts = false,
      showProductVariants = false,
    } = props;

    const offersQuery = useProductSynchronizationIdUpdatedOffersViewQuery({
      skip: showOffers === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productsQuery = useProductSynchronizationIdUpdatedProductsViewQuery({
      skip: showProducts === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productVariantsQuery =
      useProductSynchronizationIdUpdatedProductVariantsViewQuery({
        skip: showProductVariants === false,
        variables: { productSynchronizationId, take: 20, skip: 0 },
      });

    return (
      <div className={cx(className, styles.root)}>
        {showOffers && (
          <OffersTable
            title={() => <div>Offres</div>}
            activeColumns={['name', 'status', 'startsAt', 'endsAt']}
            data={
              offersQuery.data?.productSynchronization?.offersUpdatedPagination
            }
            query={offersQuery}
          />
        )}
        {showProducts && (
          <ProductsTable
            title={() => <div>Produits</div>}
            activeColumns={['name', 'brand']}
            data={
              productsQuery.data?.productSynchronization
                ?.productsUpdatedPagination
            }
            query={productsQuery}
          />
        )}
        {showProductVariants && (
          <ProductVariantsTable
            title={() => <div>Variations de produit</div>}
            activeColumns={[
              'product',
              'SKU',
              'priceWithTaxes',
              'inventoryQuantity',
            ]}
            data={
              productVariantsQuery.data?.productSynchronization
                ?.productVariantsUpdatedPagination
            }
            query={productVariantsQuery}
          />
        )}
      </div>
    );
  };

export type ProductSynchronizationIdUpdatedViewProps = {
  className?: string;
  productSynchronizationId: string;
  showOffers?: boolean;
  showProducts?: boolean;
  showProductVariants?: boolean;
};
