import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import classnames from 'classnames';

import { CompanyCampaignSelectionExcludeModal } from '@components/modals/CompanyCampaignSelectionExcludeModal/CompanyCampaignSelectionExcludeModal';
import { CampaignSelectionsTable } from '@components/tables/CampaignSelectionsTable/CampaignSelectionsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './CompanyIdCampaignSelectionsView.module.css';

import {
  useCompanyIdCampaignSelectionsExcludeRemoveMutation,
  useCompanyIdCampaignSelectionsViewQuery,
} from './CompanyIdCampaignSelectionsView.generated';

export const CompanyIdCampaignSelectionsView: React.FC<CompanyIdCampaignSelectionsViewProps> =
  (props) => {
    const { className, companyId } = props;
    const [isOpen, setOpen] = useState(false);

    const [removeMutation] =
      useCompanyIdCampaignSelectionsExcludeRemoveMutation();

    const query = useCompanyIdCampaignSelectionsViewQuery({
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
    });

    const handleDelete = (campaignSelectionId: string) => async () => {
      await removeMutation({
        variables: {
          companyId,
          input: { campaignSelectionIds: [campaignSelectionId] },
        },
      });
      await query.refetch();
    };

    const company = query?.data?.company;

    if (query.loading && !company) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!company) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <Typography.Title level={3}>
          Selection de campagnes exclues
        </Typography.Title>
        <CampaignSelectionsTable
          query={query}
          title={() => (
            <Button onClick={() => setOpen(true)}>
              Exclure une selection de campagne
            </Button>
          )}
          data={company.excludedCampaignSelectionsPagination}
          activeColumns={['name']}
          action={(record) => (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette exclusion ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete(record.id)}
            >
              <Button type="text" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        />
        <CompanyCampaignSelectionExcludeModal
          companyId={company.id}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      </div>
    );
  };

export type CompanyIdCampaignSelectionsViewProps = {
  companyId: string;
  className?: string;
};
