import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import classNames from 'classnames';
import { isNil } from 'lodash';

import {
  SubventionSpendingMode,
  SubventionType,
} from '@graphql/generated/types';

import styles from './SubventionInformationCard.module.css';

import { SubventionInformationCardFragment } from './SubventionInformationCard.generated';

type SubventionInformationCardProps = {
  className?: string;
  subvention: SubventionInformationCardFragment;
  onDetailClick?: () => void;
};

export function SubventionInformationCard(
  props: SubventionInformationCardProps,
) {
  const { className, subvention, onDetailClick } = props;
  const { t } = useTranslation('subventions');

  const modes: Record<SubventionSpendingMode, string> = {
    [SubventionSpendingMode.App]: t('spendingMode.app'),
    [SubventionSpendingMode.Refund]: t('spendingMode.refund'),
    [SubventionSpendingMode.Reimbursement]: t('spendingMode.reimbursement'),
  };

  return (
    <Card
      size="small"
      title="Informations générales"
      className={classNames(styles.root, className)}
      extra={
        !!onDetailClick && (
          <Button size="small" icon={<EyeOutlined />} onClick={onDetailClick}>
            Voir le détail
          </Button>
        )
      }
    >
      <div className={styles.content}>
        <div>
          {subvention.type === SubventionType.Fund
            ? 'Cagnotte'
            : subvention.type === SubventionType.Discount
            ? 'Réduction tarifaire'
            : ''}
          &nbsp;
          {!isNil(subvention.granteesFrozen) &&
            (subvention.granteesFrozen ? 'figée' : 'non figée')}
        </div>
        <div>
          {' > '}
          {(subvention.spendingModes || []).map((v) => modes[v]).join(', ')}
        </div>
      </div>
    </Card>
  );
}
