import { Space } from 'antd';
import classnames from 'classnames';

import { IsExclusiveToggle } from '@components/organisms/IsExclusiveToggle/IsExclusiveToggle';
import { CatalogsTable } from '@components/tables/CatalogsTable/CatalogsTable';

import styles from './CatalogIncludedView.module.css';

type EntityType = 'brand' | 'campaign' | 'offer';

export const CatalogIncludedView: React.FC<CatalogIncludedViewProps> = (
  props,
) => {
  const { className, entityId, entityType } = props;
  let filter;

  switch (entityType) {
    case 'brand':
      filter = {
        includedBrandId: entityId,
      };
      break;
    case 'campaign':
      filter = {
        includedCampaignId: entityId,
      };
      break;
    case 'offer':
      filter = {
        includedOfferId: entityId,
      };
      break;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <div>
        <CatalogsTable
          title={() => (
            <Space>
              <IsExclusiveToggle entityType={entityType} entityId={entityId} />
            </Space>
          )}
          activeColumns={['id', 'companyName']}
          filter={filter}
        />
      </div>
    </div>
  );
};

export type CatalogIncludedViewProps = {
  className?: string;
  entityId: string;
  entityType: EntityType;
};
