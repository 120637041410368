import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import { differenceInCalendarYears } from 'date-fns';
import Dinero from 'dinero.js';
import { entries, isNil } from 'lodash';

import {
  BeneficiariesArgsFilter,
  BeneficiariesRelationFilter,
  BeneficiaryRelationship,
  CohortSelectionMode,
  CohortUser,
  CompanyMembershipsArgsFilterCustomField,
  CompanyMembershipState,
  SubventionGranteeType,
  SubventionSpendingMode,
  SubventionType,
  UsersArgsFilter,
  UserStatus,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';

import styles from './SubventionInformationsModal.module.css';

import {
  SubventionInformationsModalFragment,
  useSubventionCompanyByIdsQuery,
} from './SubventionInformationsModal.generated';

type SubventionInformationsModalProps = {
  subvention: SubventionInformationsModalFragment;
  onClose?: () => void;
  opened: boolean;
};

export function SubventionInformationsModal(
  props: SubventionInformationsModalProps,
) {
  const { subvention, onClose, opened } = props;
  const { t } = useTranslation('subventions');

  const spendingModes: Record<SubventionSpendingMode, string> = {
    [SubventionSpendingMode.App]: t('spendingMode.app'),
    [SubventionSpendingMode.Refund]: t('spendingMode.refund'),
    [SubventionSpendingMode.Reimbursement]: t('spendingMode.reimbursement'),
  };

  const types: Record<SubventionType, string> = {
    [SubventionType.Discount]: t('subventionType.Discount'),
    [SubventionType.Fund]: t('subventionType.Fund'),
  };

  const granteesTypes: Record<SubventionGranteeType, string> = {
    CHILD: t('subventionGranteeType.Child', 'Enfant(s)'),
    PARTNER: t('subventionGranteeType.Partner', 'Conjoint'),
    USER: t('subventionGranteeType.User', 'Utilisateur'),
  };

  const companyMembershipState: Record<CompanyMembershipState, string> = {
    ADMIN: t('CompanyMembershipState.Admin', 'Administrateur'),
    CREATED: t('CompanyMembershipState.Created', 'A inviter'),
    DEACTIVATED: t('CompanyMembershipState.Deactivated', 'Désactivé'),
    INVITED: t('CompanyMembershipState.Invited', 'Invité'),
    USER: t('CompanyMembershipState.User', 'Utilisateur'),
  };

  const userStatus: Record<UserStatus, string> = {
    ACTIVATED: t('UserStatus.Admin', 'Activé'),
    CREATED: t('UserStatus.Created', 'A inviter'),
    INVITED: t('UserStatus.Deactivated', 'Invité'),
    ARCHIVED: t('UserStatus.Archived', 'Archivé'),
    BANNED: t('UserStatus.Banned', 'Banni'),
  };

  const beneficiaryRelationship: Record<BeneficiaryRelationship, string> = {
    CHILD: t('beneficiaryRelationship.Child', 'Enfant(s)'),
    PARTNER: t('beneficiaryRelationship.Partner', 'Conjoint'),
  };

  const query: UsersArgsFilter | undefined = subvention.cohort?.query;

  const { data } = useSubventionCompanyByIdsQuery({
    variables: {
      companyIds: query?.companyMembership?.company?.id?.in ?? null,
    },
  });

  const renderCohort = (cohort: CohortUser) => {
    switch (cohort.metadata.selectionMode) {
      case CohortSelectionMode.All:
        return 'Tous les utilisateurs';
      case CohortSelectionMode.Filter:
        return renderFilteredCohort(cohort.query);
      case CohortSelectionMode.Manual:
        return 'Selection manuelle';
    }
  };

  const renderFilteredCohort = (query: UsersArgsFilter) => {
    const {
      contract,
      seniority,
      state,
      user,
      worksite,
      customFields = [],
      jobTitle,
      role,
    } = query.companyMembership || {};

    const { status, beneficiaries = user?.beneficiaries } = query;
    const customFieldValues =
      user?.companyMembership?.customFields || customFields;

    return (
      <>
        <div>{role && `Rôle : ${role.in?.map((c) => c)}`}</div>
        <div>{jobTitle && `Poste : ${jobTitle.in?.map((c) => c)}`}</div>
        <div>{contract && `Contrat : ${contract.in?.map((c) => c)}`}</div>
        <div>
          {seniority &&
            seniority.gte &&
            `Ancienneté minimum : ${seniority.gte / 30} mois`}
        </div>
        <div>
          {seniority &&
            seniority.lte &&
            `Ancienneté maximum : ${seniority.lte / 30} mois`}
        </div>
        <div>
          {state &&
            state.include &&
            state.include.length > 0 &&
            `Accès : ${state.include
              .map((s) => companyMembershipState[s])
              .join(', ')}`}
        </div>
        <div>
          {status &&
            status.in?.length &&
            `Statut : ${status.in.map((s) => userStatus[s]).join(', ')}`}
        </div>
        <div>
          {worksite &&
            `Localisation : ${worksite.in?.map((w) => w).join(', ')}`}
        </div>
        {customFieldValues && displayCustomFields(customFieldValues)}
        <div>{beneficiaries && displayBeneficiaries(beneficiaries)}</div>
        {data?.companiesPagination.nodes &&
          data?.companiesPagination.nodes.length > 0 && (
            <div>
              Filiales :
              <ul>
                {data?.companiesPagination.nodes.map((c) => (
                  <li key={c.id}>{c.name}</li>
                ))}
              </ul>
            </div>
          )}
      </>
    );
  };

  const displayCustomFields = (
    customFields: CompanyMembershipsArgsFilterCustomField[],
  ) => {
    return customFields.map((c) => {
      const values = Object.keys(c).filter(
        (k) => k !== 'key',
      ) as unknown as (keyof CompanyMembershipsArgsFilterCustomField)[];

      const fieldsValue = values.map((v) => {
        switch (v) {
          case 'contains':
            return `contient ${c[v]}`;
          case 'endsWith':
            return `termine par ${c[v]}`;
          case 'search':
            return `recherche ${c[v]}`;
          case 'startsWith':
            return `commencer par ${c[v]}`;
          case 'exclude':
            return `exclus ${c[v]?.join(', ')}`;
          case 'include':
            return `inclus ${c[v]?.join(', ')}`;
          case 'in':
            return `contient ${c[v]?.join(', ')}`;
          case 'notIn':
            return `ne contient pas ${c[v]?.join(', ')}`;
          case 'is':
            return `égal à ${c[v]}`;
          case 'isNot':
            return `différent de ${c[v]}`;
          case 'gt':
            return `supérieur à ${c[v]}`;
          case 'gte':
            return `supérieur ou égal à ${c[v]}`;
          case 'lt':
            return `inférieur à ${c[v]}`;
          case 'lte':
            return `inférieur ou égal à ${c[v]}`;
          case 'max':
            return `max ${c[v]}`;
          case 'min':
            return `min ${c[v]}`;
          default:
            'Unknown';
        }
      });
      return (
        <div key={c.key}>
          {`champ "${c.key}"`} {fieldsValue.join(' et ')}
        </div>
      );
    });
  };

  const displayBeneficiary = (b: BeneficiariesArgsFilter) => {
    const to = b.birthDate?.gte
      ? differenceInCalendarYears(
          Date.now(),
          typeof b.birthDate?.gte === 'string'
            ? new Date(b.birthDate?.gte)
            : b.birthDate?.gte,
        )
      : undefined;

    const from = b.birthDate?.lte
      ? differenceInCalendarYears(
          Date.now(),
          typeof b.birthDate?.lte === 'string'
            ? new Date(b.birthDate?.lte)
            : b.birthDate?.lte,
        )
      : undefined;

    return (
      b.relationship?.is &&
      `${beneficiaryRelationship[b.relationship?.is]} : Oui ${
        !isNil(from) && !isNil(to) ? `, ${from} - ${to} ans(s)` : ''
      }`
    );
  };

  const displayBeneficiaries = (beneficiaries: BeneficiariesRelationFilter) => {
    return entries(beneficiaries).map(([key, value]) => {
      switch (key) {
        case 'NONE':
          return (
            value?.relationship?.is &&
            `${beneficiaryRelationship[value?.relationship?.is]} => Non`
          );
        case 'SOME':
          if (!value) return '';

          return value?.AND
            ? value?.AND?.map((b) => displayBeneficiary(b))
            : displayBeneficiary(value);
      }
    });
  };

  return (
    <Drawer
      className={styles.root}
      width={524}
      visible={opened}
      onClose={onClose}
      closable={false}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.titleWrapper}>Informations générales</div>
        </div>
        <div className={styles.subtitle}>Type de subvention</div>
        <div className={styles.block}>
          {subvention.subventionSchema ? (
            <>
              Subvention exonérée (schéma{' '}
              <I18nLink
                to={`/subventions/schemas/${subvention.subventionSchema.id}`}
              >
                {subvention.subventionSchema.name}
              </I18nLink>
              )
            </>
          ) : (
            <>Subvention personnalisée</>
          )}
          {!!subvention.type && (
            <ul>
              <li>
                {types[subvention.type]}&nbsp;
                {!isNil(subvention.granteesFrozen) &&
                  (subvention.granteesFrozen ? 'figée' : 'non figée')}
              </li>
              {(subvention.spendingModes || []).length > 0 && (
                <ul>
                  {(subvention.spendingModes || []).map((v) => (
                    <li key={v}>{spendingModes[v]}</li>
                  ))}
                </ul>
              )}
            </ul>
          )}
        </div>
        <div className={styles.subtitle}>Budget</div>
        {subvention.__typename === 'SubventionFund' && (
          <div className={styles.block}>
            {t('subventionInformations.amountByGrantee', {
              amount: Dinero({
                amount: subvention.amountByGrantee,
                currency: 'EUR',
              }),
            })}
          </div>
        )}
        {subvention.__typename === 'SubventionDiscount' && (
          <div className={styles.block}>
            {t('subventionInformations.amountDiscounted', {
              amount: Dinero({
                amount: subvention.amountDiscounted,
                currency: 'EUR',
              }),
              productQuantity: subvention.productQuantity,
            })}
          </div>
        )}
        {subvention.spendingModes?.includes(SubventionSpendingMode.App) && (
          <>
            <div className={styles.title}>
              <div className={styles.titleWrapper}>Achats inApp</div>
            </div>
            <div className={styles.subtitle}>Foyers de dépense</div>
            <div className={styles.block}>
              {subvention.campaignPagination.totalCount > 0 ? (
                <div>
                  Campagnes:{' '}
                  {subvention.campaignPagination.nodes
                    .map((n) => n.name)
                    .join(', ')}
                  {subvention.campaignPagination.totalCount > 3 &&
                    ` (+ ${subvention.campaignPagination.totalCount - 3})`}
                </div>
              ) : subvention.categories.totalCount > 0 ? (
                <div>
                  Catégories:{' '}
                  {subvention.categories.nodes.map((n) => n.name).join(', ')}
                </div>
              ) : subvention.subventionSchema ? (
                <>
                  Voir le schéma&nbsp;
                  <I18nLink
                    to={`/subventions/schemas/${subvention.subventionSchema.id}`}
                  >
                    {subvention.subventionSchema.name}
                  </I18nLink>
                </>
              ) : (
                'Toute la plateforme'
              )}
            </div>
          </>
        )}
        {(subvention.spendingModes?.includes(SubventionSpendingMode.Refund) ||
          subvention.spendingModes?.includes(
            SubventionSpendingMode.Reimbursement,
          )) && (
          <>
            <div className={styles.title}>
              <div className={styles.titleWrapper}>Remboursements</div>
            </div>
            {!!subvention.detail && (
              <>
                <div className={styles.subtitle}>Dépenses éligibles</div>
                <div className={styles.block}>{subvention.detail}</div>
              </>
            )}
            {!!subvention.reimbursementPolicy && (
              <>
                <div className={styles.subtitle}>
                  Politique de remboursement
                </div>
                <div className={styles.block}>
                  {subvention.reimbursementPolicy}
                </div>
              </>
            )}
          </>
        )}
        <div className={styles.title}>
          <div className={styles.titleWrapper}>Ciblage bénéficaires</div>
        </div>
        <div className={styles.subtitle}>Bénéficiares effectifs</div>
        {subvention.granteesTypes?.map((gt) => granteesTypes[gt]).join(', ')}

        {subvention.cohort && (
          <>
            <div className={styles.subtitle}>Ciblage</div>
            {renderCohort(subvention.cohort as CohortUser)}
          </>
        )}
      </div>
      <Button className={styles.close} onClick={onClose} block>
        Fermer cette fenêtre
      </Button>
    </Drawer>
  );
}
