import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormPicture } from '@components/atoms/FormPicture/FormPicture';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductArticleCreateForm.module.css';

import {
  ProductArticleCreateFormMutation,
  ProductArticleCreateFormMutationVariables,
  useProductArticleCreateFormMutation,
} from './ProductArticleCreateForm.generated';

export type ProductArticleCreateFormValues = {
  productVariantId: string;
  barcodeValue?: string | null;
  barcodeFormat?: string | null;
  pin?: string | null;
  url?: string | null;
  fileId?: string | null;
  expiresAt?: Date | null;
};

export function ProductArticleCreateForm(props: ProductArticleCreateFormProps) {
  const { className, defaultValues, ...rest } = props;
  const { t } = useTranslation('productArticles');

  const validationSchema = Yup.object({
    productVariantId: Yup.string().required(),
    barcodeValue: Yup.string().nullable(),
    barcodeFormat: Yup.string().nullable(),
    pin: Yup.string().nullable(),
    url: Yup.string().nullable(),
    fileId: Yup.string().nullable(),
    expiresAt: Yup.date().nullable(),
  }).required();

  const form = useForm<ProductArticleCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useProductArticleCreateFormMutation();

  const handleSubmit: FormProps<ProductArticleCreateFormValues>['onValid'] =
    async (values) => {
      const variables: ProductArticleCreateFormMutationVariables = {
        productVariantId: values.productVariantId,
        input: {
          barcodeValue: values.barcodeValue,
          barcodeFormat: values.barcodeFormat,
          pin: values.pin,
          url: values.url,
          fileId: values.fileId,
          expiresAt: values.expiresAt,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductArticleCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.productVariant}
          required
          label={t('productArticleCreateForm.fields.productVariant.label')}
          name="productVariantId"
        >
          {/* <FormSelectProductVariant name="productVariantId" /> */}
        </FormItem>

        <FormItem
          className={styles.barcodeValue}
          required
          label={t('productArticleCreateForm.fields.barcodeValue.label')}
          name="translation.barcodeValue"
        >
          <FormInput name="barcodeValue" />
        </FormItem>

        <FormItem
          className={styles.barcodeFormat}
          required
          label={t('productArticleCreateForm.fields.barcodeFormat.label')}
          name="translation.barcodeFormat"
        >
          <FormInput name="barcodeFormat" />
        </FormItem>

        <FormItem
          className={styles.pin}
          required
          label={t('productArticleCreateForm.fields.pin.label')}
          name="translation.pin"
        >
          <FormInput name="pin" />
        </FormItem>

        <FormItem
          className={styles.url}
          required
          label={t('productArticleCreateForm.fields.url.label')}
          name="translation.url"
        >
          <FormInput name="url" />
        </FormItem>

        <FormItem
          className={styles.file}
          required
          label={t('productArticleCreateForm.fields.file.label')}
          name="fileId"
        >
          <FormPicture name="fileId" type={FileType.ProductArticle} />
        </FormItem>

        <FormItem
          className={styles.expiresAt}
          label={t('productArticleCreateForm.expiresAt.label')}
          name="expiresAt"
        >
          <FormInputDate
            name="expiresAt"
            disabledDate={(date) => date.isBefore(new Date())}
          />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('productArticleCreateForm.submit.create.label')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ProductArticleCreateFormProps = ChildFormProps<
  ProductArticleCreateFormValues,
  ProductArticleCreateFormMutation
> & {
  className?: string;
  defaultValues?: ProductArticleCreateFormValues;
};
