import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from 'antd';
import classnames from 'classnames';

import styles from './ProductVariantInventoryQuantityTag.module.css';

import { ProductVariantInventoryQuantityTagFragment } from './ProductVariantInventoryQuantityTag.generated';

export const ProductVariantInventoryQuantityTag: React.FC<ProductVariantInventoryQuantityTagProps> =
  (props) => {
    const { className, productVariant } = props;
    const { t } = useTranslation('productVariants');

    if (productVariant.inventoryQuantity < 0) {
      return (
        <Tooltip title={t('stock.unlimited')}>
          <Tag className={classnames(styles.tag, className)} color="green">
            {t('stock.unlimited')}
          </Tag>
        </Tooltip>
      );
    } else if (productVariant.inventoryQuantity === 0) {
      return (
        <Tooltip title={t('stock.outOfStock')}>
          <Tag className={classnames(styles.tag, className)} color="red">
            {productVariant.inventoryQuantity}
          </Tag>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t('stock.limited')}>
          <Tag className={classnames(styles.tag, className)} color="blue">
            {productVariant.inventoryQuantity}
          </Tag>
        </Tooltip>
      );
    }
  };

export type ProductVariantInventoryQuantityTagProps = {
  className?: string;
  productVariant: ProductVariantInventoryQuantityTagFragment;
};
