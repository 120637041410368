import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Space, Typography } from 'antd';
import classnames from 'classnames';

import { HighlightTargetType } from '@graphql/generated/types';

import {
  ArticleUpdateForm,
  ArticleUpdateFormProps,
} from '@components/forms/ArticleUpdateForm/ArticleUpdateForm';
import { ArticleUpdateFormFragment } from '@components/forms/ArticleUpdateForm/ArticleUpdateForm.generated';
import { HighlightButton } from '@components/organisms/HighlightButton/HighlightButton';

import styles from './ArticleUpdateView.module.css';

export const ArticleUpdateView: React.FC<ArticleUpdateViewProps> = (props) => {
  const { className, article } = props;
  const { t, i18n } = useTranslation('communication');
  const history = useHistory();

  const handleSuccess: ArticleUpdateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/communication/articles/list/${result.articleUpdate.article.id}/update`,
    );
  };
  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('articles.update.title')}</Typography.Title>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <HighlightButton
            targetType={HighlightTargetType.Article}
            targetId={article.id}
          />
          <ArticleUpdateForm onSuccess={handleSuccess} article={article} />
        </Space>
      </div>
    </div>
  );
};

export type ArticleUpdateViewProps = {
  className?: string;
  article: ArticleUpdateFormFragment;
};
