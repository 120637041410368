import { DeepPartial } from 'react-hook-form';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormSelectProductCatalog } from '@atoms/FormSelectProductCatalog/FormSelectProductCatalog';
import { FormSelectProductProvider } from '@atoms/FormSelectProductProvider/FormSelectProductProvider';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductSynchronizationCreateForm.module.css';

import {
  ProductSynchronizationCreateFormMutation,
  ProductSynchronizationCreateFormMutationVariables,
  useProductSynchronizationCreateFormMutation,
} from './ProductSynchronizationCreateForm.generated';

export type ProductSynchronizationCreateFormValues = {
  providerId: string;
  catalogId: string;
};

export function ProductSynchronizationCreateForm(
  props: ProductSynchronizationCreateFormProps,
) {
  const { className, defaultValues = {}, ...rest } = props;

  const validationSchema = Yup.object({
    providerId: Yup.string().required(),
    catalogId: Yup.string().required(),
  }).required();

  const form = useForm<ProductSynchronizationCreateFormValues>({
    validationSchema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const [mutation] = useProductSynchronizationCreateFormMutation();

  const handleSubmit: FormProps<ProductSynchronizationCreateFormValues>['onValid'] =
    async (values) => {
      const variables: ProductSynchronizationCreateFormMutationVariables = {
        input: {
          providerId: values.providerId,
          catalogId: values.catalogId,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  const { providerId } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductSynchronizationCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.provider}
          required
          label="Fournisseur"
          name="providerId"
        >
          <FormSelectProductProvider name="providerId" />
        </FormItem>
        <FormItem
          className={styles.catalog}
          required
          label="Catalogue"
          name="catalogId"
          dependsOn={['providerId']}
        >
          <FormSelectProductCatalog
            disabled={!providerId}
            filter={{ provider: { id: { is: providerId } } }}
            name="catalogId"
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la synchronization
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ProductSynchronizationCreateFormProps = ChildFormProps<
  ProductSynchronizationCreateFormValues,
  ProductSynchronizationCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<ProductSynchronizationCreateFormValues>;
};
