import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';

import styles from './ProductsView.module.css';

export const ProductsView: React.FC<ProductsViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('products');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('productsView.title')}</Typography.Title>
      <ProductsTable activeColumns={['name', 'brand', 'offer', 'price']} />
    </div>
  );
};

export type ProductsViewProps = {
  className?: string;
};
