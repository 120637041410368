import classnames from 'classnames';

import {
  FormTreeNode,
  FormTreeSelect,
  FormTreeSelectProps,
} from '../FormTreeSelect/FormTreeSelect';

import styles from './FormSelectCategory.module.css';

import {
  FormSelectCategoryQueryVariables,
  useFormSelectCategoryQuery,
} from './FormSelectCategory.generated';

export const FormSelectCategory: React.FC<FormSelectCategoryProps> = (
  props,
) => {
  const { className, rootOnly, filter, ...rest } = props;

  const { data, loading } = useFormSelectCategoryQuery({
    variables: { filter },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <FormTreeSelect
      {...rest}
      className={classnames(className, styles.root)}
      loading={loading}
      treeNodeFilterProp="title"
    >
      {data?.categoriesPagination.nodes.map((parent) => (
        <FormTreeNode key={parent.id} value={parent.id} title={parent.name}>
          {rootOnly !== true &&
            parent.childrenPagination.nodes.map((child) => (
              <FormTreeNode key={child.id} value={child.id} title={child.name}>
                Fo
              </FormTreeNode>
            ))}
        </FormTreeNode>
      ))}
    </FormTreeSelect>
  );
};

export type FormSelectCategoryProps = FormTreeSelectProps & {
  className?: string;
  rootOnly?: boolean;
  filter?: FormSelectCategoryQueryVariables['filter'];
};
