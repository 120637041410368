import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  useVoucherRewardUpdateFormMutation,
  VoucherRewardFragment,
  VoucherRewardUpdateFormMutation,
  VoucherRewardUpdateFormMutationVariables,
} from '../VoucherUpdateForm.generated';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from '../VoucherUpdateForm.module.css';

export type VoucherRewardUpdateFormValues = {
  quantity: number;
  reward: string;
};

export function VoucherRewardUpdateForm(props: VoucherRewardUpdateFormProps) {
  const { className, voucher, ...rest } = props;

  const { t } = useTranslation('voucher');

  const validationSchema = Yup.object({
    quantity: Yup.number().required(),
    reward: Yup.string().required(),
  }).required();

  const form = useForm<VoucherRewardUpdateFormValues>({
    validationSchema,
    defaultValues: {
      quantity: voucher.quantity,
      reward: voucher.reward,
    },
  });

  const [mutation] = useVoucherRewardUpdateFormMutation();

  const handleSubmit: FormProps<VoucherRewardUpdateFormValues>['onValid'] =
    async (values) => {
      if (!voucher) return;

      const variables: VoucherRewardUpdateFormMutationVariables = {
        voucherId: voucher.id,
        input: {
          quantity: values.quantity,
          reward: values.reward,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <div className={classnames(className, styles.root)}>
      <Form
        id="VoucherRewardUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          required
          className={styles.quantity}
          label={t('fields.quantity')}
          name="quantity"
        >
          <FormInputNumber name="quantity" />
        </FormItem>

        <FormItem
          required
          className={styles.reward}
          label={t('fields.reward')}
          name="reward"
        >
          <FormInput name="reward" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export type VoucherRewardUpdateFormProps = ChildFormProps<
  VoucherRewardUpdateFormValues,
  VoucherRewardUpdateFormMutation
> & {
  className?: string;
  voucher: VoucherRewardFragment;
};
