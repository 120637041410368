import { Spin } from 'antd';

import { SubventionReimbursementsTable } from '@components/tables/SubventionReimbursementsTable/SubventionReimbursementsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useUserSubventionReimbursementsListViewQuery } from './UserIdReimbursementView.generated';

export const UserIdReimbursementView: React.FC<UserIdReimbursementViewProps> = (
  props,
) => {
  const { userId } = props;

  const query = useUserSubventionReimbursementsListViewQuery({
    variables: {
      take: 10,
      skip: 0,
      filter: {
        user: { id: { is: userId } },
      },
    },
  });

  const pagination = query.data?.subventionReimbursementRequestPagination;

  if (query.loading && !pagination) return <Spin />;
  else if (!pagination) return <NotFoundView />;

  return (
    <SubventionReimbursementsTable
      activeColumns={['id', 'customMerchant', 'reimbursementAmount', 'status']}
      query={query}
      data={pagination}
    />
  );
};

export type UserIdReimbursementViewProps = {
  userId: string;
};
