import classnames from 'classnames';

import {
  TaxRateUpdateForm,
  TaxRateUpdateFormProps,
} from '@components/forms/TaxRateUpdateForm/TaxRateUpdateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './TaxRateUpdateModal.module.css';

export type TaxRateUpdateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: TaxRateUpdateFormProps['onSuccess'];
  isOpen?: boolean;
  taxRate: TaxRateUpdateFormProps['taxRate'];
};

export function TaxRateUpdateModal(props: TaxRateUpdateModalProps) {
  const { className, onClose, onSuccess, isOpen, taxRate } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: TaxRateUpdateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="TaxRateUpdateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="TaxRateUpdateForm"
    >
      <TaxRateUpdateForm
        onSuccess={handleSuccess}
        hideFooter={true}
        taxRate={taxRate}
      />
    </Modal>
  );
}
