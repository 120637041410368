import { RcFile } from 'antd/lib/upload';
import axios from 'axios';

import { convertImageToDataURL } from '../convertImageToDataURL';

import { FileType } from '@graphql/generated/types';

import { convertImageToHash } from '@utils/convertImageToHash';

import { FormPictureCreateMutationVariables } from '@components/atoms/FormPicture/FormPicture.generated';

import { PicturePostFormDataFragment } from './extractImageInformations.generated';

export async function extractImageInformations(file: File) {
  const src = await convertImageToDataURL(file);

  return new Promise<{ height: number; width: number; hash: string }>(
    (resolve) => {
      const img = new window.Image();

      img.onload = async function () {
        resolve({
          width: img.width,
          height: img.height,
          hash: await convertImageToHash(img),
        });
      };

      img.src = src;
    },
  );
}

export const convertFileToPayload = async (
  file: File,
  type: FileType,
): Promise<FormPictureCreateMutationVariables> => {
  const { hash, height, width } = await extractImageInformations(file);
  return {
    input: {
      type,
      fileName: file.name,
      mimetype: file.type,
      size: file.size,
      height,
      width,
      hash,
    },
  };
};

export const postFormData = async (
  file: File,
  data: PicturePostFormDataFragment,
  onUploadProgress?: (progressEvent: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { fields, url } = data;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });

  formData.append('file', file, file.name);

  await axios({
    url,
    method: 'POST',
    data: formData,
    onUploadProgress,
  });
};

export const convertFileToDataUrl = async (file: RcFile) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(file);
  });
};
