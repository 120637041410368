import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, message, Row } from 'antd';
import classNames from 'classnames';
import * as Yup from 'yup';

import { LegalPersonType } from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { CompanyMangopayViewFragment } from '@components/views/Companies/CompanyIdMangopayView/CompanyIdMangopayView.generated';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './MangopayLegalUserCreateForm.module.css';

import {
  MangopayLegalUserCreateFormMutation,
  useMangopayLegalUserCreateFormMutation,
} from './MangopayLegalUserCreateForm.generated';

export type MangopayLegalUserCreateFormValues = {
  type: LegalPersonType;
  companyNumber?: string;
  representative: {
    firstName: string;
    lastName: string;
    birthday: Date;
    countryOfResidence: string;
    nationality: string;
  };
};

export function MangopayLegalUserCreateForm(
  props: MangopayLegalUserCreateFormProps,
) {
  const { className, defaultValues, company, ...rest } = props;

  const { t } = useTranslation('mangopayLegalUser');

  const doesCompanyHaveAddress = company.address
    ? Object.entries(company.address)
        .filter(([key, _value]) => key !== 'address2')
        .every(([_key, value]) => Boolean(value))
    : false;

  const validationSchema = Yup.object({
    type: Yup.mixed<LegalPersonType>()
      .oneOf(Object.values(LegalPersonType))
      .required(),
    companyNumber: Yup.string()
      .when('type', {
        is: LegalPersonType.Business,
        then: (schema) => schema.required(),
      })
      .transform((value) => value?.replace(/\s/g, '')),
    representative: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      birthday: Yup.date().required(),
      countryOfResidence: Yup.string().uppercase().max(2).required(),
      nationality: Yup.string().required(),
    }),
  }).required();

  const form = useForm<MangopayLegalUserCreateFormValues>({
    validationSchema,
    defaultValues,
    mode: 'onChange',
  });

  const [createMutation] = useMangopayLegalUserCreateFormMutation();

  const handleSubmit: FormProps<MangopayLegalUserCreateFormValues>['onValid'] =
    (values) => {
      return createMutation({
        variables: {
          companyId: company.id,
          input: {
            type: values.type,
            walletCurrency: 'EUR',
            companyNumber: values.companyNumber,
            representative: {
              birthday: values.representative.birthday,
              firstName: values.representative.firstName,
              lastName: values.representative.lastName,
              countryOfResidence: values.representative.countryOfResidence,
              nationality: values.representative.nationality,
            },
          },
        },
      })
        .then(({ data }) => data)
        .catch((error) => {
          console.error(error);
          message.error(error.message);
        });
    };

  return (
    <>
      {!doesCompanyHaveAddress && (
        <Alert
          message="L'adresse postale de la société n'est pas renseigné dans les paramètres"
          type="warning"
          showIcon
        />
      )}
      {!company.email && (
        <Alert
          message="L'email de la société n'est pas renseigné dans les paramètres"
          type="warning"
          showIcon
        />
      )}
      <Card className={classNames(className, styles.root)}>
        <Form
          id="MangopayLegalUserCreateForm"
          className={styles.form}
          onValid={handleSubmit}
          form={form}
          {...rest}
        >
          <FormItem
            className={styles.translationName}
            required
            label={t('mangopayLegalUser.form.fields.type')}
            name="type"
          >
            <FormSelect
              options={[
                {
                  value: LegalPersonType.Business,
                  label: 'BUSINESS',
                },
                {
                  value: LegalPersonType.Organization,
                  label: 'ORGANIZATION',
                },
                {
                  value: LegalPersonType.Soletrader,
                  label: 'SOLETRADER',
                },
              ]}
              name="type"
            />
          </FormItem>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                className={styles.translationName}
                required={form.getValues('type') === LegalPersonType.Business}
                label={t('mangopayLegalUser.form.fields.companyNumber')}
                name="companyNumber"
              >
                <FormInput name="companyNumber" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                className={styles.translationName}
                required
                label={t(
                  'mangopayLegalUser.form.fields.representative.firstName',
                )}
                name="representative.firstName"
              >
                <FormInput name="representative.firstName" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className={styles.translationName}
                required
                label={t(
                  'mangopayLegalUser.form.fields.representative.lastName',
                )}
                name="representative.lastName"
              >
                <FormInput name="representative.lastName" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <FormItem
                className={styles.translationName}
                required
                label={t(
                  'mangopayLegalUser.form.fields.representative.birthday',
                )}
                name="representative.birthday"
              >
                <FormInputDate name="representative.birthday" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                className={styles.translationName}
                required
                label={t(
                  'mangopayLegalUser.form.fields.representative.countryOfResidence',
                )}
                name="representative.countryOfResidence"
              >
                <FormSelectCountry name="representative.countryOfResidence" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                className={styles.translationName}
                required
                label={t(
                  'mangopayLegalUser.form.fields.representative.nationality',
                )}
                name="representative.nationality"
              >
                <FormSelectCountry name="representative.nationality" />
              </FormItem>
            </Col>
          </Row>

          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('mangopayLegalUser.create.title')}
          </Button>
        </Form>
      </Card>
    </>
  );
}

export type MangopayLegalUserCreateFormProps = ChildFormProps<
  MangopayLegalUserCreateFormValues,
  MangopayLegalUserCreateFormMutation
> & {
  company: CompanyMangopayViewFragment;
  className?: string;
  defaultValues?: Partial<MangopayLegalUserCreateFormValues>;
};
