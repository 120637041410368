import { useTranslation } from 'react-i18next';

import { I18nLink } from '@atoms/Link/Link';
import { TableRendererAvatar } from '@molecules/TableRendererAvatar/TableRendererAvatar';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CategoriesTablePaginationFragment,
  CategoriesTableQueryVariables,
  CategoriesTableRecordFragment,
  useCategoriesTableQuery,
} from './CategoriesTable.generated';

type RecordType = CategoriesTableRecordFragment;

type Column =
  | 'icon'
  | 'name'
  | 'brandCount'
  | 'offerCount'
  | 'campaignCount'
  | 'childrenCount'
  | 'parent';

export function CategoriesTable(props: CategoriesTableProps) {
  const {
    className,
    filter,
    sorter,
    data: propData,
    hideChildren,
    ...rest
  } = props;

  const { t } = useTranslation('categories');

  const query = useCategoriesTableQuery({
    skip: !!rest.query,
    variables: {
      skip: 0,
      take: 10,
      filter,
      sorter,
    },
  });

  let data: CategoriesTablePaginationFragment | null = null;

  if (propData) data = propData as CategoriesTablePaginationFragment;
  else if (query.data?.categoriesPagination)
    data = query.data.categoriesPagination;

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'icon',
      title: t('table.headers.icon'),
      dataIndex: ['icon'],
      width: 64,
      render: (icon, node) => (
        <TableRendererAvatar picture={icon} placeholder={node.name[0]} />
      ),
    },
    {
      key: 'name',
      title: t('table.headers.name'),
      sorter: true,
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/categories/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'brandCount',
      title: t('table.headers.brandCount'),
      dataIndex: ['brandsPagination', 'totalCount'],
    },
    {
      key: 'offerCount',
      title: t('table.headers.offerCount'),
      dataIndex: ['offersPagination', 'totalCount'],
    },
    {
      key: 'campaignCount',
      title: t('table.headers.campaignCount'),
      dataIndex: ['campaignPagination', 'totalCount'],
    },
    {
      key: 'childrenCount',
      title: t('table.headers.childrenCount'),
      dataIndex: ['childrenPagination', 'totalCount'],
    },
    {
      key: 'parent',
      title: t('table.headers.parent'),
      dataIndex: ['parent'],
      render: (parent) =>
        parent && (
          <I18nLink to={`/categories/list/${parent.id}`}>
            {parent.name}
          </I18nLink>
        ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CategoriesTable"
        query={query}
        columns={columns}
        data={
          data?.nodes
            ? {
                ...data,
                nodes: data.nodes.map((node) => ({
                  ...node,
                  children: hideChildren
                    ? undefined
                    : node.children.nodes.length
                    ? node.children.nodes
                    : undefined,
                })),
              }
            : null
        }
        {...rest}
      />
    </div>
  );
}

export type CategoriesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: CategoriesTableQueryVariables['filter'];
  sorter?: CategoriesTableQueryVariables['sorter'];
  hideChildren?: boolean;
};
