import { HappeningsTable } from '@components/tables/HappeningsTable/HappeningsTable';

import { useUserIdHappeningsViewQuery } from './UserIdHappeningsView.generated';

export type UserIdHappeningsViewProps = {
  userId: string;
};

export const UserIdHappeningsView = (props: UserIdHappeningsViewProps) => {
  const { userId } = props;

  const query = useUserIdHappeningsViewQuery({
    variables: { userId, take: 20, skip: 0 },
  });

  return (
    <div>
      <HappeningsTable
        activeColumns={[
          'id',
          'name',
          'registrationSartsAt',
          'registrationEndsAt',
          'happeningStartsAt',
          'happeningEndsAt',
        ]}
        data={query.data?.user?.happeningPagination}
        query={query}
      />
    </div>
  );
};
