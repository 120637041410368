import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import QueryString from 'qs';

import {
  ArticleCreateForm,
  ArticleCreateFormProps,
} from '@components/forms/ArticleCreateForm/ArticleCreateForm';

import styles from './ArticleCreateView.module.css';

export const ArticleCreateView: React.FC<ArticleCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('communication');
  const history = useHistory();
  const location = useLocation();

  const companyId = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }).companyId as string | undefined;

  const handleSuccess: ArticleCreateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/communication/articles/${result.articleCreate.article.id}`,
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('articles.create.title')}</Typography.Title>
        <ArticleCreateForm companyId={companyId} onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type ArticleCreateViewProps = {
  className?: string;
};
