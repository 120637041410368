import { useState } from 'react';
import { Checkbox } from 'antd';

import { CampaignStatus } from '@graphql/generated/types';

import ErrorModal from '@components/modals/ErrorModal/ErrorModal';

import {
  useCampaignPublishCheckboxPublishCampaignMutation,
  useCampaignPublishCheckboxQuery,
  useCampaignPublishCheckboxUnpublishCampaignMutation,
} from './CampaignPublishCheckbox.generated';

type Props = {
  campaignId: string;
};

const CampaignPublishCheckbox = ({ campaignId }: Props) => {
  const [error, setError] = useState<string>();

  const { data } = useCampaignPublishCheckboxQuery({
    variables: { id: campaignId },
  });

  const [publishCampaign] = useCampaignPublishCheckboxPublishCampaignMutation({
    variables: { campaignId },
    optimisticResponse: {
      __typename: 'Mutation',
      campaignPublish: {
        __typename: 'CampaignPublishOutput',
        campaign: {
          __typename: 'Campaign',
          id: campaignId,
          status: CampaignStatus.Published,
          isHighlighted: false,
        },
      },
    },
  });

  const [unpublishCampaign] =
    useCampaignPublishCheckboxUnpublishCampaignMutation({
      variables: { campaignId },
      optimisticResponse: {
        __typename: 'Mutation',
        campaignUnpublish: {
          __typename: 'CampaignUnpublishOutput',
          campaign: {
            __typename: 'Campaign',
            id: campaignId,
            status: CampaignStatus.Unpublished,
            isHighlighted: false,
          },
        },
      },
    });

  const handlePublishCampaign = async () => {
    try {
      await publishCampaign();
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <div>
      <Checkbox
        checked={data?.campaign?.status === CampaignStatus.Published || false}
        onChange={() =>
          data?.campaign?.status === CampaignStatus.Published
            ? unpublishCampaign()
            : handlePublishCampaign()
        }
      >
        Publiée
      </Checkbox>
      <ErrorModal onClose={() => setError(undefined)} error={error} />
    </div>
  );
};

export default CampaignPublishCheckbox;
