import React from 'react';
import classnames from 'classnames';

import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';

import styles from './CompaniesView.module.css';

export const CompaniesView: React.FC<CompaniesViewProps> = (props) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <CompaniesTable
        activeColumns={[
          'logo',
          'name',
          'displayName',
          'membershipCount',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type CompaniesViewProps = {
  className?: string;
};
