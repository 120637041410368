import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import Dinero from 'dinero.js';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { SubventionState, SubventionType } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionStateTag } from '@components/atoms/SubventionStateTag/SubventionStateTag';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDate } from '@components/molecules/TableRendererDate/TableRendererDate';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { UserSubventionsTableRecordFragment } from './UserSubventionsTable.generated';

type RecordType = UserSubventionsTableRecordFragment;

type Column =
  | 'name'
  | 'type'
  | 'startsAt'
  | 'endsAt'
  | 'company'
  | 'state'
  | 'unitAmount'
  | 'consumption';

export type UserSubventionsTableProps = Omit<
  ChildTablePaginationProps<RecordType, Column>,
  'query' | 'data'
> &
  Pick<
    Required<ChildTablePaginationProps<RecordType, Column>>,
    'query' | 'data'
  >;

export const UserSubventionsTable = (props: UserSubventionsTableProps) => {
  const { data, query, ...rest } = props;

  const { i18n, t } = useTranslation('subventions');

  const formatAmount = (amount: number | null | undefined) =>
    Dinero({
      currency: 'EUR',
      amount: amount || 0,
    })
      .setLocale(i18n.resolvedLanguage)
      .toFormat();

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      title: t('tables.headers.id'),
      dataIndex: ['node', 'id'],
      key: 'id',
      ...generateColumnFilter(query, 'id', 'id'),
    },
    {
      title: t('tables.headers.name'),
      dataIndex: ['node', 'name'],
      key: 'name',
      render: (name, subvention) => (
        <I18nLink to={`/subventions/subventions/${subvention.node.id}`}>
          {name}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'name', 'search'),
    },
    {
      title: t('tables.headers.type'),
      dataIndex: ['node', 'type'],
      key: 'type',
      render: (type: string) => (type ? <Tag>{type}</Tag> : ''),
    },
    {
      title: t('tables.headers.startsAt'),
      dataIndex: ['node', 'startsAt'],
      key: 'startsAt',
      render: (startsAt) => <TableRendererDate value={startsAt} />,
    },
    {
      title: t('tables.headers.endsAt'),
      dataIndex: ['node', 'endsAt'],
      key: 'endsAt',
      render: (endsAt) => <TableRendererDate value={endsAt} />,
    },
    {
      title: t('tables.headers.unitAmount'),
      dataIndex: 'node',
      key: 'unitAmount',
      render: (subvention) => {
        switch (subvention.type) {
          case SubventionType.Fund:
            return (
              <TableRendererCurrency
                value={{
                  amount: subvention.amountByGrantee || 0,
                  currency: subvention.currency,
                }}
              />
            );
          case SubventionType.Discount:
            return (
              <TableRendererCurrency
                value={{
                  amount: subvention.amountDiscounted || 0,
                  currency: subvention.currency,
                }}
              />
            );
          default:
            return 'N/A';
        }
      },
    },
    {
      title: t('tables.headers.consumption'),
      dataIndex: 'node',
      key: 'consumption',
      render: (subvention, userSubvention) => {
        switch (subvention.type) {
          case SubventionType.Fund:
            return `${formatAmount(
              userSubvention.amountConsumed || 0,
            )} / ${formatAmount(userSubvention.amountTotal || 0)}`;
          case SubventionType.Discount:
            return `${userSubvention.quantityConsumed || 0} / ${
              userSubvention.quantityTotal || 0
            }`;
          default:
            return 'N/A';
        }
      },
    },
    {
      title: t('tables.headers.state'),
      dataIndex: ['node', 'state'],
      key: 'state',
      render: (state) => <SubventionStateTag subventionState={state} />,
      ...generateColumnFilter(query, 'state', 'enum', [
        {
          label: t('subventionState.Completed'),
          value: SubventionState.Completed,
        },
        {
          label: t('subventionState.Published'),
          value: SubventionState.Published,
        },
      ]),
    },
  ];

  return (
    <div>
      <TablePagination<RecordType>
        id="UserSubventionsTable"
        columns={columns}
        query={query}
        data={data}
        {...rest}
      />
    </div>
  );
};
