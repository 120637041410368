import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UserUpdateFormValues } from '../UserUpdateForm/UserUpdateForm';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectProvince } from '@components/atoms/FormSelectProvince/FormSelectProvince';

import styles from './AddressForm.module.css';

type AddressFormProps = {
  title: string;
  formKey: 'billingAddress' | 'shippingAddress';
  form: UseFormReturn<UserUpdateFormValues, Record<string, unknown>>;
};

const AddressForm = ({ title, formKey, form }: AddressFormProps) => {
  const { t } = useTranslation('address');

  const values = form.getValues();
  const country = values[formKey]?.country;

  return (
    <div className={styles.addressForm}>
      <h2 className={styles.title}>{title}</h2>
      <FormItem
        className={styles.address1}
        label={t('fields.address1.label')}
        name={`${formKey}.address1`}
      >
        <FormInput name={`${formKey}.address1`} />
      </FormItem>
      <FormItem
        className={styles.address2}
        label={t('fields.address2.label')}
        name={`${formKey}.address2`}
      >
        <FormInput name={`${formKey}.address2`} />
      </FormItem>
      <FormItem
        className={styles.city}
        label={t('fields.city.label')}
        name={`${formKey}.city`}
      >
        <FormInput name={`${formKey}.city`} />
      </FormItem>
      <FormItem
        className={styles.zip}
        label={t('fields.zip.label')}
        name={`${formKey}.zip`}
      >
        <FormInput name={`${formKey}.zip`} />
      </FormItem>
      <FormItem
        className={styles.province}
        label={t('fields.province.label')}
        name={`${formKey}.province`}
        dependsOn={[`${formKey}.country`]}
      >
        <FormSelectProvince
          countryCode={country || undefined}
          name={`${formKey}.province`}
          clearable
        />
      </FormItem>
      <FormItem
        className={styles.country}
        label={t('fields.country.label')}
        name={`${formKey}.country`}
      >
        <FormSelectCountry name={`${formKey}.country`} clearable />
      </FormItem>
    </div>
  );
};

export default AddressForm;
