import { Spin } from 'antd';
import classnames from 'classnames';

import { ArticleItemContentFragment } from '../ArticleIdView/ArticleIdView.generated';

import { Card } from '@components/atoms/Card/Card';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ArticleIdDetailedView.module.css';

export const ArticleIdDetailedView: React.FC<ArticleIdDetailedViewProps> = ({
  article,
  loading,
  className,
}) => {
  if (loading && !article) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!article) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <Card className={classnames(className, styles.root)}>
        <div className={styles.header}>
          {article.author && (
            <div>
              <strong>Auteur:</strong> {article.author.firstName}{' '}
              {article.author.lastName}
            </div>
          )}

          <strong>{article.category.name}</strong>
        </div>
        <h1>{article.title}</h1>

        <p
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: article.contentHTML }}
        />

        <div>
          {article.attachmentsPagination.nodes.map((attachment) => (
            <div key={attachment.id}>
              <a href={attachment.url}>{attachment.originalName}</a>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export type ArticleIdDetailedViewProps = {
  article?: ArticleItemContentFragment;
  loading: boolean;
  className?: string;
};
