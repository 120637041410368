import { Spin } from 'antd';

import { SubventionSchemaUpdateForm } from '@components/forms/SubventionSchemaUpdateForm/SubventionSchemaUpdateForm';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useSubventionSchemaSettingsViewQuery } from './SubventionSchemaSettingsView.generated';

export const SubventionSchemaSettingsView = (
  props: SubventionSchemaSettingsViewProps,
) => {
  const { subventionSchemaId } = props;

  const query = useSubventionSchemaSettingsViewQuery({
    variables: { subventionSchemaId },
  });

  const subventionSchema = query.data?.subventionSchema;

  if (query.loading && !subventionSchema) {
    return <Spin size="large" />;
  } else if (!subventionSchema) {
    return <NotFoundView />;
  }

  return (
    <div>
      <SubventionSchemaUpdateForm subventionSchema={subventionSchema} />
    </div>
  );
};

export type SubventionSchemaSettingsViewProps = {
  subventionSchemaId: string;
};
