import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { message } from 'antd';
import classnames from 'classnames';

import { Typography } from '@atoms/Typography/Typography';
import {
  CompanyCreateForm,
  CompanyCreateFormProps,
} from '@forms/CompanyCreateForm/CompanyCreateForm';

import styles from './CompanyCreateView.module.css';

export const CompanyCreateView: React.FC<CompanyCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('companies');
  const history = useHistory();

  const handleSuccess: CompanyCreateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/companies/list/${result.companyCreate.company.id}`,
    );
  };
  const handleFailure: CompanyCreateFormProps['onFailure'] = (error) => {
    if (error.graphQLErrors.length > 0) {
      error.graphQLErrors.forEach((graphQLError) => {
        if (graphQLError.extensions) {
          Object.keys(graphQLError?.extensions.fields).forEach((field) => {
            const error = graphQLError?.extensions?.fields[field];
            Object.keys(error).forEach((errKey) => {
              switch (errKey) {
                case 'IsSlugUnique':
                  message.error(t(`errors.IsSlugUnique`));
                  break;
                default:
                  message.error(t(`error:default`));
              }
            });
          });
        }
      });
    }
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <CompanyCreateForm
          onSuccess={handleSuccess}
          onFailure={handleFailure}
        />
      </div>
    </div>
  );
};

export type CompanyCreateViewProps = {
  className?: string;
};
