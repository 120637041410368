import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { ProductIdDiscountsView } from '../ProductIdDiscountsView/ProductIdDiscountsView';
import { ProductIdTranslationsView } from '../ProductIdTranslationsView/ProductIdTranslationsView';
import { ProductIdUpdateView } from '../ProductIdUpdateView/ProductIdUpdateView';
import { ProductIdVariantsView } from '../ProductIdVariantsView/ProductIdVariantsView';

import { ProductHeader } from '@organisms/ProductHeader/ProductHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ProductIdView.module.css';

import { useProductIdViewQuery } from './ProductIdView.generated';

export const ProductIdView: React.FC<ProductIdViewProps> = (props) => {
  const { className, match } = props;
  const {
    params: { productId },
  } = match;
  const { t } = useTranslation('products');

  const query = useProductIdViewQuery({
    variables: {
      productId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const product = query?.data?.product;

  if (query.loading && !product) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!product) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <ProductHeader product={product} />
      <TabsRouter replace match={match} className={styles.router}>
        <TabsRouter.Route
          label={t('tabs.translations')}
          exact
          path={`${match.url}`}
        >
          <ProductIdTranslationsView productId={productId} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/update`}
          exact
          label={t('tabs.update')}
        >
          <ProductIdUpdateView productId={productId} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/discounts`}
          exact
          label={t('tabs.discounts')}
        >
          <ProductIdDiscountsView productId={productId} />
        </TabsRouter.Route>

        <TabsRouter.Route
          label={t('tabs.variants')}
          exact
          path={`${match.url}/variants`}
        >
          <ProductIdVariantsView productId={productId} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type ProductIdViewProps = RouteComponentProps<{
  productId: string;
}> & {
  className?: string;
};
