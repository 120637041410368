import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Typography } from 'antd';

import { supportedBrowsers } from '@utils/supportedBrowsers';

export const BrowserSupport: React.FC = memo(() => {
  const { t } = useTranslation();
  const isBrowserSupported = supportedBrowsers.test(navigator.userAgent);

  useEffect(() => {
    if (!isBrowserSupported) {
      Modal.warning({
        title: t('browserSupport.title'),
        content: (
          <div>
            <Typography.Paragraph>
              {t('browserSupport.description')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t('browserSupport.download')}
            </Typography.Paragraph>
            <ul>
              <li>
                <Typography.Link href="https://www.google.com/chrome/">
                  Google Chrome
                </Typography.Link>
              </li>
              <li>
                <Typography.Link href="https://www.mozilla.org/firefox/new/">
                  Mozilla Firefox
                </Typography.Link>
              </li>
            </ul>
          </div>
        ),
        okText: t('browserSupport.close'),
      });
    }
  }, []);

  return null;
});
