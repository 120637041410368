import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';

import { BooleanTag } from '@components/atoms/BooleanTag/BooleanTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { ProductVariantInventoryQuantityTag } from '@components/atoms/ProductVariantInventoryQuantityTag/ProductVariantInventoryQuantityTag';
import { ProductVariantPriceTag } from '@components/atoms/ProductVariantPriceTag/ProductVariantPriceTag';
import { Typography } from '@components/atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './ProductVariantHeader.module.css';

import { ProductVariantHeaderFragment } from './ProductVariantHeader.generated';

export const ProductVariantHeader: React.FC<ProductVariantHeaderProps> = (
  props,
) => {
  const { className, productVariant } = props;
  const { t } = useTranslation('productVariants');

  const taxRate = productVariant.product.taxRatePagination?.nodes?.[0];

  return (
    <PageHeader showNav className={className}>
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {productVariant.SKU}
      </Typography.Title>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('fields.isActivated')}>
          <BooleanTag value={productVariant.isActivated} />
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.product')}>
          <I18nLink to={`/products/list/${productVariant.product.id}/variants`}>
            {productVariant.product.name}
          </I18nLink>
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.priceWithTaxes')}>
          <ProductVariantPriceTag productVariant={productVariant} />
          {taxRate && `${taxRate.displayName} ${taxRate.percent}%`}
        </Descriptions.Item>

        <Descriptions.Item label={t('fields.inventoryQuantity')}>
          <ProductVariantInventoryQuantityTag productVariant={productVariant} />
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export type ProductVariantHeaderProps = {
  className?: string;
  productVariant: ProductVariantHeaderFragment;
};
