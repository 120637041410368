import { useTranslation } from 'react-i18next';
import { QueryResult } from '@apollo/client';
import { Spin, Table, TableProps } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  CompanyMembershipRole,
  CompanyMembershipsArgsFilter,
  CompanyMembershipsArgsSorter,
  Exact,
  Maybe,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { UserIntegrationProfileTag } from '@components/atoms/UserIntegrationProfileTag/UserIntegrationProfileTag';
import { CompanyMembershipRoleForm } from '@components/forms/CompanyMembershipRoleForm/CompanyMembershipRoleForm';
import { TableRendererAvatar } from '@components/molecules/TableRendererAvatar/TableRendererAvatar';
import {
  TableRendererDateTime,
  TableRendererDateTimeFactory,
} from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { UserIdCompaniesViewQuery } from '@components/views/Users/UserIdCompaniesView/UserIdCompaniesView.generated';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  UserCompanyMembershipsTableRecordFragment,
  UserIntegrationProfileFragment,
  useUserIdUserIntegrationProfilesLazyQuery,
} from './UserCompanyMembershipsTable.generated';

type RecordType = UserCompanyMembershipsTableRecordFragment;

type Query = QueryResult<
  UserIdCompaniesViewQuery,
  Exact<{
    take: number;
    skip: number;
    sorter?: Maybe<CompanyMembershipsArgsSorter>;
    filter?: Maybe<CompanyMembershipsArgsFilter>;
  }>
>;

type Column =
  | 'name'
  | 'displayName'
  | 'email'
  | 'logo'
  | 'role'
  | 'joinedAt'
  | 'leftAt';

export function UserCompanyMembershipsTable(
  props: UserCompanyMembershipsTableProps,
) {
  const { query } = props;

  if (query.loading && !query.data) {
    return <Spin />;
  } else {
    return <CompanyMembershipsTableInner {...props} />;
  }
}

function CompanyMembershipsTableInner(
  props: UserCompanyMembershipsTableProps & {
    query: Query;
  },
) {
  const { userId, className, query, data: propData, ...rest } = props;

  const { t } = useTranslation('users');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('companyMembershipTable.headers.name'),
      sorter: true,
      dataIndex: ['company', 'name'],
      render: (name, node) => (
        <I18nLink to={`/companies/list/${node.company.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, `name`, 'search'),
    },
    {
      key: 'displayName',
      title: t('companyMembershipTable.headers.displayName'),
      sorter: true,
      dataIndex: ['company', 'displayName'],
      ...generateColumnFilter(query, `displayName`, 'search'),
    },
    {
      key: 'email',
      title: t('companyMembershipTable.headers.email'),
      sorter: true,
      dataIndex: ['company', 'email'],
      ...generateColumnFilter(query, `email`, 'search'),
    },
    {
      key: 'logo',
      title: t('companyMembershipTable.headers.logo'),
      dataIndex: ['company', 'logo'],
      width: 64,
      render: (logo, node) => (
        <TableRendererAvatar picture={logo} placeholder={node.company.name} />
      ),
    },
    {
      key: 'role',
      title: t('companyMembershipTable.headers.role'),
      dataIndex: ['id'],
      render: (companyMembershipId, node) => (
        <CompanyMembershipRoleForm
          companyMembershipId={companyMembershipId}
          role={node.role}
        />
      ),
      ...generateColumnFilter(query, 'role', 'enum', [
        {
          value: CompanyMembershipRole.Admin,
          label: t('companies:companyMembershipRoleTag.ADMIN'),
        },
        {
          value: CompanyMembershipRole.Member,
          label: t('companies:companyMembershipRoleTag.USER'),
        },
      ]),
    },
    {
      key: 'joinedAt',
      title: t('companyMembershipTable.headers.joinedAt'),
      dataIndex: ['joinedAt'],
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `joinedAt`, 'date'),
    },
    {
      key: 'leftAt',
      title: t('companyMembershipTable.headers.leftAt'),
      dataIndex: ['leftAt'],
      render: TableRendererDateTimeFactory(),
      ...generateColumnFilter(query, `leftAt`, 'date'),
    },
  ];

  const [userIntegrationProfileQueryFetch, userIntegrationProfileQuery] =
    useUserIdUserIntegrationProfilesLazyQuery();

  const onExpand = (expanded: boolean, record: RecordType) => {
    if (expanded) {
      userIntegrationProfileQueryFetch({
        variables: {
          userId,
          companyId: record.company.id,
        },
      });
    }
  };

  const expandedRowRender = (record: RecordType) => {
    const columns: TableProps<UserIntegrationProfileFragment>['columns'] = [
      { title: 'Provider Id', dataIndex: 'providerId', key: 'providerId' },
      {
        title: 'UpdateAt',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt: Date) => (
          <TableRendererDateTime value={updatedAt} />
        ),
      },
      {
        title: 'Provider',
        key: 'provider',
        render: (_, record) => (
          <UserIntegrationProfileTag userIntegrationProfile={record} />
        ),
      },
    ];

    return (
      <Table
        key={`user-integration-profiles-${record.company.id}`}
        rowKey={`providerId`}
        loading={
          userIntegrationProfileQuery.loading &&
          !userIntegrationProfileQuery.data
        }
        columns={columns}
        dataSource={
          userIntegrationProfileQuery.data?.user?.userIntegrationProfiles
        }
        pagination={false}
      />
    );
  };

  return (
    <div className={className}>
      <TablePagination
        id="UserCompanyMembershipsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.membershipsPagination}
        expandable={{ onExpand, expandedRowRender }}
        {...rest}
      />
    </div>
  );
}

export type UserCompanyMembershipsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  query: Query;
  userId: string;
};
