import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import classNames from 'classnames';
import VirtualList from 'rc-virtual-list';

import { AuditTrailMessageFormatter } from '@components/atoms/AuditTrailMessageFormatter/AuditTrailMessageFormatter';

import styles from './AuditTrailList.module.css';

import { AuditTrailListFragment } from './AuditTrailList.generated';

export const AuditTrailList: React.FC<AuditTrailListProps> = (props) => {
  const { className, auditTrailPagination } = props;
  const { t } = useTranslation('auditTrail');

  return (
    <div className={classNames(className, styles.root)}>
      <List
        size="small"
        bordered
        header={<div className={styles.listHeader}>{t('header')}</div>}
      >
        <VirtualList
          data={auditTrailPagination.nodes}
          height={194}
          itemHeight={38}
          itemKey="auditTrail"
        >
          {(item) => <AuditTrailMessageFormatter auditTrail={item} />}
        </VirtualList>
      </List>
    </div>
  );
};

export type AuditTrailListProps = {
  className?: string;
  auditTrailPagination: AuditTrailListFragment;
};
