import { Avatar, Dropdown, Space, Typography } from 'antd';
import classnames from 'classnames';

import { AppHeaderDropdownMenu } from '../AppHeaderDropdownMenu/AppHeaderDropdownMenu';

import styles from './AppHeaderProfile.module.css';

import { AppHeaderProfileFragment } from './AppHeaderProfile.generated';

const { Text } = Typography;

export const AppHeaderProfile: React.FC<AppHeaderProfileProps> = (props) => {
  const { className, user } = props;

  return (
    <Dropdown overlay={<AppHeaderDropdownMenu />} trigger={['click']}>
      <Space
        size="middle"
        direction="horizontal"
        className={classnames(className, styles.root)}
      >
        <Text strong>
          {user.firstName} {user.lastName}
        </Text>
        <Avatar src={user.avatar?.file?.url}>
          {user.firstName?.[0]}
          {user.lastName?.[0]}
        </Avatar>
      </Space>
    </Dropdown>
  );
};

export type AppHeaderProfileProps = {
  user: AppHeaderProfileFragment;
  className?: string;
};
