import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './GameAccessForm.module.css';

import {
  GameAccessFormFragment,
  GameCompanyAccessItemFragment,
  useGameCompanyBlacklistAddMutation,
  useGameCompanyBlacklistRemoveMutation,
  useGameCompanyWhitelistAddMutation,
  useGameCompanyWhitelistRemoveMutation,
} from './GameAccessForm.generated';

export type GameAccessFormValues = {
  includedCompanies: Array<string>;
  excludedCompanies: Array<string>;
};

function getCompanyIds(companies: GameCompanyAccessItemFragment[]) {
  return companies.map((d) => d.id);
}

function getNewCompanyIds(dataFetched: string[], inputValues: string[]) {
  return inputValues.filter(
    (newValue) =>
      !dataFetched.find((previousValue) => previousValue === newValue),
  );
}

function getRemovedCompanyIds(dataFetched: string[], inputValues: string[]) {
  return dataFetched.filter(
    (previousValue) =>
      !inputValues.find((newValue) => previousValue === newValue),
  );
}

export function GameAccessForm(props: GameAccessFormProps) {
  const { className, game, ...rest } = props;
  const { t } = useTranslation('games');

  const validationSchema = Yup.object({
    includedCompanies: Yup.array(),
    excludedCompanies: Yup.array(),
  }).required();

  const form = useForm<GameAccessFormValues>({
    validationSchema,
    defaultValues: {
      includedCompanies: getCompanyIds(game.includedCompaniesPagination.nodes),
      excludedCompanies: getCompanyIds(game.excludedCompaniesPagination.nodes),
    },
  });
  const [gameCompanyWhitelistAdd] = useGameCompanyWhitelistAddMutation();
  const [gameCompanyWhitelistRemove] = useGameCompanyWhitelistRemoveMutation();
  const [gameCompanyBlacklistAdd] = useGameCompanyBlacklistAddMutation();
  const [gameCompanyBlacklistRemove] = useGameCompanyBlacklistRemoveMutation();

  const handleSubmit: FormProps<GameAccessFormValues>['onValid'] = async (
    values,
  ) => {
    const updatePromises = [];

    const includedAdded = getNewCompanyIds(
      getCompanyIds(game.includedCompaniesPagination.nodes),
      values.includedCompanies,
    );
    if (includedAdded.length) {
      updatePromises.push(
        gameCompanyWhitelistAdd({
          variables: {
            gameId: game.id,
            input: {
              companyIds: includedAdded,
            },
          },
        }),
      );
    }

    const includedRemoved = getRemovedCompanyIds(
      getCompanyIds(game.includedCompaniesPagination.nodes),
      values.includedCompanies,
    );
    if (includedRemoved.length) {
      updatePromises.push(
        gameCompanyWhitelistRemove({
          variables: {
            gameId: game.id,
            input: {
              companyIds: includedRemoved,
            },
          },
        }),
      );
    }

    const excludedAdded = getNewCompanyIds(
      getCompanyIds(game.excludedCompaniesPagination.nodes),
      values.excludedCompanies,
    );
    if (excludedAdded.length) {
      updatePromises.push(
        gameCompanyBlacklistAdd({
          variables: {
            gameId: game.id,
            input: {
              companyIds: excludedAdded,
            },
          },
        }),
      );
    }

    const excludedRemove = getRemovedCompanyIds(
      getCompanyIds(game.excludedCompaniesPagination.nodes),
      values.excludedCompanies,
    );
    if (excludedRemove.length) {
      updatePromises.push(
        gameCompanyBlacklistRemove({
          variables: {
            gameId: game.id,
            input: {
              companyIds: excludedRemove,
            },
          },
        }),
      );
    }
    if (updatePromises.length) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error weird error
        await Promise.all(updatePromises);
        message.success(t('GameAccessForm.successMessage'));
      } catch (err) {
        handleMutationErrors(err, {});
      }
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="GameAccessForm"
        className={styles.form}
        form={form}
        onValid={handleSubmit}
        {...rest}
      >
        <FormItem
          className={styles.includedCompanies}
          required
          label={t('fields.includedCompanies')}
          name="includedCompanies"
        >
          <FormSelectCompany multiple name="includedCompanies" />
        </FormItem>
        <FormItem
          className={styles.excludedCompanies}
          required
          label={t('fields.excludedCompanies')}
          name="excludedCompanies"
        >
          <FormSelectCompany multiple name="excludedCompanies" />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('GameAccessForm.save')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type GameAccessFormProps = {
  className?: string;
  game: GameAccessFormFragment;
};
