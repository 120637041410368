import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './OfferTypeTag.module.css';

import { OfferTypeTagFragment } from './OfferTypeTag.generated';

export const OfferTypeTag: React.FC<OfferTypeTagProps> = (props) => {
  const { className, offer } = props;
  const { t } = useTranslation('offers');

  const colors: Record<typeof offer.__typename, string> = {
    OfferCouponClaimable: 'yellow',
    OfferCouponPublic: 'yellow',
    OfferGiftCard: 'yellow',
    OfferTicket: 'yellow',
    OfferUrl: 'yellow',
    OfferBase: 'yellow',
  };

  const labels: Record<typeof offer.__typename, string> = {
    OfferCouponClaimable: t('type.couponClaimable'),
    OfferCouponPublic: t('type.couponPublic'),
    OfferGiftCard: t('type.giftCard'),
    OfferTicket: t('type.ticket'),
    OfferUrl: t('type.url'),
    OfferBase: t('type.base', 'Interne'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[offer.__typename]}
    >
      {labels[offer.__typename]}
    </Tag>
  );
};

export type OfferTypeTagProps = {
  className?: string;
  offer: OfferTypeTagFragment;
};
