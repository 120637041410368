import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Tooltip, Typography } from 'antd';

import { Subvention } from '@graphql/generated/types';

import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';

import {
  OrderRefundCreateOrderItemSelectorOrderFragment,
  OrderRefundCreateOrderItemSelectorOrderItemFragment,
} from './OrderRefundCreateOrderItemSelector.generated';

type OrderRefundCreateOrderItemSelectorProps = {
  order: OrderRefundCreateOrderItemSelectorOrderFragment;
  orderItems: OrderRefundCreateOrderItemSelectorOrderItemFragment[];
  onChange?: (selected: string[]) => void;
};

export const OrderRefundCreateOrderItemSelector = (
  props: OrderRefundCreateOrderItemSelectorProps,
) => {
  const { order, orderItems, onChange } = props;
  const { t } = useTranslation('orderItems');

  const handleChange = (rowKeys: Key[]) => {
    if (onChange) {
      onChange(rowKeys as string[]);
    }
  };

  return (
    <div>
      <Typography.Title level={4}>Éléments à rembourser :</Typography.Title>
      <Table
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
        }}
        columns={[
          { title: 'Quantité', dataIndex: 'quantity', width: 100 },
          {
            title: 'Produit',
            dataIndex: ['productVariant', 'product', 'name'],
          },
          {
            title: 'Prix unitaire TTC',
            width: 150,
            render: (_, node) => (
              <TableRendererCurrency
                value={{
                  amount: node.unitPriceAfterTaxes,
                  currency: order.currency,
                }}
              />
            ),
          },
          {
            title: 'Prix total TTC',
            width: 150,
            render: (_, node) => (
              <TableRendererCurrency
                value={{
                  amount: node.totalPriceAfterTaxes,
                  currency: order.currency,
                }}
              />
            ),
          },
          {
            key: 'fundSubventionsUsed',
            title: t('table.headers.fundSubventionsUsed'),
            dataIndex: ['subventionPagination', 'nodes'],
            render: (subventions: Subvention[]) =>
              subventions.map((subvention) => (
                <Tag key={subvention.id}>{subvention.name}</Tag>
              )),
          },
          {
            key: 'discountSubvention',
            title: t('table.headers.discountSubvention'),
            render: (_, node) => {
              if (node.discountSubvention)
                return (
                  <Tooltip title={node.discountSubvention.name}>
                    <span>
                      <TableRendererCurrency
                        value={{
                          amount: node.totalDiscountSubventionAmount * -1,
                          currency: node.currency,
                        }}
                      />
                    </span>
                  </Tooltip>
                );
            },
          },
        ]}
        dataSource={orderItems}
        rowKey="id"
      />
    </div>
  );
};
