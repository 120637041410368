import classnames from 'classnames';

import { Company } from '@graphql/generated/types';

import { PublicationsTable } from '@components/tables/PublicationsTable/PublicationsTable';

export const CompanyIdPublicationsView: React.FC<CompanyIdPublicationsViewProps> =
  (props) => {
    const { className, companyId } = props;

    return (
      <div className={classnames(className)}>
        <PublicationsTable
          activeColumns={['title', 'createdAt', 'updatedAt']}
          filter={{ company: { id: { is: companyId } } }}
        />
      </div>
    );
  };

export type CompanyIdPublicationsViewProps = {
  companyId: Company['id'];
  className?: string;
};
