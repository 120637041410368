import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { ProductArticlesTable } from '@components/tables/ProductArticlesTable/ProductArticlesTable';

import styles from './ProductArticlesView.module.css';

export const ProductArticlesView: React.FC<ProductArticlesViewProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation('productArticles');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('productsView.title')}</Typography.Title>
      <ProductArticlesTable
        activeColumns={['id', 'productVariant', 'expiresAt', 'createdAt']}
      />
    </div>
  );
};

export type ProductArticlesViewProps = {
  className?: string;
};
