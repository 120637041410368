import { Link, useParams } from 'react-router-dom';
import { UserIntegrationConnectorSynchronisationsTable } from '@features/user-integration/components/UserIntegrationConnectorSynchronisationsTable/UserIntegrationConnectorSynchronisationsTable';
import { Button } from 'antd';

interface CompanyFeatureConfigUserIntegrationConnectorIdViewProps {
  companyId: string;
}

export const CompanyFeatureConfigUserIntegrationConnectorIdView = (
  props: CompanyFeatureConfigUserIntegrationConnectorIdViewProps,
) => {
  const { companyId } = props;
  const { connectorId } = useParams<{ connectorId: string }>();

  return (
    <div>
      <UserIntegrationConnectorSynchronisationsTable
        title={() => (
          <Link to="..">
            <Button>Retour</Button>
          </Link>
        )}
        companyId={companyId}
        connectorId={connectorId}
      />
    </div>
  );
};
