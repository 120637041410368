import { ApolloLink } from '@apollo/client';
import * as Sentry from '@sentry/react';

export const sentryLink = new ApolloLink((operation, forward) => {
  const observable = forward(operation);

  const transaction = Sentry.getCurrentHub().startTransaction({
    op: 'Graphql Request',
    name: operation.operationName,
  });

  operation.setContext({
    headers: {
      ['sentry-trace']: transaction.toTraceparent(),
    },
  });

  return observable.map((result) => {
    transaction.finish();

    return result;
  });
});
