import { useTranslation } from 'react-i18next';
import { CommentOutlined } from '@ant-design/icons';
import { Affix, Button, Spin } from 'antd';

import { AdminCommentTargetType } from '@graphql/generated/types';

import { useAdminCommentsDrawer } from '@hooks/adminCommentsDrawer/adminCommentsDrawer.hooks';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './AdminCommentsDrawerOpenButton.module.css';

import { useAdminCommentsDrawerOpenButtonQuery } from './AdminCommentsDrawerOpenButton.generated';

type AdminCommentsDrawerOpenButtonProps = {
  targetType: AdminCommentTargetType;
  targetId: string;
  tableMode?: boolean;
};

const AdminCommentsDrawerOpenButton = (
  props: AdminCommentsDrawerOpenButtonProps,
) => {
  const { targetId, targetType, tableMode } = props;

  const { t } = useTranslation('adminComments');
  const { open } = useAdminCommentsDrawer();
  const { loading, data } = useAdminCommentsDrawerOpenButtonQuery({
    variables: {
      filter: {
        targetType: { is: targetType },
        targetId: { is: targetId },
      },
      take: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const comments = data?.adminCommentPagination;

  if (loading && !comments) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!comments) {
    return <NotFoundView />;
  }

  const button = (
    <Button
      type={tableMode ? 'text' : 'primary'}
      shape={tableMode ? 'default' : 'round'}
      size={tableMode ? 'small' : 'large'}
      icon={<CommentOutlined />}
      onClick={() => open(targetType, targetId)}
    >
      {tableMode
        ? ' ' + comments.totalCount
        : t('comments', { count: comments.totalCount })}
    </Button>
  );

  if (tableMode) {
    return button;
  }

  return <Affix className={styles.affix}>{button}</Affix>;
};

export default AdminCommentsDrawerOpenButton;
