import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import { QueryResult } from '@apollo/client';
import { Button, Card } from 'antd';
import classNames from 'classnames';
import { endOfDay, formatDistance, isAfter } from 'date-fns';
import { fr as dateFr } from 'date-fns/locale';

import {
  SubventionIdViewQuery,
  SubventionIdViewQueryVariables,
} from '../../SubventionIdView.generated';

import { SubventionState } from '@graphql/generated/types';

import SubventionDatesUpdateModal from '@components/modals/SubventionDatesUpdateModal/SubventionDatesUpdateModal';

import styles from './SubventionTimelineCard.module.css';

import { SubventionTimelineCardFragment } from './SubventionTimelineCard.generated';

type SubventionTimelineCardProps = {
  className?: string;
  subvention: SubventionTimelineCardFragment;
  query: QueryResult<SubventionIdViewQuery, SubventionIdViewQueryVariables>;
};

export function SubventionTimelineCard(props: SubventionTimelineCardProps) {
  const { className, subvention, query } = props;
  const { t } = useTranslation('common');

  if (!subvention.startsAt || !subvention.endsAt) {
    return (
      <Card
        className={classNames(styles.root, className)}
        size="small"
        title="Temps restant"
      >
        <div>En attente de programmation</div>
      </Card>
    );
  }

  const start = subvention.startsAt;
  const current = new Date();
  const end = endOfDay(subvention.endsAt);

  const isSubventionCompleted = subvention.state === SubventionState.Completed;

  const progress = !isSubventionCompleted
    ? (current.getTime() - start.getTime()) / (end.getTime() - start.getTime())
    : 1;

  const [isSubventionDatesModalOpen, setIsSubventionDatesModalOpen] =
    useState(false);

  const showModal = () => {
    setIsSubventionDatesModalOpen(true);
  };

  return (
    <>
      <Card
        size="small"
        title="Temps restant"
        className={classNames(styles.root, className)}
        extra={
          <Button size="small" icon={<EditOutlined />} onClick={showModal}>
            {subvention.state === SubventionState.Completed
              ? 'Prolonger'
              : 'Modifier'}
          </Button>
        }
      >
        <div className={styles.content}>
          {isAfter(current, end) || isSubventionCompleted
            ? 'Terminée'
            : formatDistance(current, end, {
                locale: dateFr,
              })}
        </div>
        <div className={styles.footer}>
          <div className={styles.progress}>
            <div className={styles.progressValues}>
              <div className={styles.progressValueCurrent}>
                {t('formats.shortDate', { date: start })}
              </div>
              <div className={styles.progressValueEnd}>
                {t('formats.shortDate', { date: end })}
              </div>
              <div className={styles.progressValueSeparator}>
                <RightOutlined />
              </div>
            </div>
            <div className={styles.progressBar}>
              <div
                style={{ width: progress * 100 + '%' }}
                className={styles.progressFill}
              />
            </div>
          </div>
        </div>
      </Card>

      <SubventionDatesUpdateModal
        isOpen={isSubventionDatesModalOpen}
        onClose={() => setIsSubventionDatesModalOpen(false)}
        title="Editer les dates de la subvention"
        subvention={subvention}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
      />
    </>
  );
}
