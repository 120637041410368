import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

// import countries from 'country-region-data';
import { FormSelect } from '../FormSelect/FormSelect';

import { CompanyCustomFieldType } from '@graphql/generated/types';

export const FormSelectCompanyCustomFieldType: React.FC<FormSelectCompanyCustomFieldTypeProps> =
  (props) => {
    const { className, name } = props;
    const { t } = useTranslation('companies');

    const options: { label: string; value: CompanyCustomFieldType }[] = [
      {
        value: CompanyCustomFieldType.String,
        label: t('custom-fields.type.string'),
      },
      {
        value: CompanyCustomFieldType.Integer,
        label: t('custom-fields.type.integer'),
      },
    ];

    return (
      <FormSelect
        name={name}
        className={classnames(className)}
        showSearch
        optionFilterProp="label"
        options={options}
      />
    );
  };

export type FormSelectCompanyCustomFieldTypeProps = {
  name: string;
  className?: string;
};
