import { RouteComponentProps } from 'react-router';
import { Layout, Spin } from 'antd';

import { BrandIdCampaignsView } from '../BrandIdCampaignsView/BrandIdCampaignsView';
import { BrandIdOffersView } from '../BrandIdOffersView/BrandIdOffersView';
import { BrandIdProductsView } from '../BrandIdProductsView/BrandIdProductsView';
import { BrandIdStoresView } from '../BrandIdStoresView/BrandIdStoresView';
import { BrandIdTranslationsView } from '../BrandIdTranslationsView/BrandIdTranslationsView';

import { AdminCommentTargetType } from '@graphql/generated/types';

import AdminCommentsDrawerOpenButton from '@components/organisms/AdminCommentsDrawerOpenButton/AdminCommentsDrawerOpenButton';
import { CatalogIncludedView } from '@components/views/CatalogIncludedView/CatalogIncludedView';
import { BrandHeader } from '@organisms/BrandHeader/BrandHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './BrandIdView.module.css';

import { useBrandIdViewQuery } from './BrandIdView.generated';

export const BrandIdView: React.FC<BrandIdViewProps> = (props) => {
  const { match } = props;
  const {
    params: { brandId },
  } = match;

  const brandQuery = useBrandIdViewQuery({
    variables: { brandId },
    notifyOnNetworkStatusChange: true,
  });

  const brand = brandQuery?.data?.brand;

  if (brandQuery.loading && !brand) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!brand) {
    return <NotFoundView />;
  }

  return (
    <Layout>
      <BrandHeader className={styles.header} brand={brand} showNav />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label="Description/Configuration"
        >
          <BrandIdTranslationsView brandId={brandId} />
        </TabsRouter.Route>
        <TabsRouter.Route
          exact
          path={`${match.url}/campaigns`}
          label="Campagnes"
        >
          <BrandIdCampaignsView brandId={brandId} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/offers`} label="Offres">
          <BrandIdOffersView brandId={brandId} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/products`} label="Produits">
          <BrandIdProductsView brandId={brandId} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/stores`} label="Stores">
          <BrandIdStoresView brandId={brandId} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/include`} label="Exclusivité">
          <CatalogIncludedView entityId={brandId} entityType={'brand'} />
        </TabsRouter.Route>
      </TabsRouter>

      <AdminCommentsDrawerOpenButton
        targetId={brandId}
        targetType={AdminCommentTargetType.Brand}
      />
    </Layout>
  );
};

export type BrandIdViewProps = RouteComponentProps<{
  brandId: string;
}>;
