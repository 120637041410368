import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { UserStatus } from '@graphql/generated/types';

import styles from './UserStatusTag.module.css';

import { UserStatusTagFragment } from './UserStatusTag.generated';

export const UserStatusTag: React.FC<UserStatusTagProps> = (props) => {
  const { className, user } = props;
  const { t } = useTranslation('users');

  const colors: Record<UserStatus, string> = {
    CREATED: 'default',
    INVITED: 'yellow',
    ACTIVATED: 'green',
    ARCHIVED: 'grey',
    BANNED: 'red',
  };

  const labels: Record<UserStatus, string> = {
    CREATED: t('userStatusTag.CREATED'),
    ACTIVATED: t('userStatusTag.ACTIVATED'),
    ARCHIVED: t('userStatusTag.ARCHIVED'),
    BANNED: t('userStatusTag.BANNED'),
    INVITED: t('userStatusTag.INVITED'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[user.status]}
    >
      {labels[user.status]}
    </Tag>
  );
};

export type UserStatusTagProps = {
  className?: string;
  user: UserStatusTagFragment;
};
