import { RouteComponentProps } from 'react-router-dom';
import { Card, Spin } from 'antd';

import { Picture } from '@components/molecules/Picture/Picture';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './PictureStockIdView.module.css';

import { usePictureStockIdViewQuery } from './PictureStockIdView.generated';

export type PictureStockIdViewProps = RouteComponentProps<{
  pictureStockId: string;
}>;

export const PictureStockIdView: React.FC<PictureStockIdViewProps> = (
  props,
) => {
  const { match } = props;
  const {
    params: { pictureStockId },
  } = match;

  const pictureStockQuery = usePictureStockIdViewQuery({
    variables: { pictureStockId },
  });

  if (pictureStockQuery.loading && !pictureStockQuery.data?.pictureStock) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!pictureStockQuery.data?.pictureStock) {
    return <NotFoundView />;
  }

  const pictureStock = pictureStockQuery?.data?.pictureStock;

  return (
    <Card
      className={styles.pictureStockCard}
      style={{ width: 600 }}
      cover={
        <Picture width={600} height={300} picture={pictureStock.picture} />
      }
    >
      identifiant: {pictureStock.id}
    </Card>
  );
};
