import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';

import { OrderRefundsTable } from '@components/tables/OrderRefundsTable/OrderRefundsTable';

export const OrderRefundsView = () => {
  const { t } = useTranslation('orders');

  return (
    <OrderRefundsTable
      title={() => <Title level={5}>{t('orderRefundsTable.title')}</Title>}
      activeColumns={[
        'id',
        'createdAt',
        'updatedAt',
        'order',
        'status',
        'stripeAmount',
        'stripeStatus',
      ]}
    />
  );
};
