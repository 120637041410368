import classnames from 'classnames';

import {
  CouponClaimableUpdateForm,
  CouponClaimableUpdateFormProps,
} from '@components/forms/CouponClaimableUpdateForm/CouponClaimableUpdateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './CouponClaimableUpdateModal.module.css';

export type CouponClaimableUpdateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  couponClaimable: CouponClaimableUpdateFormProps['couponClaimable'];
};

export function CouponClaimableUpdateModal(
  props: CouponClaimableUpdateModalProps,
) {
  const { className, onClose, onSuccess, isOpen, couponClaimable } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="Ajouter une réduction"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="CouponClaimableUpdateForm"
    >
      <CouponClaimableUpdateForm
        onSuccess={handleSuccess}
        couponClaimable={couponClaimable}
        onSubmit={handleSuccess}
      />
    </Modal>
  );
}
