import { InvitationsTable } from '@components/tables/InvitationsTable/InvitationsTable';

import { useUserInvitationsViewQuery } from './UserInvitationsView.generated';

type UserInvitationsViewProps = {
  userId: string;
};

export const UserInvitationsView = (props: UserInvitationsViewProps) => {
  const { userId } = props;

  const query = useUserInvitationsViewQuery({
    variables: {
      userId,
      skip: 0,
      take: 20,
    },
  });

  return (
    <div>
      <InvitationsTable
        activeColumns={[
          'id',
          'claimedAt',
          'revokedAt',
          'createdAt',
          'expiredAt',
          'sendAt',
          'sender',
          'status',
        ]}
        query={query}
        user={query.data?.user ?? undefined}
        data={query.data?.user?.invitationsReceived || null}
      />
    </div>
  );
};
