import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectTaxRate.module.css';

import {
  FormSelectTaxRateQueryVariables,
  useFormSelectTaxRateQuery,
} from './FormSelectTaxRate.generated';

export const FormSelectTaxRate: React.FC<FormSelectTaxRateProps> = (props) => {
  const { className, filter, mode, ...rest } = props;

  const { data, loading, variables, refetch } = useFormSelectTaxRateQuery({
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = debounce((value: string) => {
    refetch({
      ...(variables || {}),
      filter: {
        ...(variables?.filter || {}),
        displayName: { contains: value },
      },
    });
  }, 300);

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      options={data?.taxRatePagination.nodes.map((node) => ({
        value: node.id,
        label: node.displayName,
      }))}
      {...rest}
    />
  );
};

export type FormSelectTaxRateProps = FormSelectProps & {
  filter?: FormSelectTaxRateQueryVariables['filter'];
};
