import { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { SurveyCreateModal } from '@features/surveys/SurveyCreateModal/SurveyCreateModal';
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';

import { SurveyProvider } from '@graphql/generated/types';

import {
  CompanyFeatureConfigSurveyViewDocument,
  useCompanyFeatureConfigSurveyFeatureDisableMutation,
  useCompanyFeatureConfigSurveyFeatureEnableMutation,
  useCompanyFeatureConfigSurveyViewQuery,
  useSurveyDeleteMutation,
} from './CompanyFeatureConfigSurveyView.generated';

interface CompanyFeatureConfigSurveyViewProps {
  companyId: string;
}

export const CompanyFeatureConfigSurveyView = (
  props: CompanyFeatureConfigSurveyViewProps,
) => {
  const { companyId } = props;
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteSurvey, setDeleteSurvey] = useState<string | null>(null);

  const [featureEnableMutation, featureEnableState] =
    useCompanyFeatureConfigSurveyFeatureEnableMutation({
      variables: { companyId },
    });
  const [featureDisableMutation, featureDisableState] =
    useCompanyFeatureConfigSurveyFeatureDisableMutation({
      variables: { companyId },
    });

  const { data, loading, refetch } = useCompanyFeatureConfigSurveyViewQuery({
    variables: { companyId },
  });

  const [deleteMutation, deleteMutationState] = useSurveyDeleteMutation({
    refetchQueries: [
      {
        query: CompanyFeatureConfigSurveyViewDocument,
        variables: { companyId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => setDeleteSurvey(null),
  });

  const company = data?.company;
  const feature = company?.featureConfig.survey;
  const surveys = company?.surveys ?? [];

  const handleFeatureToggle = async (checked: boolean) =>
    checked ? featureEnableMutation() : featureDisableMutation();

  const isFeatureLoading =
    featureDisableState.loading || featureEnableState.loading;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Typography>Fonctionnalité</Typography>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={!company || isFeatureLoading}
          checked={feature?.isEnabled}
          onChange={handleFeatureToggle}
          loading={featureDisableState.loading || featureEnableState.loading}
        />
      </Space>
      <Card>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space>
            <Button onClick={() => setCreateModalOpen(true)} type="primary">
              Créer un sondage
            </Button>
          </Space>
          <Table
            rowKey={(record) => record.id}
            loading={loading}
            columns={[
              {
                title: 'ID Fournisseur',
                dataIndex: 'providerId',
                ellipsis: true,
                render: (id) => (
                  <Typography.Text title={id} copyable ellipsis>
                    {id}
                  </Typography.Text>
                ),
              },
              {
                title: 'Fournisseur',
                dataIndex: 'provider',
                sorter: true,
                filters: Object.values(SurveyProvider).map((provider) => ({
                  text: provider,
                  value: provider,
                })),
                onFilter: (val, record) => val === record.provider,
              },
              {
                title: 'Début',
                dataIndex: 'startsAt',
                render: (date) => new Date(date).toLocaleString(),
                sorter: true,
              },
              {
                title: 'Fin',
                dataIndex: 'endsAt',
                render: (date) => new Date(date).toLocaleString(),
                sorter: true,
              },
              {
                title: 'Création',
                dataIndex: 'createdAt',
                render: (date) => new Date(date).toLocaleString(),
                sorter: true,
              },
              {
                title: 'Action',
                dataIndex: 'id',
                render: (id) => (
                  <Popconfirm
                    title="Supprimer ce sondage ?"
                    okType="danger"
                    okText="Supprimer"
                    cancelText="Annuler"
                    open={deleteSurvey === id}
                    onConfirm={() =>
                      deleteMutation({ variables: { surveyId: id } })
                    }
                    okButtonProps={{ loading: deleteMutationState.loading }}
                    onCancel={() => setDeleteSurvey(null)}
                  >
                    <Button danger onClick={() => setDeleteSurvey(id)}>
                      Supprimer
                    </Button>
                  </Popconfirm>
                ),
              },
            ]}
            dataSource={surveys}
          />
        </Space>
      </Card>
      <SurveyCreateModal
        companyId={companyId}
        onCancel={() => setCreateModalOpen(false)}
        onCreate={() => {
          setCreateModalOpen(false);
          refetch();
        }}
        open={createModalOpen}
      />
    </Space>
  );
};
