import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { CategoryIdBrandsView } from '../CategoryIdBrandsView/CategoryIdBrandsView';
import { CategoryIdCampaignsView } from '../CategoryIdCampaignsView/CategoryIdCampaignsView';
import { CategoryIdChildrenView } from '../CategoryIdChildrenView/CategoryIdChildrenView';
import { CategoryIdOffersView } from '../CategoryIdOffersView/CategoryIdOffersView';
import { CategoryIdTranslationsView } from '../CategoryIdTranslationsView/CategoryIdTranslationsView';

import { CategoryHeader } from '@organisms/CategoryHeader/CategoryHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CategoryIdView.module.css';

import { useCategoryIdViewQuery } from './CategoryIdView.generated';

export const CategoryIdView: React.FC<CategoryIdViewProps> = (props) => {
  const { className, match } = props;
  const { t } = useTranslation('categories');
  const {
    params: { categoryId },
  } = match;

  const categoryQuery = useCategoryIdViewQuery({
    variables: { categoryId },
    notifyOnNetworkStatusChange: true,
  });

  const category = categoryQuery?.data?.category;

  if (categoryQuery.loading && !category) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!category) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <CategoryHeader category={category} />
      <TabsRouter match={match}>
        <TabsRouter.Route label={t('nav.translations')} exact path={match.url}>
          <CategoryIdTranslationsView categoryId={categoryId} />
        </TabsRouter.Route>
        {!category.parent && (
          <TabsRouter.Route
            label={t('nav.children', {
              count: category.childrenPagination.totalCount,
            })}
            path={`${match.url}/children`}
          >
            <CategoryIdChildrenView categoryId={category.id} />
          </TabsRouter.Route>
        )}
        <TabsRouter.Route
          label={t('nav.offers', {
            count: category.offersPagination.totalCount,
          })}
          path={`${match.url}/offers`}
        >
          <CategoryIdOffersView categoryId={category.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          label={t('nav.campaigns', {
            count: category.campaignPagination.totalCount,
          })}
          path={`${match.url}/campaigns`}
        >
          <CategoryIdCampaignsView categoryId={category.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          label={t('nav.brands', {
            count: category.brandsPagination.totalCount,
          })}
          path={`${match.url}/brands`}
        >
          <CategoryIdBrandsView categoryId={category.id} />
        </TabsRouter.Route>
        <TabsRouter.Route label={t('nav.icon')} path={`${match.url}/icon`}>
          {t('nav.icon')}
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type CategoryIdViewProps = RouteComponentProps<{
  categoryId: string;
}> & {
  className?: string;
};
