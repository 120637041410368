import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MinusOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import classnames from 'classnames';

import styles from './StoreRemoveOfferButton.module.css';

import {
  OfferForStoreRemoveOfferButtonFragment,
  StoreForStoreRemoveOfferButtonFragment,
  StoreRemoveOfferButtonMutationResult,
  useStoreRemoveOfferButtonMutation,
} from './StoreRemoveOfferButton.generated';

export const StoreRemoveOfferButton: React.FC<StoreRemoveOfferButtonProps> = (
  props,
) => {
  const { className, store, offer, mode, onSuccess: onSucccess } = props;
  const { t } = useTranslation('stores');

  const [isSubmitting, setSubmitting] = useState(false);

  const [mutation] = useStoreRemoveOfferButtonMutation({
    refetchQueries: ['StoreIdView'],
  });

  const handleRemove = async () => {
    if (isSubmitting) return;

    setSubmitting(true);

    try {
      const { data } = await mutation({
        variables: {
          input: {
            offerIds: [offer.id],
            storeIds: [store.id],
          },
        },
      });

      message.success(t('StoreRemoveOfferButton.success'));

      if (onSucccess) {
        onSucccess(data);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
    setSubmitting(false);
  };

  const renderButton = () => {
    switch (mode) {
      case 'icon':
        return <MinusOutlined />;
      case 'text':
        return t('StoreRemoveOfferButton.remove.label');
      case 'iconAndText':
        return (
          <>
            <MinusOutlined /> {t('StoreRemoveOfferButton.remove.label')}
          </>
        );
    }
  };

  return (
    <Popconfirm
      onConfirm={handleRemove}
      title={t('StoreRemoveOfferButton.remove.confirm', {
        offer: offer.name,
        store: store.address.address1,
      })}
    >
      <Button
        className={classnames(className, styles.root)}
        loading={isSubmitting}
        danger
      >
        {renderButton()}
      </Button>
    </Popconfirm>
  );
};

export type StoreRemoveOfferButtonProps = {
  className?: string;
  offer: OfferForStoreRemoveOfferButtonFragment;
  store: StoreForStoreRemoveOfferButtonFragment;
  mode: 'icon' | 'text' | 'iconAndText';
  onSuccess?: (data: StoreRemoveOfferButtonMutationResult['data']) => void;
};
