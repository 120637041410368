import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { Modal } from '@components/organisms/Modal/Modal';
import {
  OffersTable,
  OffersTableProps,
} from '@components/tables/OffersTable/OffersTable';

import styles from './OfferSelectModal.module.css';

export const OfferSelectModal = (props: OfferSelectModalProps) => {
  const { exclude = [], isOpen, onSubmit, onClose, filter } = props;
  const [submitLoading, setLoading] = useState(false);

  const [offerIds, setOfferIds] = useState<React.Key[]>([]);

  const { t } = useTranslation('offers');

  const handleSubmit = async () => {
    if (!onSubmit) return;

    try {
      await onSubmit?.(offerIds.map((id) => id.toString()));
      onClose();
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title=""
      onClose={onClose}
      width="100%"
      footer={
        <Button type="primary" loading={submitLoading} onClick={handleSubmit}>
          Sélectionner
        </Button>
      }
      className={styles.root}
    >
      <OffersTable
        activeColumns={['name', 'brand']}
        selectable={{
          type: 'checkbox',
          onChange: setOfferIds,
          value: offerIds,
          isDisabled: (offer) => exclude.includes(offer.id),
        }}
        title={() => t('offersSelectedCount', { count: offerIds.length })}
        filter={filter}
      />
    </Modal>
  );
};

export type OfferSelectModalProps = {
  onSubmit?: (offersIds: string[]) => void | Promise<void>;
  multiple?: boolean;
  exclude?: string[];
  isOpen: boolean;
  onClose: () => void;
  filter?: OffersTableProps['filter'];
};
