import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { OffersTable } from '@tables/OffersTable/OffersTable';

import styles from '../Redbin.module.css';

import { useRedBinOffersWithoutDiscountQuery } from './OffersWithoutDiscount.generated';

export const OffersWithoutDiscount: React.FC<OffersWithoutDiscountProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinOffersWithoutDiscountQuery({
    variables: { take: 10, skip: 0 },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('offersWithoutDiscount.title')} />
      <OffersTable
        query={query}
        data={query.data?.redBinOffersDiscountOrVoucher}
        activeColumns={[
          'name',
          'type',
          'status',
          'brand',
          'campaign',
          'startsAt',
          'endsAt',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type OffersWithoutDiscountProps = {
  className?: string;
};
