import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { Typography } from '@atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './BrandHeader.module.css';

import {
  BrandHeaderFragment,
  useBrandHeaderUpdateMutation,
} from './BrandHeader.generated';

export const BrandHeader: React.FC<BrandHeaderProps> = (props) => {
  const { className, brand, showNav } = props;
  const { t } = useTranslation('common');

  const [mutation] = useBrandHeaderUpdateMutation();

  const handleUpdate = async (
    property: 'logoId' | 'coverId',
    pictureId: string,
  ) => {
    await mutation({
      variables: {
        brandId: brand.id,
        input: {
          [property]: pictureId,
        },
      },
    });
  };

  return (
    <>
      <PageHeader
        cover={brand.cover}
        logo={brand.logo}
        showNav={showNav}
        className={classnames(className, styles.root)}
        uploadLogoProps={{
          fileType: FileType.BrandLogo,
          onUploadPicture: (pictureId) => handleUpdate('logoId', pictureId),
          title: 'Logo',
          picture: brand.logo,
          info: 'Résolution recommandée 512x512, max 1mo',
        }}
        uploadCoverProps={{
          fileType: FileType.BrandCover,
          onUploadPicture: (pictureId) => handleUpdate('coverId', pictureId),
          title: 'Cover',
          picture: brand.cover,
          info: t('picture.advice', {
            resolution: COVER_RESOLUTION_INFO,
            size: COVER_SIZE_INFO,
          }),
        }}
      >
        <Typography.Title
          level={2}
          ellipsis={{ rows: 2 }}
          className={styles.title}
        >
          {brand.name}
        </Typography.Title>
      </PageHeader>
    </>
  );
};

export type BrandHeaderProps = {
  className?: string;
  brand: BrandHeaderFragment;
  showNav?: boolean;
};
