import { useState } from 'react';
import { Button, message, Table, Tag } from 'antd';

import { TableRendererDate } from '@components/molecules/TableRendererDate/TableRendererDate';
import { AppdragMigrationsSynchronizationsTable } from '@components/tables/AppdragMigrationsSynchronizationsTable/AppdragMigrationsSynchronizationsTable';

import {
  useAppdragCompanyMigrationsViewQuery,
  useMigrateAppdragCompanyMutation,
} from './AppdragCompanyMigrationsView.generated';

export const AppdragCompanyMigrationsView = () => {
  const { data, loading, refetch } = useAppdragCompanyMigrationsViewQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [migrateCompany] = useMigrateAppdragCompanyMutation();

  const [companyId, setCompanyId] = useState(-1);

  const handleMigrate = async (companyId: number, companyName: string) => {
    if (
      !confirm(
        "Êtes-vous sûr de vouloir migrer cette société ? Les accès vers cette société seront bloqués sur l'ancienne version d'HappyPal",
      )
    ) {
      return;
    }
    if (
      !confirm('Confirmer le lancement de la migration pour cette company ?')
    ) {
      return;
    }

    if (prompt('Veuillez saisir le nom de la société') !== companyName) {
      alert('Le nom saisi ne correspond pas au nom de la company');
      return;
    }
    setCompanyId(companyId);

    try {
      await migrateCompany({
        variables: {
          appdragId: companyId,
        },
      });
      message.success('Synchronization lancée avec succès');
      refetch();
    } catch (err) {
      message.error(`Une erreur est survenue: ${err}`);
      setCompanyId(-1);
    }
  };

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.appdragCompanies}
        rowKey={(r) => r.id}
        columns={[
          {
            key: 'id',
            dataIndex: 'id',
            title: 'Id de company',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'ascend',
          },
          {
            key: 'nom',
            dataIndex: 'nom',
            title: 'Nom',
            render: (v) => <TableRendererDate hideFrom value={v} />,
            sorter: (a, b) => a.nom.localeCompare(b.nom),
          },
          {
            key: 'isMigrated',
            dataIndex: 'isMigrated',
            title: 'Migrée',
            render: (v) => (
              <Tag color={v ? 'green' : 'red'}>{v ? 'Oui' : 'Non'}</Tag>
            ),
            filters: [
              {
                text: 'Oui',
                value: 1,
              },
              {
                text: 'Non',
                value: 0,
              },
            ],
            onFilter: (v, record) => record.isMigrated === v,
          },
          {
            key: 'migrate',
            dataIndex: 'id',
            title: 'Action',
            width: 250,
            align: 'right',
            render: (v, record) => (
              <Button
                onClick={() => handleMigrate(v, record.nom)}
                loading={companyId === v}
                disabled={companyId === v}
              >
                Migrer
              </Button>
            ),
          },
        ]}
      />
      <AppdragMigrationsSynchronizationsTable
        activeColumns={[
          'id',
          'appdragCompanyId',
          'status',
          'error',
          'createdAt',
        ]}
      />
    </>
  );
};
