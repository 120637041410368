import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CompanyCustomFieldType,
  CompanyMembershipRole,
} from '@graphql/generated/types';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelectCompanyMembershipRole } from '@components/atoms/FormSelectCompanyMembershipRole/FormSelectCompanyMembershipRole';
import { I18nLink } from '@components/atoms/Link/Link';
import { Typography } from '@components/atoms/Typography/Typography';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';
import { Avatar } from '@atoms/Avatar/Avatar';

import styles from './CompanyMembershipUpdateForm.module.css';

import {
  CompanyMembershipUpdateFormCustomFieldsFragment,
  CompanyMembershipUpdateFormFragment,
  CompanyMembershipUpdateFormUpdateMutation,
  CompanyMembershipUpdateFormUpdateMutationVariables,
  useCompanyMembershipUpdateFormUpdateMutation,
} from './CompanyMembershipUpdateForm.generated';

type CompanyMembershipUpdateFormValues = {
  role: CompanyMembershipRole;
  worksite?: string | null;
  jobTitle?: string | null;
  contract?: string | null;
  joinedAt?: Date | null;
  leftAt?: Date | null;
  deactivated?: boolean | null;
  custom?: Record<string, string | number> | null;
};

export const CompanyMembershipUpdateForm: React.FC<CompanyMembershipUpdateFormProps> =
  (props) => {
    const { className, membership, customFields, ...rest } = props;

    const validationSchema = Yup.object({
      role: Yup.mixed().oneOf(Object.values(CompanyMembershipRole)).required(),
      worksite: Yup.string().nullable(),
      jobTitle: Yup.string().nullable(),
      contract: Yup.string().nullable(),
      joinedAt: Yup.date().nullable(),
      leftAt: Yup.date().nullable(),
      deactivated: Yup.boolean().nullable(),
      custom: Yup.object().nullable(),
    });

    const form = useForm<CompanyMembershipUpdateFormValues>({
      validationSchema,
      defaultValues: {
        ...membership,
        deactivated: !!membership.deactivatedAt,
        custom: membership.customFields,
      },
    });

    const { t } = useTranslation('companies');

    const [mutation] = useCompanyMembershipUpdateFormUpdateMutation();

    const handleSubmit: FormProps<CompanyMembershipUpdateFormValues>['onValid'] =
      async (values) => {
        const variables: CompanyMembershipUpdateFormUpdateMutationVariables = {
          companyMembershipId: membership.id,
          input: {
            role: values.role,
            worksite: values.worksite,
            jobTitle: values.jobTitle,
            contract: values.contract,
            joinedAt: values.joinedAt,
            leftAt: values.leftAt,
            deactivated: values.deactivated,
            metadata: values.custom,
          },
        };

        const { data } = await mutation({ variables });

        return data;
      };

    const { joinedAt, leftAt } = form.getValues();

    return (
      <div className={styles.root}>
        <div className={styles.user}>
          <Avatar size={128} avatar={membership.user.avatar} />
          <Typography.Title className={styles.user_name} level={3}>
            {membership.user.firstName} {membership.user.lastName}
          </Typography.Title>
          <I18nLink to={`/users/list/${membership.user.id}`}>
            {membership.user.email}
          </I18nLink>
        </div>
        <Form
          id="CompanyMembershipUpdateForm"
          className={styles.form}
          onValid={handleSubmit}
          form={form}
          {...rest}
        >
          <FormItem
            className={styles.role}
            required
            label={t('CompanyMembershipUpdateForm.fields.role.label')}
            name="role"
          >
            <FormSelectCompanyMembershipRole name="role" />
          </FormItem>
          <FormItem
            className={styles.worksite}
            label={t('CompanyMembershipUpdateForm.fields.worksite.label')}
            name="worksite"
          >
            <FormInput name="worksite" />
          </FormItem>
          <FormItem
            className={styles.jobTitle}
            label={t('CompanyMembershipUpdateForm.fields.jobTitle.label')}
            name="jobTitle"
          >
            <FormInput name="jobTitle" />
          </FormItem>
          <FormItem
            className={styles.contract}
            label={t('CompanyMembershipUpdateForm.fields.contract.label')}
            name="contract"
          >
            <FormInput name="contract" />
          </FormItem>
          <FormItem
            className={styles.joinedAt}
            label={t('CompanyMembershipUpdateForm.fields.joinedAt.label')}
            name="joinedAt"
          >
            <FormInputDate
              disabledDate={(date) => (leftAt ? date.isAfter(leftAt) : false)}
              name="joinedAt"
            />
          </FormItem>
          <FormItem
            className={styles.leftAt}
            label={t('CompanyMembershipUpdateForm.fields.leftAt.label')}
            name="leftAt"
          >
            <FormInputDate
              disabledDate={(date) =>
                joinedAt ? date.isBefore(joinedAt) : false
              }
              name="leftAt"
            />
          </FormItem>
          <FormItem
            className={styles.deactivated}
            label={t('CompanyMembershipUpdateForm.fields.deactivated.label')}
            name="deactivated"
          >
            <FormCheckbox name="deactivated" />
          </FormItem>
          {customFields.map((field) => (
            <FormItem
              key={field.id}
              className={styles.custom}
              label={field.label}
              name={`custom.${field.key}`}
            >
              {field.type === CompanyCustomFieldType.String && (
                <FormInput name={`custom.${field.key}`} />
              )}
              {field.type === CompanyCustomFieldType.Integer && (
                <FormInputNumber name={`custom.${field.key}`} />
              )}
            </FormItem>
          ))}
        </Form>
      </div>
    );
  };

export type CompanyMembershipUpdateFormProps = {
  className?: string;
  membership: CompanyMembershipUpdateFormFragment;
  hideSubmitButton?: boolean;
  customFields: CompanyMembershipUpdateFormCustomFieldsFragment[];
  onSuccess?: (
    data: CompanyMembershipUpdateFormUpdateMutation['companyMembershipUpdate'],
  ) => void;
};
