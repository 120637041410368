import { useTranslation } from 'react-i18next';

import { HappeningFundsStatus } from '@graphql/generated/types';

import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { HappeningConfirmTransferButton } from '@components/organisms/HappeningConfirmTransferButton/HappeningConfirmTransferButton';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  HappeningsTableQueryVariables,
  HappeningsTableRecordFragment,
  useHappeningsTableQuery,
} from './HappeningsTable.generated';

type RecordType = HappeningsTableRecordFragment;

type Column =
  | 'name'
  | 'company'
  | 'registrationSartsAt'
  | 'registrationEndsAt'
  | 'happeningStartsAt'
  | 'happeningEndsAt';

export function HappeningsTable(props: HappeningsTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('happenings');

  const query =
    propQuery ||
    useHappeningsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('fields.name'),
      dataIndex: ['name'],
      render: (_, happening) => (
        <I18nLink to={`/happenings/list/${happening.id}`}>
          {happening.name}
        </I18nLink>
      ),
    },
    {
      key: 'status',
      title: t('fields.company'),
      dataIndex: ['company', 'name'],
      sorter: true,
      render: (_, happening) => (
        <I18nLink to={`/companies/list/${happening.company?.id}`}>
          {happening.company?.name}
        </I18nLink>
      ),
    },
    {
      key: 'registrationSartsAt',
      title: t('fields.registrationStartsAt'),
      dataIndex: ['registrationStartsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'registrationEndsAt',
      title: t('fields.registrationEndsAt'),
      dataIndex: ['registrationEndsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'happeningSartsAt',
      title: t('fields.happeningStartsAt'),
      dataIndex: ['happeningStartsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'happeningEndsAt',
      title: t('fields.happeningEndsAt'),
      dataIndex: ['happeningEndsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="HappeningsTable"
        query={query}
        columns={columns}
        action={(record) => {
          if (
            record.registrationEndsAt < new Date() &&
            record.fundsStatus &&
            [
              HappeningFundsStatus.PendingTransfer,
              HappeningFundsStatus.TransferDone,
            ].includes(record.fundsStatus)
          ) {
            return (
              <HappeningConfirmTransferButton
                key={record.id}
                happening={record}
              />
            );
          }
        }}
        data={propData || query.data?.happeningPagination}
        {...rest}
      />
    </div>
  );
}

export type HappeningsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: HappeningsTableQueryVariables['filter'];
  sorter?: HappeningsTableQueryVariables['sorter'];
};
