import { useTranslation } from 'react-i18next';

import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  AppdragMigrationsSynchronizationsTableRecordFragment,
  useAppdragMigrationsSynchronizationsTableQuery,
} from './AppdragMigrationsSynchronizationsTable.generated';

type RecordType = AppdragMigrationsSynchronizationsTableRecordFragment;

type Column = 'appdragCompanyId' | 'status' | 'createdAt' | 'error';

export function AppdragMigrationsSynchronizationsTable(
  props: AppdragMigrationsSynchronizationsTableProps,
) {
  const { className, ...rest } = props;

  const { t } = useTranslation('productSynchronizations');

  const query = useAppdragMigrationsSynchronizationsTableQuery({
    variables: {
      skip: 0,
      take: 10,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
      render: (id) => (
        <I18nLink to={`/AppdragMigrationss/synchronizations/${id}`}>
          {id}
        </I18nLink>
      ),
    },

    {
      key: 'appdragCompanyId',
      title: 'appdragCompanyId',
      dataIndex: ['appdragCompanyId'],
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
    },
    {
      key: 'error',
      title: t('table.headers.error'),
      dataIndex: ['error'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="AppdragMigrationsSynchronizationsTable"
        query={query}
        columns={columns}
        data={query.data?.AppdragMigrationsSynchronizationsPagination}
        {...rest}
      />
    </div>
  );
}

export type AppdragMigrationsSynchronizationsTableProps =
  ChildTablePaginationProps<RecordType, Column> & {
    className?: string;
  };
