import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';

import { SplashView } from '../../components/views/SplashView/SplashView';

import { EventBus, Events } from '@services/bus.service';

import {
  AuthHookFragment,
  AuthLogoutMutationHookResult,
  useAuthHookViewerQuery,
  useAuthLogoutMutation,
} from './auth.hooks.generated';

type AuthContextType = {
  viewer: AuthHookFragment | null;
  logout: AuthLogoutMutationHookResult['0'];
};

const AuthContext = createContext<AuthContextType>(
  null as unknown as AuthContextType,
);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;

  const viewerQuery = useAuthHookViewerQuery({
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
  });

  const [logout] = useAuthLogoutMutation();

  async function handleRefetch() {
    await viewerQuery.refetch();
  }

  useEffect(() => {
    EventBus.on(Events.UnauthorizedError, handleRefetch);

    return () => EventBus.off(Events.UnauthorizedError, handleRefetch);
  }, []);

  if (viewerQuery.networkStatus === NetworkStatus.loading) {
    return <SplashView backgroundOnly />;
  }

  return (
    <AuthContext.Provider
      value={{
        viewer: viewerQuery?.data?.viewer || null,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export type AuthProviderProps = {
  children: ReactNode;
};
