import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import classnames from 'classnames';

import { I18nLink } from '@components/atoms/Link/Link';
import { HappeningsTable } from '@tables/HappeningsTable/HappeningsTable';

import styles from './HappeningsView.module.css';

export const HappeningsView: React.FC<HappeningsViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('happenings');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('HappeningsView.title')}</Typography.Title>

      <HappeningsTable
        activeColumns={[
          'name',
          'registrationSartsAt',
          'registrationEndsAt',
          'happeningStartsAt',
          'happeningEndsAt',
        ]}
        title={() => (
          <I18nLink to="/happenings/create">
            <Button type="primary">{t('HappeningsView.create')}</Button>
          </I18nLink>
        )}
      />
    </div>
  );
};

export type HappeningsViewProps = {
  className?: string;
};
