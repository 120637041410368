import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Space, Statistic, Tooltip } from 'antd';
import cronstrue from 'cronstrue';

import { CompanyFeatureConfigConnectorFrequencyButton } from '../CompanyFeatureConfigConnectorFrequencyButton/CompanyFeatureConfigConnectorFrequencyButton';
import { CompanyFeatureConfigConnectorSynchronisationTriggerButton } from '../CompanyFeatureConfigConnectorSynchronisationTriggerButton/CompanyFeatureConfigConnectorSynchronisationTriggerButton';

import { CompanyFeatureConfigConnectorCardConnectorFragment } from './CompanyFeatureConfigConnectorCard.generated';

interface CompanyFeatureConfigConnectorCardProps {
  connector: CompanyFeatureConfigConnectorCardConnectorFragment;
  providerDisabled?: boolean;
  companyId: string;
  index: number;
}

export const CompanyFeatureConfigConnectorCard = (
  props: CompanyFeatureConfigConnectorCardProps,
) => {
  const { connector, index, companyId, providerDisabled } = props;

  const lastSynchronisation = connector.lastSynchronisation.edges[0]?.node;
  const nextSynchronsationTime = connector.nextSynchronisationDate?.getTime();
  const frequency = connector.synchronisationFrequency;

  const predeterminedFrequency: Record<string, string> = {
    '0 */4 * * *': 'Toutes les 4 heures',
    '0 0 * * *': 'Tous les jours',
    '0 0 * * 1-5': 'Tous les jours de semaine',
    '0 0 * * MON': 'Tous les lundis',
    '0 0 1 * *': 'Tous les 1er du mois',
  };

  return (
    <Card
      title={`Connecteur #${index + 1}`}
      size="small"
      extra={
        <Space>
          <CompanyFeatureConfigConnectorFrequencyButton
            companyId={companyId}
            connector={connector}
          />
          <Link to={`./user-integration/${connector.id}`}>
            <Button>Voir plus</Button>
          </Link>
          <CompanyFeatureConfigConnectorSynchronisationTriggerButton
            companyId={companyId}
            connectorId={connector.id}
          />
        </Space>
      }
    >
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title="Nombre de synchronisations"
            value={connector.synchronisationCount.totalCount}
          />
        </Col>
        <Col span={6}>
          {lastSynchronisation ? (
            <Statistic
              title="Dernière synchronisation"
              value={
                lastSynchronisation.endedAt?.toLocaleString() ?? 'En cours'
              }
            />
          ) : (
            <Statistic
              valueStyle={{ color: 'lightgrey' }}
              title="Dernìere synchronisation"
              value="Aucune"
            />
          )}
        </Col>
        <Col span={6}>
          {providerDisabled ? (
            <Statistic
              valueStyle={{ color: 'lightgrey' }}
              title="Prochaine synchronisation"
              value="Désactivée"
            />
          ) : nextSynchronsationTime ? (
            <Tooltip
              title={connector.nextSynchronisationDate?.toLocaleString()}
            >
              <Statistic.Countdown
                title="Prochaine synchronisation"
                value={nextSynchronsationTime}
              />
            </Tooltip>
          ) : (
            <Statistic
              valueStyle={{ color: 'lightgrey' }}
              title="Prochaine synchronisation"
              value="Non planifiée"
            />
          )}
        </Col>
        <Col span={6}>
          {frequency ? (
            <Tooltip title={frequency}>
              <Statistic
                title="Fréquence de synchronisation"
                value={
                  predeterminedFrequency[frequency] ??
                  cronstrue.toString(frequency, {
                    use24HourTimeFormat: true,
                    locale: 'fr',
                  })
                }
              />
            </Tooltip>
          ) : (
            <Statistic
              valueStyle={{ color: 'lightgrey' }}
              title="Fréquence de synchronisation"
              value="Non paramétrée"
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
