import classnames from 'classnames';

import { SearchSynchronizationsTable } from '@tables/SearchSynchronizationsTable/SearchSynchronizationsTable';

import styles from './SearchSynchronizationsView.module.css';

export const SearchSynchronizationsView: React.FC<SearchSynchronizationsViewProps> =
  (props) => {
    const { className } = props;

    return (
      <div className={classnames(className, styles.root)}>
        <SearchSynchronizationsTable
          activeColumns={['id', 'type', 'status', 'createdAt']}
        />
      </div>
    );
  };

export type SearchSynchronizationsViewProps = {
  className?: string;
};
