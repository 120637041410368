import { CheckCircleFilled } from '@ant-design/icons';
import { Card, List } from 'antd';

import { Typography } from '@components/atoms/Typography/Typography';

import { MangopayUserCardFragment } from './MangopayUserCard.generated';

export const MangopayUserCard = (props: MangopayUserCardProps) => {
  const { mangopayUser, className } = props;

  if (!mangopayUser) {
    return <span>non trouvé</span>;
  }

  const data = [
    { title: 'User', value: mangopayUser.mangopayId },
    ...mangopayUser.bankAccountsPagination.nodes.map((bankAccount) => ({
      title: (
        <Typography.Text>
          Bank Account{' '}
          <CheckCircleFilled
            style={{ color: bankAccount.active ? 'green' : 'red' }}
          />
        </Typography.Text>
      ),
      value: bankAccount.mangopayId,
    })),
  ];

  return (
    <List
      grid={{
        gutter: 16,
        column: 2,
      }}
      dataSource={data}
      className={className}
      renderItem={(item) => (
        <Card title={item.title} size="small">
          <Typography.Text copyable>{item.value}</Typography.Text>
        </Card>
      )}
    />
  );
};

export type MangopayUserCardProps = {
  mangopayUser?: MangopayUserCardFragment | null;
  className?: string;
};
