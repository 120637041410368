import { ReactNode } from 'react';
import { Radio, RadioGroupProps } from 'antd';

import styles from './FilterDropdownBoolean.module.css';

export function FilterDropdownBoolean(props: FilterDropdownBooleanProps) {
  const { options, onChange, value, name } = props;

  const handleChange: RadioGroupProps['onChange'] = (e) => {
    const value = e.target.value;
    onChange(value as unknown as string);
  };

  return (
    <div className={styles.root} id={name}>
      <Radio.Group onChange={handleChange} value={value}>
        {options.map((option) => (
          <div key={option.value}>
            <Radio value={option.value}>{option.label}</Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );
}

export type FilterDropdownBooleanProps = {
  options: { value: string | number; label: ReactNode }[];
  onChange: (e: string) => void;
  value?: string;
  name: string;
};
