import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './GameUpdateForm.module.css';

import {
  GameUpdateFormFragment,
  GameUpdateFormMutation,
  GameUpdateFormMutationVariables,
  useGameUpdateFormMutation,
} from './GameUpdateForm.generated';

export type GameUpdateFormValues = {
  name: string;
  url: string;
  startsAt: Date;
  endsAt: Date;
  isExclusive: boolean;
};

export function GameUpdateForm(props: GameUpdateFormProps) {
  const { className, hideFooter, game, ...rest } = props;

  const { t } = useTranslation('games');

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    url: Yup.string().required().url(),
    startsAt: Yup.date().required(),
    endsAt: Yup.date().required(),
    isExclusive: Yup.boolean().required(),
  }).required();

  const form = useForm<GameUpdateFormValues>({
    validationSchema,
    defaultValues: {
      name: game.name,
      url: game.url,
      startsAt: game.startsAt || undefined,
      endsAt: game.endsAt || undefined,
      isExclusive: game.isExclusive,
    },
  });

  const [mutation] = useGameUpdateFormMutation();

  const handleSubmit: FormProps<GameUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: GameUpdateFormMutationVariables = {
      gameId: game.id,
      input: {
        name: values.name,
        url: values.url,
        startsAt: values.startsAt,
        endsAt: values.endsAt,
        isExclusive: values.isExclusive,
      },
    };

    try {
      const { data } = await mutation({
        variables,
        refetchQueries: ['GameIdView'],
      });
      message.success(t('GameUpdateForm.successMessage'));
      return data;
    } catch (err) {
      handleMutationErrors(err, {});
    }
  };

  const { startsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="GameUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('fields.name')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.description}
          required
          label={t('fields.url')}
          name="url"
        >
          <FormInput name="url" />
        </FormItem>

        <FormItem
          className={styles.startsAt}
          required
          label={t('fields.startsAt')}
          name="startsAt"
        >
          <FormInputDate
            name="startsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
          />
        </FormItem>

        <FormItem
          className={styles.endsAt}
          required
          label={t('fields.endsAt')}
          name="endsAt"
        >
          <FormInputDate
            name="endsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(startsAt)}
          />
        </FormItem>
        <FormItem
          className={styles.isExclusive}
          required
          label={t('fields.isExclusive')}
          name="isExclusive"
        >
          <FormCheckbox name="isExclusive" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('GameUpdateForm.update')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type GameUpdateFormProps = ChildFormProps<
  GameUpdateFormValues,
  GameUpdateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
  game: GameUpdateFormFragment;
};
