import classnames from 'classnames';

import { AdminCommentTargetType } from '@graphql/generated/types';

import { AdminCommentForm } from '@components/forms/AdminCommentForm/AdminCommentForm';
import AdminCommentList, {
  ADMIN_COMMENT_PAGE_SIZE,
} from '@components/organisms/AdminCommentList/AdminCommentList';

import styles from './AdminCommentsView.module.css';

import { useAdminCommentsViewQuery } from './AdminCommentsView.generated';

export const AdminCommentsView: React.FC<AdminCommentsViewProps> = (props) => {
  const { className, targetType, targetId } = props;

  const query = useAdminCommentsViewQuery({
    variables: {
      skip: 0,
      take: ADMIN_COMMENT_PAGE_SIZE,
      filter: {
        targetType: { is: targetType },
        targetId: { is: targetId },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className={classnames(className, styles.root)}>
      <AdminCommentList
        query={query}
        data={query.data?.adminCommentPagination}
        targetId={targetId}
        targetType={targetType}
      />

      <AdminCommentForm
        targetId={targetId}
        targetType={targetType}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
      />
    </div>
  );
};

export type AdminCommentsViewProps = {
  className?: string;
  targetType: AdminCommentTargetType;
  targetId: string;
};
