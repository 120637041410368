import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectCampaign.module.css';

import {
  FormSelectCampaignQueryVariables,
  useFormSelectCampaignQuery,
} from './FormSelectCampaign.generated';

export const FormSelectCampaign: React.FC<FormSelectCampaignProps> = (
  props,
) => {
  const { className, filter, sorter, ...rest } = props;

  const { data, loading, variables, refetch } = useFormSelectCampaignQuery({
    variables: {
      filter,
      sorter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = debounce((value: string) => {
    refetch({
      ...(variables || {}),
      filter: {
        ...(variables?.filter || {}),
        name: { search: value },
      },
    });
  }, 300);

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      options={data?.campaignPagination.nodes.map((node) => ({
        value: node.id,
        label: `${node.name} - (${node.status})`,
      }))}
      {...rest}
    />
  );
};

export type FormSelectCampaignProps = FormSelectProps & {
  filter?: FormSelectCampaignQueryVariables['filter'];
  sorter?: FormSelectCampaignQueryVariables['sorter'];
};
