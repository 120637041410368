/* eslint-disable @typescript-eslint/no-explicit-any */

import { get } from 'lodash';
import { Parser } from 'xml2js';
import * as Yup from 'yup';

const PATHS = {
  identityId: 'EntityDescriptor.$.entityID',
  supportedProtocols:
    'EntityDescriptor.IDPSSODescriptor[0].$.protocolSupportEnumeration',
  keyDescriptors: 'EntityDescriptor.IDPSSODescriptor[0].KeyDescriptor',
  certificate: 'KeyInfo[0].X509Data[0].X509Certificate[0]',
  singleSignOnService:
    'EntityDescriptor.IDPSSODescriptor[0].SingleSignOnService',
  singleLogoutService:
    'EntityDescriptor.IDPSSODescriptor[0].SingleLogoutService',
  namedIdFormats: 'EntityDescriptor.IDPSSODescriptor[0].NameIDFormat',
  attributes: 'EntityDescriptor.IDPSSODescriptor[0].Attribute',
};

export async function parseSAMLMetadata(xml: string) {
  const parser = new Parser({
    normalize: true,
    tagNameProcessors: [(name: string) => name.split(':').pop()],
  });

  const result = await parser.parseStringPromise(xml);
  console.log(result);

  const keyDescriptor = get(result, PATHS.keyDescriptors, []).find(
    (elem: any) => get(elem, '$.use') === 'signing',
  );

  const certificate = get(keyDescriptor, PATHS.certificate);

  const loginUrl = get(result, PATHS.singleSignOnService, []).find(
    (elem: any) =>
      get(elem, '$.Binding') ===
      'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
  )?.$.Location;

  const singleLogoutServices = get(result, PATHS.singleLogoutService, []);
  const logoutUrl = singleLogoutServices.find((elem: any) =>
    [
      'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
      'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
    ].includes(get(elem, '$.Binding')),
  )?.$.Location;

  try {
    return await SAMLProviderSchema.validate(
      {
        identityId: get(result, PATHS.identityId),
        supportedProtocols: get(result, PATHS.supportedProtocols, '').split(
          ' ',
        ),
        certificate,
        loginUrl,
        logoutUrl,
        namedIdFormats: get(result, PATHS.namedIdFormats, []),
        attributes: get(result, PATHS.attributes, []).map((elem: any) => ({
          name: elem.$.Name,
          friendlyName: elem.$.FriendlyName,
          format: elem.$.NameFormat,
        })),
      },
      { stripUnknown: true, abortEarly: false },
    );
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      console.error(err.errors);
    }
    throw err;
  }
}

const SAMLProviderSchema = Yup.object().shape({
  identityId: Yup.string().required(),
  supportedProtocols: Yup.array().of(Yup.string().required()).required().min(1),
  certificate: Yup.string().required(),
  loginUrl: Yup.string().required(),
  logoutUrl: Yup.string().optional(),
  namedIdFormats: Yup.array().of(Yup.string().required()).required(),
  attributes: Yup.array(
    Yup.object({
      friendlyName: Yup.string(),
      name: Yup.string().required(),
      format: Yup.string().required(),
    }).required(),
  ).required(),
});
