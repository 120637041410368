import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, message, Spin } from 'antd';
import cx from 'classnames';
import * as yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { Card } from '@components/atoms/Card/Card';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './ProductArticleCreateBulkView.module.css';

import {
  useProductArticleCreateBulkViewCreateProductArticlesMutation,
  useProductArticleCreateBulkViewProductVariantQuery,
} from './ProductArticleCreateBulkView.generated';

type ProductArticleCreateBulkViewProps = {
  className?: string;
  productVariantId: string;
};

export const ProductArticleCreateBulkView = (
  props: ProductArticleCreateBulkViewProps,
) => {
  const { className, productVariantId } = props;
  const history = useHistory();
  const { i18n } = useTranslation();

  const { loading, data } = useProductArticleCreateBulkViewProductVariantQuery({
    variables: { productVariantId },
  });
  const [bulkCreateMutation, bulkCreateState] =
    useProductArticleCreateBulkViewCreateProductArticlesMutation({});

  const form = useForm({
    validationSchema: yup
      .object({
        files: yup
          .array(yup.mixed<AttachmentItemFragment>().required())
          .min(1)
          .required(),
      })
      .required(),
  });

  if (loading && !data) return <Spin />;
  else if (!data) return <NotFoundView />;

  const handleValid: FormProps<{
    files?: (AttachmentItemFragment | undefined)[];
  }>['onValid'] = async (values) => {
    try {
      await bulkCreateMutation({
        variables: {
          productVariantId,
          input: {
            fileIds: values.files
              ?.map((file) => file?.id)
              .filter(Boolean) as string[],
          },
        },
      });

      history.push(
        `/${i18n.language}/product-variants/list/${productVariantId}/articles`,
      );
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
      } else {
        message.error('Une erreur est survenue');
        console.error(err);
      }
    }
  };

  return (
    <Card className={cx(className, styles.root)}>
      <Form id="ProductArticleCreateBulkForm" onValid={handleValid} form={form}>
        <FormItem required label="Fichiers" name="files">
          <FormInputMultipleUpload
            control={form.control}
            name="files"
            type={FileType.ProductArticle}
          />
        </FormItem>
        <Button loading={bulkCreateState.loading} htmlType="submit">
          Envoyer
        </Button>
      </Form>
    </Card>
  );
};
