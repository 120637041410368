import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

import { PublishedStatusTag } from '@components/atoms/PublishedStatusTag/PublishedStatusTag';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  PublicationsTableQueryVariables,
  PublicationsTableRecordFragment,
  usePublicationsTableQuery,
  usePublicationsTableRemoveMutation,
} from './PublicationsTable.generated';

type RecordType = PublicationsTableRecordFragment;

type Column =
  | 'title'
  | 'isHighlighted'
  | 'author'
  | 'target'
  | 'status'
  | 'publishedAt';

export function PublicationsTable(props: PublicationsTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('communication');

  const [removeMutation] = usePublicationsTableRemoveMutation();

  const query =
    propQuery ||
    usePublicationsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'title',
      title: t('publications.table.headers.title'),
      dataIndex: ['title'],
      render: (title, node) => (
        <I18nLink to={`/communication/publications/list/${node.id}`}>
          {title}
        </I18nLink>
      ),
    },
    {
      key: 'isHighlighted',
      title: t('publications.table.headers.isHighlighted'),
      dataIndex: ['isHighlighted'],
    },
    {
      key: 'publishedAt',
      title: t('publications.table.headers.publishedAt'),
      dataIndex: ['publishedAt'],
    },
    {
      key: 'status',
      title: t('publications.table.headers.status'),
      dataIndex: ['status'],
      sorter: true,
      render: (_, node) => (
        <PublishedStatusTag published={!!node.publishedAt} />
      ),
    },
    {
      key: 'author',
      title: t('publications.table.headers.author'),
      dataIndex: ['author'],
      render: (_, node) => `${node.author?.firstName} ${node.author?.lastName}`,
    },
    {
      key: 'target',
      title: t('publications.table.headers.target'),
      dataIndex: ['target'],
      render: (_, node) => node.target?.__typename || 'Aucun',
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="PublicationsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.publicationsPagination}
        action={(record) => (
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer cette publication ?"
            okText="Supprimer"
            okType="danger"
            onConfirm={handleDelete(record.id)}
          >
            <Button type="text" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
        {...rest}
      />
    </div>
  );

  function handleDelete(recordId: string) {
    return async () => {
      await removeMutation({
        variables: { publicationId: recordId },
      });
      await query.refetch();
    };
  }
}

export type PublicationsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: PublicationsTableQueryVariables['filter'];
  sorter?: PublicationsTableQueryVariables['sorter'];
};
