import { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
import { random } from 'lodash';

import { AppLogo } from '@atoms/AppLogo/AppLogo';

import styles from './SplashView.module.css';

export const SplashView: React.FC<SplashViewProps> = memo((props) => {
  const { className, children, backgroundOnly } = props;
  const [flares, setFlares] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const flareWidth = 40;
    const flareSpace = -20;
    const flareCount = Math.ceil(
      window.screen.availWidth / (flareWidth + flareSpace),
    );

    setFlares(
      new Array(flareCount).fill('').map((_, i) => {
        return (
          <div
            key={i}
            className={styles.flare}
            style={{
              top: `calc(-50% + ${(flareWidth + flareSpace) * i}px)`,
              animationDelay: `${random(0, 10000)}ms`,
              animationDuration: `${random(8000, 13000)}ms`,
            }}
          />
        );
      }),
    );
  }, []);

  return (
    <div id="splash" className={classnames(className, styles.root)}>
      {backgroundOnly !== true && (
        <>
          <div className={styles.flares}>{flares}</div>
          <AppLogo className={styles.logo} />
          {children && <div className={styles.children}>{children}</div>}
        </>
      )}
    </div>
  );
});

export type SplashViewProps = {
  className?: string;
  backgroundOnly?: boolean;
};
