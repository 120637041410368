export enum MIME_TYPES {
  PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CSV = 'text/csv',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  MOV = 'video/quicktime',
}

export function acceptInputFormatter(types: MIME_TYPES[]): string {
  return types.reduce((acc, mime) => acc + ', ' + mime, '');
}
