import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { ProductType } from '@graphql/generated/types';

import styles from './ProductTypeTag.module.css';

import { ProductTypeTagFragment } from './ProductTypeTag.generated';

export const ProductTypeTag: React.FC<ProductTypeTagProps> = (props) => {
  const { className, fragment } = props;
  const { t } = useTranslation('products');

  const colors: Record<ProductType, string> = {
    [ProductType.GiftCard]: 'green',
    [ProductType.Ticket]: 'blue',
  };

  const labels: Record<ProductType, string> = {
    [ProductType.GiftCard]: t('productType.GiftCard'),
    [ProductType.Ticket]: t('productType.Ticket'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[fragment.type]}
    >
      {labels[fragment.type]}
    </Tag>
  );
};

export type ProductTypeTagProps = {
  className?: string;
  fragment: ProductTypeTagFragment;
};
