import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import QueryString from 'qs';

import { Company } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { ArticleCategoriesTable } from '@components/tables/ArticleCategoriesTable/ArticleCategoriesTable';

export const CompanyIdArticleCategoriesView: React.FC<CompanyIdArticleCategoriesViewProps> =
  (props) => {
    const { className, companyId } = props;
    const { t } = useTranslation('communication');

    return (
      <div className={classnames(className)}>
        <ArticleCategoriesTable
          activeColumns={['name', 'articlesCount', 'createdAt', 'updatedAt']}
          filter={{ company: { id: { is: companyId } } }}
          title={() => (
            <>
              <I18nLink
                to={{
                  pathname: `/communication/articles/categories/create`,
                  search: QueryString.stringify({ companyId }),
                }}
              >
                <Button>
                  {t('articles.category.table.create', 'Ajouter une catégorie')}
                </Button>
              </I18nLink>
            </>
          )}
          placeholder={{
            children: (
              <I18nLink
                to={{
                  pathname: `/communication/articles/categories/create`,
                  search: QueryString.stringify({ companyId }),
                }}
              >
                <Button>
                  {t('articles.category.table.create', 'Ajouter une catégorie')}
                </Button>
              </I18nLink>
            ),
          }}
        />
      </div>
    );
  };

export type CompanyIdArticleCategoriesViewProps = {
  companyId: Company['id'];
  className?: string;
};
