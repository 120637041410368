import { noop } from 'lodash';

import { SSOProviderSAMLForm } from '@components/forms/SSOProviderSAMLForm/SSOProviderSAMLForm';
import { Modal } from '@components/organisms/Modal/Modal';

type SSOProviderSAMLModalProps = {
  companyId: string;
  onClose?: () => void;
  isOpen?: boolean;
};

export function SSOProviderSAMLModal(props: SSOProviderSAMLModalProps) {
  const { companyId, onClose = noop, isOpen = false } = props;

  return (
    <Modal
      title="SSOProviderSAMLModal"
      onClose={onClose}
      isOpen={isOpen}
      form="SSOProviderSAMLForm"
    >
      <SSOProviderSAMLForm companyId={companyId} />
    </Modal>
  );
}
