/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import {
  ColumnHeightOutlined,
  ReloadOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { QueryResult } from '@apollo/client';
import {
  Button,
  ConfigProvider,
  Empty,
  Result,
  Space,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import { GetRowKey, SortOrder } from 'antd/lib/table/interface';
import classnames from 'classnames';
import { flatten, set } from 'lodash';

import {
  AdminCommentTargetType,
  Maybe,
  SortDirection,
} from '@graphql/generated/types';

import { TablePaginationModal } from '@components/modals/TablePaginationModal/TablePaginationModal';
import { TableRendererDateTime } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';

import styles from './TablePagination.module.css';

export const mapperSorter: Record<SortDirection, SortOrder> = {
  ASC: 'ascend',
  DESC: 'descend',
};

export function TablePagination<
  RecordType extends { id: string } | { cursor: string },
  Column = string,
>(props: TablePaginationProps<Column, RecordType>) {
  const {
    id,
    className,
    query,
    data,
    columns: propColumns,
    activeColumns,
    selectable,
    placeholder,
    sorterConverter,
    title,
    action,
    customizable = true,
    onRowClick,
    rowClassName,
    adminCommentTargetType,
    sorter,
    ...rest
  } = props;

  const route = useRouteMatch();

  const localStorageKey = `table_${id}_${route.url
    .replaceAll(
      /([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gm,
      ':id',
    )
    .slice(3)}`;

  const getDefaultActiveColumns = () => {
    const stored = localStorage.getItem(localStorageKey);

    if (stored) {
      return stored.split(',') as string[];
    } else {
      return activeColumns as string[];
    }
  };

  const [dataSource, setDataSource] = useState<RecordType[]>(
    (data && 'edges' in data ? data.edges : data?.nodes) || [],
  );
  const [areSettingsOpen, setSettingsOpen] = useState(false);
  const [_activeColumns, setActiveColumns] = useState(
    getDefaultActiveColumns(),
  );
  const [density, setDensity] = useState(
    (localStorage.getItem('table_density') as SizeType) || 'middle',
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, _activeColumns.toString());
  }, [_activeColumns]);

  useEffect(() => {
    if (data && 'edges' in data) {
      setDataSource(data.edges);
    } else if (data?.nodes) {
      setDataSource(data.nodes);
    }
  }, [data]);

  const cycleDensity = () => {
    const densities = ['small', 'middle', 'large', 'small'] as const;

    const nextDensity =
      densities[densities.findIndex((v) => v === density) + 1];

    setDensity(nextDensity);
    localStorage.setItem('table_density', nextDensity);
  };

  const reload = async () => {
    await query.refetch();
  };

  const { t } = useTranslation();

  const defaultColumns: TablePaginationColumn<RecordType>[] = [
    {
      title: t('table.headers.__typename'),
      dataIndex: ['__typename'],
      key: '__typename',
      ellipsis: true,
    },
    {
      title: t('table.headers.id'),
      dataIndex: ['id'],
      key: 'id',
      ellipsis: true,
    },
    {
      title: t('table.headers.createdAt'),
      dataIndex: ['createdAt'],
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: mapperSorter[sorter?.createdAt as SortDirection],
      ellipsis: true,
      render: (createdAt) => <TableRendererDateTime value={createdAt} />,
    },
    {
      title: t('table.headers.updatedAt'),
      dataIndex: ['updatedAt'],
      key: 'updatedAt',
      sorter: true,
      defaultSortOrder: mapperSorter[sorter?.updatedAt as SortDirection],
      ellipsis: true,
      render: (updatedAt) => <TableRendererDateTime value={updatedAt} />,
    },
  ];

  const availableColumns = propColumns;

  defaultColumns.reverse().forEach((col) => {
    if (!availableColumns.find((c) => c.key === col.key)) {
      availableColumns.unshift(col);
    }
  });

  const columns = flatten(
    _activeColumns.map((key) =>
      availableColumns.filter(
        (col) =>
          col.key === key ||
          (col.key.startsWith('customFields') &&
            (key as string) === 'customFields'),
      ),
    ),
  ).filter(Boolean) as TablePaginationColumn<RecordType>[];

  if (action) {
    columns.push({
      title: t('table.headers.action'),
      key: 'action',
      render: (_, record) => action(record),
    });
  }

  // if (adminCommentTargetType) {
  //   columns.push({
  //     title: t('table.headers.adminComments'),
  //     width: 80,
  //     key: 'adminComments',
  //     render: (_, record) => (
  //       <AdminCommentsDrawerOpenButton
  //         targetType={adminCommentTargetType}
  //         // @ts-expect-error edges ...
  //         targetId={record.id || record.cursor}
  //         tableMode={true}
  //       />
  //     ),
  //   });
  // }

  const handleChange: TableProps<RecordType>['onChange'] = (
    { pageSize = 25, current = 1 },
    _,
    sorters,
  ) => {
    const variables = {
      skip: (current - 1) * pageSize,
      take: pageSize,
      sorter: {},
    };

    (Array.isArray(sorters) ? sorters : [sorters])
      .filter((sorter) => sorter.order && sorter.columnKey)
      .forEach((sorter) => {
        if (sorter.order === 'ascend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Asc);
        } else if (sorter.order === 'descend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Desc);
        }
      });

    if (sorterConverter) {
      variables.sorter = sorterConverter(variables.sorter) || {};
    }

    query.refetch({
      ...(query.variables || {}),
      ...variables,
    });
  };

  const renderEmpty = () =>
    query.error ? (
      <Result status="warning" title="Une erreur est survenue">
        <pre style={{ margin: 0 }}>{query.error.message}</pre>
      </Result>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={placeholder?.description}
      >
        {placeholder?.children}
      </Empty>
    );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table<RecordType>
        rowKey="id"
        {...rest}
        className={classnames(className, styles.route)}
        loading={query.loading}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        size={density}
        rowClassName={rowClassName}
        rowSelection={
          selectable
            ? {
                type: selectable.type,
                selectedRowKeys: selectable.value,
                onChange: selectable.onChange,
                preserveSelectedRowKeys: true,
                getCheckboxProps: (record) => ({
                  disabled: selectable.isDisabled
                    ? // @ts-ignore
                      selectable.isDisabled(record)
                    : false,
                }),
              }
            : undefined
        }
        pagination={{
          total: data?.totalCount,
          showTotal: (total, range) =>
            t('table.pagination', {
              count: total,
              rangeStart: range[0],
              rangeEnd: range[1],
            }),
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          defaultCurrent: 1,
          pageSize: query.variables?.take,
          current:
            (query.variables?.skip || 0) / (query.variables?.take || 10) + 1,
          defaultPageSize: 25,
          position: ['bottomCenter'],
        }}
        onRow={
          onRowClick &&
          ((record) => {
            return {
              onClick: (event) => onRowClick(record, event),
            };
          })
        }
        title={
          customizable
            ? (_data) => {
                return (
                  <div className={styles.title}>
                    <div className={styles.title__left}>
                      {title ? title(_data) : null}
                    </div>
                    <div className={styles.title__right}>
                      <Space>
                        <Tooltip title="Recharger">
                          <Button
                            onClick={() => reload()}
                            shape="circle"
                            icon={<ReloadOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Densité">
                          <Button
                            onClick={() => cycleDensity()}
                            shape="circle"
                            icon={<ColumnHeightOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Configurer">
                          <Button
                            onClick={() => setSettingsOpen(true)}
                            shape="circle"
                            icon={<TableOutlined />}
                          />
                        </Tooltip>
                      </Space>
                    </div>
                  </div>
                );
              }
            : undefined
        }
      />
      <TablePaginationModal
        isVisible={areSettingsOpen}
        onClose={() => setSettingsOpen(false)}
        onSubmit={(columns) => {
          setActiveColumns(columns);
          setSettingsOpen(false);
        }}
        selectedColumns={_activeColumns}
        columns={availableColumns}
      />
    </ConfigProvider>
  );
}

type TablePaginationQueryVariables = {
  take: number;
  skip: number;
  sorter?: Maybe<Record<string, any>>;
};

export type TablePaginationColumn<RecordType> = ColumnType<RecordType> & {
  key: string;
  title: string;
};

export type TablePaginationProps<
  Column = string,
  RecordType = any,
  R = any,
  V extends TablePaginationQueryVariables = TablePaginationQueryVariables,
> = ChildTablePaginationProps<RecordType, Column> & {
  id: string;
  className?: string;
  query: QueryResult<R, V>;
  data?: Maybe<
    {
      totalCount: number;
    } & (
      | { nodes: RecordType[] }
      | { edges: RecordType[] }
      | { edges: { node: RecordType } }[]
    )
  >;
  columns: TablePaginationColumn<RecordType>[];
  sorterConverter?: (sorter: V['sorter']) => V['sorter'];
  rowClassName?: string;
  rowKey?: string | GetRowKey<RecordType>;
  sorter?: V['sorter'];
};

type DefaultColumn = 'id' | '__typename' | 'createdAt' | 'updatedAt';

export type ChildTablePaginationProps<RecordType, Column = string> = {
  activeColumns: (DefaultColumn | Column)[];
  placeholder?: {
    description?: string;
    children?: React.ReactNode;
  };
  selectable?: {
    type: 'checkbox' | 'radio';
    isDisabled?: (record: RecordType) => boolean;
    onChange?: (identifiers: React.Key[]) => void;
    value?: React.Key[];
  };
  title?: TableProps<RecordType>['title'];
  footer?: TableProps<RecordType>['footer'];
  expandable?: TableProps<RecordType>['expandable'];
  query?: QueryResult<any, TablePaginationQueryVariables & any>;
  data?: Maybe<
    {
      totalCount: number;
    } & ({ nodes: RecordType[] } | { edges: RecordType[] })
  >;
  action?: (record: RecordType) => React.ReactNode;
  customizable?: boolean;
  onRowClick?: (record: RecordType, event: React.MouseEvent) => void;
  adminCommentTargetType?: AdminCommentTargetType;
};

export type ControllerChildTablePaginationProps<
  RecordType,
  Column = string,
> = Omit<ChildTablePaginationProps<RecordType, Column>, 'data' | 'query'> &
  Required<
    Pick<ChildTablePaginationProps<RecordType, Column>, 'data' | 'query'>
  >;
