import { useTranslation } from 'react-i18next';

import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  GamesTableQueryVariables,
  GamesTableRecordFragment,
  useGamesTableQuery,
} from './GamesTable.generated';

type RecordType = GamesTableRecordFragment;

type Column = 'name' | 'url' | 'startsAt' | 'endsAt' | 'isExclusive';

export function GamesTable(props: GamesTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('games');

  const query =
    propQuery ||
    useGamesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('fields.name'),
      dataIndex: ['name'],
      render: (_, game) => (
        <I18nLink to={`/Games/list/${game.id}`}>{game.name}</I18nLink>
      ),
    },
    {
      key: 'url',
      title: t('fields.url'),
      dataIndex: ['url'],
      render: (_, game) => (
        <a href={`/Games/list/${game.id}`} target="blank">
          {game.url}
        </a>
      ),
    },
    {
      key: 'startsAt',
      title: t('fields.startsAt'),
      dataIndex: ['startsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'endsAt',
      title: t('fields.endsAt'),
      dataIndex: ['endsAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'isExclusive',
      title: t('fields.isExclusive'),
      dataIndex: ['isExclusive'],
      sorter: true,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="GamesTable"
        query={query}
        columns={columns}
        data={propData || query.data?.gamesPagination}
        {...rest}
      />
    </div>
  );
}

export type GamesTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  filter?: GamesTableQueryVariables['filter'];
  sorter?: GamesTableQueryVariables['sorter'];
};
