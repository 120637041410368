import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import classnames from 'classnames';

import styles from './FormTextArea.module.css';

export const FormTextArea: React.FC<FormTextAreaProps> = (props) => {
  const { className, name, ...rest } = props;
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input.TextArea
          {...rest}
          name={field.name}
          value={field.value}
          onChange={(...e) => {
            field.onChange(...e);

            if (errors[name]) {
              trigger(name);
            }
          }}
          onBlur={field.onBlur}
          ref={field.ref}
          className={classnames(styles.root, className)}
        />
      )}
    />
  );
};

export type FormTextAreaProps = Omit<
  TextAreaProps,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string;
  className?: string;
};
