import { useTranslation } from 'react-i18next';
import { Card, Collapse, Table } from 'antd';
import classnames from 'classnames';
import { sumBy } from 'lodash';

import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';

import styles from './OrderSummaryCard.module.css';

import { OrderSummaryCardFragment } from './OrderSummaryCard.generated';

export const OrderSummaryCard: React.FC<OrderSummaryCardProps> = (props) => {
  const { className, order } = props;
  const { t } = useTranslation('orders');

  const totalDiscountSubventionsAmount = sumBy(
    order.orderItemPagination.nodes,
    (orderItem) => orderItem.totalDiscountSubventionAmount,
  );
  const fundSubventions = order.subventionConsumptionsPagination.nodes.filter(
    (node) => ['SubventionFund'].includes(node.subvention.__typename),
  );
  const fundSubventionsAmount = fundSubventions.reduce(
    (accumulator, current) => accumulator + current.amount,
    0,
  );

  const amountDataSource = [];
  if (totalDiscountSubventionsAmount || order.discountsAmount) {
    amountDataSource.push({
      key: 'initialTotalAmount',
      label: t('OrderSummaryCard.table.rows.initialTotalAmount', 'Initial'),
      valueBeforeTaxes: '',
      valueAfterTaxes: (
        <TableRendererCurrency
          value={{
            amount: order.pricesAmountAfterTaxes + order.discountsAmount,
            currency: order.currency,
          }}
        />
      ),
    });

    if (order.discountsAmount) {
      amountDataSource.push({
        key: 'discountsAmount',
        label: t(
          'OrderSummaryCard.table.rows.discountsAmount',
          'Réductions happypal',
        ),
        valueBeforeTaxes: '',
        valueAfterTaxes: (
          <TableRendererCurrency
            value={{
              amount: order.discountsAmount * -1,
              currency: order.currency,
            }}
          />
        ),
      });
    }

    if (totalDiscountSubventionsAmount) {
      amountDataSource.push({
        key: 'discountsSubventionAmount',
        label: t(
          'OrderSummaryCard.table.rows.discountsSubventionAmount',
          'Réductions tarifaires',
        ),
        valueBeforeTaxes: '',
        valueAfterTaxes: (
          <TableRendererCurrency
            value={{
              amount: totalDiscountSubventionsAmount * -1, // TODO : calculate
              currency: order.currency,
            }}
          />
        ),
      });
    }
  }

  amountDataSource.push({
    key: 'total',
    label: t('OrderSummaryCard.table.rows.total', 'Total'),
    valueBeforeTaxes: '',
    valueAfterTaxes: (
      <TableRendererCurrency
        value={{
          amount: order.pricesAmountAfterTaxes - totalDiscountSubventionsAmount,
          currency: order.currency,
        }}
      />
    ),
  });

  return (
    <Card
      title={t('OrderSummaryCard.title')}
      className={classnames(className, styles.root)}
    >
      <Collapse>
        <Collapse.Panel key={'Montants'} header="Montants">
          <Table
            size="small"
            dataSource={amountDataSource}
            rowKey={(row) => row.key}
            pagination={false}
            columns={[
              {
                title: t('OrderSummaryCard.table.columns.amounts', 'Montants'),
                key: 'name',
                dataIndex: ['label'],
                width: '70%',
              },
              {
                title: t('OrderSummaryCard.table.columns.amountAfterTaxes'),
                key: 'valueAfterTaxes',
                render: (_, { valueAfterTaxes }) => valueAfterTaxes,
                align: 'right',
              },
            ]}
          />
        </Collapse.Panel>
        {fundSubventions.length > 0 && (
          <Collapse.Panel
            key={'cagnottes'}
            header={t(
              'OrderSummaryCard.table.columns.fundSubventions',
              'Cagnottes',
            )}
          >
            <Table
              size="small"
              dataSource={[
                ...fundSubventions.map((sc) => ({
                  key: sc.subvention.id,
                  label: sc.subvention.name,
                  valueBeforeTaxes: '',
                  valueAfterTaxes: (
                    <TableRendererCurrency
                      value={{
                        amount: sc.amount,
                        currency: order.currency,
                      }}
                    />
                  ),
                })),
              ]}
              rowKey={(row) => row.key}
              pagination={false}
              columns={[
                {
                  title: t(
                    'OrderSummaryCard.table.columns.fundSubventions',
                    'Cagnottes',
                  ),
                  key: 'name',
                  dataIndex: ['label'],
                  width: '70%',
                },
                {
                  title: t('OrderSummaryCard.table.columns.amountAfterTaxes'),
                  key: 'valueAfterTaxes',
                  render: (_, { valueAfterTaxes }) => valueAfterTaxes,
                  align: 'right',
                },
              ]}
            />
          </Collapse.Panel>
        )}

        <Collapse.Panel
          key={'payments'}
          header={t('OrderSummaryCard.table.columns.payments', 'Paiements')}
        >
          <Table
            size="small"
            dataSource={[
              {
                key: 'RemaingToPay',
                label: t(
                  'OrderSummaryCard.table.rows.RemaingToPay',
                  'Restant à payer',
                ),
                valueBeforeTaxes: '',
                valueAfterTaxes: (
                  <TableRendererCurrency
                    value={{
                      amount:
                        order.pricesAmountAfterTaxes -
                        fundSubventionsAmount -
                        totalDiscountSubventionsAmount,
                      currency: order.currency,
                    }}
                  />
                ),
              },
              {
                key: 'feesAmount',
                label: t('OrderSummaryCard.table.rows.feesAmount'),
                valueBeforeTaxes: '',
                valueAfterTaxes: (
                  <TableRendererCurrency
                    value={{
                      amount: order.feesAmountAfterTaxes,
                      currency: order.currency,
                    }}
                  />
                ),
              },
              {
                key: 'totalAmount',
                label: t('OrderSummaryCard.table.rows.totalAmount'),
                valueBeforeTaxes: '',
                valueAfterTaxes: (
                  <TableRendererCurrency
                    value={{
                      amount:
                        order.totalAmountAfterTaxes - fundSubventionsAmount,
                      currency: order.currency,
                    }}
                  />
                ),
              },
            ]}
            rowKey={(row) => row.key}
            pagination={false}
            columns={[
              {
                title: t('OrderSummaryCard.table.columns.payment', 'A payer'),
                key: 'name',
                dataIndex: ['label'],
                width: '70%',
              },
              {
                title: t('OrderSummaryCard.table.columns.amountAfterTaxes'),
                key: 'valueAfterTaxes',
                render: (_, { valueAfterTaxes }) => valueAfterTaxes,
                align: 'right',
              },
            ]}
          />
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

export type OrderSummaryCardProps = {
  className?: string;
  order: OrderSummaryCardFragment;
};
