import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';

import { Happening, HappeningFundsStatus } from '@graphql/generated/types';

import {
  HappeningConfirmTransferButtonFragment,
  useHappeningConfirmFundsTransferedMutation,
} from './HappeningConfirmTransferButton.generated';

export const HappeningConfirmTransferButton: React.FC<HappeningConfirmTransferButtonProps> =
  (props) => {
    const { happening } = props;
    const { t } = useTranslation('happenings');

    const [happeningConfirmFundsTransfered] =
      useHappeningConfirmFundsTransferedMutation();

    const handleTranferFunds = (happeningId: Happening['id']) => async () => {
      await happeningConfirmFundsTransfered({
        variables: { happeningId },
      });
    };
    if (
      happening.registrationEndsAt < new Date() &&
      happening.fundsStatus &&
      [
        HappeningFundsStatus.PendingTransfer,
        HappeningFundsStatus.TransferDone,
      ].includes(happening.fundsStatus)
    ) {
      return (
        <Popconfirm
          title={t('action.transferedFundsConfirm')}
          okText={t('common:BooleanTag.true')}
          cancelText={t('common:BooleanTag.false')}
          onConfirm={handleTranferFunds(happening.id)}
          disabled={happening.fundsStatus === HappeningFundsStatus.TransferDone}
        >
          <Button
            type="primary"
            color="#"
            disabled={
              happening.fundsStatus === HappeningFundsStatus.TransferDone
            }
          >
            {happening.fundsStatus === HappeningFundsStatus.PendingTransfer
              ? t('action.confirmTransferedFunds')
              : t('action.TransferedFunds')}
          </Button>
        </Popconfirm>
      );
    }
    return null;
  };

export type HappeningConfirmTransferButtonProps = {
  className?: string;
  happening: HappeningConfirmTransferButtonFragment;
};
