import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Space, Spin } from 'antd';
import classnames from 'classnames';

import { ProductVariantCreateFormProps } from '@components/forms/ProductVariantCreateForm/ProductVariantCreateForm';
import { ProductVariantCreateModal } from '@components/modals/ProductVariantCreateModal/ProductVariantCreateModal';
import { ProductVariantsTable } from '@components/tables/ProductVariantsTable/ProductVariantsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './ProductIdVariantsView.module.css';

import { useProductIdVariantsViewQuery } from './ProductIdVariantsView.generated';

export const ProductIdVariantsView: React.FC<ProductIdVariantsViewProps> = (
  props,
) => {
  const { className, productId } = props;
  const { t, i18n } = useTranslation('productVariants');
  const history = useHistory();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const query = useProductIdVariantsViewQuery({
    variables: {
      productId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const product = query?.data?.product;

  if (query.loading && !product) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!product) {
    return <NotFoundView />;
  }

  const handleCreateSuccess: ProductVariantCreateFormProps['onSuccess'] = (
    result,
  ) => {
    history.push(
      `/${i18n.language}/product-variants/list/${result.productVariantCreate.productVariant.id}`,
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <ProductVariantsTable
        activeColumns={[
          'SKU',
          'priceWithTaxes',
          'inventoryQuantity',
          'isActivated',
        ]}
        query={query}
        data={query.data?.product?.productVariantPagination}
        title={() => (
          <>
            <Space>
              {product.catalog.provider.isInternal && (
                <Button onClick={() => setIsCreateModalOpen(true)}>
                  {t('buttons.create')}
                </Button>
              )}
            </Space>
          </>
        )}
      />

      <ProductVariantCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSuccess={handleCreateSuccess}
        productId={productId}
      />
    </div>
  );
};

export type ProductIdVariantsViewProps = {
  productId: string;
  className?: string;
};
