import { useTranslation } from 'react-i18next';
import { Table, TableProps } from 'antd';
import classNames from 'classnames';
import Dinero from 'dinero.js';
import { set } from 'lodash';

import { SortDirection } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionConsumptionStatusTag } from '@components/atoms/SubventionConsumptionStatusTag/SubventionConsumptionStatusTag';
import { generateColumnFilter } from '@components/tables/_Filters/FilterDropdown/FilterDropdown';

import styles from './SubventionTransactions.module.css';

import {
  SubventionTransactionsQuery,
  useSubventionTransactionsQuery,
} from './SubventionTransactions.generated';

type SubventionTransactionsProps = {
  className?: string;
  subventionId: string;
};

type RecordType = NonNullable<
  SubventionTransactionsQuery['subvention']
>['transactionsPagination']['nodes'][number];

export function SubventionTransactions(props: SubventionTransactionsProps) {
  const { className, subventionId } = props;
  const { t } = useTranslation(['subventions', 'common']);

  const query = useSubventionTransactionsQuery({
    variables: {
      subventionId,
      skip: 0,
      take: 10,
      sorter: {
        createdAt: SortDirection.Desc,
      },
    },
  });

  const { data, loading } = query;

  const columns: TableProps<RecordType>['columns'] = [
    {
      key: 'order.id',
      title: 'Commande',
      width: '280px',
      ellipsis: true,
      render: (_, record) => {
        switch (record.__typename) {
          case 'SubventionDiscountInAppConsumption':
          case 'SubventionFundInAppConsumption':
            return (
              <I18nLink to={`/orders/list/${record.order.id}`}>
                {record.order.id}
              </I18nLink>
            );
          case 'SubventionFundReimbursementConsumption':
            return (
              <I18nLink
                to={`/subventions/reimbursement/${record.subventionReimbursementRequest.id}`}
              >
                {record.subventionReimbursementRequest.id}
              </I18nLink>
            );
          case 'SubventionLegacyConsumption':
            return 'LEGACY ' + record.id;
        }
      },
    },
    {
      key: 'createdAt',
      title: 'Date commande',
      render: (_, record) => {
        switch (record.__typename) {
          case 'SubventionDiscountInAppConsumption':
          case 'SubventionFundInAppConsumption':
            return t('common:formats.shortDate', {
              date: record.order.createdAt,
            });
          case 'SubventionFundReimbursementConsumption':
            return t('common:formats.shortDate', {
              date: record.subventionReimbursementRequest.createdAt,
            });
          case 'SubventionLegacyConsumption':
            return 'LEGACY ' + record.id;
        }
      },
      sorter: true,
    },
    {
      key: 'fullName',
      title: "Nom de l'utilisateur",
      render: (_, record) => (
        <I18nLink to={`/users/list/${record.user.id}`}>
          {record.user.firstName} {record.user.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, `order.user.fullName`, 'search'),
    },
    {
      key: 'amount',
      title: 'Montant utilisé',
      render: (_, record) =>
        t('subventionTransactions.amount', {
          amount: Dinero({
            amount: record.amount,
            currency: 'EUR',
          }),
        }),
      sorter: true,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <SubventionConsumptionStatusTag subventionConsumptionStatus={status} />
      ),
      sorter: true,
    },
  ];

  const handleChange: TableProps<RecordType>['onChange'] = (
    { pageSize = 10, current = 1 },
    _,
    sorters,
  ) => {
    const variables = {
      skip: (current - 1) * pageSize,
      take: pageSize,
      sorter: {},
    };

    (Array.isArray(sorters) ? sorters : [sorters])
      .filter((sorter) => sorter.order && sorter.columnKey)
      .forEach((sorter) => {
        if (sorter.order === 'ascend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Asc);
        } else if (sorter.order === 'descend') {
          set(variables.sorter, sorter.columnKey as string, SortDirection.Desc);
        }
      });

    query.refetch({
      ...(query.variables || {}),
      ...variables,
    });
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Table
        loading={loading}
        className={styles.table}
        onChange={handleChange}
        columns={columns.filter((c) => 'title' in c)}
        rowKey={(record) => record?.id}
        dataSource={data?.subvention?.transactionsPagination.nodes || []}
        pagination={{
          total: data?.subvention?.transactionsPagination.totalCount || 0,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          defaultCurrent: 1,
          pageSize: query.variables?.take,
          current:
            (query.variables?.skip || 0) / (query.variables?.take || 10) + 1,
          defaultPageSize: 25,
        }}
      />
    </div>
  );
}
