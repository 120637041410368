import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Typography } from '@components/atoms/Typography/Typography';

import styles from './TableRendererDate.module.css';

export const TableRendererDate: React.FC<TableRendererDateProps> = (props) => {
  const { className, value, hideFrom } = props;
  const { t } = useTranslation();

  return (
    <div className={classnames(className, styles.root)}>
      {value && (
        <>
          <Typography.Text>
            {t('formats.date', { date: value })}
          </Typography.Text>
          {hideFrom !== true && (
            <>
              <br />
              <Typography.Text type="secondary">
                {t('formats.fromNow', { date: new Date(value) })}
              </Typography.Text>
            </>
          )}
        </>
      )}
    </div>
  );
};

export type TableRendererDateProps = {
  className?: string;
  value?: string | Date;
  hideFrom?: boolean;
};

export function TableRendererDateFactory(props: TableRendererDateProps = {}) {
  return (value: string | Date) => (
    <TableRendererDate {...props} value={value} />
  );
}
