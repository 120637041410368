import { useHistory, useRouteMatch } from 'react-router-dom';
import { DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import { format } from 'date-fns';

import { IfgRewardCodeUsage } from '@graphql/generated/types';

import {
  IfgRedemptionCardFragment,
  useIfgOrderStateViewQuery,
} from './IFGOrderStateView.generated';

type IfgOrderStateViewProps = {
  orderId?: string;
};

export const IfgOrderStateView = (props: IfgOrderStateViewProps) => {
  const { orderId } = props;
  const history = useHistory();
  const { url } = useRouteMatch();

  const { data, loading } = useIfgOrderStateViewQuery({
    skip: !orderId,
    variables: { ifgOrderId: orderId as string },
  });

  if (!orderId) {
    return (
      <div>
        <Form
          layout="vertical"
          onFinish={({ orderId }) => history.push(`${url}/${orderId}`)}
        >
          <Form.Item name="orderId" required label="Numéro de commande">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form>
      </div>
    );
  }

  if (!data && loading) return <Spin />;

  return (
    <div>
      <Typography.Title level={2}>Commande {orderId}</Typography.Title>
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 16 }}
      >
        {data?.ifgOrderRedemptions.map((redemption) => (
          <IFGRedemptionCard key={redemption.id} redemption={redemption} />
        ))}
      </div>
    </div>
  );
};

type IFGRedemptionCardProps = {
  redemption: IfgRedemptionCardFragment;
};

const IFGRedemptionCard = (props: IFGRedemptionCardProps) => {
  const { redemption } = props;

  const downloadUrl = redemption.codes.find(
    (code) => code.usage === IfgRewardCodeUsage.PrintableDocumentUrl,
  )?.value;

  const primaryCode = redemption.codes.find(
    (code) => code.usage === IfgRewardCodeUsage.Primary,
  )?.value;

  const securityCode = redemption.codes.find(
    (code) => code.usage === IfgRewardCodeUsage.Security,
  )?.value;

  return (
    <Card
      size="small"
      actions={[
        !!downloadUrl && (
          <a href={downloadUrl} key="download" target="__blank">
            <DownloadOutlined />
          </a>
        ),
        <a href={redemption.url} key="redeem" target="__blank">
          <ShareAltOutlined />
        </a>,
      ]}
    >
      <Card.Meta title={redemption.reward.name} description={redemption.id} />
      <br />
      {redemption.expirationDatetime && (
        <Card.Meta
          description={`Expire le : ${format(
            redemption.expirationDatetime,
            'dd/MM/yyyy HH:mm',
          )}`}
        />
      )}
      <Card.Meta description={`Montant: ${redemption.value.amount} €`} />
      <Card.Meta description={`Status: ${redemption.state}`} />
      <br />
      {!!primaryCode && (
        <Card.Meta description={`Code primaire: ${primaryCode}`} />
      )}
      {!!securityCode && (
        <Card.Meta description={`Code sécurité: ${securityCode}`} />
      )}
    </Card>
  );
};
