import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { Modal } from '@components/organisms/Modal/Modal';
import {
  StoresTable,
  StoresTableProps,
} from '@components/tables/StoresTable/StoresTable';

import styles from './StoreSelectModal.module.css';

export const StoreSelectModal = (props: StoreSelectModalProps) => {
  const { exclude = [], isOpen, onSubmit, onClose, filter } = props;
  const [submitLoading, setLoading] = useState(false);

  const [storeIds, setStoreIds] = useState<React.Key[]>([]);

  const { t } = useTranslation('stores');

  const handleSubmit = async () => {
    if (!onSubmit) return;

    try {
      await onSubmit?.(storeIds.map((id) => id.toString()));
      onClose();
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title=""
      onClose={onClose}
      width="100%"
      footer={
        <Button type="primary" loading={submitLoading} onClick={handleSubmit}>
          Sélectionner
        </Button>
      }
      className={styles.root}
    >
      <StoresTable
        activeColumns={[
          'address1',
          'address2',
          'zip',
          'city',
          'country',
          'province',
          'formatted',
        ]}
        selectable={{
          type: 'checkbox',
          onChange: setStoreIds,
          value: storeIds,
          isDisabled: (store) => exclude.includes(store.id),
        }}
        title={() => t('storesSelectedCount', { count: storeIds.length })}
        filter={filter}
      />
    </Modal>
  );
};

export type StoreSelectModalProps = {
  onSubmit?: (storesIds: string[]) => void | Promise<void>;
  multiple?: boolean;
  exclude?: string[];
  isOpen: boolean;
  onClose: () => void;
  filter?: StoresTableProps['filter'];
};
