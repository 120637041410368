import classnames from 'classnames';

import styles from './Card.module.css';

export const Card: React.FC<CardProps> = (props) => {
  const { className, children } = props;

  return <div className={classnames(className, styles.root)}>{children}</div>;
};

export type CardProps = {
  className?: string;
};
