import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { PublicationUpdateView } from '../PublicationUpdateView/PublicationUpdateView';

import { PublicationHeader } from '@components/organisms/PublicationHeader/PublicationHeader';
import PublicationPublishCheckbox from '@components/organisms/PublicationPublishCheckbox/PublicationPublishCheckbox';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './PublicationIdView.module.css';

import { usePublicationIdViewQuery } from './PublicationIdView.generated';

export const PublicationIdView: React.FC<PublicationIdViewProps> = (props) => {
  const { className, match } = props;

  const {
    params: { publicationId },
  } = match;

  const publicationQuery = usePublicationIdViewQuery({
    variables: { publicationId },
    notifyOnNetworkStatusChange: true,
  });

  const publication = publicationQuery?.data?.publication;

  if (publicationQuery.loading && !publication) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!publication) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <PublicationHeader publication={publication} />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route path={`${match.url}`} exact label="Modifier">
          <PublicationUpdateView publication={publication} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/settings`} label="Paramètres">
          <PublicationPublishCheckbox publicationId={publication.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/contents`} label="Contenu lié">
          Contenu lié
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type PublicationIdViewProps = RouteComponentProps<{
  publicationId: string;
}> & {
  className?: string;
};
