import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { OfferCommonFormValues } from '../OfferCommonForm/OfferCommonForm';

import { Card } from '@atoms/Card/Card';
import { FormInputNumber } from '@atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormSelect } from '@atoms/FormSelect/FormSelect';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferCouponClaimableForm.module.css';

import {
  OfferCouponClaimableFormCreateMutation,
  OfferCouponClaimableFormCreateMutationVariables,
  useOfferCouponClaimableFormCreateMutation,
} from './OfferCouponClaimableForm.generated';

type OfferCouponClaimableValues = {
  claimCountMax?: number | null;
  claimInterval: number;
};

export function OfferCouponClaimableForm(props: OfferCouponClaimableProps) {
  const { className, commonValues, onBack, ...rest } = props;

  const validationSchema = Yup.object({
    claimCountMax: Yup.number().positive().nullable(),
    claimInterval: Yup.number().positive().required(),
  });

  const form = useForm<OfferCouponClaimableValues>({
    validationSchema,
    defaultValues: {
      claimInterval: 60 * 24,
    },
  });

  const [mutation] = useOfferCouponClaimableFormCreateMutation();

  const handleSubmit: FormProps<OfferCouponClaimableValues>['onValid'] = async (
    values,
  ) => {
    const variables: OfferCouponClaimableFormCreateMutationVariables = {
      input: {
        brandId: commonValues.brandId,
        campaignId: commonValues.campaignId,
        categoryIds: commonValues.categoryIds,
        localeId: commonValues.localeId,
        url: commonValues.url,
        name: commonValues.name,
        descriptionDelta: commonValues.description,
        storeIds: commonValues.storeIds,
        claimCountMax: values.claimCountMax,
        claimInterval: values.claimInterval,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferCouponClaimableForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.interval}
          label="Interval de revendication"
          help="Temps à attendre entre deux revendications"
          name="claimInterval"
        >
          <FormSelect
            name="claimInterval"
            options={[
              {
                label: 'Un toutes les heures',
                value: 60,
              },
              {
                label: 'Un tous les jours',
                value: 60 * 24,
              },
              {
                label: 'Un toutes les semaines',
                value: 60 * 24 * 7,
              },
              {
                label: 'Un toutes les deux semaines',
                value: 60 * 24 * 14,
              },
              {
                label: 'Un tous les mois',
                value: 60 * 24 * 30,
              },
              {
                label: 'Un tous les trimestres',
                value: 60 * 24 * 30 * 3,
              },
              {
                label: 'Un tous les ans',
                value: 60 * 24 * 30 * 12,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.count}
          label="Revendication maximum"
          help="Nombre de fois qu'un utilisateur peut revendiquer un coupon"
          name="claimCountMax"
        >
          <FormInputNumber min={0} name="claimCountMax" />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer l&apos;offre
          </Button>
          {!!onBack && (
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onBack}
              htmlType="button"
            >
              Revenir en arrière
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type OfferCouponClaimableProps = ChildFormProps<
  OfferCouponClaimableValues,
  OfferCouponClaimableFormCreateMutation
> & {
  className?: string;
  commonValues: OfferCommonFormValues;
  onBack?: () => void;
};
