import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm, Tooltip, Typography } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { OfferStatusTag } from '@components/atoms/OfferStatusTag/OfferStatusTag';
import { CouponClaimableUpdateModal } from '@components/modals/CouponClaimableUpdateModal/CouponClaimableUpdateModal';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import styles from './CouponClaimablesTable.module.css';

import {
  CouponClaimableDeleteMutationVariables,
  CouponClaimablesTableQueryVariables,
  CouponClaimablesTableRecordFragment,
  useCouponClaimableDeleteMutation,
  useCouponClaimablesTableQuery,
} from './CouponClaimablesTable.generated';

type RecordType = CouponClaimablesTableRecordFragment;

type Column =
  | 'brand'
  | 'offer'
  | 'value'
  | 'expiresAt'
  | 'claimedAsCompany'
  | 'claimedBy'
  | 'claimedAt'
  | 'offerStatus'
  | 'offerExpiresAt'
  | 'actions';

export function CouponClaimablesTable(props: CouponClaimableTableProps) {
  const {
    className,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('coupons');
  const [updateableCoupon, setUpdateableCoupon] =
    useState<CouponClaimablesTableRecordFragment | null>(null);

  const { Paragraph } = Typography;

  const query =
    propQuery ||
    useCouponClaimablesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
      },
    });

  const [deleteMutation] = useCouponClaimableDeleteMutation({
    refetchQueries: ['OfferIdCouponClaimablesView'],
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'brand',
      title: t('table.headers.brand'),
      dataIndex: ['offer', 'brand', 'name'],
      render: (name, node) =>
        node.offer?.brand && (
          <I18nLink to={`/brands/list/${node.offer.brand.id}`}>{name}</I18nLink>
        ),
    },
    {
      key: 'offer',
      title: t('table.headers.offer'),
      dataIndex: ['offer', 'name'],
      render: (name, node) =>
        node.offer && (
          <I18nLink to={`/offers/list/${node.offer.id}`}>{name}</I18nLink>
        ),
    },
    {
      key: 'value',
      title: t('table.headers.value'),
      dataIndex: ['value'],
      render: (value, _) => (
        <Paragraph copyable={{ text: value }}>{value}</Paragraph>
      ),
    },
    {
      key: 'expiresAt',
      title: t('table.headers.expiresAt'),
      dataIndex: ['expiresAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'claimedAsCompany',
      title: t('table.headers.claimedAsCompany'),
      ellipsis: true,
      sorter: true,
      render: (name, node) =>
        node.claimedAsCompany && (
          <I18nLink to={`/companies/list/${node.claimedAsCompany.id}`}>
            {node.claimedAsCompany.name}
          </I18nLink>
        ),
      ...generateColumnFilter(query, `company.name`, 'search'),
    },
    {
      key: 'claimedBy',
      title: t('table.headers.claimedBy'),
      ellipsis: true,
      sorter: true,
      render: (name, node) =>
        node.claimedBy && (
          <I18nLink to={`/users/list/${node.claimedBy.id}`}>
            {node.claimedBy.email}
          </I18nLink>
        ),
      ...generateColumnFilter(query, `user.email`, 'search'),
    },
    {
      key: 'claimedAt',
      title: t('table.headers.claimedAt'),
      dataIndex: ['claimedAt'],
      sorter: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'offerStatus',
      title: t('table.headers.offerStatus'),
      render: (_, node) => <OfferStatusTag offer={node.offer} />,
    },
    {
      key: 'offerExpiresAt',
      title: t('table.headers.offerExpiresAt'),
      dataIndex: ['offer', 'expiresAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'actions',
      title: t('table.headers.actions'),
      render: (_, node) => {
        return (
          <div className={styles.actions}>
            <Tooltip title={`Modifier ${node.value}`}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => setUpdateableCoupon(node)}
              />
            </Tooltip>
            {!node.claimedAt ? (
              <Popconfirm
                title={t('confirmation.delete.title')}
                okText={t('confirmation.delete.button')}
                onConfirm={() => couponClaimableDelete(node.id)}
                okType="danger"
              >
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CouponClaimableTable"
        query={query}
        columns={columns}
        data={propData || query.data?.couponClaimablesPagination}
        {...rest}
      />

      {updateableCoupon && (
        <CouponClaimableUpdateModal
          couponClaimable={updateableCoupon}
          isOpen={!!updateableCoupon}
          onClose={() => setUpdateableCoupon(null)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      )}
    </div>
  );

  async function couponClaimableDelete(couponClaimableId: string) {
    const variables: CouponClaimableDeleteMutationVariables = {
      couponClaimableId: couponClaimableId,
    };

    try {
      const { data } = await deleteMutation({ variables });

      message.success(t('delete.success'));

      return data;
    } catch (err) {
      if (err instanceof ApolloError) {
        message.error(err.message.replace('GraphQL error: ', ''));
      } else {
        message.error('error:default');
      }
    }
  }
}

export type CouponClaimableTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: CouponClaimablesTableQueryVariables['sorter'];
};
