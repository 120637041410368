import { useState } from 'react';
import { Button, Input, Space, Spin, Switch, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import { difference, uniqBy } from 'lodash';

import {
  CampaignStatus,
  OfferStatus,
  OfferType,
} from '@graphql/generated/types';

import { CampaignStatusTag } from '@components/atoms/CampaignStatusTag/CampaignStatusTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { OfferStatusTag } from '@components/atoms/OfferStatusTag/OfferStatusTag';

import {
  SubventionSchemaOffersViewRowFragment,
  useSubventionSchemaOffersViewAddOffersMutation,
  useSubventionSchemaOffersViewCategoriesQuery,
  useSubventionSchemaOffersViewOffersQuery,
  useSubventionSchemaOffersViewRemoveOffersMutation,
  useSubventionSchemaOffersViewSubventionSchemaQuery,
  useSubventionSchemaOffersViewViewerQuery,
} from './SubventionSchemaOffersView.generated';

export const SubventionSchemaOffersView = (
  props: SubventionSchemaOffersViewProps,
) => {
  const { subventionSchemaId } = props;

  const { data, loading, refetch } =
    useSubventionSchemaOffersViewSubventionSchemaQuery({
      variables: { subventionSchemaId },
      onCompleted: (data) => {
        setSelectedOfferIds(data.subventionSchema?.offerIds ?? []);
      },
    });

  const viewerQuery = useSubventionSchemaOffersViewViewerQuery();

  const isSuperCare = viewerQuery.data?.viewer?.isSuperCare === true;

  const { data: dataCategories } = useSubventionSchemaOffersViewCategoriesQuery(
    {},
  );

  const [removeMutation, removeState] =
    useSubventionSchemaOffersViewRemoveOffersMutation();
  const [addMutation, addState] =
    useSubventionSchemaOffersViewAddOffersMutation();
  const submitLoading = removeState.loading || addState.loading;

  const savedOfferIds = data?.subventionSchema?.offerIds ?? [];
  const [selectedOfferIds, setSelectedOfferIds] = useState<string[]>([]);
  const addedOfferIds = difference(selectedOfferIds, savedOfferIds);
  const removedOfferIds = difference(savedOfferIds, selectedOfferIds);

  const categories =
    dataCategories?.categoriesPagination.edges.map((e) => e.node) ?? [];
  const [showAll, setShowAll] = useState(true);

  const [filters, setFilters] = useState<{
    campaignStatus?: CampaignStatus[];
    offerStatuses?: OfferStatus[];
    categoryIds?: string[];
    search?: string;
  }>({});

  const offersQuery = useSubventionSchemaOffersViewOffersQuery({
    variables: {
      take: 50,
      skip: 0,
      filter: {
        id: showAll ? undefined : { in: savedOfferIds },
        type: { in: [OfferType.Ticket, OfferType.GiftCard] },
        campaign: filters.campaignStatus
          ? { status: { in: filters.campaignStatus } }
          : undefined,
        status: filters.offerStatuses
          ? { in: filters.offerStatuses }
          : undefined,
        categories: filters.categoryIds
          ? {
              SOME: {
                OR: [
                  { id: { in: filters.categoryIds } },
                  { parent: { id: { in: filters.categoryIds } } },
                ],
              },
            }
          : undefined,
        AND: filters.search?.trim()
          ? [
              { brand: { name: { search: filters.search.trim() } } },
              { campaign: { name: { search: filters.search.trim() } } },
              { name: { search: filters.search.trim() } },
            ]
          : undefined,
      },
    },
  });

  const handleFilterChange = (filters: Record<string, FilterValue | null>) => {
    setFilters({
      campaignStatus:
        (filters['node.campaign.status'] as CampaignStatus[]) ?? undefined,
      offerStatuses: (filters['node.status'] as OfferStatus[]) ?? undefined,
      categoryIds:
        (filters['node.categoriesPagination.edges'] as string[]) ?? undefined,
    });
  };

  const subventionSchema = data?.subventionSchema ?? null;

  const columns: ColumnsType<SubventionSchemaOffersViewRowFragment> = [
    {
      title: 'Offre',
      dataIndex: ['node', 'name'],
      width: 120,
      ellipsis: { showTitle: true },
      render: (name, edge) => (
        <I18nLink to={`/offers/list/${edge.node.id}`}>{name}</I18nLink>
      ),
    },
    {
      title: 'Catégories parent',
      dataIndex: ['node', 'categoriesPagination', 'edges'],
      ellipsis: { showTitle: true },
      render: (_, row) => {
        const categories = uniqBy(
          row.node.categoriesPagination.edges.map(
            (e) => e.node.parent ?? e.node,
          ) ?? [],
          (cat) => cat.id,
        );

        return (
          <div>
            {categories.map((cat) => (
              <div key={cat.id}>{cat.name}</div>
            ))}
          </div>
        );
      },
      filters: categories.map((c) => ({ text: c.name, value: c.id })),
      filterSearch: true,
    },
    {
      title: 'Marque',
      width: 200,
      dataIndex: ['node', 'brand', 'name'],
      ellipsis: { showTitle: true },
      render: (name, edge) => (
        <I18nLink to={`/brands/list/${edge.node.brand.id}`}>{name}</I18nLink>
      ),
    },
    {
      title: 'Campagne',
      dataIndex: ['node', 'campaign', 'name'],
      width: 200,
      ellipsis: { showTitle: true },
      render: (name, edge) =>
        edge.node.campaign ? (
          <I18nLink to={`/campaigns/list/${edge.node.campaign.id}`}>
            {name}
          </I18nLink>
        ) : (
          '-'
        ),
    },
    {
      title: 'Status offre',
      dataIndex: ['node', 'status'],
      width: 140,
      render: (_, edge) => <OfferStatusTag offer={edge.node} />,
      filters: [
        {
          text: 'Brouillon',
          value: OfferStatus.Draft,
        },
        {
          text: 'Publiée',
          value: OfferStatus.Published,
        },
        {
          text: 'Non publié',
          value: OfferStatus.Unpublished,
        },
      ],
    },
    {
      title: 'Status campagne',
      dataIndex: ['node', 'campaign', 'status'],
      width: 170,
      render: (_, edge) =>
        edge.node.campaign ? (
          <CampaignStatusTag status={edge.node.campaign.status} />
        ) : (
          '-'
        ),
      filters: [
        {
          text: 'Brouillon',
          value: OfferStatus.Draft,
        },
        {
          text: 'Publiée',
          value: OfferStatus.Published,
        },
        {
          text: 'Non publié',
          value: OfferStatus.Unpublished,
        },
      ],
    },
  ];

  if (loading && !subventionSchema) return <Spin />;
  else if (!subventionSchema) return <div>Not found</div>;

  const handleSubmit = async () => {
    if (!isSuperCare) return;

    if (addedOfferIds.length > 0) {
      await addMutation({
        variables: {
          subventionSchemaId,
          input: {
            offerIds: addedOfferIds,
          },
        },
      });
    }

    if (removedOfferIds.length > 0) {
      await removeMutation({
        variables: {
          subventionSchemaId,
          input: {
            offerIds: removedOfferIds,
          },
        },
      });
    }

    await refetch();
  };

  return (
    <Table
      rowSelection={{
        fixed: true,
        selectedRowKeys: selectedOfferIds,
        onChange: (selectedRowKeys) =>
          setSelectedOfferIds(selectedRowKeys as string[]),
        preserveSelectedRowKeys: true,
        getCheckboxProps: () => ({ disabled: !isSuperCare }),
      }}
      rowKey={(row) => row.node.id}
      columns={columns}
      dataSource={offersQuery.data?.offersPagination.edges ?? []}
      loading={offersQuery.loading}
      pagination={{
        current: offersQuery.variables?.skip
          ? 1 + offersQuery.variables.skip / offersQuery.variables.take
          : 1,
        pageSize: 50,
        total: offersQuery.data?.offersPagination.totalCount ?? 0,
        showSizeChanger: false,
        showTotal: (total) => `Total ${total} offres`,
        onChange(page, pageSize) {
          offersQuery.refetch({
            filter: offersQuery.variables?.filter,
            skip: (page - 1) * pageSize,
            take: pageSize,
          });
        },
      }}
      onChange={(pagination, filters) => {
        handleFilterChange(filters);
      }}
      scroll={{ y: 430 }}
      title={() => (
        <Space>
          <Space>
            <Switch checked={showAll} onChange={setShowAll} />
            <Typography.Text>
              Mode: {showAll ? 'Ajout' : 'Retrait'}{' '}
            </Typography.Text>
          </Space>
          <Input.Search
            placeholder="Rechercher..."
            allowClear
            enterButton="Search"
            onSearch={(val) =>
              setFilters((filters) => ({
                ...filters,
                search: val?.trim() || undefined,
              }))
            }
          />
          {isSuperCare && (
            <Space>
              <div>{addedOfferIds.length} ajouts</div>
              <div>{removedOfferIds.length} retraits</div>
              <Button
                loading={submitLoading}
                onClick={handleSubmit}
                type="primary"
                disabled={
                  !isSuperCare ||
                  addedOfferIds.length + removedOfferIds.length === 0
                }
              >
                Enregistrer
              </Button>
            </Space>
          )}
        </Space>
      )}
    />
  );
};

export type SubventionSchemaOffersViewProps = {
  subventionSchemaId: string;
};
