import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';

import {
  CompanyFeatureConfigConnectorFrequencyButtonConnectorFragment,
  useCompanyFeatureConfigConnectorFrequencyButtonMutation,
} from './CompanyFeatureConfigConnectorFrequencyButton.generated';

interface CompanyFeatureConfigConnectorFrequencyButtonProps {
  connector: CompanyFeatureConfigConnectorFrequencyButtonConnectorFragment;
  companyId: string;
}

const MANUAL_FREQUENCY = 'MANUAL';

export const CompanyFeatureConfigConnectorFrequencyButton = (
  props: CompanyFeatureConfigConnectorFrequencyButtonProps,
) => {
  const { connector, companyId } = props;
  const [frequencyMutation, frequencyState] =
    useCompanyFeatureConfigConnectorFrequencyButtonMutation();

  const handleFrequencyChange = async (frequency: string) => {
    await frequencyMutation({
      variables: {
        companyId,
        connectorId: connector.id,
        input: {
          frequency: frequency === MANUAL_FREQUENCY ? null : frequency,
        },
      },
    });
  };

  return (
    <Dropdown
      disabled={frequencyState.loading}
      menu={{
        items: [
          {
            label: 'Manuelle',
            key: MANUAL_FREQUENCY,
          },
          {
            label: 'Toutes les 4 heures',
            key: '0 */4 * * *',
          },
          {
            label: 'Tous les jours',
            key: '0 0 * * *',
          },
          {
            label: 'Tous les jours de semaine',
            key: '0 0 * * 1-5',
          },
          {
            label: 'Tous les lundis',
            key: '0 0 * * MON',
          },
          {
            label: 'Tous les 1er du mois',
            key: '0 0 1 * *',
          },
        ],
        onClick: (info) => handleFrequencyChange(info.key),
      }}
    >
      <Button loading={frequencyState.loading} icon={<DownOutlined />}>
        Fréquence
      </Button>
    </Dropdown>
  );
};
