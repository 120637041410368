import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Card } from 'antd';
import Title from 'antd/lib/typography/Title';

import { OrderSteps } from '../OrderSteps/OrderSteps';

import { OrderStatus } from '@graphql/generated/types';

import { OrderRefundCreateModal } from '@components/modals/OrderRefundCreateModal/OrderRefundCreateModal';

import { OrderHeaderFragment } from './OrderHeader.generated';

const OrderHeaderCard: React.FC<OrderHeaderProps> = ({
  order,
  isSuperCare,
}) => {
  const { t } = useTranslation('orders');
  const [isOrderRefundModalOpen, setOrderRefundModalOpen] = useState(false);

  return (
    <Card
      title={
        <Title level={4}>
          {t('orderTitle', {
            identifier: order.invoice?.identifier,
            defaultValue: 'Commande avec le numéro de facture {{identifier}}',
          })}
        </Title>
      }
    >
      <OrderSteps order={order} />
      {isSuperCare && order.status !== OrderStatus.RefundedFull && (
        <Button onClick={() => setOrderRefundModalOpen(true)}>
          {t('reimbursementCreate', 'Créer un remboursement')}
        </Button>
      )}
      <OrderRefundCreateModal
        isOpen={isOrderRefundModalOpen}
        onClose={() => setOrderRefundModalOpen(false)}
        orderId={order.id}
      />
    </Card>
  );
};

export const OrderHeader: React.FC<OrderHeaderProps> = ({
  order,
  isSuperCare,
}) => {
  const { t } = useTranslation('orders');

  if (
    [OrderStatus.RefundedFull, OrderStatus.RefundedPartial].includes(
      order.status,
    )
  ) {
    const refundStyles: Partial<
      Record<OrderStatus, { color: string; label: string }>
    > = {
      REFUNDED_FULL: {
        color: 'red',
        label: t('status.refundedFull'),
      },
      REFUNDED_PARTIAL: {
        color: 'orange',
        label: t('status.refundedPartial'),
      },
    };

    return (
      <Badge.Ribbon
        text={refundStyles[order.status]?.label}
        color={refundStyles[order.status]?.color}
      >
        <OrderHeaderCard order={order} isSuperCare={isSuperCare} />
      </Badge.Ribbon>
    );
  }

  return <OrderHeaderCard order={order} isSuperCare={isSuperCare} />;
};

export type OrderHeaderProps = {
  className?: string;
  order: OrderHeaderFragment;
  isSuperCare?: boolean;
};
