import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  CampaignSelectionCreateForm,
  CampaignSelectionCreateFormProps,
} from '@components/forms/campaignSelectionCreateForm/CampaignSelectionCreateForm';
import { CampaignCreateFormProps } from '@forms/CampaignCreateForm/CampaignCreateForm';

import styles from './CampaignSelectionCreateView.module.css';

type Props = {
  className?: string;
};

export const CampaignSelectionCreateView = ({ className }: Props) => {
  const { t, i18n } = useTranslation('campaignSelections');
  const history = useHistory();
  const location = useLocation();

  const handleSuccess: CampaignSelectionCreateFormProps['onSuccess'] = (
    result,
  ) => {
    history.push(
      `/${i18n.language}/campaign-selections/list/${result.campaignSelectionCreate.campaignSelection.id}`,
    );
  };

  const defaultValues = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as CampaignCreateFormProps['defaultValues'];

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <CampaignSelectionCreateForm
          defaultValues={defaultValues}
          onSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};
