import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  CompanyCommercialPackage,
  CompanyPersona,
  User,
  UserRole,
} from '@graphql/generated/types';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectProvince } from '@components/atoms/FormSelectProvince/FormSelectProvince';
import { FormSelectUser } from '@components/atoms/FormSelectUser/FormSelectUser';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyUpdateForm.module.css';

import {
  CompanyUpdateFormFragment,
  CompanyUpdateFormUpdateMutation,
  CompanyUpdateFormUpdateMutationVariables,
  useCompanyUpdateFormUpdateMutation,
} from './CompanyUpdateForm.generated';

export type CompanyUpdateFormValues = {
  name: string;
  displayName?: string | null;
  email?: string | null;
  color?: string | null;
  isDemo: boolean;
  address?: {
    address1: string;
    address2?: string | null;
    city?: string | null;
    zip: string;
    country: string;
    province?: string | null;
  } | null;
  thresholdForDiscounts?: number | null;
  thresholdForFunds?: number | null;
  chargebeeId?: string | null;
  hubspotId?: string | null;
  commercialPackage?: CompanyCommercialPackage | null;
  accountManagerId?: User['id'] | null;
  persona?: CompanyPersona | null;
  intraCommunityVat?: string | null;
};

export function CompanyUpdateForm(props: CompanyUpdateFormProps) {
  const { className, company, ...rest } = props;

  const { t } = useTranslation(['companies', 'common']);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    displayName: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    color: Yup.string()
      .matches(/^#?([0-9A-F]{3}){1,2}$/i, {
        message: 'Invalid color',
        excludeEmptyString: true,
      })
      .nullable(),
    isDemo: Yup.boolean().required(),
    address: Yup.object()
      .shape(
        {
          address1: Yup.string().when(
            ['address2', 'city', 'zip', 'country', 'province'],
            {
              is: (
                address2: string,
                city: string,
                zip: string,
                country: string,
                province: string,
              ) => !!address2 || !!city || !!zip || !!country || !!province,
              then: Yup.string().required(),
            },
          ),
          address2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          zip: Yup.string().when(
            ['address1', 'address2', 'city', 'country', 'province'],
            {
              is: (
                address1: string,
                address2: string,
                city: string,
                country: string,
                province: string,
              ) =>
                !!address1 || !!address2 || !!city || !!country || !!province,
              then: Yup.string().required(),
            },
          ),
          country: Yup.string().when(
            ['address1', 'address2', 'city', 'zip', 'province'],
            {
              is: (
                address1: string,
                address2: string,
                city: string,
                zip: string,
                province: string,
              ) => !!address1 || !!address2 || !!city || !!zip || !!province,
              then: Yup.string().required(),
            },
          ),
          province: Yup.string().nullable(),
        },
        [
          ['address1', 'address2'],
          ['address1', 'city'],
          ['address1', 'zip'],
          ['address1', 'country'],
          ['address1', 'province'],

          ['address2', 'city'],
          ['address2', 'zip'],
          ['address2', 'country'],
          ['address2', 'province'],

          ['city', 'zip'],
          ['city', 'country'],
          ['city', 'province'],

          ['zip', 'country'],
          ['zip', 'province'],

          ['country', 'province'],
        ],
      )
      .nullable()
      .required(),
    thresholdForDiscounts: Yup.number().min(0).max(100).nullable(),
    thresholdForFunds: Yup.number().min(0).max(100).nullable(),
    chargebeeId: Yup.string().nullable(),
    hubspotId: Yup.string().when('isDemo', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(),
    }),
    commercialPackage: Yup.mixed()
      .nullable()
      .oneOf([null, ...Object.values(CompanyCommercialPackage)]),
    accountManagerId: Yup.string(),
    persona: Yup.mixed().oneOf(Object.values(CompanyPersona)),
    intraCommunityVat: Yup.string().nullable(),
  }).required();

  const form = useForm<CompanyUpdateFormValues>({
    validationSchema,
    defaultValues: {
      name: company.name,
      displayName: company.displayName,
      email: company.email,
      color: company.color,
      isDemo: company.isDemo,
      address: {
        address1: company.address?.address1,
        address2: company.address?.address2,
        city: company.address?.city,
        zip: company.address?.zip,
        country: company.address?.country,
        province: company.address?.province,
      },
      thresholdForDiscounts: company.thresholdForDiscounts,
      thresholdForFunds: company.thresholdForFunds,
      chargebeeId: company.chargebeeId,
      hubspotId: company.hubspotId,
      accountManagerId: company.accountManager?.id,
      commercialPackage: company.commercialPackage,
      persona: company.persona,
      intraCommunityVat: company.intraCommunityVat,
    },
  });

  const [mutation] = useCompanyUpdateFormUpdateMutation();

  const handleSubmit: FormProps<CompanyUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!company) return;

    const variables: CompanyUpdateFormUpdateMutationVariables = {
      companyId: company.id,
      input: {
        name: values.name.trim(),
        displayName: values.displayName?.trim() || null,
        email: values.email?.trim() || null,
        color: values.color?.trim() || null,
        isDemo: values.isDemo,
        address: !values.address?.address1
          ? null
          : {
              address1: values.address.address1,
              address2: values.address.address2?.trim() || null,
              city: values.address.city?.trim() || null,
              zip: values.address.zip,
              country: values.address.country,
              province: values.address.province?.trim() || null,
            },
        thresholdForDiscounts: values.thresholdForDiscounts ?? null,
        thresholdForFunds: values.thresholdForFunds ?? null,
        chargebeeId: values.chargebeeId?.trim() ?? null,
        hubspotId: values.hubspotId?.trim() ?? null,
        commercialPackage: values.commercialPackage ?? null,
        persona: values.persona,
        accountManagerId: values.accountManagerId ?? null,
        intraCommunityVat: values.intraCommunityVat?.trim() ?? null,
      },
    };

    try {
      const { data } = await mutation({ variables });

      await message.success(t('common:messages.success.updated'));

      return data;
    } catch (err) {
      if (err instanceof ApolloError) {
        message.error(err.message.replace('GraphQL error: ', ''));
      } else {
        message.error('Une erreur est survenue');
      }
    }
  };

  const { address } = form.getValues();
  const country = address?.country;

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('companyUpdateForm.fields.name.label')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.displayName}
          label={t('companyUpdateForm.fields.displayName.label')}
          name="displayName"
        >
          <FormInput name="displayName" />
        </FormItem>

        <FormItem
          className={styles.isDemo}
          required
          label={t('fields.isDemo')}
          name="isDemo"
        >
          <FormCheckbox name="isDemo" />
        </FormItem>

        <FormItem
          className={styles.email}
          required
          label={t('companyUpdateForm.fields.email.label')}
          name="email"
        >
          <FormInput name="email" />
        </FormItem>

        <FormItem
          className={styles.color}
          required
          label={t('companyUpdateForm.fields.color.label')}
          name="color"
        >
          <FormInput name="color" type="color" />
        </FormItem>

        <FormItem
          className={styles.thresholdForDiscounts}
          label={t('companyCreateForm.fields.thresholdForDiscounts.label')}
          name="thresholdForDiscounts"
        >
          <FormInputNumber addonAfter="%" name="thresholdForDiscounts" />
        </FormItem>

        <FormItem
          className={styles.thresholdForFunds}
          label={t('companyCreateForm.fields.thresholdForFunds.label')}
          name="thresholdForFunds"
        >
          <FormInputNumber addonAfter="%" name="thresholdForFunds" />
        </FormItem>

        <FormItem
          className={styles.address1}
          required
          label={t('companyUpdateForm.fields.address1.label')}
          name="address.address1"
        >
          <FormInput name="address.address1" />
        </FormItem>
        <FormItem
          className={styles.address2}
          required
          label={t('companyUpdateForm.fields.address2.label')}
          name="address.address2"
        >
          <FormInput name="address.address2" />
        </FormItem>
        <FormItem
          className={styles.zip}
          required
          label="Code postal"
          name={t('companyUpdateForm.fields.zip.label')}
        >
          <FormInput name="address.zip" />
        </FormItem>
        <FormItem
          className={styles.city}
          required
          label="Ville"
          name={t('companyUpdateForm.fields.city.label')}
        >
          <FormInput name="address.city" />
        </FormItem>
        <FormItem
          className={styles.country}
          required
          label="Pays"
          name={t('companyUpdateForm.fields.country.label')}
        >
          <FormSelectCountry name="address.country" />
        </FormItem>
        <FormItem
          className={styles.province}
          required
          label="Région"
          name={t('companyUpdateForm.fields.province.label')}
          dependsOn={['country']}
        >
          <FormSelectProvince
            disabled={!country}
            countryCode={country}
            name="address.province"
          />
        </FormItem>
        <FormItem
          className={styles.chargebeeId}
          label={t('companyUpdateForm.fields.chargebeeId.label')}
          name="chargebeeId"
        >
          <FormInput name="chargebeeId" />
        </FormItem>
        <FormItem
          className={styles.hubspotId}
          label="Identifiant Hubspot"
          name="hubspotId"
          required={!form.getValues().isDemo}
        >
          <FormInput name="hubspotId" />
        </FormItem>
        <FormItem
          className={styles.intraCommunityVat}
          label={t('companyUpdateForm.fields.intraCommunityVat.label')}
          name="intraCommunityVat"
        >
          <FormInput name="intraCommunityVat" />
        </FormItem>
        <FormItem
          className={styles.commercialPackage}
          label={t('companyUpdateForm.fields.commercialPackage.label')}
          name="commercialPackage"
        >
          <FormSelect
            clearable
            name="commercialPackage"
            options={[
              {
                label: t('fields.commercialPackage.values.premium'),
                value: CompanyCommercialPackage.Premium,
              },
              {
                label: t('fields.commercialPackage.values.premiumPlus'),
                value: CompanyCommercialPackage.PremiumPlus,
              },
              {
                label: t('fields.commercialPackage.values.standard'),
                value: CompanyCommercialPackage.Standard,
              },
              {
                label: t('fields.commercialPackage.values.starter'),
                value: CompanyCommercialPackage.Starter,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.persona}
          label={t('companyUpdateForm.fields.persona.label')}
          name="persona"
          required
        >
          <FormSelect
            name="persona"
            options={[
              {
                label: t('fields.companyPersona.values.esc'),
                value: CompanyPersona.Esc,
              },
              {
                label: t('fields.companyPersona.values.hr'),
                value: CompanyPersona.Hr,
              },
              {
                label: t('fields.companyPersona.values.other'),
                value: CompanyPersona.Other,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.accountManagerId}
          label={t('companyUpdateForm.fields.accountManagerId.label')}
          name="accountManagerId"
        >
          <FormSelectUser
            clearable
            name="accountManagerId"
            filter={{ role: { is: UserRole.Superadmin } }}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('companyUpdateForm.buttons.submit.label')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CompanyUpdateFormProps = ChildFormProps<
  CompanyUpdateFormValues,
  CompanyUpdateFormUpdateMutation
> & {
  className?: string;
  company: CompanyUpdateFormFragment;
};
