import { DeepPartial } from 'react-hook-form';

import { OrderRefundCreateFormValue } from '../OrderRefundCreateForm';
import {
  OrderRefundCreateFormOrderFragment,
  OrderRefundCreateFormOrderItemFragment,
} from '../OrderRefundCreateForm.generated';

type RefundableAmounts = {
  total: number;
  stripe: number;
  discount: number;
  fund: number;
  fees: number;
};

export function getRefundableAmounts(
  order: OrderRefundCreateFormOrderFragment,
  orderItems: OrderRefundCreateFormOrderItemFragment[],
  items?: DeepPartial<OrderRefundCreateFormValue['items']>,
): RefundableAmounts {
  if (!items?.length) {
    return {
      total: 0,
      fees: 0,
      stripe: 0,
      discount: 0,
      fund: 0,
    };
  }

  const { discountAmount, fundAmount, itemsAmount } = items.reduce(
    (amounts, item) => {
      if (!item?.orderItemId) {
        return amounts;
      }

      const orderItem = orderItems.find(
        (orderItem) => orderItem.id === item?.orderItemId,
      );

      if (!orderItem) {
        return amounts;
      }

      amounts.discountAmount +=
        (item.quantity || 0) * orderItem.discountSubventionAmount;

      amounts.itemsAmount +=
        (item.quantity || 0) * orderItem.unitPriceAfterTaxes;

      amounts.fundAmount += orderItem.subventionPagination.nodes.length
        ? (item.quantity || 0) * orderItem.unitPriceAfterTaxes
        : 0;

      return amounts;
    },
    { discountAmount: 0, fundAmount: 0, itemsAmount: 0 },
  );

  const totalAmount = itemsAmount - discountAmount;

  let stripeAmount = 0;

  if (order.paymentIntent?.amount) {
    stripeAmount = Math.min(
      totalAmount,
      order.paymentIntent.amount - order.feesAmountAfterTaxes,
    );
  }

  return {
    total: totalAmount,
    fees: order.feesAmountAfterTaxes,
    stripe: stripeAmount,
    discount: discountAmount,
    fund: fundAmount,
  };
}
