import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Spin } from 'antd';
import classnames from 'classnames';

import { OfferCouponClaimableUpdateForm } from '@components/forms/OfferCouponClaimableUpdateForm/OfferCouponClaimableUpdateForm';
import { CouponClaimablesCreateModal } from '@components/modals/CouponClaimablesCreateModal/CouponClaimablesCreateModal';
import { CouponClaimablesTable } from '@components/tables/CouponClaimablesTable/CouponClaimablesTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdCouponClaimablesView.module.css';

import { useOfferIdCouponClaimablesViewQuery } from './OfferIdCouponClaimablesView.generated';

export const OfferIdCouponClaimablesView: React.FC<OfferIdCouponClaimablesViewProps> =
  (props) => {
    const { className, offerId } = props;
    const { t } = useTranslation('couponClaimables');

    const [isOpen, setOpen] = useState(false);

    const query = useOfferIdCouponClaimablesViewQuery({
      variables: {
        offerId,
        take: 10,
        skip: 0,
      },
      notifyOnNetworkStatusChange: true,
    });

    const offer =
      query?.data?.offer?.__typename === 'OfferCouponClaimable' &&
      query.data.offer;

    if (query.loading && !offer) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!offer) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <Space direction="vertical" size="large">
          <OfferCouponClaimableUpdateForm offer={offer} />

          <CouponClaimablesTable
            activeColumns={[
              'id',
              'createdAt',
              'updatedAt',
              'brand',
              'offer',
              'value',
              'expiresAt',
              'claimedAt',
              'actions',
            ]}
            query={query}
            data={offer.couponClaimablesPagination}
            title={() => (
              <Button type="default" onClick={() => setOpen(true)}>
                {t('OfferIdCouponClaimablesView.createCoupons')}
              </Button>
            )}
          />
        </Space>

        <CouponClaimablesCreateModal
          offerId={offer.id}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      </div>
    );
  };

export type OfferIdCouponClaimablesViewProps = {
  offerId: string;
  className?: string;
};
