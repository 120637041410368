import { SubventionsTable } from '@components/tables/SubventionsTable/SubventionsTable';

import { useSubventionsListViewQuery } from './SubventionsListView.generated';

export const SubventionsListView = () => {
  const query = useSubventionsListViewQuery({
    variables: {
      take: 10,
      skip: 0,
    },
  });

  return (
    <div>
      <SubventionsTable
        activeColumns={[
          'name',
          'type',
          'startsAt',
          'endsAt',
          'company',
          'state',
        ]}
        query={query}
        data={query.data?.subventionPagination || null}
      />
    </div>
  );
};
