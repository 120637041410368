import classnames from 'classnames';

import styles from './TableRendererLocation.module.css';

export const TableRendererLocation: React.FC<TableRendererLocationProps> = (
  props,
) => {
  const { className, value } = props;

  return (
    <div className={classnames(className, styles.root)}>
      {value ? `${value.latitude}, ${value.longitude}` : null}
    </div>
  );
};

export type TableRendererLocationProps = {
  className?: string;
  value?: { latitude: number; longitude: number };
};

export function TableRendererLocationFactory(
  props: TableRendererLocationProps = {},
) {
  return (value: { latitude: number; longitude: number }) => (
    <TableRendererLocation {...props} value={value} />
  );
}
