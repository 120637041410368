import { Button, Popconfirm, Space } from 'antd';

import { CatalogViewDocument } from '../../CatalogView.generated';

import { Typography } from '@components/atoms/Typography/Typography';

import styles from './CatalogHeader.module.css';

import {
  CatalogHeaderFragment,
  useCatalogComputeMutation,
  useCatalogDeleteMutation,
} from './CatalogHeader.generated';

type CatalogHeaderProps = {
  catalog: CatalogHeaderFragment;
};

export const CatalogHeader = (props: CatalogHeaderProps) => {
  const { catalog } = props;

  const [catalogCreateMutation, { loading }] = useCatalogDeleteMutation({
    variables: {
      catalogId: catalog.id,
    },
    refetchQueries: catalog.company
      ? [
          {
            query: CatalogViewDocument,
            variables: { companyId: catalog.company.id },
          },
        ]
      : [],
    awaitRefetchQueries: true,
  });

  const [catalogComputeMutation, catalogComputeMutationState] =
    useCatalogComputeMutation({
      variables: { companyId: catalog.company?.id },
    });

  return (
    <div className={styles.root}>
      <Typography.Title level={3}>
        Catalogue &ldquo;{catalog.company?.name}&rdquo; ({catalog.accessMode})
      </Typography.Title>
      <Space>
        <Popconfirm
          title="Cette action est irréversible"
          onConfirm={() => catalogCreateMutation()}
          okText="Supprimer"
          cancelText="Annuler"
          okType="danger"
        >
          <Button danger loading={loading}>
            Supprimer le catalogue
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Cette action va rafraîchir le catalogue"
          onConfirm={() => {
            catalogComputeMutation();
          }}
          okText="Rafraîchir"
          cancelText="Annuler"
        >
          <Button loading={catalogComputeMutationState.loading}>
            Rafraîchir le catalogue
          </Button>
        </Popconfirm>
      </Space>
    </div>
  );
};
