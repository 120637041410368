/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  useForm as rhUseForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form as AntForm } from 'antd';
import classnames from 'classnames';
import { SchemaOf } from 'yup';

import styles from './Form.module.css';

export const useForm = <T extends FieldValues>({
  validationSchema,
  ...props
}: UseFormProps<T> & { validationSchema: SchemaOf<T> }) => {
  return rhUseForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    ...props,
  });
};

export const Form = <T extends FieldValues = FieldValues, M = any>(
  props: FormProps<T, M>,
) => {
  const {
    id,
    className,
    form,
    onValid,
    onInvalid,
    onSuccess,
    onFailure,
    children,
  } = props;

  const handleValid: SubmitHandler<T> = async (values, event) => {
    try {
      const data = await onValid(values, event);

      if (onSuccess) {
        await onSuccess(data, values);
      }
    } catch (err) {
      if (onFailure) {
        await onFailure(err as unknown as ApolloError, values);
      } else {
        throw err;
      }
    }
  };

  const handleInvalid: SubmitErrorHandler<T> = async (err, values) => {
    console.error(err);
    if (onInvalid) {
      await onInvalid(err, values);
    }
  };

  return (
    <FormProvider {...form}>
      <AntForm
        id={id}
        layout={props.layout ?? 'vertical'}
        className={classnames(className, styles.root)}
        onFinish={form.handleSubmit(handleValid, handleInvalid)}
      >
        {children}
      </AntForm>
    </FormProvider>
  );
};

export type FormProps<T extends FieldValues = FieldValues, M = any> = {
  id: string;
  className?: string;
  form: UseFormReturn<T>;
  children: ReactNode;
  layout?: 'vertical' | 'horizontal' | 'inline';
  onValid: (...args: Parameters<SubmitHandler<T>>) => Promise<M>;
  onInvalid?: SubmitErrorHandler<T>;
  onSuccess?: (result: M, data: UnpackNestedValue<T>) => void | Promise<void>;
  onFailure?: (
    err: ApolloError,
    data: UnpackNestedValue<T>,
  ) => void | Promise<void>;
};

export type ChildFormProps<T extends FieldValues = FieldValues, M = any> = Pick<
  FormProps<T, M>,
  'onSuccess' | 'onFailure'
> & {
  onSubmit?: (data: UnpackNestedValue<T>) => void;
};
