import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormSelectProductProvider } from '@components/atoms/FormSelectProductProvider/FormSelectProductProvider';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductCatalogCreateForm.module.css';

import {
  ProductCatalogCreateFormMutation,
  ProductCatalogCreateFormMutationVariables,
  useProductCatalogCreateFormMutation,
} from './ProductCatalogCreateForm.generated';

export type ProductCatalogCreateFormValues = {
  providerId: string;
  name: string;
  identifier: string;
};

export function ProductCatalogCreateForm(props: ProductCatalogCreateFormProps) {
  const { className, defaultValues, ...rest } = props;
  const { t } = useTranslation('productCatalogs');

  const validationSchema = Yup.object({
    providerId: Yup.string().required(),
    name: Yup.string().required(),
    identifier: Yup.string().required(),
  }).required();

  const form = useForm<ProductCatalogCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useProductCatalogCreateFormMutation();

  const handleSubmit: FormProps<ProductCatalogCreateFormValues>['onValid'] =
    async (values) => {
      const variables: ProductCatalogCreateFormMutationVariables = {
        providerId: values.providerId,
        input: {
          name: values.name,
          identifier: values.identifier,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductCatalogCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.provider}
          required
          label={t('ProductCatalogCreateForm.fields.provider.label')}
          name="providerId"
        >
          <FormSelectProductProvider
            filter={{ isInternal: { is: true } }}
            name="providerId"
          />
        </FormItem>

        <FormItem
          className={styles.name}
          required
          label={t('ProductCatalogCreateForm.fields.name.label')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.identifier}
          required
          label={t('ProductCatalogCreateForm.fields.identifier.label')}
          name="identifier"
        >
          <FormInput name="identifier" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('ProductCatalogCreateForm.submit.create.label')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ProductCatalogCreateFormProps = ChildFormProps<
  ProductCatalogCreateFormValues,
  ProductCatalogCreateFormMutation
> & {
  className?: string;
  defaultValues?: ProductCatalogCreateFormValues;
};
