import { Spin } from 'antd';
import classnames from 'classnames';

import { BrandsTable } from '@tables/BrandsTable/BrandsTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CategoryIdBrandsView.module.css';

import { useCategoryIdBrandsViewQuery } from './CategoryIdBrandsView.generated';

export const CategoryIdBrandsView: React.FC<CategoryIdBrandsViewProps> = (
  props,
) => {
  const { className, categoryId } = props;

  const query = useCategoryIdBrandsViewQuery({
    variables: {
      categoryId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const category = query?.data?.category;

  if (query.loading && !category) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!category) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <BrandsTable
        activeColumns={['name']}
        query={query}
        data={query.data?.category?.brandsPagination}
      />
    </div>
  );
};

export type CategoryIdBrandsViewProps = {
  className?: string;
  categoryId: string;
};
