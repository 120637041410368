import classnames from 'classnames';

import { PictureStockCreateForm } from '@components/forms/PictureStockCreateForm/PictureStockCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './PictureStockCreateModal.module.css';

export type PictureStockCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
};

export function PictureStockCreateModal(props: PictureStockCreateModalProps) {
  const { className, onClose, onSuccess, isOpen } = props;

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess = async () => {
    await onSuccess?.();
    handleClose();
  };

  return (
    <Modal
      title="PictureStockCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="PictureStockCreateForm"
    >
      <PictureStockCreateForm onSuccess={handleSuccess} />
    </Modal>
  );
}
