import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import classnames from 'classnames';

import { Typography } from '@atoms/Typography/Typography';
import {
  BrandCreateForm,
  BrandCreateFormProps,
} from '@forms/BrandCreateForm/BrandCreateForm';

import styles from './BrandCreateView.module.css';

export const BrandCreateView: React.FC<BrandCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('brands');
  const history = useHistory();

  const handleSuccess: BrandCreateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/brands/list/${result.brandCreate.brand.id}`,
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <BrandCreateForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type BrandCreateViewProps = {
  className?: string;
};
