import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { OrdersTable } from '@components/tables/OrdersTable/OrdersTable';

import styles from '../Redbin.module.css';

import { useRedBinOrdersNotDeliveredQuery } from './OrdersNotDelivered.generated';

export const OrdersNotDelivered: React.FC<OrdersNotDeliveredProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinOrdersNotDeliveredQuery();

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader
        ghost={false}
        title={t('OrdersNotDelivered.title')}
        subTitle={t('OrdersNotDelivered.subtitle')}
      />
      <OrdersTable
        query={query}
        data={query.data?.redBinOrdersNotDelivered}
        activeColumns={[
          'id',
          'invoice.identifier',
          'user',
          'createdAt',
          'updatedAt',
          'amount',
          'status',
        ]}
      />
    </div>
  );
};

export type OrdersNotDeliveredProps = {
  className?: string;
};
