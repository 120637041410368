import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Offer } from '@graphql/generated/types';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormTextArea } from '@components/atoms/FormTextArea/FormTextArea';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CouponClaimablesCreateForm.module.css';

import {
  CouponClaimablesCreateFormMutation,
  CouponClaimablesCreateFormMutationVariables,
  useCouponClaimablesCreateFormMutation,
} from './CouponClaimablesCreateForm.generated';

export type CouponClaimablesCreateFormValues = {
  coupons: string;
  expiresAt?: Date;
};

export function CouponClaimablesCreateForm(
  props: CouponClaimablesCreateFormProps,
) {
  const { className, defaultValues, hideFooter, offerId, ...rest } = props;
  const { t } = useTranslation('couponClaimables');

  const validationSchema = Yup.object({
    coupons: Yup.string().required(),
    expiresAt: Yup.date().optional(),
  }).required();

  const form = useForm<CouponClaimablesCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useCouponClaimablesCreateFormMutation();

  const handleSubmit: FormProps<CouponClaimablesCreateFormValues>['onValid'] =
    async (values) => {
      const coupons = values.coupons
        .split('\n')
        .filter(Boolean)
        .map((coupon) => coupon.trim());

      const variables: CouponClaimablesCreateFormMutationVariables = {
        offerId,
        input: {
          coupons,
          expiresAt: values.expiresAt,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CouponClaimablesCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.coupons}
          required
          label={t('CouponClaimablesCreateForm.fields.coupons.label')}
          help={t('CouponClaimablesCreateForm.fields.coupons.help')}
          name="coupons"
        >
          <FormTextArea rows={10} name="coupons" />
        </FormItem>
        <FormItem
          className={styles.expiresAt}
          label={t('CouponClaimablesCreateForm.fields.expiresAt.label')}
          name="expiresAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
            name="expiresAt"
          />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CouponClaimablesCreateFormProps = ChildFormProps<
  CouponClaimablesCreateFormValues,
  CouponClaimablesCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<CouponClaimablesCreateFormValues>;
  hideFooter?: boolean;
  offerId: Offer['id'];
};
