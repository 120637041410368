import { useRouteMatch } from 'react-router-dom';

import { CompanyIdArticleCategoriesView } from '../CompanyIdArticleCategoriesView/CompanyIdArticleCategoriesView';
import { CompanyIdArticlesView } from '../CompanyIdArticlesView/CompanyIdArticlesView';
import { CompanyIdPublicationsView } from '../CompanyIdPulicationsView/CompanyIdPublicationsView';
import { CompanyIdQuestionCategoryView } from '../CompanyIdQuestionCategoryView/CompanyIdQuestionCategoryView';

import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

type CompanyIdCommunicationViewProps = {
  companyId: string;
};

export function CompanyIdCommunicationView(
  props: CompanyIdCommunicationViewProps,
) {
  const match = useRouteMatch();
  const { companyId } = props;

  return (
    <div>
      <TabsRouter replace match={match} tabPosition="left">
        <TabsRouter.Route
          path={`${match.url}/acticle-categories`}
          exact
          label="Catégories d'articles"
        >
          <CompanyIdArticleCategoriesView companyId={companyId} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/articles`} exact label="Articles">
          <CompanyIdArticlesView companyId={companyId} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/question-categories`}
          exact
          label="Catégories de questions"
        >
          <CompanyIdQuestionCategoryView companyId={companyId} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/publications`}
          exact
          label="Publications"
        >
          <CompanyIdPublicationsView companyId={companyId} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
}
