import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import classnames from 'classnames';

import styles from './ProductVariantActivateDeactivateButton.module.css';

import {
  ProductVariantActivateDeactivateButtonFragment,
  useProductVariantActivateDeactivateButtonActivateMutation,
  useProductVariantActivateDeactivateButtonDeactivateMutation,
} from './ProductVariantActivateDeactivateButton.generated';

export const ProductVariantActivateDeactivateButton: React.FC<ProductVariantActivateDeactivateButtonProps> =
  (props) => {
    const { className, productVariant } = props;
    const { t } = useTranslation('productVariants');

    const [activateMutation] =
      useProductVariantActivateDeactivateButtonActivateMutation();
    const [deactivateMutation] =
      useProductVariantActivateDeactivateButtonDeactivateMutation();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleActivate = async () => {
      if (isSubmitting) return;
      setSubmitting(true);
      try {
        await activateMutation({
          variables: { productVariantId: productVariant.id },
        });
      } catch (err) {
        if (err instanceof ApolloError) {
          err.graphQLErrors.forEach((err) => {
            switch (err.extensions?.code) {
              case 'product/has-different-variant-price-types':
                message.error(
                  t(`error:product/has-different-variant-price-types`),
                );
                break;
              default:
                message.error(err.message.replace('GraphQL error: ', ''));
            }
          });
        } else {
          message.error('error:default');
        }
      }
      setSubmitting(false);
    };

    const handleDeactivate = async () => {
      if (isSubmitting) return;
      setSubmitting(true);
      try {
        await deactivateMutation({
          variables: { productVariantId: productVariant.id },
        });
      } catch (err) {
        if (err instanceof ApolloError) {
          message.error(t(`error:default`));
        } else {
          message.error('error:default');
        }
      }
      setSubmitting(false);
    };

    return (
      <>
        {!productVariant.isActivated ? (
          <Popconfirm
            onConfirm={handleActivate}
            title={t('ProductVariantActivateDeactivate.activate.confirm')}
          >
            <Button
              loading={isSubmitting}
              type="primary"
              className={classnames(className, styles.root)}
            >
              {t('ProductVariantActivateDeactivate.activate.label')}
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            onConfirm={handleDeactivate}
            title={t('ProductVariantActivateDeactivate.deactivate.confirm')}
          >
            <Button
              loading={isSubmitting}
              className={classnames(className, styles.root)}
            >
              {t('ProductVariantActivateDeactivate.deactivate.label')}
            </Button>
          </Popconfirm>
        )}
      </>
    );
  };

export type ProductVariantActivateDeactivateButtonProps = {
  className?: string;
  productVariant: ProductVariantActivateDeactivateButtonFragment;
};
