import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { InvitationStatus } from '@graphql/generated/types';

export const InvitationStatusTag: React.FC<InvitationStatusTagProps> = (
  props,
) => {
  const { className, status } = props;
  const { t } = useTranslation('invitations');

  const colors: Record<InvitationStatus, string> = {
    ACTIVE: 'default',
    REVOKED: 'yellow',
    CLAIMED: 'green',
    EXPIRED: 'red',
  };

  const labels: Record<InvitationStatus, string> = {
    ACTIVE: t('invitationsStatusTag.ACTIVE'),
    REVOKED: t('invitationsStatusTag.REVOKED'),
    CLAIMED: t('invitationsStatusTag.CLAIMED'),
    EXPIRED: t('invitationsStatusTag.EXPIRED'),
  };

  return (
    <Tag className={classnames(className)} color={colors[status]}>
      {labels[status]}
    </Tag>
  );
};

export type InvitationStatusTagProps = {
  className?: string;
  status: InvitationStatus;
};
