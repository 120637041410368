import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './OrderRefundStatusTag.module.css';

import { OrderRefundStatusTagFragment } from './OrderRefundStatusTag.generated';

export const OrderRefundStatusTag: React.FC<OrderRefundStatusTagProps> = (
  props,
) => {
  const { className, orderRefund } = props;
  const { t } = useTranslation('orderRefund');

  const colors: Record<typeof orderRefund.status, string> = {
    PENDING: 'grey',
    PROCESSED: 'green',
    REJECTED: 'red',
    VALIDATED: 'green',
    FAILED: 'red',
  };

  const labels: Record<typeof orderRefund.status, string> = {
    PENDING: t('status.pending'),
    PROCESSED: t('status.processed'),
    REJECTED: t('status.rejected'),
    VALIDATED: t('status.validated'),
    FAILED: t('status.failed'),
  };

  return (
    <Tag
      title={labels[orderRefund.status]}
      className={classnames(className, styles.root)}
      color={colors[orderRefund.status]}
    >
      {labels[orderRefund.status]}
    </Tag>
  );
};

export type OrderRefundStatusTagProps = {
  className?: string;
  orderRefund: OrderRefundStatusTagFragment;
};
