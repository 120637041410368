import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';
import Dinero from 'dinero.js';

import { PageHeader } from '@components/molecules/PageHeader/PageHeader';
import { Typography } from '@atoms/Typography/Typography';

import styles from './UserHeader.module.css';

import { UserHeaderFragment } from './UserHeader.generated';

export const UserHeader: React.FC<UserHeaderProps> = (props) => {
  const { className, user } = props;
  const { i18n } = useTranslation();

  return (
    <PageHeader
      logo={user.avatar}
      showNav
      className={classnames(className, styles.root)}
    >
      <div className={styles.profile}>
        <div className={styles.profileRight}>
          <Typography.Title className={styles.name} level={4}>
            {`${user.firstName} ${user.lastName}`}
          </Typography.Title>
          <Typography.Paragraph className={styles.email} copyable>
            {user.email}
          </Typography.Paragraph>
        </div>
      </div>
      <div className={styles.limits}>
        <div className={styles.limit}>
          <Typography.Text>Plafond de paiement :</Typography.Text>
          <Typography.Text>
            {Dinero({
              amount: user.paymentLimitCeiling,
              currency: 'EUR',
            })
              .setLocale(i18n.resolvedLanguage)
              .toFormat()}
          </Typography.Text>
          {user.paymentLimit && (
            <Typography.Text>
              {' '}
              (Valide jusqu&apos;au{' '}
              {format(user.paymentLimit.validUntil, 'dd-MM-yyyy')} à{' '}
              {format(user.paymentLimit.validUntil, 'HH:mm')})
            </Typography.Text>
          )}
        </div>
        <div className={styles.limit}>
          <Typography.Text>Plafond restant :</Typography.Text>
          <Typography.Text>
            {Dinero({
              amount: user.paymentLimitBalance,
              currency: 'EUR',
            })
              .setLocale(i18n.resolvedLanguage)
              .toFormat()}
          </Typography.Text>
        </div>
      </div>
    </PageHeader>
  );
};

export type UserHeaderProps = {
  className?: string;
  user: UserHeaderFragment;
};
