import { ChangeEventHandler, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { message, Progress } from 'antd';
import classnames from 'classnames';

import { Typography } from '../Typography/Typography';

import { FileType } from '@graphql/generated/types';

import {
  convertFileToPayload,
  postFormData,
} from '@utils/extractImageInformations/extractImageInformations';

import styles from './FormPicture.module.css';

import {
  useFormPictureCreateMutation,
  useFormPictureLazyQuery,
} from './FormPicture.generated';
import placeholder from './placeholder.png';

export function FormPicture(props: FormPictureProps) {
  const { className, info, name, previewRatio = '16:9' } = props;

  const { t } = useTranslation('error');
  const { field, fieldState } = useController({ name });
  const [previewSrc, setPreviewSrc] = useState(placeholder);
  const [progress, setProgress] = useState<number | null>(null);

  const [getPicture, { loading }] = useFormPictureLazyQuery({
    onCompleted: (data) =>
      data?.picture && setPreviewSrc(data.picture.file.url),
  });

  useEffect(() => {
    if (field.value) {
      getPicture({ variables: { pictureId: field.value } });
    }
  }, [field.value]);

  const [mutation] = useFormPictureCreateMutation();

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.item(0);

    // Should we emit change null here?
    if (!file) return;
    setProgress(0);
    setPreviewSrc(URL.createObjectURL(file));

    // If image preprocession (resize/crop) need to be done
    // it shoulde be handled here before sending picture create mutation
    // Another PicturePreview component would be necessary to split the logic/complexity

    try {
      const variables = await convertFileToPayload(file, props.type);

      const { data } = await mutation({ variables });

      await postFormData(file, data!.pictureCreate, (p) => {
        setProgress(Math.floor((p.loaded / p.total) * 100));
      });

      field.onChange(data!.pictureCreate.picture.id);
      setProgress(100);
    } catch (err) {
      // @ts-expect-error check
      if (err.message.includes('Payload too large')) {
        message.error(t('error:picture/too-large'));
      } else {
        message.error(t('error:picture/upload'));
      }
      console.error(err);
    } finally {
      setTimeout(() => {
        setProgress(null);
      }, 2000);
    }
  };

  return (
    <div
      className={classnames(styles.root, className, {
        [styles['status-error']]: !!fieldState.error,
      })}
    >
      <div className={styles.pictureWrapper}>
        <div
          className={styles.pictureAspectRatio}
          style={{
            paddingBottom: convertAspectRatioToPercent(previewRatio),
          }}
        >
          <img
            alt="uploaded_picture"
            src={previewSrc}
            className={styles.picture}
          />
          {progress !== null && (
            <div className={styles.overlay}>
              {progress <= 0 ? (
                <Typography.Text>Conversion</Typography.Text>
              ) : (
                <Progress
                  className={styles.progress}
                  percent={(progress > 0 && Math.max(0, progress)) || 0}
                  size="small"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <Typography.Text type="secondary" className={styles.info}>
          {info}
        </Typography.Text>
        <div className={styles.inputWrapper}>
          <input
            ref={field.ref}
            onChange={handleChange}
            type="file"
            accept="image/png,image/jpeg"
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
}

export type FormPictureProps = {
  name: string;
  type: FileType;
  info?: string;
  className?: string;
  previewRatio?: string;
};

function convertAspectRatioToPercent(ratio: string) {
  const [width = '16', height = '9'] = ratio.split(':');

  return (parseInt(height) / parseInt(width)) * 100 + '%';
}
