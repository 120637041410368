import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import Dinero from 'dinero.js';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { ProductType, TaxRate } from '@graphql/generated/types';

import { ProductTypeTag } from '@components/atoms/ProductTypeTag/ProductTypeTag';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductsTableQueryVariables,
  ProductsTableRecordFragment,
  useProductsTableQuery,
} from './ProductsTable.generated';

type RecordType = ProductsTableRecordFragment;

type Column =
  | 'name'
  | 'type'
  | 'brand'
  | 'offer'
  | 'expiresAt'
  | 'expiresInDays'
  | 'variantCount'
  | 'priceMin'
  | 'priceMax'
  | 'price'
  | 'providerDiscount'
  | 'defaultDiscount'
  | 'discountMax'
  | 'provider'
  | 'taxRates';

export function ProductsTable(props: ProductsTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t, i18n } = useTranslation('products');

  const query =
    propQuery ||
    useProductsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });

  const renderDiscount = (
    discount: ProductsTableRecordFragment['defaultDiscount'],
  ) => {
    if (!discount) return;

    switch (discount.__typename) {
      case 'DiscountFlat':
        return (
          '-' +
          Dinero({
            currency: 'EUR',
            amount: discount.amountOff,
          })
            .setLocale(i18n.resolvedLanguage)
            .toFormat()
        );
      case 'DiscountPercentage':
        return '-' + discount.percentOff + '%';
    }
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
      ellipsis: true,
      render: (name, node) => (
        <I18nLink to={`/products/list/${node.id}`}>{name}</I18nLink>
      ),
      sorter: true,
      ...generateColumnFilter(query, 'name', 'search'),
    },
    {
      key: 'type',
      title: t('table.headers.type'),
      dataIndex: ['type'],
      ellipsis: true,
      render: (_, node) => <ProductTypeTag fragment={node} />,
      sorter: true,
      ...generateColumnFilter(query, 'type', 'enum', [
        {
          label: t('productType.GiftCard'),
          value: ProductType.GiftCard,
        },
        {
          label: t('productType.Ticket'),
          value: ProductType.Ticket,
        },
      ]),
    },
    {
      key: 'brand',
      title: t('table.headers.brand'),
      dataIndex: ['brand', 'name'],
      ellipsis: true,
      render: (name, node) =>
        node.brand ? (
          <I18nLink to={`/brands/list/${node.brand.id}`}>{name}</I18nLink>
        ) : (
          'N/A'
        ),
      sorter: true,
      ...generateColumnFilter(query, 'brand.name', 'search'),
    },
    {
      key: 'offer',
      title: t('table.headers.offer'),
      dataIndex: ['offer', 'name'],
      ellipsis: true,
      render: (name, node) =>
        node.offer ? (
          <I18nLink to={`/offers/list/${node.offer.id}`}>{name}</I18nLink>
        ) : (
          'N/A'
        ),
      sorter: true,
      ...generateColumnFilter(query, 'offer.name', 'search'),
    },
    {
      key: 'expiresAt',
      title: t('table.headers.expiresAt'),
      dataIndex: ['expiresAt'],
      render: TableRendererDateTimeFactory(),
      sorter: true,
      ...generateColumnFilter(query, 'expiresAt', 'datetime'),
    },
    {
      key: 'expiresInDays',
      title: t('table.headers.expiresInDays'),
      dataIndex: ['expiresInDays'],
      sorter: true,
      ...generateColumnFilter(query, 'expiresInDays', 'number'),
    },
    {
      key: 'variantCount',
      title: t('table.headers.variantCount'),
      dataIndex: ['productVariantPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'priceMin',
      title: t('table.headers.priceMin'),
      dataIndex: ['productVariantPagination', 'nodes'],
      render: (variants) =>
        variants.length &&
        t('table.data.price', { price: getMinPrice(variants) }),
    },
    {
      key: 'priceMax',
      title: t('table.headers.priceMax'),
      dataIndex: ['productVariantPagination', 'nodes'],
      render: (variants) =>
        variants.length &&
        t('table.data.price', { price: getMaxPrice(variants) }),
    },
    {
      key: 'price',
      title: t('table.headers.price'),
      dataIndex: ['productVariantPagination', 'nodes'],
      render: (variants) => {
        if (!variants.length) return null;

        const min = getMinPrice(variants);
        const max = getMaxPrice(variants);

        if (min.equalsTo(max)) {
          return t('table.data.price', { price: min });
        } else {
          return t('table.data.priceRange', { min, max });
        }
      },
    },
    {
      key: 'defaultDiscount',
      title: t('table.headers.defaultDiscount'),
      dataIndex: ['defaultDiscount'],
      render: (discount) => renderDiscount(discount),
    },
    {
      key: 'provider',
      title: t('table.headers.provider'),
      dataIndex: ['catalog', 'provider'],
      render: (provider) => provider.name,
      ...generateColumnFilter(query, 'catalog.provider.name', 'search'),
    },
    {
      key: 'catalog',
      title: t('table.headers.catalog'),
      dataIndex: ['catalog'],
      render: (catalog) => catalog.name,
      ...generateColumnFilter(query, 'catalog.name', 'search'),
    },
    {
      key: 'providerDiscount',
      title: t('table.headers.providerDiscount'),
      dataIndex: ['providerDiscount'],
      render: (discount) => renderDiscount(discount),
    },
    {
      key: 'taxRates',
      title: t('table.headers.taxRates'),
      dataIndex: ['taxRatePagination', 'nodes'],
      render: (taxRates: TaxRate[] | undefined) =>
        taxRates && (
          <div>
            {taxRates.map((t) => (
              <Tag key={t.id}>{t.displayName}</Tag>
            ))}
          </div>
        ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.productPagination}
        {...rest}
      />
    </div>
  );
}

export type ProductsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: ProductsTableQueryVariables['sorter'];
  filter?: ProductsTableQueryVariables['filter'];
};

function getMinPrice(
  variants: RecordType['productVariantPagination']['nodes'],
) {
  if (!variants?.length) return Dinero({ currency: 'EUR', amount: 0 });

  const currency = variants[0].priceCurrency;

  let defaultValue = 0;

  switch (variants[0].__typename) {
    case 'ProductVariantPriceFixed':
      defaultValue = variants[0].priceValue;
      break;

    case 'ProductVariantPriceRange':
      defaultValue = variants[0].priceMin;
      break;

    case 'ProductVariantPriceDynamic':
      defaultValue = variants[0].priceValue;
      break;
  }

  const minValue = variants.reduce((prev, curr) => {
    if (
      curr.__typename === 'ProductVariantPriceFixed' &&
      curr.priceValue < prev
    ) {
      return curr.priceValue;
    }

    if (
      curr.__typename === 'ProductVariantPriceRange' &&
      curr.priceMin < prev
    ) {
      return curr.priceMin;
    }

    if (
      curr.__typename === 'ProductVariantPriceDynamic' &&
      curr.priceValue < prev
    ) {
      return curr.priceValue;
    }

    return prev;
  }, defaultValue);

  return Dinero({
    currency,
    amount: minValue,
  });
}

function getMaxPrice(
  variants: RecordType['productVariantPagination']['nodes'],
) {
  if (!variants?.length) return Dinero({ currency: 'EUR', amount: 0 });

  const currency = variants[0].priceCurrency;

  let defaultValue = 0;

  switch (variants[0].__typename) {
    case 'ProductVariantPriceFixed':
      defaultValue = variants[0].priceValue;
      break;

    case 'ProductVariantPriceRange':
      defaultValue = variants[0].priceMax;
      break;

    case 'ProductVariantPriceDynamic':
      defaultValue = 0; // FIXME: not implemented
      break;
  }

  const maxValue = variants.reduce((prev, curr) => {
    if (
      curr.__typename === 'ProductVariantPriceFixed' &&
      curr.priceValue > prev
    ) {
      return curr.priceValue;
    }

    if (
      curr.__typename === 'ProductVariantPriceRange' &&
      curr.priceMax > prev
    ) {
      return curr.priceMax;
    }

    // FIXME: price dynamic

    return prev;
  }, defaultValue);

  return Dinero({
    currency,
    amount: maxValue,
  });
}
