import React, { useEffect, useState } from 'react';
import { isApolloError } from '@apollo/client';
import {
  Alert,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Radio,
} from 'antd';

import { SurveyProvider } from '@graphql/generated/types';

import { useSurveyCreateModalMutation } from './SurveyCreateModal.generated';

type FormValues = {
  date: [string, string];
  provider: SurveyProvider;
  url: string;
};

interface SurveyCreateFormProps {
  initialValues?: Partial<FormValues>;
  onFormInstanceReady: (instance: FormInstance<FormValues>) => void;
}

const SurveyCreateForm: React.FC<SurveyCreateFormProps> = ({
  initialValues,
  onFormInstanceReady,
}) => {
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    onFormInstanceReady(form);
  }, []);

  const provider = Form.useWatch(['provider'], form);

  return (
    <Form
      layout="vertical"
      form={form}
      name="survey_create_form"
      initialValues={initialValues}
    >
      <Form.Item
        name="date"
        label="Début et fin du sondage"
        rules={[{ required: true, message: 'Dates obligatoires' }]}
      >
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          showTime={{ format: 'HH:mm' }}
          format="D MMMM YYYY HH:mm"
          disabledDate={(current) => current.isBefore(Date.now(), 'day')}
        />
      </Form.Item>
      <Form.Item
        name="provider"
        label="Fournisseur"
        rules={[{ required: true, message: 'Fournisseur obligatoire' }]}
      >
        <Radio.Group>
          <Radio value={SurveyProvider.GoogleForm}>Google Form</Radio>
          <Radio value={SurveyProvider.MicrosoftForm}>Microsoft Form</Radio>
          <Radio value={SurveyProvider.Tally}>Tally</Radio>
          <Radio value={SurveyProvider.Typeform}>Typeform</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="url"
        label="URL du sondage"
        rules={[
          { required: true, message: 'URL sondage obligatoire' },
          { type: 'url', message: 'Doit être une URL' },
        ]}
      >
        <Input />
      </Form.Item>
      {!!provider && (
        <Alert
          type="info"
          showIcon
          message={
            provider
              ? {
                  [SurveyProvider.GoogleForm]:
                    'https://forms.gle/XXXXXXXXXXXXXXXXX',
                  [SurveyProvider.MicrosoftForm]:
                    'https://forms.office.com/pages/responsepage.aspx?id=XXXXXXXXXXXX',
                  [SurveyProvider.Tally]: 'https://tally.so/r/XXXXXX',
                  [SurveyProvider.Typeform]:
                    'https://XXXX.typeform.com/to/XXXXXXXX',
                }[provider]
              : ''
          }
        />
      )}
    </Form>
  );
};

interface SurveyCreateModalProps {
  open: boolean;
  onCreate: () => void;
  onCancel: () => void;
  initialValues?: Partial<FormValues>;
  companyId: string;
}

export const SurveyCreateModal: React.FC<SurveyCreateModalProps> = ({
  open,
  onCancel,
  onCreate,
  initialValues,
  companyId,
}) => {
  const [formInstance, setFormInstance] = useState<FormInstance<FormValues>>();
  const [createMutation] = useSurveyCreateModalMutation();

  const handleCreate = async () => {
    if (!formInstance) return;

    try {
      const values = await formInstance.validateFields();

      const startsAt = new Date(values.date[0]);
      startsAt.setSeconds(0);
      startsAt.setMilliseconds(0);
      const endsAt = new Date(values.date[1]);
      endsAt.setSeconds(0);
      endsAt.setMilliseconds(0);

      await createMutation({
        variables: {
          companyId,
          input: {
            provider: values.provider,
            startsAt,
            endsAt,
            url: values.url,
          },
        },
      });

      formInstance?.resetFields();
      onCreate();
    } catch (error) {
      console.error(error);
      if (error instanceof Error && isApolloError(error)) {
        const code = error.graphQLErrors[0]?.extensions?.code;

        switch (code) {
          case 'survey/invalid-provider-url':
            formInstance.setFields([
              {
                name: 'url',
                errors: [
                  'URL du sondage invalide pour le fournisseur sélectionné',
                ],
              },
            ]);
            break;
          default:
            message.error(
              "Une erreur est survenue. Plus d'informations dans la console.",
            );
            break;
        }
      }
    }
  };

  return (
    <Modal
      open={open}
      title="Créer un sondage"
      okText="Créer"
      cancelText="Annuler"
      okButtonProps={{ autoFocus: true }}
      onCancel={onCancel}
      destroyOnClose
      onOk={handleCreate}
    >
      <SurveyCreateForm
        initialValues={initialValues}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
    </Modal>
  );
};
