import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { CompanyFeesCollectionMethodTag } from '@components/atoms/CompanyFeesCollectionMethodTag/CompanyFeesCollectionMethodTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CompanyFeesTableQueryResult,
  CompanyFeesTableQueryVariables,
  CompanyFeesTableRecordFragment,
  useCompanyFeesTableQuery,
} from './CompanyFeesTable.generated';

type RecordType = CompanyFeesTableRecordFragment;

type Column =
  | 'flat'
  | 'percent'
  | 'min'
  | 'max'
  | 'collectionMethod'
  | 'taxRate'
  | 'createdBy'
  | 'deletedAt'
  | 'deletedBy';

export function CompanyFeesTable(props: CompanyFeesTableProps) {
  const { companyId, sorter } = props;

  const query = useCompanyFeesTableQuery({
    variables: {
      companyId,
      skip: 0,
      take: 10,
      sorter,
    },
  });

  if (query.loading && !query.data) {
    return <Spin />;
  } else {
    return <CompanyFeesTableInner query={query} {...props} />;
  }
}

function CompanyFeesTableInner(
  props: CompanyFeesTableProps & {
    query: CompanyFeesTableQueryResult;
  },
) {
  const { className, sorter, companyId, query, ...rest } = props;

  const company = (query as CompanyFeesTableQueryResult).data!.company!;

  const { t } = useTranslation('companyFees');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'flat',
      title: t('table.headers.flat'),
      render: (_, node) => (
        <TableRendererCurrency
          value={{
            amount: node.flat,
            currency: node.currency,
          }}
        />
      ),
    },
    {
      key: 'percent',
      title: t('table.headers.percent'),
      render: (_, node) => `${node.percent} %`,
    },
    {
      key: 'min',
      title: t('table.headers.min'),
      render: (_, node) => (
        <TableRendererCurrency
          value={{
            amount: node.min,
            currency: node.currency,
          }}
        />
      ),
    },
    {
      key: 'max',
      title: t('table.headers.max'),
      render: (_, node) =>
        node.max && (
          <TableRendererCurrency
            value={{
              amount: node.max,
              currency: node.currency,
            }}
          />
        ),
    },
    {
      key: 'collectionMethod',
      title: t('table.headers.collectionMethod'),
      dataIndex: ['user', 'collectionMethod'],
      render: (_, node) => (
        <CompanyFeesCollectionMethodTag companyFees={node} />
      ),
    },
    {
      key: 'taxRate',
      title: t('table.headers.taxRate'),
      dataIndex: ['taxRate', 'displayName'],
      ellipsis: true,
      render: (displayName, node) =>
        node.taxRate && (
          <I18nLink to={`/brands/list/${node.taxRate.id}`}>
            {displayName}
          </I18nLink>
        ),
    },
    {
      key: 'deletedAt',
      title: t('table.headers.deletedAt'),
      dataIndex: ['deletedAt'],
      render: TableRendererDateTimeFactory(),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CompanyFeesTable"
        query={query}
        columns={columns}
        data={company.companyFeesPagination}
        {...rest}
      />
    </div>
  );
}

export type CompanyFeesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  companyId: string;
  className?: string;
  sorter?: CompanyFeesTableQueryVariables['sorter'];
};
