import classnames from 'classnames';

import { CategoriesTable } from '@tables/CategoriesTable/CategoriesTable';

import styles from './CategoriesView.module.css';

export const CategoriesView: React.FC<CategoriesViewProps> = (props) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <CategoriesTable
        activeColumns={[
          'icon',
          'name',
          'brandCount',
          'campaignCount',
          'offerCount',
          'childrenCount',
        ]}
      />
    </div>
  );
};

export type CategoriesViewProps = {
  className?: string;
};
