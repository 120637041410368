import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox, Descriptions, Divider, Spin } from 'antd';
import classNames from 'classnames';

import { Typography } from '@components/atoms/Typography/Typography';
import {
  MangopayLegalUserCreateForm,
  MangopayLegalUserCreateFormProps,
} from '@components/forms/MangopayLegalUserForm/MangopayLegalUserCreateForm';
import { MangopayKycDocumentCreateModal } from '@components/modals/MangopayKycDocumentCreateModal/MangopayKycDocumentCreateModal';
import { MangopayKycDocumentsTable } from '@components/tables/MangopayKycDocumentsTable/MangopayKycDocumentsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './CompanyIdMangopayView.module.css';

import {
  useBankAliasCompanyFeatureDisableMutation,
  useBankAliasCompanyFeatureEnableMutation,
  useCompanyIdMangopayViewQuery,
} from './CompanyIdMangopayView.generated';

export const CompanyIdMangopayView: React.FC<CompanyIdMangopayViewProps> = (
  props,
) => {
  const { className, companyId } = props;

  const mlu = useTranslation('mangopayLegalUser');
  const mkd = useTranslation('mangopayKycDocument');

  const query = useCompanyIdMangopayViewQuery({ variables: { companyId } });

  const [isOpen, setOpen] = useState(false);

  const handleSuccess: MangopayLegalUserCreateFormProps['onSuccess'] = () => {
    query.refetch();
  };

  const company = query?.data?.company;

  const [enableBankAliasFeature] = useBankAliasCompanyFeatureEnableMutation();
  const [disableBankAliasFeature] = useBankAliasCompanyFeatureDisableMutation();

  if (query.loading && !company) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!company) {
    return <NotFoundView />;
  }

  if (!company.mangopayUser) {
    return (
      <>
        <Typography.Title level={3}>
          {mlu.t('mangopayLegalUser.create.title')}
        </Typography.Title>
        <MangopayLegalUserCreateForm
          company={company}
          onSuccess={handleSuccess}
        />
      </>
    );
  }

  return (
    <div className={classNames(className, styles.root)}>
      <Card title="Information du compte Mangopay" size="small">
        <Descriptions column={2} size="small" bordered>
          <Descriptions.Item label="Company Mangopay id">
            {company.mangopayUser.mangopayId}
          </Descriptions.Item>
          <Descriptions.Item label="Wallet Mangopay id">
            {company.mangopayUser.defaultWallet.mangopayId}
          </Descriptions.Item>
          {company.features.mangopayBankingAlias &&
            company.mangopayUser.defaultWallet?.bankingAlias && (
              <>
                <Descriptions.Item label="Alias bancaire IBAN">
                  {company.mangopayUser.defaultWallet?.bankingAlias.IBAN}
                </Descriptions.Item>
                <Descriptions.Item label="Alias bancaire BIC">
                  {company.mangopayUser.defaultWallet?.bankingAlias.BIC}
                </Descriptions.Item>
              </>
            )}
        </Descriptions>
      </Card>

      <Divider />

      <Typography.Title level={3}>
        {mkd.t('mangopayKycDocument.table.title')}
      </Typography.Title>

      <MangopayKycDocumentsTable
        title={() => (
          <Button type="primary" onClick={() => setOpen(true)}>
            {mkd.t('mangopayKycDocument.create.title')}
          </Button>
        )}
        query={query}
        data={company.mangopayUser?.mangopayKycDocumentPagination}
        activeColumns={['mangopayId', 'type', 'status', 'processedAt']}
      />

      <Divider />

      <Card title="Paramètres" size="small">
        <Checkbox
          className={styles.feature}
          checked={company.features.mangopayBankingAlias || false}
          onChange={() => {
            company.features.mangopayBankingAlias
              ? disableBankAliasFeature({ variables: { companyId } })
              : enableBankAliasFeature({ variables: { companyId } });
          }}
        >
          Alias bancaire
        </Checkbox>
      </Card>

      <MangopayKycDocumentCreateModal
        mangopayLegalUserId={company.mangopayUser.id}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
      />
    </div>
  );
};

export type CompanyIdMangopayViewProps = {
  companyId: string;
  className?: string;
};
