import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Layout, Spin, Tag, Typography } from 'antd';

import { SubventionSchemaChildrenView } from '../SubventionSchemaChildrenView/SubventionSchemaChildrenView';
import { SubventionSchemaOffersView } from '../SubventionSchemaOffersView/SubventionSchemaOffersView';
import { SubventionSchemaParentView } from '../SubventionSchemaParentView/SubventionSchemaParentView';
import { SubventionSchemaSettingsView } from '../SubventionSchemaSettingsView/SubventionSchemaSettingsView';
import { SubventionSchemaVisibilityView } from '../SubventionSchemaVisibilityView/SubventionSchemaVisibilityView';

import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './SubventionSchemaView.module.css';

import { useSubventionSchemaViewQuery } from './SubventionSchemaView.generated';

export const SubventionSchemaView = (props: SubventionSchemaViewProps) => {
  const { match } = props;
  const {
    params: { subventionSchemaId },
  } = match;

  const { t } = useTranslation('subvention-schemas');

  const query = useSubventionSchemaViewQuery({
    variables: { subventionSchemaId },
  });

  const subventionSchema = query.data?.subventionSchema;

  if (query.loading && !subventionSchema) {
    return <Spin size="large" />;
  } else if (!subventionSchema) {
    return <NotFoundView />;
  }

  return (
    <Layout>
      <div className={styles.title}>
        <Typography.Title level={3}>{subventionSchema.name}</Typography.Title>
        <Tag>{subventionSchema.status}</Tag>
        {subventionSchema.parent && (
          <>
            <Typography.Title level={5}>
              {subventionSchema.parent.name}
            </Typography.Title>
            <Tag>{subventionSchema.parent.status}</Tag>
          </>
        )}
      </div>
      <TabsRouter replace match={match}>
        <TabsRouter.Route
          path={`${match.url}/offers`}
          label={t('tabs.offers', {
            total: subventionSchema.offersPagination.totalCount,
          })}
        >
          <SubventionSchemaOffersView subventionSchemaId={subventionSchemaId} />
        </TabsRouter.Route>
        {subventionSchema.parent ? (
          <TabsRouter.Route
            path={`${match.url}/parent`}
            label={t('tabs.parent')}
          >
            <SubventionSchemaParentView
              subventionSchemaId={subventionSchemaId}
            />
          </TabsRouter.Route>
        ) : (
          <TabsRouter.Route
            path={`${match.url}/children`}
            label={t('tabs.children', {
              total: subventionSchema.childrenPagination.totalCount,
            })}
          >
            <SubventionSchemaChildrenView
              subventionSchemaId={subventionSchemaId}
            />
          </TabsRouter.Route>
        )}
        <TabsRouter.Route
          path={`${match.url}/visibility`}
          label={t('tabs.visibility')}
        >
          <SubventionSchemaVisibilityView
            subventionSchemaId={subventionSchemaId}
          />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/settings`}
          label={t('tabs.settings')}
        >
          <SubventionSchemaSettingsView
            subventionSchemaId={subventionSchemaId}
          />
        </TabsRouter.Route>
      </TabsRouter>
    </Layout>
  );
};

export type SubventionSchemaViewProps = RouteComponentProps<{
  subventionSchemaId: string;
}>;
