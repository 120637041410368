import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { CategoryTranslationForm } from '@forms/CategoryTranslationForm/CategoryTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './CategoryIdTranslationsView.module.css';

import { useCategoryIdTranslationsViewQuery } from './CategoryIdTranslationsView.generated';

export const CategoryIdTranslationsView: React.FC<CategoryIdTranslationsViewProps> =
  (props) => {
    const { className, categoryId } = props;
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
      undefined,
    );

    const localesQuery = useLocales();

    const query = useCategoryIdTranslationsViewQuery({
      variables: { categoryId },
      notifyOnNetworkStatusChange: true,
    });

    const category = query?.data?.category;
    const locales = localesQuery?.data?.localesPagination.nodes;

    if ((localesQuery.loading || query.loading) && !category) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!category) {
      return <NotFoundView />;
    } else if (!locales) {
      return <ServerErrorView />;
    }

    const { viewer } = useAuth();

    const userLanguage = viewer?.preferences?.properties.language || 'en';

    return (
      <div className={classnames(className, styles.root)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          defaultActiveKey={userLanguage}
        >
          {category?.translationsPagination.nodes.map((node) => {
            const locale = locales.find((locale) => locale.id === node.locale);

            return (
              <Tabs.TabPane
                tab={locale ? `${locale.name} (${locale.id})` : node.locale}
                key={node.locale}
              >
                <CategoryTranslationForm
                  categoryId={category.id}
                  translation={node}
                  onDeleted={({ category }) => setActiveTabKey(category.locale)}
                />
              </Tabs.TabPane>
            );
          })}
          {category.translationsPagination.totalCount < locales.length && (
            <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
              <CategoryTranslationForm
                onCreated={({ categoryTranslation }) =>
                  setActiveTabKey(categoryTranslation.locale)
                }
                localeFilter={{
                  locale: {
                    exclude: category.translationsPagination.nodes.map(
                      (node) => node.locale,
                    ),
                  },
                }}
                categoryId={category.id}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };

export type CategoryIdTranslationsViewProps = {
  className?: string;
  categoryId: string;
};
