import { Spin } from 'antd';
import classnames from 'classnames';

import { CategoriesTable } from '@tables/CategoriesTable/CategoriesTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CategoryIdChildrenView.module.css';

import { useCategoryIdChildrenViewQuery } from './CategoryIdChildrenView.generated';

export const CategoryIdChildrenView: React.FC<CategoryIdChildrenViewProps> = (
  props,
) => {
  const { className, categoryId } = props;

  const query = useCategoryIdChildrenViewQuery({
    variables: {
      categoryId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const category = query?.data?.category;

  if (query.loading && !category) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!category) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <CategoriesTable
        activeColumns={['name', 'brandCount', 'campaignCount', 'offerCount']}
        query={query}
        data={query.data?.category?.childrenPagination}
      />
    </div>
  );
};

export type CategoryIdChildrenViewProps = {
  className?: string;
  categoryId: string;
};
