import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { OfferCommonFormValues } from '../OfferCommonForm/OfferCommonForm';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferCouponPublicForm.module.css';

import {
  OfferCouponPublicFormCreateMutation,
  OfferCouponPublicFormCreateMutationVariables,
  useOfferCouponPublicFormCreateMutation,
} from './OfferCouponPublicForm.generated';

type OfferCouponPublicValues = {
  coupon: string;
  expiresAt?: Date;
};

export function OfferCouponPublicForm(props: OfferCouponPublicProps) {
  const { className, commonValues, onBack, ...rest } = props;

  const validationSchema = Yup.object({
    coupon: Yup.string().required(),
    expiresAt: Yup.date().optional(),
  });

  const form = useForm<OfferCouponPublicValues>({
    validationSchema,
  });

  const [mutation] = useOfferCouponPublicFormCreateMutation();

  const handleSubmit: FormProps<OfferCouponPublicValues>['onValid'] = async (
    values,
  ) => {
    const variables: OfferCouponPublicFormCreateMutationVariables = {
      input: {
        brandId: commonValues.brandId,
        campaignId: commonValues.campaignId,
        categoryIds: commonValues.categoryIds,
        localeId: commonValues.localeId,
        url: commonValues.url,
        name: commonValues.name,
        descriptionDelta: commonValues.description,
        storeIds: commonValues.storeIds,
        coupon: values.coupon,
        expiresAt: values.expiresAt,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferCouponPublicForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.coupon}
          required
          label="Coupon"
          name="coupon"
        >
          <FormInput name="coupon" />
        </FormItem>
        <FormItem
          className={styles.expiresAt}
          label="Date d'expiration"
          name="expiresAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
            name="expiresAt"
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer l&apos;offre
          </Button>
          {!!onBack && (
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onBack}
              htmlType="button"
            >
              Revenir en arrière
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type OfferCouponPublicProps = ChildFormProps<
  OfferCouponPublicValues,
  OfferCouponPublicFormCreateMutation
> & {
  className?: string;
  commonValues: OfferCommonFormValues;
  onBack?: () => void;
};
