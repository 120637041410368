import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Scalars } from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormRichTextEditor } from '@components/atoms/FormRichTextEditor/FormRichTextEditor';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './HappeningUpdateForm.module.css';

import {
  HappeningUpdateFormFragment,
  HappeningUpdateFormMutation,
  HappeningUpdateFormMutationVariables,
  useHappeningUpdateFormMutation,
} from './HappeningUpdateForm.generated';

export type HappeningUpdateFormValues = {
  description: Scalars['Delta'];
  happeningStartsAt: Date;
  happeningEndsAt: Date;
  registrationStartsAt: Date;
  registrationEndsAt: Date;
  maxRegistrationCount?: number | null;
};

export function HappeningUpdateForm(props: HappeningUpdateFormProps) {
  const { className, hideFooter, happening, ...rest } = props;

  const { t } = useTranslation('happenings');

  const validationSchema = Yup.object({
    description: Yup.object().required(),
    happeningStartsAt: Yup.date().required(),
    happeningEndsAt: Yup.date().required(),
    registrationStartsAt: Yup.date().required(),
    registrationEndsAt: Yup.date().required(),
    maxRegistrationCount: Yup.number().nullable(),
  }).required();

  const form = useForm<HappeningUpdateFormValues>({
    validationSchema,
    defaultValues: {
      description: happening.description,
      happeningStartsAt: happening.happeningStartsAt,
      happeningEndsAt: happening.happeningEndsAt,
      registrationStartsAt: happening.registrationStartsAt,
      registrationEndsAt: happening.registrationEndsAt,
      maxRegistrationCount: happening.maxRegistrationCount,
    },
  });

  const [mutation] = useHappeningUpdateFormMutation();

  const handleSubmit: FormProps<HappeningUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: HappeningUpdateFormMutationVariables = {
      happeningId: happening.id,
      input: {
        description: values.description,
        happeningStartsAt: values.happeningStartsAt,
        happeningEndsAt: values.happeningEndsAt,
        registrationStartsAt: values.registrationStartsAt,
        registrationEndsAt: values.registrationEndsAt,
        maxSeatsCount: values.maxRegistrationCount || null,
      },
    };

    try {
      const { data } = await mutation({
        variables,
        refetchQueries: ['HappeningIdView'],
      });
      message.success(t('HappeningUpdateForm.successMessage'));
      return data;
    } catch (err) {
      handleMutationErrors(err, {});
    }
  };

  const { happeningStartsAt, happeningEndsAt, registrationStartsAt } =
    form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="HappeningUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.description}
          required
          label={t('fields.description')}
          name="description"
        >
          <FormRichTextEditor name="description" />
        </FormItem>

        <FormItem
          className={styles.happeningStartsAt}
          required
          label={t('fields.happeningStartsAt')}
          name="happeningStartsAt"
        >
          <FormInputDate
            name="happeningStartsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
          />
        </FormItem>

        <FormItem
          className={styles.happeningEndsAt}
          required
          label={t('fields.happeningEndsAt')}
          name="happeningEndsAt"
        >
          <FormInputDate
            name="happeningEndsAt"
            showTime={true}
            disabledDate={(date) => date.isBefore(happeningStartsAt)}
          />
        </FormItem>

        <FormItem
          className={styles.registrationStartsAt}
          required
          label={t('fields.registrationStartsAt')}
          name="registrationStartsAt"
        >
          <FormInputDate
            name="registrationStartsAt"
            showTime={true}
            disabled={!happeningStartsAt || !happeningEndsAt}
            disabledDate={(date) =>
              date.isBefore(new Date()) || date.isAfter(happeningEndsAt)
            }
          />
        </FormItem>

        <FormItem
          className={styles.registrationEndsAt}
          required
          label={t('fields.registrationEndsAt')}
          name="registrationEndsAt"
        >
          <FormInputDate
            name="registrationEndsAt"
            showTime={true}
            disabled={!happeningStartsAt || !happeningEndsAt}
            disabledDate={(date) =>
              date.isBefore(registrationStartsAt) ||
              date.isAfter(happeningEndsAt)
            }
          />
        </FormItem>

        <FormItem
          className={styles.maxRegistrationCount}
          label={t('fields.maxRegistrationCount')}
          name="maxRegistrationCount"
        >
          <FormInputNumber name="maxRegistrationCount" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('HappeningUpdateForm.update')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type HappeningUpdateFormProps = ChildFormProps<
  HappeningUpdateFormValues,
  HappeningUpdateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
  happening: HappeningUpdateFormFragment;
};
