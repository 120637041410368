import { useTranslation } from 'react-i18next';
import { Button, List, Popconfirm } from 'antd';
import classnames from 'classnames';
import VirtualList from 'rc-virtual-list';
import * as Yup from 'yup';

import AddressForm from '../AddressForm/AddressForm';

import { CountryCode, UserRole } from '@graphql/generated/types';

import { AuditTrailMessageFormatter } from '@components/atoms/AuditTrailMessageFormatter/AuditTrailMessageFormatter';
import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectUserRole } from '@components/atoms/FormSelectUserRole/FormSelectUserRole';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './UserUpdateForm.module.css';

import {
  UserUpdateFormFragment,
  UserUpdateFormUpdateMutation,
  UserUpdateFormUpdateMutationVariables,
  useUserBanMutation,
  useUserUnbanMutation,
  useUserUpdateFormUpdateMutation,
} from './UserUpdateForm.generated';

type Address = {
  address1?: string | null;
  address2?: string | null;
  country?: string | null;
  province?: string | null;
  zip?: string | null;
  city?: string | null;
} | null;

const addressShape = Yup.object().shape(
  {
    address1: Yup.string().when(['country', 'zip', 'address2', 'province'], {
      is: (country: string, zip: string, address2: string, province: string) =>
        country || zip || address2 || province,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    address2: Yup.string().nullable(),
    country: Yup.string().when(['address1', 'zip', 'address2', 'province'], {
      is: (address1: string, zip: string, address2: string, province: string) =>
        address1 || zip || address2 || province,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    province: Yup.string().nullable(),
    zip: Yup.string().when(['country', 'address1', 'address2', 'province'], {
      is: (
        country: string,
        address1: string,
        address2: string,
        province: string,
      ) => country || address1 || address2 || province,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    city: Yup.string().nullable(),
  },
  [
    ['country', 'zip'],
    ['address1', 'zip'],
    ['country', 'address1'],
  ],
);

const formatAddress = (address: Address) => {
  return address?.address1 && address?.country && address?.zip
    ? {
        address1: address.address1,
        address2: address.address2,
        country: address.country,
        province: address.province,
        zip: address.zip,
        city: address.city,
      }
    : null;
};

export type UserUpdateFormValues = {
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  nationality?: CountryCode | null;
  birthdate?: Date | null;
  phone?: string | null;
  shippingAddress: Address;
  billingAddress: Address;
  isShippingSameAsBillingAddress?: boolean | null;
};

export function UserUpdateForm(props: UserUpdateFormProps) {
  const { className, user, ...rest } = props;
  const isSuperAdmin = user.role === UserRole.Superadmin;

  const { t } = useTranslation('users');

  const validationSchema = Yup.object({
    role: Yup.mixed().oneOf(Object.values(UserRole)).required(),
    email: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    nationality: Yup.mixed()
      .nullable()
      .oneOf([null, ...Object.values(CountryCode)]),
    birthdate: Yup.date().nullable(),
    phone: Yup.string().nullable(),
    shippingAddress: addressShape.when(`isShippingSameAsBillingAddress`, {
      is: true,
      then(schema) {
        return schema.nullable();
      },
      otherwise(schema) {
        return schema.required();
      },
    }),
    billingAddress: addressShape,
    isShippingSameAsBillingAddress: Yup.boolean().nullable(),
  }).required();

  const form = useForm<UserUpdateFormValues>({
    validationSchema,
    defaultValues: {
      ...user,
    },
  });

  const [mutation] = useUserUpdateFormUpdateMutation();
  const [banUser] = useUserBanMutation();
  const [unbanUser] = useUserUnbanMutation();

  const handleSubmit: FormProps<UserUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!user || isSuperAdmin) return;

    const shippingAddress = formatAddress(values.shippingAddress);
    const billingAddress = formatAddress(values.billingAddress);

    const variables: UserUpdateFormUpdateMutationVariables = {
      userId: user.id,
      input: {
        role: values.role,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        nationality: values.nationality,
        birthdate: values.birthdate,
        phone: values.phone ? values.phone.trim() : undefined,
        shippingAddress,
        billingAddress,
        isShippingSameAsBillingAddress: values.isShippingSameAsBillingAddress,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  const handleBan = async () => {
    if (isSuperAdmin) return;

    const variables = { userId: user.id };

    if (user.bannedAt) {
      await unbanUser({ variables });
    } else {
      await banUser({ variables });
    }
  };

  const trails = user?.auditTrailPagination?.nodes;

  const isShippingSameAsBillingAddress = form.watch(
    'isShippingSameAsBillingAddress',
  );

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="UserUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.email}
          required
          label={t('userUpdateForm.fields.email.label')}
          name="email"
        >
          <FormInput name="email" />
        </FormItem>
        <FormItem
          className={styles.firstName}
          required
          label={t('userUpdateForm.fields.firstName.label')}
          name="firstName"
        >
          <FormInput name="firstName" />
        </FormItem>
        <FormItem
          className={styles.lastName}
          required
          label={t('userUpdateForm.fields.lastName.label')}
          name="lastName"
        >
          <FormInput name="lastName" />
        </FormItem>
        <FormItem
          className={styles.role}
          required
          label={t('userUpdateForm.fields.role.label')}
          name="role"
        >
          <FormSelectUserRole name="role" />
        </FormItem>
        <FormItem
          className={styles.nationality}
          label={t('userUpdateForm.fields.nationality.label')}
          name="nationality"
        >
          <FormSelectCountry clearable={true} name="nationality" />
        </FormItem>
        <FormItem
          className={styles.birthdate}
          label={t('userUpdateForm.fields.birthdate.label')}
          name="birthdate"
        >
          <FormInputDate name="birthdate" />
        </FormItem>
        <FormItem
          className={styles.phone}
          label={t('userUpdateForm.fields.phone.label')}
          name="phone"
        >
          <FormInput name="phone" />
        </FormItem>
        <div className={styles.billingAddress}>
          <div className={styles.billingAddress}>
            <AddressForm
              title={t('userUpdateForm.fields.billingAddress.label')}
              formKey="billingAddress"
              form={form}
            />
          </div>
        </div>
        <FormItem
          className={styles.isShippingSameAsBillingAddress}
          label={t(
            'userUpdateForm.fields.isShippingSameAsBillingAddress.label',
          )}
          name="isShippingSameAsBillingAddress"
        >
          <FormCheckbox name="isShippingSameAsBillingAddress" />
        </FormItem>
        <div className={styles.preferencesNewsletters}>
          <FormItem
            label={t(
              'userUpdateForm.fields.preferences.newsletters.global.label',
            )}
            name="preferences.properties.newsletters.global"
          >
            <FormCheckbox
              disabled
              name="preferences.properties.newsletters.global"
            />
          </FormItem>
          <FormItem
            label={t(
              'userUpdateForm.fields.preferences.newsletters.company.label',
            )}
            name="preferences.properties.newsletters.company"
          >
            <FormCheckbox
              disabled
              name="preferences.properties.newsletters.company"
            />
          </FormItem>
          <FormItem
            label={t(
              'userUpdateForm.fields.preferences.newsletters.partner.label',
            )}
            name="preferences.properties.newsletters.partner"
          >
            <FormCheckbox
              disabled
              name="preferences.properties.newsletters.partner"
            />
          </FormItem>
        </div>
        {!isShippingSameAsBillingAddress && (
          <div className={styles.shippingAddress}>
            <AddressForm
              title={t('userUpdateForm.fields.shippingAddress.label')}
              formKey="shippingAddress"
              form={form}
            />
          </div>
        )}

        {trails && (
          <div className={styles.auditTrail}>
            <List
              size="small"
              header={
                <div className={styles.listHeader}>
                  {t('auditTrail.header')}
                </div>
              }
              bordered
            >
              <VirtualList
                data={trails}
                height={194}
                itemHeight={40}
                itemKey="auditTrail"
              >
                {(item) => <AuditTrailMessageFormatter auditTrail={item} />}
              </VirtualList>
            </List>
          </div>
        )}
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
            disabled={isSuperAdmin}
          >
            {t('userUpdateForm.submit')}
          </Button>
          <Popconfirm
            title={t('userUpdateForm.banConfirm')}
            onConfirm={handleBan}
            okText={t('userUpdateForm.yesBan')}
            cancelText={t('userUpdateForm.noBan')}
            disabled={isSuperAdmin}
          >
            <Button
              className={styles.banButton}
              loading={form.formState.isSubmitting}
              danger
              type={user.bannedAt ? 'primary' : 'default'}
              disabled={isSuperAdmin}
              //onClick={() => handleBan()}
            >
              {user.bannedAt
                ? t('userUpdateForm.cancelBan')
                : t('userUpdateForm.ban')}
            </Button>
          </Popconfirm>
        </div>
      </Form>
    </Card>
  );
}

export type UserUpdateFormProps = ChildFormProps<
  UserUpdateFormValues,
  UserUpdateFormUpdateMutation
> & {
  className?: string;
  user: UserUpdateFormFragment;
};
