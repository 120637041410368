import classNames from 'classnames';

import { ProgressivePicture } from '@components/atoms/ProgressivePicture/ProgressivePicture';
import { ArticleItemContentFragment } from '@components/views/Communication/Articles/ArticleIdView/ArticleIdView.generated';

import styles from './ArticleCard.module.css';

export const ArticleCard = (props: ArticleCardProps) => {
  const { article, className } = props;

  const picture = article.cover;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        {picture && (
          <ProgressivePicture
            mode="cover"
            picture={picture}
            className={styles.picture}
          />
        )}
        <div className={styles.overlay} />
        <div className={styles.texts}>
          <div className={styles.name} title={article.title}>
            {article.title}
          </div>
        </div>
      </div>
    </div>
  );
};

export type ArticleCardProps = {
  article: ArticleItemContentFragment;
  className?: string;
};
