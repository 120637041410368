/* eslint-disable @typescript-eslint/no-explicit-any */

export function downloadObjectToJson(
  object: Record<string, any>,
  fileName: string,
) {
  const headers = Object.keys(object);
  const row = headers.map((h) => object[h]);

  return downloadArrayToJson([headers, row], fileName);
}

export function downloadArrayToJson(rows: any[][], fileName: string) {
  const csvContent =
    'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csvContent));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}
