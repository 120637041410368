import { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';

import styles from './FilterDropdownSearchableEnum.module.css';

export function FilterDropdownSearchableEnum({
  options,
  onChange,
  values,
}: FilterDropdownSearchableEnumProps) {
  return (
    <div className={styles.root}>
      <Select
        optionFilterProp="label"
        options={options as SelectProps<SelectValue>['options']}
        value={values}
        onChange={onChange}
        mode="multiple"
        allowClear={true}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus
      />
    </div>
  );
}

export type FilterDropdownSearchableEnumProps = {
  options: {
    value: string | number;
    label: ReactNode;
  }[];
  onChange: (checked: string[]) => void;
  values: string[];
};
