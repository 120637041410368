import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { UserRole } from '@graphql/generated/types';

import styles from './FormSelectUserRole.module.css';

export const FormSelectUserRole: React.FC<FormSelectUserRoleProps> = (
  props,
) => {
  const { className, name } = props;
  const { t } = useTranslation('users');

  const options: { label: string; value: UserRole }[] = [
    { value: UserRole.User, label: t('userRole.User') },
    { value: UserRole.Superadmin, label: t('userRole.Superadmin') },
  ];

  return (
    <FormSelect
      name={name}
      className={classnames(className, styles.root)}
      options={options}
    />
  );
};

export type FormSelectUserRoleProps = {
  name: string;
  className?: string;
};
