import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, DatePickerProps } from 'antd';
import classnames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';

import 'antd/es/date-picker/style/index';

import styles from './FormInputDate.module.css';

export function FormInputDate(props: FormInputDateProps) {
  const { className, name, showTime = false, ...rest } = props;
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DatePicker
          {...rest}
          picker="date"
          name={name}
          className={classnames(styles.root, className)}
          value={field.value ? moment(field.value) : null}
          ref={field.ref}
          onBlur={field.onBlur}
          showTime={showTime}
          onChange={(value) => {
            field.onChange(value?.toString());

            if (get(errors, name)) {
              trigger(name);
            }
          }}
        />
      )}
    />
  );
}

export type FormInputDateProps = Omit<
  DatePickerProps,
  'name' | 'className' | 'onChange' | 'onBlur' | 'ref' | 'value' | 'picker'
> & {
  name: string;
  className?: string;
  showTime?: boolean;
};
