import { Button, Modal as AntModal } from 'antd';
import classnames from 'classnames';

import { BeneficiaryProofsTable } from '@components/tables/BeneficiaryProofsTable/BeneficiaryProofsTable';

import styles from './BeneficiaryProofsModal.module.css';

import { useBeneficiaryProofsModalQuery } from './BeneficiaryProofsModal.generated';

export type BeneficiaryProofsModalProps = {
  className?: string;
  onClose?: () => void;
  isOpen?: boolean;
  beneficiaryId?: string | null;
};

export function BeneficiaryProofsModal(props: BeneficiaryProofsModalProps) {
  const { className, onClose, isOpen, beneficiaryId } = props;

  const query = useBeneficiaryProofsModalQuery({
    variables: {
      beneficiaryId: beneficiaryId || '',
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleClose = () => {
    onClose?.();
  };

  return (
    <AntModal
      className={classnames(className, styles.root)}
      visible={isOpen || false}
      destroyOnClose
      onCancel={handleClose}
      title="BeneficiaryProofsModal"
      footer={[
        <Button onClick={onClose} key="cancel" htmlType="button" type="primary">
          Fermer
        </Button>,
      ]}
    >
      <BeneficiaryProofsTable
        data={query.data?.beneficiary?.proofsPagination}
        query={query}
        activeColumns={['originalName']}
      />
    </AntModal>
  );
}
