import { useTranslation } from 'react-i18next';

import { I18nLink } from '@components/atoms/Link/Link';
import { OrderRefundStatusTag } from '@components/atoms/OrderRefundStatusTag/OrderRefundStatusTag';
import { StripeRefundStatusTag } from '@components/atoms/StripeRefundStatusTag/StripeRefundStatusTag';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDateTime } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  OrderRefundTableQueryVariables,
  OrderRefundTableRecordFragment,
  useOrderRefundTableQuery,
} from './OrderRefundsTable.generated';

type RecordType = OrderRefundTableRecordFragment;

type Column = 'order' | 'stripeAmount' | 'status' | 'stripeStatus';

export function OrderRefundsTable(props: OrderRefundsTableProps) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    ...rest
  } = props;

  const { t } = useTranslation('orderRefund');

  const query =
    propQuery ||
    useOrderRefundTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['node', 'id'],
      render: (id) => <I18nLink to={`/orders/refund/${id}`}>{id}</I18nLink>,
    },
    {
      key: 'createdAt',
      title: t('table.headers.createdAt'),
      dataIndex: ['node', 'createdAt'],
      render: (createdAt) => <TableRendererDateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('table.headers.updatedAt'),
      dataIndex: ['node', 'updatedAt'],
      render: (updatedAt) => <TableRendererDateTime value={updatedAt} />,
    },
    {
      key: 'order',
      title: t('table.headers.order', 'Commande'),
      dataIndex: ['node', 'order'],
      render: (order) => (
        <I18nLink to={`/orders/list/${order.id}`}>
          {order.invoice.identifier}
        </I18nLink>
      ),
    },
    {
      key: 'stripeAmount',
      dataIndex: ['node', 'stripeAmount'],
      title: t('table.headers.stripeAmount'),
      render: (
        stripeAmount: number,
        {
          node: {
            order: { currency },
          },
        },
      ) => (
        <TableRendererCurrency
          value={{
            amount: stripeAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'feesAmount',
      dataIndex: ['node', 'feesAmount'],
      title: t('table.headers.feesAmount'),
      render: (
        feesAmount: number,
        {
          node: {
            order: { currency },
          },
        },
      ) => (
        <TableRendererCurrency
          value={{
            amount: feesAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      render: (_, edge) => <OrderRefundStatusTag orderRefund={edge.node} />,
    },
    {
      key: 'stripeStatus',
      title: t('table.headers.stripeStatus'),
      dataIndex: ['node', 'stripeRefund'],
      render: (stripeRefund) => (
        <StripeRefundStatusTag stripeRefund={stripeRefund} />
      ),
    },
  ];
  return (
    <div className={className}>
      <TablePagination
        id="OrderRefundsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.orderRefundPagination}
        rowKey="cursor"
        {...rest}
      />
    </div>
  );
}

export type OrderRefundsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: OrderRefundTableQueryVariables['filter'];
};
