import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import QueryString from 'qs';

import { Company } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { ArticlesTable } from '@components/tables/ArticlesTable/ArticlesTable';

export const CompanyIdArticlesView: React.FC<CompanyIdArticlesViewProps> = (
  props,
) => {
  const { className, companyId } = props;
  const { t } = useTranslation('communication');

  return (
    <div className={classnames(className)}>
      <ArticlesTable
        activeColumns={['title', 'category', 'createdAt', 'updatedAt']}
        filter={{ category: { company: { id: { is: companyId } } } }}
        title={() => (
          <>
            <I18nLink
              to={{
                pathname: `/communication/articles/create`,
                search: QueryString.stringify({ companyId }),
              }}
            >
              <Button>
                {t('articles.table.create', 'Ajouter un article')}
              </Button>
            </I18nLink>
          </>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/communication/articles/create`,
                search: QueryString.stringify({ companyId }),
              }}
            >
              <Button>
                {t('articles.table.create', 'Ajouter un article')}
              </Button>
            </I18nLink>
          ),
        }}
      />
    </div>
  );
};

export type CompanyIdArticlesViewProps = {
  companyId: Company['id'];
  className?: string;
};
