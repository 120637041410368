import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './SearchSynchronizationIdView.module.css';

import { useSearchSynchronizationIdViewQuery } from './SearchSynchronizationIdView.generated';

export const SearchSynchronizationIdView: React.FC<SearchSynchronizationIdViewProps> =
  (props) => {
    const { className, match } = props;
    const {
      params: { searchSynchronizationId },
    } = match;

    const query = useSearchSynchronizationIdViewQuery({
      variables: { searchSynchronizationId },
      notifyOnNetworkStatusChange: true,
    });

    const searchSynchronization = query?.data?.searchSynchronization;

    if (query.loading && !searchSynchronization) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!searchSynchronization) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        {JSON.stringify(searchSynchronization)}
      </div>
    );
  };

export type SearchSynchronizationIdViewProps = RouteComponentProps<{
  searchSynchronizationId: string;
}> & {
  className?: string;
};
