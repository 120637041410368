import { useTranslation } from 'react-i18next';
import { Popover, Tag } from 'antd';
import classnames from 'classnames';

import { ProductSynchronizationStatus } from '@graphql/generated/types';

import styles from './ProductSynchronizationStatusTag.module.css';

import { ProductSynchronizationStatusTagFragment } from './ProductSynchronizationStatusTag.generated';

export const ProductSynchronizationStatusTag: React.FC<ProductSynchronizationStatusTagProps> =
  (props) => {
    const { className, productSynchronization } = props;
    const { t } = useTranslation('productSynchronizations');

    const options: Record<
      ProductSynchronizationStatus,
      {
        label: string;
        color: string;
      }
    > = {
      ACTIVE: { label: t('status.active'), color: 'blue' },
      CANCELED: { label: t('status.canceled'), color: 'default' },
      COMPLETED: { label: t('status.completed'), color: 'success' },
      FAILED: { label: t('status.failed'), color: 'error' },
      PENDING: { label: t('status.pending'), color: 'default' },
    };

    const content = (
      <Tag
        className={classnames(className, styles.root)}
        color={options[productSynchronization.status].color}
      >
        {options[productSynchronization.status].label}
      </Tag>
    );

    if (productSynchronization.error) {
      return (
        <Popover
          content={
            <pre style={{ margin: 0 }}>{productSynchronization.error}</pre>
          }
        >
          {content}
        </Popover>
      );
    }

    return content;
  };

export type ProductSynchronizationStatusTagProps = {
  className?: string;
  productSynchronization: ProductSynchronizationStatusTagFragment;
};
