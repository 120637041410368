import { Spin } from 'antd';

import { I18nLink } from '@components/atoms/Link/Link';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useSubventionSchemaParentViewQuery } from './SubventionSchemaParentView.generated';

export const SubventionSchemaParentView = (
  props: SubventionSchemaParentViewProps,
) => {
  const { subventionSchemaId } = props;

  const query = useSubventionSchemaParentViewQuery({
    variables: {
      subventionSchemaId,
    },
  });

  const subventionSchema = query.data?.subventionSchema;

  if (query.loading && !subventionSchema) {
    return <Spin size="large" />;
  } else if (!subventionSchema || !subventionSchema.parent) {
    return <NotFoundView />;
  }

  return (
    <div>
      <I18nLink to={`/subventions/schemas/${subventionSchema.parent.id}`}>
        {subventionSchema.parent.name}
      </I18nLink>
    </div>
  );
};

export type SubventionSchemaParentViewProps = {
  subventionSchemaId: string;
};
