import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { PictureUpload } from '../PictureUpload/PictureUpload';

import { FileType } from '@graphql/generated/types';

import { ProgressivePicture } from '@components/atoms/ProgressivePicture/ProgressivePicture';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';

import styles from './CoverLibrarySelect.module.css';

import { useCoverLibrarySelectPictureStockQuery } from './CoverLibrarySelect.generated';

export type CoverLibrarySelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  deleteable?: boolean;
};

export const CoverLibrarySelect = <
  T extends FieldValues = Record<string, unknown>,
>({
  control,
  name,
  deleteable = true,
}: CoverLibrarySelectProps<T>) => {
  const { data, loading } = useCoverLibrarySelectPictureStockQuery({
    variables: { take: 7 },
  });

  const [uploadedPicture, setUploadedPicture] =
    useState<ProgressivePictureFragment | null>();

  const controller = useController({ name, control });

  useEffect(() => {
    if (
      !loading &&
      !data?.pictureStockPagination.nodes.some(
        (pictureStock) =>
          pictureStock.picture.id === controller.field.value?.id,
      )
    ) {
      setUploadedPicture(controller.field.value);
    }
  }, [controller.field.value, data, loading]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <div>
          <div>
            <PictureUpload
              inputClassName={styles.hidden}
              onSuccess={(picture) => {
                setUploadedPicture(picture);
                onChange(picture);
              }}
              type={FileType.ArticleCover}
            >
              <Button>+ Changer la couverture</Button>
              {/* <>
                {uploadedPicture && (
                  <ProgressivePicture
                    picture={uploadedPicture}
                    className={styles.thumbnail}
                  />
                )}
              </> */}
            </PictureUpload>
          </div>
          {uploadedPicture && (
            <>
              <Button
                className={styles.thumbnail}
                onClick={() => onChange(uploadedPicture)}
              >
                <ProgressivePicture
                  picture={uploadedPicture}
                  className={styles.cover}
                />

                {value?.id === uploadedPicture.id && (
                  <div className={styles.uploaded}>
                    <CheckOutlined />
                  </div>
                )}
              </Button>
              {deleteable && (
                <Button
                  type="primary"
                  shape="round"
                  className={styles.delete}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setUploadedPicture(null);
                    onChange(null);
                  }}
                >
                  Supprimer
                </Button>
              )}
            </>
          )}
          <p className={styles.libraryTitle}>
            <em>Choisir parmis les couvertures de la bibliothèque: </em>
          </p>
          <div>
            {loading
              ? Array(uploadedPicture ? 6 : 7)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className={styles.fakeImage} />
                  ))
              : data?.pictureStockPagination.nodes.map((pictureStock) => (
                  <Button
                    key={pictureStock.id}
                    className={styles.thumbnail}
                    onClick={() => onChange(pictureStock.picture)}
                  >
                    <ProgressivePicture
                      picture={pictureStock.picture}
                      className=""
                    />
                  </Button>
                ))}
          </div>
        </div>
      )}
    />
  );
};
