import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { ProductType } from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectBrand } from '@components/atoms/FormSelectBrand/FormSelectBrand';
import { FormSelectLocale } from '@components/atoms/FormSelectLocale/FormSelectLocale';
import { FormSelectProductCatalog } from '@components/atoms/FormSelectProductCatalog/FormSelectProductCatalog';
import { FormSelectProductType } from '@components/atoms/FormSelectProductType/FormSelectProductType';
import { FormSelectTaxRate } from '@components/atoms/FormSelectTaxRate/FormSelectTaxRate';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductCreateForm.module.css';

import {
  ProductCreateFormMutation,
  ProductCreateFormMutationVariables,
  useProductCreateFormMutation,
} from './ProductCreateForm.generated';

export type ProductCreateFormValues = {
  catalogId: string;
  brandId: string;
  type: ProductType;
  SKU: string;
  expiresAt?: Date | null;
  expiresInDays?: number | null;
  taxRateIds: string[];
  translation: {
    localeId: string;
    name: string;
  };
};

export function ProductCreateForm(props: ProductCreateFormProps) {
  const { className, defaultValues, ...rest } = props;
  const { t } = useTranslation('products');

  const validationSchema = Yup.object({
    catalogId: Yup.string().required(),
    brandId: Yup.string().required(),
    type: Yup.mixed().oneOf(Object.values(ProductType)).required(),
    SKU: Yup.string().required(),
    translation: Yup.object({
      localeId: Yup.string().required(),
      name: Yup.string().required(),
    }).required(),
    expiresAt: Yup.date().nullable(),
    expiresInDays: Yup.number().min(1).nullable(),
    taxRateIds: Yup.array(Yup.string().required()).optional(),
  }).required();

  const form = useForm<ProductCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useProductCreateFormMutation();

  const handleSubmit: FormProps<ProductCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: ProductCreateFormMutationVariables = {
      brandId: values.brandId,
      catalogId: values.catalogId,
      input: {
        type: values.type,
        translations: [values.translation],
        expiresAt: values.expiresAt,
        expiresInDays: values.expiresInDays,
        taxRateIds: values.taxRateIds,
        SKU: values.SKU,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.catalog}
          required
          label={t('fields.catalog')}
          name="catalogId"
        >
          <FormSelectProductCatalog
            filter={{ provider: { isInternal: { is: true } } }}
            name="catalogId"
          />
        </FormItem>

        <FormItem
          className={styles.brand}
          required
          label={t('fields.brand')}
          name="brandId"
        >
          <FormSelectBrand name="brandId" />
        </FormItem>

        <FormItem
          className={styles.type}
          required
          label={t('fields.type')}
          name="type"
        >
          <FormSelectProductType name="type" />
        </FormItem>

        <FormItem
          className={styles.SKU}
          required
          label={t('fields.SKU')}
          name="SKU"
        >
          <FormInput name="SKU" />
        </FormItem>

        <FormItem
          className={styles.taxRates}
          required
          label={t('fields.taxRates')}
          name="taxRateIds"
        >
          <FormSelectTaxRate
            name="taxRateIds"
            multiple={true}
            clearable={true}
          />
        </FormItem>

        <FormItem
          className={styles.translationLocale}
          required
          label={t('fields.translation.locale')}
          name="translation.localeId"
        >
          <FormSelectLocale name="translation.localeId" />
        </FormItem>

        <FormItem
          className={styles.translationName}
          required
          label={t('fields.translation.name')}
          name="translation.name"
        >
          <FormInput name="translation.name" />
        </FormItem>

        <FormItem
          className={styles.expiresAt}
          label={t('fields.expiresAt')}
          name="expiresAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
            name="expiresAt"
          />
        </FormItem>

        <FormItem
          className={styles.expiresInDays}
          required
          label={t('fields.expiresInDays')}
          name="expiresInDays"
        >
          <FormInputNumber name="expiresInDays" min={0} />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.create')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ProductCreateFormProps = ChildFormProps<
  ProductCreateFormValues,
  ProductCreateFormMutation
> & {
  className?: string;
  defaultValues?: ProductCreateFormValues;
};
