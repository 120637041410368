import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import classnames from 'classnames';

import 'react-quill/dist/quill.snow.css';
import styles from './FormRichTextEditor.module.css';

export function FormRichTextEditor(props: FormRichTextEditorProps) {
  const { className, name, modules, ...rest } = props;
  const { control, trigger } = useFormContext();

  const editorModules = modules || {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // ['blockquote', 'code-block'],
      ['image', 'link'],
      ['clean'], // remove formatting button

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      // [{ header: [1, 2, 3, false] }], // custom dropdown

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      // [{ align: [] }],
    ],
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <ReactQuill
          {...rest}
          theme="snow"
          modules={editorModules}
          value={field.value}
          ref={field.ref}
          onBlur={field.onBlur}
          onChange={(_value, _delta, _source, editor) => {
            if (editor.getLength() > 1) {
              field.onChange(editor.getContents());
            } else {
              field.onChange(undefined);
            }

            if (fieldState.error) {
              trigger(name);
            }
          }}
          className={classnames(styles.root, className)}
        />
      )}
    />
  );
}

export type FormRichTextEditorProps = Omit<ReactQuillProps, 'onChange'> & {
  name: string;
  className?: string;
  modules?: ReactQuillProps['modules'];
};
