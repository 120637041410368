import { useState } from 'react';
import { Checkbox } from 'antd';

import ErrorModal from '@components/modals/ErrorModal/ErrorModal';

import {
  usePublicationPublishCheckboxPublishPublicationMutation,
  usePublicationPublishCheckboxQuery,
  usePublicationPublishCheckboxUnpublishPublicationMutation,
} from './PublicationPublishCheckbox.generated';

type Props = {
  publicationId: string;
};

const PublicationPublishCheckbox = ({ publicationId }: Props) => {
  const [error, setError] = useState<string>();

  const { data } = usePublicationPublishCheckboxQuery({
    variables: { id: publicationId },
  });

  const [publishPublication] =
    usePublicationPublishCheckboxPublishPublicationMutation({
      variables: { publicationId },
      optimisticResponse: {
        __typename: 'Mutation',
        publicationPublish: {
          __typename: 'PublicationCreateOutput',
          publication: {
            __typename: 'Publication',
            id: publicationId,
            publishedAt: new Date(),
          },
        },
      },
    });

  const [unpublishPublication] =
    usePublicationPublishCheckboxUnpublishPublicationMutation({
      variables: { publicationId },
      optimisticResponse: {
        __typename: 'Mutation',
        publicationUnpublish: {
          __typename: 'PublicationCreateOutput',
          publication: {
            __typename: 'Publication',
            id: publicationId,
            publishedAt: null,
          },
        },
      },
    });

  const handlePublishPublication = async () => {
    try {
      await publishPublication();
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <div>
      <Checkbox
        checked={!!data?.publication?.publishedAt}
        onChange={() =>
          data?.publication?.publishedAt
            ? unpublishPublication()
            : handlePublishPublication()
        }
      >
        Publiée
      </Checkbox>
      <ErrorModal onClose={() => setError(undefined)} error={error} />
    </div>
  );
};

export default PublicationPublishCheckbox;
