import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { CompanyPersona, FileType } from '@graphql/generated/types';

import { LOGO_RESOLUTION_INFO, LOGO_SIZE_INFO } from '@utils/constants';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormPicture } from '@atoms/FormPicture/FormPicture';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyCreateForm.module.css';

import {
  CompanyCreateFormMutation,
  CompanyCreateFormMutationVariables,
  useCompanyCreateFormMutation,
} from './CompanyCreateForm.generated';

export type CompanyCreateFormValues = {
  slug: string;
  name: string;
  persona: CompanyPersona;
  displayName?: string | null;
  email?: string | null;
  color?: string | null;
  logoId?: string;
  isDemo?: boolean | null;
  thresholdForDiscounts?: number | null;
  thresholdForFunds?: number | null;
  intraCommunityVat?: string | null;
  hubspotId?: string | null;
};

export function CompanyCreateForm(props: CompanyCreateFormProps) {
  const { className, defaultValues = {}, ...rest } = props;

  const { t } = useTranslation(['companies', 'common']);

  const validationSchema = Yup.object({
    slug: Yup.string().required(),
    name: Yup.string().required(),
    displayName: Yup.string().nullable(),
    email: Yup.string().email().required(),
    persona: Yup.mixed()
      .oneOf([null, ...Object.values(CompanyPersona)])
      .required(),
    color: Yup.string()
      .matches(/^#?([0-9A-F]{3}){1,2}$/i, {
        message: 'Invalid color',
        excludeEmptyString: true,
      })
      .nullable(),
    logoId: Yup.string(),
    isDemo: Yup.boolean(),
    thresholdForDiscounts: Yup.number()
      .min(0)
      .max(100)
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    thresholdForFunds: Yup.number()
      .min(0)
      .max(100)
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    intraCommunityVat: Yup.string().nullable(),
    hubspotId: Yup.string().when('isDemo', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(),
    }),
  }).required();

  const form = useForm<CompanyCreateFormValues>({
    validationSchema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const [mutation] = useCompanyCreateFormMutation();

  const handleSubmit: FormProps<CompanyCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: CompanyCreateFormMutationVariables = {
      input: {
        slug: values.slug,
        name: values.name,
        persona: values.persona,
        displayName: values.displayName?.trim() || null,
        email: values.email?.trim() || null,
        color: values.color?.trim() || null,
        logoId: values.logoId,
        isDemo: values.isDemo ? true : false,
        thresholdForDiscounts: values.thresholdForDiscounts || null,
        thresholdForFunds: values.thresholdForFunds || null,
        intraCommunityVat: values.intraCommunityVat?.trim() || null,
        hubspotId: values.hubspotId?.trim() || null,
      },
    };

    try {
      const { data } = await mutation({ variables });

      await message.success(t('common:messages.success.created'));

      return data;
    } catch (err) {
      if (err instanceof ApolloError) {
        message.error(err.message.replace('GraphQL error: ', ''));
      } else {
        message.error('Une erreur est survenue');
      }
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.slug}
          required
          label={t('companyCreateForm.fields.slug.label')}
          name="slug"
        >
          <FormInput name="slug" />
        </FormItem>

        <FormItem
          className={styles.isDemo}
          required
          label={t('fields.isDemo')}
          name="isDemo"
        >
          <FormCheckbox name="isDemo" />
        </FormItem>

        <FormItem
          className={styles.name}
          required
          label={t('companyCreateForm.fields.name.label')}
          name="name"
        >
          <FormInput name="name" />
        </FormItem>

        <FormItem
          className={styles.displayName}
          label={t('companyCreateForm.fields.displayName.label')}
          name="displayName"
        >
          <FormInput name="displayName" />
        </FormItem>

        <FormItem
          className={styles.email}
          required
          label="Identifiant Hubspot"
          name="hubspotId"
        >
          <FormInput name="hubspotId" />
        </FormItem>

        <FormItem
          className={styles.email}
          required
          label={t('companyCreateForm.fields.email.label')}
          name="email"
        >
          <FormInput name="email" />
        </FormItem>

        <FormItem
          className={styles.color}
          label={t('companyCreateForm.fields.color.label')}
          name="color"
        >
          <FormInput name="color" placeholder="#02B6A9" />
        </FormItem>

        <FormItem
          className={styles.thresholdForDiscounts}
          label={t('companyCreateForm.fields.thresholdForDiscounts.label')}
          name="thresholdForDiscounts"
        >
          <FormInputNumber addonAfter="%" name="thresholdForDiscounts" />
        </FormItem>

        <FormItem
          className={styles.thresholdForFunds}
          label={t('companyCreateForm.fields.thresholdForFunds.label')}
          name="thresholdForFunds"
        >
          <FormInputNumber addonAfter="%" name="thresholdForFunds" />
        </FormItem>
        <FormItem
          className={styles.intraCommunityVat}
          label={t('companyCreateForm.fields.intraCommunityVat.label')}
          name="intraCommunityVat"
        >
          <FormInput name="intraCommunityVat" />
        </FormItem>
        <FormItem
          className={styles.logo}
          label={t('companyCreateForm.fields.logoId.label')}
          name="logoId"
        >
          <FormPicture
            name="logoId"
            type={FileType.CompanyLogo}
            info={t('picture.advice', {
              resolution: LOGO_RESOLUTION_INFO,
              size: LOGO_SIZE_INFO,
              ns: 'common',
            })}
          />
        </FormItem>
        <FormItem
          className={styles.persona}
          required
          label={t('companyCreateForm.fields.persona.label')}
          name="persona"
        >
          <FormSelect
            clearable
            name="persona"
            options={[
              {
                label: t('fields.companyPersona.values.esc'),
                value: CompanyPersona.Esc,
              },
              {
                label: t('fields.companyPersona.values.hr'),
                value: CompanyPersona.Hr,
              },
              {
                label: t('fields.companyPersona.values.other'),
                value: CompanyPersona.Other,
              },
            ]}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('companyCreateForm.buttons.submit.label')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CompanyCreateFormProps = ChildFormProps<
  CompanyCreateFormValues,
  CompanyCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<CompanyCreateFormValues>;
};
