import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Select, Spin } from 'antd';

import {
  SubventionSchemaAccessType,
  SubventionSchemaStatus,
} from '@graphql/generated/types';

import { CompanySelectModal } from '@components/modals/CompanySelectModal/CompanySelectModal';
import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import {
  useSubventionSchemaBlacklistCompaniesAddMutation,
  useSubventionSchemaBlacklistCompaniesRemoveMutation,
  useSubventionSchemaVisibilityViewPublishMutation,
  useSubventionSchemaVisibilityViewQuery,
  useSubventionSchemaVisibilityViewUnpublishMutation,
  useSubventionSchemaVisibilityViewUpdateMutation,
  useSubventionSchemaWhitelistCompaniesAddMutation,
  useSubventionSchemaWhitelistCompaniesRemoveMutation,
} from './SubventionSchemaVisibilityView.generated';

export const SubventionSchemaVisibilityView = (
  props: SubventionSchemaVisibilityViewProps,
) => {
  const { subventionSchemaId } = props;
  const { t } = useTranslation('subvention-schemas');
  const [isAdding, setAdding] = useState(false);
  const [isRemoving, setRemoving] = useState<string | null>(null);

  const query = useSubventionSchemaVisibilityViewQuery({
    variables: { subventionSchemaId, skip: 0, take: 10 },
  });
  const [updateMutation, updateState] =
    useSubventionSchemaVisibilityViewUpdateMutation();
  const [publishMutation, publishState] =
    useSubventionSchemaVisibilityViewPublishMutation();
  const [unpublishMutation, unpublishState] =
    useSubventionSchemaVisibilityViewUnpublishMutation();

  const [companyWhitelistAddMutation] =
    useSubventionSchemaWhitelistCompaniesAddMutation({
      refetchQueries: ['SubventionSchemaVisibilityView'],
      awaitRefetchQueries: true,
    });

  const [companyWhitelistRemoveMutation] =
    useSubventionSchemaWhitelistCompaniesRemoveMutation({
      refetchQueries: ['SubventionSchemaVisibilityView'],
      awaitRefetchQueries: true,
    });

  const [companyBlacklistAddMutation] =
    useSubventionSchemaBlacklistCompaniesAddMutation({
      refetchQueries: ['SubventionSchemaVisibilityView'],
      awaitRefetchQueries: true,
    });

  const [companyBlacklistRemoveMutation] =
    useSubventionSchemaBlacklistCompaniesRemoveMutation({
      refetchQueries: ['SubventionSchemaVisibilityView'],
      awaitRefetchQueries: true,
    });

  const subventionSchema = query.data?.subventionSchema;

  if (query.loading && !subventionSchema) {
    return <Spin size="large" />;
  } else if (!subventionSchema) {
    return <NotFoundView />;
  }

  const handleAccessTypeChange = async (value: SubventionSchemaAccessType) => {
    try {
      await updateMutation({
        variables: {
          subventionSchemaId,
          input: {
            accessType: value,
          },
        },
      });
    } catch (err) {
      message.error('Une erreur est survenue');
    }
  };

  const handlePublish = async () => {
    try {
      await publishMutation({
        variables: {
          subventionSchemaId,
        },
      });
    } catch (err) {
      message.error('Une erreur est survenue');
    }
  };

  const handleUnpublish = async () => {
    try {
      await unpublishMutation({
        variables: {
          subventionSchemaId,
        },
      });
    } catch (err) {
      message.error('Une erreur est survenue');
    }
  };

  const handleWhitelistAdd = async (companyIds: string[]) => {
    try {
      await companyWhitelistAddMutation({
        variables: {
          subventionSchemaId,
          input: {
            whitelistCompanyIds: companyIds,
          },
        },
      });
      setAdding(false);
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    }
  };

  const handleWhitelistRemove = async (companyId: string) => {
    setRemoving(companyId);

    try {
      await companyWhitelistRemoveMutation({
        variables: {
          subventionSchemaId,
          input: {
            whitelistCompanyIds: [companyId],
          },
        },
      });
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    } finally {
      setRemoving(null);
    }
  };

  const handleBlacklistAdd = async (companyIds: string[]) => {
    try {
      await companyBlacklistAddMutation({
        variables: {
          subventionSchemaId,
          input: {
            blacklistCompanyIds: companyIds,
          },
        },
      });
      setAdding(false);
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    }
  };

  const handleBlacklistRemove = async (companyId: string) => {
    setRemoving(companyId);

    try {
      await companyBlacklistRemoveMutation({
        variables: {
          subventionSchemaId,
          input: {
            blacklistCompanyIds: [companyId],
          },
        },
      });
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    } finally {
      setRemoving(null);
    }
  };

  return (
    <div>
      {subventionSchema.status === SubventionSchemaStatus.Published ? (
        <Button onClick={handleUnpublish} loading={unpublishState.loading}>
          Dépublier
        </Button>
      ) : (
        <Button onClick={handlePublish} loading={publishState.loading}>
          Publier
        </Button>
      )}
      &nbsp; &nbsp;
      <Select
        value={subventionSchema.accessType}
        onChange={handleAccessTypeChange}
        loading={updateState.loading}
        options={[
          {
            label: t('fields.accessType.values.open'),
            value: SubventionSchemaAccessType.Open,
          },
          {
            label: t('fields.accessType.values.whitelist'),
            value: SubventionSchemaAccessType.Whitelist,
          },
        ]}
      />
      {subventionSchema.accessType === SubventionSchemaAccessType.Whitelist ? (
        <div>
          <CompaniesTable
            activeColumns={['name']}
            query={query}
            data={subventionSchema.whitelistCompaniesPagination}
            title={() => (
              <Button onClick={() => setAdding(true)}>
                {t('whitelistCompanies')}
              </Button>
            )}
            action={(record) => (
              <Button
                disabled={!!isRemoving && isRemoving !== record.id}
                loading={isRemoving === record.id}
                type="text"
                danger
                onClick={() => handleWhitelistRemove(record.id)}
              >
                <DeleteOutlined />
              </Button>
            )}
          />
          <CompanySelectModal isOpen={isAdding} onSubmit={handleWhitelistAdd} />
        </div>
      ) : (
        <div>
          <CompaniesTable
            activeColumns={['name']}
            query={query}
            data={subventionSchema.blacklistCompaniesPagination}
            title={() => (
              <Button onClick={() => setAdding(true)}>
                {t('blacklistCompanies')}
              </Button>
            )}
            action={(record) => (
              <Button
                disabled={!!isRemoving && isRemoving !== record.id}
                loading={isRemoving === record.id}
                type="text"
                danger
                onClick={() => handleBlacklistRemove(record.id)}
              >
                <DeleteOutlined />
              </Button>
            )}
          />
          <CompanySelectModal isOpen={isAdding} onSubmit={handleBlacklistAdd} />
        </div>
      )}
    </div>
  );
};

export type SubventionSchemaVisibilityViewProps = {
  subventionSchemaId: string;
};
