import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  CompanyMembershipState,
  Subvention,
  UserStatus,
} from '@graphql/generated/types';

import { FormSelectUser } from '@components/atoms/FormSelectUser/FormSelectUser';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './SubventionGranteeAddForm.module.css';

import {
  SubventionGranteeAddMutation,
  SubventionGranteeAddMutationVariables,
  useSubventionGranteeAddMutation,
} from './SubventionGranteeAddForm.generated';

export type SubventionGranteeAddFormValues = {
  userId: string;
};

export function SubventionGranteeAddForm(props: SubventionGranteeAddFormProps) {
  const { className, hideFooter, subventionId, companyId, ...rest } = props;
  const { t } = useTranslation('subventions');

  const validationSchema = Yup.object({
    userId: Yup.string().required(),
  }).required();

  const form = useForm<SubventionGranteeAddFormValues>({
    validationSchema,
  });

  const [mutation] = useSubventionGranteeAddMutation();

  const handleSubmit: FormProps<SubventionGranteeAddFormValues>['onValid'] =
    async (values) => {
      const variables: SubventionGranteeAddMutationVariables = {
        subventionId,
        userId: values.userId,
      };

      const { data } = await mutation({
        variables,
        refetchQueries: ['SubventionBeneficiaries'],
      });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="SubventionGranteeAddForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem required label={t('fields.user.label')} name="userId">
          <FormSelectUser
            name="userId"
            filter={{
              status: {
                in: [
                  UserStatus.Created,
                  UserStatus.Invited,
                  UserStatus.Activated,
                ],
              },
              companyMembership: {
                state: {
                  exclude: [CompanyMembershipState.Deactivated],
                },
                ...(companyId ? { company: { id: { is: companyId } } } : {}),
              },
            }}
          />
        </FormItem>
      </Form>
    </Card>
  );
}

export type SubventionGranteeAddFormProps = ChildFormProps<
  SubventionGranteeAddFormValues,
  SubventionGranteeAddMutation
> & {
  className?: string;
  hideFooter?: boolean;
  subventionId: Subvention['id'];
  companyId?: string;
};
