import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch, Typography } from 'antd';

import {
  useCompanyFeatureConfigGatheringFeatureDisableMutation,
  useCompanyFeatureConfigGatheringFeatureEnableMutation,
  useCompanyFeatureConfigGatheringViewQuery,
} from './CompanyFeatureConfigGatheringView.generated';

interface CompanyFeatureConfigGatheringViewProps {
  companyId: string;
}

export const CompanyFeatureConfigGatheringView = (
  props: CompanyFeatureConfigGatheringViewProps,
) => {
  const { companyId } = props;

  const [featureEnableMutation, featureEnableState] =
    useCompanyFeatureConfigGatheringFeatureEnableMutation();

  const [featureDisableMutation, featureDisableState] =
    useCompanyFeatureConfigGatheringFeatureDisableMutation();

  const { data } = useCompanyFeatureConfigGatheringViewQuery({
    variables: { companyId },
  });

  const company = data?.company;

  const handleFeatureToggle = async (checked: boolean) => {
    if (checked) {
      await featureEnableMutation({
        variables: { companyId },
      });
    } else {
      await featureDisableMutation({
        variables: { companyId },
      });
    }
  };

  return (
    <Space>
      <Typography>Fonctionnalité</Typography>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        disabled={
          !company || featureDisableState.loading || featureEnableState.loading
        }
        checked={company?.featureConfig.gathering.isEnabled}
        onChange={handleFeatureToggle}
        loading={featureDisableState.loading || featureEnableState.loading}
      />
    </Space>
  );
};
