import classnames from 'classnames';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectLocale.module.css';

import {
  FormSelectLocaleQueryVariables,
  useFormSelectLocaleQuery,
} from './FormSelectLocale.generated';

export const FormSelectLocale: React.FC<FormSelectLocaleProps> = (props) => {
  const { className, filter, ...rest } = props;

  const { data, loading } = useFormSelectLocaleQuery({
    variables: { filter },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      options={data?.localesPagination.nodes.map((node) => ({
        value: node.id,
        label: node.name,
      }))}
      {...rest}
    />
  );
};

export type FormSelectLocaleProps = FormSelectProps & {
  className?: string;
  filter?: FormSelectLocaleQueryVariables['filter'];
};
