import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormSelectCampaignSelection } from '@components/atoms/FormSelectCampaignSelection/FormSelectCampaignSelection';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyCampaignSelectionExcludeForm.module.css';

import {
  CompanyCampaignSelectionExcludeFormMutation,
  CompanyCampaignSelectionExcludeFormMutationVariables,
  useCompanyCampaignSelectionExcludeFormMutation,
} from './CompanyCampaignSelectionExcludeForm.generated';

export type CompanyCampaignSelectionExcludeFormValues = {
  campaignSelectionIds: string[];
};

export function CompanyCampaignSelectionExcludeForm(
  props: CompanyCampaignSelectionExcludeFormProps,
) {
  const {
    className,
    companyId,
    hideFooter,
    defaultValues = {},
    ...rest
  } = props;

  const { t } = useTranslation('companies');

  const validationSchema = Yup.object({
    campaignSelectionIds: Yup.array(Yup.string().required()).required(),
  }).required();

  const form = useForm<CompanyCampaignSelectionExcludeFormValues>({
    validationSchema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const [mutation] = useCompanyCampaignSelectionExcludeFormMutation();

  const handleSubmit: FormProps<CompanyCampaignSelectionExcludeFormValues>['onValid'] =
    async (values) => {
      const variables: CompanyCampaignSelectionExcludeFormMutationVariables = {
        companyId,
        input: {
          campaignSelectionIds: values.campaignSelectionIds,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyCampaignSelectionExcludeForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('CompanyCampaignSelectionExcludeForm.fields.name.label')}
          name="campaignSelectionIds"
        >
          <FormSelectCampaignSelection name="campaignSelectionIds" multiple />
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('CompanyCampaignSelectionExcludeForm.buttons.submit.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CompanyCampaignSelectionExcludeFormProps = ChildFormProps<
  CompanyCampaignSelectionExcludeFormValues,
  CompanyCampaignSelectionExcludeFormMutation
> & {
  className?: string;
  companyId: string;
  defaultValues?: DeepPartial<CompanyCampaignSelectionExcludeFormValues>;
  hideFooter?: boolean;
};
