import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { GameAccessForm } from '@components/forms/GameAccessForm/GameAccessForm';

import styles from './GameAccessView.module.css';

import { GameAccessViewFragment } from './GameAccessView.generated';

export const GameAccessView: React.FC<GameAccessViewProps> = (props) => {
  const { className, game } = props;
  const { t } = useTranslation('games');

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('GameAccessView.title')}</Typography.Title>
        <GameAccessForm game={game} />
      </div>
    </div>
  );
};

export type GameAccessViewProps = {
  className?: string;
  game: GameAccessViewFragment;
};
