import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import Text from 'antd/lib/typography/Text';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  AdminCommentTargetType,
  CampaignStatus,
  OfferType,
} from '@graphql/generated/types';

import {
  CampaignDeleteButton,
  CampaignDeleteButtonProps,
} from '@components/organisms/CampaignDeleteButton/CampaignDeleteButton';
import { CampaignStatusTag } from '@atoms/CampaignStatusTag/CampaignStatusTag';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CampaignsTableQueryVariables,
  CampaignsTableRecordFragment,
  useCampaignsTableQuery,
} from './CampaignsTable.generated';

const generateNamesList = (list: string[], limit = 3) => {
  return (
    <>
      {list.slice(0, limit).join(', ')}
      {list.length > limit && `, (+${list.length - limit})`}
    </>
  );
};

type RecordType = CampaignsTableRecordFragment;

type Column =
  | 'name'
  | 'brand'
  | 'offerCount'
  | 'offerPublishedCount'
  | 'categoryCount'
  | 'categories'
  | 'status'
  | 'startsAt'
  | 'expiresAt'
  | 'isExclusive'
  | 'offerTypes';

export function CampaignsTable(props: CampaignsTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('campaigns');

  const [categoriesEnums, setCategoriesEnums] = useState<
    { label: string; value: string }[]
  >([]);

  const query =
    propQuery ||
    useCampaignsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
      onCompleted: (data) => {
        const cEnums: typeof categoriesEnums = [];
        const { categories } = data?.campaignPagination;

        for (let i = 0; i < categories.length; i++) {
          if (categories[i]) {
            const category = categories[i].value;

            const hasParent = !!category.parent;

            const label = hasParent
              ? `${category.name} (${category.parent?.name})`
              : category.name;

            cEnums.push({ label, value: category.id });
          }
        }

        setCategoriesEnums(cEnums);
      },
    });

  const onDeleteSuccess: CampaignDeleteButtonProps['onSuccess'] = async (
    _data,
  ) => {
    await query.refetch();
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      dataIndex: ['name'],
      sorter: true,
      render: (name, node) => (
        <I18nLink to={`/campaigns/list/${node.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, 'name', 'search'),
    },
    {
      key: 'brand',
      title: t('table.headers.brand'),
      sorter: true,
      dataIndex: ['brand', 'name'],
      render: (name, node) => (
        <I18nLink to={`/brands/list/${node.brand.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      sorter: true,
      render: (status) => <CampaignStatusTag status={status} />,
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('status.published'),
          value: CampaignStatus.Published,
        },
        {
          label: t('status.unpublished'),
          value: CampaignStatus.Unpublished,
        },
        {
          label: t('status.draft'),
          value: CampaignStatus.Draft,
        },
      ]),
    },
    {
      key: 'startsAt',
      title: t('table.headers.startsAt'),
      dataIndex: ['startsAt'],
      sorter: true,
      render: (startsAt) => t('table.data.startsAt', { date: startsAt }),
    },
    {
      key: 'endsAt',
      title: t('table.headers.endsAt'),
      dataIndex: ['endsAt'],
      sorter: true,
      render: (endsAt) => t('table.data.endsAt', { date: endsAt }),
    },
    {
      key: 'offerCount',
      title: t('table.headers.offerCount'),
      dataIndex: ['offersPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'offerPublishedCount',
      title: t('table.headers.offerPublishedCount'),
      dataIndex: ['offersPublishedCount', 'totalCount'],
      sorter: true,
    },
    {
      key: 'categoryCount',
      title: t('table.headers.categoryCount'),
      dataIndex: ['categoriesPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'categories',
      title: t('table.headers.categories'),
      dataIndex: ['categoriesPagination', 'nodes'],
      render: (_, edge) =>
        generateNamesList(
          edge.categoriesPagination.nodes.map((category) => category.name),
        ),
      ...generateColumnFilter(query, 'categories', 'enum', categoriesEnums),
    },
    {
      key: 'isExclusive',
      title: t('table.headers.isExclusive'),
      render: (_, node) => (
        <Text>
          {' '}
          {node.isExclusive
            ? t('table.isExlusive.yes')
            : t('table.isExlusive.no')}
        </Text>
      ),
      ...generateColumnFilter(query, 'isExclusive', 'boolean', [
        {
          label: t('table.isExlusive.yes'),
          value: 'true',
        },
        {
          label: t('table.isExlusive.no'),
          value: 'false',
        },
        {
          label: t('table.isExlusive.all'),
          value: 'all',
        },
      ]),
    },
    {
      key: 'offerTypes',
      title: t('table.headers.offerTypes'),
      dataIndex: ['offersPagination', 'types'],
      render: (_, edge) => (
        <>
          {edge.offersPagination.types.map((type) => (
            <Tag key={type.value}>{type.value}</Tag>
          ))}
        </>
      ),
      ...generateColumnFilter(query, 'offers.SOME.type', 'enum', [
        {
          label: t('offers:type.couponClaimable'),
          value: OfferType.CouponClaimable,
        },
        {
          label: t('offers:type.couponPublic'),
          value: OfferType.CouponPublic,
        },
        {
          label: t('offers:type.giftCard'),
          value: OfferType.GiftCard,
        },
        {
          label: t('offers:type.ticket'),
          value: OfferType.Ticket,
        },
        {
          label: t('offers:type.url'),
          value: OfferType.Url,
        },
      ]),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CampaignsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.campaignPagination}
        action={(campaign) =>
          !campaign.offersPagination.totalCount && (
            <CampaignDeleteButton
              campaign={campaign}
              mode="icon"
              onSuccess={onDeleteSuccess}
            />
          )
        }
        adminCommentTargetType={AdminCommentTargetType.Campaign}
        {...rest}
      />
    </div>
  );
}

export type CampaignsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: CampaignsTableQueryVariables['filter'];
  sorter?: CampaignsTableQueryVariables['sorter'];
};
