import { useMemo } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

import { CatalogViewSelectionType } from '../../CatalogView';

import {
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import {
  CatalogEntitySelectionRowBrandFragment,
  CatalogEntitySelectionRowCampaignFragment,
  CatalogEntitySelectionRowCategoryFragment,
  CatalogEntitySelectionRowOfferFragment,
  useCatalogBrandExcludeRemoveMutation,
  useCatalogBrandIncludeRemoveMutation,
  useCatalogCampaignExcludeRemoveMutation,
  useCatalogCampaignIncludeRemoveMutation,
  useCatalogCategoryExcludeRemoveMutation,
  useCatalogExcludedBrandsQuery,
  useCatalogExcludedCampaignsQuery,
  useCatalogExcludedCategoriesQuery,
  useCatalogExcludedOffersQuery,
  useCatalogIncludedBrandsQuery,
  useCatalogIncludedCampaignsQuery,
  useCatalogIncludedOffersQuery,
  useCatalogOfferExcludeRemoveMutation,
  useCatalogOfferIncludeRemoveMutation,
} from './CatalogEntitySelection.generated';

type CatalogEntitySelectionProps = {
  companyId: string;
  type: Exclude<CatalogViewSelectionType, null>;
};

type RecordType =
  | CatalogEntitySelectionRowBrandFragment
  | CatalogEntitySelectionRowCampaignFragment
  | CatalogEntitySelectionRowCategoryFragment
  | CatalogEntitySelectionRowOfferFragment;

export const CatalogEntitySelection = (props: CatalogEntitySelectionProps) => {
  const { companyId, type } = props;

  const [gqlQuery, gqlMutation] = useMemo(() => {
    switch (type) {
      case 'excludedCategory':
        return [
          useCatalogExcludedCategoriesQuery,
          useCatalogCategoryExcludeRemoveMutation,
        ];
      case 'excludedBrand':
        return [
          useCatalogExcludedBrandsQuery,
          useCatalogBrandExcludeRemoveMutation,
        ];
      case 'excludedCampaign':
        return [
          useCatalogExcludedCampaignsQuery,
          useCatalogCampaignExcludeRemoveMutation,
        ];
      case 'excludedOffer':
        return [
          useCatalogExcludedOffersQuery,
          useCatalogOfferExcludeRemoveMutation,
        ];
      case 'includedBrand':
        return [
          useCatalogIncludedBrandsQuery,
          useCatalogBrandIncludeRemoveMutation,
        ];
      case 'includedCampaign':
        return [
          useCatalogIncludedCampaignsQuery,
          useCatalogCampaignIncludeRemoveMutation,
        ];
      case 'includedOffer':
        return [
          useCatalogIncludedOffersQuery,
          useCatalogOfferIncludeRemoveMutation,
        ];
      default:
        throw new Error('Invalid entity');
    }
  }, [type]);

  const query = gqlQuery({
    variables: { companyId, take: 20, skip: 0 },
  });

  const [mutation] = gqlMutation();

  const { data, loading } = query;
  const catalog = data?.company?.catalog;

  if (!data && loading) return <Spin />;
  if (!catalog) return <div>Something went wrong</div>;

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: 'Nom',
      dataIndex: ['name'],
    },
  ];

  const handleDelete = async (id: string) => {
    await mutation({
      variables: { catalogId: catalog.id, entityIds: [id] },
      refetchQueries: ['CatalogView'],
      awaitRefetchQueries: true,
    });

    await query.refetch();
  };

  return (
    <TablePagination<RecordType>
      // @ts-expect-error complex type
      query={query}
      columns={columns}
      activeColumns={['name']}
      data={catalog.entityPagination || { totalCount: 0, edges: [], nodes: [] }}
      rowKey="id"
      action={(record) => (
        <Button type="text" danger onClick={() => handleDelete(record.id)}>
          <DeleteOutlined />
        </Button>
      )}
    />
  );
};
