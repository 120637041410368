import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { CampaignsTable } from '@components/tables/CampaignsTable/CampaignsTable';

import styles from '../Redbin.module.css';

import { useRedBinCampaignsWithoutThumbnailQuery } from './CampaignsWithoutThumbnail.generated';

export const CampaignsWithoutThumbnail: React.FC<CampaignsWithoutThumbnailProps> =
  (props) => {
    const { className } = props;

    const { t } = useTranslation('redbin');

    const query = useRedBinCampaignsWithoutThumbnailQuery({
      variables: { take: 10, skip: 0 },
    });

    return (
      <div className={classnames(className, styles.root)}>
        <PageHeader
          ghost={false}
          title={t('CampaignsWithoutThumbnail.title')}
        />
        <CampaignsTable
          query={query}
          data={query.data?.redBinCampaignsWithoutThumbnail}
          activeColumns={[
            'name',
            'offerCount',
            'status',
            'startsAt',
            'expiresAt',
            'createdAt',
            'updatedAt',
          ]}
        />
      </div>
    );
  };

export type CampaignsWithoutThumbnailProps = {
  className?: string;
};
