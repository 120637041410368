import { useState } from 'react';
import { Button, message, Table, Tag } from 'antd';
import axios from 'axios';

import { downloadFileFromBlob } from '@utils/downloadFileFromBlob';

import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDate } from '@components/molecules/TableRendererDate/TableRendererDate';

import { useReducCeInvoicesViewQuery } from './ReducCeInvoicesView.generated';

export const ReducCeInvoicesView = () => {
  const { data, loading } = useReducCeInvoicesViewQuery();

  const [invoiceId, setInvoiceId] = useState<string | null>(null);

  const handleDownload = async (invoiceId: string) => {
    setInvoiceId(invoiceId);

    try {
      const { data } = await axios.get(
        `/internal-tooling/reduc-ce/invoice/${invoiceId}/pdf`,
        {
          baseURL: process.env.REACT_APP_API_HTTP_ENDPOINT,
          withCredentials: true,
          responseType: 'blob',
        },
      );

      downloadFileFromBlob(data, `${invoiceId}.pdf`);
    } catch (err) {
      message.error('Une erreur est survenue');
    } finally {
      setInvoiceId(null);
    }
  };

  return (
    <Table
      loading={loading}
      dataSource={data?.reducCeInvoices}
      rowKey={(r) => r.id}
      columns={[
        {
          key: 'id',
          dataIndex: 'id',
          title: 'Numéro de commande',
        },
        {
          key: 'date',
          dataIndex: 'date',
          title: 'Date',
          render: (v) => <TableRendererDate hideFrom value={v} />,
          sorter: (a, b) => a.date.getTime() - b.date.getTime(),
          defaultSortOrder: 'descend',
        },
        {
          key: 'paid',
          dataIndex: 'paid',
          title: 'Payée',
          render: (v) => (
            <Tag color={v ? 'green' : 'red'}>{v ? 'Oui' : 'Non'}</Tag>
          ),
          filters: [
            {
              text: 'Oui',
              value: true,
            },
            {
              text: 'Non',
              value: false,
            },
          ],
          onFilter: (v, record) => record.paid === v,
        },
        {
          key: 'totalBeforeTaxes',
          dataIndex: 'totalBeforeTaxes',
          title: 'Total HT',
          render: (v) => (
            <TableRendererCurrency value={{ amount: v, currency: 'EUR' }} />
          ),
          sorter: (a, b) => a.totalBeforeTaxes - b.totalBeforeTaxes,
        },
        {
          key: 'totalAfterTaxes',
          dataIndex: 'totalAfterTaxes',
          title: 'Total TTC',
          render: (v) => (
            <TableRendererCurrency value={{ amount: v, currency: 'EUR' }} />
          ),
          sorter: (a, b) => a.totalAfterTaxes - b.totalAfterTaxes,
        },
        {
          key: 'download',
          dataIndex: 'id',
          title: 'Facture',
          width: 250,
          align: 'right',
          render: (v) => (
            <Button
              onClick={() => handleDownload(v)}
              loading={invoiceId === v}
              disabled={!!invoiceId && invoiceId !== v}
            >
              Télécharger la facture
            </Button>
          ),
        },
      ]}
    />
  );
};
