import { Button, Modal, Spin } from 'antd';
import classnames from 'classnames';

import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';
import {
  CompanyMembershipUpdateForm,
  CompanyMembershipUpdateFormProps,
} from '@forms/CompanyMembershipUpdateForm/CompanyMembershipUpdateForm';

import styles from './CompanyMembershipUpdateModal.module.css';

import {
  CompanyMembershipUpdateModalQueryVariables,
  useCompanyMembershipUpdateModalQuery,
} from './CompanyMembershipUpdateModal.generated';

export const CompanyMembershipUpdateModal: React.FC<CompanyMembershipUpdateModalProps> =
  (props) => {
    const {
      className,
      companyId,
      companyMembershipId,
      isOpen,
      onClose,
      onSuccess,
    } = props;

    const query = useCompanyMembershipUpdateModalQuery({
      variables: { companyMembershipId, companyId },
    });

    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };

    const company = query?.data?.company;
    const membership = company?.membershipsPagination.nodes.find(
      (node) => node.id === companyMembershipId,
    );

    return (
      <Modal
        title="CompanyMembershipUpdateModal"
        onCancel={handleClose}
        visible={isOpen}
        className={classnames(className, styles.root)}
        footer={[
          <Button key="cancel" htmlType="button" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            form="CompanyMembershipUpdateForm"
            type="primary"
            key="submit"
            htmlType="submit"
          >
            Submit
          </Button>,
        ]}
      >
        {query.loading ? (
          <Spin />
        ) : membership && company ? (
          <CompanyMembershipUpdateForm
            onSuccess={onSuccess}
            membership={membership}
            customFields={company.customFieldsPagination.nodes}
            hideSubmitButton
          />
        ) : (
          <NotFoundView />
        )}
      </Modal>
    );
  };

export type CompanyMembershipUpdateModalProps =
  CompanyMembershipUpdateModalQueryVariables & {
    className?: string;
    isOpen?: boolean;
    onClose: () => void;
    onSuccess: CompanyMembershipUpdateFormProps['onSuccess'];
  };
