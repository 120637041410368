import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import Dinero from 'dinero.js';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { MangopayTransactionStatus } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import {
  MangopayPayOutRecordFragment,
  MangopayPayOutsTableQueryVariables,
  useMangopayPayOutsTableQuery,
} from './MangopayPayOutsTable.generated';

type RecordType = MangopayPayOutRecordFragment;

type Column =
  | 'id'
  | 'mangopayId'
  | 'status'
  | 'amount'
  | 'resultMessage'
  | 'link';

export type MangopayPayOutsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: MangopayPayOutsTableQueryVariables['sorter'];
  filter?: MangopayPayOutsTableQueryVariables['filter'];
};

export function MangopayPayOutsTable(props: MangopayPayOutsTableProps) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    sorter,
    ...rest
  } = props;

  const { t, i18n } = useTranslation([
    'mangopayPayOut',
    'subventionReimbursements',
  ]);

  const query =
    propQuery ||
    useMangopayPayOutsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });
  const data = propData || query.data?.mangopayBankwirePayOutPagination;

  const colors: Record<string, string> = {
    CREATED: 'grey',
    FAILED: 'red',
    SUCCEEDED: 'green',
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'mangopayId',
      title: t('table.headers.mangopayId', 'Mangopay ID'),
      dataIndex: 'mangopayId',
    },
    {
      key: 'amount',
      title: t('table.headers.amount', 'Amount'),
      render: (node: RecordType) => (
        <>
          {Dinero({
            amount: node.debitedFundsAmount,
            currency: node.debitedFundsCurrency,
          })
            .setLocale(i18n.resolvedLanguage)
            .toFormat()}
        </>
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status', 'Status'),
      render: (node: RecordType) => (
        <>
          <Tag color={colors[node.status]}>{node.status}</Tag>
        </>
      ),
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('status.created', 'Created'),
          value: MangopayTransactionStatus.Created,
        },
        {
          label: t('status.failed', 'Failed'),
          value: MangopayTransactionStatus.Failed,
        },
        {
          label: t('status.succeeded', 'Succeeded'),
          value: MangopayTransactionStatus.Succeeded,
        },
      ]),
    },
    {
      key: 'resultMessage',
      title: t('table.headers.resultMessage', 'Result Message'),
      dataIndex: 'resultMessage',
    },
    {
      key: 'link',
      title: t('table.headers.link', 'Link'),
      render: (node) =>
        node.subventionReimbursementRequest && (
          <I18nLink
            to={`/subventions/reimbursement/${node.subventionReimbursementRequest.id}`}
          >
            {t('name', { ns: 'subventionReimbursements' })}
          </I18nLink>
        ),
    },
  ];

  return (
    <TablePagination
      id="MangopayPayOutsTable"
      query={query}
      columns={columns}
      data={data}
      {...rest}
    />
  );
}
