import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { SubventionTypeTagFragment } from './SubventionTypeTag.generated';

type SubventionTypeTagProps = {
  subvention: SubventionTypeTagFragment;
};

export function SubventionTypeTag(props: SubventionTypeTagProps) {
  const { subvention } = props;
  const { t } = useTranslation('subventions');

  if (!subvention.type) return null;

  const colors: Record<typeof subvention.type, string> = {
    DISCOUNT: '',
    FUND: '',
  };

  const labels: Record<typeof subvention.type, string> = {
    DISCOUNT: t('subventionType.Discount'),
    FUND: t('subventionType.Fund'),
  };

  return <Tag color={colors[subvention.type]}>{labels[subvention.type]}</Tag>;
}
