import { useTranslation } from 'react-i18next';
import { StepProps, Steps } from 'antd';
import classnames from 'classnames';

import { ProductOrderEventEnum } from '@graphql/generated/types';

import styles from './ProductOrderEvents.module.css';

import { ProductOrderEventsTablePaginationFragment } from './ProductOrderEvents.generated';

const { Step } = Steps;

const EVENTS_STATUSES: Record<ProductOrderEventEnum, StepProps['status']> = {
  [ProductOrderEventEnum.DeliveredFull]: 'finish',
  [ProductOrderEventEnum.DeliveredPartial]: 'process',
  [ProductOrderEventEnum.FailedAccount]: 'error',
  [ProductOrderEventEnum.FailedBadRequest]: 'error',
  [ProductOrderEventEnum.FailedGeneric]: 'error',
  [ProductOrderEventEnum.FailedProductDisabled]: 'error',
  [ProductOrderEventEnum.FailedProductNotFound]: 'error',
  [ProductOrderEventEnum.FailedProductOutOfStock]: 'error',
  [ProductOrderEventEnum.FailedUnreachable]: 'error',
  [ProductOrderEventEnum.OrderCreated]: 'finish',
};

export const ProductOrderEvents: React.FC<ProductOrderEventsProps> = (
  props,
) => {
  const { className, events } = props;
  const { t } = useTranslation('productOrders');

  const EVENTS_TRANSLATIONS: Record<ProductOrderEventEnum, string> = {
    [ProductOrderEventEnum.DeliveredFull]: t('events.types.deliveredFull'),
    [ProductOrderEventEnum.DeliveredPartial]: t(
      'events.types.deliveredPartial',
    ),
    [ProductOrderEventEnum.FailedAccount]: t('events.types.failedAccount'),
    [ProductOrderEventEnum.FailedBadRequest]: t(
      'events.types.failedBadRequest',
    ),
    [ProductOrderEventEnum.FailedGeneric]: t('events.types.failedGeneric'),
    [ProductOrderEventEnum.FailedProductDisabled]: t(
      'events.types.failedProductDisabled',
    ),
    [ProductOrderEventEnum.FailedProductNotFound]: t(
      'events.types.failedProductNotFound',
    ),
    [ProductOrderEventEnum.FailedProductOutOfStock]: t(
      'events.types.failedProductOutOfStock',
    ),
    [ProductOrderEventEnum.FailedUnreachable]: t(
      'events.types.failedUnreachable',
    ),
    [ProductOrderEventEnum.OrderCreated]: t('events.types.orderCreated'),
  };

  return (
    <div className={classnames(className, styles.stepsContainer)}>
      <Steps direction="vertical" size="small" current={events.totalCount}>
        {events.nodes.map((event) => (
          <Step
            key={event.id}
            status={EVENTS_STATUSES[event.type]}
            title={<h3>{EVENTS_TRANSLATIONS[event.type]}</h3>}
            description={
              <>
                {event.providerCode && (
                  <div>
                    <strong>{t('events.providerCode.label')}</strong>
                    &nbsp;{event.providerCode}
                  </div>
                )}
                {event.providerReason && (
                  <div>
                    <strong>{t('events.providerReason.label')}</strong>
                    &nbsp;{event.providerReason}
                  </div>
                )}
                <em>
                  {t('common:formats.dateTime', { date: event.createdAt })}
                </em>
                ;
              </>
            }
          />
        ))}
      </Steps>
    </div>
  );
};

export type ProductOrderEventsProps = {
  className?: string;
  events: ProductOrderEventsTablePaginationFragment;
};
