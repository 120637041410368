import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import classnames from 'classnames';

import { Typography } from '@atoms/Typography/Typography';
import {
  ProductSynchronizationCreateForm,
  ProductSynchronizationCreateFormProps,
  ProductSynchronizationCreateFormValues,
} from '@forms/ProductSynchronizationCreateForm/ProductSynchronizationCreateForm';

import styles from './ProductSynchronizationCreateView.module.css';

export const ProductSynchronizationCreateView: React.FC<ProductSynchronizationCreateViewProps> =
  (props) => {
    const { className } = props;
    const { t, i18n } = useTranslation('productSynchronizations');
    const history = useHistory();
    const location = useLocation<ProductSynchronizationCreateFormValues>();

    const handleSuccess: ProductSynchronizationCreateFormProps['onSuccess'] = (
      result,
    ) => {
      history.push(
        `/${i18n.language}/products/synchronizations/${result.productSynchonizationCreate.synchronization.id}`,
      );
    };

    return (
      <div className={classnames(className, styles.root)}>
        <div className={styles.wrapper}>
          <Typography.Title>{t('create.title')}</Typography.Title>
          <ProductSynchronizationCreateForm
            defaultValues={location.state}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
    );
  };

export type ProductSynchronizationCreateViewProps = {
  className?: string;
};
