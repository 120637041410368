import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { CampaignStatus } from '@graphql/generated/types';

import styles from './CampaignStatusTag.module.css';

export const CampaignStatusTag: React.FC<CampaignStatusTagProps> = (props) => {
  const { className, status } = props;
  const { t } = useTranslation('campaigns');

  const options: Record<
    CampaignStatus,
    {
      label: string;
      color: string;
    }
  > = {
    DRAFT: { label: t('status.draft'), color: 'default' },
    PUBLISHED: { label: t('status.published'), color: 'success' },
    UNPUBLISHED: { label: t('status.unpublished'), color: 'error' },
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={options[status].color}
    >
      {options[status].label}
    </Tag>
  );
};

export type CampaignStatusTagProps = {
  className?: string;
  status: CampaignStatus;
};
