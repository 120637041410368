import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import {
  FormSelectLocale,
  FormSelectLocaleProps,
} from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CategoryTranslationForm.module.css';

import {
  CategoryTranslationFormCreateMutation,
  CategoryTranslationFormCreateMutationVariables,
  CategoryTranslationFormDeleteMutation,
  CategoryTranslationFormDeleteMutationVariables,
  CategoryTranslationFormFragment,
  CategoryTranslationFormUpdateMutation,
  CategoryTranslationFormUpdateMutationVariables,
  useCategoryTranslationFormCreateMutation,
  useCategoryTranslationFormDeleteMutation,
  useCategoryTranslationFormUpdateMutation,
} from './CategoryTranslationForm.generated';

export type CategoryTranslationFormValues = {
  localeId: string;
  name: string;
};

export function CategoryTranslationForm(props: CategoryTranslationFormProps) {
  const {
    className,
    categoryId,
    translation,
    onCreated,
    onUpdated,
    onDeleted,
    localeFilter,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
  }).required();

  const form = useForm<CategoryTranslationFormValues>({
    validationSchema,
    defaultValues: {
      localeId: translation?.locale,
      name: translation?.name,
    },
  });

  const [createMutation, createMutationState] =
    useCategoryTranslationFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useCategoryTranslationFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useCategoryTranslationFormDeleteMutation();

  const handleCreate = async (values: CategoryTranslationFormValues) => {
    if (translation) return;

    const variables: CategoryTranslationFormCreateMutationVariables = {
      categoryId,
      localeId: values.localeId,
      input: {
        name: values.name,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.categoryTranslationCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: CategoryTranslationFormValues) => {
    if (!translation) return;

    const variables: CategoryTranslationFormUpdateMutationVariables = {
      categoryId,
      localeId: translation.locale,
      input: {
        name: values.name,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.categoryTranslationUpdate);
    }
  };

  const handleDelete = async () => {
    if (!translation) return;

    const variables: CategoryTranslationFormDeleteMutationVariables = {
      categoryId,
      localeId: translation.locale,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.categoryTranslationDelete);
    }
  };

  const handleSubmit: FormProps<CategoryTranslationFormValues>['onValid'] =
    async (values) => {
      if (translation) {
        return handleUpdate(values);
      } else {
        return handleCreate(values);
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CategoryTranslationUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale
            disabled={!!translation}
            filter={localeFilter}
            name="localeId"
          />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <div className={styles.footer}>
          {!translation ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              Créer la traduction
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              Mettre à jour la traduction
            </Button>
          )}
          {!!translation && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette traduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                Supprimer
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type CategoryTranslationFormProps = ChildFormProps<
  CategoryTranslationFormValues,
  void
> & {
  className?: string;
  categoryId: string;
  translation?: CategoryTranslationFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: CategoryTranslationFormCreateMutation['categoryTranslationCreate'],
  ) => void;
  onUpdated?: (
    v: CategoryTranslationFormUpdateMutation['categoryTranslationUpdate'],
  ) => void;
  onDeleted?: (
    v: CategoryTranslationFormDeleteMutation['categoryTranslationDelete'],
  ) => void;
};
