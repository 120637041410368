import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps } from 'antd';
import classnames from 'classnames';

import styles from './FormCheckbox.module.css';

export const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const { className, name, ...rest } = props;
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...rest}
          name={field.name}
          checked={field.value}
          onChange={(...e) => {
            field.onChange(...e);

            if (errors[name]) {
              trigger(name);
            }
          }}
          ref={field.ref}
          className={classnames(styles.root, className)}
        />
      )}
    />
  );
};

export type FormCheckboxProps = Omit<
  CheckboxProps,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string;
  className?: string;
  addonAfter?: ReactNode;
};
