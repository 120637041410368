import classnames from 'classnames';

import {
  DefaultDiscountCreateForm,
  DefaultDiscountCreateFormProps,
  DefaultDiscountCreateFormValues,
} from '@components/forms/DefaultDiscountCreateForm/DefaultDiscountCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './DefaultDiscountCreateModal.module.css';

export type DefaultDiscountCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: DefaultDiscountCreateFormProps['onSuccess'];
  isOpen?: boolean;
  defaultValues?: Partial<DefaultDiscountCreateFormValues>;
  productId: string;
};

export function DefaultDiscountCreateModal(
  props: DefaultDiscountCreateModalProps,
) {
  const { className, onClose, onSuccess, isOpen, defaultValues, productId } =
    props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: DefaultDiscountCreateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="DefaultDiscountCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="DefaultDiscountCreateForm"
    >
      <DefaultDiscountCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        hideFooter={true}
        productId={productId}
      />
    </Modal>
  );
}
