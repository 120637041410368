import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Layout, Spin } from 'antd';
import classnames from 'classnames';

import { StoreIdOffersView } from '../StoreIdOffersView/StoreIdOffersView';
import { StoreIdUpdateView } from '../StoreIdUpdateView/StoreIdUpdateView';

import { StoreHeader } from '@components/organisms/StoreHeader/StoreHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './StoreIdView.module.css';

import { useStoreIdViewQuery } from './StoreIdView.generated';

export const StoreIdView: React.FC<StoreIdViewProps> = (props) => {
  const { className, match } = props;
  const {
    params: { storeId },
  } = match;

  const { t } = useTranslation('stores');

  const query = useStoreIdViewQuery({
    variables: { storeId },
    notifyOnNetworkStatusChange: true,
  });

  const store = query?.data?.store;

  if (query.loading && !store) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!store) {
    return <NotFoundView />;
  }

  return (
    <Layout className={classnames(className, styles.root)}>
      <StoreHeader store={store} showNav />
      <TabsRouter replace match={match} className={styles.router}>
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label={t('store.menu.update')}
        >
          <StoreIdUpdateView storeId={store.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/offers`}
          exact
          label={t('store.menu.offers')}
        >
          <StoreIdOffersView storeId={store.id} />
        </TabsRouter.Route>
      </TabsRouter>
    </Layout>
  );
};

export type StoreIdViewProps = RouteComponentProps<{ storeId: string }> & {
  className?: string;
};
