import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { CountryCode } from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './TaxRateCreateForm.module.css';

import {
  TaxRateCreateFormMutation,
  TaxRateCreateFormMutationVariables,
  useTaxRateCreateFormMutation,
} from './TaxRateCreateForm.generated';

export type TaxRateCreateFormValues = {
  displayName: string;
  percent: number;
  country: CountryCode;
  state?: string | null;
};

export function TaxRateCreateForm(props: TaxRateCreateFormProps) {
  const { className, defaultValues, hideFooter, ...rest } = props;
  const { t } = useTranslation('taxRates');

  const validationSchema = Yup.object({
    displayName: Yup.string().required(),
    percent: Yup.number().min(0).required(),
    country: Yup.mixed().oneOf(Object.values(CountryCode)).required(),
    state: Yup.string().nullable(),
  }).required();

  const form = useForm<TaxRateCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useTaxRateCreateFormMutation();

  const handleSubmit: FormProps<TaxRateCreateFormValues>['onValid'] = async (
    values,
  ) => {
    console.log(values);
    const variables: TaxRateCreateFormMutationVariables = {
      input: {
        displayName: values.displayName,
        percent: values.percent,
        country: values.country,
        state: values.state,
      },
    };

    try {
      const { data } = await mutation({ variables });
      return data;
    } catch (err) {
      if (err instanceof ApolloError)
        message.error(err.message.replace('GraphQL error: ', ''));
      else message.error('Une erreur est survenue');

      throw err;
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="TaxRateCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        onInvalid={(errors) => console.log(errors)}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.displayName}
          required
          label={t('fields.displayName')}
          name="displayName"
        >
          <FormInput name="displayName" />
        </FormItem>

        <FormItem
          className={styles.percent}
          required
          label={t('fields.percent')}
          name="percent"
        >
          <FormInputNumber addonAfter="%" name="percent" />
        </FormItem>

        <FormItem
          className={styles.country}
          required
          label={t('fields.country')}
          name="country"
        >
          <FormSelectCountry name="country" />
        </FormItem>

        <FormItem
          className={styles.state}
          required
          label={t('fields.state')}
          name="state"
        >
          <FormInput name="state" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type TaxRateCreateFormProps = ChildFormProps<
  TaxRateCreateFormValues,
  TaxRateCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<TaxRateCreateFormValues>;
  hideFooter?: boolean;
};
