import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import * as Sentry from '@sentry/react';
import { Layout } from 'antd';
import * as qs from 'qs';

import { UserRole } from '@graphql/generated/types';

import { useAuth } from '@hooks/auth/auth.hooks';
import AdminCommentsDrawer from '@components/organisms/AdminCommentsDrawer/AdminCommentsDrawer';
import { AuthLoginView } from '@components/views/Auth/AuthLoginView/AuthLoginView';
import { AuthUserView } from '@components/views/Auth/AuthUserView/AuthUserView';
import { BrandsView } from '@components/views/Brands/BrandsView/BrandsView';
import { CampaignsView } from '@components/views/Campaigns/CampaignsView/CampaignsView';
import { CampaignSelectionCreateView } from '@components/views/CampaignSelections/CampaignSelectionCreateView/CampaignSelectionCreateView';
import { CampaignSelectionIdView } from '@components/views/CampaignSelections/CampaignSelectionIdView/CampaignSelectionIdView';
import { CampaignSelectionsView } from '@components/views/CampaignSelections/CampaignSelectionsView/CampaignSelectionsView';
import { ArticleCreateView } from '@components/views/Communication/Articles/ArticleCreateView/ArticleCreateView';
import { ArticleIdView } from '@components/views/Communication/Articles/ArticleIdView/ArticleIdView';
import { ArticlesDashboardView } from '@components/views/Communication/Articles/ArticlesDashboardView/ArticlesDashboardView';
import { ArticleCategoryCreateView } from '@components/views/Communication/ArticlesCategories/ArticleCategoryCreateView/ArticleCategoryCreateView';
import { ArticleCategoryIdView } from '@components/views/Communication/ArticlesCategories/ArticleCategoryIdView/ArticleCategoryIdView';
import { HighlightsDashboardView } from '@components/views/Communication/Highlights/HighlightsDashboardView/HighlightsDashboardView';
import { PublicationCreateView } from '@components/views/Communication/Publications/PublicationCreateView/PublicationCreateView';
import { PublicationIdView } from '@components/views/Communication/Publications/PublicationIdView/PublicationIdView';
import { PubilcationsDashboardView } from '@components/views/Communication/Publications/PublicationsDashboardView/PublicationsDashboardView';
import { CompaniesView } from '@components/views/Companies/CompaniesView/CompaniesView';
import { CompanyCreateView } from '@components/views/Companies/CompanyCreateView/CompanyCreateView';
import { GameCreateView } from '@components/views/Games/GameCreateView/GameCreateView';
import { GameIdView } from '@components/views/Games/GameIdView/GameIdView';
import { GamesView } from '@components/views/Games/GamesView/GamesView';
import { HappeningCreateView } from '@components/views/Happenings/HappeningCreateView/HappeningCreateView';
import { HappeningIdView } from '@components/views/Happenings/HappeningIdView/HappeningIdView';
import { HappeningsView } from '@components/views/Happenings/HappeningsView/HappeningsView';
import { KycView } from '@components/views/KycView/KycView';
import { OfferCreateView } from '@components/views/Offers/OfferCreateView/OfferCreateView';
import { OffersView } from '@components/views/Offers/OffersView/OffersView';
import { OrderIdView } from '@components/views/Orders/OrderIdView/OrderIdView';
import { OrderRefundIdView } from '@components/views/Orders/OrderRefundIdView/OrderRefundIdView';
import { OrderRefundsView } from '@components/views/Orders/OrderRefundsView/OrderRefundsView';
import { ParametersDiscoverView } from '@components/views/ParametersDiscoverView/ParametersDiscoverView';
import { PayOutsView } from '@components/views/PayOutsView/PayOutsView';
import { PicturesStockView } from '@components/views/PicturesStock/PicturesStockView/PicturesStockView';
import { PictureStockIdView } from '@components/views/PicturesStock/PictureStockIdView/PictureStockIdView';
import { ProductArticleCreateBulkView } from '@components/views/Products/ProductArticleCreateBulkView/ProductArticleCreateBulkView';
import { ProductArticleCreateView } from '@components/views/Products/ProductArticleCreateView/ProductArticleCreateView';
import { ProductArticlesView } from '@components/views/Products/ProductArticlesView/ProductArticlesView';
import { ProductCatalogCreateView } from '@components/views/Products/ProductCatalogCreateView/ProductCatalogCreateView';
import { ProductCatalogsView } from '@components/views/Products/ProductCatalogsView/ProductCatalogsView';
import { ProductCreateView } from '@components/views/Products/ProductCreateView/ProductCreateView';
import { ProductsView } from '@components/views/Products/ProductsView/ProductsView';
import { ProductVariantIdView } from '@components/views/Products/ProductVariantIdView/ProductVariantIdView';
import { ProductVariantsView } from '@components/views/Products/ProductVariantsView/ProductVariantsView';
import { BrandsMissingTranslation } from '@components/views/RedBin/BrandsMissingTranslation/BrandsMissingTranslation';
import { BrandsWithoutLogo } from '@components/views/RedBin/BrandsWithoutLogo/BrandsWithoutLogo';
import { CampaignsMissingTranslation } from '@components/views/RedBin/CampaignsMissingTranslation/CampaignsMissingTranslation';
import { CampaignsWithoutCover } from '@components/views/RedBin/CampaignsWithoutCover/CampaignsWithoutCover';
import { CampaignsWithoutThumbnail } from '@components/views/RedBin/CampaignsWithoutThumbnail/CampaignsWithoutThumbnail';
import { OffersEnded } from '@components/views/RedBin/OffersEnded/OffersEnded';
import { OffersEndingSoon } from '@components/views/RedBin/OffersEndingSoon/OffersEndingSoon';
import { OffersMissingTranslation } from '@components/views/RedBin/OffersMissingTranslation/OffersMissingTranslation';
import { OffersWithLowCoupons } from '@components/views/RedBin/OffersWithLowCoupons/OffersWithLowCoupons';
import { OffersWithoutCategory } from '@components/views/RedBin/OffersWithoutCategory/OffersWithoutCategory';
import { OffersWithoutDiscount } from '@components/views/RedBin/OffersWithoutDiscount/OffersWithoutDiscount';
import { OffersWithoutLocalization } from '@components/views/RedBin/OffersWithoutLocalization/OffersWithoutLocalization';
import { OffersWithoutUrl } from '@components/views/RedBin/OffersWithoutUrl/OffersWithoutUrl';
import { OffersWithProductsExpiringSoon } from '@components/views/RedBin/OffersWithProductsExpiringSoon/OffersWithProductsExpiringSoon';
import { OrdersNotDelivered } from '@components/views/RedBin/OrdersNotDelivered/OrdersNotDelivered';
import { ProductsExpiringSoon } from '@components/views/RedBin/ProductsExpiringSoon/ProductsExpiringSoon';
import { ProductsWithLittleStockRemaining } from '@components/views/RedBin/ProductsWithLittleStockRemaining/ProductsWithLittleStockRemaining';
import { StoresWithoutProvince } from '@components/views/RedBin/StoresWithoutProvince/StoresWithoutProvince';
import { SearchSynchronizationCreateView } from '@components/views/Search/SearchSynchronizationCreateView/SearchSynchronizationCreateView';
import { SearchSynchronizationIdView } from '@components/views/Search/SearchSynchronizationIdView/SearchSynchronizationIdView';
import { SearchSynchronizationsView } from '@components/views/Search/SearchSynchronizationsView/SearchSynchronizationsView';
import { StoreIdView } from '@components/views/Stores/StoreIdView/StoreIdView';
import { StripePaymentIntentsView } from '@components/views/StripePaymentIntents/StripePaymentIntentsView/StripePaymentIntentsView';
import { SubventionRouter } from '@components/views/Subventions/SubventionRouter';
import { TaxRatesView } from '@components/views/TaxRates/TaxRatesView/TaxRatesView';
import { AppdragCompanyMigrationsView } from '@components/views/Tools/AppdragCompanyMigrationsView/AppdragCompanyMigrationsView';
import { AwsWafView } from '@components/views/Tools/AwsWafView/AwsWafView';
import { IfgOrderStateView } from '@components/views/Tools/IFGOrderStateView/IFGOrderStateView';
import { QueryBuilderView } from '@components/views/Tools/QueryBuilderView/QueryBuilderView';
import { ReducCeInvoicesView } from '@components/views/Tools/ReducCeInvoicesView/ReducCeInvoicesView';
import { ReducCeOrderStateView } from '@components/views/Tools/ReducCeOrderStateView/ReducCeOrderStateView';
import { TranslationsView } from '@components/views/Tools/Translations/TranslationsView/TranslationsView';
import { TransfersView } from '@components/views/TransfersView/TransfersView';
import { UserCreateView } from '@components/views/Users/UserCreateView/UserCreateView';
import { UserIdView } from '@components/views/Users/UserIdView/UserIdView';
import { UsersView } from '@components/views/Users/UsersView/UsersView';
import { BrowserSupport } from '@molecules/BrowserSupport/BrowserSupport';
import { AppHeader } from '@organisms/AppHeader/AppHeader';
import { AppSideMenu } from '@organisms/AppSideMenu/AppSideMenu';

import { BrandCreateView } from '@views/Brands/BrandCreateView/BrandCreateView';
import { BrandIdView } from '@views/Brands/BrandIdView/BrandIdView';
import { CampaignCreateView } from '@views/Campaigns/CampaignCreateView/CampaignCreateView';
import { CampaignIdView } from '@views/Campaigns/CampaignIdView/CampaignIdView';
import { CategoriesView } from '@views/Categories/CategoriesView/CategoriesView';
import { CategoryCreateView } from '@views/Categories/CategoryCreateView/CategoryCreateView';
import { CategoryIdView } from '@views/Categories/CategoryIdView/CategoryIdView';
import { CompanyIdView } from '@views/Companies/CompanyIdView/CompanyIdView';
// import { DashboardView } from '@views/DashboardView/DashboardView';
import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';
import { OfferIdView } from '@views/Offers/OfferIdView/OfferIdView';
import { OrdersView } from '@views/Orders/OrdersView/OrdersView';
import { ProductIdView } from '@views/Products/ProductIdView/ProductIdView';
import { ProductProvidersView } from '@views/Products/ProductProvidersView/ProductProvidersView';
import { ProductSynchronizationCreateView } from '@views/Products/ProductSynchronizationCreateView/ProductSynchronizationCreateView';
import { ProductSynchronizationIdView } from '@views/Products/ProductSynchronizationIdView/ProductSynchronizationIdView';
import { ProductSynchronizationsView } from '@views/Products/ProductSynchronizationsView/ProductSynchronizationsView';
import { SplashView } from '@views/SplashView/SplashView';

import styles from './App.module.css';

import i18n from './i18n';

const SentryRoute = Sentry.withSentryRouting(Route);

export const App: React.FC<AppProps> = (props) => {
  const { match } = props;
  const { viewer } = useAuth();

  return (
    <Suspense fallback={<SplashView backgroundOnly />}>
      <Helmet
        titleTemplate="%s | HappyPal Back-Office"
        defaultTitle="HappyPal Back-Office"
      >
        <html lang={i18n.resolvedLanguage} />
      </Helmet>
      <BrowserSupport />
      <Sentry.ErrorBoundary
        fallback={<ServerErrorView />}
        showDialog
        dialogOptions={{
          lang: i18n.resolvedLanguage,
          user: viewer
            ? {
                email: viewer.email,
                name: `${viewer.firstName} ${viewer.lastName}`,
              }
            : undefined,
        }}
      >
        {viewer ? (
          viewer.role === UserRole.User ? (
            <Layout>
              <AuthUserView />
            </Layout>
          ) : (
            <Layout className={styles.root}>
              <AppSideMenu className={styles.menu} />
              <AdminCommentsDrawer />
              <Layout>
                <AppHeader className={styles.header} />
                <Layout.Content className={styles.content}>
                  <Switch>
                    {/* <SentryRoute
                      exact
                      path={`${match.url}/dashboard`}
                      component={DashboardView}
                    /> */}
                    <Redirect
                      from={`${match.url}/brands`}
                      to={`${match.url}/brands/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/brands/list`}
                      component={BrandsView}
                    />
                    <SentryRoute
                      path={`${match.url}/brands/create`}
                      component={BrandCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/brands/list/:brandId`}
                      component={BrandIdView}
                    />
                    <Redirect
                      from={`${match.url}/campaigns`}
                      to={`${match.url}/campaigns/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/campaigns/list`}
                      component={CampaignsView}
                    />
                    <SentryRoute
                      path={`${match.url}/campaigns/list/:campaignId`}
                      component={CampaignIdView}
                    />
                    <SentryRoute
                      path={`${match.url}/campaigns/create`}
                      component={CampaignCreateView}
                    />
                    <Redirect
                      from={`${match.url}/campaign-selections`}
                      to={`${match.url}/campaign-selections/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/campaign-selections/list`}
                      component={CampaignSelectionsView}
                    />
                    <SentryRoute
                      path={`${match.url}/campaign-selections/list/:campaignSelectionId`}
                      component={CampaignSelectionIdView}
                    />
                    <SentryRoute
                      path={`${match.url}/campaign-selections/create`}
                      component={CampaignSelectionCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/users/list/:userId`}
                      component={UserIdView}
                    />
                    <Redirect
                      from={`${match.url}/users`}
                      to={`${match.url}/users/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/users/list`}
                      component={UsersView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/users/create`}
                      component={UserCreateView}
                    />
                    <Redirect
                      from={`${match.url}/offers`}
                      to={`${match.url}/offers/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/offers/list`}
                      component={OffersView}
                    />
                    <SentryRoute
                      path={`${match.url}/offers/create`}
                      component={OfferCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/offers/list/:offerId`}
                      component={OfferIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/orders/list`}
                      component={OrdersView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/orders/list/:orderId`}
                      component={OrderIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/orders/refund/list`}
                      component={OrderRefundsView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/orders/refund/:orderRefunId`}
                      component={OrderRefundIdView}
                    />
                    <Redirect
                      from={`${match.url}/companies`}
                      to={`${match.url}/companies/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/companies/list`}
                      component={CompaniesView}
                    />
                    <SentryRoute
                      path={`${match.url}/companies/create`}
                      component={CompanyCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/companies/list/:companyId`}
                      component={CompanyIdView}
                    />
                    <Redirect
                      from={`${match.url}/products`}
                      to={`${match.url}/products/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/list`}
                      component={ProductsView}
                    />
                    <SentryRoute
                      path={`${match.url}/products/list/:productId`}
                      component={ProductIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/create`}
                      component={ProductCreateView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/product-variants/list`}
                      component={ProductVariantsView}
                    />
                    <SentryRoute
                      path={`${match.url}/product-variants/list/:productVariantId`}
                      component={ProductVariantIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/product-articles/list`}
                      component={ProductArticlesView}
                    />
                    <SentryRoute
                      path={`${match.url}/product-articles/create-bulk`}
                      render={({ location }) => {
                        const { productVariantId } = qs.parse(location.search, {
                          ignoreQueryPrefix: true,
                        });

                        if (typeof productVariantId === 'string') {
                          return (
                            <ProductArticleCreateBulkView
                              productVariantId={productVariantId}
                            />
                          );
                        } else {
                          return <NotFoundView />;
                        }
                      }}
                    />
                    <SentryRoute
                      path={`${match.url}/product-articles/create`}
                      component={ProductArticleCreateView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/providers`}
                      component={ProductProvidersView}
                    />
                    <Redirect
                      from={`${match.url}/products/catalogs`}
                      to={`${match.url}/products/catalogs/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/catalogs/list`}
                      component={ProductCatalogsView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/catalogs/create`}
                      component={ProductCatalogCreateView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/synchronizations`}
                      component={ProductSynchronizationsView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/products/synchronizations/create`}
                      component={ProductSynchronizationCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/products/synchronizations/:productSynchronizationId`}
                      component={ProductSynchronizationIdView}
                    />
                    <Redirect
                      from={`${match.url}/tax-rates`}
                      to={`${match.url}/tax-rates/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tax-rates/list`}
                      component={TaxRatesView}
                    />
                    <Redirect
                      from={`${match.url}/categories`}
                      to={`${match.url}/categories/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/categories/list`}
                      component={CategoriesView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/categories/create`}
                      component={CategoryCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/categories/list/:categoryId`}
                      component={CategoryIdView}
                    />
                    <SentryRoute
                      path={`${match.url}/stores/list/:storeId`}
                      component={StoreIdView}
                    />
                    <Redirect
                      from={`${match.url}/search`}
                      to={`${match.url}/search/synchronizations`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/search/synchronizations`}
                      component={SearchSynchronizationsView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/search/synchronizations/create`}
                      component={SearchSynchronizationCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/search/synchronizations/:searchSynchronizationId`}
                      component={SearchSynchronizationIdView}
                    />
                    <Redirect
                      from={`${match.url}/payments`}
                      to={`${match.url}/payments/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/payments/list`}
                      component={StripePaymentIntentsView}
                    />

                    <Redirect
                      exact
                      from={match.url}
                      to={`${match.url}/users/list`}
                    />

                    {/* COMMUNICATION */}
                    <SentryRoute
                      exact
                      path={`${match.url}/communication/articles/create`}
                      component={ArticleCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/articles/list/:articleId`}
                      component={ArticleIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/communication/articles/categories/create`}
                      component={ArticleCategoryCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/articles/categories/:articleCategoryId`}
                      component={ArticleCategoryIdView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/articles`}
                      component={ArticlesDashboardView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/publications/list/:publicationId`}
                      component={PublicationIdView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/communication/publications/create`}
                      component={PublicationCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/publications`}
                      component={PubilcationsDashboardView}
                    />
                    <SentryRoute
                      path={`${match.url}/communication/highlights`}
                      component={HighlightsDashboardView}
                    />

                    {/* PARAMETERS */}
                    <SentryRoute
                      path={`${match.url}/parameters/picture-stock/list`}
                      component={PicturesStockView}
                      exact
                    />
                    <SentryRoute
                      path={`${match.url}/parameters/discover`}
                      component={ParametersDiscoverView}
                      exact
                    />
                    <SentryRoute
                      path={`${match.url}/parameters/picture-stock/list/:pictureStockId`}
                      component={PictureStockIdView}
                    />
                    <SentryRoute
                      path={`${match.url}/subventions`}
                      component={SubventionRouter}
                    />

                    {/* MANGOPAY */}
                    <SentryRoute
                      path={`${match.url}/mangopay/kyc`}
                      component={KycView}
                    />
                    <SentryRoute
                      path={`${match.url}/mangopay/payout`}
                      component={PayOutsView}
                    />
                    <SentryRoute
                      path={`${match.url}/mangopay/transfer`}
                      component={TransfersView}
                    />

                    {/* HAPPENINGS */}
                    <Redirect
                      from={`${match.url}/happenings`}
                      to={`${match.url}/happenings/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/happenings/list`}
                      component={HappeningsView}
                    />
                    <SentryRoute
                      path={`${match.url}/happenings/create`}
                      component={HappeningCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/happenings/list/:happeningId`}
                      component={HappeningIdView}
                    />

                    {/* GAMES */}
                    <Redirect
                      from={`${match.url}/games`}
                      to={`${match.url}/games/list`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/games/list`}
                      component={GamesView}
                    />
                    <SentryRoute
                      path={`${match.url}/games/create`}
                      component={GameCreateView}
                    />
                    <SentryRoute
                      path={`${match.url}/games/list/:gameId`}
                      component={GameIdView}
                    />

                    {/* TOOLS */}

                    <SentryRoute
                      path={`${match.url}/tools/aws-waf-ipset`}
                      component={AwsWafView}
                    />

                    <SentryRoute
                      path={`${match.url}/tools/translations`}
                      component={TranslationsView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tools/query-builder`}
                      component={QueryBuilderView}
                    />
                    <Redirect
                      from={`${match.url}/tools`}
                      to={`${match.url}/tools/query-builder`}
                      strict
                      exact
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tools/reduc-ce-invoices`}
                      component={ReducCeInvoicesView}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tools/reduc-ce-order-state/:orderId?`}
                      render={(props) => (
                        <ReducCeOrderStateView
                          orderId={props.match.params.orderId}
                        />
                      )}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tools/ifg-order-state/:orderId?`}
                      render={(props) => (
                        <IfgOrderStateView
                          orderId={props.match.params.orderId}
                        />
                      )}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/tools/appdrag-company-migrations`}
                      component={AppdragCompanyMigrationsView}
                    />

                    {/* REDBIN */}
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-ending-soon`}
                      component={OffersEndingSoon}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-ended`}
                      component={OffersEnded}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-without-category`}
                      component={OffersWithoutCategory}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-without-store`}
                      component={OffersWithoutLocalization}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-low-coupons`}
                      component={OffersWithLowCoupons}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-without-discount`}
                      component={OffersWithoutDiscount}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-without-url`}
                      component={OffersWithoutUrl}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-with-products-expiring-soon`}
                      component={OffersWithProductsExpiringSoon}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/offers-missing-translation`}
                      component={OffersMissingTranslation}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/campaigns-without-cover`}
                      component={CampaignsWithoutCover}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/campaigns-without-thumbnail`}
                      component={CampaignsWithoutThumbnail}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/campaigns-missing-translation`}
                      component={CampaignsMissingTranslation}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/brands-without-logo`}
                      component={BrandsWithoutLogo}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/brands-missing-translation`}
                      component={BrandsMissingTranslation}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/products-expiring-soon`}
                      component={ProductsExpiringSoon}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/products-little-stock`}
                      component={ProductsWithLittleStockRemaining}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/orders-not-delivered`}
                      component={OrdersNotDelivered}
                    />
                    <SentryRoute
                      exact
                      path={`${match.url}/redbin/stores-without-province`}
                      component={StoresWithoutProvince}
                    />
                    <SentryRoute component={NotFoundView} />
                  </Switch>
                </Layout.Content>
              </Layout>
            </Layout>
          )
        ) : (
          <AuthLoginView />
        )}
      </Sentry.ErrorBoundary>
    </Suspense>
  );
};

export type AppProps = RouteComponentProps;
