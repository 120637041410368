import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import classnames from 'classnames';

import { OrderStatus } from '@graphql/generated/types';

import styles from './OrderSteps.module.css';

import { OrderStepsFragment } from './OrderSteps.generated';

export const OrderSteps: React.FC<OrderStepsProps> = (props) => {
  const { className, order } = props;
  const { t } = useTranslation('orders');

  return (
    <div className={classnames(className, styles.root)}>
      <Steps
        progressDot
        status="process"
        current={[
          OrderStatus.Draft,
          OrderStatus.Paid,
          OrderStatus.DeliveredPartial,
          OrderStatus.DeliveredFull,
        ].indexOf(order.status)}
      >
        <Steps.Step title={t('status.draft')} />
        <Steps.Step title={t('status.paid')} />
        <Steps.Step title={t('status.deliveredPartial')} />
        <Steps.Step title={t('status.deliveredFull')} />
      </Steps>
    </div>
  );
};

export type OrderStepsProps = {
  className?: string;
  order: OrderStepsFragment;
};
