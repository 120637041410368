import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { useAuth } from '@hooks/auth/auth.hooks';

import styles from './AuthUserView.module.css';

export const AuthUserView: React.FC = () => {
  const { logout } = useAuth();
  const { resetStore } = useApolloClient();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.log(err);
    }
    await resetStore();
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Title>Super admin</Title>
        <Text>{t('AuthUserView.accesFromUserAccount')}</Text>
        <a
          href={process.env.REACT_APP_HAPPYPAL_URL}
          className={styles.happypalLink}
        >
          <Button type="primary" className={styles.happypal}>
            {t('AuthUserView.accessToHappypal')}
          </Button>
        </a>
        <Button className={styles.logout} onClick={handleLogout}>
          {t('logout')}
        </Button>
      </div>
    </div>
  );
};
