import { RouteComponentProps } from 'react-router';
import { Layout, Spin } from 'antd';
import classnames from 'classnames';

import { OfferIdCouponClaimablesView } from '../OfferIdCouponClaimablesView/OfferIdCouponClaimablesView';
import { OfferIdCouponPublicView } from '../OfferIdCouponPublicView/OfferIdCouponPublicView';
import OfferIdSettingsView from '../OfferIdSettingsView/OfferIdSettingsView';
import { OfferIdStoresView } from '../OfferIdStoresView/OfferIdStoresView';
import { OfferIdUpdateView } from '../OfferIdUpdateView/OfferIdUpdateView';

import { AdminCommentTargetType } from '@graphql/generated/types';

import AdminCommentsDrawerOpenButton from '@components/organisms/AdminCommentsDrawerOpenButton/AdminCommentsDrawerOpenButton';
import { OfferIdTranslationsView } from '@components/views/Brands/OfferIdTranslationsView/OfferIdTranslationsView';
import { CatalogIncludedView } from '@components/views/CatalogIncludedView/CatalogIncludedView';
import { OfferHeader } from '@organisms/OfferHeader/OfferHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdView.module.css';

import { useOfferIdViewQuery } from './OfferIdView.generated';

export const OfferIdView: React.FC<OfferIdViewProps> = (props) => {
  const { className, match } = props;
  const {
    params: { offerId },
  } = match;

  const query = useOfferIdViewQuery({
    variables: { offerId },
    notifyOnNetworkStatusChange: true,
  });

  const offer = query?.data?.offer;

  if (query.loading && !offer) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!offer) {
    return <NotFoundView />;
  }

  return (
    <Layout className={classnames(className, styles.root)}>
      <OfferHeader offer={offer} className={styles.header} showNav />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label="Description/Configuration"
        >
          <OfferIdTranslationsView offerId={offerId} />
        </TabsRouter.Route>

        <TabsRouter.Route path={`${match.url}/update`} exact label="Modifier">
          <OfferIdUpdateView offerId={offer.id} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/stores`}
          exact
          label="Points de vente"
        >
          <OfferIdStoresView offerId={offer.id} />
        </TabsRouter.Route>

        <TabsRouter.Route
          path={`${match.url}/settings`}
          exact
          label="Paramètres"
        >
          <OfferIdSettingsView offer={offer} />
        </TabsRouter.Route>

        {offer.__typename === 'OfferCouponClaimable' && (
          <TabsRouter.Route
            path={`${match.url}/coupons-claimable`}
            label="Coupons"
          >
            <OfferIdCouponClaimablesView offerId={offer.id} />
          </TabsRouter.Route>
        )}

        {offer.__typename === 'OfferCouponPublic' && (
          <TabsRouter.Route path={`${match.url}/coupons-public`} label="Coupon">
            <OfferIdCouponPublicView offerId={offer.id} />
          </TabsRouter.Route>
        )}
        <TabsRouter.Route path={`${match.url}/include`} label="Exclusivité">
          <CatalogIncludedView entityId={offerId} entityType={'offer'} />
        </TabsRouter.Route>

        <TabsRouter.Route path={`${match.url}/statistics`} label="Statistiques">
          Statistiques
        </TabsRouter.Route>
      </TabsRouter>

      <AdminCommentsDrawerOpenButton
        targetId={offer.id}
        targetType={AdminCommentTargetType.Offer}
      />
    </Layout>
  );
};

export type OfferIdViewProps = RouteComponentProps<{ offerId: string }> & {
  className?: string;
};
