import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { BooleanTag } from '@components/atoms/BooleanTag/BooleanTag';
import { ProductVariantInventoryQuantityTag } from '@components/atoms/ProductVariantInventoryQuantityTag/ProductVariantInventoryQuantityTag';
import { ProductVariantPriceTag } from '@components/atoms/ProductVariantPriceTag/ProductVariantPriceTag';
import { ProductVariantActivateDeactivateButton } from '@components/organisms/ProductVariantActivateDeactivateButton/ProductVariantActivateDeactivateButton';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductVariantsTableQueryVariables,
  ProductVariantsTableRecordFragment,
  useProductVariantsTableQuery,
} from './ProductVariantsTable.generated';

type RecordType = ProductVariantsTableRecordFragment;

type Column =
  | 'product'
  | 'SKU'
  | 'priceWithTaxes'
  | 'inventoryQuantity'
  | 'isActivated';

export function ProductVariantsTable(props: ProductVariantsTableProps) {
  const {
    className,
    sorter,
    filter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('productVariants');

  const query =
    propQuery ||
    useProductVariantsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'product',
      title: t('fields.product'),
      dataIndex: ['product', 'name'],
      render: (name, variant) =>
        variant.product && (
          <I18nLink to={`/products/list/${variant.product.id}`}>
            {name}
          </I18nLink>
        ),
      ...generateColumnFilter(query, 'product.name', 'search'),
    },
    {
      key: 'SKU',
      title: t('fields.SKU'),
      dataIndex: ['SKU'],
      render: (SKU, variant) =>
        variant.product && (
          <I18nLink to={`/product-variants/list/${variant.id}`}>{SKU}</I18nLink>
        ),
      ...generateColumnFilter(query, 'SKU', 'string'),
    },
    {
      key: 'priceWithTaxes',
      title: t('fields.priceWithTaxes'),
      ellipsis: true,
      render: (_, variant) => {
        const taxRate = variant.product.taxRatePagination?.nodes?.[0];

        return (
          <>
            <ProductVariantPriceTag productVariant={variant} />
            {taxRate && `${taxRate.displayName} ${taxRate.percent}%`}
          </>
        );
      },
    },
    {
      key: 'inventoryQuantity',
      title: t('fields.inventoryQuantity'),
      ellipsis: true,
      render: (_, node) => (
        <ProductVariantInventoryQuantityTag productVariant={node} />
      ),
    },
    {
      key: 'isActivated',
      title: t('fields.isActivated'),
      ellipsis: true,
      render: (_, node) => <BooleanTag value={node.isActivated} />,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductVariantsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.productVariantPagination}
        action={(record) => (
          <Space>
            <ProductVariantActivateDeactivateButton productVariant={record} />
          </Space>
        )}
        {...rest}
      />
    </div>
  );
}

export type ProductVariantsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: ProductVariantsTableQueryVariables['sorter'];
  filter?: ProductVariantsTableQueryVariables['filter'];
};
