import cx from 'classnames';

import { OffersTable } from '@components/tables/OffersTable/OffersTable';
import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';
import { ProductVariantsTable } from '@components/tables/ProductVariantsTable/ProductVariantsTable';

import styles from './ProductSynchronizationIdDeletedView.module.css';

import {
  useProductSynchronizationIdDeletedOffersViewQuery,
  useProductSynchronizationIdDeletedProductsViewQuery,
  useProductSynchronizationIdDeletedProductVariantsViewQuery,
} from './ProductSynchronizationIdDeletedView.generated';

export const ProductSynchronizationIdDeletedView: React.FC<ProductSynchronizationIdDeletedViewProps> =
  (props) => {
    const {
      className,
      productSynchronizationId,
      showOffers = false,
      showProducts = false,
      showProductVariants = false,
    } = props;

    const offersQuery = useProductSynchronizationIdDeletedOffersViewQuery({
      skip: showOffers === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productsQuery = useProductSynchronizationIdDeletedProductsViewQuery({
      skip: showProducts === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productVariantsQuery =
      useProductSynchronizationIdDeletedProductVariantsViewQuery({
        skip: showProductVariants === false,
        variables: { productSynchronizationId, take: 20, skip: 0 },
      });

    return (
      <div className={cx(className, styles.root)}>
        {showOffers && (
          <OffersTable
            title={() => <div>Offres</div>}
            activeColumns={['name', 'status', 'startsAt', 'endsAt']}
            data={
              offersQuery.data?.productSynchronization?.offersDeletedPagination
            }
            query={offersQuery}
          />
        )}
        {showProducts && (
          <ProductsTable
            title={() => <div>Produits</div>}
            activeColumns={['name', 'brand']}
            data={
              productsQuery.data?.productSynchronization
                ?.productsDeletedPagination
            }
            query={productsQuery}
          />
        )}
        {showProductVariants && (
          <ProductVariantsTable
            title={() => <div>Variations de produit</div>}
            activeColumns={[
              'product',
              'SKU',
              'priceWithTaxes',
              'inventoryQuantity',
            ]}
            data={
              productVariantsQuery.data?.productSynchronization
                ?.productVariantsDeletedPagination
            }
            query={productVariantsQuery}
          />
        )}
      </div>
    );
  };

export type ProductSynchronizationIdDeletedViewProps = {
  className?: string;
  productSynchronizationId: string;
  showOffers?: boolean;
  showProducts?: boolean;
  showProductVariants?: boolean;
};
