import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classnames from 'classnames';

import styles from './SelectLocale.module.css';

import { useSelectLocaleQuery } from './SelectLocale.generated';

export const SelectLocale: React.FC<SelectLocaleProps> = (props) => {
  const { className, excludedLocales, disabled, ...rest } = props;

  const { data, loading } = useSelectLocaleQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Select
      loading={loading}
      disabled={disabled}
      {...rest}
      className={classnames(className, styles.root)}
    >
      {data?.localesPagination?.nodes.map((node) => (
        <Select.Option
          key={node.id}
          disabled={disabled ? false : excludedLocales?.includes(node.id)}
          value={node.id}
        >
          {node.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export type SelectLocaleProps = SelectProps<SelectValue> & {
  className?: string;
  excludedLocales?: string[];
};
