import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  CampaignCreateForm,
  CampaignCreateFormProps,
} from '@forms/CampaignCreateForm/CampaignCreateForm';

import styles from './CampaignCreateView.module.css';

export const CampaignCreateView: React.FC<CampaignCreateViewProps> = (
  props,
) => {
  const { className } = props;
  const { t, i18n } = useTranslation('campaigns');
  const history = useHistory();
  const location = useLocation();

  const handleSuccess: CampaignCreateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/campaigns/list/${result.campaignCreate.campaign.id}`,
    );
  };

  const defaultValues = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as CampaignCreateFormProps['defaultValues'];

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <CampaignCreateForm
          defaultValues={defaultValues}
          onSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};

export type CampaignCreateViewProps = {
  className?: string;
};
