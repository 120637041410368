import { useState } from 'react';
import { Button, Space } from 'antd';

import { SubsidiaryImportUserModal } from '@components/organisms/SubsidiaryImportModal/SubsidiaryImportUsersModal';
import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';

import { useCompanyIdSubsidiariesViewQuery } from './CompanyIdSubsidiariesView.generated';

export type CompanyIdSubsidiariesViewProps = {
  companyId: string;
};

export const CompanyIdSubsidiariesView = (
  props: CompanyIdSubsidiariesViewProps,
) => {
  const { companyId } = props;

  const query = useCompanyIdSubsidiariesViewQuery({ variables: { companyId } });
  const [isSubsidiaryImportUsersOpen, setSubsidiaryImportUsersOpen] =
    useState(false);

  return (
    <div>
      <CompaniesTable
        activeColumns={['id', 'name', 'displayName']}
        query={query}
        data={query.data?.company?.childrenPagination}
        title={() => (
          <Space>
            <Button onClick={() => setSubsidiaryImportUsersOpen(true)}>
              Importer des utilisateurs dans les filiales
            </Button>
          </Space>
        )}
      />
      <SubsidiaryImportUserModal
        companyId={companyId}
        isOpen={isSubsidiaryImportUsersOpen}
        onClose={() => setSubsidiaryImportUsersOpen(false)}
      />
    </div>
  );
};
