import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Dinero from 'dinero.js';

import styles from './TableRendererCurrency.module.css';

export const TableRendererCurrency: React.FC<TableRendererCurrencyProps> = (
  props,
) => {
  const { className, value } = props;
  const { i18n } = useTranslation();

  return (
    <div className={classnames(className, styles.root)}>
      {value
        ? Dinero({ amount: value.amount, currency: value.currency })
            .setLocale(i18n.resolvedLanguage)
            .toFormat()
        : null}
    </div>
  );
};

export type TableRendererCurrencyProps = {
  className?: string;
  value?: { amount: number; currency: Dinero.Currency };
};

export function TableRendererCurrencyFactory(
  props: TableRendererCurrencyProps = {},
) {
  return (value: { amount: number; currency: Dinero.Currency }) => (
    <TableRendererCurrency {...props} value={value} />
  );
}
