import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { message, Spin } from 'antd';
import classnames from 'classnames';

import UserPaymentLimitUpdate from '../../../organisms/UserPaymentLimitUpdate/UserPaymentLimitUpdate';
import { UserIdBeneficiariesView } from '../UserIdBeneficiariesView/UserIdBeneficiariesView';
import { UserIdCareView } from '../UserIdCareView/UserIdCareView';
import { UserIdCompaniesView } from '../UserIdCompaniesView/UserIdCompaniesView';
import { UserIdCouponClaimablesView } from '../UserIdCouponClaimablesView/UserIdCouponClaimablesView';
import { UserIdHappeningsView } from '../UserIdHappeningsView/UserIdHappeningsView';
import { UserIdKycView } from '../UserIdKycView/UserIdKycView';
import { UserIdOrdersView } from '../UserIdOrdersView/UserIdOrdersView';
import { UserIdReimbursementView } from '../UserIdReimbursementView/UserIdReimbursementView';
import { UserIdStripePaymentIntentsView } from '../UserIdStripePaymentIntentsView/UserIdStripePaymentIntentsView';
import { UserIdSubventionView } from '../UserIdSubventionView/UserIdSubventionView';
import { UserInvitationsView } from '../UserInvitationsView/UserInvitationsView';

import { AdminCommentTargetType } from '@graphql/generated/types';

import {
  UserUpdateForm,
  UserUpdateFormProps,
} from '@components/forms/UserUpdateForm/UserUpdateForm';
import AdminCommentsDrawerOpenButton from '@components/organisms/AdminCommentsDrawerOpenButton/AdminCommentsDrawerOpenButton';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';
import { UserHeader } from '@organisms/UserHeader/UserHeader';

import styles from './UserIdView.module.css';

import { useUserIdViewQuery } from './UserIdView.generated';

export const UserIdView: React.FC<UserIdViewProps> = (props) => {
  const { className, match } = props;
  const {
    params: { userId },
  } = match;
  const { t } = useTranslation('users');

  const query = useUserIdViewQuery({
    variables: {
      userId,
      takeStripePaymentIntents: 10,
      skipStripePaymentIntents: 0,
      takeOrders: 10,
      skipOrders: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = query?.data?.user;

  if (query.loading && !user) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!user) {
    return <NotFoundView />;
  }

  const handleSuccess: UserUpdateFormProps['onSuccess'] = async () => {
    await query.refetch(query.variables);
    message.success(t('userUpdateForm.success'));
  };

  const handleFailure: UserUpdateFormProps['onFailure'] = (error) => {
    message.error('Une erreur est survenue');
    console.error(error);
  };

  return (
    <div className={classnames(className, styles.root)}>
      <UserHeader className={styles.header} user={user} />
      <TabsRouter match={match}>
        <TabsRouter.Route path={match.url} exact label={t('tabs.orders')}>
          <UserIdOrdersView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/coupons`}
          exact
          label={t('tabs.couponClaimables')}
        >
          <UserIdCouponClaimablesView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/companies`}
          exact
          label={t('tabs.companies')}
        >
          <UserIdCompaniesView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/settings`}
          label={t('tabs.parameters')}
        >
          <UserUpdateForm
            user={user}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/paymentLimit`}
          label={t('tabs.paymentLimit')}
        >
          <UserPaymentLimitUpdate userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/payments`}
          label={t('tabs.paymentIntents')}
        >
          <UserIdStripePaymentIntentsView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/beneficiaries`}
          exact
          label={t('tabs.beneficiaries')}
        >
          <UserIdBeneficiariesView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route path={`${match.url}/kyc`} exact label={t('tabs.kyc')}>
          <UserIdKycView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/subventions`}
          exact
          label={t('tabs.subventions')}
        >
          <UserIdSubventionView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/reimbursements`}
          exact
          label={t('tabs.reimbursements')}
        >
          <UserIdReimbursementView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/happenings`}
          exact
          label={t('tabs.happenings')}
        >
          <UserIdHappeningsView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/care`}
          exact
          label={t('tabs.care')}
        >
          <UserIdCareView userId={user.id} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/invitations`}
          exact
          label={t('tabs.invitations')}
        >
          <UserInvitationsView userId={user.id} />
        </TabsRouter.Route>
      </TabsRouter>

      <AdminCommentsDrawerOpenButton
        targetId={user.id}
        targetType={AdminCommentTargetType.User}
      />
    </div>
  );
};

export type UserIdViewProps = RouteComponentProps<{ userId: string }> & {
  className?: string;
};
