import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { OffersTable } from '@tables/OffersTable/OffersTable';

import styles from '../Redbin.module.css';

import { useRedBinOffersEndedQuery } from './OffersEnded.generated';

export const OffersEnded: React.FC<OffersEndedProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinOffersEndedQuery({
    variables: { take: 10, skip: 0 },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('offersEnded.title')} />
      <OffersTable
        query={query}
        data={query.data?.redBinOffersEnded}
        activeColumns={[
          'name',
          'type',
          'status',
          'brand',
          'campaign',
          'startsAt',
          'endsAt',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type OffersEndedProps = {
  className?: string;
};
