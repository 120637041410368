import CampaignPromoteCheckbox from '@components/organisms/CampaignPromoteCheckbox/CampaignPromoteCheckbox';
import CampaignPublishCheckbox from '@components/organisms/CampaignPublishCheckbox/CampaignPublishCheckbox';

type Props = {
  campaignId: string;
};

const CampaignIdSettingsView = ({ campaignId }: Props) => {
  return (
    <div>
      <CampaignPublishCheckbox campaignId={campaignId} />
      <CampaignPromoteCheckbox campaignId={campaignId} />
    </div>
  );
};

export default CampaignIdSettingsView;
