import { Spin } from 'antd';

import { MetabaseEmbed } from '@components/atoms/MetabaseEmbed/MetabaseEmbed';

import { useCompanyIdStatsViewQuery } from './CompanyIdStatsView.generated';

type CompanyIdStatsViewProps = {
  companyId: string;
};

export function CompanyIdStatsView(props: CompanyIdStatsViewProps) {
  const { companyId } = props;

  const { data } = useCompanyIdStatsViewQuery({
    variables: {
      companyId,
    },
  });

  return (
    <div>
      {data?.company?.metabaseStatsDashboardToken ? (
        <MetabaseEmbed token={data.company.metabaseStatsDashboardToken} />
      ) : (
        <Spin />
      )}
    </div>
  );
}
