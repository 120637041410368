import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Descriptions,
  Layout,
  message,
  Popconfirm,
  Space,
  Spin,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import Dinero from 'dinero.js';

import {
  MangopayBankwirePayOut,
  MangopayTransactionStatus,
  MangopayTransfer,
  SubventionReimbursementRequest,
  SubventionReimbursementRequestStatus,
  UserKycStatus,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionReimbursementStatusTag } from '@components/atoms/SubventionReimbursementStatusTag/SubventionReimbursementStatusTag';
import { UserReimbursementStatusTag } from '@components/atoms/UserReimbursementStatusTag/UserReimbursementStatusTag';
import { SubventionReimbursementRejectModal } from '@components/modals/SubventionReimbursementRejectModal/SubventionReimbursementRejectModal';
import { MangopayUserCard } from '@components/molecules/MangopayUserCard/MangopayUserCard';
import { MangopayPayOutsTable } from '@components/tables/MangopayPayOutsTable/MangopayPayOutsTable';
import { useMangopayBankwirePayOutRetryMutation } from '@components/tables/MangopayPayOutsTable/MangopayPayOutsTable.generated';
import { MangopayTransferTable } from '@components/tables/MangopayTransfersTable/MangopayTransfersTable';
import { useMangopayTransferRetryMutation } from '@components/tables/MangopayTransfersTable/MangopayTransfersTable.generated';

import {
  useMangopayBankwirePayOutCreateForSubventionReimbursementMutation,
  useSubventionReimbursementIdViewQuery,
  useSubventionReimbursementRequestApproveMutation,
} from './SubventionReimbursementIdView.generated';

export type SubventionReimbursementIdViewProps = RouteComponentProps<{
  subventionReimbursementId: string;
}> & {
  className?: string;
};

export const SubventionReimbursementIdView = ({
  match,
}: SubventionReimbursementIdViewProps) => {
  const { t, i18n } = useTranslation([
    'subventionReimbursements',
    'mangopayTransfer',
  ]);

  const {
    params: { subventionReimbursementId },
  } = match;

  const [mangopayBankwirePayOutRetry] =
    useMangopayBankwirePayOutRetryMutation();
  const handlePayoutRetry =
    (mangopayBankwirePayOutId: MangopayBankwirePayOut['id']) => () =>
      mangopayBankwirePayOutRetry({
        variables: { mangopayBankwirePayOutId },
        refetchQueries: ['MangopayPayOutsTable'],
      }).catch((error) => {
        message.error(error.message);
      });

  const [mangopayTransferRetry] = useMangopayTransferRetryMutation();
  const handleTransferRetry =
    (mangopayTransferId: MangopayTransfer['id']) => () =>
      mangopayTransferRetry({
        variables: { mangopayTransferId },
        refetchQueries: ['MangopayTransfersTable'],
      }).catch((error) => {
        message.error(error.message);
      });

  const [mangopayPayOutCreateForSubventionReimbursement] =
    useMangopayBankwirePayOutCreateForSubventionReimbursementMutation();
  const handlePayoutCreate =
    (subventionReimbursementRequestId: string) => async () => {
      await mangopayPayOutCreateForSubventionReimbursement({
        variables: {
          subventionReimbursementRequestId,
        },
        refetchQueries: [
          'MangopayPayOutsTable',
          'SubventionReimbursementIdView',
        ],
      }).catch((error) => {
        message.error(error.message);
      });
    };

  const query = useSubventionReimbursementIdViewQuery({
    variables: {
      subventionReimbursementRequestId: subventionReimbursementId,
    },
  });

  const data = query.data;

  const subventionReimbursement = data?.subventionReimbursementRequest;

  const haveBankAccount = subventionReimbursement
    ? subventionReimbursement.user.mangopayUser?.bankAccountsPagination
        .totalCount ?? 0
    : false;
  const isKycValidated = subventionReimbursement
    ? subventionReimbursement.user.kycStatus === UserKycStatus.Validated
    : false;

  const haveNoPayout = subventionReimbursement
    ? subventionReimbursement.mangopayBankwirePayOutPagination.totalCount === 0
    : false;

  const isCreatePayoutButtonVisible =
    haveBankAccount && isKycValidated && haveNoPayout;

  const [subventionReimbursementRequestApprove] =
    useSubventionReimbursementRequestApproveMutation();

  const handleApprove =
    (subventionReimbursementRequestId: SubventionReimbursementRequest['id']) =>
    async () => {
      await subventionReimbursementRequestApprove({
        variables: { subventionReimbursementRequestId },
        refetchQueries: [
          'MangopayTransfersTable',
          'MangopayPayOutsTable',
          'SubventionReimbursementIdView',
        ],
        awaitRefetchQueries: true,
      });
    };

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  if (!subventionReimbursement) {
    return <Spin size="large" />;
  }
  return (
    <Layout>
      <Card
        title={
          <Title level={5}>
            {t(
              'subventionReimbursementIdView.title',
              'Demande de remboursement',
            )}
          </Title>
        }
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Descriptions bordered>
            <Descriptions.Item label="Identifiant">
              {subventionReimbursement.id}
            </Descriptions.Item>
            <Descriptions.Item label="CreatedAt">
              {subventionReimbursement.createdAt.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="UpdatedAt">
              {subventionReimbursement.updatedAt.toLocaleString()}
            </Descriptions.Item>
            {subventionReimbursement.user.company && (
              <Descriptions.Item label="Company">
                <I18nLink
                  to={`/companies/list/${subventionReimbursement.user.company.id}`}
                >
                  {subventionReimbursement.user.company.name}
                </I18nLink>
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label="User"
              span={subventionReimbursement.user.company ? 1 : 2}
            >
              <I18nLink to={`/users/list/${subventionReimbursement.user.id}`}>
                {subventionReimbursement.user.firstName}{' '}
                {subventionReimbursement.user.lastName}
              </I18nLink>{' '}
              <UserReimbursementStatusTag user={subventionReimbursement.user} />
            </Descriptions.Item>
            <Descriptions.Item label="Subvention">
              <I18nLink
                to={`/subventions/subventions/${subventionReimbursement.subvention.id}`}
              >
                {subventionReimbursement.subvention.name}
              </I18nLink>
            </Descriptions.Item>
            <Descriptions.Item label="Total amount">
              {Dinero({
                amount: subventionReimbursement.totalAmount,
                currency: subventionReimbursement.currency,
              })
                .setLocale(i18n.resolvedLanguage)
                .toFormat()}
            </Descriptions.Item>
            <Descriptions.Item label="Reimbursement amount">
              {Dinero({
                amount: subventionReimbursement.reimbursementAmount,
                currency: subventionReimbursement.currency,
              })
                .setLocale(i18n.resolvedLanguage)
                .toFormat()}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <SubventionReimbursementStatusTag
                subventionReimbursementRequest={subventionReimbursement}
              />
            </Descriptions.Item>
            {subventionReimbursement.rejectedReason && (
              <Descriptions.Item label="Rejected reason" span={1}>
                {subventionReimbursement.rejectedReason}
              </Descriptions.Item>
            )}
            {subventionReimbursement.processedBy && (
              <Descriptions.Item
                label="Processed By"
                span={subventionReimbursement.rejectedReason ? 1 : 2}
              >
                <I18nLink
                  to={`/users/list/${subventionReimbursement.processedBy.id}`}
                >
                  {subventionReimbursement.processedBy.firstName}{' '}
                  {subventionReimbursement.processedBy.lastName}
                </I18nLink>
                {subventionReimbursement.processedBy.company?.logo && (
                  <img
                    alt="company logo"
                    src={
                      subventionReimbursement.processedBy.company?.logo?.file
                        .url
                    }
                    style={{ width: '50px', height: '50px' }}
                  />
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Date invoice">
              {subventionReimbursement.invoiceDate.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Invoices" span={3}>
              {subventionReimbursement.proofs.nodes.map((node) => (
                <a
                  key={node.id}
                  href={node.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {node.originalName}
                </a>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Mangopay" span={3}>
              <MangopayUserCard
                mangopayUser={subventionReimbursement.user.mangopayUser}
              />
            </Descriptions.Item>
          </Descriptions>
          {subventionReimbursement.status ===
            SubventionReimbursementRequestStatus.WaitingForApproval && (
            <Card>
              <Space>
                <Popconfirm
                  title={t(
                    'subventionReimbursements:table.headers.action.approveConfirm',
                  )}
                  okText={t('common:BooleanTag.true')}
                  cancelText={t('common:BooleanTag.false')}
                  onConfirm={handleApprove(subventionReimbursement.id)}
                >
                  <Button type="primary" color="#">
                    {t('subventionReimbursements:table.headers.action.approve')}
                  </Button>
                </Popconfirm>

                <Button
                  type="primary"
                  danger
                  onClick={() => setIsRejectModalOpen(true)}
                >
                  {t('subventionReimbursements:table.headers.action.reject')}
                </Button>
              </Space>
            </Card>
          )}

          <MangopayTransferTable
            title={() => (
              <strong>
                {t('mangopayTransferTable.title', 'Liste des transfers')}
              </strong>
            )}
            activeColumns={[
              'id',
              'updatedAt',
              'mangopayId',
              'amount',
              'status',
              'resultMessage',
            ]}
            filter={{
              subventionReimbursementRequest: {
                SOME: { id: { is: subventionReimbursement.id } },
              },
            }}
            action={(record) => {
              if (record.status === MangopayTransactionStatus.Failed) {
                return (
                  <Space direction="vertical">
                    <Popconfirm
                      title={t(
                        'mangopayTransferTable.action.retry.confirmation',
                        'Est ce que vous voulez vraiment retenter ce transfer ?',
                      )}
                      okText={t('common:BooleanTag.true')}
                      cancelText={t('common:BooleanTag.false')}
                      onConfirm={handleTransferRetry(record.id)}
                    >
                      <Button type="primary" icon={<ReloadOutlined />} danger>
                        {t(
                          'mangopayTransferTable.action.retry.button',
                          'Retry',
                        )}
                      </Button>
                    </Popconfirm>
                  </Space>
                );
              }
            }}
          />

          <MangopayPayOutsTable
            title={() => (
              <Space>
                <strong>
                  {t('mangopayPayOutsTable.title', 'Liste des payouts')}
                </strong>
                {isCreatePayoutButtonVisible && (
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={handlePayoutCreate(subventionReimbursementId)}
                  >
                    Créer le mangopay pay out
                  </Button>
                )}
              </Space>
            )}
            activeColumns={[
              'id',
              'updatedAt',
              'mangopayId',
              'amount',
              'status',
              'resultMessage',
            ]}
            filter={{
              subventionReimbursementRequest: {
                SOME: { id: { is: subventionReimbursement.id } },
              },
            }}
            action={(record) => {
              if (record.status === MangopayTransactionStatus.Failed) {
                return (
                  <Space direction="vertical">
                    <Popconfirm
                      title={t(
                        'mangopayPayOutsTable.action.retry.confirmation',
                        'Est ce que vous voulez vraiment retenter ce payout ?',
                      )}
                      okText={t('common:BooleanTag.true')}
                      cancelText={t('common:BooleanTag.false')}
                      onConfirm={handlePayoutRetry(record.id)}
                    >
                      <Button type="primary" icon={<ReloadOutlined />} danger>
                        {t('mangopayPayOutsTable.action.retry.button', 'Retry')}
                      </Button>
                    </Popconfirm>
                  </Space>
                );
              }
            }}
          />
        </Space>

        <SubventionReimbursementRejectModal
          key={subventionReimbursement.id}
          subventionReimbursementRequestId={subventionReimbursement.id}
          isOpen={isRejectModalOpen}
          onClose={() => {
            setIsRejectModalOpen(false);
          }}
        />
      </Card>
    </Layout>
  );
};
