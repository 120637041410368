import { useState } from 'react';
import { Button, message } from 'antd';

import { CatalogViewDocument } from '../../CatalogView.generated';

import { CatalogAccessMode } from '@graphql/generated/types';

import { Typography } from '@components/atoms/Typography/Typography';

import styles from './CatalogCreateForm.module.css';

import { useCatalogFormCreateMutation } from './CatalogCreateForm.generated';

type CatalogCreateFormProps = {
  companyId: string;
};

export const CatalogCreateForm = (props: CatalogCreateFormProps) => {
  const { companyId } = props;
  const [catalogCreateMutation] = useCatalogFormCreateMutation();

  const [loading, setLoading] = useState<CatalogAccessMode | null>(null);

  const handleCreate = async (accessMode: CatalogAccessMode) => {
    try {
      setLoading(accessMode);
      await catalogCreateMutation({
        variables: {
          companyId,
          input: {
            accessMode,
          },
        },
        refetchQueries: [
          {
            query: CatalogViewDocument,
            variables: { companyId },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      setLoading(null);

      if (err instanceof Error) {
        message.error(err.message);
      } else {
        message.error('Something went wrong');
        console.error(err);
      }
    }
  };

  return (
    <div className={styles.root}>
      <Typography.Title level={3}>
        Créer un catalogue personnalisé
      </Typography.Title>
      <div className={styles.containers}>
        <div className={styles.container}>
          <Button
            onClick={() => handleCreate(CatalogAccessMode.Blacklist)}
            loading={loading === CatalogAccessMode.Blacklist}
            disabled={loading === CatalogAccessMode.Whitelist}
          >
            Blocklist (défaut)
          </Button>
          <div className={styles.description}>
            Le catalog contient tout sauf les éléments explicitement retirés
          </div>
        </div>
        <div className={styles.container}>
          <Button
            onClick={() => handleCreate(CatalogAccessMode.Whitelist)}
            loading={loading === CatalogAccessMode.Whitelist}
            disabled={loading === CatalogAccessMode.Blacklist}
          >
            AllowList
          </Button>
          <div className={styles.description}>
            Le catalog ne contient que les éléments explicitement ajoutés
          </div>
        </div>
      </div>
    </div>
  );
};
