import { Button, Card, Form, Input, message, Space } from 'antd';

import {
  useAwsWafRemoveIpV4InIpSetMutation,
  useAwsWafRemoveIpV6InIpSetMutation,
} from './AwsWafView.generated';

export const AwsWafView = () => {
  const [removeIpV4InIpSetMutation] = useAwsWafRemoveIpV4InIpSetMutation();
  const [removeIpV6InIpSetMutation] = useAwsWafRemoveIpV6InIpSetMutation();

  const [formIpv4] = Form.useForm<{ ipv4: string }>();
  const [formIpv6] = Form.useForm<{ ipv6: string }>();

  const onFinishFormIpv4 = ({ ipv4 }: { ipv4: string }) => {
    if (!ipv4) {
      message.error('Vous devez renseinger au moins une IP v4');
      return;
    }

    removeIpV4InIpSetMutation({ variables: { ip: ipv4 } })
      .then(() => {
        message.success('IP v4 débloquée');
        formIpv4.resetFields();
      })
      .catch((error) => {
        if (error instanceof Error) {
          message.error(error.message, 5);
        }
      });
  };

  const onFinishFormIpv6 = ({ ipv6 }: { ipv6: string }) => {
    if (!ipv6) {
      message.error('Vous devez renseinger au moins une IP v6');
      return;
    }

    removeIpV6InIpSetMutation({ variables: { ip: ipv6 } })
      .then(() => {
        message.success('IP v6 débloquée');
        formIpv6.resetFields();
      })
      .catch((error) => {
        if (error instanceof Error) {
          message.error(error.message, 5);
        }
      });
  };

  return (
    <Card title="Blocage IP dans le parefeu AWS">
      <Space direction="vertical" size="middle">
        <Form
          name="ipsetV4"
          layout="inline"
          onFinish={onFinishFormIpv4}
          form={formIpv4}
        >
          <Form.Item label="IP v4" name="ipv4" required>
            <Input style={{ width: '150px' }} placeholder="192.168.100.89" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Débloquer
            </Button>
          </Form.Item>
        </Form>

        <Form
          name="ipsetV6"
          layout="inline"
          onFinish={onFinishFormIpv6}
          form={formIpv6}
        >
          <Form.Item label="IP v6" name="ipv6" required>
            <Input
              style={{ width: '350px' }}
              placeholder="fd00:cb11:415:a100:e42d:9fb6:9eef:a62b"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Débloquer
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  );
};
