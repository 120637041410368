import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { SSOProviderCreateButton } from '@components/organisms/SSOProviderCreateButton/SSOProviderCreateButton';
import { SSOProviderTable } from '@components/tables/SSOProviderTable/SSOProviderTable';

import styles from './CompanyIdSSOView.module.css';

import { useCompanyIdSsoViewQuery } from './CompanyIdSSOView.generated';

export const CompanyIdSSOView: React.FC<CompanyIdSSOViewProps> = (props) => {
  const { className, companyId } = props;
  const { t } = useTranslation('companySSO');

  const query = useCompanyIdSsoViewQuery({
    variables: { companyId },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title level={3}>{t('title')}</Typography.Title>
      <SSOProviderTable
        query={query}
        title={() => <SSOProviderCreateButton companyId={companyId} />}
        data={query.data?.company?.ssoProviderPagination}
        activeColumns={['slug', 'status', 'type']}
      />
    </div>
  );
};

export type CompanyIdSSOViewProps = {
  companyId: string;
  className?: string;
};
