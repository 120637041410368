import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Layout, Typography } from 'antd';
import classnames from 'classnames';

import { PublicationsView } from '../PublicationsView/PublicationsView';

import { PageHeader } from '@components/molecules/PageHeader/PageHeader';

import styles from './PublicationsDashboardView.module.css';

export const PubilcationsDashboardView: React.FC<PublicationsDashboardViewProps> =
  (props) => {
    const { className } = props;
    const { t } = useTranslation('communication');

    return (
      <Layout className={classnames(className, styles.root)}>
        <PageHeader showNav className={classnames(className, styles.root)}>
          <Typography.Title
            level={2}
            ellipsis={{ rows: 2 }}
            className={styles.title}
          >
            {t('publications.dashboard.title', 'Gérer les publications')}
          </Typography.Title>
        </PageHeader>
        <PublicationsView />
      </Layout>
    );
  };

export type PublicationsDashboardViewProps = RouteComponentProps & {
  className?: string;
};
