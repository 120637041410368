import { List } from 'antd';

import { I18nLink } from '../Link/Link';

import { CampaignListItemFragment } from './CampaignList.generated';

export const CampaignList: React.FC<CampaignListProps> = (props) => {
  const { campaigns } = props;
  return (
    <List
      dataSource={campaigns}
      renderItem={(item) => (
        <List.Item>
          <I18nLink to={`/campaigns/list/${item.id}`}>{item.name}</I18nLink>
        </List.Item>
      )}
    />
  );
};

export type CampaignListProps = {
  className?: string;
  campaigns: CampaignListItemFragment[];
};
