import classnames from 'classnames';

import { AdminCommentTargetType } from '@graphql/generated/types';

import { AdminCommentForm } from '@components/forms/AdminCommentForm/AdminCommentForm';
import { AdminCommentFormFragment } from '@components/forms/AdminCommentForm/AdminCommentForm.generated';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './AdminCommentModal.module.css';

export type AdminCommentModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  defaultValues?: AdminCommentFormFragment;
  targetType: AdminCommentTargetType;
  targetId: string;
};

export function AdminCommentModal(props: AdminCommentModalProps) {
  const {
    className,
    onClose,
    onSuccess,
    isOpen,
    defaultValues,
    targetId,
    targetType,
  } = props;

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess = async () => {
    await onSuccess?.();

    handleClose();
  };

  return (
    <Modal
      title="AdminCommentModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="AdminCommentForm"
    >
      <AdminCommentForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        targetId={targetId}
        targetType={targetType}
        id="AdminCommentForm"
      />
    </Modal>
  );
}
