import { useEffect, useState } from 'react';
import { MutationTuple } from '@apollo/client';
import { Alert, Button, message, Progress, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import styles from './TranslationGeneratorModal.module.css';

import { TranslationGeneratorRowProps } from './TranslationGeneratorRow';

export const TranslationGeneratorModal = (
  props: TranslationGeneratorModalProps,
) => {
  const { locale, useMissingQuery, onClose } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [iterationCount, setIterationCount] = useState(-1);

  const missingQuery = useMissingQuery({
    variables: { localeId: locale.code },
  });

  useEffect(() => {
    missingQuery.refetch({ localeId: locale.code }).then(({ data }) => {
      setTotalCount(data.entities.totalCount);
      setProcessedCount(0);
    });
  }, [isVisible]);

  useEffect(() => {
    if (iterationCount >= 0) {
      handlePlay();
    }
  }, [iterationCount]);

  const isLoading = missingQuery.loading && !missingQuery.data;

  const handlePlay = async (): Promise<void> => {
    const batchIds = await fetchBatchIds();

    const entityId = batchIds[0];

    if (!entityId) return;

    try {
      await generateTranslationForEntity(entityId);
      setProcessedCount((c) => c + 1);
    } catch (err) {
      message.error('Une erreur est survenue');
      handlePause();
      return;
    }

    setIterationCount((f) => (f >= 0 ? f + 1 : f));
  };

  const handlePause = () => {
    setIterationCount(-1);
  };

  const handleStop = () => {
    handlePause();
    setVisible(false);
    onClose?.();
  };

  const fetchBatchIds = async () => {
    const { data } = await missingQuery.refetch({ localeId: locale.code });

    const batchIds = data.entities.nodes.map((n) => n.id);

    return batchIds;
  };

  const generateTranslationForEntity = async (entityId: string) => {
    return await props.generateMutation({
      variables: { entityId, localeId: locale.code },
    });
  };

  const percent = (processedCount / totalCount) * 100;

  return (
    <>
      <Button onClick={() => setVisible(true)} size="small">
        Générer
      </Button>
      <Modal
        visible={isVisible}
        onCancel={handleStop}
        footer={null}
        title="Génération automatique de traduction"
      >
        <Alert
          message="Génération de traduction"
          description={`Vous êtes sur le point de générer une traduction automatique pour la langue "${locale.name}". Cette opération peut prendre un certain temps, merci de patienter.`}
          type="warning"
        />
        {isLoading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div>
            <div className={styles.progress}>
              <Progress
                format={(v) => (v !== undefined ? Math.trunc(v) : 0) + '%'}
                percent={percent}
              />
              <span className={styles.counter}>{`${[
                processedCount,
              ]} / ${totalCount}`}</span>
            </div>
            <div className={styles.controls}>
              <Button
                disabled={iterationCount > 0}
                loading={iterationCount > 0}
                onClick={() => setIterationCount(0)}
              >
                Play
              </Button>
              <Button disabled={iterationCount <= 0} onClick={handlePause}>
                Pause
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export type TranslationGeneratorModalProps = {
  useMissingQuery: TranslationGeneratorRowProps['useMissingQuery'];
  generateMutation: MutationTuple<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    { entityId: string; localeId: string }
  >[0];
  locale: TranslationGeneratorRowProps['locale'];
  onClose?: () => void;
};
