import { Layout } from 'antd';
import classnames from 'classnames';

import { AppHeaderProfile } from '../AppHeaderProfile/AppHeaderProfile';

import styles from './AppHeader.module.css';

import { useAppHeaderQuery } from './AppHeader.generated';

const { Header } = Layout;

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const { className } = props;

  const { data } = useAppHeaderQuery();

  return (
    <Header className={classnames(className, styles.root)}>
      {data?.viewer && (
        <div className={styles.right}>
          <AppHeaderProfile className={styles.profile} user={data.viewer} />
        </div>
      )}
    </Header>
  );
};

export type AppHeaderProps = {
  className?: string;
};
