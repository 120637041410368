import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { UserIntegrationProviderName } from '@graphql/generated/types';

import styles from './UserIntegrationProfileTag.module.css';

import { UserIntegrationProfileTagFragment } from './UserIntegrationProfileTag.generated';

export const UserIntegrationProfileTag: React.FC<UserIntegrationProfileTagProps> =
  (props) => {
    const { className, userIntegrationProfile } = props;
    const { t } = useTranslation('user-integration');

    const labels: Record<UserIntegrationProviderName, string> = {
      LUCCA: t('type.LuccaProfile', 'Lucca'),
      SALESFORCE_RCU: t('type.SalesforceRcuProfile', 'Salesforce RCU'),
    };

    return (
      <Tag className={classnames(className, styles.root)}>
        {labels[userIntegrationProfile.providerName]}
      </Tag>
    );
  };

export type UserIntegrationProfileTagProps = {
  className?: string;
  userIntegrationProfile: UserIntegrationProfileTagFragment;
};
