import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { ArticleCategoryTranslationForm } from '@forms/ArticleCategoryTranslationForm/ArticleCategoryTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './ArticleCategoryIdTranslationsView.module.css';

import { useArticleCategoryIdTranslationsViewQuery } from './ArticleCategoryIdTranslationsView.generated';

export const ArticleCategoryIdTranslationsView: React.FC<ArticleCategoryIdTranslationsViewProps> =
  (props) => {
    const { className, articleCategoryId } = props;
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
      undefined,
    );

    const localesQuery = useLocales();

    const query = useArticleCategoryIdTranslationsViewQuery({
      variables: { articleCategoryId },
      notifyOnNetworkStatusChange: true,
    });

    const articleCategory = query?.data?.articleCategory;
    const locales = localesQuery?.data?.localesPagination.nodes;

    if ((localesQuery.loading || query.loading) && !articleCategory) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!articleCategory) {
      return <NotFoundView />;
    } else if (!locales) {
      return <ServerErrorView />;
    }

    const { viewer } = useAuth();

    const userLanguage = viewer?.preferences?.properties.language || 'en';

    return (
      <div className={classnames(className, styles.root)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          defaultActiveKey={userLanguage}
        >
          {articleCategory?.translationsPagination.nodes.map((node) => {
            const locale = locales.find((locale) => locale.id === node.locale);

            return (
              <Tabs.TabPane
                tab={locale ? `${locale.name} (${locale.id})` : node.locale}
                key={node.locale}
              >
                <ArticleCategoryTranslationForm
                  articleCategoryId={articleCategory.id}
                  translation={node}
                  onDeleted={({ articleCategory }) =>
                    setActiveTabKey(articleCategory.locale)
                  }
                />
              </Tabs.TabPane>
            );
          })}
          {articleCategory.translationsPagination.totalCount <
            locales.length && (
            <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
              <ArticleCategoryTranslationForm
                onCreated={({ articleCategoryTranslation }) =>
                  setActiveTabKey(articleCategoryTranslation.locale)
                }
                localeFilter={{
                  locale: {
                    exclude: articleCategory.translationsPagination.nodes.map(
                      (node) => node.locale,
                    ),
                  },
                }}
                articleCategoryId={articleCategory.id}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };

export type ArticleCategoryIdTranslationsViewProps = {
  className?: string;
  articleCategoryId: string;
};
