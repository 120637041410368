import { DeepPartial } from 'react-hook-form';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { SearchSynchronizationType } from '@graphql/generated/types';

import { FormSelectSearchSynchronizationType } from '@components/atoms/FormSelectSearchSynchronizationType/FormSelectSearchSynchronizationType';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './SearchSynchronizationCreateForm.module.css';

import {
  SearchSynchronizationCreateFormMutation,
  SearchSynchronizationCreateFormMutationVariables,
  useSearchSynchronizationCreateFormMutation,
} from './SearchSynchronizationCreateForm.generated';

export type SearchSynchronizationCreateFormValues = {
  type: SearchSynchronizationType;
};

export function SearchSynchronizationCreateForm(
  props: SearchSynchronizationCreateFormProps,
) {
  const { className, defaultValues = {}, ...rest } = props;

  const validationSchema = Yup.object({
    type: Yup.mixed()
      .oneOf(Object.values(SearchSynchronizationType))
      .required(),
  }).required();

  const form = useForm<SearchSynchronizationCreateFormValues>({
    validationSchema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const [mutation] = useSearchSynchronizationCreateFormMutation();

  const handleSubmit: FormProps<SearchSynchronizationCreateFormValues>['onValid'] =
    async (values) => {
      const variables: SearchSynchronizationCreateFormMutationVariables = {
        input: {
          type: values.type,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="SearchSynchronizationCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.type}
          required
          label="Type d'offre"
          name="type"
        >
          <FormSelectSearchSynchronizationType name="type" />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la synchronization
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type SearchSynchronizationCreateFormProps = ChildFormProps<
  SearchSynchronizationCreateFormValues,
  SearchSynchronizationCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<SearchSynchronizationCreateFormValues>;
};
