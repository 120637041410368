import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { StoresTable } from '@components/tables/StoresTable/StoresTable';

import styles from '../Redbin.module.css';

import { useRedBinStoresWithoutProvinceQuery } from './StoresWithoutProvince.generated';

export const StoresWithoutProvince: React.FC<StoresWithoutProvinceProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinStoresWithoutProvinceQuery({
    variables: { take: 10, skip: 0 },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('StoresWithoutProvince.title')} />
      <StoresTable
        query={query}
        data={query.data?.redBinStoresWithoutProvince}
        activeColumns={[
          'formatted',
          'brand',
          'province',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type StoresWithoutProvinceProps = {
  className?: string;
};
