import type { ValidatorRule } from 'rc-field-form/lib/interface';
import isEmail from 'validator/es/lib/isEmail';

export const isEmailValidator: ValidatorRule['validator'] = async (
  _,
  value,
) => {
  return new Promise<void>((resolve, reject) => {
    if (isEmail(value)) {
      resolve();
    } else {
      reject();
    }
  });
};
