import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './OrderStatusTag.module.css';

import { OrderStatusTagFragment } from './OrderStatusTag.generated';

export const OrderStatusTag: React.FC<OrderStatusTagProps> = (props) => {
  const { className, order } = props;
  const { t } = useTranslation('orders');

  const colors: Record<typeof order.status, string> = {
    DRAFT: 'grey',
    PAID: 'green',
    CANCELED: 'red',
    DELIVERED_FULL: 'green',
    DELIVERED_PARTIAL: 'orange',
    REFUNDED_FULL: 'orange',
    REFUNDED_PARTIAL: 'orange',
  };

  const labels: Record<typeof order.status, string> = {
    DRAFT: t('status.draft'),
    PAID: t('status.paid'),
    CANCELED: t('status.canceled'),
    DELIVERED_FULL: t('status.deliveredFull'),
    DELIVERED_PARTIAL: t('status.deliveredPartial'),
    REFUNDED_FULL: t('status.refundedFull'),
    REFUNDED_PARTIAL: t('status.refundedPartial'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[order.status]}
    >
      {labels[order.status]}
    </Tag>
  );
};

export type OrderStatusTagProps = {
  className?: string;
  order: OrderStatusTagFragment;
};
