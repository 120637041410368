import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Typography } from 'antd';
import Dinero, { Currency } from 'dinero.js';
import { sumBy } from 'lodash';
import * as Yup from 'yup';

import { Subvention } from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { ChildFormProps, Form, useForm } from '@components/organisms/Form/Form';

import styles from './OrderRefundCreateForm.module.css';

import { AmountRepartitionBar } from './components/AmountRepartitionBar/AmountRepartitionBar';
import { FundRepartitionBar } from './components/FundRepartitionBar/FundRepartitionBar';
import { OrderRefundCreateInAppRepartitor } from './components/OrderRefundCreateInAppRepartitor/OrderRefundCreateInAppRepartitor';
import { OrderRefundCreateOrderItemQuantitySelector } from './components/OrderRefundCreateOrderItemQuantitySelector/OrderRefundCreateOrderItemQuantitySelector';
import { OrderRefundCreateOrderItemSelector } from './components/OrderRefundCreateOrderItemSelector/OrderRefundCreateOrderItemSelector';
import {
  OrderRefundCreateFormOrderFragment,
  OrderRefundCreateFormOrderItemFragment,
  OrderRefundCreateFormSubventionConsumptionFragment,
  useOrderRefundCreateFormCreateRefundMutation,
} from './OrderRefundCreateForm.generated';
import { getRefundableAmounts } from './utils/getRefundableAmounts';
import { getRefundableSubventions } from './utils/getRefundableConsumptions';

export type OrderRefundCreateFormValue = Yup.InferType<typeof validationSchema>;

type OrderRefundCreateFormProps = ChildFormProps<OrderRefundCreateFormValue> & {
  order: OrderRefundCreateFormOrderFragment;
  orderItems: OrderRefundCreateFormOrderItemFragment[];
  subventionConsumptions: OrderRefundCreateFormSubventionConsumptionFragment[];
};

export type RefundableSubvention = {
  id: Subvention['id'];
  max: number;
  eligibleAmount: number;
  name: string;
};

export const OrderRefundCreateForm = (props: OrderRefundCreateFormProps) => {
  const { order, orderItems, subventionConsumptions, ...formProps } = props;

  const { t } = useTranslation([
    'order-refund',
    'orders',
    'companies',
    'subventions',
    'orderItems',
  ]);

  const OrderRefundCreateErrors: Record<string, string> = {
    'order-refund/wrong-allocation-amount': t(
      'form.errors.order-refund/wrong-allocation-amount',
    ),
    'order/already-refund-pending': t(
      'form.errors.order/already-refund-pending',
    ),
    'order-refund/order-item-quantity-exceeded': t(
      'form.errors.order-refund/order-item-quantity-exceeded',
    ),
    'order-refund/fees-amount': t('form.errors.order-refund/fees-amount'),
    'order/not-found': t('errors.order/not-found', { ns: 'orders' }),
    'order-item/not-found': t('errors.order-item/not-found', {
      ns: 'orderItems',
    }),
    'subvention/not-found': t('errors.subvention/not-found', {
      ns: 'subventions',
    }),
    'order-refund/stripe-amount': t('form.errors.order-refund/stripe-amount'),
    'order-refund/subvention-amount': t(
      'form.errors.order-refund/subvention-amount',
    ),
    'company/wallet-not-provisionned': t(
      'errors.company/wallet-not-provisionned',
      { ns: 'companies' },
    ),
  };

  const [refundCreate] = useOrderRefundCreateFormCreateRefundMutation();

  const initialItems = orderItems.map((orderItem) => ({
    orderItemId: orderItem.id,
    quantity: orderItem.quantity,
  }));

  const initialRefundableSubventions = getRefundableSubventions(
    orderItems,
    subventionConsumptions,
    initialItems,
  );

  const [refundableSubventions, setRefundableSubventions] = useState<
    RefundableSubvention[]
  >(initialRefundableSubventions);

  const form = useForm<OrderRefundCreateFormValue>({
    validationSchema,
    defaultValues: {
      stripeAmount: 0,
      feesAmount: 0,
      subventions: [],
      items: [],
      fundAmount: 0,
      totalAmount: 0,
    },
  });
  const items = form.watch('items') || [];
  const subventions = form.watch('subventions') || [];

  const amounts = getRefundableAmounts(order, orderItems, items);
  const fundRepartitionAmount = Math.min(
    amounts.fund,
    refundableSubventions.reduce((acc, rs) => acc + rs.max, 0),
  );
  form.setValue('fundAmount', amounts.fund);
  form.setValue('totalAmount', amounts.total);

  const handleOrderItemSelect = (orderItemIds: string[]) => {
    const items = orderItemIds.map((orderItemId) => ({
      orderItemId: orderItemId,
      quantity: 0,
    }));

    const subventions = getRefundableSubventions(
      orderItems,
      subventionConsumptions,
      items,
    );

    setRefundableSubventions(subventions);

    form.setValue('items', items);
    form.setValue(
      'subventions',
      subventions.map((rs) => ({
        amount: 0,
        subventionId: rs.id,
      })),
    );
  };

  const handleValid = async (v: OrderRefundCreateFormValue) => {
    try {
      await refundCreate({
        variables: {
          orderId: order.id,
          input: {
            stripeAmount: v.stripeAmount,
            feesAmount: v.feesAmount,
            items: v.items.map((i) => ({
              orderItemId: i.orderItemId,
              quantity: i.quantity,
            })),
            subventions: v.subventions
              .map((s) => ({
                subventionId: s.subventionId,
                amount: s.amount,
              }))
              .filter((s) => s.amount > 0),
          },
        },
        refetchQueries: ['OrderIdView', 'OrderRefundTable', 'OrderItemTable'],
      });

      message.success('Demande créée avec succès');
    } catch (err) {
      handleMutationErrors(err, OrderRefundCreateErrors);
    }

    return {};
  };

  useEffect(() => {
    const subventions = getRefundableSubventions(
      orderItems,
      subventionConsumptions,
      items,
    );

    setRefundableSubventions(subventions);
  }, [JSON.stringify(items)]);

  return (
    <Form
      id="OrderRefundCreateForm"
      form={form}
      onValid={handleValid}
      {...formProps}
    >
      <OrderRefundCreateOrderItemSelector
        order={order}
        orderItems={orderItems}
        onChange={handleOrderItemSelect}
      />

      {items.length > 0 && (
        <>
          <br />
          <OrderRefundCreateOrderItemQuantitySelector
            items={items || []}
            orderItems={orderItems}
          />
          <Typography.Text strong>
            Total à rembourser:{' '}
            {Dinero({
              amount: amounts.total,
              currency: order.currency,
            }).toFormat()}
          </Typography.Text>
          <Typography.Title level={5}>Répartition des fonds :</Typography.Title>
          <AmountRepartitionBar
            className={styles.amountRepartitionBar}
            values={[
              {
                amount: amounts.fees,
                color: '#000000',
                currency: order.currency,
                label: 'Frais',
              },
              {
                amount: amounts.stripe,
                color: '#73d13d',
                currency: order.currency,
                label: 'Stripe',
              },
              {
                amount: amounts.discount,
                color: '#4096ff',
                currency: order.currency,
                label: 'Réductions tarifaires',
              },
              {
                amount: fundRepartitionAmount,
                color: '#f759ab',
                currency: order.currency,
                label: 'Cagnottes',
              },
            ]}
          />
        </>
      )}

      {(amounts.total > 0 || amounts.discount > 0) && (
        <div>
          <br />
          <OrderRefundCreateInAppRepartitor
            amounts={amounts}
            order={order}
            orderItems={orderItems}
            items={items}
            refundableSubventions={refundableSubventions}
            orderRefundError={form.formState.errors.totalAmount?.message}
          />
          <br />
          {amounts.fund > 0 && (
            <>
              <Typography.Title level={5}>
                Répartition des cagnottes :
              </Typography.Title>

              <FundRepartitionBar
                totalAmount={fundRepartitionAmount}
                values={(subventions || []).map((sub) => ({
                  amount: sub.amount || 0,
                  currency: order.currency as Currency,
                  label:
                    subventionConsumptions.find(
                      (c) => c.subvention.id === sub.subventionId,
                    )?.subvention.name || '',
                }))}
              />
            </>
          )}
        </div>
      )}
    </Form>
  );
};

// FIXME: Context validation
const validationSchema = Yup.object({
  totalAmount: Yup.number()
    .min(0)
    .required()
    .test({
      name: 'is-valid',
      test: (totalAmount, context) => {
        const { subventions, stripeAmount } = context.parent;
        const subventionsAmount = sumBy(subventions, 'amount');

        const repartitionAmount = subventionsAmount + stripeAmount;
        if (repartitionAmount !== totalAmount) {
          return context.createError({ message: 'Mauvaise répartition' });
        }

        return true;
      },
    }),
  fundAmount: Yup.number().min(0).required(),
  stripeAmount: Yup.number().min(0).required(),
  feesAmount: Yup.number().min(0).required(),
  subventions: Yup.array(
    Yup.object({
      subventionId: Yup.string().uuid().required(),
      amount: Yup.number().min(0).required(),
    }).required(),
  ).required(),
  items: Yup.array(
    Yup.object({
      orderItemId: Yup.string().uuid().required(),
      quantity: Yup.number().min(0).required(),
    }).required(),
  )
    .required()
    .min(1),
}).required();
