import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Spin } from 'antd';
import classnames from 'classnames';

import { Store } from '@graphql/generated/types';

import { OfferSelectModal } from '@components/modals/OfferSelectModal/OfferSelectModal';
import {
  StoreRemoveOfferButton,
  StoreRemoveOfferButtonProps,
} from '@components/organisms/StoreRemoveOfferButton/StoreRemoveOfferButton';
import { OffersTable } from '@components/tables/OffersTable/OffersTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './StoreIdOffersView.module.css';

import {
  useStoreAddOffersMutation,
  useStoreIdOffersViewQuery,
} from './StoreIdOffersView.generated';

export const StoreIdOffersView: React.FC<StoreIdOffersViewProps> = (props) => {
  const { t } = useTranslation('stores');

  const { className, storeId } = props;

  const [isAdding, setAdding] = useState(false);
  const [offersAdd] = useStoreAddOffersMutation({
    refetchQueries: ['StoreIdView'],
  });

  const query = useStoreIdOffersViewQuery({
    variables: {
      storeId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const store = query?.data?.store;

  if (query.loading && !store) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!store) {
    return <NotFoundView />;
  }

  const handleAdd = async (offerIds: string[]) => {
    try {
      await offersAdd({
        variables: {
          input: {
            storeIds: [store.id],
            offerIds,
          },
        },
      });
      setAdding(false);
      await query.refetch();
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    }
  };

  const handleRemoveSuccess: StoreRemoveOfferButtonProps['onSuccess'] =
    async () => {
      await query.refetch();
    };

  return (
    <div className={classnames(className, styles.root)}>
      <OfferSelectModal
        isOpen={isAdding}
        onClose={() => setAdding(false)}
        onSubmit={handleAdd}
        filter={{
          AND: [
            { brand: { id: { is: store.brand.id } } },
            { stores: { id: { isNot: store.id } } },
          ],
        }}
      />

      <OffersTable
        activeColumns={['name', 'type', 'campaign', 'categories']}
        query={query}
        data={query?.data?.store?.offerPagination}
        title={() => (
          <Button onClick={() => setAdding(true)}>
            <PlusOutlined /> {t('buttons.addOffers')}
          </Button>
        )}
        action={(offer) => (
          <StoreRemoveOfferButton
            offer={offer}
            store={store}
            mode="iconAndText"
            onSuccess={handleRemoveSuccess}
          />
        )}
      />
    </div>
  );
};

export type StoreIdOffersViewProps = {
  className?: string;
  storeId: Store['id'];
};
