import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { UserRole } from '@graphql/generated/types';

import styles from './UserRoleTag.module.css';

import { UserRoleTagFragment } from './UserRoleTag.generated';

export const UserRoleTag: React.FC<UserRoleTagProps> = (props) => {
  const { className, user } = props;
  const { t } = useTranslation('users');

  const colors: Record<UserRole, string> = {
    [UserRole.Superadmin]: 'green',
    [UserRole.User]: 'blue',
  };

  const labels: Record<UserRole, string> = {
    [UserRole.User]: t('userRole.User'),
    [UserRole.Superadmin]: t('userRole.Superadmin'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[user.role]}
    >
      {labels[user.role]}
    </Tag>
  );
};

export type UserRoleTagProps = {
  className?: string;
  user: UserRoleTagFragment;
};
