import CampaignSelectionPublishCheckbox from '@components/organisms/CampaignSelectionPublishCheckbox/CampaignSelectionPublishCheckbox';

type Props = {
  campaignSelectionId: string;
};

const CampaignSelectionIdSettingsView = ({ campaignSelectionId }: Props) => {
  return (
    <div>
      <CampaignSelectionPublishCheckbox
        campaignSelectionId={campaignSelectionId}
      />
    </div>
  );
};

export default CampaignSelectionIdSettingsView;
