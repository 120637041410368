import classNames from 'classnames';

import { ProgressivePicture } from '@components/atoms/ProgressivePicture/ProgressivePicture';

import styles from './CategoryCard.module.css';

import { CategoryCardFragment } from './CategoryCard.generated';

export const CategoryCard = (props: CategoryCardProps) => {
  const { category, className } = props;

  const picture = category.icon;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header}>
        {picture && (
          <ProgressivePicture
            mode="cover"
            picture={picture}
            className={styles.picture}
          />
        )}
        <div className={styles.overlay} />
        <div className={styles.texts}>
          <div className={styles.name} title={category.name}>
            {category.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export type CategoryCardProps = {
  category: CategoryCardFragment;
  className?: string;
};
