import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { BrandTranslationForm } from '@forms/BrandTranslationForm/BrandTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './BrandIdTranslationsView.module.css';

import { useBrandIdTranslationsViewQuery } from './BrandIdTranslationsView.generated';

export const BrandIdTranslationsView: React.FC<BrandIdTranslationsViewProps> = (
  props,
) => {
  const { className, brandId } = props;
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
    undefined,
  );

  const localesQuery = useLocales();

  const query = useBrandIdTranslationsViewQuery({
    variables: { brandId },
    notifyOnNetworkStatusChange: true,
  });

  const brand = query?.data?.brand;
  const locales = localesQuery?.data?.localesPagination.nodes;

  if ((localesQuery.loading || query.loading) && !brand) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!brand) {
    return <NotFoundView />;
  } else if (!locales) {
    return <ServerErrorView />;
  }

  const { viewer } = useAuth();

  const userLanguage = viewer?.preferences?.properties.language || 'en';

  return (
    <div className={classnames(className, styles.root)}>
      <Tabs
        className={styles.tabs}
        tabPosition="left"
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        defaultActiveKey={userLanguage}
      >
        {brand?.translationsPagination.nodes.map((node) => {
          const locale = locales.find((locale) => locale.id === node.locale);

          return (
            <Tabs.TabPane
              tab={
                locale
                  ? `${locale.name} (${locale.id})${
                      node.isGenerated ? ` (générée)` : ''
                    }`
                  : node.locale
              }
              key={node.locale}
            >
              <BrandTranslationForm
                brandId={brand.id}
                translation={node}
                onDeleted={({ brand }) => setActiveTabKey(brand.locale)}
              />
            </Tabs.TabPane>
          );
        })}
        {brand.translationsPagination.totalCount < locales.length && (
          <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
            <BrandTranslationForm
              onCreated={({ brandTranslation }) =>
                setActiveTabKey(brandTranslation.locale)
              }
              localeFilter={{
                locale: {
                  exclude: brand.translationsPagination.nodes.map(
                    (node) => node.locale,
                  ),
                },
              }}
              brandId={brand.id}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export type BrandIdTranslationsViewProps = {
  className?: string;
  brandId: string;
};
