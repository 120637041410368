import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { SubventionState } from '@graphql/generated/types';

type SubventionStateTagProps = {
  subventionState: SubventionState;
};

export function SubventionStateTag(props: SubventionStateTagProps) {
  const { subventionState } = props;
  const { t } = useTranslation('subventions');

  const colors: Record<typeof subventionState, string> = {
    DRAFT: '',
    ABORTED: 'red',
    AWAITING_PROVISION: 'yellow',
    AWAITING_SCHEDULING: 'yellow',
    COMPLETED: 'grey',
    CONFIRMED: 'blue',
    READY: 'blue',
    PUBLISHED: 'green',
  };

  const labels: Record<typeof subventionState, string> = {
    DRAFT: t('subventionState.Draft'),
    ABORTED: t('subventionState.Aborted'),
    AWAITING_PROVISION: t('subventionState.AwaitingProvision'),
    AWAITING_SCHEDULING: t('subventionState.AwaitingScheduling'),
    COMPLETED: t('subventionState.Completed'),
    CONFIRMED: t('subventionState.Confirmed'),
    PUBLISHED: t('subventionState.Published'),
    READY: t('subventionState.Ready'),
  };

  return <Tag color={colors[subventionState]}>{labels[subventionState]}</Tag>;
}
