import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Menu } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';

export const AppHeaderDropdownMenu: React.FC<AppHeaderDropdownMenuProps> = (
  props,
) => {
  const { className } = props;
  const { logout } = useAuth();
  const { resetStore } = useApolloClient();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.log(err);
    }
    await resetStore();
  };

  return (
    <Menu className={classnames(className)}>
      {/* <Menu.Item key="0">
        <a href="https://www.antgroup.com">1st menu item</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="https://www.aliyun.com">2nd menu item</a>
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item onClick={handleLogout} danger key="3">
        {t('logout')}
      </Menu.Item>
    </Menu>
  );
};

export type AppHeaderDropdownMenuProps = {
  className?: string;
};
