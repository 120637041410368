import { Button } from 'antd';

import {
  useCompanyFeatureConfigConnectorSynchronisationTriggerButtonMutation,
  useCompanyFeatureConfigConnectorSynchronisationTriggerButtonSynchronisationQuery,
} from './CompanyFeatureConfigConnectorSynchronisationTriggerButton.generated';

interface CompanyFeatureConfigConnectorSynchronisationTriggerButtonProps {
  connectorId: string;
  companyId: string;
}

export const CompanyFeatureConfigConnectorSynchronisationTriggerButton = (
  props: CompanyFeatureConfigConnectorSynchronisationTriggerButtonProps,
) => {
  const { connectorId, companyId } = props;

  const query =
    useCompanyFeatureConfigConnectorSynchronisationTriggerButtonSynchronisationQuery(
      {
        variables: {
          companyId,
          connectorId,
        },
        onCompleted: (data) => {
          const lastSynchronisation =
            data?.company?.featureConfig.userIntegration.connector
              ?.lastSynchronisation.edges[0]?.node;

          if (!lastSynchronisation) return;

          if (!lastSynchronisation.endedAt) {
            query.startPolling(1000);
          } else {
            query.stopPolling();
          }
        },
      },
    );

  const [synchronisationMutation, synchronisationState] =
    useCompanyFeatureConfigConnectorSynchronisationTriggerButtonMutation();

  const connector =
    query.data?.company?.featureConfig.userIntegration.connector;
  const lastSynchronisation = connector?.lastSynchronisation.edges[0]?.node;

  const handleTrigger = async () => {
    await synchronisationMutation({
      variables: {
        companyId,
        connectorId,
      },
    });
    await query.refetch();
  };

  return (
    <Button
      loading={
        !connector ||
        (lastSynchronisation && !lastSynchronisation.endedAt) ||
        synchronisationState.loading
      }
      onClick={handleTrigger}
      type="primary"
    >
      Déclencher une synchronisation
    </Button>
  );
};
