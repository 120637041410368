import { useTranslation } from 'react-i18next';

import { FileType } from '@graphql/generated/types';

import {
  COVER_ASPECT_RATIO,
  COVER_RESOLUTION_INFO,
  COVER_SIZE_INFO,
} from '@utils/constants';

import { Typography } from '@atoms/Typography/Typography';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import styles from './CampaignSelectionHeader.module.css';

import {
  CampaignSelectionHeaderFragment,
  useCampaignSelectionHeaderUpdateMutation,
} from './CampaignSelectionHeader.generated';

export const CampaignSelectionHeader: React.FC<CampaignSelectionHeaderProps> = (
  props,
) => {
  const { className, campaignSelection } = props;
  const { t } = useTranslation();

  const [mutation] = useCampaignSelectionHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        campaignSelectionId: campaignSelection.id,
        input: {
          coverId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      className={className}
      cover={campaignSelection.cover}
      showNav
      uploadCoverProps={{
        fileType: FileType.CampaignCover,
        onUploadPicture: handleUpdate,
        title: 'Cover',
        picture: campaignSelection.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
          ns: 'common',
        }),
        aspect: COVER_ASPECT_RATIO,
        crop: true,
      }}
    >
      <Typography.Title
        level={2}
        ellipsis={{ rows: 2 }}
        className={styles.title}
      >
        {campaignSelection.name}
      </Typography.Title>
    </PageHeader>
  );
};

export type CampaignSelectionHeaderProps = {
  className?: string;
  campaignSelection: CampaignSelectionHeaderFragment;
};
