import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { InvitationStatus, UserStatus } from '@graphql/generated/types';

import { InvitationStatusTag } from '@components/atoms/InvitationsStatusTag/InvitationsStatusTag';
import { I18nLink } from '@components/atoms/Link/Link';
import { TableRendererDateTime } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ControllerChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  InvitationsTableRecordFragment,
  InvitationsTableUserFragment,
  useInvitationsTableInvitationRenewMutation,
} from './InvitationsTable.generated';

type RecordType = InvitationsTableRecordFragment;

type Column =
  | 'id'
  | 'claimedAt'
  | 'revokedAt'
  | 'createdAt'
  | 'expiredAt'
  | 'sendAt'
  | 'status'
  | 'sender'
  | 'receiver'
  | 'actions';

export function InvitationsTable(props: InvitationsTableProps) {
  const { query, data, user, ...rest } = props;

  const { t } = useTranslation('invitations');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('fields.id'),
      dataIndex: ['node', 'id'],
    },
    {
      key: 'sender',
      title: t('fields.author'),
      dataIndex: ['node', 'author'],
      render: (node) =>
        node && (
          <I18nLink to={`/users/list/${node.id}`}>
            {node.firstName} {node.lastName}
          </I18nLink>
        ),
    },
    {
      key: 'receiver',
      title: t('fields.receiver'),
      dataIndex: ['node', 'receiver'],
      render: (node) => (
        <I18nLink to={`/users/list/${node.id}`}>
          {node.firstName} {node.lastName}
        </I18nLink>
      ),
    },
    {
      key: 'claimedAt',
      title: t('fields.claimedAt'),
      dataIndex: ['node', 'claimedAt'],
      render: (claimedAt) => <TableRendererDateTime value={claimedAt} />,
    },
    {
      key: 'revokedAt',
      title: t('fields.revokedAt'),
      dataIndex: ['node', 'revokedAt'],
      render: (revokedAt) => <TableRendererDateTime value={revokedAt} />,
    },
    {
      key: 'expiresAt',
      title: t('fields.expiresAt'),
      dataIndex: ['node', 'expiresAt'],
      render: (expiresAt) => <TableRendererDateTime value={expiresAt} />,
    },
    {
      key: 'createdAt',
      title: t('fields.createdAt'),
      dataIndex: ['node', 'createdAt'],
      render: (createdAt) => <TableRendererDateTime value={createdAt} />,
    },
    {
      key: 'status',
      title: t('fields.status'),
      dataIndex: ['node', 'status'],
      render: (status: InvitationStatus) => (
        <InvitationStatusTag status={status} />
      ),
      ...(query
        ? generateColumnFilter(query, 'statuses', 'enum', [
            {
              value: InvitationStatus.Active,
              label: t('invitationsStatusTag.ACTIVE'),
            },
            {
              value: InvitationStatus.Revoked,
              label: t('invitationsStatusTag.REVOKED'),
            },
            {
              value: InvitationStatus.Claimed,
              label: t('invitationsStatusTag.CLAIMED'),
            },
            {
              value: InvitationStatus.Expired,
              label: t('invitationsStatusTag.EXPIRED'),
            },
          ])
        : {}),
    },
    {
      key: 'actions',
      title: t('fields.actions'),
      dataIndex: ['node'],
      render: (record, { node: invitation }) => (
        <Button
          disabled={invitation.status !== InvitationStatus.Active}
          onClick={() => handleCopyInvitationLink(invitation.invitationLink)}
        >
          Copier le lien
        </Button>
      ),
    },
  ];

  const [invitationRenew, { loading: invitationRenewLoading }] =
    useInvitationsTableInvitationRenewMutation({});

  const handleCopyInvitationLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    alert("Lien d'invitation copié dans le presse-papier");
  };

  const handleInvitationClick = async () => {
    if (!user) return;

    try {
      await invitationRenew({
        variables: {
          userId: user.id,
        },
      });

      await query.refetch();

      message.success("L'invitation a été envoyée");
    } catch (err) {
      message.error("Une erreur est survenue lors de l'envoie de l'invitation");
    }
  };

  return (
    <div>
      <TablePagination
        id="InvitationsTable"
        query={query}
        columns={columns}
        data={data}
        title={
          user
            ? () => (
                <div>
                  <Button
                    onClick={handleInvitationClick}
                    loading={invitationRenewLoading}
                    disabled={
                      ![UserStatus.Created, UserStatus.Invited].includes(
                        user.status,
                      )
                    }
                  >
                    {t(
                      'care.actions.renew_invitation.label',
                      'Envoyer une nouvelle invitation',
                    )}
                  </Button>
                </div>
              )
            : undefined
        }
        {...rest}
      />
    </div>
  );
}

export type InvitationsTableProps = ControllerChildTablePaginationProps<
  RecordType,
  Column
> & {
  user?: InvitationsTableUserFragment;
};
