import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message, Spin } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyDiscountCreateForm.module.css';

import {
  CompanyDiscountCreateFormMutation,
  useCompanyDiscountCreateFormMutation,
  useProductForCompanyDiscountCreateFormQuery,
} from './CompanyDiscountCreateForm.generated';

export type CompanyDiscountCreateFormValues = {
  companyIds: string[];
  percentOff?: number | null;
  amountOff?: number | null;
  startsAt?: Date | null;
  endsAt?: Date | null;
};

export function CompanyDiscountCreateForm(
  props: CompanyDiscountCreateFormProps,
) {
  const { className, productId, defaultValues, hideFooter, ...rest } = props;
  const { t } = useTranslation('discounts');

  const query = useProductForCompanyDiscountCreateFormQuery({
    variables: { productId },
    notifyOnNetworkStatusChange: true,
  });

  const validationSchema = Yup.object({
    companyIds: Yup.array().min(1).of(Yup.string().required()).required(),
    percentOff: Yup.number().min(0).nullable(),
    amountOff: Yup.number().min(0).nullable(),
    startsAt: Yup.date().min(new Date()).nullable(),
    endsAt: Yup.date().min(new Date()).nullable(),
  }).required();

  const form = useForm<CompanyDiscountCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [createMutation] = useCompanyDiscountCreateFormMutation();

  const product = query?.data?.product;

  if (query.loading && !product) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!product) {
    return <NotFoundView />;
  }

  if (!product.productVariantPagination?.totalCount) {
    return <div>Il faut créer au moins un variant avant une réduction</div>;
  }

  const variants = product.productVariantPagination?.nodes;
  const [variant] = variants;

  const handleSubmit: FormProps<CompanyDiscountCreateFormValues>['onValid'] =
    async (values) => {
      const creates = values.companyIds.map((companyId) => {
        return createMutation({
          variables: {
            productId,
            companyId,
            input: {
              percentOff: values.percentOff,
              amountOff: values.amountOff,
              startsAt: values.startsAt,
              endsAt: values.endsAt,
            },
          },
        });
      });

      try {
        const [{ data }] = await Promise.all(creates);

        return data;
      } catch (err) {
        if (err instanceof ApolloError)
          message.error(err.message.replace('GraphQL error: ', ''));
        else message.error('Une erreur est survenue');

        throw err;
      }
    };

  const { startsAt, endsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyDiscountCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.company}
          required
          label={t('fields.company')}
          name="companyIds"
        >
          <FormSelectCompany name="companyIds" multiple={true} />
        </FormItem>

        <FormItem
          className={styles.percentOff}
          required
          label={t('fields.percentOff')}
          name="percentOff"
        >
          <FormInputNumber name="percentOff" />
        </FormItem>

        {variant &&
          (variant.__typename === 'ProductVariantPriceFixed' ||
            variant.__typename === 'ProductVariantPriceDynamic') && (
            <FormItem
              className={styles.amountOff}
              required
              label={t('fields.amountOff')}
              name="amountOff"
            >
              <FormInputNumber name="amountOff" />
            </FormItem>
          )}

        <FormItem
          className={styles.startsAt}
          label={t('fields.startsAt')}
          name="startsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => (endsAt ? date.isAfter(endsAt) : false)}
            name="startsAt"
          />
        </FormItem>
        <FormItem
          className={styles.endsAt}
          label={t('fields.endsAt')}
          name="endsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) =>
              startsAt ? date.isBefore(startsAt) : false
            }
            name="endsAt"
          />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CompanyDiscountCreateFormProps = ChildFormProps<
  CompanyDiscountCreateFormValues,
  CompanyDiscountCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<CompanyDiscountCreateFormValues>;
  hideFooter?: boolean;
  productId: string;
};
