import * as CSV from 'csv-string';
import { filter, get, includes } from 'lodash';
import slugify from 'slugify';
import isEmail from 'validator/es/lib/isEmail';

import { CompanyMembershipRole } from '@graphql/generated/types';

const CELLS = {
  slug: 0,
  company: 1,
  email: 2,
  firstName: 3,
  lastName: 4,
  role: 5,
};

const roles = {
  ADMIN: CompanyMembershipRole.Admin,
  MEMBER: CompanyMembershipRole.Member,
};

export function parseSubsidiaryCsv(content: string) {
  const companies: Record<
    string,
    {
      slug: string;
      name: string;
      users: {
        firstName: string;
        lastName: string;
        email: string;
        role: CompanyMembershipRole;
      }[];
    }
  > = {};

  const rows = CSV.parse(content);

  rows.forEach((cells, i) => {
    const companySlug = cells[CELLS.slug].trim();

    if (!companySlug) {
      throw new Error(`Le slug de la société est obligatoire ligne ${i + 1}`);
    }

    if (!companies[companySlug]) {
      companies[companySlug] = {
        slug: companySlug,
        name: cells[CELLS.company].trim(),
        users: [],
      };
    }

    const email = cells[CELLS.email]?.trim();
    const firstName = cells[CELLS.firstName]?.trim() || '';
    const lastName = cells[CELLS.lastName]?.trim() || '';
    const role = cells[CELLS.role]?.trim().toUpperCase();

    if (!isEmail(email || '')) {
      console.log(email, cells, cells[CELLS.email]);
      throw new Error(`L'email est obligatoire ligne ${i + 1}`);
    }

    if (!firstName) {
      throw new Error(`Le prénom est obligatoire ligne ${i + 1}`);
    }

    if (!lastName) {
      throw new Error(`Le nom est obligatoire ligne ${i + 1}`);
    }

    companies[companySlug].users.push({
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      role: get(roles, role, CompanyMembershipRole.Member),
    });
  });

  const emails = Object.values(companies)
    .map((company) => company.users.map((user) => user.email))
    .flat();

  const duplicates = filter(emails, (val, i, iteratee) =>
    includes(iteratee, val, i + 1),
  );

  if (duplicates.length) {
    throw new Error(
      `Le fichier contient des doublons d'email :\n${duplicates.join('\n')}`,
    );
  }

  Object.values(companies).map((company) => {
    if (
      company.slug !==
      slugify(company.slug, {
        lower: true,
        strict: true,
        trim: true,
      })
    ) {
      throw new Error(`Le slug ${company.slug} n'est pas valide`);
    }
  });

  return Object.values(companies);
}
