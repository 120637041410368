import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  MangopayKycDocumentStatus,
  MangopayKycDocumentType,
  MangopayUser,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import {
  MangopayKycDocumentRecordFragment,
  MangopayKycDocumentsTableQueryVariables,
  useMangopayKycDocumentsTableQuery,
} from './MangopayKycDocumentsTable.generated';

type RecordType = MangopayKycDocumentRecordFragment;

type Column =
  | 'id'
  | 'mangopayId'
  | 'type'
  | 'status'
  | 'processedAt'
  | 'mangopayUserType'
  | 'mangopayUser';

const StatusColors: Record<MangopayKycDocumentStatus, string> = {
  CREATED: 'default',
  VALIDATED: 'green',
  REFUSED: 'red',
  OUT_OF_DATE: 'red',
  VALIDATION_ASKED: 'blue',
};

export type KycDocumentsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: MangopayKycDocumentsTableQueryVariables['sorter'];
  filter?: MangopayKycDocumentsTableQueryVariables['filter'];
};

export function MangopayKycDocumentsTable(props: KycDocumentsTableProps) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    sorter,
    ...rest
  } = props;

  const { t } = useTranslation('mangopayKycDocument');

  const query =
    propQuery ||
    useMangopayKycDocumentsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
        sorter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'mangopayId',
      title: t('table.headers.mangopayId'),
      dataIndex: ['mangopayId'],
    },
    {
      key: 'type',
      title: t('table.headers.type'),
      dataIndex: ['type'],
      render: (type) => <Tag>{type}</Tag>,
      ...generateColumnFilter(query, 'type', 'enum', [
        {
          label: t('type.addressProof'),
          value: MangopayKycDocumentType.AddressProof,
        },
        {
          label: t('type.articlesOfAssociation'),
          value: MangopayKycDocumentType.ArticlesOfAssociation,
        },
        {
          label: t('type.identityProof'),
          value: MangopayKycDocumentType.IdentityProof,
        },
        {
          label: t('type.registrationProof'),
          value: MangopayKycDocumentType.RegistrationProof,
        },
        {
          label: t('type.shareholderDeclaration'),
          value: MangopayKycDocumentType.ShareholderDeclaration,
        },
      ]),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      render: (status: MangopayKycDocumentStatus) => (
        <Tag color={StatusColors[status]}>{status}</Tag>
      ),
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('status.created'),
          value: MangopayKycDocumentStatus.Created,
        },
        {
          label: t('status.outOfDate'),
          value: MangopayKycDocumentStatus.OutOfDate,
        },
        {
          label: t('status.refused'),
          value: MangopayKycDocumentStatus.Refused,
        },
        {
          label: t('status.validated'),
          value: MangopayKycDocumentStatus.Validated,
        },
        {
          label: t('status.validationAsked'),
          value: MangopayKycDocumentStatus.ValidationAsked,
        },
      ]),
    },
    {
      key: 'processedAt',
      title: t('table.headers.processedAt'),
      dataIndex: ['processedAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'refusedReasonMessage',
      title: t('table.headers.refusedReasonMessage'),
      dataIndex: ['refusedReasonMessage'],
    },
    {
      key: 'mangopayUserType',
      title: t('table.headers.mangopayUserType'),
      dataIndex: ['mangopayUser'],
      render: (mangopayUser: MangopayUser) =>
        mangopayUser ? <Tag>{mangopayUser.type}</Tag> : '',
    },
    {
      key: 'mangopayUser',
      title: t('table.headers.mangopayUser'),
      dataIndex: ['mangopayUser'],
      render: (mangopayUser: MangopayUser) => {
        if (!mangopayUser || !mangopayUser.owner) {
          return '';
        }

        switch (mangopayUser.owner.__typename) {
          case 'Company':
            return (
              <I18nLink to={`/companies/list/${mangopayUser.owner.id}`}>
                {mangopayUser.owner.name}
              </I18nLink>
            );
          case 'User':
            return (
              <I18nLink to={`/users/list/${mangopayUser.owner.id}`}>
                {mangopayUser.owner.email}
              </I18nLink>
            );
        }
      },
    },
  ];

  return (
    <TablePagination
      id="MangopayKycDocumentsTable"
      query={query}
      columns={columns}
      data={propData || query.data?.mangopayKycDocumentPagination}
      {...rest}
    />
  );
}
