import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { CompanyExcludeEntityForm } from '@components/forms/CompanyExcludeEntityForm/CompanyExcludeEntityForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './CompanyExcludeEntityModal.module.css';

export type CompanyExcludeEntityModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  entityId: string;
  entityType: 'article';
};

export function CompanyExcludeEntityModal(
  props: CompanyExcludeEntityModalProps,
) {
  const { className, onClose, onSuccess, isOpen, entityId, entityType } = props;

  const { t } = useTranslation('companies');

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };
  let modalName = '';

  switch (entityType) {
    case 'article':
      modalName = t('CompanyExcludeEntityForm.modal.article.name');
      break;
    default:
      modalName = t('CompanyExcludeEntityForm.modal.default');
      break;
  }

  return (
    <Modal
      title={modalName}
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="CompanyExcludeEntityForm"
    >
      <CompanyExcludeEntityForm
        onSuccess={handleSuccess}
        entityId={entityId}
        entityType={entityType}
      />
    </Modal>
  );
}
