import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';

import styles from './FilterDropdownDate.module.css';

// FIXME: Refactor
export function FilterDropdownDate(props: FilterDropdownDateProps) {
  const { onChange, showTime } = props;

  const getFormatedDate = (e: moment.Moment | null) => {
    if (e) {
      return e.toDate();
    }
    return undefined;
  };
  const handleChangeMin: DatePickerProps['onChange'] = (e) => {
    const date = getFormatedDate(e);
    onChange({
      gte: date,
    });
  };

  const handleChangeMax: DatePickerProps['onChange'] = (e) => {
    const date = getFormatedDate(e);
    onChange({
      lte: date,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.labelGroup}>
        <p className={styles.label}>Min</p>
        <DatePicker
          onChange={handleChangeMin}
          showTime={showTime}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
        />
      </div>
      <div className={styles.labelGroup}>
        <p className={styles.label}>Max</p>
        <DatePicker onChange={handleChangeMax} />
      </div>
    </div>
  );
}

export type FilterDropdownDateProps = {
  onChange: ({ gte, lte }: { gte?: Date; lte?: Date }) => void;
  showTime?: boolean;
};
