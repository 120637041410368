import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { OfferType } from '@graphql/generated/types';

import styles from './FormSelectOfferType.module.css';

export const FormSelectOfferType: React.FC<FormSelectOfferTypeProps> = (
  props,
) => {
  const { className, name } = props;
  const { t } = useTranslation('offers');

  const options: { label: string; value: OfferType }[] = [
    { value: OfferType.Url, label: t('type.url') },
    { value: OfferType.CouponPublic, label: t('type.couponPublic') },
    { value: OfferType.CouponClaimable, label: t('type.couponClaimable') },
    { value: OfferType.GiftCard, label: t('type.giftCard') },
    { value: OfferType.Ticket, label: t('type.ticket') },
    { value: OfferType.Base, label: t('type.base', 'Interne') },
  ];

  return (
    <FormSelect
      name={name}
      className={classnames(className, styles.root)}
      options={options}
    />
  );
};

export type FormSelectOfferTypeProps = {
  name: string;
  className?: string;
};
