import classnames from 'classnames';

import {
  QuestionCategoryUpdateForm,
  QuestionCategoryUpdateFormProps,
} from '@components/forms/QuestionCategoryUpdateForm/QuestionCategoryUpdateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './QuestionCategoryUpdateModal.module.css';

export type QuestionCategoryUpdateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  subprops: QuestionCategoryUpdateFormProps;
};

export function QuestionCategoryUpdateModal(
  props: QuestionCategoryUpdateModalProps,
) {
  const { className, onClose, onSuccess, isOpen, subprops } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="QuestionCategoryUpdateModal"
      width="fit-content"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="QuestionCategoryUpdateForm"
    >
      <QuestionCategoryUpdateForm onSuccess={handleSuccess} {...subprops} />
    </Modal>
  );
}
