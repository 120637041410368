import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm, Typography } from 'antd';
import classnames from 'classnames';

import { ProductVariantsTable } from '@components/tables/ProductVariantsTable/ProductVariantsTable';
import { ProductVariantsTableRecordFragment } from '@components/tables/ProductVariantsTable/ProductVariantsTable.generated';

import styles from './ProductVariantsView.module.css';

import { useProductVariantStockUpdateMutation } from './ProductVariantsView.generated';

export const ProductVariantsView: React.FC<ProductVariantsViewProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation('productVariants');

  const [updateStock] = useProductVariantStockUpdateMutation();

  const productVariantStockUpdate =
    (record: ProductVariantsTableRecordFragment) => () => {
      try {
        updateStock({
          variables: {
            productVariantId: record.id,
            input: {
              quantity: -1,
            },
          },
        });
      } catch (err) {
        if (err instanceof ApolloError)
          message.error(err.message.replace('GraphQL error: ', ''));
        else message.error('Une erreur est survenue');
      }
    };

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('productVariantsView.title')}</Typography.Title>
      <ProductVariantsTable
        activeColumns={[
          'product',
          'SKU',
          'priceWithTaxes',
          'inventoryQuantity',
        ]}
        action={(record) => {
          if (record.inventoryQuantity === 0) {
            return (
              <Popconfirm
                title={t(
                  'productVariantsView.action.putBackStock.confirmation',
                  'Est ce que vous voulez vraiment remettre du stock ?',
                )}
                okText={t('common:BooleanTag.true')}
                cancelText={t('common:BooleanTag.false')}
                onConfirm={productVariantStockUpdate(record)}
              >
                <Button icon={<ReloadOutlined />}>
                  {t(
                    'productVariantsView.action.putBackStock.button',
                    'Remettre du stock',
                  )}
                </Button>
              </Popconfirm>
            );
          }
        }}
      />
    </div>
  );
};

export type ProductVariantsViewProps = {
  className?: string;
};
