import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './SearchSynchronizationTypeTag.module.css';

import { SearchSynchronizationTypeTagFragment } from './SearchSynchronizationTypeTag.generated';

export const SearchSynchronizationTypeTag: React.FC<SearchSynchronizationTypeTagProps> =
  (props) => {
    const { className, searchSynchronization } = props;
    const { t } = useTranslation('searchSynchronizations');

    const colors: Record<typeof searchSynchronization.type, string> = {
      CATEGORY: 'yellow',
      CAMPAIGN: 'yellow',
      CAMPAIGN_SELECTION: 'yellow',
    };

    const labels: Record<typeof searchSynchronization.type, string> = {
      CATEGORY: t('type.category'),
      CAMPAIGN: t('type.campaign'),
      CAMPAIGN_SELECTION: t('type.campaignSelection'),
    };

    return (
      <Tag
        className={classnames(className, styles.root)}
        color={colors[searchSynchronization.type]}
      >
        {labels[searchSynchronization.type]}
      </Tag>
    );
  };

export type SearchSynchronizationTypeTagProps = {
  className?: string;
  searchSynchronization: SearchSynchronizationTypeTagFragment;
};
