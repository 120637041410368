import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { MangopayKycDocumentWithPagesCreateForm } from '@components/forms/MangopayKycDocumentCreateForm/MangopayKycDocumentWithPagesCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './MangopayKycDocumentCreateModal.module.css';

export type MangopayKycDocumentCreateModalProps = {
  mangopayLegalUserId: string;
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
};

export function MangopayKycDocumentCreateModal(
  props: MangopayKycDocumentCreateModalProps,
) {
  const { mangopayLegalUserId, className, onClose, onSuccess, isOpen } = props;

  const { t } = useTranslation('mangopayKycDocument');

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess = async () => {
    await onSuccess?.();
    handleClose();
  };

  return (
    <Modal
      title={t('mangopayKycDocument.modal.title')}
      onClose={handleClose}
      isOpen={!!isOpen}
      className={classnames(className, styles.root)}
      form="MangopayKycDocumentWithPagesCreateForm"
    >
      <MangopayKycDocumentWithPagesCreateForm
        mangopayLegalUserId={mangopayLegalUserId}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
}
