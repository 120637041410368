import { encode } from 'blurhash';

const MAX_COMPONENTS = 6;
const MIN_COMPONENTS = 2;

export function extractImageComponents(width: number, height: number) {
  const componentX = 4;
  const componentY = Math.min(
    MAX_COMPONENTS,
    Math.max(MIN_COMPONENTS, Math.round(componentX * (height / width))),
  );

  return { componentX, componentY };
}

export async function convertImageToHash(image: HTMLImageElement) {
  const { componentX, componentY } = extractImageComponents(
    image.width,
    image.height,
  );
  const imageData = getImageData(image, componentX, componentY);

  return encode(
    imageData.data,
    imageData.width,
    imageData.height,
    componentX,
    componentY,
  );
}

const getImageData = (
  image: HTMLImageElement,
  width: number,
  height: number,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d')!;
  context.drawImage(image, 0, 0, width * 8, height * 8);

  return context.getImageData(0, 0, width * 8, height * 8);
};
