import classnames from 'classnames';

import {
  BrandMergeDuplicateForm,
  BrandMergeDuplicateFormProps,
  BrandMergeDuplicateFormValues,
} from '@components/forms/BrandMergeDuplicateForm/BrandMergeDuplicateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './BrandMergeDuplicateModal.module.css';

export type BrandMergeDuplicateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: BrandMergeDuplicateFormProps['onSuccess'];
  isOpen?: boolean;
  defaultValues?: Partial<BrandMergeDuplicateFormValues>;
};

export function BrandMergeDuplicateModal(props: BrandMergeDuplicateModalProps) {
  const { className, onClose, onSuccess, isOpen, defaultValues } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: BrandMergeDuplicateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="BrandMergeDuplicateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="BrandMergeDuplicateForm"
    >
      <BrandMergeDuplicateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        hideFooter={true}
      />
    </Modal>
  );
}
