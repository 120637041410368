import { DeepPartial } from 'react-hook-form';
import { sumBy } from 'lodash';

import { OrderRefundCreateFormValue } from '../OrderRefundCreateForm';
import {
  OrderRefundCreateFormOrderItemFragment,
  OrderRefundCreateFormSubventionConsumptionFragment,
} from '../OrderRefundCreateForm.generated';

export function getRefundableSubventions(
  orderItems: OrderRefundCreateFormOrderItemFragment[],
  consumptions: OrderRefundCreateFormSubventionConsumptionFragment[],
  items?: DeepPartial<OrderRefundCreateFormValue['items']>,
) {
  const refundableSubventions: Record<
    string,
    {
      max: number;
      eligibleAmount: number;
      name: string;
    }
  > = {};

  items?.forEach((item) => {
    const orderItem = orderItems.find(
      (orderItem) => orderItem.id === item?.orderItemId,
    );

    if (!orderItem) return;

    const applicableSubventions = orderItem.subventionPagination.nodes;

    applicableSubventions.forEach((subvention) => {
      const subventionConsumptions = consumptions.filter(
        (consumption) => consumption.subvention.id === subvention.id,
      );

      if (!subventionConsumptions.length) return;
      if (!refundableSubventions[subvention.id]) {
        refundableSubventions[subvention.id] = {
          max: sumBy(
            subventionConsumptions,
            (subventionConsumption) => subventionConsumption.amount,
          ),
          eligibleAmount: 0,
          name: subvention.name,
        };
      }

      refundableSubventions[subvention.id].eligibleAmount +=
        (item?.quantity || 0) * orderItem.unitPriceAfterTaxes;
    });
  });

  return Object.entries(refundableSubventions).map(
    ([subventionId, content]) => ({
      id: subventionId,
      ...content,
    }),
  );
}
