import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CouponClaimableUpdateForm.module.css';

import {
  CouponClaimableUpdateFormFragment,
  CouponClaimableUpdateFormMutation,
  CouponClaimableUpdateFormMutationVariables,
  useCouponClaimableUpdateFormMutation,
} from './CouponClaimableUpdateForm.generated';

export type CouponClaimableUpdateFormValues = {
  value?: string;
  claimedAt?: Date | null;
  expiresAt?: Date | null;
};

export function CouponClaimableUpdateForm(
  props: CouponClaimableUpdateFormProps,
) {
  const { className, hideFooter, couponClaimable, ...rest } = props;
  const { t } = useTranslation('couponClaimables');

  const validationSchema = Yup.object({
    expiresAt: Yup.date().nullable(),
    // Only used for the condition below
    claimedAt: Yup.date().optional().nullable(),
    value: Yup.string().when('claimedAt', {
      is: (claimedAt: Date | undefined) => !!claimedAt,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(),
    }),
  }).required();

  const form = useForm<CouponClaimableUpdateFormValues>({
    validationSchema,
    defaultValues: {
      claimedAt: couponClaimable.claimedAt,
      value: couponClaimable.value,
      expiresAt: couponClaimable.expiresAt,
    },
  });

  const [mutation] = useCouponClaimableUpdateFormMutation();

  const handleSubmit: FormProps<CouponClaimableUpdateFormValues>['onValid'] =
    async (values) => {
      const variables: CouponClaimableUpdateFormMutationVariables = {
        couponClaimableId: couponClaimable.id,
        input: {
          value: values.value ? values.value.trim() : undefined,
          expiresAt: values.expiresAt ?? null,
        },
      };

      try {
        const { data } = await mutation({ variables });

        message.success(t('update.success'));
        return data;
      } catch (err) {
        if (err instanceof ApolloError) {
          message.error(err.message.replace('GraphQL error: ', ''));
        } else {
          message.error('error:default');
        }
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CouponClaimableUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        {!couponClaimable.claimedAt && (
          <FormItem
            className={styles.value}
            required
            label={t('fields.value')}
            name="value"
          >
            <FormInput name="value" />
          </FormItem>
        )}

        <FormItem
          className={styles.expiresAt}
          label={t('fields.expiresAt')}
          name="expiresAt"
        >
          <FormInputDate name="expiresAt" showTime={true} allowClear={true} />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.submit.update')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CouponClaimableUpdateFormProps = ChildFormProps<
  CouponClaimableUpdateFormValues,
  CouponClaimableUpdateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
  couponClaimable: CouponClaimableUpdateFormFragment;
};
