import { Typography as AntTypography } from 'antd';
import { LinkProps as AntLinkProps } from 'antd/lib/typography/Link';
import { ParagraphProps as AntParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps as AntTextProps } from 'antd/lib/typography/Text';
import { TitleProps as AntTitleProps } from 'antd/lib/typography/Title';
import { TypographyProps as AntTypographyProps } from 'antd/lib/typography/Typography';
import classnames from 'classnames';

import styles from './Typography.module.css';

const Text = (props: TypographyProps & TextProps) => {
  const { className, ...rest } = props;

  return (
    <AntTypography.Text
      className={classnames(className, styles.root, styles.text)}
      {...rest}
    />
  );
};

const Title = (props: TypographyProps & TitleProps) => {
  const { className, ...rest } = props;

  return (
    <AntTypography.Title
      className={classnames(className, styles.root, styles.text)}
      {...rest}
    />
  );
};

const Paragraph = (props: TypographyProps & ParagraphProps) => {
  const { className, ...rest } = props;

  return (
    <AntTypography.Paragraph
      className={classnames(className, styles.root, styles.text)}
      {...rest}
    />
  );
};

const Link = (props: TypographyProps & LinkProps) => {
  const { className, ...rest } = props;

  return (
    <AntTypography.Link
      className={classnames(className, styles.root, styles.text)}
      {...rest}
    />
  );
};

export const Typography = {
  Text,
  Title,
  Paragraph,
  Link,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypographyProps = AntTypographyProps<any> & {
  className?: string;
};

export type TextProps = TypographyProps & AntTextProps;
export type TitleProps = TypographyProps & AntTitleProps;
export type ParagraphProps = TypographyProps & AntParagraphProps;
export type LinkProps = TypographyProps & AntLinkProps;
