import React from 'react';
import { Button, Modal } from 'antd';

type Props = {
  onClose: () => void;
  error?: string;
};

const ErrorModal = ({ error, onClose }: Props) => {
  return (
    <Modal
      title="Erreur"
      onCancel={onClose}
      visible={!!error}
      footer={
        <Button type="primary" onClick={onClose}>
          Fermer
        </Button>
      }
    >
      Une erreur est survenue : {error}
    </Modal>
  );
};

export default ErrorModal;
