import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import classnames from 'classnames';

import styles from './StoreDeleteButton.module.css';

import {
  StoreDeleteButtonFragment,
  StoreDeleteButtonMutationResult,
  useStoreDeleteButtonMutation,
} from './StoreDeleteButton.generated';

export const StoreDeleteButton: React.FC<StoreDeleteButtonProps> = (props) => {
  const { className, store, mode, onSuccess: onSucccess } = props;
  const { t } = useTranslation('stores');

  const [isSubmitting, setSubmitting] = useState(false);

  const [mutation] = useStoreDeleteButtonMutation();

  const handleDelete = async () => {
    if (isSubmitting) return;

    setSubmitting(true);

    try {
      const { data } = await mutation({
        variables: {
          storeId: store.id,
        },
      });

      message.success(
        t('StoreDeleteButton.success', {
          store: store.address.formattedAddress,
        }),
      );

      if (onSucccess) {
        onSucccess(data);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
    setSubmitting(false);
  };

  const renderButton = () => {
    switch (mode) {
      case 'icon':
        return <DeleteOutlined />;
      case 'text':
        return t('StoreDeleteButton.delete.label');
      case 'iconAndText':
        return (
          <>
            <DeleteOutlined /> {t('StoreDeleteButton.delete.label')}
          </>
        );
    }
  };

  return (
    <Popconfirm
      onConfirm={handleDelete}
      title={t('StoreDeleteButton.delete.confirm', {
        store: store.address.formattedAddress,
      })}
    >
      <Button
        className={classnames(className, styles.root)}
        loading={isSubmitting}
        danger
      >
        {renderButton()}
      </Button>
    </Popconfirm>
  );
};

export type StoreDeleteButtonProps = {
  className?: string;
  store: StoreDeleteButtonFragment;
  mode: 'icon' | 'text' | 'iconAndText';
  onSuccess?: (data: StoreDeleteButtonMutationResult['data']) => void;
};
