import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { I18nLink } from '@components/atoms/Link/Link';
import { PictureStockCreateModal } from '@components/modals/PictureStockCreateModal/PictureStockCreateModal';
import { Picture } from '@components/molecules/Picture/Picture';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import {
  PictureStockRecordFragment,
  usePictureStockTableQuery,
} from './PicturesStockTable.generated';

type RecordType = PictureStockRecordFragment;

type Column = 'picture' | 'height' | 'width';

export type PictureStockTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
};

export function PicturesStockTable(props: PictureStockTableProps) {
  const { className, ...rest } = props;

  const { t } = useTranslation('picturesStock');

  const query = usePictureStockTableQuery({
    variables: {
      skip: 0,
      take: 10,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'picture',
      title: 'picture',
      dataIndex: ['picture'],
      width: 64,
      render: (picture) => <Picture width={60} height={30} picture={picture} />,
    },
    {
      key: 'id',
      title: 'id',
      dataIndex: ['id'],
      render: (name, node) => (
        <I18nLink to={`/parameters/picture-stock/list/${node.id}`}>
          {name}
        </I18nLink>
      ),
    },
  ];

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <TablePagination
        id="PictureStockTable"
        query={query}
        columns={columns}
        data={query.data?.pictureStockPagination}
        title={() => (
          <Button type="primary" onClick={() => setOpen(true)}>
            {t('picturesStock.create.title')}
          </Button>
        )}
        {...rest}
      />
      <PictureStockCreateModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
      />
    </>
  );
}
