import { Tag, Tooltip } from 'antd';
import classnames from 'classnames';

import styles from './MangopayTransferStatusTag.module.css';

import { MangopayTransferStatusTagFragment } from './MangopayTransferStatusTag.generated';

export const MangopayTransferStatusTag: React.FC<MangopayTransferStatusTagProps> =
  (props) => {
    const { className, MangopayTransfer } = props;

    const colors: Record<typeof MangopayTransfer.status, string> = {
      CREATED: 'grey',
      FAILED: 'red',
      SUCCEEDED: 'green',
    };

    return (
      <div key={MangopayTransfer.id}>
        <Tooltip placement="topLeft" title={MangopayTransfer.mangopayId}>
          <Tag
            className={classnames(className, styles.root)}
            color={colors[MangopayTransfer.status]}
          >
            {MangopayTransfer.mangopayId}
          </Tag>
        </Tooltip>
      </div>
    );
  };

export type MangopayTransferStatusTagProps = {
  className?: string;
  MangopayTransfer: MangopayTransferStatusTagFragment;
};
