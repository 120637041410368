import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { SubventionReimbursementRequestStatus } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionReimbursementStatusTag } from '@components/atoms/SubventionReimbursementStatusTag/SubventionReimbursementStatusTag';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import {
  TableRendererDateTime,
  TableRendererDateTimeFactory,
} from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ControllerChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import {
  SubventionReimbursementProofRecordFragment,
  SubventionReimbursementsTableRecordFragment,
} from './SubventionReimbursementsTable.generated';

type RecordType = SubventionReimbursementsTableRecordFragment;

type Column =
  | 'subvention'
  | 'user'
  | 'totalAmount'
  | 'reimbursementAmount'
  | 'invoiceDate'
  | 'company'
  | 'processedAt'
  | 'proofs'
  | 'customMerchant'
  | 'description'
  | 'status'
  | 'rejectedReason';

export const SubventionReimbursementsTable = (
  props: SubventionReimbursementsTableProps,
) => {
  const { data, query, ...rest } = props;

  const { t } = useTranslation('subventionReimbursements');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id', 'id'),
      dataIndex: ['id'],
      ellipsis: true,
      render: (id) => (
        <I18nLink to={`/subventions/reimbursement/${id}`}>{id}</I18nLink>
      ),
    },
    {
      title: t('table.headers.createdAt'),
      dataIndex: ['createdAt'],
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      render: (createdAt) => <TableRendererDateTime value={createdAt} />,
      ...generateColumnFilter(query, 'createdAt', 'datetime'),
    },
    {
      key: 'subvention',
      title: t('table.headers.subvention'),
      dataIndex: ['subvention'],
      render: (subvention) => (
        <I18nLink to={`/subventions/subventions/${subvention.id}`}>
          {subvention.name}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'subventions.name', 'search'),
    },
    {
      key: 'user',
      title: t('table.headers.user'),
      dataIndex: ['user'],
      render: (user) => (
        <I18nLink to={`/users/list/${user.id}`}>
          {user.firstName} {user.lastName}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'user.fullName', 'search'),
    },

    {
      key: 'email',
      title: t('table.headers.email'),
      dataIndex: ['user', 'email'],
      ellipsis: true,
      render: (email, { user }) => (
        <I18nLink copyable ellipsis={true} to={`/users/list/${user.id}`}>
          {email}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'user.email', 'search'),
    },
    {
      key: 'totalAmount',
      title: t('table.headers.totalAmount'),
      render: (_, { totalAmount, currency }) => (
        <TableRendererCurrency
          value={{
            amount: totalAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'reimbursementAmount',
      title: t('table.headers.reimbursementAmount'),
      render: (_, { reimbursementAmount, currency }) => (
        <TableRendererCurrency
          value={{
            amount: reimbursementAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'invoiceDate',
      title: t('table.headers.invoiceDate'),
      dataIndex: ['invoiceDate'],
      ellipsis: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'company',
      title: t('table.headers.company'),
      render: (_, { user: { company } }) => {
        if (company)
          return (
            <I18nLink to={`/companies/list/${company.id}`}>
              {company.name}
            </I18nLink>
          );
      },
      ...generateColumnFilter(
        query,
        'user.companyMembership.company.name',
        'search',
      ),
    },
    {
      key: 'processedAt',
      title: t('table.headers.processedAt'),
      dataIndex: ['processedAt'],
      ellipsis: true,
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'proofs',
      title: t('table.headers.proofs'),
      dataIndex: ['proofs'],
      ellipsis: true,
      render: (_, { proofs }) =>
        proofs.nodes.map((node: SubventionReimbursementProofRecordFragment) => (
          <Tooltip key={node.id} title={node.originalName} placement="topLeft">
            <a key={node.id} href={node.url} target="_blank" rel="noreferrer">
              {node.originalName}
            </a>
          </Tooltip>
        )),
    },
    {
      key: 'customMerchant',
      title: t('table.headers.customMerchant'),
      dataIndex: ['customMerchant'],
      render: (customMerchant) => `${customMerchant}`,
    },
    {
      key: 'description',
      title: t('table.headers.description'),
      dataIndex: ['description'],
      ellipsis: true,
      render: (description) => `${description}`,
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      render: (_, subventionReimbursementRequest) => (
        <SubventionReimbursementStatusTag
          subventionReimbursementRequest={subventionReimbursementRequest}
        />
      ),
      ...generateColumnFilter(query, 'status', 'enum', [
        {
          label: t('ReimbursementRequestStatus.Approved'),
          value: SubventionReimbursementRequestStatus.Approved,
        },
        {
          label: t('ReimbursementRequestStatus.Cancelled'),
          value: SubventionReimbursementRequestStatus.Cancelled,
        },
        {
          label: t('ReimbursementRequestStatus.PayOutCreated'),
          value: SubventionReimbursementRequestStatus.PayOutCreated,
        },
        {
          label: t('ReimbursementRequestStatus.PayOutFailed'),
          value: SubventionReimbursementRequestStatus.PayOutFailed,
        },
        {
          label: t('ReimbursementRequestStatus.PayOutSucceeded'),
          value: SubventionReimbursementRequestStatus.PayOutSucceeded,
        },
        {
          label: t('ReimbursementRequestStatus.Rejected'),
          value: SubventionReimbursementRequestStatus.Rejected,
        },
        {
          label: t('ReimbursementRequestStatus.WaitingForApproval'),
          value: SubventionReimbursementRequestStatus.WaitingForApproval,
        },
      ]),
    },
    {
      key: 'rejectedReason',
      title: t('table.headers.rejectedReason'),
      dataIndex: ['rejectedReason'],
      render: (rejectedReason) => `${rejectedReason}`,
    },
  ];

  return (
    <div>
      <TablePagination
        id="SubventionReimbursementsTable"
        query={query}
        data={data}
        columns={columns}
        {...rest}
      />
    </div>
  );
};

export type SubventionReimbursementsTableProps =
  ControllerChildTablePaginationProps<RecordType, Column>;
