import React from 'react';
import classnames from 'classnames';

import { StripePaymentIntentsTable } from '@components/tables/StripePaymentIntentsTable/StripePaymentIntentsTable';

import styles from './StripePaymentIntentsView.module.css';

export const StripePaymentIntentsView: React.FC<StripePaymentIntentsViewProps> =
  (props) => {
    const { className } = props;

    return (
      <div className={classnames(className, styles.root)}>
        <StripePaymentIntentsTable
          activeColumns={[
            'id',
            'createdAt',
            'updatedAt',
            'stripeUrl',
            'user',
            'currency',
            'amount',
            'amountReceived',
            'status',
          ]}
        />
      </div>
    );
  };

export type StripePaymentIntentsViewProps = {
  className?: string;
};
