import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Spin } from 'antd';
import classnames from 'classnames';

import { VoucherFlatDiscountUpdateForm } from '@components/forms/VoucherUpdateForm/VoucherFlatDiscountUpdateForm/VoucherFlatDiscountUpdateForm';
import { VoucherPercentDiscountUpdateForm } from '@components/forms/VoucherUpdateForm/VoucherFlatDiscountUpdateForm/VoucherPercentDiscountUpdateForm';
import { VoucherRewardUpdateForm } from '@components/forms/VoucherUpdateForm/VoucherFlatDiscountUpdateForm/VoucherRewardUpdateForm';
import { OfferVoucherCreateModal } from '@components/modals/OfferVoucherCreateModal/OfferVoucherCreateModal';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdVoucherView.module.css';

import {
  OfferIdVoucherDeleteMutationVariables,
  useOfferIdVoucherDeleteMutation,
  useOfferIdVoucherViewQuery,
} from './OfferIdVoucherView.generated';

export const OfferIdVoucherView: React.FC<OfferIdVoucherViewProps> = (
  props,
) => {
  const { className, offerId } = props;

  const { t } = useTranslation('voucher');
  const [isOpen, setOpen] = useState(false);

  const query = useOfferIdVoucherViewQuery({
    variables: {
      offerId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [deleteMutation] = useOfferIdVoucherDeleteMutation({
    refetchQueries: ['OfferIdVoucherView'],
  });

  const offer = query?.data?.offer;
  const voucher = offer?.voucher;

  if (query.loading && !voucher) {
    return <Spin size="large" className={styles.spinner} />;
  }
  if (!offer) {
    return <NotFoundView />;
  }

  const handleSubmit = async () => {
    const variables: OfferIdVoucherDeleteMutationVariables = {
      voucherId: voucher?.id as string,
    };

    const { data } = await deleteMutation({ variables });

    return data;
  };

  return (
    <div className={classnames(className, styles.root)}>
      {voucher ? (
        <Popconfirm
          title={t('confirmation.delete.title')}
          okText={t('confirmation.delete.button')}
          onConfirm={handleSubmit}
          okType="danger"
        >
          <Button type="primary" danger className={styles.deleteButton}>
            {t('buttons.submit.delete')}
          </Button>
        </Popconfirm>
      ) : (
        <>
          <Button type="primary" onClick={() => setOpen(true)}>
            {t('buttons.submit.create')}
          </Button>

          <OfferVoucherCreateModal
            offerId={offer.id}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            onSuccess={async () => {
              await query.refetch(query.variables);
            }}
          />
        </>
      )}

      {voucher?.__typename === 'VoucherFlatDiscount' && (
        <VoucherFlatDiscountUpdateForm voucher={voucher} />
      )}
      {voucher?.__typename === 'VoucherPercentDiscount' && (
        <VoucherPercentDiscountUpdateForm voucher={voucher} />
      )}
      {voucher?.__typename === 'VoucherReward' && (
        <VoucherRewardUpdateForm voucher={voucher} />
      )}
    </div>
  );
};

export type OfferIdVoucherViewProps = {
  offerId: string;
  className?: string;
};
