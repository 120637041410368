import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import {
  CategoryCreateForm,
  CategoryCreateFormProps,
} from '@forms/CategoryCreateForm/CategoryCreateForm';

import styles from './CategoryCreateView.module.css';

export const CategoryCreateView: React.FC<CategoryCreateViewProps> = (
  props,
) => {
  const { className } = props;
  const { t, i18n } = useTranslation('categories');
  const history = useHistory();

  const handleSuccess: CategoryCreateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/categories/list/${result.categoryCreate.category.id}`,
    );
  };
  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <CategoryCreateForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type CategoryCreateViewProps = {
  className?: string;
};
