import { useState } from 'react';
import { Alert, Button, message, Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { Modal } from '../Modal/Modal';

import { CompanyMembershipRole } from '@graphql/generated/types';

import {
  SubsidiaryImportModalImportUsersDryMutation,
  useSubsidiaryImportModalImportUsersDryMutation,
  useSubsidiaryImportModalImportUsersMutation,
} from './SubsidiaryImportUsersModal.generated';
import { parseSubsidiaryCsv } from './utils/parseSubsidiaryCsv';

type SubsidiaryImportUsersModalProps = {
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
};

export function SubsidiaryImportUserModal(
  props: SubsidiaryImportUsersModalProps,
) {
  const { companyId, isOpen, onClose } = props;

  const [importMutation] = useSubsidiaryImportModalImportUsersMutation();
  const [importDryMutation] = useSubsidiaryImportModalImportUsersDryMutation();

  const [csvFile, setCsvFile] = useState<RcFile | null>(null);
  const [companies, setCompanies] = useState<ReturnType<
    typeof parseSubsidiaryCsv
  > | null>(null);
  const [errors, setErrors] = useState<
    | SubsidiaryImportModalImportUsersDryMutation['companySubsidiaryImportUsersDry']['errors']
    | null
  >(null);
  const [count, setCount] = useState({ companies: 0, users: 0, admins: 0 });
  const [isImporting, setIsImporting] = useState(false);

  const handleFileChange: UploadProps['beforeUpload'] = async (file) => {
    setErrors(null);
    setCsvFile(file);

    try {
      const companies = parseSubsidiaryCsv(await file.text());

      const { data } = await importDryMutation({
        variables: {
          companyId: companyId,
          input: {
            rows: companies.flatMap((company) =>
              company.users.map((user) => ({
                slug: company.slug,
                companyName: company.name,
                ...user,
              })),
            ),
          },
        },
      });

      if (
        [
          data?.companySubsidiaryImportUsersDry.errors.duplicateEmails?.length,
          data?.companySubsidiaryImportUsersDry.errors.existingEmails?.length,
          data?.companySubsidiaryImportUsersDry.errors.conflictingSlugs?.length,
        ].some(Boolean)
      ) {
        setErrors(data?.companySubsidiaryImportUsersDry.errors || null);
      }

      setCompanies(companies);
      setCount({
        companies: companies.length,
        users: companies.reduce(
          (acc, company) =>
            acc +
            company.users.filter((u) => u.role === CompanyMembershipRole.Member)
              .length,
          0,
        ),
        admins: companies.reduce(
          (acc, company) =>
            acc +
            company.users.filter((u) => u.role === CompanyMembershipRole.Admin)
              .length,
          0,
        ),
      });
    } catch (err) {
      setCsvFile(null);
      setCount({ companies: 0, users: 0, admins: 0 });
      message.error(
        <pre>{err instanceof Error ? err.message : 'Erreur inconnue'}</pre>,
      );
    }

    return false;
  };

  const handleImport = async (
    companies: ReturnType<typeof parseSubsidiaryCsv>,
  ) => {
    if (errors || isImporting) return;
    setIsImporting(true);

    try {
      await importMutation({
        variables: {
          companyId: companyId,
          input: {
            rows: companies.flatMap((company) =>
              company.users.map((user) => ({
                slug: company.slug,
                companyName: company.name,
                ...user,
              })),
            ),
          },
        },
      });

      location.reload();
    } catch (err) {
      message.error('Une erreur est survenue');
      console.log(err);
    } finally {
      setIsImporting(false);
    }
  };

  const handleClose = () => {
    if (isImporting) {
      message.warning("Vous ne pouvez pas fermer la fenêtre pendant l'import");
      return;
    }

    setErrors(null);
    setCsvFile(null);
    setCompanies(null);
    setCount({ companies: 0, users: 0, admins: 0 });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={() => (companies ? handleImport(companies) : null)}
      title="Importer des utilisateurs dans les filiales"
    >
      <Alert
        type="warning"
        showIcon
        message="Attention, le fichier ne doit pas contenir d'en-tête et respecter le format : slug de la société, email admin, prénom, nom, role"
      />
      <br />
      <Upload
        accept="text/csv"
        maxCount={1}
        beforeUpload={handleFileChange}
        showUploadList={false}
        fileList={csvFile ? [csvFile] : []}
        disabled={!!csvFile}
        type="drag"
      >
        <Button>Sélectionnez ou glissez un fichier CSV</Button>
      </Upload>
      {!!errors && (
        <>
          {errors.duplicateEmails.length > 0 && (
            <Alert
              type="error"
              showIcon
              message={
                'Emails en doublon dans le fichier : ' +
                errors.duplicateEmails.join(',\n')
              }
            />
          )}
          {errors.existingEmails.length > 0 && (
            <Alert
              type="error"
              showIcon
              message={
                'Des utilisateurs existent déjà sur la plateforme : ' +
                errors.existingEmails.join(',\n')
              }
            />
          )}
          {errors.conflictingSlugs.length > 0 && (
            <Alert
              type="error"
              showIcon
              message={
                'Slug déjà existants : ' + errors.conflictingSlugs.join(',\n')
              }
            />
          )}
        </>
      )}
      {errors === null && companies !== null && (
        <>
          <br />
          <Alert
            type="warning"
            showIcon
            message={`${count.users} membres et ${count.admins} admins seront importés`}
          />
        </>
      )}
    </Modal>
  );
}
