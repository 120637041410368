import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { Currency } from 'dinero.js';

import { I18nLink } from '@components/atoms/Link/Link';
import { CompanyDiscountCreateFormProps } from '@components/forms/CompanyDiscountCreateForm/CompanyDiscountCreateForm';
import { CompanyDiscountCreateModal } from '@components/modals/CompanyDiscountCreateModal/CompanyDiscountCreateModal';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CompanyDiscountTableRecordFragment,
  useCompanyDiscountsTableDeleteMutation,
  useCompanyDiscountsTableQuery,
} from './CompanyDiscountsTable.generated';

type RecordType = CompanyDiscountTableRecordFragment;

type Column = 'company' | 'percentOff' | 'amountOff' | 'startsAt' | 'endsAt';

export function CompanyDiscountsTable(props: CompanyDiscountsTableProps) {
  const {
    className,
    productId,
    currency,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('discounts');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteMutation] = useCompanyDiscountsTableDeleteMutation();

  const query =
    propQuery ||
    useCompanyDiscountsTableQuery({
      variables: {
        productId,
        skip: 0,
        take: 10,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'percentOff',
      title: t('fields.percentOff'),
      render: (_, discount) => discount.percentOff && `${discount.percentOff}%`,
    },
    {
      key: 'amountOff',
      title: t('fields.amountOff'),
      render: (_, discount) =>
        discount.amountOff && (
          <TableRendererCurrency
            value={{
              amount: discount.amountOff,
              currency,
            }}
          />
        ),
    },
    {
      key: 'company',
      title: t('fields.company'),
      dataIndex: ['company', 'name'],
      render: (companyName, discount) =>
        discount.company && (
          <I18nLink to={`/companies/list/${discount.company.id}`}>
            {companyName}
          </I18nLink>
        ),
    },
    {
      key: 'startsAt',
      title: t('fields.startsAt'),
      dataIndex: ['startsAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'endsAt',
      title: t('fields.endsAt'),
      dataIndex: ['endsAt'],
      render: TableRendererDateTimeFactory(),
    },
  ];

  const handleCreateSuccess: CompanyDiscountCreateFormProps['onSuccess'] = (
    _result,
    _data,
  ) => {
    query.refetch();
  };

  const handleDelete = (discountId: string) => async () => {
    await deleteMutation({
      variables: {
        discountId,
      },
    });
    await query.refetch();
  };

  return (
    <div className={className}>
      <TablePagination
        id="CompanyDiscountsTable"
        // @ts-expect-error typing error after lib update
        query={query}
        columns={columns}
        data={propData || query.data?.product?.companyDiscountPagination}
        {...rest}
        title={() => (
          <>
            <Space>
              <Button onClick={() => setIsCreateModalOpen(true)}>
                {t('buttons.createCompanyDiscount')}
              </Button>
            </Space>
          </>
        )}
        action={(record) => (
          <Space>
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette réduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete(record.id)}
            >
              <Button type="text" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )}
      />

      <CompanyDiscountCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSuccess={handleCreateSuccess}
        productId={productId}
      />
    </div>
  );
}

export type CompanyDiscountsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  productId: string;
  currency: Currency;
};
