import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType, Scalars } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormPicture } from '@atoms/FormPicture/FormPicture';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignSelectionCreateForm.module.css';

import {
  CampaignSelectionCreateFormMutation,
  CampaignSelectionCreateFormMutationVariables,
  useCampaignSelectionCreateFormMutation,
} from './CampaignSelectionCreateForm.generated';

export type CampaignSelectionCreateFormValues = {
  localeId: string;
  description: Scalars['Delta'];
  name: string;
  coverId: string;
};

export function CampaignSelectionCreateForm(
  props: CampaignSelectionCreateFormProps,
) {
  const { className, defaultValues = {}, ...rest } = props;
  const { t } = useTranslation('common');

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    description: Yup.object().required(),
    name: Yup.string().required(),
    coverId: Yup.string().required(),
  }).required();

  const form = useForm<CampaignSelectionCreateFormValues>({
    validationSchema,
    defaultValues: {
      localeId: 'fr',
      ...defaultValues,
    },
  });

  const [mutation] = useCampaignSelectionCreateFormMutation();

  const handleSubmit: FormProps<CampaignSelectionCreateFormValues>['onValid'] =
    async (values) => {
      const variables: CampaignSelectionCreateFormMutationVariables = {
        input: {
          localeId: values.localeId,
          description: values.description,
          name: values.name,
          coverId: values.coverId,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignSelectionCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          required
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <FormItem
          className={styles.cover}
          required
          label="Image de couverture"
          name="coverId"
        >
          <FormPicture
            name="coverId"
            type={FileType.CampaignCover}
            info={t('picture.advice', {
              resolution: COVER_RESOLUTION_INFO,
              size: COVER_SIZE_INFO,
            })}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la sélection de campagnes
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CampaignSelectionCreateFormProps = ChildFormProps<
  CampaignSelectionCreateFormValues,
  CampaignSelectionCreateFormMutation
> & {
  className?: string;
  defaultValues?: DeepPartial<CampaignSelectionCreateFormValues>;
};
