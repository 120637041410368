import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import QueryString from 'qs';

import { I18nLink } from '@components/atoms/Link/Link';
import { HappeningsTable } from '@components/tables/HappeningsTable/HappeningsTable';

import { useCompanyIdHappeningsViewQuery } from './CompanyIdHappeningsView.generated';

export type CompanyIdHappeningsViewProps = {
  companyId: string;
};

export const CompanyIdHappeningsView = (
  props: CompanyIdHappeningsViewProps,
) => {
  const { companyId } = props;

  const { t } = useTranslation('happenings');
  const query = useCompanyIdHappeningsViewQuery({
    variables: { companyId, take: 20, skip: 0 },
  });

  return (
    <div>
      <HappeningsTable
        activeColumns={[
          'name',
          'registrationSartsAt',
          'registrationEndsAt',
          'happeningStartsAt',
          'happeningEndsAt',
        ]}
        data={query.data?.company?.happeningPagination}
        query={query}
        title={() => (
          <I18nLink
            to={{
              pathname: `/happenings/create`,
              search: QueryString.stringify({ companyId }),
            }}
          >
            <Button type="primary">
              {t('CompanyIdHappeningsView.create')}
            </Button>
          </I18nLink>
        )}
      />
    </div>
  );
};
