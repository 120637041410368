import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Spin } from 'antd';
import classnames from 'classnames';

import { ProductVariantIdArticlesView } from '../ProductVariantIdArticlesView/ProductVariantIdArticlesView';
import ProductVariantIdSettingsView from '../ProductVariantIdSettingsView/ProductVariantIdSettingsView';

import { SortDirection } from '@graphql/generated/types';

import { ProductVariantHeader } from '@components/organisms/ProductVariantHeader/ProductVariantHeader';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ProductVariantIdView.module.css';

import { useProductVariantIdViewQuery } from './ProductVariantIdView.generated';

export const ProductVariantIdView: React.FC<ProductVariantIdViewProps> = (
  props,
) => {
  const { className, match } = props;
  const {
    params: { productVariantId },
  } = match;
  const { t } = useTranslation('productVariants');

  const query = useProductVariantIdViewQuery({
    variables: { productVariantId },
    notifyOnNetworkStatusChange: true,
  });

  const productVariant = query?.data?.productVariant;

  if (query.loading && !productVariant) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!productVariant) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <ProductVariantHeader productVariant={productVariant} />

      <TabsRouter replace match={match} className={styles.router}>
        <TabsRouter.Route
          path={`${match.url}/settings`}
          exact
          label={t('tabs.settings')}
        >
          <ProductVariantIdSettingsView productVariant={productVariant} />
        </TabsRouter.Route>

        {productVariant.product.catalog.provider.isInternal && (
          <TabsRouter.Route
            path={`${match.url}/articles`}
            exact
            label={t('tabs.articles')}
          >
            <ProductVariantIdArticlesView
              productVariantId={productVariantId}
              sorter={{ fileName: SortDirection.Desc }}
            />
          </TabsRouter.Route>
        )}
      </TabsRouter>
    </div>
  );
};

export type ProductVariantIdViewProps = RouteComponentProps<{
  productVariantId: string;
}> & {
  className?: string;
};
