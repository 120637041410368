import { useTranslation } from 'react-i18next';
import { Descriptions, Divider, Spin } from 'antd';
import classnames from 'classnames';

import { MangopayKycDocumentsTable } from '@components/tables/MangopayKycDocumentsTable/MangopayKycDocumentsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './UserIdKycView.module.css';

import { useUserIdKycViewQuery } from './UserIdKycView.generated';

export const UserIdKycView: React.FC<UserIdKycViewProps> = (props) => {
  const { className, userId } = props;
  const { t } = useTranslation('mangopayNaturalUser');
  const query = useUserIdKycViewQuery({
    variables: {
      userId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = query?.data?.user;

  if (query.loading && !user) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!user) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      {user?.mangopayUser && (
        <>
          <Descriptions title="Mangopay account informations" size="small">
            <Descriptions.Item label={t('mangopayNaturalUser.view.mangopayId')}>
              {user?.mangopayUser?.mangopayId}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('mangopayNaturalUser.view.defaultWallet.mangopayId')}
            >
              {user?.mangopayUser?.defaultWallet.mangopayId}
            </Descriptions.Item>
          </Descriptions>

          <Divider />
        </>
      )}
      <MangopayKycDocumentsTable
        activeColumns={['id', 'status', 'type', 'createdAt']}
        query={query}
        data={query.data?.user?.mangopayUser?.mangopayKycDocumentPagination}
      />
    </div>
  );
};

export type UserIdKycViewProps = {
  userId: string;
  className?: string;
};
