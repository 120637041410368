import classnames from 'classnames';
import { debounce } from 'lodash';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectOffer.module.css';

import {
  FormSelectOfferQueryVariables,
  useFormSelectOfferQuery,
} from './FormSelectOffer.generated';

export const FormSelectOffer: React.FC<FormSelectOfferProps> = (props) => {
  const { className, filter, ...rest } = props;

  const { data, loading, variables, refetch } = useFormSelectOfferQuery({
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = debounce((value: string) => {
    refetch({
      ...(variables || {}),
      filter: {
        ...(variables?.filter || {}),
        name: { search: value },
      },
    });
  }, 300);

  return (
    <FormSelect
      className={classnames(className, styles.root)}
      loading={loading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      options={data?.offersPagination.nodes.map((node) => ({
        value: node.id,
        label: node.name,
      }))}
      {...rest}
    />
  );
};

export type FormSelectOfferProps = FormSelectProps & {
  filter?: FormSelectOfferQueryVariables['filter'];
};
