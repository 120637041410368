import { Button, Modal } from 'antd';
import * as DateFns from 'date-fns';
import * as Yup from 'yup';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';

import { usePaymentLimitUpdateModalCreateMutation } from './PaymentLimitUpdateModal.generated';

const LIMIT_VALID_UNTIL = 3; // days

type PaymentLimitUpdateFormValues = {
  limit: number;
};

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  userId: string;
};

export const PaymentLimitUpdateModal = ({ isOpen, onClose, userId }: Props) => {
  const validationSchema = Yup.object({
    limit: Yup.number().required().min(0),
  });

  const form = useForm<PaymentLimitUpdateFormValues>({
    validationSchema,
  });

  const [mutate] = usePaymentLimitUpdateModalCreateMutation();

  const handleSubmit: FormProps<PaymentLimitUpdateFormValues>['onValid'] =
    async (values) => {
      await mutate({
        variables: {
          input: {
            userId,
            amount: values.limit * 100,
            validUntil: DateFns.add(new Date(), { days: LIMIT_VALID_UNTIL }),
          },
        },
      });

      onClose();
    };

  return (
    <Modal
      title="Nouvelle limite de paiement"
      onCancel={onClose}
      visible={isOpen}
      footer={[
        <Button key="cancel" htmlType="button" onClick={onClose}>
          Annuler
        </Button>,
        <Button
          form="PaymentLimitUpdateForm"
          type="primary"
          key="submit"
          htmlType="submit"
        >
          Valider
        </Button>,
      ]}
    >
      <Form id="PaymentLimitUpdateForm" onValid={handleSubmit} form={form}>
        <FormItem label="Limite de paiement (€)" name="limit" required>
          <FormInputNumber name="limit" />
        </FormItem>
      </Form>
    </Modal>
  );
};
