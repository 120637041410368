import { TranslationGenerator } from '@components/organisms/TranslationGenerator/TranslationGenerator';

import {
  useBrandTranslationGenerateMutation,
  useGeneratedTranslationBrandsCountQuery,
  useMissingTranslationBrandsCountQuery,
  useTranslationBrandsCountQuery,
} from './TranslationsBrandsView.generated';

export const TranslationsBrandsView = () => {
  const countQuery = useTranslationBrandsCountQuery();
  const [translationMutation] = useBrandTranslationGenerateMutation();

  return (
    <TranslationGenerator
      useMissingQuery={useMissingTranslationBrandsCountQuery}
      useGeneratedQuery={useGeneratedTranslationBrandsCountQuery}
      countQuery={countQuery}
      generateMutation={translationMutation}
    />
  );
};
