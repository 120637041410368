import { SubventionsTable } from '@components/tables/SubventionsTable/SubventionsTable';

import { useCompanyIdSubventionViewQuery } from './CompanyIdSubventionView.generated';

export const CompanyIdSubventionView: React.FC<CompanyIdSubventionViewProps> = (
  props,
) => {
  const { companyId } = props;

  const query = useCompanyIdSubventionViewQuery({
    variables: {
      companyId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div>
      <SubventionsTable
        activeColumns={[
          'name',
          'startsAt',
          'endsAt',
          'budgetConsumed',
          'budgetTotal',
        ]}
        query={query}
        data={query.data?.company?.subventionPagination || null}
      />
    </div>
  );
};

export type CompanyIdSubventionViewProps = {
  companyId: string;
};
