import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  CompanyMembershipCreateForm,
  CompanyMembershipCreateFormProps,
} from '@components/forms/CompanyMembershipCreateForm/companyMembershipCreateForm';

import styles from './UserCreateView.module.css';

export const UserCreateView: React.FC<UserCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('users');
  const history = useHistory();
  const location = useLocation();

  const handleSuccess: CompanyMembershipCreateFormProps['onSuccess'] = (
    result,
  ) => {
    history.push(
      `/${i18n.language}/users/list/${result.companyMembershipCreate.user.id}`,
    );
  };

  const defaultValues = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as CompanyMembershipCreateFormProps['defaultValues'];

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <CompanyMembershipCreateForm
          defaultValues={defaultValues}
          onSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};

export type UserCreateViewProps = {
  className?: string;
};
