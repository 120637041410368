import { Controller } from 'react-hook-form';
import { Button } from 'antd';
import classNames from 'classnames';
import * as Yup from 'yup';

import { OfferType, Scalars, SortDirection } from '@graphql/generated/types';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { StoresTable } from '@components/tables/StoresTable/StoresTable';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectBrand } from '@atoms/FormSelectBrand/FormSelectBrand';
import { FormSelectCampaign } from '@atoms/FormSelectCampaign/FormSelectCampaign';
import { FormSelectCategory } from '@atoms/FormSelectCategory/FormSelectCategory';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { FormSelectOfferType } from '@atoms/FormSelectOfferType/FormSelectOfferType';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferCommonForm.module.css';

export type OfferCommonFormValues = {
  type: OfferType;
  brandId: string;
  campaignId: string;
  categoryIds: string[];
  localeId: string;
  url?: string;
  startsAt?: Date;
  endsAt?: Date;
  name: string;
  description?: Scalars['Delta'];
  storeIds: string[];
  productId?: string;
};

export function OfferCommonForm(props: OfferCommonFormProps) {
  const { className, onSubmit, value, ...rest } = props;

  const validationSchema = Yup.object({
    type: Yup.mixed().oneOf(Object.values(OfferType)).required(),
    brandId: Yup.string().required(),
    campaignId: Yup.string().required(),
    categoryIds: Yup.array(Yup.string().required()).min(1).required(),
    localeId: Yup.string().required(),
    url: Yup.string().url().when('type', {
      is: OfferType.Url,
      then: Yup.string().required(),
    }),
    startsAt: Yup.date().optional(),
    endsAt: Yup.date().optional(),
    name: Yup.string().required(),
    description: Yup.object().optional(),
    storeIds: Yup.array(Yup.string().required()).required(),
    productId: Yup.string(),
  });

  const form = useForm<OfferCommonFormValues>({
    validationSchema,
    defaultValues: {
      localeId: 'fr',
      storeIds: [],
      ...(value || {}),
    },
  });

  const handleSubmit: FormProps<OfferCommonFormValues>['onValid'] = async (
    data,
  ) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const { brandId, startsAt, endsAt, type } = form.getValues();

  return (
    <Card className={classNames(className, styles.root)}>
      <Form
        onValid={handleSubmit}
        id="OfferCommonForm"
        className={styles.form}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.type}
          required
          label="Type d'offre"
          name="type"
        >
          <FormSelectOfferType name="type" />
        </FormItem>
        <FormItem
          className={styles.brand}
          required
          label="Marque"
          name="brandId"
        >
          <FormSelectBrand
            name="brandId"
            sorter={{ name: SortDirection.Asc }}
          />
        </FormItem>
        <FormItem
          className={styles.campaign}
          required
          label="Campagne"
          name="campaignId"
          dependsOn={['brandId']}
        >
          <FormSelectCampaign
            disabled={!brandId}
            filter={brandId ? { brand: { id: { in: [brandId] } } } : {}}
            sorter={{ name: SortDirection.Asc }}
            name="campaignId"
          />
        </FormItem>
        <FormItem
          className={styles.url}
          required={type === OfferType.Url}
          label="Lien de l'offre"
          name="url"
          dependsOn={['type']}
        >
          <FormInput name="url" />
        </FormItem>
        <FormItem
          className={styles.categories}
          label="Catégories"
          name="categoryIds"
          required
        >
          <FormSelectCategory multiple name="categoryIds" />
        </FormItem>
        <FormItem className={styles.startsAt} label="Début" name="startsAt">
          <FormInputDate
            showTime={true}
            disabledDate={(date) => (endsAt ? date.isAfter(endsAt) : false)}
            name="startsAt"
          />
        </FormItem>
        <FormItem className={styles.endsAt} label="Fin" name="endsAt">
          <FormInputDate
            showTime={true}
            disabledDate={(date) =>
              startsAt ? date.isBefore(startsAt) : false
            }
            name="endsAt"
          />
        </FormItem>
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          label="Description"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <FormItem
          className={styles.stores}
          required
          label="Stores"
          name="storeIds"
        >
          <Controller
            name="storeIds"
            render={({ field }) => (
              <StoresTable
                activeColumns={['formatted']}
                filter={{ brand: { id: { is: brandId ?? '' } } }}
                className={styles.storesInput}
                data={brandId ? undefined : { nodes: [], totalCount: 0 }}
                selectable={{
                  type: 'checkbox',
                  onChange: (keys) => {
                    field.onChange(keys);
                    field.onBlur();
                  },
                  value: field.value,
                }}
              />
            )}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Continuer
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type OfferCommonFormProps = ChildFormProps<
  OfferCommonFormValues,
  void
> & {
  value?: OfferCommonFormValues | null;
  className?: string;
};
