import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useLocales } from '@hooks/locales/locales.hooks';
import { CompanyCustomFieldForm } from '@components/forms/CompanyCustomFieldForm/CompanyCustomFieldForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './CompanyIdCustomFieldsView.module.css';

import { useCompanyIdCustomFieldsViewQuery } from './CompanyIdCustomFieldsView.generated';

export const CompanyIdCustomFieldsView: React.FC<CompanyIdCustomFieldsViewProps> =
  (props) => {
    const { className, companyId } = props;
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
      undefined,
    );

    const localesQuery = useLocales();

    const query = useCompanyIdCustomFieldsViewQuery({
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
    });

    const company = query?.data?.company;
    const locales = localesQuery?.data?.localesPagination.nodes;

    if ((localesQuery.loading || query.loading) && !company) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!company) {
      return <NotFoundView />;
    } else if (!locales) {
      return <ServerErrorView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
        >
          {company?.customFieldsPagination.nodes.map((node) => {
            return (
              <Tabs.TabPane tab={node.label} key={node.id}>
                <CompanyCustomFieldForm
                  companyId={company.id}
                  customField={node}
                  onDeleted={() => setActiveTabKey('create')}
                />
              </Tabs.TabPane>
            );
          })}
          <Tabs.TabPane tab="Ajouter un champ" tabKey="create">
            <CompanyCustomFieldForm
              onCreated={({ companyCustomField }) =>
                setActiveTabKey(companyCustomField.id)
              }
              companyId={company.id}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  };

export type CompanyIdCustomFieldsViewProps = {
  className?: string;
  companyId: string;
};
