import { Alert, Table, Typography } from 'antd';
import Dinero from 'dinero.js';

import { RefundableSubvention } from '../../OrderRefundCreateForm';
import { OrderRefundCreateFormOrderItemFragment } from '../../OrderRefundCreateForm.generated';
import { getRefundableAmounts } from '../../utils/getRefundableAmounts';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';

import { OrderRefundCreateInAppRepartitorOrderFragment } from './OrderRefundCreateInAppRepartitor.generated';

type OrderRefundCreateInAppRepartitorProps = {
  order: OrderRefundCreateInAppRepartitorOrderFragment;
  refundableSubventions: RefundableSubvention[];
  amounts: ReturnType<typeof getRefundableAmounts>;
  orderRefundError?: string;
  orderItems: OrderRefundCreateFormOrderItemFragment[];
  items: { orderItemId: string; quantity: number }[];
};

export const OrderRefundCreateInAppRepartitor = (
  props: OrderRefundCreateInAppRepartitorProps,
) => {
  const {
    order,
    refundableSubventions,
    amounts,
    orderRefundError,
    orderItems,
    items,
  } = props;

  return (
    <div>
      <Typography.Title level={4}>
        Montant des remboursements :
      </Typography.Title>
      {orderRefundError && (
        <Alert showIcon message={orderRefundError} type="error" />
      )}
      {(!!amounts.stripe || !!amounts.fees) && (
        <Table
          pagination={false}
          columns={[
            {
              title: 'Stripe',
              key: 'label',
              dataIndex: 'label',
              render: (label) => {
                return label;
              },
            },
            {
              title: 'Max remboursable',
              key: 'max',
              dataIndex: 'max',
              render: (max) => {
                return Dinero({
                  amount: max,
                  currency: order.currency,
                }).toFormat();
              },
            },
            {
              title: 'Montant à rembourser',
              render: (_, field) => {
                return (
                  <FormInputNumber
                    min={0}
                    max={field.max}
                    addonAfter="€"
                    name={field.formKey}
                    formatter={(value) => (value ? value / 100 : 0).toString()}
                    parser={(value) =>
                      value ? Math.ceil(parseFloat(value) * 100) : 0
                    }
                  />
                );
              },
            },
          ]}
          dataSource={[
            {
              id: 'stripe',
              label: 'Montant',
              max: amounts.stripe,
              formKey: 'stripeAmount',
            },
            {
              id: 'fees',
              label: 'Frais',
              max: amounts.fees,
              formKey: 'feesAmount',
            },
          ]}
          rowKey="id"
        />
      )}
      {!!amounts.discount && (
        <Table
          pagination={false}
          columns={[
            {
              title: 'Réduction tarifaire',
              dataIndex: ['name'],
              render: (name) => {
                return name;
              },
            },
            {
              title: 'Montant remboursé',
              dataIndex: ['amount'],
              render: (amount) => {
                return Dinero({
                  amount,
                  currency: order.currency,
                }).toFormat();
              },
            },
          ]}
          dataSource={items.reduce((acc, item) => {
            const orderItem = orderItems.find(
              (orderItem) => orderItem.id === item.orderItemId,
            );

            if (orderItem && orderItem.discountSubvention) {
              acc.push({
                id: orderItem.id,
                name: orderItem.discountSubvention?.name || '',
                amount: orderItem.discountSubventionAmount * item.quantity,
              });
            }

            return acc;
          }, [] as { id: string; name: string; amount: number }[])}
          rowKey="id"
        />
      )}
      <br />
      {!!amounts.fund && (
        <Table
          pagination={false}
          columns={[
            {
              title: 'Cagnotte',
              render: (_, field) => {
                return field.name;
              },
            },
            {
              title: 'Montant dépensé',
              render: (_, field) => {
                return Dinero({
                  amount: field.max,
                  currency: order.currency,
                }).toFormat();
              },
            },
            {
              title: 'Max remboursable',
              render: (_, field) => {
                return Dinero({
                  amount: Math.min(
                    field.max,
                    field.eligibleAmount,
                    amounts.fund,
                  ),
                  currency: order.currency,
                }).toFormat();
              },
            },
            {
              title: 'Montant à rembourser',
              render: (_, field, index) => {
                return (
                  <FormInputNumber
                    min={0}
                    max={Math.min(field.max, amounts.fund)}
                    addonAfter="€"
                    name={`subventions.${index}.amount`}
                    formatter={(value) => (value ? value / 100 : 0).toString()}
                    parser={(value) =>
                      value ? Math.trunc(parseFloat(value) * 100) : 0
                    }
                  />
                );
              },
            },
          ]}
          dataSource={refundableSubventions.map((subvention) => {
            return {
              id: subvention.id,
              max: subvention.max,
              name: subvention.name || '',
              eligibleAmount: subvention.eligibleAmount,
            };
          })}
          rowKey="id"
        />
      )}
    </div>
  );
};
