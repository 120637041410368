import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { SubventionConsumptionStatus } from '@graphql/generated/types';

type SubventionStateTagProps = {
  subventionConsumptionStatus: SubventionConsumptionStatus;
};

export function SubventionConsumptionStatusTag(props: SubventionStateTagProps) {
  const { subventionConsumptionStatus } = props;
  const { t } = useTranslation('subventions');

  const colors: Record<typeof subventionConsumptionStatus, string> = {
    DRAFT: '',
    RETAINED: 'orange',
    USED: 'green',
  };

  const labels: Record<typeof subventionConsumptionStatus, string> = {
    DRAFT: t('subventionConsumptionStatus.Draft'),
    RETAINED: t('subventionConsumptionStatus.Retained'),
    USED: t('subventionConsumptionStatus.Used'),
  };

  return (
    <Tag color={colors[subventionConsumptionStatus]}>
      {labels[subventionConsumptionStatus]}
    </Tag>
  );
}
