import { useEffect } from 'react';

import { OrderRefundCreateForm } from '@components/forms/OrderRefundCreateForm/OrderRefundCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import { useOrderRefundCreateModalLazyQuery } from './OrderRefundCreateModal.generated';

type OrderRefundCreateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void | Promise<void>;
  orderId: string;
};

export const OrderRefundCreateModal = (props: OrderRefundCreateModalProps) => {
  const { isOpen, onClose, onSuccess, orderId } = props;

  const [loadOrderRefundCreateModal, { data, loading }] =
    useOrderRefundCreateModalLazyQuery();

  useEffect(() => {
    if (isOpen) loadOrderRefundCreateModal({ variables: { orderId } });
  }, [orderId, isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="Créer une demande de remboursement"
      isOpen={isOpen}
      onClose={onClose}
      width={1000}
      form="OrderRefundCreateForm"
    >
      {loading && !data && <div>Chargement...</div>}
      {!data?.order && <NotFoundView />}
      {!!data?.order && (
        <OrderRefundCreateForm
          order={data.order}
          onSuccess={handleSuccess}
          onSubmit={handleSuccess}
          orderItems={data.order.orderItemPagination.nodes}
          subventionConsumptions={
            data.order.subventionConsumptionsPagination.nodes
          }
        />
      )}
    </Modal>
  );
};
