import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import {
  GameCreateForm,
  GameCreateFormProps,
} from '@components/forms/GameCreateForm/GameCreateForm';

import styles from './GameCreateView.module.css';

export const GameCreateView: React.FC<GameCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('games');
  const history = useHistory();

  const handleSuccess: GameCreateFormProps['onSuccess'] = (result) => {
    history.push(`/${i18n.language}/Games/list/${result.gameCreate.game.id}`);
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('GameCreateView.title')}</Typography.Title>
        <GameCreateForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type GameCreateViewProps = {
  className?: string;
};
