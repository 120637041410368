import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Popconfirm, Row, Space, Spin } from 'antd';
import classnames from 'classnames';

import { DefaultDiscountCreateFormProps } from '@components/forms/DefaultDiscountCreateForm/DefaultDiscountCreateForm';
import { DefaultDiscountCreateModal } from '@components/modals/DefaultDiscountCreateModal/DefaultDiscountCreateModal';
import { CompanyDiscountsTable } from '@components/tables/CompanyDiscountsTable/CompanyDiscountsTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ProductIdDiscountsView.module.css';

import {
  useDefaultDiscountDeleteMutation,
  useProductIdDiscountsViewQuery,
} from './ProductIdDiscountsView.generated';

export const ProductIdDiscountsView: React.FC<ProductIdDiscountsViewProps> = (
  props,
) => {
  const { className, productId } = props;
  const { t } = useTranslation(['discounts', 'common']);
  const [
    isCreateDefaultDiscountModalOpen,
    setIsCreateDefaultDiscountModalOpen,
  ] = useState(false);
  const [deleteMutation] = useDefaultDiscountDeleteMutation();

  const query = useProductIdDiscountsViewQuery({
    variables: { productId },
    notifyOnNetworkStatusChange: true,
  });

  const product = query?.data?.product;

  if (query.loading && !product) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!product) {
    return <NotFoundView />;
  }

  if (!product.productVariantPagination?.totalCount) {
    return <div>Il faut créer au moins un variant avant une réduction</div>;
  }

  const [variant] = product.productVariantPagination?.nodes;

  const handleCreateDefaultDiscountSuccess: DefaultDiscountCreateFormProps['onSuccess'] =
    async (_result, _data) => {
      await query.refetch();
    };

  const handleDelete = (discountId: string) => async () => {
    await deleteMutation({ variables: { discountId } });
    await query.refetch();
  };

  const renderProviderDiscount = () => {
    const { providerDiscount: discount } = product;

    if (!discount) return <Empty />;

    switch (discount.__typename) {
      case 'DiscountFlat':
        return `${t('fields.amountOff')}: ${discount.amountOff}${
          variant.priceCurrency
        }`;
      case 'DiscountPercentage':
        return `${t('fields.percentOff')}: ${discount.percentOff} %`;
    }
  };

  const renderDefaultDiscount = () => {
    const { defaultDiscount: discount } = product;

    if (!discount) return <Empty />;

    let reduction = '';

    switch (discount.__typename) {
      case 'DiscountFlat':
        reduction = `${t('fields.amountOff')}: ${discount.amountOff} ${
          variant.priceCurrency
        }`;
        break;
      case 'DiscountPercentage':
        reduction = `${t('fields.percentOff')}: ${discount.percentOff} %`;
        break;
    }

    return (
      <>
        <p>{reduction}</p>
        <p>{`${t('fields.startsAt')}: ${
          discount.startsAt
            ? t('formats.dateTime', {
                date: new Date(discount.startsAt),
                ns: 'common',
              })
            : 'Aucune'
        }`}</p>
        <p>{`${t('fields.endsAt')}: ${
          discount.endsAt
            ? t('formats.dateTime', {
                date: new Date(discount.endsAt),
                ns: 'common',
              })
            : 'Aucune'
        }`}</p>
      </>
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <Row>
        <Col span={12}>
          <Card title="Réduction fournisseur">{renderProviderDiscount()}</Card>
        </Col>

        <Col span={12}>
          <Card
            title="Réduction pour tous"
            extra={
              <Space>
                <Button
                  onClick={() => setIsCreateDefaultDiscountModalOpen(true)}
                >
                  {t('buttons.createDefaultDiscount')}
                </Button>

                {product.defaultDiscount && (
                  <Popconfirm
                    title="Êtes-vous sûr de vouloir supprimer cette réduction ?"
                    okText="Supprimer"
                    okType="danger"
                    onConfirm={handleDelete(product.defaultDiscount.id)}
                  >
                    <Button type="text" danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            }
          >
            {renderDefaultDiscount()}

            <DefaultDiscountCreateModal
              isOpen={isCreateDefaultDiscountModalOpen}
              onClose={() => {
                setIsCreateDefaultDiscountModalOpen(false);
              }}
              onSuccess={handleCreateDefaultDiscountSuccess}
              productId={productId}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CompanyDiscountsTable
            productId={productId}
            currency={variant.priceCurrency}
            activeColumns={[
              'company',
              'percentOff',
              'amountOff',
              'startsAt',
              'endsAt',
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export type ProductIdDiscountsViewProps = {
  className?: string;
  productId: string;
};
