import { Button, message, Space } from 'antd';

import { Card } from '@components/atoms/Card/Card';
import { Typography } from '@components/atoms/Typography/Typography';

import {
  useCompanyIdStripeConnectedAccountCreateMutation,
  useCompanyIdStripeConnectedAccountLinkCreateMutation,
  useCompanyIdStripeViewQuery,
} from './CompanyIdStripeView.generated';

interface CompanyIdStripeViewProps {
  companyId: string;
}

const CompanyIdStripeConnectedAccountCreateButton = (props: {
  companyId: string;
}) => {
  const [createMutation, { data, loading }] =
    useCompanyIdStripeConnectedAccountCreateMutation({
      refetchQueries: ['CompanyIdStripeView'],
    });
  return (
    <div>
      <p>No connected account found for company</p>
      <Button
        type="primary"
        loading={loading}
        onClick={async () => {
          if (!data) {
            try {
              await createMutation({
                variables: { companyId: props.companyId },
              });
            } catch (err) {
              console.error(err);
              message.error(
                'An error occured, please check the devtools console',
              );
            }
          }
        }}
      >
        Create
      </Button>
    </div>
  );
};

const CompanyIdStripeConnectedAccount = (props: {
  companyId: string;
  connectedAccountId: string;
}) => {
  const [createMutation, { data, loading }] =
    useCompanyIdStripeConnectedAccountLinkCreateMutation();
  return (
    <Space size={'middle'} direction="vertical">
      <h2>Connected Account</h2>
      <div>
        <Typography.Text>{`Account id: ${props.connectedAccountId}`}</Typography.Text>
      </div>
      <div>
        <Button
          type={!data ? 'primary' : 'dashed'}
          loading={loading}
          onClick={async () => {
            if (!data) {
              try {
                await createMutation({
                  variables: { companyId: props.companyId },
                });
              } catch (err) {
                console.error(err);
                message.error(
                  'An error occured, please check the devtools console',
                );
              }
            }
          }}
        >
          {!data ? 'Generate account link' : 'Generated'}
        </Button>
      </div>
      {data ? (
        <div>
          <Typography.Text>
            {
              data.companyStripeConnectedAccountLinkCreate
                .stripeConnectedAccountLink.url
            }
          </Typography.Text>
        </div>
      ) : null}
    </Space>
  );
};

export const CompanyIdStripeView = ({
  companyId,
}: CompanyIdStripeViewProps) => {
  const query = useCompanyIdStripeViewQuery({ variables: { companyId } });
  if (query.loading) {
    return null;
  }
  if (query.error) {
    return (
      <Card>
        <h2>Error</h2>
        <p>{query.error.message}</p>
      </Card>
    );
  }
  return (
    <Card>
      {!query.data?.company?.stripeConnectedAccount ? (
        <CompanyIdStripeConnectedAccountCreateButton companyId={companyId} />
      ) : (
        <CompanyIdStripeConnectedAccount
          companyId={companyId}
          connectedAccountId={
            query.data.company.stripeConnectedAccount.connectedAccountId
          }
        />
      )}
    </Card>
  );
};
