import { useState } from 'react';

import {
  QueryBuilder,
  QueryBuilderConfig,
} from '@components/organisms/QueryBuilder/QueryBuilder';

export const QueryBuilderView = () => {
  const [query, setQuery] = useState<unknown>({});

  return (
    <QueryBuilder value={query} onChange={setQuery} config={USER_CONFIG} />
  );
};

const USER_CONFIG: QueryBuilderConfig = {
  target: 'User',
  models: {
    User: {
      email: { type: 'string' },
      firstName: { type: 'string' },
      lastName: { type: 'string' },
      companyMembership: { type: 'relation-one', target: 'CompanyMembership' },
    },
    CompanyMembership: {
      user: { type: 'relation-one', target: 'User' },
      company: { type: 'relation-one', target: 'Company' },
    },
    Company: {
      name: { type: 'string' },
      memberships: { type: 'relation-many', target: 'CompanyMembership' },
    },
  },
};
