import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FileType } from '@graphql/generated/types';

import { acceptInputFormatter, MIME_TYPES } from '@utils/mimetypes';

import {
  FileCreateOptions,
  useAttachmentCreate,
} from '@hooks/attachmentsCreate/attachmentCreate.hooks';

import styles from './InputFileUpload.module.css';

import { InputFileUploadFragment } from './InputFileUpload.generated';

export type InputFileUploadProps =
  | Omit<
      DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'ref' | 'type' | 'accept' | 'onChange' | 'onProgress'
    > & {
      type: FileType;
      accept?: MIME_TYPES[];
      onProgress?: FileCreateOptions['onProgress'];
      uploadOnChange?: boolean;
      onChange: (files: InputFileUploadFragment[] | FileList) => void;
    };

export const InputFileUpload = forwardRef<
  HTMLInputElement,
  InputFileUploadProps
>(
  (
    { type, onChange, uploadOnChange, accept, onProgress, ...rest },
    inputRef,
  ) => {
    const { t } = useTranslation();
    const fileCreate = useAttachmentCreate({
      type,
      onProgress,
    });

    return (
      <input
        ref={inputRef}
        className={styles.hidden}
        onChange={handleFileUpload}
        type="file"
        accept={accept && acceptInputFormatter(accept)}
        {...rest}
      />
    );

    async function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
      const { files } = e.target;

      if (!files?.length) return;

      if (!uploadOnChange) {
        onChange(files);
        return;
      }

      try {
        const uploadedFiles = await Promise.all(
          Array.from(files).map(fileCreate),
        );

        onChange(uploadedFiles);
      } catch (error) {
        console.log(t('errors:default', 'Une erreur est survenue'));
      } finally {
        onProgress?.(null);
      }
    }
  },
);
