import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormSelectBrand } from '@components/atoms/FormSelectBrand/FormSelectBrand';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './BrandMergeDuplicateForm.module.css';

import {
  BrandMergeDuplicateFormMutation,
  BrandMergeDuplicateFormMutationVariables,
  useBrandMergeDuplicateFormMutation,
} from './BrandMergeDuplicateForm.generated';

export type BrandMergeDuplicateFormValues = {
  targetId: string;
  sourceIds: string[];
  confirm: boolean;
};

export function BrandMergeDuplicateForm(props: BrandMergeDuplicateFormProps) {
  const { className, defaultValues, hideFooter, ...rest } = props;
  const { t } = useTranslation('brands');

  const validationSchema = Yup.object({
    targetId: Yup.string().required(),
    sourceIds: Yup.array().min(1).of(Yup.string().required()).required(),
    confirm: Yup.boolean().isTrue().required(),
  }).required();

  const form = useForm<BrandMergeDuplicateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useBrandMergeDuplicateFormMutation();

  const handleSubmit: FormProps<BrandMergeDuplicateFormValues>['onValid'] =
    async (values) => {
      const variables: BrandMergeDuplicateFormMutationVariables = {
        input: {
          targetId: values.targetId,
          sourceIds: values.sourceIds,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  const { targetId } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="BrandMergeDuplicateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.target}
          required
          label={t('brandMergeDuplicateForm.fields.target')}
          name="targetId"
        >
          <FormSelectBrand name="targetId" />
        </FormItem>

        <FormItem
          className={styles.sources}
          required
          label={t('brandMergeDuplicateForm.fields.sources')}
          name="sourceIds"
        >
          <FormSelectBrand
            name="sourceIds"
            multiple={true}
            disabled={!targetId}
            filter={targetId ? { id: { notIn: [targetId] } } : undefined}
          />
        </FormItem>

        <FormItem
          className={styles.confirm}
          required
          label={t('brandMergeDuplicateForm.fields.confirm')}
          name="sourceIds"
        >
          <FormCheckbox name="confirm" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('brandMergeDuplicateForm.submits.merge')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type BrandMergeDuplicateFormProps = ChildFormProps<
  BrandMergeDuplicateFormValues,
  BrandMergeDuplicateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<BrandMergeDuplicateFormValues>;
  hideFooter?: boolean;
};
