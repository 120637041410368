import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  ArticleAccessMode,
  Company,
  FileType,
  Scalars,
} from '@graphql/generated/types';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { FormRichTextEditor } from '@components/atoms/FormRichTextEditor/FormRichTextEditor';
import { FormSelectArticleAccessMode } from '@components/atoms/FormSelectArticleAccessMode/FormSelectArticleAccessMode';
import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { FormSelectLocale } from '@components/atoms/FormSelectLocale/FormSelectLocale';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { FormSelectArticleCategory } from '@components/molecules/FormSelectArticleCategory/FormSelectArticleCategory';
import { CoverLibrarySelect } from '@components/organisms/CoverLibrarySelect/CoverLibrarySelect';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ArticleCreateForm.module.css';

import {
  ArticleCreateFormMutation,
  ArticleCreateFormMutationVariables,
  useArticleCreateFormMutation,
} from './ArticleCreateForm.generated';

// Note: Having no company ID means that the category is global
export type ArticleCreateFormValues = {
  localeId: string;
  title: string;
  content: Scalars['Delta'];
  categoryId: string;
  attachments?: AttachmentItemFragment[];
  cover?: ProgressivePictureFragment;
  accessMode: ArticleAccessMode;
  includedCompanyIds: Company['id'][];
  excludedCompanyIds: Company['id'][];
};

export function ArticleCreateForm(props: ArticleCreateFormProps) {
  const { className, companyId, ...rest } = props;
  const { t } = useTranslation('communication');

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    title: Yup.string().required(),
    content: Yup.object().required(),
    categoryId: Yup.string().required(),
    attachments: Yup.mixed<AttachmentItemFragment[]>().optional(),
    cover: Yup.mixed<ProgressivePictureFragment>().required(),
    accessMode: Yup.mixed().oneOf(Object.values(ArticleAccessMode)).required(),
    includedCompanyIds: Yup.array(),
    excludedCompanyIds: Yup.array(),
  }).required();

  const form = useForm<ArticleCreateFormValues>({
    validationSchema,
    defaultValues: { localeId: 'fr' },
  });

  const [mutation] = useArticleCreateFormMutation();

  const handleSubmit: FormProps<ArticleCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: ArticleCreateFormMutationVariables = {
      input: {
        translations: [
          {
            localeId: values.localeId,
            title: values.title,
            content: values.content,
          },
        ],
        categoryId: values.categoryId,
        attachmentIds: values.attachments?.map((a) => a.id),
        coverId: values.cover?.id,
        accessMode: values.accessMode,
        includedCompanyIds: values.includedCompanyIds,
        excludedCompanyIds: values.excludedCompanyIds,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ArticleCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          required
          label={t('articles.fields.category')}
          name="categoryId"
        >
          <FormSelectArticleCategory
            name="categoryId"
            filter={{ company: { id: { is: companyId || null } } }}
          />
        </FormItem>

        <FormItem
          className={styles.accessMode}
          required
          label={t('articles.fields.accessMode')}
          name="accessMode"
        >
          <FormSelectArticleAccessMode name="accessMode" />
        </FormItem>

        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>

        <FormItem
          className={styles.title}
          required
          label={t('articles.fields.title')}
          name="title"
        >
          <FormInput name="title" />
        </FormItem>

        <FormItem
          className={styles.content}
          required
          label={t('articles.fields.content')}
          name="content"
        >
          <FormRichTextEditor className={styles.content} name="content" />
        </FormItem>

        <FormItem
          className={styles.attachments}
          label={t('articles.fields.attachments')}
          name="attachments"
        >
          <FormInputMultipleUpload
            control={form.control}
            name="attachments"
            max={5}
            type={FileType.ArticleAttachment}
          />
        </FormItem>

        <FormItem
          className={styles.cover}
          label={t('articles.fields.cover')}
          name="cover"
        >
          <CoverLibrarySelect control={form.control} name="cover" />
        </FormItem>

        <FormItem
          className={styles.includedCompanies}
          label={t('fields.includedCompanies')}
          name="includedCompanyIds"
        >
          <FormSelectCompany multiple name="includedCompanyIds" />
        </FormItem>
        <FormItem
          className={styles.excludedCompanies}
          label={t('fields.excludedCompanies')}
          name="excludedCompanyIds"
        >
          <FormSelectCompany multiple name="excludedCompanyIds" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('articles.submits.create')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ArticleCreateFormProps = ChildFormProps<
  ArticleCreateFormValues,
  ArticleCreateFormMutation
> & {
  className?: string;
  companyId?: string;
};
