import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';

import styles from '../Redbin.module.css';

import { useRedBinProductsExpiringSoonQuery } from './ProductsExpiringSoon.generated';

export const ProductsExpiringSoon: React.FC<ProductsExpiringSoonProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinProductsExpiringSoonQuery();

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('ProductsExpiringSoon.title')} />
      <ProductsTable
        query={query}
        data={query.data?.redBinProductsExpiringSoon}
        activeColumns={['name', 'brand', 'offer', 'price']}
      />
    </div>
  );
};

export type ProductsExpiringSoonProps = {
  className?: string;
};
