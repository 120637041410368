import classnames from 'classnames';

import { CampaignSelectionCampaignAddForm } from '@components/forms/CampaignSelectionCampaignAddForm/CampaignSelectionCampaignAddForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './CampaignSelectionCampaignAddModal.module.css';

export type CampaignSelectionCampaignAddModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  campaignSelectionId: string;
};

export function CampaignSelectionCampaignAddModal(
  props: CampaignSelectionCampaignAddModalProps,
) {
  const { className, onClose, onSuccess, isOpen, campaignSelectionId } = props;

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess = async () => {
    await onSuccess?.();
    handleClose();
  };

  return (
    <Modal
      title="CampaignSelectionCampaignAddModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="CampaignSelectionCampaignAddForm"
    >
      <CampaignSelectionCampaignAddForm
        onSuccess={handleSuccess}
        campaignSelectionId={campaignSelectionId}
      />
    </Modal>
  );
}
