import { Spin } from 'antd';
import classnames from 'classnames';

import { OfferUpdateForm } from '@components/forms/OfferUpdateForm/OfferUpdateForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdUpdateView.module.css';

import { useOfferIdUpdateViewQuery } from './OfferIdUpdateView.generated';

export const OfferIdUpdateView: React.FC<OfferIdUpdateViewProps> = (props) => {
  const { className, offerId } = props;

  const query = useOfferIdUpdateViewQuery({
    variables: { offerId },
    notifyOnNetworkStatusChange: true,
  });

  const offer = query?.data?.offer;

  if (query.loading && !offer) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!offer) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <OfferUpdateForm offer={offer} />
    </div>
  );
};

export type OfferIdUpdateViewProps = {
  className?: string;
  offerId: string;
};
