import { Spin } from 'antd';
import classnames from 'classnames';

import { CouponClaimablesTable } from '@components/tables/CouponClaimablesTable/CouponClaimablesTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './UserIdCouponClaimablesView.module.css';

import { useUserIdCouponClaimablesViewQuery } from './UserIdCouponClaimablesView.generated';

export const UserIdCouponClaimablesView: React.FC<UserIdCouponClaimablesViewProps> =
  (props) => {
    const { className, userId } = props;

    const query = useUserIdCouponClaimablesViewQuery({
      variables: {
        userId,
        take: 10,
        skip: 0,
      },
      notifyOnNetworkStatusChange: true,
    });

    const user = query?.data?.user;

    if (query.loading && !user) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!user) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <CouponClaimablesTable
          activeColumns={[
            'id',
            'createdAt',
            'updatedAt',
            'brand',
            'offer',
            'value',
            'claimedAt',
            'offerStatus',
            'offerExpiresAt',
            'actions',
          ]}
          query={query}
          data={query.data?.user?.claimedCouponClaimablesPagination}
        />
      </div>
    );
  };

export type UserIdCouponClaimablesViewProps = {
  userId: string;
  className?: string;
};
