import classnames from 'classnames';

import { CampaignSelectionsTable } from '@components/tables/CampaignSelectionsTable/CampaignSelectionsTable';

import styles from './CampaignSelectionsView.module.css';

type Props = {
  className?: string;
};

export const CampaignSelectionsView = ({ className }: Props) => {
  return (
    <div className={classnames(className, styles.root)}>
      <CampaignSelectionsTable
        activeColumns={['name', 'status', 'campaignCount', 'updatedAt']}
      />
    </div>
  );
};
