import { Button } from 'antd';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import styles from './SSOProviderOIDCForm.module.css';

import {
  SsoProviderOidcFormCreateMutation,
  SsoProviderOidcFormCreateMutationVariables,
  SsoProviderOidcFormFragment,
  useSsoProviderOidcFormCreateMutation,
} from './SSOProviderOIDCForm.generated';

export type SSOProviderOIDCFormValues = {
  name: string;
  issuer: string;
  clientId: string;
  clientSecret: string;
};

export const SSOProviderOIDCForm = (props: SSOProviderOIDCFormProps) => {
  const { companyId, ssoProvider, defaultValues, hideFooter, ...rest } = props;

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    issuer: Yup.string().url().required(),
    clientId: Yup.string().required(),
    clientSecret: Yup.string().required(),
  }).required();

  const form = useForm<SSOProviderOIDCFormValues>({
    validationSchema,
    defaultValues,
  });

  const [createMutation] = useSsoProviderOidcFormCreateMutation();

  const handleCreate: FormProps<SSOProviderOIDCFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: SsoProviderOidcFormCreateMutationVariables = {
      companyId,
      input: values,
    };

    const { data } = await createMutation({ variables });

    return data?.ssoProviderOIDCCreate;
  };

  const handleUpdate = async () => {
    throw new Error('not implemented');
  };

  const handleSubmit: FormProps<SSOProviderOIDCFormValues>['onValid'] = async (
    values,
  ) => {
    if (!ssoProvider) return handleCreate(values);
    else return handleUpdate();
  };

  return (
    <Form id="SSOProviderOIDCForm" form={form} onValid={handleSubmit} {...rest}>
      <FormItem
        label="Nom"
        help="Nom présenter à l'utilisateur eg (Google, Facebook, etc...)"
        name="name"
        required
      >
        <FormInput name="name" />
      </FormItem>
      <FormItem required label="Issuer" name="issuer">
        <FormInput name="issuer" />
      </FormItem>
      <FormItem required label="Id client" name="clientId">
        <FormInput name="clientId" />
      </FormItem>
      <FormItem required label="Secret client" name="clientSecret">
        <FormInput name="clientSecret" />
      </FormItem>
      {hideFooter !== true && (
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Envoyer
          </Button>
        </div>
      )}
    </Form>
  );
};

export type SSOProviderOIDCFormProps = ChildFormProps<
  SSOProviderOIDCFormValues,
  SsoProviderOidcFormCreateMutation['ssoProviderOIDCCreate']
> & {
  companyId: string;
  ssoProvider?: SsoProviderOidcFormFragment;
  defaultValues?: Partial<SSOProviderOIDCFormValues>;
  hideFooter?: boolean;
};
