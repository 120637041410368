import { SubventionDatesUpdateForm } from '@components/forms/SubventionDatesUpdateForm/SubventionDatesUpdateForm';
import { Modal } from '@components/organisms/Modal/Modal';
import { SubventionTimelineCardFragment } from '@components/views/Subventions/SubventionIdView/components/SubventionTimelineCard/SubventionTimelineCard.generated';

export type SubventionDatesUpdateModalProps = {
  onClose: () => void;
  onSuccess?: () => void | Promise<void>;
  subvention: SubventionTimelineCardFragment;
  isOpen?: boolean;
  title: string;
};

const SubventionDatesUpdateModal = ({
  subvention,
  onClose,
  onSuccess,
  isOpen,
  title,
}: SubventionDatesUpdateModalProps) => {
  const handleClose = () => {
    onClose();
  };

  const handleSuccess = async () => {
    await onSuccess?.();
    handleClose();
  };

  return (
    <Modal
      title={title}
      onClose={handleClose}
      isOpen={!!isOpen}
      form="SubventionDatesUpdateForm"
    >
      <SubventionDatesUpdateForm
        subvention={subvention}
        defaultValues={{
          endsAt: subvention.endsAt || undefined,
          startsAt: subvention.startsAt || undefined,
        }}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
};

export default SubventionDatesUpdateModal;
