import { useEffect } from 'react';
import * as Yup from 'yup';

import { Form, useForm } from '../../organisms/Form/Form';

import { CompanyMembershipRole } from '@graphql/generated/types';

import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelectCompanyMembershipRole } from '@components/atoms/FormSelectCompanyMembershipRole/FormSelectCompanyMembershipRole';

import { useCompanyMembershipRoleFormMutation } from './CompanyMembershipRoleForm.generated';

export type CompanyMembershipRoleFormValues = {
  companyMembershipRole: CompanyMembershipRole;
};

export const CompanyMembershipRoleForm = (
  props: CompanyMembershipRoleFormProps,
) => {
  const { className, companyMembershipId, role } = props;

  const [mutate] = useCompanyMembershipRoleFormMutation();

  const validationSchema = Yup.object({
    companyMembershipRole: Yup.mixed()
      .oneOf(Object.values(CompanyMembershipRole))
      .required(),
  }).required();

  const form = useForm<CompanyMembershipRoleFormValues>({
    validationSchema,
    defaultValues: {
      companyMembershipRole: role,
    },
  });

  const companyMembershipRole = form.watch('companyMembershipRole');

  useEffect(() => {
    form.setValue('companyMembershipRole', role);
  }, [role]);

  useEffect(() => {
    mutate({
      variables: {
        companyMembershipId,
        input: { role: companyMembershipRole },
      },
    });
  }, [companyMembershipRole, companyMembershipId]);

  return (
    <Form id="CompanyMembershipRoleForm" onValid={async () => null} form={form}>
      <FormItem required name="companyMembershipRole">
        <FormSelectCompanyMembershipRole
          className={className}
          name="companyMembershipRole"
        />
      </FormItem>
    </Form>
  );
};

export type CompanyMembershipRoleFormProps = {
  className?: string;
  role: CompanyMembershipRole;
  companyMembershipId: string;
};
