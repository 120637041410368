import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classnames from 'classnames';

import styles from './FormSelect.module.css';

export const FormSelect: React.FC<FormSelectProps> = (props) => {
  const { className, name, multiple, clearable, ...rest } = props;
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...rest}
          value={field.value}
          onChange={(...e) => {
            field.onChange(...e);

            if (errors[name]) {
              trigger(name);
            }
          }}
          mode={multiple ? 'multiple' : undefined}
          allowClear={clearable}
          onBlur={field.onBlur}
          ref={field.ref}
          className={classnames(className, styles.root)}
          dropdownMatchSelectWidth={false}
        />
      )}
    />
  );
};

export type FormSelectProps = Omit<
  SelectProps<SelectValue>,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string;
  className?: string;
  multiple?: boolean;
  clearable?: boolean;
};
