import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';

import { useAdminCommentsDrawer } from '@hooks/adminCommentsDrawer/adminCommentsDrawer.hooks';
import { AdminCommentsView } from '@components/views/AdminComments/AdminCommentsView';

const AdminCommentsDrawer = () => {
  const { targetType, targetId, close, isOpen } = useAdminCommentsDrawer();

  const { t } = useTranslation('adminComments');

  if (!targetType || !targetId) {
    return null;
  }

  return (
    <Drawer
      title={t('AdminCommentsDrawer.title')}
      placement="right"
      closable={false}
      onClose={() => close()}
      visible={isOpen}
      key="right"
    >
      <AdminCommentsView targetId={targetId} targetType={targetType} />
    </Drawer>
  );
};

export default AdminCommentsDrawer;
