import { useTranslation } from 'react-i18next';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { PageHeader } from '@molecules/PageHeader/PageHeader';

import {
  PublicationHeaderFragment,
  usePublicationHeaderUpdateMutation,
} from './PublicationHeader.generated';

export const PublicationHeader: React.FC<PublicationHeaderProps> = (props) => {
  const { className, publication } = props;
  const { t } = useTranslation('common');

  const [mutation] = usePublicationHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        publicationId: publication.id,
        input: {
          coverId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      cover={publication.cover}
      logoProps={{ mode: 'contain' }}
      className={className}
      showNav
      uploadCoverProps={{
        fileType: FileType.PublicationCover,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Couverture',
        picture: publication.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
        }),
      }}
    >
      {publication.title}
    </PageHeader>
  );
};

export type PublicationHeaderProps = {
  className?: string;
  publication: PublicationHeaderFragment;
};
