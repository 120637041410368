import { useTranslation } from 'react-i18next';

import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import { ApiRequestTableRecordFragment } from './ApiRequestTable.generated';

type RecordType = ApiRequestTableRecordFragment;

type Column =
  | 'host'
  | 'method'
  | 'path'
  | 'statusCode'
  | 'duration'
  | 'idempotencyKey'
  | 'ip';

export function ApiRequestTable(props: ApiRequestTableProps) {
  const { className, data, query, ...rest } = props;

  if (!query) {
    return null;
  }

  const { t } = useTranslation('apiRequest');

  const columns: TablePaginationColumn<RecordType>[] = [
    { key: 'host', title: t('table.headers.host'), dataIndex: ['host'] },
    { key: 'method', title: t('table.headers.method'), dataIndex: ['method'] },
    { key: 'path', title: t('table.headers.path'), dataIndex: ['path'] },
    {
      key: 'statusCode',
      title: t('table.headers.statusCode'),
      dataIndex: ['statusCode'],
    },
    {
      key: 'duration',
      title: t('table.headers.duration'),
      dataIndex: ['duration'],
    },
    {
      key: 'idempotencyKey',
      title: t('table.headers.idempotencyKey'),
      dataIndex: ['idempotencyKey'],
    },
    { key: 'ip', title: t('table.headers.ip'), dataIndex: ['ip'] },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ApiRequestTableTable"
        query={query}
        columns={columns}
        data={data}
        {...rest}
      />
    </div>
  );
}

export type ApiRequestTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  // filter?: CompanyIdSsoViewQueryVariables['filter'];
  // sorter?: CompanyIdSsoViewQueryVariables['sorter'];
};
