import { Spin } from 'antd';
import classnames from 'classnames';

import { CampaignsTable } from '@tables/CampaignsTable/CampaignsTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CategoryIdCampaignsView.module.css';

import { useCategoryIdCampaignsViewQuery } from './CategoryIdCampaignsView.generated';

export const CategoryIdCampaignsView: React.FC<CategoryIdCampaignsViewProps> = (
  props,
) => {
  const { className, categoryId } = props;

  const query = useCategoryIdCampaignsViewQuery({
    variables: {
      categoryId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const category = query?.data?.category;

  if (query.loading && !category) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!category) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <CampaignsTable
        activeColumns={['name', 'brand', 'status']}
        query={query}
        data={query.data?.category?.campaignPagination}
      />
    </div>
  );
};

export type CategoryIdCampaignsViewProps = {
  className?: string;
  categoryId: string;
};
