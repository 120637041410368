export const AppLogo: React.FC<AppLogoProps> = (props) => {
  const { className, color = 'white' } = props;

  let fillColor = '#fff';

  switch (color) {
    case 'colored':
      fillColor = '#02B6A9';
      break;
  }

  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 212 72"
    >
      <path
        d="M3 36.7h6V24h14.3v12.6h5.9v-30h-6v12H9v-12H3.1v30Zm41.8.6c3.6 0 6.7-1.7 8-5v4.4h5V25.5c0-7.3-4.9-12-12-12-7.2 0-12.2 4.9-12.2 12 0 7.8 5.7 11.8 11.2 11.8Zm1-5.1c-4 0-6.7-2.9-6.7-6.9s2.7-6.8 6.7-6.8 6.6 2.8 6.6 6.8-2.7 6.9-6.6 6.9Zm16.6 14.1h5.4v-13a8.6 8.6 0 0 0 7.6 4c5.5 0 11.3-4 11.3-11.7 0-7.2-5-12.2-12.3-12.2-7 0-12 4.8-12 12.1v20.8Zm12-14.1c-4 0-6.6-2.9-6.6-6.9s2.7-6.8 6.7-6.8 6.7 2.8 6.7 6.8-2.7 6.9-6.7 6.9Zm15.9 14.1h5.4v-13a8.6 8.6 0 0 0 7.6 4c5.5 0 11.2-4 11.2-11.7 0-7.2-5-12.2-12.2-12.2-7.1 0-12 4.8-12 12.1v20.8Zm12-14.1c-4 0-6.6-2.9-6.6-6.9s2.7-6.8 6.7-6.8 6.6 2.8 6.6 6.8-2.7 6.9-6.6 6.9Zm27 14.7c6.2 0 11.2-3.1 11.2-11V14h-5.4v11.5c0 4.2-2.4 6.4-5.8 6.4s-5.7-2-5.7-6.4V14H118v11.5c0 8 5 11.5 10.2 11.5 3 0 5.4-1.4 6.8-3.8V36c0 4.4-2.2 6.2-5.9 6.2-2.7 0-4.5-1-5.4-3h-5.5c1.3 5.3 5.7 7.7 11 7.7Zm16.4-.6h5.5v-13a8.6 8.6 0 0 0 7.5 4c5.5 0 11.3-4 11.3-11.7 0-7.2-5-12.2-12.2-12.2-7.1 0-12 4.8-12 12.1v20.8Zm12.1-14.1c-4 0-6.6-2.9-6.6-6.9s2.6-6.8 6.6-6.8c4 0 6.7 2.8 6.7 6.8s-2.7 6.9-6.7 6.9Zm26.2 5c3.5 0 6.6-1.6 8-5v4.5h5V25.5c0-7.3-5-12-12-12-7.3 0-12.3 4.9-12.3 12 0 7.8 5.8 11.8 11.3 11.8Zm.9-5c-4 0-6.7-2.9-6.7-6.9s2.7-6.8 6.7-6.8 6.6 2.8 6.6 6.8-2.6 6.9-6.6 6.9Zm17.4 4.5h5.4V4h-5.4v32.6Z"
        fill={fillColor}
      />
      <path
        d="M71.6 58A22.3 22.3 0 0 0 93 71.2c10.1 0 17.6-5 21.3-13.2h-5c-3.2 4.9-8.4 8-16.3 8-7.8 0-13-3.1-16.1-8h-5.2Z"
        fill="#212A85"
      />
    </svg>
  );
};

export type AppLogoProps = {
  className?: string;
  color?: 'white' | 'colored';
};
