import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import { PublicationItemContentFragment } from '../PublicationIdView/PublicationIdView.generated';

import {
  PublicationForm,
  PublicationFormProps,
} from '@components/forms/PublicationForm/PublicationForm';
import { PublicationUpdateFormMutation } from '@components/forms/PublicationForm/PublicationForm.generated';

import styles from './PublicationUpdateView.module.css';

export const PublicationUpdateView: React.FC<PublicationUpdateViewProps> = (
  props,
) => {
  const { className } = props;
  const { t, i18n } = useTranslation('communication');
  const history = useHistory();

  const handleSuccess: PublicationFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/communication/publications/list/${
        (result as PublicationUpdateFormMutation).publicationUpdate.publication
          .id
      }/update`,
    );
  };
  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('publications.update.title')}</Typography.Title>
        <PublicationForm
          onSuccess={handleSuccess}
          publication={props.publication}
        />
      </div>
    </div>
  );
};

export type PublicationUpdateViewProps = {
  className?: string;
  publication: PublicationItemContentFragment;
};
