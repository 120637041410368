import { ApiKeyTable } from '@components/tables/ApiKeyTable/ApiKeyTable';
import { ApiRequestTable } from '@components/tables/ApiRequestTable/ApiRequestTable';

import { useCompanyIdApiViewQuery } from './CompanyIdApiView.generated';

export const CompanyIdApiView = (props: CompanyIdApiViewProps) => {
  const { companyId } = props;

  const query = useCompanyIdApiViewQuery({ variables: { companyId } });

  return (
    <div>
      <ApiKeyTable
        activeColumns={['name', 'clientId']}
        query={query}
        data={query.data?.company?.apiKeyPagination}
        companyId={companyId}
      />
      <ApiRequestTable
        activeColumns={['host', 'ip']}
        query={query}
        data={query.data?.company?.apiRequestPagination}
      />
    </div>
  );
};

export type CompanyIdApiViewProps = {
  companyId: string;
};
