/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { QueryResult } from '@apollo/client';
import { Button } from 'antd';
import { get, omit, set } from 'lodash';

import { FilterDropdownBoolean } from '../FilterDropdownBoolean/FilterDropdownBoolean';
import { FilterDropdownDate } from '../FilterDropdownDate/FilterDropdownDate';
import { FilterDropdownEnum } from '../FilterDropdownEnum/FilterDropdownEnum';
import { FilterDropdownNumber } from '../FilterDropdownNumber/FilterDropdownNumber';
import { FilterDropdownSearch } from '../FilterDropdownSearch/FilterDropdownSearch';
import { FilterDropdownSearchableEnum } from '../FilterDropdownSearchableEnum/FilterDropdownSearchableEnum';

import { TablePaginationColumn } from '@components/organisms/TablePagination/TablePagination';

import styles from './FilterDropdown.module.css';

type FilterType =
  | 'enum'
  | 'search'
  | 'searchableEnum'
  | 'date'
  | 'datetime'
  | 'number'
  | 'boolean'
  | 'id'
  | 'string';

type GenerateColumnFilterOutput = Partial<TablePaginationColumn<any>>;

export function generateColumnFilter(
  query: QueryResult<any, any>,
  filterPath: string,
  type: FilterType,
  values?: { label: ReactNode; value: string | number }[],
  filterQuery?: QueryResult<any, any>,
  filterHasMore?: boolean,
): GenerateColumnFilterOutput {
  const [filters, setFilters] = useState<{
    search?: string;
    in?: string[];
    id?: {
      in?: string[];
    };
    gte?: number | string | Date;
    lte?: number | string | Date;
    is?: boolean | string;
    contains?: string;
  }>({});

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isResetLoading, setResetLoading] = useState(false);

  const handleEnumChange = (values: string[]) => {
    if (values?.length) {
      const filter = filterQuery ? { id: { in: values } } : { in: values };
      setFilters({ ...filters, ...filter });
    } else {
      setFilters(omit(filters, 'include'));
    }
  };

  const handleBooleanChange = (value: string) => {
    if (value === 'all') {
      setFilters({});
    } else {
      const filter = { is: value === 'true' };
      setFilters({ ...filters, ...filter });
    }
  };

  const handleSearchChange = (value: string) => {
    if (value?.length) {
      setFilters({ ...filters, search: value });
    } else {
      setFilters(omit(filters, 'search'));
    }
  };

  const handleIdChange = (value: string) => {
    if (value?.length) {
      const filter = { is: value };
      setFilters({ ...filters, ...filter });
    } else {
      setFilters(omit(filters, 'is'));
    }
  };

  const handleStringChange = (value: string) => {
    if (value?.length) {
      const filter = { contains: value };
      setFilters({ ...filters, ...filter });
    } else {
      setFilters(omit(filters, 'contains'));
    }
  };

  const handleNumberChange = (minOrAndMax: { gte?: number; lte?: number }) => {
    if (Object.values(minOrAndMax).length) {
      setFilters({ ...filters, ...minOrAndMax });
    } else {
      setFilters(omit(filters, 'include'));
    }
  };

  const handleDateChange = (minOrAndMax: { gte?: Date; lte?: Date }) => {
    if (Object.values(minOrAndMax).length) {
      setFilters({ ...filters, ...minOrAndMax });
    } else {
      setFilters(omit(filters, 'include'));
    }
  };

  const handleRefetch = async () => {
    setSubmitLoading(true);

    try {
      if (filterPath === 'statuses') {
        const statuses = filters.in;

        let statusesArray: string[] = [];

        if (statuses !== undefined) {
          statusesArray = Object.values(statuses);
        }

        const filter = {
          statuses: statusesArray,
        };

        await query.refetch({
          ...query.variables,
          skip: 0,
          filter: {
            ...(query.variables.filter || {}),
            ...filter,
          },
        });
      } else if (filterPath.startsWith('customFields')) {
        const [root, path] = filterPath.split('.');

        const previousFilters = get(query.variables, `filter.${root}`, []) as {
          key: string;
        }[];

        await query.refetch({
          ...query.variables,
          filter: {
            ...(query.variables.filter || {}),
            customFields: [
              ...previousFilters.filter((f) => f.key !== path),
              { key: path, ...filters },
            ],
          },
          skip: 0,
        });
      } else {
        await query.refetch(
          set(
            {
              ...query.variables,
              skip: 0,
            },
            `filter.${filterPath}`,
            filters,
          ),
        );
      }
    } finally {
      setSubmitLoading(false);
      setDropdownVisible(false);
    }
  };

  const handleReset = async () => {
    setResetLoading(true);
    setFilters({});

    try {
      if (filterPath.startsWith('customFields')) {
        const [root, path] = filterPath.split('.');
        const previousFilters = get(query.variables, `filter.${root}`, []) as {
          key: string;
        }[];

        await query.refetch({
          ...query.variables,
          filter: {
            ...(query.variables.filter || {}),
            customFields: previousFilters.filter((f) => f.key !== path),
          },
        });
      } else {
        await query.refetch(omit(query.variables, `filter.${filterPath}`));
      }
    } finally {
      setResetLoading(false);
      setDropdownVisible(false);
    }
  };

  const filtered = Object.values(filters).filter(Boolean).length > 0;

  return {
    filterDropdown: (
      <FilterDropdown
        resetDisabled={!filtered}
        onReset={handleReset}
        onSubmit={handleRefetch}
        resetLoading={isResetLoading}
        submitLoading={isSubmitLoading}
      >
        {type === 'search' && (
          <FilterDropdownSearch
            value={filters?.search || ''}
            onChange={handleSearchChange}
          />
        )}
        {type === 'id' && (
          <FilterDropdownSearch
            value={(filters?.is as string) || ''}
            onChange={handleIdChange}
          />
        )}
        {type === 'string' && (
          <FilterDropdownSearch
            value={(filters?.contains as string) || ''}
            onChange={handleStringChange}
          />
        )}
        {type === 'enum' && !!values && (
          <FilterDropdownEnum
            value={filters.in || filters?.id?.in || []}
            onChange={handleEnumChange}
            options={values}
            name={filterPath}
            filterQuery={filterQuery}
            filterHasMore={filterHasMore}
          />
        )}
        {type === 'searchableEnum' && !!values && (
          <FilterDropdownSearchableEnum
            values={filters.in || []}
            onChange={handleEnumChange}
            options={values}
          />
        )}
        {type === 'date' && (
          <FilterDropdownDate onChange={handleDateChange} showTime={false} />
        )}
        {type === 'datetime' && (
          <FilterDropdownDate onChange={handleDateChange} showTime={true} />
        )}

        {type === 'number' && (
          <FilterDropdownNumber
            onChange={handleNumberChange}
            values={{
              min: filters.gte as number | undefined,
              max: filters.lte as number | undefined,
            }}
          />
        )}
        {type === 'boolean' && !!values && (
          <FilterDropdownBoolean
            value={typeof filters.is === 'boolean' ? String(filters.is) : 'all'}
            onChange={handleBooleanChange}
            options={values}
            name={filterPath}
          />
        )}
      </FilterDropdown>
    ),
    filtered,
    filterIcon: (
      <FilterFilled
        onClick={() => setDropdownVisible(!isDropdownVisible)}
        style={{ color: filtered ? '#02B6A9' : undefined }}
      />
    ),
    filterDropdownVisible: isDropdownVisible,
  };
}

function FilterDropdown(props: FilterDropdownProps) {
  const {
    children,
    onReset,
    resetLoading,
    onSubmit,
    submitLoading,
    resetDisabled,
  } = props;

  return (
    <div className={styles.root}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {children}
        <div className={styles.footer}>
          <Button
            size="small"
            disabled={resetDisabled || submitLoading}
            loading={resetLoading}
            onClick={onReset}
          >
            Réinitialiser
          </Button>
          <Button
            size="small"
            onClick={onSubmit}
            loading={submitLoading}
            disabled={resetLoading}
            type="primary"
          >
            Appliquer
          </Button>
        </div>
      </form>
    </div>
  );
}

type FilterDropdownProps = {
  children: ReactNode | ReactNode[];
  onSubmit: () => void;
  onReset: () => void;
  resetDisabled: boolean;
  submitLoading?: boolean;
  resetLoading?: boolean;
};
