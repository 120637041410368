import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormPicture } from '@atoms/FormPicture/FormPicture';
import { FormSelectCategory } from '@atoms/FormSelectCategory/FormSelectCategory';
import { FormSelectLocale } from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CategoryCreateForm.module.css';

import {
  CategoryCreateFormMutation,
  CategoryCreateFormMutationVariables,
  useCategoryCreateFormMutation,
} from './CategoryCreateForm.generated';

export type CategoryCreateFormValues = {
  parentCategoryId?: string;
  localeId: string;
  name: string;
  iconId?: string;
};

export function CategoryCreateForm(props: CategoryCreateFormProps) {
  const { className, ...rest } = props;

  const validationSchema = Yup.object({
    parentCategoryId: Yup.string(),
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    iconId: Yup.string().when(['parentCategoryId'], {
      is: (parentCategoryId: string) => !parentCategoryId,
      then: Yup.string().required(),
    }),
  }).required();

  const form = useForm<CategoryCreateFormValues>({
    validationSchema,
    defaultValues: {
      localeId: 'fr',
    },
  });

  const [mutation] = useCategoryCreateFormMutation();

  const handleSubmit: FormProps<CategoryCreateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: CategoryCreateFormMutationVariables = {
      input: {
        parentCategoryId: values.parentCategoryId,
        iconId: values.iconId,
        localeId: values.localeId,
        name: values.name,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CategoryCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale name="localeId" />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.parent}
          required
          label="Catégorie parent"
          name="parentCategoryId"
        >
          <FormSelectCategory rootOnly name="parentCategoryId" />
        </FormItem>
        <FormItem
          className={styles.icon}
          required
          label="Icone"
          name="iconId"
          help="Requis en cas de création de catégorie racine"
        >
          <FormPicture
            name="iconId"
            previewRatio="1:1"
            type={FileType.CategoryIcon}
            info="Résolution recommandée 256x256, max 512ko"
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer la catégorie
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type CategoryCreateFormProps = ChildFormProps<
  CategoryCreateFormValues,
  CategoryCreateFormMutation
> & {
  className?: string;
};
