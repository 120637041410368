import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { HappeningRegistrationStatusTagFragment } from './HappeningRegistrationStatus.generated';

type HappeningRegistrationStatusTagProps = {
  happeningRegistration: HappeningRegistrationStatusTagFragment;
};

export function HappeningRegistrationStatusTag(
  props: HappeningRegistrationStatusTagProps,
) {
  const { happeningRegistration } = props;
  const { t } = useTranslation('happeningRegistrations');

  const colors: Record<typeof happeningRegistration.status, string> = {
    CANCELLED: 'grey',
    CONFIRMED: 'green',
    AWAITING_PAYMENT: 'orange',
    AWAITING_STRIPE: 'orange',
    REFUNDED: 'red',
  };

  const labels: Record<typeof happeningRegistration.status, string> = {
    CANCELLED: t('happeningRegistrationStatus.cancelled'),
    CONFIRMED: t('happeningRegistrationStatus.confirmed'),
    AWAITING_PAYMENT: t('happeningRegistrationStatus.awaitingPayment'),
    AWAITING_STRIPE: t('happeningRegistrationStatus.awaitingStripe'),
    REFUNDED: t('happeningRegistrationStatus.refunded'),
  };

  return (
    <Tag color={colors[happeningRegistration.status]}>
      {labels[happeningRegistration.status]}
    </Tag>
  );
}
