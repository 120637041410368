import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import {
  PublicationForm,
  PublicationFormProps,
} from '@components/forms/PublicationForm/PublicationForm';
import { PublicationCreateFormMutation } from '@components/forms/PublicationForm/PublicationForm.generated';

import styles from './PublicationCreateView.module.css';

export const PublicationCreateView: React.FC<PublicationCreateViewProps> = (
  props,
) => {
  const { className } = props;
  const { t, i18n } = useTranslation('communication');
  const history = useHistory();

  const handleSuccess: PublicationFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/communication/publications/${
        (result as PublicationCreateFormMutation).publicationCreate.publication
          .id
      }`,
    );
  };
  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('publications.create.title')}</Typography.Title>
        <PublicationForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type PublicationCreateViewProps = {
  className?: string;
};
