import { useState } from 'react';
import { Collapse, Empty, message, Space, Spin, Switch } from 'antd';

import { CompanyFeatureConfigConnectorCard } from '../CompanyFeatureConfigConnectorCard/CompanyFeatureConfigConnectorCard';

import { UserIntegrationProviderName } from '@graphql/generated/types';

import {
  useCompanyFeatureConfigProviderToggleQuery,
  useCompanyFeatureConfigUserIntegrationProviderDisableMutation,
  useCompanyFeatureConfigUserIntegrationProviderEnableMutation,
} from './CompanyFeatureConfigProviderToggle.generated';

interface CompanyFeatureConfigProviderToggleProps {
  className?: string;
  companyId: string;
  providerName: UserIntegrationProviderName;
}

const PANEL_KEY = 'default';

export const CompanyFeatureConfigProviderToggle = (
  props: CompanyFeatureConfigProviderToggleProps,
) => {
  const { companyId, providerName } = props;

  const [openedKeys, setOpenedKeys] = useState<string | string[] | undefined>();

  const query = useCompanyFeatureConfigProviderToggleQuery({
    variables: {
      companyId,
      providerName,
    },
    onCompleted: (data) => {
      if (
        data.company?.featureConfig.userIntegration.providerConfig?.isEnabled
      ) {
        setOpenedKeys(PANEL_KEY);
      }
    },
  });

  const [providerEnableMutation, providerEnableState] =
    useCompanyFeatureConfigUserIntegrationProviderEnableMutation();
  const [providerDisableMutation, providerDisableState] =
    useCompanyFeatureConfigUserIntegrationProviderDisableMutation();

  const handleProviderToggle = async (checked: boolean) => {
    try {
      if (checked) {
        await providerEnableMutation({
          variables: {
            companyId,
            input: {
              providerName,
            },
          },
        });
      } else {
        await providerDisableMutation({
          variables: {
            companyId,
            input: {
              providerName,
            },
          },
        });
      }

      await query.refetch();
    } catch (err) {
      message.error('Une erreur est survenue');
    }
  };

  const userIntegration = query.data?.company?.featureConfig.userIntegration;
  const providerConfig = userIntegration?.providerConfig;
  const connectors =
    userIntegration?.connectorsPagination.edges.map((e) => e.node) ?? [];

  return (
    <Collapse
      destroyInactivePanel
      onChange={setOpenedKeys}
      activeKey={openedKeys}
    >
      <Collapse.Panel
        key={PANEL_KEY}
        extra={
          <Switch
            checked={providerConfig?.isEnabled}
            onChange={handleProviderToggle}
            disabled={
              providerDisableState.loading || providerEnableState.loading
            }
            loading={
              providerEnableState.loading || providerDisableState.loading
            }
          />
        }
        collapsible="header"
        header={providerName}
      >
        {query.loading ? (
          <Spin />
        ) : connectors.length === 0 ? (
          <Empty description="Aucun connecteur configuré" />
        ) : (
          <Space direction="vertical" style={{ width: '100%' }}>
            {connectors.map((connector, i) => (
              <CompanyFeatureConfigConnectorCard
                key={connector.id}
                companyId={companyId}
                index={i}
                connector={connector}
                providerDisabled={!providerConfig?.isEnabled}
              />
            ))}
          </Space>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
