import classnames from 'classnames';

import { Offer } from '@graphql/generated/types';

import {
  CouponClaimablesCreateForm,
  CouponClaimablesCreateFormValues,
} from '@components/forms/CouponClaimablesCreateForm/CouponClaimablesCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './CouponClaimablesCreateModal.module.css';

export type CouponClaimablesCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  offerId: Offer['id'];
  defaultValues?: Partial<CouponClaimablesCreateFormValues>;
};

export function CouponClaimablesCreateModal(
  props: CouponClaimablesCreateModalProps,
) {
  const { className, onClose, onSuccess, isOpen, offerId, defaultValues } =
    props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="CouponClaimablesCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="CouponClaimablesCreateForm"
    >
      <CouponClaimablesCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        offerId={offerId}
        onSubmit={handleSuccess}
      />
    </Modal>
  );
}
