import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import classNames from 'classnames';

import { SubventionState } from '@graphql/generated/types';

import styles from './SubventionSteps.module.css';

import { SubventionStepsFragment } from './SubventionSteps.generated';

type SubventionStepsProps = {
  className?: string;
  subvention: SubventionStepsFragment;
};

export function SubventionSteps(props: SubventionStepsProps) {
  const { className, subvention } = props;
  const { t } = useTranslation(['subventions', 'common']);

  const steps: Record<SubventionState, number> = {
    DRAFT: 0,
    AWAITING_PROVISION: 1,
    AWAITING_SCHEDULING: 1,
    CONFIRMED: 1,
    READY: 2,
    PUBLISHED: 3,
    COMPLETED: 4,
    ABORTED: 4,
  };

  return (
    <Steps
      className={classNames(className, styles.root)}
      progressDot
      current={steps[subvention.state]}
    >
      <Steps.Step
        title="Brouillon"
        description={t('common:formats.shortDate', {
          date: subvention.createdAt,
        })}
      />
      <Steps.Step title="À confirmer" />
      <Steps.Step title="Programmé" />
      <Steps.Step
        title="Active"
        description={t('common:formats.shortDate', {
          date: subvention.startsAt,
        })}
      />
      <Steps.Step
        title="Terminée"
        description={t('common:formats.shortDate', {
          date: subvention.endsAt,
        })}
      />
    </Steps>
  );
}
