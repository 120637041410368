import { useTranslation } from 'react-i18next';
import { Drawer, Space } from 'antd';
import classnames from 'classnames';

import { Card } from '@components/atoms/Card/Card';
import { OrderItemStatusUpdateForm } from '@components/forms/OrderStatusUpdateForm/OrderStatusUpdateForm';
import { OrderItemArticlesPagination } from '@components/tables/OrderItemArticlesPagination/OrderItemArticlesPagination';
import { OrderItemTableRecordFragment } from '@components/tables/OrderItemTable/OrderItemTable.generated';
import { ProductOrdersTable } from '@components/tables/ProductOrdersTable/ProductOrdersTable';

import styles from './ProductOrdersDrawer.module.css';

export const ProductOrdersDrawer: React.FC<ProductOrdersDrawerProps> = (
  props,
) => {
  const { t } = useTranslation('productOrders');

  const { orderItem, onClose } = props;

  return (
    <div className={classnames('', styles.root)}>
      <Drawer
        title={t('table.title')}
        placement="right"
        size="large"
        width={900}
        onClose={onClose}
        visible={true}
        bodyStyle={{ margin: 0, padding: 8 }}
      >
        <Space direction="vertical" size={10} style={{ display: 'flex' }}>
          <Card>
            <OrderItemStatusUpdateForm orderItem={orderItem} />
          </Card>
          <Card>
            <ProductOrdersTable
              filter={{ lineItems: { include: [orderItem.id] } }}
              activeColumns={[
                'status',
                'catalog',
                'provider',
                'createdAt',
                'updatedAt',
              ]}
            />
          </Card>
          <Card>
            <OrderItemArticlesPagination orderItemId={orderItem.id} />
          </Card>
        </Space>
      </Drawer>
    </div>
  );
};

export type ProductOrdersDrawerProps = {
  orderItem: OrderItemTableRecordFragment;
  onClose: () => void;
};
