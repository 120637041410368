import { useTranslation } from 'react-i18next';

import { I18nLink } from '@components/atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  CatalogPaginationQueryVariables,
  CatalogRecordFragment,
  useCatalogPaginationQuery,
} from './CatalogsTable.generated';

type RecordType = CatalogRecordFragment;

type Column = 'companyName';

export function CatalogsTable(props: CatalogsTableProps) {
  const { className, filter, ...rest } = props;
  let { query } = props;

  const { t } = useTranslation(['catalogs', 'common']);

  if (!query) {
    query = useCatalogPaginationQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
      },
    });
  }

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'companyName',
      title: t('table.headers.companyName'),
      dataIndex: ['node', 'company'],
      render: (company) => (
        <I18nLink to={`/companies/list/${company.id}/catalog`}>
          {company.name}
        </I18nLink>
      ),
    },
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['node', 'id'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="CatalogsTable"
        rowKey="cursor"
        query={query}
        columns={columns}
        data={query.data?.catalogPagination}
        {...rest}
      />
    </div>
  );
}

export type CatalogsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: CatalogPaginationQueryVariables['filter'];
};
