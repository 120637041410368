import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectProvince } from '@components/atoms/FormSelectProvince/FormSelectProvince';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './StoreUpdateForm.module.css';

import {
  StoreUpdateFormFragment,
  StoreUpdateFormUpdateMutation,
  useStoreUpdateFormUpdateMutation,
} from './StoreUpdateForm.generated';

export type StoreUpdateFormValues = {
  address1: string;
  address2?: string | null;
  city?: string | null;
  zip: string;
  country: string;
  province?: string | null;
};

export function StoreUpdateForm(props: StoreUpdateFormProps) {
  const { className, store, ...rest } = props;

  const { t } = useTranslation('stores');

  const validationSchema = Yup.object({
    address1: Yup.string().required(),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    zip: Yup.string().required(),
    country: Yup.string().required(),
    province: Yup.string().nullable(),
  }).required();

  const form = useForm<StoreUpdateFormValues>({
    validationSchema,
    defaultValues: {
      address1: store.address.address1,
      address2: store.address.address2,
      zip: store.address.zip,
      city: store.address.city,
      country: store.address.country,
      province: store.address.province,
    },
  });

  const [mutation] = useStoreUpdateFormUpdateMutation({
    refetchQueries: ['StoreIdView'],
  });

  const handleSubmit: FormProps<StoreUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!store) return;

    try {
      const { data } = await mutation({
        variables: {
          storeId: store.id,
          input: {
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              zip: values.zip,
              country: values.country,
              province: values.province,
            },
          },
        },
      });

      return data;
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
  };

  const { country } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="StoreUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.address1}
          required
          label={t('fields.address1')}
          name="address1"
        >
          <FormInput name="address1" />
        </FormItem>

        <FormItem
          className={styles.address2}
          required
          label={t('fields.address2')}
          name="address2"
        >
          <FormInput name="address2" />
        </FormItem>

        <FormItem
          className={styles.zip}
          required
          label={t('fields.zip')}
          name="zip"
        >
          <FormInput name="zip" />
        </FormItem>

        <FormItem
          className={styles.city}
          required
          label={t('fields.city')}
          name="city"
        >
          <FormInput name="city" />
        </FormItem>

        <FormItem
          className={styles.country}
          required
          label={t('fields.country')}
          name="country"
        >
          <FormSelectCountry name="country" />
        </FormItem>

        <FormItem
          className={styles.province}
          required
          label={t('fields.province')}
          name="province"
          dependsOn={['country']}
        >
          <FormSelectProvince
            disabled={!country}
            countryCode={country}
            name="province"
          />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type StoreUpdateFormProps = ChildFormProps<
  StoreUpdateFormValues,
  StoreUpdateFormUpdateMutation
> & {
  className?: string;
  store: StoreUpdateFormFragment;
};
