import { Input, InputProps } from 'antd';

import styles from './FilterDropdownSearch.module.css';

export function FilterDropdownSearch(props: FilterDropdownSearchProps) {
  const { value, onChange } = props;

  const handleChange: InputProps['onChange'] = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.root}>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <Input autoFocus={true} onChange={handleChange} value={value} />
    </div>
  );
}

export type FilterDropdownSearchProps = {
  onChange: (value: string) => void;
  value: string;
};
