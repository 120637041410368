import { onError } from '@apollo/client/link/error';

import { EventBus, Events } from '@services/bus.service';

export const unauthorizedErrorLink = onError(
  ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.name) {
          case 'UnauthorizedError':
            EventBus.emit(Events.UnauthorizedError);
            break;
          default:
            console.error('unhandled graphql error', { graphQLErrors });
            break;
        }
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
);
