import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Divider, Space, Switch } from 'antd';
import Typography from 'antd/lib/typography/Typography';

import { UserIntegrationProviderName } from '@graphql/generated/types';

import {
  useCompanyFeatureConfigUserIntegrationFeatureDisableMutation,
  useCompanyFeatureConfigUserIntegrationFeatureEnableMutation,
  useCompanyFeatureConfigUserIntegrationViewQuery,
} from './CompanyFeatureConfigUserIntegrationView.generated';
import { CompanyFeatureConfigProviderToggle } from './components/CompanyFeatureConfigProviderToggle/CompanyFeatureConfigProviderToggle';

interface CompanyFeatureConfigUserIntegrationViewProps {
  companyId: string;
}

export const CompanyFeatureConfigUserIntegrationView = (
  props: CompanyFeatureConfigUserIntegrationViewProps,
) => {
  const { companyId } = props;

  const [featureDisableMutation, featureDisableState] =
    useCompanyFeatureConfigUserIntegrationFeatureDisableMutation();
  const [featureEnableMutation, featureEnableState] =
    useCompanyFeatureConfigUserIntegrationFeatureEnableMutation();

  const { data } = useCompanyFeatureConfigUserIntegrationViewQuery({
    variables: { companyId },
  });

  const company = data?.company;

  const handleFeatureToggle = async (checked: boolean) => {
    if (checked) {
      await featureEnableMutation({
        variables: { companyId },
      });
    } else {
      await featureDisableMutation({
        variables: { companyId },
      });
    }
  };

  return (
    <div>
      <Space>
        <Typography>Fonctionnalité</Typography>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          disabled={
            !company ||
            featureDisableState.loading ||
            featureEnableState.loading
          }
          checked={company?.featureConfig.userIntegration.isEnabled}
          onChange={handleFeatureToggle}
          loading={featureDisableState.loading || featureEnableState.loading}
        />
      </Space>
      <Divider />
      {Object.values(UserIntegrationProviderName).map((providerName) => (
        <CompanyFeatureConfigProviderToggle
          key={providerName}
          companyId={companyId}
          providerName={providerName}
        />
      ))}
    </div>
  );
};
