import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';

import { ProductOrderEvents } from '@components/organisms/ProductOrderEvents/ProductOrderEvents';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import styles from './ProductOrdersTable.module.css';

import {
  ProductOrderRetryMutationVariables,
  ProductOrdersTablePaginationFragment,
  ProductOrdersTableQueryVariables,
  ProductOrdersTableRecordFragment,
  useProductOrderRetryMutation,
  useProductOrdersTableQuery,
} from './ProductOrdersTable.generated';

type RecordType = ProductOrdersTableRecordFragment;

type Column = 'status' | 'productVariant' | 'product' | 'catalog' | 'provider';

export function ProductOrdersTable(props: ProductOrdersTableProps) {
  const { className, filter, data: propData, ...rest } = props;

  const { t } = useTranslation('productOrders');

  const [mutation] = useProductOrderRetryMutation();

  const query = useProductOrdersTableQuery({
    skip: !!rest.query,
    variables: {
      filter,
      skip: 0,
      take: 10,
    },
  });

  let data: ProductOrdersTablePaginationFragment | null = null;

  if (propData) data = propData as ProductOrdersTablePaginationFragment;
  else if (query.data?.productOrdersPagination)
    data = query.data.productOrdersPagination;

  if (!data) {
    return <Spin size="large" className={styles.spinner} />;
  }

  const handleRetry = async (variables: ProductOrderRetryMutationVariables) => {
    try {
      await mutation({ variables });
    } catch (err) {
      console.log(err);
    }
    await query.refetch();
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
    },
    {
      key: 'productVariant',
      title: t('table.headers.productVariant'),
      dataIndex: ['productVariant', 'name'],
    },
    {
      key: 'product',
      title: t('table.headers.product'),
      dataIndex: ['product', 'name'],
    },
    {
      key: 'catalog',
      title: t('table.headers.catalog'),
      dataIndex: ['catalog', 'name'],
    },
    {
      key: 'provider',
      title: t('table.headers.provider'),
      dataIndex: ['provider', 'name'],
    },
  ];

  return (
    <div>
      <TablePagination
        id="ProductOrdersTable"
        query={query}
        columns={columns}
        data={data}
        {...rest}
        expandable={{
          defaultExpandAllRows: false,
          rowExpandable: (record) => record.eventsPagination.totalCount > 0,
          expandedRowRender: (record) => (
            <ProductOrderEvents events={record.eventsPagination} />
          ),
        }}
        action={(node) =>
          node.isRetryable && (
            <Button
              type="dashed"
              onClick={() => handleRetry({ productOrderId: node.id })}
            >
              {t('table.actions.retry')}
            </Button>
          )
        }
      />
    </div>
  );
}

export type ProductOrdersTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: ProductOrdersTableQueryVariables['filter'];
};
