import { useEffect, useState } from 'react';
import { Button, message, Select, Table } from 'antd';
import { noop } from 'lodash';

import { Modal } from '@components/organisms/Modal/Modal';

import {
  useAppdragMigratePerksIntoCampaignMutation,
  useAppdragPerksImportModalCampaignsQuery,
  useAppdragPerksImportModalPerksQuery,
} from './AppdragPerksImportModal.generated';

const properties = ['id', 'titreOffre', 'format', 'actif', 'code_unique'];

export const AppdragPerksImportModal = (
  props: AppdragPerksImportModalProps,
) => {
  const { brandId, isOpen, onClose = noop } = props;
  const [perkIds, setPerkIds] = useState([] as number[]);
  const [campaignId, setCampaignId] = useState<string | null>(null);

  const [mutation, mutationState] = useAppdragMigratePerksIntoCampaignMutation({
    refetchQueries: ['BrandIdOffersView'],
    awaitRefetchQueries: true,
  });

  const brandQuery = useAppdragPerksImportModalCampaignsQuery({
    variables: {
      brandId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.brand?.campaignPagination.nodes.length === 1) {
        setCampaignId(data.brand.campaignPagination.nodes[0].id);
      }
    },
  });

  useEffect(() => {
    brandQuery.refetch();
  }, [isOpen]);

  const perksQuery = useAppdragPerksImportModalPerksQuery({
    skip: !brandQuery.data?.brand,
    variables: {
      fournisseurId: brandQuery.data?.brand?.appdragId as number,
    },
  });

  const handleClose = () => {
    setPerkIds([]);
    onClose();
  };

  const handleSubmit = async () => {
    if (!campaignId || !perkIds.length) return;

    try {
      await mutation({
        variables: {
          campaignId,
          perkIds,
        },
      });

      handleClose();
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    }
  };

  const brand = brandQuery.data?.brand;
  const campaigns = brand?.campaignPagination.nodes || [];
  const perks = perksQuery.data?.appdragPerks || [];

  const disabledPerkIds =
    brand?.offersPagination.nodes.map((o) => o.appdragId).filter(Boolean) || [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      width="100%"
      title="Import de perks depuis Appdrag"
      onSubmit={handleSubmit}
      footer={
        <>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={handleSubmit}
            disabled={perkIds.length === 0}
            loading={mutationState.loading}
            type="primary"
          >
            Importer
          </Button>
        </>
      }
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Sélectionner une campagne où importer les offres"
        loading={brandQuery.loading}
        disabled={campaigns.length === 1}
        onChange={(v) => setCampaignId(v?.toString() || null)}
        value={campaignId || undefined}
        options={campaigns.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
      />
      {!!campaignId && !!brand && (
        <Table
          dataSource={perks}
          rowKey="id"
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: perkIds,
            onChange: (keys) =>
              setPerkIds(keys.map((k) => parseInt(k.toString()))),
            preserveSelectedRowKeys: true,
            getCheckboxProps: (record) => ({
              disabled: disabledPerkIds.includes(record.id),
            }),
          }}
          columns={properties.map((p) => ({
            key: p,
            dataIndex: p,
            title: p,
          }))}
        />
      )}
    </Modal>
  );
};

export type AppdragPerksImportModalProps = {
  brandId: string;
  onClose?: () => void;
  isOpen: boolean;
};
