import { message } from 'antd';
import * as Yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { Form, FormProps, useForm } from '@components/organisms/Form/Form';

import { useOrderItemArticlesAddFormMutation } from './OrderItemArticlesAddForm.generated';

export type OrderItemArticlesAddFormProps = {
  orderItemId: string;
  onSubmit?: () => void;
};

export type OrderItemArticlesAddFormValues = {
  attachments: AttachmentItemFragment[];
};

export const OrderItemArticlesAddForm = (
  props: OrderItemArticlesAddFormProps,
) => {
  const { orderItemId, onSubmit } = props;
  const validationSchema = Yup.object({
    attachments: Yup.mixed<AttachmentItemFragment[]>().optional(),
  }).required();

  const [mutation] = useOrderItemArticlesAddFormMutation();

  const form = useForm<OrderItemArticlesAddFormValues>({
    validationSchema,
  });

  const handleValid: FormProps<OrderItemArticlesAddFormValues>['onValid'] =
    async (values) => {
      try {
        await mutation({
          variables: {
            orderItemId,
            input: {
              fileIds: values.attachments.map((a) => a.id),
            },
          },
        });
        onSubmit?.();
      } catch (err) {
        message.error((err as Error).message);
      }
    };

  return (
    <Form onValid={handleValid} form={form} id="OrderItemArticlesAddForm">
      <FormItem name="attachments">
        <FormInputMultipleUpload
          control={form.control}
          name="attachments"
          type={FileType.ProductArticle}
        />
      </FormItem>
    </Form>
  );
};
