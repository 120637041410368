import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { ProductTranslationForm } from '@forms/ProductTranslationForm/ProductTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './ProductIdTranslationsView.module.css';

import { useProductIdTranslationsViewQuery } from './ProductIdTranslationsView.generated';

export const ProductIdTranslationsView: React.FC<ProductIdTranslationsViewProps> =
  (props) => {
    const { className, productId } = props;
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
      undefined,
    );

    const localesQuery = useLocales();

    const query = useProductIdTranslationsViewQuery({
      variables: { productId },
      notifyOnNetworkStatusChange: true,
    });

    const product = query?.data?.product;
    const locales = localesQuery?.data?.localesPagination.nodes;

    if ((localesQuery.loading || query.loading) && !product) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!product) {
      return <NotFoundView />;
    } else if (!locales) {
      return <ServerErrorView />;
    }

    const { viewer } = useAuth();

    const userLanguage = viewer?.preferences?.properties.language || 'en';

    return (
      <div className={classnames(className, styles.root)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          defaultActiveKey={userLanguage}
        >
          {product?.translationsPagination.nodes.map((node) => {
            const locale = locales.find((locale) => locale.id === node.locale);

            return (
              <Tabs.TabPane
                tab={locale ? `${locale.name} (${locale.id})` : node.locale}
                key={node.locale}
              >
                <ProductTranslationForm
                  productId={product.id}
                  translation={node}
                  onDeleted={({ product }) => setActiveTabKey(product.locale)}
                />
              </Tabs.TabPane>
            );
          })}
          {product.translationsPagination.totalCount < locales.length && (
            <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
              <ProductTranslationForm
                onCreated={({ productTranslation }) =>
                  setActiveTabKey(productTranslation.locale)
                }
                localeFilter={{
                  locale: {
                    exclude: product.translationsPagination.nodes.map(
                      (node) => node.locale,
                    ),
                  },
                }}
                productId={product.id}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };

export type ProductIdTranslationsViewProps = {
  className?: string;
  productId: string;
};
