import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { AdminCommentTargetType, Scalars } from '@graphql/generated/types';

import { FormRichTextEditor } from '@components/atoms/FormRichTextEditor/FormRichTextEditor';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './AdminCommentForm.module.css';

import {
  AdminCommentFormCreateMutation,
  AdminCommentFormCreateMutationVariables,
  AdminCommentFormFragment,
  AdminCommentFormUpdateMutationVariables,
  useAdminCommentFormCreateMutation,
  useAdminCommentFormUpdateMutation,
} from './AdminCommentForm.generated';

export type AdminCommentFormValues = {
  contentDelta: Scalars['Delta'];
};

export function AdminCommentForm(props: AdminCommentFormProps) {
  const {
    className,
    defaultValues,
    hideFooter,
    targetId,
    targetType,
    id,
    ...rest
  } = props;
  const { t } = useTranslation('adminComments');

  const validationSchema = Yup.object({
    contentDelta: Yup.object().required(),
  }).required();

  const form = useForm<AdminCommentFormValues>({
    validationSchema,
    defaultValues,
  });

  const [createMutation] = useAdminCommentFormCreateMutation();
  const [updateMutation] = useAdminCommentFormUpdateMutation();

  const handleCreate = (values: AdminCommentFormValues) => {
    if (!targetId || !targetType) return;

    const variables: AdminCommentFormCreateMutationVariables = {
      targetId,
      targetType,
      input: {
        content: values.contentDelta,
      },
    };

    return createMutation({ variables });
  };

  const handleUpdate = (values: AdminCommentFormValues) => {
    if (!defaultValues?.id) return;

    const variables: AdminCommentFormUpdateMutationVariables = {
      adminCommentId: defaultValues.id,
      input: {
        content: values.contentDelta,
      },
    };

    return updateMutation({ variables });
  };

  const handleSubmit: FormProps<AdminCommentFormValues>['onValid'] = async (
    values,
  ) => {
    try {
      if (defaultValues?.id) {
        await handleUpdate(values);
      } else {
        await handleCreate(values);
      }

      form.reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id={id || ''}
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem className={styles.content} required name="contentDelta">
          <FormRichTextEditor
            className={styles.contentInput}
            name="contentDelta"
          />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('form.submits.create.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type AdminCommentFormProps = ChildFormProps<
  AdminCommentFormValues,
  AdminCommentFormCreateMutation
> & {
  className?: string;
  defaultValues?: AdminCommentFormFragment;
  hideFooter?: boolean;
  targetId?: string;
  targetType?: AdminCommentTargetType;
  id?: string;
};
