import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';

import { OrderItemListFragment } from './OrderProductArticleDownload.generated';

export const OrderProductArticleDownload = (
  props: OrderProductArticleDownloadProps,
) => {
  const { orderItemsPagination } = props;

  if (orderItemsPagination.totalCount === 0) {
    return null;
  }

  const productArticles = orderItemsPagination.nodes.flatMap(
    (orderItem) => orderItem.productArticlePagination.nodes,
  );

  if (productArticles.length === 0) return null;

  const items = productArticles
    .filter((article) => article.url || article.file?.url)
    .map((article, i: number) => ({
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={article.url ? article.url.href : article.file?.url}
        >
          Billet {i + 1}
        </a>
      ),
      key: `${i + 1}`,
    }));

  if (items.length === 0) return null;
  return (
    <Dropdown menu={{ items }}>
      <Button>
        <Space>
          Download
          <DownloadOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export type OrderProductArticleDownloadProps = {
  orderItemsPagination: OrderItemListFragment;
  className?: string;
};
