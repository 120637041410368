import { match, Redirect, Route, Switch, useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { Tabs, TabsProps } from 'antd';
import classnames from 'classnames';

import styles from './TabsRouter.module.css';

import { TabsRouterRoute, TabsRouterRouteProps } from './TabsRouterRoute';

const SentryRoute = Sentry.withSentryRouting(Route);

export const TabsRouter = (props: TabsRouterProps) => {
  const {
    replace,
    match: parentMatch,
    children,
    className,
    navClassName,
    ...rest
  } = props;
  const history = useHistory();

  const handleTabClick = (key: string) => {
    const method = replace ? 'replace' : 'push';

    if (key !== 'null') {
      return history[method](`${parentMatch.url}/${key}`);
    } else {
      return history[method](parentMatch.url);
    }
  };

  const generateTabKey = (path: string) => {
    if (path === parentMatch.url) {
      return undefined;
    } else {
      return path.slice(parentMatch.url.length + 1);
    }
  };

  const routes = children.filter(Boolean) as React.ReactElement<
    TabsRouterRouteProps,
    typeof TabsRouterRoute
  >[];

  return (
    <div
      className={classnames(styles.root, className, {
        [styles['-mode-horizontal']]: !!['left', 'right'].includes(
          rest.tabPosition || '',
        ),
        [styles['-mode-vertical']]:
          !rest.tabPosition || !!['top', 'bottom'].includes(rest.tabPosition),
      })}
    >
      <SentryRoute path={`${parentMatch.url}/:tab?`}>
        {({ match }) => (
          <Tabs
            className={classnames(styles.tabs, navClassName)}
            activeKey={match!.params.tab}
            onTabClick={handleTabClick}
            {...rest}
          >
            {routes.map((child) => {
              return (
                <Tabs.TabPane
                  disabled={child.props.disabled}
                  key={generateTabKey(child.props.path) || null}
                  tab={child.props.label}
                />
              );
            })}
          </Tabs>
        )}
      </SentryRoute>
      <div className={styles.content}>
        <Switch>
          {routes
            .filter((r) => !r.props.disabled)
            .map((child) => {
              return <SentryRoute key={child.props.path} {...child.props} />;
            })}
          <SentryRoute>
            <Redirect to={routes[0].props.path} />
          </SentryRoute>
        </Switch>
      </div>
    </div>
  );
};

TabsRouter.Route = TabsRouterRoute;

export type TabsRouterProps = {
  match: match;
  replace?: boolean;
  children: (
    | React.ReactElement<TabsRouterRouteProps, typeof TabsRouterRoute>
    | false
  )[];
  className?: string;
  navClassName?: string;
} & TabsProps;
