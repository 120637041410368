import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Space } from 'antd';

import {
  SubventionReimbursementRequest,
  SubventionReimbursementRequestStatus,
} from '@graphql/generated/types';

import { SubventionReimbursementRejectModal } from '@components/modals/SubventionReimbursementRejectModal/SubventionReimbursementRejectModal';
import { SubventionReimbursementsTable } from '@components/tables/SubventionReimbursementsTable/SubventionReimbursementsTable';

import {
  useSubventionReimbursementListViewRequestApproveMutation,
  useSubventionReimbursementsListViewQuery,
} from './SubventionReimbursementListView.generated';

export const SubventionReimbursementListView = () => {
  const query = useSubventionReimbursementsListViewQuery({
    variables: {
      take: 10,
      skip: 0,
    },
  });

  const [
    subventionReimbursementRequestId,
    setSubventionReimbursementRequestId,
  ] = useState<SubventionReimbursementRequest['id'] | null>(null);

  const { t } = useTranslation(['subventionReimbursements', 'common']);

  const [subventionReimbursementRequestApprove] =
    useSubventionReimbursementListViewRequestApproveMutation();

  const handleApprove =
    (subventionReimbursementRequestId: SubventionReimbursementRequest['id']) =>
    async () => {
      await subventionReimbursementRequestApprove({
        variables: { subventionReimbursementRequestId },
      });

      await query.refetch();
    };

  const displayRejectModal = (
    subventionReimbursementRequestId: SubventionReimbursementRequest['id'],
  ) => {
    setSubventionReimbursementRequestId(subventionReimbursementRequestId);
  };

  return (
    <div>
      <SubventionReimbursementsTable
        activeColumns={[
          'id',
          'company',
          'user',
          'subvention',
          'invoiceDate',
          'totalAmount',
          'reimbursementAmount',
          'proofs',
          'status',
        ]}
        action={(record) => {
          if (
            record.status ===
            SubventionReimbursementRequestStatus.WaitingForApproval
          ) {
            return (
              <Space direction="vertical">
                <Popconfirm
                  title={t(
                    'subventionReimbursements:table.headers.action.approveConfirm',
                  )}
                  okText={t('common:BooleanTag.true')}
                  cancelText={t('common:BooleanTag.false')}
                  onConfirm={handleApprove(record.id)}
                >
                  <Button type="primary" color="#">
                    {t('subventionReimbursements:table.headers.action.approve')}
                  </Button>
                </Popconfirm>

                <Button
                  type="primary"
                  danger
                  onClick={() => displayRejectModal(record.id)}
                >
                  {t('subventionReimbursements:table.headers.action.reject')}
                </Button>
              </Space>
            );
          }
        }}
        query={query}
        data={query.data?.subventionReimbursementRequestPagination || null}
      />
      {subventionReimbursementRequestId && (
        <SubventionReimbursementRejectModal
          key={subventionReimbursementRequestId}
          subventionReimbursementRequestId={subventionReimbursementRequestId}
          isOpen={!!subventionReimbursementRequestId}
          onClose={() => {
            setSubventionReimbursementRequestId(null);
          }}
        />
      )}
    </div>
  );
};
