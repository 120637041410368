import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { OffersTable } from '@tables/OffersTable/OffersTable';

import styles from '../Redbin.module.css';

import { useRedBinOffersWithoutLocalizationQuery } from './OffersWithoutLocalization.generated';

export const OffersWithoutLocalization: React.FC<OffersWithoutLocalizationProps> =
  (props) => {
    const { className } = props;

    const { t } = useTranslation('redbin');

    const query = useRedBinOffersWithoutLocalizationQuery({
      variables: { take: 10, skip: 0 },
    });

    return (
      <div className={classnames(className, styles.root)}>
        <PageHeader
          ghost={false}
          title={t('offersWithoutLocalization.title')}
        />
        <OffersTable
          query={query}
          data={query.data?.redBinOffersWithoutLocalization}
          activeColumns={[
            'name',
            'type',
            'status',
            'brand',
            'campaign',
            'startsAt',
            'endsAt',
            'createdAt',
            'updatedAt',
          ]}
        />
      </div>
    );
  };

export type OffersWithoutLocalizationProps = {
  className?: string;
};
