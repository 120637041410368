/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, message, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import * as Yup from 'yup';

import { MangopayKycDocumentType } from '@graphql/generated/types';

import { Card } from '@components/atoms/Card/Card';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import styles from './MangopayKycDocumentWithPagesCreateForm.module.css';

import {
  MangopayKycDocumentWithPagesCreateFormMutation,
  MangopayKycDocumentWithPagesCreateFormMutationVariables,
  useMangopayKycDocumentWithPagesCreateFormMutation,
} from './MangopayKycDocumentWithPagesCreateForm.generated';

export type MangopayKycDocumentWithPagesCreateFormValues = {
  type: string;
  pages: {
    uid: string;
    file: string;
  }[];
};

export type MangopayKycDocumentWithPagesCreateFormProps = ChildFormProps<
  MangopayKycDocumentWithPagesCreateFormValues,
  MangopayKycDocumentWithPagesCreateFormMutation
> & {
  mangopayLegalUserId: string;
  className?: string;
  hideFooter?: boolean;
};

export function MangopayKycDocumentWithPagesCreateForm(
  props: MangopayKycDocumentWithPagesCreateFormProps,
) {
  const { className, hideFooter, mangopayLegalUserId, ...rest } = props;

  const validationSchema = Yup.object({
    type: Yup.string().required(),
    pages: Yup.array(
      Yup.object({
        uid: Yup.string().required(),
        file: Yup.string().required(),
      }),
    ).min(1),
  }).required();

  const form = useForm<MangopayKycDocumentWithPagesCreateFormValues>({
    validationSchema,
    defaultValues: { pages: [] },
  });

  const [mutation] = useMangopayKycDocumentWithPagesCreateFormMutation();

  const handleSubmit: FormProps<MangopayKycDocumentWithPagesCreateFormValues>['onValid'] =
    async (values) => {
      const variables: MangopayKycDocumentWithPagesCreateFormMutationVariables =
        {
          mangopayLegalUserId,
          type: values.type as MangopayKycDocumentType,
          pages: values.pages.map((page) => ({ file: page.file })),
        };

      try {
        const { data } = await mutation({ variables });
        return data;
      } catch (e: any) {
        message.error(e.message);
        throw new Error(e.message);
      }
    };

  const handleBeforeUpload = (file: File & { uid: string }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Content = (e?.target?.result as string).split(',')[1];
      const size = new TextEncoder().encode(base64Content).length;
      const values = form.getValues();
      const documentType = values.type as MangopayKycDocumentType;
      if (
        documentType === MangopayKycDocumentType.IdentityProof &&
        size < (32_000 * 4) / 3
      ) {
        message.error('File must be greater than 32KB!');
      } else if (size <= 10_000_000) {
        form.setValue('pages', [
          { uid: file.uid, file: base64Content },
          ...values.pages,
        ]);
      } else {
        message.error('File must be smaller than 10MB!');
      }
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleOnRemove = (file: UploadFile) => {
    const values = form.getValues();
    form.setValue(
      'pages',
      values.pages.filter((page) => page.uid !== file.uid),
    );
  };

  return (
    <Card className={classNames(className, styles.root)}>
      <Form
        id="MangopayKycDocumentWithPagesCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.translationName}
          required
          label="type"
          name="type"
        >
          <FormSelect
            options={[
              {
                value: 'IDENTITY_PROOF',
                label: 'IDENTITY_PROOF',
              },
              {
                value: 'REGISTRATION_PROOF',
                label: 'REGISTRATION_PROOF',
              },
              {
                value: 'ARTICLES_OF_ASSOCIATION',
                label: 'ARTICLES_OF_ASSOCIATION',
              },
              {
                value: 'SHAREHOLDER_DECLARATION',
                label: 'SHAREHOLDER_DECLARATION',
              },
              {
                value: 'ADDRESS_PROOF',
                label: 'ADDRESS_PROOF',
              },
            ]}
            name="type"
          />
        </FormItem>
        <FormItem name="pages" label="pages">
          <Upload beforeUpload={handleBeforeUpload} onRemove={handleOnRemove}>
            <Button>Select file</Button>
          </Upload>
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Créer le KYC Document
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}
