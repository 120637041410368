import classnames from 'classnames';

import { ProductCatalogsTable } from '@tables/ProductCatalogsTable/ProductCatalogsTable';

import styles from './ProductCatalogsView.module.css';

export const ProductCatalogsView: React.FC<ProductCatalogsViewProps> = (
  props,
) => {
  const { className } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <ProductCatalogsTable
        activeColumns={[
          'name',
          'identifier',
          'provider',
          'productCount',
          'synchronizationCount',
        ]}
      />
    </div>
  );
};

export type ProductCatalogsViewProps = {
  className?: string;
};
