import { useTranslation } from 'react-i18next';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { PageHeader } from '@molecules/PageHeader/PageHeader';

import {
  ArticleCategoryHeaderFragment,
  useArticleCategoryHeaderUpdateMutation,
} from './ArticleCategoryHeader.generated';

export const ArticleCategoryHeader: React.FC<ArticleCategoryHeaderProps> = (
  props,
) => {
  const { className, articleCategory } = props;
  const { t } = useTranslation('common');

  const [mutation] = useArticleCategoryHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        articleCategoryId: articleCategory.id,
        input: {
          coverId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      cover={articleCategory.cover}
      logoProps={{ mode: 'contain' }}
      className={className}
      showNav
      uploadCoverProps={{
        fileType: FileType.ArticleCategoryCover,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Couverture',
        picture: articleCategory.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
        }),
      }}
    >
      {articleCategory.name}
    </PageHeader>
  );
};

export type ArticleCategoryHeaderProps = {
  className?: string;
  articleCategory: ArticleCategoryHeaderFragment;
};
