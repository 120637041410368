import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './IdentityProviderTypeTag.module.css';

export const IdentityProviderTypeTag: React.FC<IdentityProviderTypeTagProps> = (
  props,
) => {
  const { className, type } = props;
  const { t } = useTranslation('identityProviders');

  const options: Record<
    string,
    {
      label: string;
      color: string;
    }
  > = {
    SSOProviderOIDC: { label: t('type.oidc'), color: 'default' },
    SSOProviderSAML: { label: t('type.saml'), color: 'default' },
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={options[type].color}
    >
      {options[type].label}
    </Tag>
  );
};

export type IdentityProviderTypeTagProps = {
  className?: string;
  type: string;
};
