import cx from 'classnames';

import { BrandsTable } from '@components/tables/BrandsTable/BrandsTable';
import { CampaignsTable } from '@components/tables/CampaignsTable/CampaignsTable';
import { OffersTable } from '@components/tables/OffersTable/OffersTable';
import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';
import { ProductVariantsTable } from '@components/tables/ProductVariantsTable/ProductVariantsTable';

import styles from './ProductSynchronizationIdCreatedView.module.css';

import {
  useProductSynchronizationIdCreatedBrandsViewQuery,
  useProductSynchronizationIdCreatedCampaignsViewQuery,
  useProductSynchronizationIdCreatedOffersViewQuery,
  useProductSynchronizationIdCreatedProductsViewQuery,
  useProductSynchronizationIdCreatedProductVariantsViewQuery,
} from './ProductSynchronizationIdCreatedView.generated';

export const ProductSynchronizationIdCreatedView: React.FC<ProductSynchronizationIdCreatedViewProps> =
  (props) => {
    const {
      className,
      productSynchronizationId,
      showBrands = false,
      showCampaigns = false,
      showOffers = false,
      showProducts = false,
      showProductVariants = false,
    } = props;

    const brandsQuery = useProductSynchronizationIdCreatedBrandsViewQuery({
      skip: showBrands === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const campaignsQuery = useProductSynchronizationIdCreatedCampaignsViewQuery(
      {
        skip: showCampaigns === false,
        variables: { productSynchronizationId, take: 20, skip: 0 },
      },
    );
    const offersQuery = useProductSynchronizationIdCreatedOffersViewQuery({
      skip: showOffers === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productsQuery = useProductSynchronizationIdCreatedProductsViewQuery({
      skip: showProducts === false,
      variables: { productSynchronizationId, take: 20, skip: 0 },
    });
    const productVariantsQuery =
      useProductSynchronizationIdCreatedProductVariantsViewQuery({
        skip: showProductVariants === false,
        variables: { productSynchronizationId, take: 20, skip: 0 },
      });

    return (
      <div className={cx(className, styles.root)}>
        {showBrands && (
          <BrandsTable
            title={() => <div>Marques</div>}
            activeColumns={['name']}
            data={
              brandsQuery.data?.productSynchronization?.brandsCreatedPagination
            }
            query={brandsQuery}
          />
        )}
        {showCampaigns && (
          <CampaignsTable
            title={() => <div>Campagnes</div>}
            activeColumns={['name', 'status', 'startsAt', 'expiresAt']}
            data={
              campaignsQuery.data?.productSynchronization
                ?.createdCampaignPagination
            }
            query={campaignsQuery}
          />
        )}
        {showOffers && (
          <OffersTable
            title={() => <div>Offres</div>}
            activeColumns={['name', 'status', 'startsAt', 'endsAt']}
            data={
              offersQuery.data?.productSynchronization?.offersCreatedPagination
            }
            query={offersQuery}
          />
        )}
        {showProducts && (
          <ProductsTable
            title={() => <div>Produits</div>}
            activeColumns={['name', 'brand']}
            data={
              productsQuery.data?.productSynchronization
                ?.productsCreatedPagination
            }
            query={productsQuery}
          />
        )}
        {showProductVariants && (
          <ProductVariantsTable
            title={() => <div>Variations de produit</div>}
            activeColumns={[
              'product',
              'SKU',
              'priceWithTaxes',
              'inventoryQuantity',
            ]}
            data={
              productVariantsQuery.data?.productSynchronization
                ?.productVariantsCreatedPagination
            }
            query={productVariantsQuery}
          />
        )}
      </div>
    );
  };

export type ProductSynchronizationIdCreatedViewProps = {
  className?: string;
  productSynchronizationId: string;
  showBrands?: boolean;
  showCampaigns?: boolean;
  showOffers?: boolean;
  showProducts?: boolean;
  showProductVariants?: boolean;
};
