import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Offer } from '@graphql/generated/types';

import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { FormSelectCurrency } from '@components/atoms/FormSelectCurrency/FormSelectCurrency';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferVoucherCreateForm.module.css';

import {
  OfferVoucherCreateFormMutation,
  OfferVoucherCreateFormMutationVariables,
  useOfferVoucherCreateFormMutation,
} from './OfferVoucherCreateForm.generated';

enum VoucherType {
  VoucherFlatDiscount = 'VoucherFlatDiscount',
  VoucherPercentDiscount = 'VoucherPercentDiscount',
  VoucherReward = 'VoucherReward',
}

export type OfferVoucherCreateFormValues = {
  type: VoucherType;
  amount?: number;
  quantity?: number;
  reward?: string;
  currency?: string;
};

export function OfferVoucherCreateForm(props: OfferVoucherCreateFormProps) {
  const { className, defaultValues, offerId, ...rest } = props;
  const { t } = useTranslation('voucher');

  const typesTranslations = {
    [VoucherType.VoucherFlatDiscount]: t('fields.types.VoucherFlatDiscount'),
    [VoucherType.VoucherPercentDiscount]: t(
      'fields.types.VoucherPercentDiscount',
    ),
    [VoucherType.VoucherReward]: t('fields.types.VoucherReward'),
  };

  const [voucherType, setVoucherType] = useState<VoucherType | null>(null);

  const validationSchema = Yup.object({
    type: Yup.mixed<VoucherType>().oneOf(Object.values(VoucherType)).required(),
    amount: Yup.number().when('type', {
      is: (val: VoucherType) =>
        val === VoucherType.VoucherFlatDiscount ||
        val === VoucherType.VoucherPercentDiscount,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    currency: Yup.string().when('type', {
      is: VoucherType.VoucherFlatDiscount,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    quantity: Yup.number().when('type', {
      is: VoucherType.VoucherReward,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    reward: Yup.string().when('type', {
      is: VoucherType.VoucherReward,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  }).required();

  const form = useForm<OfferVoucherCreateFormValues>({
    validationSchema,
    defaultValues,
  });

  const [mutation] = useOfferVoucherCreateFormMutation();

  const handleSubmit: FormProps<OfferVoucherCreateFormValues>['onValid'] =
    async (values) => {
      const variables: OfferVoucherCreateFormMutationVariables = {
        offerId,
        input: {
          type: values.type,
          amount: values.amount,
          currency: values.currency,
          quantity: values.quantity,
          reward: values.reward,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferVoucherCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.type}
          label={t('fields.type')}
          name="type"
          required
        >
          <FormSelect
            filterOption={false}
            options={Object.values(VoucherType).map((type) => ({
              label: typesTranslations[type],
              value: type,
            }))}
            name="type"
            onSelect={(type) =>
              setVoucherType(type ? (type as VoucherType) : null)
            }
          />
        </FormItem>

        {voucherType === VoucherType.VoucherFlatDiscount && (
          <>
            <FormItem
              required
              className={styles.amount}
              label={t('fields.amountFlat')}
              name="amount"
            >
              <FormInputNumber name="amount" />
            </FormItem>
            <FormItem
              required
              className={styles.currency}
              label={t('fields.currency')}
              name="currency"
            >
              <FormSelectCurrency name="currency" />
            </FormItem>
          </>
        )}

        {voucherType === VoucherType.VoucherPercentDiscount && (
          <FormItem
            required
            className={styles.amount}
            label={t('fields.amountPercent')}
            name="amount"
          >
            <FormInputNumber name="amount" />
          </FormItem>
        )}

        {voucherType === VoucherType.VoucherReward && (
          <>
            <FormItem
              required
              className={styles.quantity}
              label={t('fields.quantity')}
              name="quantity"
            >
              <FormInputNumber name="quantity" />
            </FormItem>

            <FormItem
              required
              className={styles.reward}
              label={t('fields.reward')}
              name="reward"
            >
              <FormInput name="reward" />
            </FormItem>
          </>
        )}
      </Form>
    </Card>
  );
}

export type OfferVoucherCreateFormProps = ChildFormProps<
  OfferVoucherCreateFormValues,
  OfferVoucherCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<OfferVoucherCreateFormValues>;
  offerId: Offer['id'];
};
