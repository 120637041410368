import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import classnames from 'classnames';

import styles from './Avatar.module.css';

import { AvatarFragment } from './Avatar.generated';

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, avatar, placeholder, ...rest } = props;

  return (
    <AntAvatar
      {...rest}
      className={classnames(className, styles.root)}
      src={avatar?.file.url}
    >
      {placeholder}
    </AntAvatar>
  );
};

export type AvatarProps = Omit<AntAvatarProps, 'className' | 'src'> & {
  className?: string;
  avatar?: AvatarFragment | null;
  placeholder?: string;
};
