import { noop } from 'lodash';

import {
  ApiKeyForm,
  ApiKeyFormProps,
} from '@components/forms/ApiKeyForm/ApiKeyForm';
import { Modal } from '@components/organisms/Modal/Modal';

export type ApiKeyModalProps = ApiKeyFormProps & {
  isOpen?: boolean;
  onClose?: () => void;
};

export const ApiKeyModal = (props: ApiKeyModalProps) => {
  const { isOpen = false, onClose = noop, ...rest } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={rest.apiKey ? 'Modifier une clé API' : 'Ajouter une clé API'}
      form="ApiKeyForm"
    >
      <ApiKeyForm {...rest} />
    </Modal>
  );
};
