import classnames from 'classnames';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

import styles from './FormSelectProductProvider.module.css';

import {
  FormSelectProductProviderQueryVariables,
  useFormSelectProductProviderQuery,
} from './FormSelectProductProvider.generated';

export const FormSelectProductProvider: React.FC<FormSelectProductProviderProps> =
  (props) => {
    const { className, filter, ...rest } = props;

    const { data, loading } = useFormSelectProductProviderQuery({
      variables: {
        filter,
      },
      notifyOnNetworkStatusChange: true,
    });

    return (
      <FormSelect
        className={classnames(className, styles.root)}
        loading={loading}
        filterOption={false}
        options={data?.productProviderPagination.nodes.map((node) => ({
          value: node.id,
          label: node.name,
        }))}
        {...rest}
      />
    );
  };

export type FormSelectProductProviderProps = FormSelectProps & {
  filter?: FormSelectProductProviderQueryVariables['filter'];
};
