import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import classNames from 'classnames';

import { HighlightTargetType } from '@graphql/generated/types';

import { ArticleCard } from '@components/molecules/ArticleCard/ArticleCard';
import { PublicationCard } from '@components/molecules/PublicationCard/PublicationCard';
import { ArticleItemContentFragment } from '@components/views/Communication/Articles/ArticleIdView/ArticleIdView.generated';
import { PublicationItemContentFragment } from '@components/views/Communication/Publications/PublicationIdView/PublicationIdView.generated';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './HighlightEditor.module.css';

import {
  HighlightItemFragment,
  useHighlightEditorDeleteMutation,
  useHighlightEditorPositionUpdateMutation,
  useHighlightsEditorQuery,
} from './HighlightEditor.generated';

export const HighlightEditor = () => {
  const nbToTake = 6;

  const { data, loading, refetch } = useHighlightsEditorQuery({
    variables: {
      take: nbToTake,
      skip: 0,
      filter: {
        targetType: {
          in: [HighlightTargetType.Article, HighlightTargetType.Publication],
        },
        company: {
          id: {
            is: null,
          },
        },
      },
    },
    onCompleted: (data) => setNodes(data?.highlightPagination?.nodes || []),
  });

  const [nodes, setNodes] = useState(data?.highlightPagination?.nodes || []);

  const [updatePositionMutation] = useHighlightEditorPositionUpdateMutation();
  const [deleteMutation] = useHighlightEditorDeleteMutation();

  const sensors = useSensors(useSensor(PointerSensor));

  if (loading && !data?.highlightPagination) {
    return <Spin />;
  } else if (!data?.highlightPagination) {
    return <NotFoundView />;
  }

  const handleDragEnd = async (evt: DragEndEvent) => {
    const { active, over } = evt;

    if (!over || active.id === over.id) return;

    const oldIndex = nodes.findIndex((n) => n.id === active.id);
    const newIndex = nodes.findIndex((n) => n.id === over.id);

    setNodes((nodes) => arrayMove(nodes, oldIndex, newIndex));

    try {
      await updatePositionMutation({
        variables: {
          highlightId: active.id,
          input: {
            position: newIndex,
          },
        },
      });
    } catch (err) {
      setNodes((nodes) => arrayMove(nodes, newIndex, oldIndex));
    }
  };

  const handleDelete = async (highlightId: string) => {
    await deleteMutation({
      variables: {
        highlightId,
      },
    });

    await refetch();
  };

  return (
    <div>
      <Typography.Title level={3}>Contenu à la une</Typography.Title>
      <div className={styles.cards}>
        <div className={styles.cards__wrapper}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={nodes}
              strategy={horizontalListSortingStrategy}
            >
              {nodes.map((node) => (
                <HighlightCardSortable
                  highlight={node as HighlightItemFragment}
                  sortableId={node.id}
                  key={node.id}
                  onDelete={() => handleDelete(node.id)}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  );
};

export type HighlightEditorProps = {
  title: string;
};

const HighlightCardSortable = (props: HighlightCardSortableProps) => {
  const { highlight, sortableId, onDelete, ...rest } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: sortableId || highlight.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.card, isDragging && styles['-isDragging'])}
      style={style}
    >
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        {HighlightTargetType[highlight.target.__typename] ===
          HighlightTargetType.Article && (
          <ArticleCard
            article={highlight.target as ArticleItemContentFragment}
            {...rest}
          />
        )}
        {HighlightTargetType[highlight.target.__typename] ===
          HighlightTargetType.Publication && (
          <PublicationCard
            publication={highlight.target as PublicationItemContentFragment}
            {...rest}
          />
        )}
      </div>
      <Popconfirm
        title="Êtes-vous sûr de vouloir retirer ce contenu"
        okText="Supprimer"
        okType="danger"
        onConfirm={handleDelete}
      >
        <Button
          type="primary"
          shape="circle"
          danger
          className={styles.deleteButton}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  );
};

export type HighlightCardSortableProps = {
  highlight: HighlightItemFragment;
  sortableId: string;
  onDelete: () => void;
};
