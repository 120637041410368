import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { ArticleAccessMode, FileType } from '@graphql/generated/types';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { useAttachmentUpdate } from '@hooks/attachmentUpdate/attachmentUpdate.hooks';
import { FormSelectArticleAccessMode } from '@components/atoms/FormSelectArticleAccessMode/FormSelectArticleAccessMode';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { FormSelectArticleCategory } from '@components/molecules/FormSelectArticleCategory/FormSelectArticleCategory';
import { CoverLibrarySelect } from '@components/organisms/CoverLibrarySelect/CoverLibrarySelect';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ArticleUpdateForm.module.css';

import {
  ArticleUpdateFormFragment,
  ArticleUpdateFormMutation,
  ArticleUpdateFormMutationVariables,
  useArticleUpdateFormMutation,
} from './ArticleUpdateForm.generated';

export type ArticleUpdateFormValues = {
  categoryId: string;
  attachments?: AttachmentItemFragment[];
  cover?: ProgressivePictureFragment;
  accessMode: ArticleAccessMode;
};

export function ArticleUpdateForm(props: ArticleUpdateFormProps) {
  const { className, hideFooter, article, ...rest } = props;
  const { t } = useTranslation('communication');

  const validationSchema = Yup.object({
    categoryId: Yup.string().required(),
    attachments: Yup.mixed<AttachmentItemFragment[]>().optional(),
    cover: Yup.mixed<ProgressivePictureFragment>().required(),
    accessMode: Yup.mixed().oneOf(Object.values(ArticleAccessMode)).required(),
  }).required();

  const form = useForm<ArticleUpdateFormValues>({
    validationSchema,
    defaultValues: {
      categoryId: article.category.id,
      attachments: article.attachmentsPagination.nodes,
      cover: article.cover || undefined,
      accessMode: article.accessMode,
    },
  });

  const updateAttachmentsMutation = useAttachmentUpdate(
    'ARTICLE',
    article?.id || '',
  );

  const [mutation] = useArticleUpdateFormMutation();

  const handleSubmit: FormProps<ArticleUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    const variables: ArticleUpdateFormMutationVariables = {
      articleId: article.id,
      input: {
        categoryId: values.categoryId,
        attachmentIds: values.attachments?.map((a) => a.id),
        coverId: values.cover?.id,
        accessMode: values.accessMode,
      },
    };

    await updateAttachmentsMutation?.(
      article.attachmentsPagination.nodes,
      values.attachments || [],
    );

    const { data } = await mutation({
      variables,
      refetchQueries: ['ArticleIdView'],
    });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ArticleUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label={t('articles.fields.category')}
          name="categoryId"
        >
          <FormSelectArticleCategory
            name="categoryId"
            filter={{ company: { id: { is: null } } }}
          />
        </FormItem>

        <FormItem
          className={styles.accessMode}
          required
          label={t('articles.fields.accessMode')}
          name="accessMode"
        >
          <FormSelectArticleAccessMode name="accessMode" />
        </FormItem>

        <FormItem
          className={styles.attachments}
          label={t('articles.fields.attachments')}
          name="attachments"
        >
          <FormInputMultipleUpload
            control={form.control}
            name="attachments"
            max={5}
            type={FileType.ArticleAttachment}
          />
        </FormItem>

        <FormItem
          className={styles.cover}
          label={t('articles.fields.cover')}
          name="cover"
        >
          <CoverLibrarySelect control={form.control} name="cover" />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('articles.submits.update')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type ArticleUpdateFormProps = ChildFormProps<
  ArticleUpdateFormValues,
  ArticleUpdateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
  article: ArticleUpdateFormFragment;
};
