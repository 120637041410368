import { useState } from 'react';
import { Checkbox } from 'antd';

import { CampaignSelectionStatus } from '@graphql/generated/types';

import ErrorModal from '@components/modals/ErrorModal/ErrorModal';

import {
  useCampaignSelectionPublishCheckboxPublishMutation,
  useCampaignSelectionPublishCheckboxQuery,
  useCampaignSelectionPublishCheckboxUnpublishMutation,
} from './CampaignSelectionPublishCheckbox.generated';

type Props = {
  campaignSelectionId: string;
};

const CampaignSelectionPublishCheckbox = ({ campaignSelectionId }: Props) => {
  const [error, setError] = useState<string>();

  const { data } = useCampaignSelectionPublishCheckboxQuery({
    variables: { campaignSelectionId },
  });

  const [publishCampaignSelection] =
    useCampaignSelectionPublishCheckboxPublishMutation({
      variables: { campaignSelectionId },
      optimisticResponse: {
        __typename: 'Mutation',
        campaignSelectionPublish: {
          __typename: 'CampaignSelectionPublishOutput',
          campaignSelection: {
            __typename: 'CampaignSelection',
            id: campaignSelectionId,
            status: CampaignSelectionStatus.Published,
          },
        },
      },
    });

  const [unpublishCampaignSelection] =
    useCampaignSelectionPublishCheckboxUnpublishMutation({
      variables: { campaignSelectionId },
      optimisticResponse: {
        __typename: 'Mutation',
        campaignSelectionUnpublish: {
          __typename: 'CampaignSelectionUnpublishOutput',
          campaignSelection: {
            __typename: 'CampaignSelection',
            id: campaignSelectionId,
            status: CampaignSelectionStatus.Unpublished,
          },
        },
      },
    });

  const handlePublishCampaignSelection = async () => {
    try {
      await publishCampaignSelection();
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <div>
      <Checkbox
        checked={
          data?.campaignSelection?.status ===
            CampaignSelectionStatus.Published || false
        }
        onChange={() =>
          data?.campaignSelection?.status === CampaignSelectionStatus.Published
            ? unpublishCampaignSelection()
            : handlePublishCampaignSelection()
        }
      >
        Publiée
      </Checkbox>
      <ErrorModal onClose={() => setError(undefined)} error={error} />
    </div>
  );
};

export default CampaignSelectionPublishCheckbox;
