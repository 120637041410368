import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';

import styles from '../Redbin.module.css';

import { useRedBinProductsWithLittleStockRemainingQuery } from './ProductsWithLittleStockRemaining.generated';

export const ProductsWithLittleStockRemaining: React.FC<ProductsWithLittleStockRemainingProps> =
  (props) => {
    const { className } = props;

    const { t } = useTranslation('redbin');

    const query = useRedBinProductsWithLittleStockRemainingQuery();

    return (
      <div className={classnames(className, styles.root)}>
        <PageHeader
          ghost={false}
          title={t('ProductsWithLittleStockRemaining.title')}
        />
        <ProductsTable
          query={query}
          data={query.data?.redBinProductsWithLittleStockRemaining}
          activeColumns={['name', 'brand', 'offer', 'price']}
        />
      </div>
    );
  };

export type ProductsWithLittleStockRemainingProps = {
  className?: string;
};
