import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Subvention } from '@graphql/generated/types';

import {
  SubventionGranteeAddForm,
  SubventionGranteeAddFormProps,
} from '@components/forms/SubventionGranteeAddForm/SubventionGranteeAddForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './SubventionGranteeAddModal.module.css';

export type SubventionGranteeAddModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: SubventionGranteeAddFormProps['onSuccess'];
  isOpen?: boolean;
  subventionId: Subvention['id'];
  companyId?: string;
};

export function SubventionGranteeAddModal(
  props: SubventionGranteeAddModalProps,
) {
  const {
    className,
    onClose,
    onSuccess,
    isOpen = false,
    subventionId,
    companyId,
  } = props;

  const { t } = useTranslation('subventions');

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess: SubventionGranteeAddFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    await onSuccess?.(result, data);
    handleClose();
  };

  return (
    <Modal
      title={t('SubventionGranteeAddModal')}
      onClose={handleClose}
      isOpen={isOpen}
      className={classnames(className, styles.root)}
      form="SubventionGranteeAddForm"
    >
      <SubventionGranteeAddForm
        subventionId={subventionId}
        companyId={companyId}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
}
