import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Descriptions,
  message,
  Popconfirm,
  Space,
  Spin,
} from 'antd';
import Layout from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import Dinero from 'dinero.js';

import {
  MangopayTransactionStatus,
  MangopayTransfer,
} from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { OrderRefundStatusTag } from '@components/atoms/OrderRefundStatusTag/OrderRefundStatusTag';
import { StripeRefundStatusTag } from '@components/atoms/StripeRefundStatusTag/StripeRefundStatusTag';
import { MangopayTransferTable } from '@components/tables/MangopayTransfersTable/MangopayTransfersTable';
import { useMangopayTransferRetryMutation } from '@components/tables/MangopayTransfersTable/MangopayTransfersTable.generated';

import { useOrderRefundIdViewQuery } from './OrderRefundIdView.generated';

export type OrderRefundIdViewProps = RouteComponentProps<{
  orderRefunId: string;
}> & {
  className?: string;
};

export const OrderRefundIdView = ({ match }: OrderRefundIdViewProps) => {
  const { t, i18n } = useTranslation('orderRefund');

  const {
    params: { orderRefunId },
  } = match;

  const [mangopayTransferRetry] = useMangopayTransferRetryMutation();
  const handleRetry = (mangopayTransferId: MangopayTransfer['id']) => () =>
    mangopayTransferRetry({
      variables: { mangopayTransferId },
      refetchQueries: ['MangopayTransfersTable'],
    }).catch((error) => {
      message.error(error.message);
    });

  const query = useOrderRefundIdViewQuery({
    variables: {
      orderRefundId: orderRefunId,
    },
  });

  const data = query.data;

  const orderRefund = data?.orderRefund;

  if (!orderRefund) {
    return <Spin size="large" />;
  }

  return (
    <Layout>
      <Card
        title={
          <Title level={5}>
            {t('card.title', 'Remboursement de commande')}
          </Title>
        }
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Descriptions bordered>
            <Descriptions.Item label="Identifiant">
              {orderRefund.id}
            </Descriptions.Item>
            <Descriptions.Item label="Création">
              {orderRefund.createdAt.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Mise à jour">
              {orderRefund.updatedAt.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              <OrderRefundStatusTag orderRefund={orderRefund} />
            </Descriptions.Item>
            <Descriptions.Item label="Montant Stripe">
              {Dinero({
                amount: orderRefund.stripeAmount,
                currency: orderRefund.order.currency,
              })
                .setLocale(i18n.resolvedLanguage)
                .toFormat()}
            </Descriptions.Item>
            <Descriptions.Item label="Status Stripe" span={2}>
              <StripeRefundStatusTag stripeRefund={orderRefund.stripeRefund} />
            </Descriptions.Item>
            <Descriptions.Item label="Commande">
              <I18nLink to={`/orders/list/${orderRefund.order.id}`}>
                {orderRefund.order.id}
              </I18nLink>
            </Descriptions.Item>
          </Descriptions>

          <MangopayTransferTable
            title={() => (
              <strong>
                {t('mangopayTransferTable.title', 'Liste des transfers')}
              </strong>
            )}
            activeColumns={[
              'id',
              'updatedAt',
              'mangopayId',
              'amount',
              'status',
              'resultMessage',
            ]}
            filter={{
              orderRefund: {
                SOME: { id: { is: orderRefund.id } },
              },
            }}
            action={(record) => {
              if (record.status === MangopayTransactionStatus.Failed) {
                return (
                  <Space direction="vertical">
                    <Popconfirm
                      title={t(
                        'mangopayTransferTable.action.retry.confirmation',
                        'Est ce que vous voulez vraiment retenter ce transfer ?',
                      )}
                      okText={t('common:BooleanTag.true')}
                      cancelText={t('common:BooleanTag.false')}
                      onConfirm={handleRetry(record.id)}
                    >
                      <Button type="primary" icon={<ReloadOutlined />} danger>
                        {t(
                          'mangopayTransferTable.action.retry.button',
                          'Retry',
                        )}
                      </Button>
                    </Popconfirm>
                  </Space>
                );
              }
            }}
          />
        </Space>
      </Card>
    </Layout>
  );
};
