import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { SubventionIdView } from './SubventionIdView/SubventionIdView';
import { SubventionReimbursementIdView } from './SubventionReimbursementIdView/SubventionReimbursementIdView';
import { SubventionReimbursementListView } from './SubventionReimbursementListView/SubventionReimbursementListView';
import { SubventionSchemasCreateView } from './SubventionSchemasCreateView/SubventionSchemasCreateView';
import { SubventionSchemasListView } from './SubventionSchemasListView/SubventionSchemasListView';
import { SubventionSchemaView } from './SubventionSchemaView/SubventionSchemaView';
import { SubventionsListView } from './SubventionsListView/SubventionsListView';

const SentryRoute = Sentry.withSentryRouting(Route);

export const SubventionRouter = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <SentryRoute exact path={`${path}/subventions`}>
        <SubventionsListView />
      </SentryRoute>

      <SentryRoute
        path={`${path}/subventions/:subventionId`}
        component={SubventionIdView}
      />

      <SentryRoute exact path={`${path}/schemas`}>
        <SubventionSchemasListView />
      </SentryRoute>
      <SentryRoute exact path={`${path}/schemas/create`}>
        <SubventionSchemasCreateView />
      </SentryRoute>
      <SentryRoute
        path={`${path}/schemas/:subventionSchemaId`}
        component={SubventionSchemaView}
      />
      <SentryRoute
        path={`${path}/reimbursement/:subventionReimbursementId`}
        component={SubventionReimbursementIdView}
      />
      <SentryRoute path={`${path}/reimbursement`}>
        <SubventionReimbursementListView />
      </SentryRoute>
      <Redirect to={`${url}/subventions`} />
    </Switch>
  );
};
