import React, { useRef, useState } from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { message, Progress, Space, Typography } from 'antd';
import axios from 'axios';
import classnames from 'classnames';

import { FileType } from '@graphql/generated/types';

import { extractImageInformations } from '@utils/extractImageInformations/extractImageInformations';

import { Picture, PictureProps } from '@molecules/Picture/Picture';

import styles from './PictureUpload.module.css';

import {
  PictureUploadPictureCreateMutation,
  PictureUploadPictureCreateMutationVariables,
  usePictureUploadPictureCreateMutation,
} from './PictureUpload.generated';

export const PictureUpload = (props: PictureUploadProps) => {
  const { className, children, onSuccess, type } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState<null | number>(null);

  const [pictureCreateMutation] = usePictureUploadPictureCreateMutation();

  const triggerFileSelector = () => {
    if (!inputRef?.current) return;

    inputRef.current.click();
  };

  const handleImageUpload: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    if (!e.target.files || e.target.files.length === 0) return;
    if (e.target.files.length > 1)
      return message.error('Une seule image est autorisée');

    const file = e.target.files.item(0)!;

    setProgress(0);

    const { width, height, hash } = await extractImageInformations(file);

    try {
      const variables: PictureUploadPictureCreateMutationVariables = {
        input: {
          fileName: file.name,
          size: file.size,
          mimetype: file.type,
          height: height,
          width: width,
          hash,
          type,
        },
      };

      const { data } = await pictureCreateMutation({ variables });
      const { fields, url, picture } = data!.pictureCreate;

      const formData = new FormData();

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      formData.append('file', file, file.name);

      await axios({
        url,
        method: 'POST',
        data: formData,
        onUploadProgress: (p) => {
          setProgress(Math.floor((p.loaded / p.total) * 100));
        },
      });

      if (onSuccess) {
        onSuccess(picture);
      }

      setProgress(100);
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    } finally {
      setTimeout(() => {
        setProgress(null);
      }, 2000);
    }
  };

  return (
    <div
      className={classnames(className, styles.root, {
        [styles['-shape-circle']]: children.props.shape === 'circle',
        [styles['-shape-square']]: children.props.shape === 'square',
        [styles['-shape-rounded']]: children.props.shape === 'rounded',
        [styles['-state-uploading']]: progress !== null,
      })}
      style={{
        width: children.props.width,
        height: children.props.height,
      }}
    >
      {React.cloneElement(children, {
        preview: false,
      })}
      <div
        role="button"
        className={styles.overlay}
        onClick={triggerFileSelector}
        onKeyPress={triggerFileSelector}
        tabIndex={0}
      >
        {progress === null ? (
          <Space>
            <PictureOutlined />
            <Typography.Text>Modifier l&apos;image</Typography.Text>
          </Space>
        ) : progress === 0 ? (
          <Space>
            <Typography.Text>Initialisation</Typography.Text>
          </Space>
        ) : (
          <Progress
            percent={(progress > 0 && Math.max(0, progress)) || 0}
            size="small"
            status={
              progress === -1
                ? 'exception'
                : progress > 100
                ? 'success'
                : 'active'
            }
          />
        )}
      </div>
      <input
        className={classnames(styles.input, props.inputClassName)}
        onChange={handleImageUpload}
        ref={inputRef}
        type="file"
        accept="image/*"
      />
    </div>
  );
};

export type PictureUploadProps = {
  inputClassName?: string;
  className?: string;
  type: FileType;
  children: React.ReactElement<PictureProps, typeof Picture>;
  onSuccess?: (
    picture: PictureUploadPictureCreateMutation['pictureCreate']['picture'],
  ) => void;
};
