import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import { Layout, Typography } from 'antd';
import classnames from 'classnames';

import { ArticleCategoriesView } from '../../ArticlesCategories/ArticleCategoriesView/ArticleCategoriesView';
import { ArticlesView } from '../ArticlesView/ArticlesView';

import { PageHeader } from '@components/molecules/PageHeader/PageHeader';
import { TabsRouter } from '@organisms/TabsRouter/TabsRouter';

import styles from './ArticlesDashboardView.module.css';

export const ArticlesDashboardView: React.FC<ArticlesDashboardViewProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation('communication');

  const match = useRouteMatch();

  return (
    <Layout className={classnames(className, styles.root)}>
      <PageHeader showNav className={classnames(className, styles.root)}>
        <Typography.Title
          level={2}
          ellipsis={{ rows: 2 }}
          className={styles.title}
        >
          {t('articles.dashboard.title', 'Gérer les articles')}
        </Typography.Title>
      </PageHeader>
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route path={`${match.url}`} exact label="Tous les articles">
          <ArticlesView />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/categories`}
          exact
          label="Catégories"
        >
          <ArticleCategoriesView />
        </TabsRouter.Route>
      </TabsRouter>
    </Layout>
  );
};

export type ArticlesDashboardViewProps = RouteComponentProps & {
  className?: string;
};
