import classnames from 'classnames';

import {
  TaxRateCreateForm,
  TaxRateCreateFormProps,
  TaxRateCreateFormValues,
} from '@components/forms/TaxRateCreateForm/TaxRateCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './TaxRateCreateModal.module.css';

export type TaxRateCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: TaxRateCreateFormProps['onSuccess'];
  isOpen?: boolean;
  defaultValues?: Partial<TaxRateCreateFormValues>;
};

export function TaxRateCreateModal(props: TaxRateCreateModalProps) {
  const { className, onClose, onSuccess, isOpen, defaultValues } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: TaxRateCreateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="TaxRateCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="TaxRateCreateForm"
    >
      <TaxRateCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        hideFooter={true}
      />
    </Modal>
  );
}
