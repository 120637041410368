import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './PaymentIntentStatusTag.module.css';

import { PaymentIntentStatusTagFragment } from './PaymentIntentStatusTag.generated';

export const PaymentIntentStatusTag: React.FC<PaymentIntentStatusTagProps> = (
  props,
) => {
  const { className, paymentIntent } = props;
  const { t } = useTranslation('stripePaymentIntents');

  const colors: Record<typeof paymentIntent.status, string> = {
    processing: 'grey',
    succeeded: 'green',
    canceled: 'red',
    requires_action: 'orange',
    requires_capture: 'orange',
    requires_confirmation: 'orange',
    requires_payment_method: 'orange',
  };

  const labels: Record<typeof paymentIntent.status, string> = {
    processing: t('status.processing'),
    succeeded: t('status.succeeded'),
    canceled: t('status.canceled'),
    requires_action: t('status.requires_action'),
    requires_capture: t('status.requires_capture'),
    requires_confirmation: t('status.requires_confirmation'),
    requires_payment_method: t('status.requires_payment_method'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[paymentIntent.status]}
    >
      {labels[paymentIntent.status]}
    </Tag>
  );
};

export type PaymentIntentStatusTagProps = {
  className?: string;
  paymentIntent: PaymentIntentStatusTagFragment;
};
