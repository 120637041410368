import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { SubventionReimbursementRequest } from '@graphql/generated/types';

import {
  SubventionReimbursementRejectForm,
  SubventionReimbursementRejectFormProps,
} from '@components/forms/SubventionReimbursementRejectForm/SubventionReimbursementRejectForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './SubventionReimbursementRejectModal.module.css';

export type SubventionReimbursementRejectModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: SubventionReimbursementRejectFormProps['onSuccess'];
  isOpen?: boolean;
  subventionReimbursementRequestId: SubventionReimbursementRequest['id'];
};

export function SubventionReimbursementRejectModal(
  props: SubventionReimbursementRejectModalProps,
) {
  const {
    className,
    onClose,
    onSuccess,
    isOpen = false,
    subventionReimbursementRequestId,
  } = props;

  const { t } = useTranslation('subventionReimbursements');

  const handleClose = () => {
    onClose?.();
  };

  const handleSuccess: SubventionReimbursementRejectFormProps['onSuccess'] =
    async (result, data) => {
      await onSuccess?.(result, data);
      handleClose();
    };

  return (
    <Modal
      title={t('SubventionReimbursementRejectModal')}
      onClose={handleClose}
      isOpen={isOpen}
      className={classnames(className, styles.root)}
      form="SubventionReimbursementRejectForm"
    >
      <SubventionReimbursementRejectForm
        subventionReimbursementRequestId={subventionReimbursementRequestId}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
}
