import { createContext, ReactNode, useContext, useState } from 'react';

import { AdminCommentTargetType } from '@graphql/generated/types';

type AdminCommentsDrawerType = {
  open: (targetType: AdminCommentTargetType, targetId: string) => void;
  close: () => void;
  isOpen: boolean;
  targetType?: AdminCommentTargetType;
  targetId?: string;
};

const AdminCommentsDrawerContext = createContext<AdminCommentsDrawerType>(
  null as unknown as AdminCommentsDrawerType,
);

export function useAdminCommentsDrawer() {
  return useContext(AdminCommentsDrawerContext);
}

export type AdminCommentsDrawerProps = {
  children: ReactNode;
};

export const AdminCommentsDrawerProvider: React.FC<AdminCommentsDrawerProps> = (
  props,
) => {
  const { children } = props;

  const [targetType, setTargetType] =
    useState<AdminCommentsDrawerType['targetType']>(undefined);

  const [targetId, setTargetId] =
    useState<AdminCommentsDrawerType['targetId']>(undefined);

  const open = (type: AdminCommentTargetType, id: string) => {
    setTargetType(type);
    setTargetId(id);
  };

  const close = () => {
    setTargetType(undefined);
    setTargetId(undefined);
  };

  return (
    <AdminCommentsDrawerContext.Provider
      value={{
        targetType,
        targetId,
        open,
        close,
        isOpen: !!targetType && !!targetId,
      }}
    >
      {children}
    </AdminCommentsDrawerContext.Provider>
  );
};
