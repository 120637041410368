import { Route, useHistory, useRouteMatch } from 'react-router';
import classnames from 'classnames';

import { CompanyMembershipUpdateModal } from '@components/modals/CompanyMembershipUpdateModal/CompanyMembershipUpdateModal';
import { CompanyMembershipsTable } from '@components/tables/CompanyMembershipsTable/CompanyMembershipsTable';

import styles from './CompanyIdMembersView.module.css';

export const CompanyIdMembersView: React.FC<CompanyIdMembersViewProps> = (
  props,
) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { className, companyId } = props;

  const handleClose = () => {
    history.replace(match.url);
  };

  return (
    <div className={classnames(className, styles.root)}>
      <CompanyMembershipsTable
        activeColumns={[
          'avatar',
          'fullName',
          'status',
          'role',
          'lastLoginAt',
          'lastActiveAt',
          'joinedAt',
          'leftAt',
        ]}
        companyId={companyId}
      />
      <Route exact path={`${match.path}/:companyMembershipId`}>
        {({ match }) =>
          !!match && (
            <CompanyMembershipUpdateModal
              companyId={companyId}
              isOpen={true}
              companyMembershipId={match.params.companyMembershipId}
              onClose={handleClose}
              onSuccess={handleClose}
            />
          )
        }
      </Route>
    </div>
  );
};

export type CompanyIdMembersViewProps = {
  companyId: string;
  className?: string;
};
