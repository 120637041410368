import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FileType, Scalars } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { Card } from '@components/atoms/Card/Card';
import { FormItem } from '@components/atoms/FormItem/FormItem';
import { FormPicture } from '@components/atoms/FormPicture/FormPicture';
import {
  ChildFormProps,
  Form,
  FormProps,
  useForm,
} from '@components/organisms/Form/Form';

import styles from './PictureStockCreateForm.module.css';

import {
  PictureStockCreateFormMutation,
  PictureStockCreateFormMutationVariables,
  usePictureStockCreateFormMutation,
} from './PictureStockCreateForm.generated';

export type PictureStockCreateFormValues = {
  pictureId: Scalars['ID'];
};

export type PictureStockCreateFormProps = ChildFormProps<
  PictureStockCreateFormValues,
  PictureStockCreateFormMutation
> & {
  className?: string;
  hideFooter?: boolean;
};

export function PictureStockCreateForm(props: PictureStockCreateFormProps) {
  const { className, hideFooter, ...rest } = props;
  const { t } = useTranslation('common');

  const validationSchema = Yup.object({
    pictureId: Yup.string().required(),
  }).required();

  const form = useForm<PictureStockCreateFormValues>({
    validationSchema,
    defaultValues: {},
  });

  const [mutation] = usePictureStockCreateFormMutation();

  const handleSubmit: FormProps<PictureStockCreateFormValues>['onValid'] =
    async (values) => {
      const variables: PictureStockCreateFormMutationVariables = {
        pictureId: values.pictureId,
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="PictureStockCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.cover}
          required
          label="Image"
          name="pictureId"
        >
          <FormPicture
            name="pictureId"
            type={FileType.PictureStock}
            info={t('picture.advice', {
              resolution: COVER_RESOLUTION_INFO,
              size: COVER_SIZE_INFO,
            })}
          />
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Créer l&apos;image
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}
