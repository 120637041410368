import { Spin } from 'antd';
import classnames from 'classnames';

import { OffersTable } from '@tables/OffersTable/OffersTable';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CategoryIdOffersView.module.css';

import { useCategoryIdOffersViewQuery } from './CategoryIdOffersView.generated';

export const CategoryIdOffersView: React.FC<CategoryIdOffersViewProps> = (
  props,
) => {
  const { className, categoryId } = props;

  const query = useCategoryIdOffersViewQuery({
    variables: {
      categoryId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const category = query?.data?.category;

  if (query.loading && !category) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!category) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <OffersTable
        activeColumns={['name', 'campaign', 'brand', 'type', 'status']}
        query={query}
        data={query.data?.category?.offersPagination}
      />
    </div>
  );
};

export type CategoryIdOffersViewProps = {
  className?: string;
  categoryId: string;
};
