import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { CompanyMembershipRole } from '@graphql/generated/types';

import styles from './FormSelectCompanyMembershipRole.module.css';

export const FormSelectCompanyMembershipRole: React.FC<FormSelectCompanyMembershipRoleProps> =
  (props) => {
    const { className, name } = props;
    const { t } = useTranslation('companies');

    const options: { label: string; value: CompanyMembershipRole }[] = [
      { value: CompanyMembershipRole.Admin, label: t('membershipRole.admin') },
      {
        value: CompanyMembershipRole.Member,
        label: t('membershipRole.member'),
      },
    ];

    return (
      <FormSelect
        name={name}
        className={classnames(className, styles.root)}
        options={options}
      />
    );
  };

export type FormSelectCompanyMembershipRoleProps = {
  name: string;
  className?: string;
};
