import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './OfferStatusTag.module.css';

import { OfferStatusTagFragment } from './OfferStatusTag.generated';

export const OfferStatusTag: React.FC<OfferStatusTagProps> = (props) => {
  const { className, offer } = props;
  const { t } = useTranslation('offers');

  const colors: Record<typeof offer.status, string> = {
    DRAFT: 'grey',
    PUBLISHED: 'green',
    UNPUBLISHED: 'orange',
  };

  const labels: Record<typeof offer.status, string> = {
    DRAFT: t('status.draft'),
    PUBLISHED: t('status.published'),
    UNPUBLISHED: t('status.unpublished'),
  };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={colors[offer.status]}
    >
      {labels[offer.status]}
    </Tag>
  );
};

export type OfferStatusTagProps = {
  className?: string;
  offer: OfferStatusTagFragment;
};
