import { Button } from 'antd';
import classnames from 'classnames';
import QueryString from 'qs';

import { I18nLink } from '@components/atoms/Link/Link';
import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';

import styles from './BrandIdProductsView.module.css';

import { useBrandIdProductsViewQuery } from './BrandIdProductsView.generated';

export const BrandIdProductsView: React.FC<BrandIdProductsViewProps> = (
  props,
) => {
  const { className, brandId } = props;

  const query = useBrandIdProductsViewQuery({
    variables: {
      brandId,
      skip: 0,
      take: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className={classnames(className, styles.root)}>
      <ProductsTable
        activeColumns={['name', 'offer', 'price', 'createdAt']}
        query={query}
        data={query.data?.brand?.productPagination}
        title={() => (
          <I18nLink
            to={{
              pathname: `/products/create`,
              search: QueryString.stringify({ brandId }),
            }}
          >
            <Button>Ajouter un produit</Button>
          </I18nLink>
        )}
      />
    </div>
  );
};

export type BrandIdProductsViewProps = {
  brandId: string;
  className?: string;
};
