import classnames from 'classnames';
import countries from 'country-region-data';

import { FormSelect, FormSelectProps } from '../FormSelect/FormSelect';

export const FormSelectProvince: React.FC<FormSelectProvinceProps> = (
  props,
) => {
  const { className, countryCode, clearable, ...rest } = props;

  const regions =
    countries.find((c) => c.countryShortCode === countryCode)?.regions || [];

  return (
    <FormSelect
      {...rest}
      className={classnames(className)}
      showSearch
      optionFilterProp="label"
      clearable={clearable}
      options={regions.map((region) => ({
        value: region.name,
        label: region.name,
      }))}
    />
  );
};

export type FormSelectProvinceProps = FormSelectProps & {
  countryCode?: string;
  clearable?: boolean;
};
