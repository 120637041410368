import { TableRendererDateTime } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import {
  TablePagination,
  TablePaginationColumn,
  TablePaginationProps,
} from '@components/organisms/TablePagination/TablePagination';

import {
  UserIntegrationConnectorSynchronisationsTableRecordFragment,
  useUserIntegrationConnectorSynchronisationsTableQuery,
} from './UserIntegrationConnectorSynchronisationsTable.generated';

type RecordType = UserIntegrationConnectorSynchronisationsTableRecordFragment;

interface UserIntegrationConnectorSynchronisationsTableProps {
  companyId: string;
  connectorId: string;
  title?: TablePaginationProps['title'];
}

export const UserIntegrationConnectorSynchronisationsTable = (
  props: UserIntegrationConnectorSynchronisationsTableProps,
) => {
  const { companyId, connectorId, title } = props;

  const query = useUserIntegrationConnectorSynchronisationsTableQuery({
    variables: {
      companyId,
      connectorId,
      skip: 0,
      take: 10,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'startedAt',
      title: 'Début',
      dataIndex: ['node', 'startedAt'],
      ellipsis: true,
      render: (value) => <TableRendererDateTime value={value} />,
    },
    {
      key: 'endedAt',
      title: 'Fin',
      dataIndex: ['node', 'endedAt'],
      ellipsis: true,
      render: (value) => <TableRendererDateTime value={value} />,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: ['node', 'status'],
    },
    {
      key: 'failureCode',
      title: 'Code erreur',
      dataIndex: ['node', 'failureCode'],
    },
    {
      key: 'operationsCount',
      title: 'Opérations',
      dataIndex: ['node', 'profilesPagination', 'totalCount'],
    },
    {
      key: 'createdCount',
      title: 'Crées',
      dataIndex: ['node', 'createdProfiles', 'totalCount'],
    },
    {
      key: 'updatedCount',
      title: 'Mis à jour',
      dataIndex: ['node', 'updatedProfiles', 'totalCount'],
    },
    {
      key: 'deletedCount',
      title: 'Supprimés',
      dataIndex: ['node', 'deletedProfiles', 'totalCount'],
    },
    {
      key: 'skippedCount',
      title: 'Passés',
      dataIndex: ['node', 'skippedProfiles', 'totalCount'],
    },
    {
      key: 'failedCount',
      title: 'Échoués',
      dataIndex: ['node', 'failedProfiles', 'totalCount'],
    },
  ];

  const data =
    query?.data?.company?.featureConfig.userIntegration.connector
      ?.synchronisationsPagination;

  return (
    <TablePagination
      id="userIntegrationConnectorSynchronisationsTable"
      // @ts-expect-error deep query
      query={query}
      columns={columns}
      data={data}
      title={title}
      activeColumns={[
        'startedAt',
        'status',
        'failureCode',
        'endedAt',
        'operationsCount',
        'createdCount',
        'updatedCount',
        'deletedCount',
        'skippedCount',
        'failedCount',
      ]}
    />
  );
};
