import { Button, Popconfirm } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Scalars } from '@graphql/generated/types';

import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { FormRichTextEditor } from '@atoms/FormRichTextEditor/FormRichTextEditor';
import {
  FormSelectLocale,
  FormSelectLocaleProps,
} from '@atoms/FormSelectLocale/FormSelectLocale';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignTranslationForm.module.css';

import {
  CampaignTranslationFormCreateMutation,
  CampaignTranslationFormCreateMutationVariables,
  CampaignTranslationFormDeleteMutation,
  CampaignTranslationFormDeleteMutationVariables,
  CampaignTranslationFormFragment,
  CampaignTranslationFormUpdateMutation,
  CampaignTranslationFormUpdateMutationVariables,
  useCampaignTranslationFormCreateMutation,
  useCampaignTranslationFormDeleteMutation,
  useCampaignTranslationFormUpdateMutation,
} from './CampaignTranslationForm.generated';

export type CampaignTranslationFormValues = {
  localeId: string;
  name: string;
  description?: Scalars['Delta'] | null;
  instructions?: Scalars['Delta'] | null;
};

export function CampaignTranslationForm(props: CampaignTranslationFormProps) {
  const {
    className,
    campaignId,
    translation,
    onCreated,
    onUpdated,
    onDeleted,
    localeFilter,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    localeId: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.object().nullable(),
    instructions: Yup.object().nullable(),
  }).required();

  const form = useForm<CampaignTranslationFormValues>({
    validationSchema,
    defaultValues: {
      localeId: translation?.locale,
      description: translation?.descriptionDelta,
      instructions: translation?.instructionsDelta,
      name: translation?.name,
    },
  });

  const [createMutation, createMutationState] =
    useCampaignTranslationFormCreateMutation();
  const [updateMutation, updateMutationState] =
    useCampaignTranslationFormUpdateMutation();
  const [deleteMutation, deleteMutationState] =
    useCampaignTranslationFormDeleteMutation();

  const handleCreate = async (values: CampaignTranslationFormValues) => {
    if (translation) return;

    const variables: CampaignTranslationFormCreateMutationVariables = {
      campaignId,
      localeId: values.localeId,
      input: {
        name: values.name,
        description: values.description ?? null,
        instructions: values.instructions,
      },
    };

    const { data } = await createMutation({
      variables,
    });

    if (onCreated && data) {
      onCreated(data.campaignTranslationCreate);
    }

    form.reset();
  };

  const handleUpdate = async (values: CampaignTranslationFormValues) => {
    if (!translation) return;

    const variables: CampaignTranslationFormUpdateMutationVariables = {
      campaignId,
      localeId: translation.locale,
      input: {
        name: values.name,
        description: values.description ?? null,
        instructions: values.instructions ?? null,
      },
    };

    const { data } = await updateMutation({
      variables,
    });

    if (onUpdated && data) {
      onUpdated(data.campaignTranslationUpdate);
    }
  };

  const handleDelete = async () => {
    if (!translation) return;

    const variables: CampaignTranslationFormDeleteMutationVariables = {
      campaignId,
      localeId: translation.locale,
    };

    const { data } = await deleteMutation({
      variables,
    });

    if (onDeleted && data) {
      onDeleted(data.campaignTranslationDelete);
    }
  };

  const handleSubmit: FormProps<CampaignTranslationFormValues>['onValid'] =
    async (values) => {
      if (translation) {
        return handleUpdate(values);
      } else {
        return handleCreate(values);
      }
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignTranslationUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.locale}
          required
          label="Langue"
          name="localeId"
        >
          <FormSelectLocale
            disabled={!!translation}
            filter={localeFilter}
            name="localeId"
          />
        </FormItem>
        <FormItem className={styles.name} required label="Nom" name="name">
          <FormInput name="name" />
        </FormItem>
        <FormItem
          className={styles.description}
          required
          label="Informations générales"
          name="description"
        >
          <FormRichTextEditor
            className={styles.descriptionInput}
            name="description"
          />
        </FormItem>
        <FormItem
          className={styles.instructions}
          required
          label="Mode d'emploi"
          name="instructions"
        >
          <FormRichTextEditor
            className={styles.instructionsInput}
            name="instructions"
          />
        </FormItem>
        <div className={styles.footer}>
          {!translation ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={createMutationState.loading}
              disabled={createMutationState.loading}
            >
              Créer la traduction
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              loading={updateMutationState.loading}
              disabled={updateMutationState.loading}
            >
              Mettre à jour la traduction
            </Button>
          )}
          {!!translation && (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette traduction ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                danger
                htmlType="button"
                loading={deleteMutationState.loading}
                disabled={deleteMutationState.loading}
              >
                Supprimer
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type CampaignTranslationFormProps = ChildFormProps<
  CampaignTranslationFormValues,
  void
> & {
  className?: string;
  campaignId: string;
  translation?: CampaignTranslationFormFragment;
  localeFilter?: FormSelectLocaleProps['filter'];
  onCreated?: (
    v: CampaignTranslationFormCreateMutation['campaignTranslationCreate'],
  ) => void;
  onUpdated?: (
    v: CampaignTranslationFormUpdateMutation['campaignTranslationUpdate'],
  ) => void;
  onDeleted?: (
    v: CampaignTranslationFormDeleteMutation['campaignTranslationDelete'],
  ) => void;
};
