import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import classnames from 'classnames';

import { SearchSynchronizationType } from '@graphql/generated/types';

import { Typography } from '@atoms/Typography/Typography';
import {
  SearchSynchronizationCreateForm,
  SearchSynchronizationCreateFormProps,
} from '@forms/SearchSynchronizationCreateForm/SearchSynchronizationCreateForm';

import styles from './SearchSynchronizationCreateView.module.css';

export const SearchSynchronizationCreateView: React.FC<SearchSynchronizationCreateViewProps> =
  (props) => {
    const { className } = props;
    const { t, i18n } = useTranslation('searchSynchronizations');
    const history = useHistory();

    const handleSuccess: SearchSynchronizationCreateFormProps['onSuccess'] = (
      result,
    ) => {
      history.push(
        `/${i18n.language}/search/synchronizations/${result.searchSynchonizationCreate.synchronization.id}`,
      );
    };

    return (
      <div className={classnames(className, styles.root)}>
        <div className={styles.wrapper}>
          <Typography.Title>{t('create.title')}</Typography.Title>
          <SearchSynchronizationCreateForm
            defaultValues={{ type: SearchSynchronizationType.Campaign }}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
    );
  };

export type SearchSynchronizationCreateViewProps = {
  className?: string;
};
