import { useTranslation } from 'react-i18next';
import { Radio, RadioProps } from 'antd';

import { FeatureAccessMode } from '@graphql/generated/types';

export const SelectFeatureAccessMode: React.FC<SelectFeatureAccessModeProps> = (
  props,
) => {
  const { className, ...rest } = props;
  const { t } = useTranslation('companies');

  const options: { label: string; value: FeatureAccessMode | null }[] = [
    {
      value: null,
      label: t('featureAccessMode.Off'),
    },
    {
      value: FeatureAccessMode.Blacklist,
      label: t('featureAccessMode.Blacklist'),
    },
    {
      value: FeatureAccessMode.Whitelist,
      label: t('featureAccessMode.Whitelist'),
    },
  ];

  return (
    <Radio.Group {...rest} className="">
      {options.map((option) => (
        <Radio key={option.value} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export type SelectFeatureAccessModeProps = RadioProps;
