import classnames from 'classnames';

import { UserCompanyMembershipsTable } from '@components/tables/UserCompanyMembershipsTable/UserCompanyMembershipsTable';

import styles from './UserIdCompaniesView.module.css';

import { useUserIdCompaniesViewQuery } from './UserIdCompaniesView.generated';

export const UserIdCompaniesView: React.FC<UserIdCompaniesViewProps> = (
  props,
) => {
  const { className, userId } = props;

  const query = useUserIdCompaniesViewQuery({
    variables: {
      take: 10,
      skip: 0,
      userId,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className={classnames(className, styles.root)}>
      <UserCompanyMembershipsTable
        activeColumns={[
          'name',
          'displayName',
          'email',
          'joinedAt',
          'leftAt',
          'role',
        ]}
        // @ts-expect-error typing error after lib update
        query={query}
        data={query.data?.user?.companyMembershipPagination}
        userId={userId}
      />
    </div>
  );
};

export type UserIdCompaniesViewProps = {
  userId: string;
  className?: string;
};
