import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  useVoucherFlatDiscountUpdateFormMutation,
  VoucherFlatDiscountFragment,
  VoucherFlatDiscountUpdateFormMutation,
  VoucherFlatDiscountUpdateFormMutationVariables,
} from '../VoucherUpdateForm.generated';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectCurrency } from '@components/atoms/FormSelectCurrency/FormSelectCurrency';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from '../VoucherUpdateForm.module.css';

export type VoucherFlatDiscountUpdateFormValues = {
  amount: number;
  currency: string;
};

export function VoucherFlatDiscountUpdateForm(
  props: VoucherFlatDiscountUpdateFormProps,
) {
  const { className, voucher, ...rest } = props;

  const { t } = useTranslation('voucher');

  const validationSchema = Yup.object({
    amount: Yup.number().required(),
    currency: Yup.string().required(),
  }).required();

  const form = useForm<VoucherFlatDiscountUpdateFormValues>({
    validationSchema,
    defaultValues: {
      amount: voucher.amountFlatDiscount,
      currency: voucher.currency,
    },
  });

  const [mutation] = useVoucherFlatDiscountUpdateFormMutation();

  const handleSubmit: FormProps<VoucherFlatDiscountUpdateFormValues>['onValid'] =
    async (values) => {
      if (!voucher) return;

      const variables: VoucherFlatDiscountUpdateFormMutationVariables = {
        voucherId: voucher.id,
        input: {
          amount: values.amount,
          currency: values.currency,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <div className={classnames(className, styles.root)}>
      <Form
        id="VoucherFlatDiscountUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          required
          className={styles.amount}
          label={t('fields.amountFlat')}
          name="amount"
        >
          <FormInputNumber name="amount" />
        </FormItem>
        <FormItem
          required
          className={styles.currency}
          label={t('fields.currency')}
          name="currency"
        >
          <FormSelectCurrency name="currency" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export type VoucherFlatDiscountUpdateFormProps = ChildFormProps<
  VoucherFlatDiscountUpdateFormValues,
  VoucherFlatDiscountUpdateFormMutation
> & {
  className?: string;
  voucher: VoucherFlatDiscountFragment;
};
