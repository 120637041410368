import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { Brand } from '@graphql/generated/types';

import { FormSelectCountry } from '@components/atoms/FormSelectCountry/FormSelectCountry';
import { FormSelectProvince } from '@components/atoms/FormSelectProvince/FormSelectProvince';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './StoreCreateForm.module.css';

import {
  StoreCreateFormMutation,
  useStoreCreateFormMutation,
} from './StoreCreateForm.generated';

export type StoreCreateFormValues = {
  address1: string;
  address2?: string;
  city?: string;
  zip: string;
  country: string;
  province?: string;
};

export function StoreCreateForm(props: StoreCreateFormProps) {
  const { className, defaultValues, hideFooter, brandId, ...rest } = props;
  const { t } = useTranslation('stores');

  const validationSchema = Yup.object({
    address1: Yup.string().required(),
    address2: Yup.string(),
    city: Yup.string(),
    zip: Yup.string().required(),
    country: Yup.string().required(),
    province: Yup.string(),
  }).required();

  const form = useForm<StoreCreateFormValues>({
    validationSchema,
    defaultValues: defaultValues,
  });

  const [mutation] = useStoreCreateFormMutation();

  const handleSubmit: FormProps<StoreCreateFormValues>['onValid'] = async (
    values,
  ) => {
    try {
      const { data } = await mutation({
        variables: {
          brandId,
          input: {
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              zip: values.zip,
              country: values.country,
              province: values.province,
            },
          },
        },
      });

      return data;
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.forEach((err) => {
          switch (err.extensions?.code) {
            default:
              message.error(err.message.replace('GraphQL error: ', ''));
          }
        });
      } else {
        message.error('error:default');
      }
    }
  };

  const { country } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="StoreCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.address1}
          required
          label={t('fields.address1')}
          name="address1"
        >
          <FormInput name="address1" />
        </FormItem>

        <FormItem
          className={styles.address2}
          required
          label={t('fields.address2')}
          name="address2"
        >
          <FormInput name="address2" />
        </FormItem>

        <FormItem
          className={styles.zip}
          required
          label={t('fields.zip')}
          name="zip"
        >
          <FormInput name="zip" />
        </FormItem>

        <FormItem
          className={styles.city}
          required
          label={t('fields.city')}
          name="city"
        >
          <FormInput name="city" />
        </FormItem>

        <FormItem
          className={styles.country}
          required
          label={t('fields.country')}
          name="country"
        >
          <FormSelectCountry name="country" />
        </FormItem>

        <FormItem
          className={styles.province}
          required
          label={t('fields.province')}
          name="province"
          dependsOn={['country']}
        >
          <FormSelectProvince
            disabled={!country}
            countryCode={country}
            name="province"
          />
        </FormItem>

        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('submits.create')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type StoreCreateFormProps = ChildFormProps<
  StoreCreateFormValues,
  StoreCreateFormMutation
> & {
  className?: string;
  defaultValues?: Partial<StoreCreateFormValues>;
  hideFooter?: boolean;
  brandId: Brand['id'];
};
