import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import classnames from 'classnames';

import { StoreCreateFormProps } from '@components/forms/StoreCreateForm/StoreCreateForm';
import { StoreCreateModal } from '@components/modals/StoreCreateModal/StoreCreateModal';
import {
  StoreDeleteButton,
  StoreDeleteButtonProps,
} from '@components/organisms/StoreDeleteButton/StoreDeleteButton';
import { StoresTable } from '@components/tables/StoresTable/StoresTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './BrandIdStoresView.module.css';

import { useBrandIdStoresViewQuery } from './BrandIdStoresView.generated';

export const BrandIdStoresView: React.FC<BrandIdStoresViewProps> = (props) => {
  const { t } = useTranslation('stores');

  const { className, brandId } = props;

  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);

  const query = useBrandIdStoresViewQuery({
    variables: {
      brandId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const brand = query?.data?.brand;

  if (query.loading && !brand) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!brand) {
    return <NotFoundView />;
  }

  const handleCreateStoreSuccess: StoreCreateFormProps['onSuccess'] =
    async () => {
      setIsCreateStoreModalOpen(false);
      await query.refetch();
    };

  const handleDeleteStoreSuccess: StoreDeleteButtonProps['onSuccess'] =
    async () => {
      await query.refetch();
    };

  return (
    <div className={classnames(className, styles.root)}>
      <StoreCreateModal
        isOpen={isCreateStoreModalOpen}
        onClose={() => {
          setIsCreateStoreModalOpen(false);
        }}
        onSuccess={handleCreateStoreSuccess}
        brandId={brandId}
      />

      <StoresTable
        activeColumns={[
          'address1',
          'address2',
          'zip',
          'city',
          'country',
          'province',
          'formatted',
          'offerCount',
        ]}
        query={query}
        data={query?.data?.brand?.storePagination}
        title={() => (
          <Button onClick={() => setIsCreateStoreModalOpen(true)}>
            {t('buttons.create')}
          </Button>
        )}
        action={(store) => (
          <StoreDeleteButton
            store={store}
            mode="iconAndText"
            onSuccess={handleDeleteStoreSuccess}
          />
        )}
      />
    </div>
  );
};

export type BrandIdStoresViewProps = {
  className?: string;
  brandId: string;
};
