import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import {
  ProductCreateForm,
  ProductCreateFormProps,
} from '@components/forms/ProductCreateForm/ProductCreateForm';

import styles from './ProductCreateView.module.css';

export const ProductCreateView: React.FC<ProductCreateViewProps> = (props) => {
  const { className } = props;
  const { t, i18n } = useTranslation('products');
  const history = useHistory();
  const location = useLocation();

  const handleSuccess: ProductCreateFormProps['onSuccess'] = ({
    productCreate,
  }) => {
    history.push(`/${i18n.language}/products/list/${productCreate.product.id}`);
  };

  const defaultValues = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as ProductCreateFormProps['defaultValues'];

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('create.title')}</Typography.Title>
        <ProductCreateForm
          defaultValues={defaultValues}
          onSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};

export type ProductCreateViewProps = {
  className?: string;
};
