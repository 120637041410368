import { useTranslation } from 'react-i18next';

import { IdentityProviderTypeTag } from '@components/atoms/IdentityProviderTypeTag/IdentityProviderTypeTag';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  SsoProviderTablePaginationFragment,
  SsoProviderTableRecordFragment,
} from './SSOProviderTable.generated';

type RecordType = SsoProviderTableRecordFragment;

type Column = 'slug' | 'status' | 'type';

export function SSOProviderTable(props: SSOProviderTableProps) {
  const { className, data: propData, query, ...rest } = props;

  if (!query) {
    return null;
  }

  const { t } = useTranslation('ssoProvider');

  let data: SsoProviderTablePaginationFragment | null = null;

  if (propData) data = propData as SsoProviderTablePaginationFragment;

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('table.headers.name'),
      sorter: true,
      dataIndex: ['name'],
    },
    {
      key: 'type',
      title: t('table.headers.type'),
      dataIndex: ['__typename'],
      sorter: true,
      render: (type) => <IdentityProviderTypeTag type={type} />,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="SSOProviderTableTable"
        query={query}
        columns={columns}
        data={data}
        {...rest}
      />
    </div>
  );
}

export type SSOProviderTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  // filter?: CompanyIdSsoViewQueryVariables['filter'];
  // sorter?: CompanyIdSsoViewQueryVariables['sorter'];
};
