import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  FileType,
  HighlightTargetType,
  PublicationTargetType,
  Scalars,
} from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import { AttachmentItemFragment } from '@hooks/attachmentsCreate/attachmentCreate.hooks.generated';
import { useAttachmentUpdate } from '@hooks/attachmentUpdate/attachmentUpdate.hooks';
import { FormRichTextEditor } from '@components/atoms/FormRichTextEditor/FormRichTextEditor';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { FormInputMultipleUpload } from '@components/molecules/FormInputMultipleUpload/FormInputMultipleUpload';
import { CoverLibrarySelect } from '@components/organisms/CoverLibrarySelect/CoverLibrarySelect';
import { PublicationItemContentFragment } from '@components/views/Communication/Publications/PublicationIdView/PublicationIdView.generated';
import { Card } from '@atoms/Card/Card';
import { FormInput } from '@atoms/FormInput/FormInput';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './PublicationForm.module.css';

import {
  PublicationCreateFormMutation,
  PublicationCreateFormMutationVariables,
  PublicationHighlightMutationVariables,
  PublicationUpdateFormMutation,
  usePublicationCreateFormMutation,
  usePublicationHighlightMutation,
  usePublicationUpdateFormMutation,
} from './PublicationForm.generated';

export type PublicationFormValues = {
  title: string;
  description: Scalars['Delta'];
  targetId?: string | null;
  targetType?: PublicationTargetType | null;
  attachments?: AttachmentItemFragment[];
  cover?: ProgressivePictureFragment;
};

// TODO: Handle targetType and targetId in the form, for now only validations exist
export function PublicationForm(props: PublicationFormProps) {
  const { className, publication, ...rest } = props;

  const { t } = useTranslation('communication');

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    description: Yup.object().required(),
    targetType: Yup.mixed<PublicationTargetType>().optional().nullable(),
    targetId: Yup.string().when('targetType', {
      is: (targetType: PublicationTargetType | undefined) => !!targetType,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    attachments: Yup.mixed<AttachmentItemFragment[]>().optional(),
    cover: Yup.mixed<ProgressivePictureFragment>().optional().nullable(),
  }).required();

  const form = useForm<PublicationFormValues>({
    validationSchema,
    defaultValues: publication
      ? {
          title: publication.title,
          description: publication.descriptionDelta,
          targetType: publication.targetType,
          targetId: publication.target?.id,
          attachments: publication.attachmentsPagination.nodes,
          cover: publication.cover || undefined,
        }
      : {},
  });

  const [createMutation] = usePublicationCreateFormMutation();
  const [updateMutation] = usePublicationUpdateFormMutation();
  const [highlightMutation] = usePublicationHighlightMutation();

  const updateAttachmentsMutation = useAttachmentUpdate(
    'PUBLICATION',
    publication?.id || '',
  );

  const highlightErrors: Record<string, string> = {
    'highlight/is-already-highlighted': t(
      'publications.form.errors.highlight/is-already-highlighted',
    ),
    'highlight/target-not-found': t(
      'publications.form.errors.highlight/target-not-found',
    ),
  };

  const handleSubmit: FormProps<PublicationFormValues>['onValid'] = async (
    values,
  ) => {
    console.log(values);
    const variables: PublicationCreateFormMutationVariables = {
      input: {
        title: values.title,
        description: values.description,
        attachmentIds: values.attachments?.map((a) => a.id),
        coverId: values.cover === null ? null : values.cover?.id,
      },
    };

    let data;
    if (publication) {
      await updateAttachmentsMutation?.(
        publication.attachmentsPagination.nodes,
        values.attachments || [],
      );

      const result = await updateMutation({
        variables: { publicationId: publication.id, ...variables },
        refetchQueries: ['PublicationIdView'],
      });
      data = result.data;
    } else {
      const result = await createMutation({ variables });
      data = result.data;
    }

    return data;
  };

  const onHighlight = async () => {
    if (!publication) return;

    const variables: PublicationHighlightMutationVariables = {
      input: {
        targetType: HighlightTargetType.Publication,
        targetId: publication.id,
      },
      companyId: null,
    };

    try {
      const { data } = await highlightMutation({
        variables,
      });
      if (data?.highlightCreate) {
        message.success(t('publications.form.actions.highlight_success'));
      }
    } catch (err) {
      handleMutationErrors(err, highlightErrors);
    }
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="PublicationCreateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem className={styles.title} required label="Titre" name="title">
          <FormInput name="title" />
        </FormItem>
        <FormItem
          className={styles.description}
          required
          label="Contenu"
          name="description"
        >
          <FormRichTextEditor
            className={styles.description}
            name="description"
          />
        </FormItem>

        <FormItem
          className={styles.attachments}
          label="Contenu joints"
          name="attachments"
        >
          <FormInputMultipleUpload
            control={form.control}
            name="attachments"
            max={5}
            type={FileType.PublicationAttachment}
          />
        </FormItem>

        <FormItem
          className={styles.cover}
          label="Photo de couverture"
          name="cover"
        >
          <CoverLibrarySelect control={form.control} name="cover" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Enregistrer
          </Button>
          {!!publication?.id && (
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onHighlight}
              htmlType="button"
            >
              Mettre à la une
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type PublicationFormProps = ChildFormProps<
  PublicationFormValues,
  PublicationCreateFormMutation | PublicationUpdateFormMutation
> & {
  className?: string;
  publication?: PublicationItemContentFragment;
};
