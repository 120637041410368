import { CatalogView } from '@components/views/Catalog/CatalogView/CatalogView';

type CompanyIdCatalogViewProps = {
  companyId: string;
};

export const CompanyIdCatalogView = (props: CompanyIdCatalogViewProps) => {
  const { companyId } = props;

  return <CatalogView companyId={companyId} />;
};
