import { Controller, useFormContext } from 'react-hook-form';
import { TreeSelect, TreeSelectProps } from 'antd';
import { SelectValue } from 'antd/lib/tree-select';
import classnames from 'classnames';

import styles from './FormTreeSelect.module.css';

export const FormTreeNode = TreeSelect.TreeNode;

export const FormTreeSelect: React.FC<FormTreeSelectProps> = (props) => {
  const { className, name, ...rest } = props;
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TreeSelect
          {...rest}
          value={field.value}
          onChange={(...e) => {
            field.onChange(...e);

            if (errors[name]) {
              trigger(name);
            }
          }}
          onBlur={field.onBlur}
          ref={field.ref}
          className={classnames(className, styles.root)}
        />
      )}
    />
  );
};

export type FormTreeSelectProps = Omit<
  TreeSelectProps<SelectValue>,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string;
  className?: string;
  multiple?: boolean;
};
