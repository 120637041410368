import { Button } from 'antd';
import * as Yup from 'yup';

import { FileType } from '@graphql/generated/types';

import { FormPicture } from '@components/atoms/FormPicture/FormPicture';
import { FormPictureCrop } from '@components/atoms/FormPictureCrop/FormPictureCrop';
import { ChildFormProps, Form, useForm } from '@organisms/Form/Form';

export type PictureUploadFormValues = {
  pictureId: string;
};

export const PictureUploadForm = (props: PictureUploadFormProps) => {
  const {
    className,
    type,
    onSubmit,
    hideFooter,
    defaultValues = {},
    info,
    aspect,
    crop,
    ...rest
  } = props;

  const validationSchema = Yup.object({
    pictureId: Yup.string().required(),
  }).required();

  const form = useForm<PictureUploadFormValues>({
    validationSchema,
    defaultValues,
  });

  return (
    <Form
      id="PictureUploadForm"
      onValid={async (values) => onSubmit?.(values)}
      form={form}
      {...rest}
    >
      {crop ? (
        <FormPictureCrop
          aspect={aspect}
          name="pictureId"
          type={type}
          info={info}
        />
      ) : (
        <FormPicture name="pictureId" type={type} info={info} />
      )}
      {!hideFooter && (
        <Button
          loading={form.formState.isSubmitting}
          type="primary"
          htmlType="submit"
        >
          Ajouter
        </Button>
      )}
    </Form>
  );
};

export type PictureUploadFormProps = ChildFormProps<PictureUploadFormValues> & {
  className?: string;
  type: FileType;
  hideFooter?: boolean;
  defaultValues?: PictureUploadFormValues;
  info?: string;
  aspect?: number;
  /** True displays <FormPictureCrop, otherwise <FormPicture */
  crop?: boolean;
};
