import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Space } from 'antd';
import classnames from 'classnames';

import { CampaignPaginationArgsFilter } from '@graphql/generated/types';

import { CampaignsTable } from '@tables/CampaignsTable/CampaignsTable';

import styles from './CampaignsView.module.css';

enum FilterType {
  TRANSLATIONS_MISSING = 'TRANSLATIONS_MISSING',
  TRANSLATIONS_GENERATED = 'TRANSLATIONS_GENERATED',
}

const queryFilters: Record<FilterType, CampaignPaginationArgsFilter> = {
  [FilterType.TRANSLATIONS_MISSING]: {
    hasMissingTranslations: true,
  },
  [FilterType.TRANSLATIONS_GENERATED]: {
    hasGeneratedTranslations: true,
  },
};

export const CampaignsView: React.FC<CampaignsViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('campaigns');
  const [filter, setFilter] = useState<FilterType>();

  const applyFilters = (filterType: FilterType) => {
    if (filterType === filter) {
      setFilter(undefined);
    } else {
      setFilter(filterType);
    }
  };

  return (
    <div className={classnames(className, styles.root)}>
      <Collapse>
        <Collapse.Panel key="filters" header={t('filters.title')}>
          <Space>
            <Button
              type={
                filter === FilterType.TRANSLATIONS_MISSING
                  ? 'primary'
                  : 'default'
              }
              onClick={() => applyFilters(FilterType.TRANSLATIONS_MISSING)}
            >
              {t('filters.translationsMissing.title')}
            </Button>
            <Button
              type={
                filter === FilterType.TRANSLATIONS_GENERATED
                  ? 'primary'
                  : 'default'
              }
              onClick={() => applyFilters(FilterType.TRANSLATIONS_GENERATED)}
            >
              {t('filters.translationsGenerated.title')}
            </Button>
          </Space>
        </Collapse.Panel>
      </Collapse>
      <CampaignsTable
        filter={(filter && queryFilters[filter]) || undefined}
        activeColumns={['name', 'brand', 'offerCount', 'status', 'updatedAt']}
      />
    </div>
  );
};

export type CampaignsViewProps = {
  className?: string;
};
