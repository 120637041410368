import React, { useState } from 'react';
import { noop } from 'lodash';

import { Modal } from '@components/organisms/Modal/Modal';
import { CampaignsTable } from '@components/tables/CampaignsTable/CampaignsTable';

import { CampaignSearchModalQueryVariables } from './CampaignSearchModal.generated';

export const CampaignSearchModal = (props: CampaignSearchModalProps) => {
  const {
    isOpen = false,
    onClose = noop,
    filter,
    sorter,
    onSelect,
    disabledCampaignIds,
  } = props;

  const [activeKeys, setActiveKeys] = useState<React.Key[]>([]);

  const handleChange = (keys: React.Key[]) => {
    setActiveKeys(keys);
  };

  const handleSubmit = () => {
    const key = activeKeys[0];

    setActiveKeys([]);

    if (key) {
      onSelect(key as string);
    } else {
      onClose();
    }
  };

  const handleClose = () => {
    setActiveKeys([]);
    onClose();
  };

  return (
    <Modal
      width="95%"
      title="Rechercher une campagne"
      onClose={handleClose}
      onSubmit={handleSubmit}
      isOpen={isOpen}
    >
      <CampaignsTable
        activeColumns={['name', 'brand', 'categories', 'offerCount', 'status']}
        filter={filter}
        sorter={sorter}
        selectable={{
          type: 'radio',
          onChange: handleChange,
          value: activeKeys,
          isDisabled: disabledCampaignIds
            ? (record) => disabledCampaignIds.includes(record.id)
            : undefined,
        }}
      />
    </Modal>
  );
};

export type CampaignSearchModalProps = {
  onClose?: () => void;
  onSelect: (campaignId: string) => void;
  isOpen?: boolean;
  filter?: CampaignSearchModalQueryVariables['filter'];
  sorter?: CampaignSearchModalQueryVariables['sorter'];
  disabledCampaignIds?: string[];
};
