import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';

import { TaxRateCreateFormProps } from '@components/forms/TaxRateCreateForm/TaxRateCreateForm';
import { TaxRateUpdateFormProps } from '@components/forms/TaxRateUpdateForm/TaxRateUpdateForm';
import { TaxRateCreateModal } from '@components/modals/TaxRateCreateModal/TaxRateCreateModal';
import { TaxRateUpdateModal } from '@components/modals/TaxRateUpdateModal/TaxRateUpdateModal';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  TaxRatesTableQueryVariables,
  TaxRatesTableRecordFragment,
  useTaxRatesTableQuery,
} from './TaxRatesTable.generated';

type RecordType = TaxRatesTableRecordFragment;

type Column = 'displayName' | 'percent' | 'country' | 'state';

export function TaxRatesTable(props: TaxRatesTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('taxRates');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [updateRecord, setUpdateRecord] =
    useState<TaxRatesTableRecordFragment | null>(null);

  const query =
    propQuery ||
    useTaxRatesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'displayName',
      title: t('fields.displayName'),
      dataIndex: ['displayName'],
      ellipsis: true,
    },
    {
      key: 'percent',
      title: t('fields.percent'),
      dataIndex: ['percent'],
      ellipsis: true,
      render: (percent) => `${percent} %`,
    },

    {
      key: 'country',
      title: t('fields.country'),
      dataIndex: ['country'],
    },
    {
      key: 'state',
      title: t('fields.state'),
      dataIndex: ['state'],
    },
  ];

  const handleCreateSuccess: TaxRateCreateFormProps['onSuccess'] = (
    _result,
    _data,
  ) => {
    // console.log('taxRate created ', result.id);
    query.refetch();
  };

  const handleUpdateSuccess: TaxRateUpdateFormProps['onSuccess'] = (
    _result,
    _data,
  ) => {
    // console.log('taxRate created ', result.id);
    query.refetch();
  };

  return (
    <div className={className}>
      <TablePagination
        id="TaxRatesTable"
        query={query}
        columns={columns}
        data={propData || query.data?.taxRatePagination}
        {...rest}
        title={() => (
          <>
            <Space>
              <Button onClick={() => setIsCreateModalOpen(true)}>
                {t('buttons.create')}
              </Button>
            </Space>
          </>
        )}
        action={(record) => (
          <Space>
            <Button onClick={() => setUpdateRecord(record)}>
              {t('buttons.update')}
            </Button>
          </Space>
        )}
      />
      <TaxRateCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSuccess={handleCreateSuccess}
      />
      {updateRecord && (
        <TaxRateUpdateModal
          isOpen={!!updateRecord}
          onClose={() => {
            setUpdateRecord(null);
          }}
          onSuccess={handleUpdateSuccess}
          taxRate={updateRecord}
        />
      )}
    </div>
  );
}

export type TaxRatesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: TaxRatesTableQueryVariables['sorter'];
  filter?: TaxRatesTableQueryVariables['filter'];
};
