import classnames from 'classnames';

import { Offer } from '@graphql/generated/types';

import {
  OfferVoucherCreateForm,
  OfferVoucherCreateFormValues,
} from '@components/forms/OfferVoucherCreateForm/OfferVoucherCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './OfferVoucherCreateModal.module.css';

export type OfferVoucherCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: () => void | Promise<void>;
  isOpen?: boolean;
  offerId: Offer['id'];
  defaultValues?: Partial<OfferVoucherCreateFormValues>;
};

export function OfferVoucherCreateModal(props: OfferVoucherCreateModalProps) {
  const { className, onClose, onSuccess, isOpen, offerId, defaultValues } =
    props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess();
    }

    handleClose();
  };

  return (
    <Modal
      title="Ajouter une réduction"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="OfferVoucherCreateForm"
    >
      <OfferVoucherCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        offerId={offerId}
        onSubmit={handleSuccess}
      />
    </Modal>
  );
}
