import classnames from 'classnames';

import {
  ProductVariantCreateForm,
  ProductVariantCreateFormProps,
  ProductVariantCreateFormValues,
} from '@components/forms/ProductVariantCreateForm/ProductVariantCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './ProductVariantCreateModal.module.css';

export type ProductVariantCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: ProductVariantCreateFormProps['onSuccess'];
  isOpen?: boolean;
  defaultValues?: Partial<ProductVariantCreateFormValues>;
  productId: string;
};

export function ProductVariantCreateModal(
  props: ProductVariantCreateModalProps,
) {
  const { className, onClose, onSuccess, isOpen, defaultValues, productId } =
    props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: ProductVariantCreateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="ProductVariantCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="ProductVariantCreateForm"
    >
      <ProductVariantCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        hideFooter={true}
        productId={productId}
      />
    </Modal>
  );
}
