import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { SearchSynchronizationTypeTag } from '@components/atoms/SearchSynchronizationTypeTag/SearchSynchronizationTypeTag';
import { I18nLink } from '@atoms/Link/Link';
import { SearchSynchronizationStatusTag } from '@atoms/SearchSynchronizationStatusTag/SearchSynchronizationStatusTag';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  SearchSynchronizationsTableRecordFragment,
  useSearchSynchronizationsTableQuery,
} from './SearchSynchronizationsTable.generated';

type RecordType = SearchSynchronizationsTableRecordFragment;

type Column = 'author' | 'type' | 'status' | 'error';

export function SearchSynchronizationsTable(
  props: SearchSynchronizationsTableProps,
) {
  const { className, ...rest } = props;

  const { t } = useTranslation('searchSynchronizations');

  const query = useSearchSynchronizationsTableQuery({
    variables: {
      skip: 0,
      take: 10,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'id',
      title: t('table.headers.id'),
      dataIndex: ['id'],
      render: (id) => (
        <I18nLink to={`/search/synchronizations/${id}`}>{id}</I18nLink>
      ),
    },
    {
      key: 'author',
      title: t('table.headers.author'),
      dataIndex: ['author'],
      render: (author) => (
        <I18nLink to={`/users/list/${author.id}`}>
          {author.firstName} {author.lastName}
        </I18nLink>
      ),
    },
    {
      key: 'type',
      title: t('table.headers.type'),
      dataIndex: ['type'],
      render: (_, node) => (
        <SearchSynchronizationTypeTag searchSynchronization={node} />
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      dataIndex: ['status'],
      render: (_, node) => (
        <SearchSynchronizationStatusTag searchSynchronization={node} />
      ),
    },
    {
      key: 'error',
      title: t('table.headers.error'),
      dataIndex: ['error'],
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="SearchSynchronizationsTable"
        query={query}
        columns={columns}
        data={query.data?.searchSynchronizationsPagination}
        title={() => (
          <I18nLink to="/search/synchronizations/create">
            <Button>Déclencher une synchronization</Button>
          </I18nLink>
        )}
        {...rest}
      />
    </div>
  );
}

export type SearchSynchronizationsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
};
