import { useTranslation } from 'react-i18next';
import { List, Typography } from 'antd';

import { EventTypes } from '@graphql/generated/types';

import { AuditTrailMessageFormatterFragment } from './AuditTrailMessageFormatter.generated';

export const AuditTrailMessageFormatter: React.FC<AuditTrailMessageFormatterProps> =
  (props) => {
    const { auditTrail: auditTrailItem } = props;
    const { eventType, createdAt, author } = auditTrailItem;

    const { t } = useTranslation('auditTrail');
    const { t: f } = useTranslation();
    const actionLabel: Partial<Record<EventTypes, string>> = {
      // ------------- ARTICLE -------------

      ARTICLE_CREATED: t('actions.ARTICLE_CREATED'),
      ARTICLE_UPDATED: t('actions.ARTICLE_UPDATED'),
      ARTICLE_DELETED: t('actions.ARTICLE_DELETED'),
      ARTICLE_ARCHIVED: t('actions.ARTICLE_ARCHIVED'),
      ARTICLE_UNARCHIVED: t('actions.ARTICLE_UNARCHIVED'),

      // ------------- BENEFICIARY -------------

      BENEFICIARY_CREATED: t('actions.BENEFICIARY_CREATED'),
      BENEFICIARY_UPDATED: t('actions.BENEFICIARY_UPDATED'),
      BENEFICIARY_DELETED: t('actions.BENEFICIARY_DELETED'),
      BENEFICIARY_ADDED_PROOF: t('actions.BENEFICIARY_ADDED_PROOF'),
      BENEFICIARY_REMOVED_PROOF: t('actions.BENEFICIARY_REMOVED_PROOF'),
      BENEFICIARY_ACCEPTED: t('actions.BENEFICIARY_ACCEPTED'),
      BENEFICIARY_REJECTED: t('actions.BENEFICIARY_REJECTED'),

      // ------------- BRAND & TRANSLATIONS -------------

      BRAND_CREATED: t('actions.BRAND_CREATED'),
      BRAND_UPDATED: t('actions.BRAND_UPDATED'),
      BRAND_TRANSLATION_CREATED: t('actions.BRAND_TRANSLATION_CREATED'),
      BRAND_TRANSLATION_UPDATED: t('actions.BRAND_TRANSLATION_UPDATED'),
      BRAND_TRANSLATION_DELETED: t('actions.BRAND_TRANSLATION_DELETED'),

      // ------------- CAMPAIGN & TRANSLATIONS -------------

      CAMPAIGN_CREATED: t('actions.CAMPAIGN_CREATED'),
      CAMPAIGN_UPDATED: t('actions.CAMPAIGN_UPDATED'),
      // CAMPAIGN_DELETED = 'CAMPAIGN_DELETED',
      CAMPAIGN_PUBLISHED: t('actions.CAMPAIGN_PUBLISHED'),
      CAMPAIGN_UNPUBLISHED: t('actions.CAMPAIGN_UNPUBLISHED'),
      CAMPAIGN_TRANSLATION_CREATED: t('actions.CAMPAIGN_TRANSLATION_CREATED'),
      CAMPAIGN_TRANSLATION_UPDATED: t('actions.CAMPAIGN_TRANSLATION_UPDATED'),
      CAMPAIGN_TRANSLATION_DELETED: t('actions.CAMPAIGN_TRANSLATION_DELETED'),

      // ------------- CAMPAIGN HIGHLIGHT -------------

      CAMPAIGN_HIGHLIGHT_CREATED: t('actions.CAMPAIGN_HIGHLIGHT_CREATED'),
      CAMPAIGN_HIGHLIGHT_DELETED: t('actions.CAMPAIGN_HIGHLIGHT_DELETED'),

      // ------------- CATEGORY & TRANSLATIONS -------------

      CATEGORY_CREATED: t('actions.CATEGORY_CREATED'),
      CATEGORY_UPDATED: t('actions.CATEGORY_UPDATED'),
      CATEGORY_DELETED: t('actions.CATEGORY_DELETED'),
      CATEGORY_TRANSLATION_CREATED: t('actions.CATEGORY_TRANSLATION_CREATED'),
      CATEGORY_TRANSLATION_UPDATED: t('actions.CATEGORY_TRANSLATION_UPDATED'),
      CATEGORY_TRANSLATION_DELETED: t('actions.CATEGORY_TRANSLATION_DELETE'),

      // ------------- COMPANY -------------

      COMPANY_CREATED: t('actions.COMPANY_CREATED'),
      COMPANY_UPDATED: t('actions.COMPANY_UPDATED'),
      COMPANY_FEATURE_ENABLED: t('actions.COMPANY_FEATURE_ENABLED'),
      COMPANY_FEATURE_DISABLED: t('actions.COMPANY_FEATURE_DISABLED'),
      MEMBERSHIP_CREATED: t('actions.MEMBERSHIP_CREATED'),
      MEMBERSHIP_UPDATED: t('actions.MEMBERSHIP_UPDATED'),
      // ------------- COMPANY DOCUMENT -------------

      COMPANY_DOCUMENT_CREATED: t('actions.COMPANY_DOCUMENT_CREATED'),
      COMPANY_DOCUMENT_UPDATED: t('actions.COMPANY_DOCUMENT_UPDATED'),
      COMPANY_DOCUMENT_DELETED: t('actions.COMPANY_DOCUMENT_DELETED'),

      // ------------- COMPANY FOLDER -------------

      COMPANY_FOLDER_CREATED: t('actions.COMPANY_FOLDER_CREATED'),
      COMPANY_FOLDER_UPDATED: t('actions.COMPANY_FOLDER_UPDATED'),
      COMPANY_FOLDER_DELETED: t('actions.COMPANY_FOLDER_DELETED'),

      // ------------- COMPANY HIGHLIGHT -------------

      HIGHLIGHT_CREATED: t('actions.HIGHLIGHT_CREATED'),
      HIGHLIGHT_DELETED: t('actions.HIGHLIGHT_DELETED'),

      NEWSLETTER_CREATED: t('actions.NEWSLETTER_CREATED'),
      NEWSLETTER_UPDATED: t('actions.NEWSLETTER_UPDATED'),
      NEWSLETTER_DELETED: t('actions.NEWSLETTER_DELETED'),
      NEWSLETTER_SENT: t('actions.NEWSLETTER_SENT'),
      NEWSLETTER_DUPLICATED: t('actions.NEWSLETTER_DUPLICATED'),

      // ------------- OFFER & TRANSLATIONS -------------

      OFFER_CREATED: t('actions.OFFER_CREATED'),
      OFFER_UPDATED: t('actions.OFFER_UPDATED'),
      OFFER_TRANSLATION_CREATED: t('actions.OFFER_TRANSLATION_CREATED'),
      OFFER_TRANSLATION_UPDATED: t('actions.OFFER_TRANSLATION_UPDATED'),
      OFFER_TRANSLATION_DELETED: t('actions.OFFER_TRANSLATION_DELETED'),

      // ------------- POINT OF CONTACT -------------
      POINT_OF_CONTACT_CREATED: t('actions.POINT_OF_CONTACT_CREATED'),
      POINT_OF_CONTACT_UPDATED: t('actions.POINT_OF_CONTACT_UPDATED'),
      POINT_OF_CONTACT_DELETED: t('actions.POINT_OF_CONTACT_DELETED'),

      // ------------- USER ------------

      USER_CREATED: t('actions.USER_CREATED'),
      USER_UPDATED: t('actions.USER_UPDATED'),
      USER_BANNED: t('actions.USER_BANNED'),
      USER_UNBANNED: t('actions.USER_UNBANNED'),

      // ------------- PUBLICATION ------------

      PUBLICATION_CREATED: t('actions.PUBLICATION_CREATED'),
      PUBLICATION_UPDATED: t('actions.PUBLICATION_UPDATED'),
      PUBLICATION_DELETED: t('actions.PUBLICATION_DELETED'),
      PUBLICATION_PUBLISHED: t('actions.PUBLICATION_PUBLISHED'),
      PUBLICATION_UNPUBLISHED: t('actions.PUBLICATION_UNPUBLISHED'),

      // ------------- SUBVENTION ------------

      SUBVENTION_ABORTED: t('actions.SUBVENTION_ABORTED'),
      SUBVENTION_COMPLETED: t('actions.SUBVENTION_COMPLETED'),
      SUBVENTION_CONFIRMED: t('actions.SUBVENTION_CONFIRMED'),
      SUBVENTION_CREATED: t('actions.SUBVENTION_CREATED'),
      SUBVENTION_DELETED: t('actions.SUBVENTION_DELETED'),
      SUBVENTION_LOCKED: t('actions.SUBVENTION_LOCKED'),
      SUBVENTION_PROVISION_VALIDATED: t(
        'actions.SUBVENTION_PROVISION_VALIDATED',
      ),
      SUBVENTION_PUBLISHED: t('actions.SUBVENTION_PUBLISHED'),
      SUBVENTION_RENAMED: t('actions.SUBVENTION_RENAMED'),
      SUBVENTION_SCHEDULED: t('actions.SUBVENTION_SCHEDULED'),
      SUBVENTION_UPDATED: t('actions.SUBVENTION_UPDATED'),

      // ------------- API_KEY ------------

      API_KEY_CREATED: t('actions.API_KEY_CREATED'),
      API_KEY_UPDATED: t('actions.API_KEY_UPDATED'),
      API_KEY_DELETED: t('actions.API_KEY_DELETED'),
    };

    const authorString = !author
      ? ''
      : `${t('author.by')} ${author.firstName}
    ${author.lastName}`;

    return (
      <List.Item>
        <div>
          {actionLabel[eventType]} {authorString}
          <Typography.Text type="secondary">
            - {f('formats.dateTime', { date: new Date(createdAt) })}
          </Typography.Text>
        </div>
      </List.Item>
    );
  };

export type AuditTrailMessageFormatterProps = {
  auditTrail: AuditTrailMessageFormatterFragment;
};
