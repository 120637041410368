import { Spin } from 'antd';
import classnames from 'classnames';

import { ProductUpdateForm } from '@components/forms/ProductUpdateForm/ProductUpdateForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './ProductIdUpdateView.module.css';

import { useProductIdUpdateViewQuery } from './ProductIdUpdateView.generated';

export const ProductIdUpdateView: React.FC<ProductIdUpdateViewProps> = (
  props,
) => {
  const { className, productId } = props;

  const query = useProductIdUpdateViewQuery({
    variables: { productId },
    notifyOnNetworkStatusChange: true,
  });

  const product = query?.data?.product;

  if (query.loading && !product) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!product) {
    return <NotFoundView />;
  }

  if (!product.catalog.provider.isInternal) {
    return <div>Vous ne pouvez pas modifier un produit externe</div>;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <ProductUpdateForm product={product} />
    </div>
  );
};

export type ProductIdUpdateViewProps = {
  className?: string;
  productId: string;
};
