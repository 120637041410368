import { useState } from 'react';
import { Spin, Tabs } from 'antd';
import classnames from 'classnames';

import { useAuth } from '@hooks/auth/auth.hooks';
import { useLocales } from '@hooks/locales/locales.hooks';
import { OfferTranslationForm } from '@forms/OfferTranslationForm/OfferTranslationForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';
import { ServerErrorView } from '@views/Errors/ServerErrorView/ServerErrorView';

import styles from './OfferIdTranslationsView.module.css';

import { useOfferIdTranslationsViewQuery } from './OfferIdTranslationsView.generated';

export const OfferIdTranslationsView: React.FC<OfferIdTranslationsViewProps> = (
  props,
) => {
  const { className, offerId } = props;
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
    undefined,
  );

  const localesQuery = useLocales();

  const query = useOfferIdTranslationsViewQuery({
    variables: { offerId },
    notifyOnNetworkStatusChange: true,
  });

  const offer = query?.data?.offer;
  const locales = localesQuery?.data?.localesPagination.nodes;

  if ((localesQuery.loading || query.loading) && !offer) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!offer) {
    return <NotFoundView />;
  } else if (!locales) {
    return <ServerErrorView />;
  }

  const { viewer } = useAuth();

  const userLanguage = viewer?.preferences?.properties.language || 'en';

  return (
    <div className={classnames(className, styles.root)}>
      <Tabs
        className={styles.tabs}
        tabPosition="left"
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        defaultActiveKey={userLanguage}
      >
        {offer?.translationsPagination.nodes.map((node) => {
          const locale = locales.find((locale) => locale.id === node.locale);

          return (
            <Tabs.TabPane
              tab={
                locale
                  ? `${locale.name} (${locale.id})${
                      node.isGenerated ? ` (générée)` : ''
                    }`
                  : node.locale
              }
              key={node.locale}
            >
              <OfferTranslationForm
                offerId={offer.id}
                translation={node}
                onDeleted={({ offer }) => setActiveTabKey(offer.locale)}
              />
            </Tabs.TabPane>
          );
        })}
        {offer.translationsPagination.totalCount < locales.length && (
          <Tabs.TabPane tab="Ajouter une traduction" tabKey="create">
            <OfferTranslationForm
              onCreated={({ offerTranslation }) =>
                setActiveTabKey(offerTranslation.locale)
              }
              localeFilter={{
                locale: {
                  exclude: offer.translationsPagination.nodes.map(
                    (node) => node.locale,
                  ),
                },
              }}
              offerId={offer.id}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export type OfferIdTranslationsViewProps = {
  className?: string;
  offerId: string;
};
