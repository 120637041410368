import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormSelectCampaign } from '@components/atoms/FormSelectCampaign/FormSelectCampaign';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CampaignSelectionCampaignAddForm.module.css';

import {
  CampaignSelectionCampaignAddFormMutation,
  CampaignSelectionCampaignAddFormMutationVariables,
  useCampaignSelectionCampaignAddFormMutation,
} from './CampaignSelectionCampaignAddForm.generated';

export type CampaignSelectionCampaignAddFormValues = {
  campaignIds: string[];
};

export function CampaignSelectionCampaignAddForm(
  props: CampaignSelectionCampaignAddFormProps,
) {
  const {
    className,
    campaignSelectionId,
    hideFooter,
    defaultValues = {},
    ...rest
  } = props;

  const { t } = useTranslation('campaignSelections');

  const validationSchema = Yup.object({
    campaignIds: Yup.array(Yup.string().required()).required(),
  }).required();

  const form = useForm<CampaignSelectionCampaignAddFormValues>({
    validationSchema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const [mutation] = useCampaignSelectionCampaignAddFormMutation();

  const handleSubmit: FormProps<CampaignSelectionCampaignAddFormValues>['onValid'] =
    async (values) => {
      const variables: CampaignSelectionCampaignAddFormMutationVariables = {
        campaignSelectionId,
        campaignIds: values.campaignIds,
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CampaignSelectionCampaignAddForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('CampaignSelectionCampaignAddForm.fields.name.label')}
          name="campaignIds"
        >
          <FormSelectCampaign name="campaignIds" multiple />
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('CampaignSelectionCampaignAddForm.buttons.submit.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CampaignSelectionCampaignAddFormProps = ChildFormProps<
  CampaignSelectionCampaignAddFormValues,
  CampaignSelectionCampaignAddFormMutation
> & {
  className?: string;
  campaignSelectionId: string;
  defaultValues?: DeepPartial<CampaignSelectionCampaignAddFormValues>;
  hideFooter?: boolean;
};
