import { useTranslation } from 'react-i18next';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import { SubventionState, SubventionType } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { SubventionStateTag } from '@components/atoms/SubventionStateTag/SubventionStateTag';
import { SubventionTypeTag } from '@components/atoms/SubventionTypeTag/SubventionTypeTag';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { TableRendererDateFactory } from '@components/molecules/TableRendererDate/TableRendererDate';
import {
  ControllerChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { SubventionsTableRecordFragment } from './SubventionsTable.generated';

type RecordType = SubventionsTableRecordFragment;

type Column =
  | 'name'
  | 'type'
  | 'startsAt'
  | 'endsAt'
  | 'company'
  | 'state'
  | 'budgetConsumed'
  | 'budgetTotal';

export const SubventionsTable = (props: SubventionsTableProps) => {
  const { data, query, ...rest } = props;

  const { t } = useTranslation('subventions');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      title: t('tables.headers.id'),
      dataIndex: ['id'],
      key: 'id',
      ...generateColumnFilter(query, 'id', 'id'),
    },
    {
      key: 'name',
      title: t('tables.headers.name'),
      dataIndex: ['name'],
      render: (_, subvention) => (
        <I18nLink to={`/subventions/subventions/${subvention.id}`}>
          {subvention.name}
        </I18nLink>
      ),
      ...generateColumnFilter(query, 'name', 'search'),
    },
    {
      key: 'type',
      title: t('tables.headers.type'),
      dataIndex: ['type'],
      render: (_, subvention) => <SubventionTypeTag subvention={subvention} />,
      ...generateColumnFilter(query, 'type', 'enum', [
        {
          label: t('subventionType.Discount'),
          value: SubventionType.Discount,
        },
        {
          label: t('subventionType.Fund'),
          value: SubventionType.Fund,
        },
      ]),
    },
    {
      key: 'startsAt',
      title: t('tables.headers.startsAt'),
      dataIndex: ['startsAt'],
      render: TableRendererDateFactory(),
      ...generateColumnFilter(query, `startsAt`, 'date'),
    },
    {
      key: 'endsAt',
      title: t('tables.headers.endsAt'),
      dataIndex: ['endsAt'],
      render: TableRendererDateFactory(),
      ...generateColumnFilter(query, `endsAt`, 'date'),
    },
    {
      key: 'company',
      title: t('tables.headers.company'),
      dataIndex: ['company'],
      render: (company) => (
        <I18nLink to={`/companies/list/${company.id}`}>{company.name}</I18nLink>
      ),
      ...generateColumnFilter(query, 'company.name', 'search'),
    },
    {
      key: 'state',
      title: t('tables.headers.state'),
      dataIndex: ['state'],
      render: (state) => <SubventionStateTag subventionState={state} />,
      ...generateColumnFilter(query, 'state', 'enum', [
        {
          label: t('subventionState.Aborted'),
          value: SubventionState.Aborted,
        },
        {
          label: t('subventionState.AwaitingProvision'),
          value: SubventionState.AwaitingProvision,
        },
        {
          label: t('subventionState.AwaitingScheduling'),
          value: SubventionState.AwaitingScheduling,
        },
        {
          label: t('subventionState.Completed'),
          value: SubventionState.Completed,
        },
        {
          label: t('subventionState.Confirmed'),
          value: SubventionState.Confirmed,
        },
        {
          label: t('subventionState.Draft'),
          value: SubventionState.Draft,
        },
        {
          label: t('subventionState.Published'),
          value: SubventionState.Published,
        },
        {
          label: t('subventionState.Ready'),
          value: SubventionState.Ready,
        },
      ]),
    },
    {
      title: t('tables.headers.budgetConsumed'),
      dataIndex: 'budgetConsumed',
      key: 'budgetConsumed',
      render: (_, subvention) => (
        <TableRendererCurrency
          value={{
            amount: subvention.budgetConsumed,
            currency: subvention.currency,
          }}
        />
      ),
    },
    {
      title: t('tables.headers.budgetTotal'),
      dataIndex: 'budgetTotal',
      key: 'budgetTotal',
      render: (_, subvention) => (
        <TableRendererCurrency
          value={{
            amount: subvention.budgetTotal,
            currency: subvention.currency,
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <TablePagination
        id="SubventionsTable"
        query={query}
        data={data}
        columns={columns}
        {...rest}
      />
    </div>
  );
};

export type SubventionsTableProps = ControllerChildTablePaginationProps<
  RecordType,
  Column
>;
