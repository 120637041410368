import { useTranslation } from 'react-i18next';

import { AdminCommentTargetType } from '@graphql/generated/types';

import { PaymentIntentStatusTag } from '@components/atoms/PaymentIntentStatusTag/PaymentIntentStatusTag';
import { StripePaymentIntentUrlButton } from '@components/atoms/StripePaymentIntentUrlButton/StripePaymentIntentUrlButton';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  StripePaymentIntentsTableQueryVariables,
  StripePaymentIntentsTableRecordFragment,
  useStripePaymentIntentsTableQuery,
} from './StripePaymentIntentsTable.generated';

type RecordType = StripePaymentIntentsTableRecordFragment;

type Column =
  | 'stripeUrl'
  | 'user'
  | 'currency'
  | 'amount'
  | 'amountReceived'
  | 'status';

export function StripePaymentIntentsTable(
  props: StripePaymentIntentsTableProps,
) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    ...rest
  } = props;

  const { t } = useTranslation('stripePaymentIntents');

  const query =
    propQuery ||
    useStripePaymentIntentsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'stripeUrl',
      title: t('table.headers.stripeUrl'),
      render: (name, node) => (
        <StripePaymentIntentUrlButton stripePaymentIntent={node} />
      ),
    },
    {
      key: 'user',
      title: t('table.headers.user'),
      dataIndex: ['user', 'email'],
      render: (email, node) => (
        <I18nLink ellipsis={true} copyable to={`/users/list/${node.user.id}`}>
          {email}
        </I18nLink>
      ),
    },
    {
      key: 'amount',
      title: t('table.headers.amount'),
      render: (_, { amount, currency }) => (
        <TableRendererCurrency
          value={{
            amount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'amountReceived',
      title: t('table.headers.amountReceived'),
      render: (_, { amountReceived, currency }) => (
        <TableRendererCurrency
          value={{
            amount: amountReceived,
            currency,
          }}
        />
      ),
    },
    {
      key: 'status',
      title: t('table.headers.status'),
      render: (_, node) => <PaymentIntentStatusTag paymentIntent={node} />,
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="StripePaymentIntentsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.stripePaymentIntentsPagination}
        adminCommentTargetType={AdminCommentTargetType.StripePaymentIntent}
        {...rest}
      />
    </div>
  );
}

export type StripePaymentIntentsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: StripePaymentIntentsTableQueryVariables['filter'];
};
