import { setContext } from '@apollo/client/link/context';

import i18n from '../../i18n';

export const i18nLink = setContext(async (_request, previousContext) => {
  const locale = i18n.language;

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      ['Accept-Language']: locale,
    },
  };
});
