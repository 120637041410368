import classnames from 'classnames';

import {
  FormSelect,
  FormSelectProps,
} from '@components/atoms/FormSelect/FormSelect';

import styles from './FormSelectArticleCategory.module.css';

import {
  SelectArticleCategoryQueryVariables,
  useSelectArticleCategoryQuery,
} from './FormSelectArticleCategory.generated';

export const FormSelectArticleCategory: React.FC<FormSelectArticleCategoryProps> =
  (props) => {
    const { className, filter, ...rest } = props;

    const { data, loading } = useSelectArticleCategoryQuery({
      variables: { filter },
      notifyOnNetworkStatusChange: true,
    });

    return (
      <FormSelect
        className={classnames(className, styles.root)}
        loading={loading}
        filterOption={false}
        showSearch
        options={data?.articleCategoriesPagination.nodes.map((node) => ({
          value: node.id,
          label: node.name,
        }))}
        {...rest}
      />
    );
  };

export type FormSelectArticleCategoryProps = FormSelectProps & {
  filter?: SelectArticleCategoryQueryVariables['filter'];
};
