import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Col, Row, Space } from 'antd';
import classnames from 'classnames';

import {
  CompanyFeature,
  CompanyFeatureEnableInput,
  FeatureAccessMode,
} from '@graphql/generated/types';

import { SelectFeatureAccessMode } from '@components/molecules/SelectFeatureAccessMode/SelectFeatureAccessMode';
import { ChildFormProps } from '@organisms/Form/Form';

import styles from './CompanyFeatureUpdateForm.module.css';

import {
  CompanyFeatureUpdateFormDisableMutation,
  CompanyFeatureUpdateFormEnableMutation,
  CompanyFeatureUpdateFormFragment,
  useCompanyFeatureUpdateFormDisableMutation,
  useCompanyFeatureUpdateFormEnableMutation,
} from './CompanyFeatureUpdateForm.generated';

export type CompanyFeatureUpdateFormValues = {
  bannerMobile?: boolean | null;
  specificHighlights?: boolean | null;
  communication?: boolean | null;
  communicationMode?: FeatureAccessMode | null;
  mailing?: boolean | null;
  happenings?: boolean | null;
  subventions?: boolean | null;
  subventionsFund?: boolean | null;
  subventionsReimbursement?: boolean | null;
  subventionsDiscount?: boolean | null;
  skipFederatedSSOPage?: boolean | null;
  happyCompta?: boolean | null;
  games?: FeatureAccessMode | null;
  canCreateUpdateUser?: boolean | null;
};

export type CompanyFeatureConfigUpdateFormValues = {
  userIntegrationEnabled?: boolean | null;
};

export function CompanyFeatureUpdateForm(props: CompanyFeatureUpdateFormProps) {
  const { className, company } = props;

  const { t } = useTranslation('companies');

  const [enableMutation] = useCompanyFeatureUpdateFormEnableMutation();
  const [disableMutation] = useCompanyFeatureUpdateFormDisableMutation();

  const enableFeature = async (
    feature: CompanyFeature,
    featureObj: CompanyFeatureUpdateFormValues,
  ) => {
    const variables: {
      companyId: string;
      input: CompanyFeatureEnableInput;
    } = {
      companyId: company.id,
      input: {
        feature,
        value: featureObj.games,
      },
    };

    switch (feature) {
      case CompanyFeature.Games:
        variables.input.value = featureObj.games;
        break;
      case CompanyFeature.CommunicationMode:
        variables.input.value = featureObj.communicationMode;
        break;
    }

    await enableMutation({
      variables,
      optimisticResponse: {
        __typename: 'Mutation',
        companyFeatureEnable: {
          __typename: 'CompanyFeatureEnableOutput',
          company: {
            __typename: 'Company',
            id: company.id,
            features: {
              ...company.features,
              ...featureObj,
            },
          },
        },
      },
    });
  };

  const disableFeature = async (
    feature: CompanyFeature,
    featureObj: CompanyFeatureUpdateFormValues,
  ) => {
    const variables = {
      companyId: company.id,
      input: {
        feature,
      },
    };
    await disableMutation({
      variables,
      optimisticResponse: {
        __typename: 'Mutation',
        companyFeatureDisable: {
          __typename: 'CompanyFeatureDisableOutput',
          company: {
            __typename: 'Company',
            id: company.id,
            features: {
              ...company.features,
              ...featureObj,
            },
          },
        },
      },
    });
  };

  const {
    bannerMobile,
    communicationMode,
    mailing,
    specificHighlights,
    subventions,
    subventionsFund,
    subventionsReimbursement,
    subventionsDiscount,
    skipFederatedSSOPage,
    happyCompta,
    happenings,
    games,
    blockUpdateCompany,
    blockCreateUpdateUser,
  } = company.features;

  const globalFeatureCheckboxes = [
    {
      key: 'specificHighlights',
      checked: specificHighlights,
      feature: CompanyFeature.SpecificHighlights,
      label: t('companyFeaturesUpdate.fields.specificHighlights.label'),
    },
    {
      key: 'bannerMobile',
      checked: bannerMobile,
      feature: CompanyFeature.BannerMobile,
      label: t('companyFeaturesUpdate.fields.bannerMobile.label'),
    },
    {
      key: 'skipFederatedSSOPage',
      checked: skipFederatedSSOPage,
      feature: CompanyFeature.SkipFederatedSsoPage,
      label: t('companyFeaturesUpdate.fields.skipFederatedSSOPage.label'),
    },
    {
      key: 'blockUpdateCompany',
      checked: blockUpdateCompany,
      feature: CompanyFeature.BlockUpdateCompany,
      label: t('companyFeaturesUpdate.fields.blockUpdateCompany.label'),
    },
    {
      key: 'blockCreateUpdateUser',
      checked: blockCreateUpdateUser,
      feature: CompanyFeature.BlockCreateUpdateUser,
      label: t('companyFeaturesUpdate.fields.blockCreateUpdateUser.label'),
    },
  ];

  const featureSubventionsCheckboxes = [
    {
      key: 'subventionsFund',
      checked: subventionsFund,
      feature: CompanyFeature.SubventionsFund,
      label: t('companyFeaturesUpdate.fields.subventionsFund.label'),
    },
    {
      key: 'subventionsReimbursement',
      checked: subventionsReimbursement,
      feature: CompanyFeature.SubventionsReimbursement,
      label: t('companyFeaturesUpdate.fields.subventionsReimbursement.label'),
    },
    {
      key: 'subventionsDiscount',
      checked: subventionsDiscount,
      feature: CompanyFeature.SubventionsDiscount,
      label: t('companyFeaturesUpdate.fields.subventionsDiscount.label'),
    },
  ];

  const renderFeatureCheckbox = ({
    key,
    checked,
    feature,
    label,
  }: {
    key: string;
    checked: boolean | null | undefined;
    feature: CompanyFeature;
    label: string;
  }) => (
    <Checkbox
      className={styles.feature}
      checked={checked || false}
      onChange={() =>
        checked
          ? disableFeature(feature, {
              [key]: false,
            })
          : enableFeature(feature, {
              [key]: true,
            })
      }
    >
      {label}
    </Checkbox>
  );

  const renderFeatureSelectMode = ({
    key,
    value,
    feature,
  }: {
    key: string;
    value: FeatureAccessMode | null | undefined;
    feature: CompanyFeature;
    label: string;
  }) => {
    return (
      <div className={styles.feature}>
        <SelectFeatureAccessMode
          value={value}
          onChange={(event) => {
            enableFeature(feature, {
              [key]: event.target.value,
            });
          }}
        />
      </div>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Card
            title="Global"
            size="small"
            className={classnames(className, styles.root)}
          >
            <Space
              direction="vertical"
              size="small"
              style={{ display: 'flex' }}
            >
              {globalFeatureCheckboxes.map((feat) => (
                <div key={feat.key}>{renderFeatureCheckbox(feat)}</div>
              ))}
            </Space>
          </Card>

          <Card
            title="Subventions"
            size="small"
            className={classnames(className, styles.root)}
          >
            <Space
              direction="vertical"
              size="small"
              style={{ display: 'flex' }}
            >
              <div>
                {renderFeatureCheckbox({
                  key: 'subventions',
                  checked: subventions,
                  feature: CompanyFeature.Subventions,
                  label: 'Activé',
                })}
              </div>
              {company.features.subventions && (
                <div>
                  <div>Type de Suvention à activer :</div>
                  <div className={styles.subFeature}>
                    {featureSubventionsCheckboxes.map((feat) =>
                      renderFeatureCheckbox(feat),
                    )}
                  </div>
                </div>
              )}
            </Space>
          </Card>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Card
            title="Communication"
            size="small"
            className={classnames(className, styles.root)}
          >
            <Space
              direction="vertical"
              size="small"
              style={{ display: 'flex' }}
            >
              <div>
                {renderFeatureSelectMode({
                  key: 'communicationMode',
                  value: communicationMode,
                  feature: CompanyFeature.CommunicationMode,
                  label: t('companyFeaturesUpdate.fields.communication.label'),
                })}
              </div>
              {company.features.communicationMode && (
                <div>
                  {renderFeatureCheckbox({
                    key: 'mailing',
                    checked: mailing,
                    feature: CompanyFeature.Mailing,
                    label: t('companyFeaturesUpdate.fields.mailing.label'),
                  })}
                </div>
              )}
            </Space>
          </Card>

          <Card
            title="Jeux"
            size="small"
            className={classnames(className, styles.root)}
          >
            {renderFeatureSelectMode({
              key: 'games',
              value: games,
              feature: CompanyFeature.Games,
              label: t('companyFeaturesUpdate.fields.games.label'),
            })}
          </Card>

          <Card
            title="Happy Compta"
            size="small"
            className={classnames(className, styles.root)}
          >
            {renderFeatureCheckbox({
              key: 'happyCompta',
              checked: happyCompta,
              feature: CompanyFeature.HappyCompta,
              label: 'Activé',
            })}
          </Card>

          <Card
            title="Événements (ancienne version)"
            size="small"
            className={classnames(className, styles.root)}
          >
            {renderFeatureCheckbox({
              key: 'happenings',
              checked: happenings,
              feature: CompanyFeature.Happenings,
              label: 'Activé',
            })}
          </Card>
        </Space>
      </Col>
    </Row>
  );
}

export type CompanyFeatureUpdateFormProps = ChildFormProps<
  CompanyFeatureUpdateFormValues,
  | CompanyFeatureUpdateFormEnableMutation
  | CompanyFeatureUpdateFormDisableMutation
> & {
  className?: string;
  company: CompanyFeatureUpdateFormFragment;
};
