import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Tag, Tooltip } from 'antd';

import { Subvention } from '@graphql/generated/types';

import { I18nLink } from '@components/atoms/Link/Link';
import { OrderItemStatusTag } from '@components/atoms/OrderItemStatusTag/OrderItemStatusTag';
import { TableRendererCurrency } from '@components/molecules/TableRendererCurrency/TableRendererCurrency';
import { ProductOrdersDrawer } from '@organisms/ProductOrdersDrawer/ProductOrdersDrawer';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import styles from './OrderItemTable.module.css';

import {
  OrderItemTablePaginationFragment,
  OrderItemTableQueryVariables,
  OrderItemTableRecordFragment,
  useOrderItemTableQuery,
} from './OrderItemTable.generated';

type RecordType = OrderItemTableRecordFragment;

type Column =
  | 'status'
  | 'variant'
  | 'product'
  | 'quantity'
  | 'unitPriceBeforeTaxes'
  | 'taxRate'
  | 'unitPriceAfterTaxes'
  | 'totalPriceAfterTaxes'
  | 'discountSubvention'
  | 'fundSubventionsUsed'
  | 'attachments';

export function OrderItemTable(props: OrderItemTableProps) {
  const { orderId, className, sorter, data: propData, ...rest } = props;

  const { t } = useTranslation('orderItems');
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<OrderItemTableRecordFragment | null>(null);

  const query = useOrderItemTableQuery({
    skip: !!rest.query,
    variables: {
      orderId,
      skip: 0,
      take: 10, // TODO: Pagination
      sorter,
    },
  });

  let data: OrderItemTablePaginationFragment | null = null;

  if (propData) data = propData as OrderItemTablePaginationFragment;
  else if (query.data?.order) data = query.data.order.orderItemPagination;

  const handleDownload = async (url: string) => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        message.error('Impossible de télécharger le fichier');
        console.error(err);
      });
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'status',
      title: t('table.headers.status'),
      ellipsis: true,
      render: (_, node) => <OrderItemStatusTag order={node} />,
    },
    {
      key: 'variant',
      title: t('table.headers.variant'),
      dataIndex: ['productVariant', 'SKU'],
      ellipsis: true,
      render: (name, node) => (
        <I18nLink to={`/product-variants/list/${node.productVariant.id}`}>
          {name}
        </I18nLink>
      ),
    },
    {
      key: 'product',
      title: t('table.headers.product'),
      dataIndex: ['productVariant', 'product', 'name'],
      ellipsis: true,
      render: (name, node) => (
        <I18nLink to={`/products/list/${node.productVariant.product.id}`}>
          {name}
        </I18nLink>
      ),
    },
    {
      key: 'quantity',
      title: t('table.headers.quantity'),
      dataIndex: ['quantity'],
    },
    {
      key: 'unitPriceBeforeTaxes',
      title: t('table.headers.unitPriceBeforeTaxes'),
      render: (_, node) => (
        <TableRendererCurrency
          value={{
            amount: node.unitPriceBeforeTaxes,
            currency: node.currency,
          }}
        />
      ),
    },
    {
      key: 'taxRate',
      title: t('table.headers.taxRate'),
      render: (_, node) => node.taxRate && `${node.taxRate.percent}%`,
    },
    {
      key: 'unitPriceAfterTaxes',
      title: t('table.headers.unitPriceAfterTaxes'),
      render: (_, node) => (
        <TableRendererCurrency
          value={{
            amount: node.unitPriceAfterTaxes,
            currency: node.currency,
          }}
        />
      ),
    },
    {
      key: 'totalPriceAfterTaxes',
      title: t('table.headers.totalPriceAfterTaxes'),
      render: (_, node) => (
        <TableRendererCurrency
          value={{
            amount: node.totalPriceAfterTaxes,
            currency: node.currency,
          }}
        />
      ),
    },
    {
      key: 'initialAmount',
      title: t('table.headers.initialAmount'),
      render: (
        _,
        {
          totalPriceAfterTaxes,
          currency,
          totalDiscountAmount,
          totalDiscountSubventionAmount,
        },
      ) => (
        <TableRendererCurrency
          value={{
            amount:
              totalPriceAfterTaxes +
              totalDiscountAmount +
              totalDiscountSubventionAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'discountSubventionAmount',
      title: t('table.headers.discountSubventionAmount'),
      render: (_, { currency, totalDiscountSubventionAmount }) => (
        <TableRendererCurrency
          value={{
            amount: totalDiscountSubventionAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'discountAmount',
      title: t('table.headers.discountAmount'),
      render: (_, { currency, totalDiscountAmount }) => (
        <TableRendererCurrency
          value={{
            amount: totalDiscountAmount,
            currency,
          }}
        />
      ),
    },
    {
      key: 'fundSubventionsUsed',
      title: t('table.headers.fundSubventionsUsed'),
      dataIndex: ['subventionPagination', 'nodes'],
      render: (subventions: Subvention[]) =>
        subventions.map((subvention) => (
          <Tag key={subvention.id}>{subvention.name}</Tag>
        )),
    },
    {
      key: 'discountSubvention',
      title: t('table.headers.discountSubvention'),
      render: (_, node) => {
        if (node.discountSubvention)
          return (
            <Tooltip title={node.discountSubvention.name}>
              <span>
                <TableRendererCurrency
                  value={{
                    amount: node.discountSubventionAmount * -1,
                    currency: node.currency,
                  }}
                />
              </span>
            </Tooltip>
          );
      },
    },
    {
      key: 'attachments',
      title: t('table.headers.attachments'),
      render: (_, node) =>
        node.productArticlePagination.nodes.map((article, i) => {
          if (article.file || article.url) {
            return (
              <div key={article.id}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    article.url
                      ? window.open(article.url.href, '_blank')
                      : handleDownload(article.file?.url || '');
                  }}
                  type="dashed"
                >
                  {t('table.button.attachment.label')} {i + 1}
                </Button>
              </div>
            );
          }
        }),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="OrderItemTable"
        // @ts-expect-error typing error after lib update
        query={query}
        columns={columns}
        data={data}
        onRowClick={(record) => setSelectedOrderItem(record)}
        rowClassName={styles.tableRow}
        rowKey={'id'}
        {...rest}
      />
      {selectedOrderItem && (
        <ProductOrdersDrawer
          orderItem={selectedOrderItem}
          onClose={() => setSelectedOrderItem(null)}
        />
      )}
    </div>
  );
}

export type OrderItemTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  orderId: string;
  className?: string;
  sorter?: OrderItemTableQueryVariables['sorter'];
};
