import classnames from 'classnames';

import {
  StoreCreateForm,
  StoreCreateFormProps,
  StoreCreateFormValues,
} from '@components/forms/StoreCreateForm/StoreCreateForm';
import { Modal } from '@components/organisms/Modal/Modal';

import styles from './StoreCreateModal.module.css';

export type StoreCreateModalProps = {
  className?: string;
  onClose?: () => void;
  onSuccess?: StoreCreateFormProps['onSuccess'];
  isOpen?: boolean;
  defaultValues?: Partial<StoreCreateFormValues>;
  brandId: StoreCreateFormProps['brandId'];
};

export function StoreCreateModal(props: StoreCreateModalProps) {
  const { className, onClose, onSuccess, isOpen, defaultValues, brandId } =
    props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: StoreCreateFormProps['onSuccess'] = async (
    result,
    data,
  ) => {
    if (onSuccess) {
      await onSuccess(result, data);
    }

    handleClose();
  };

  return (
    <Modal
      title="StoreCreateModal"
      onClose={handleClose}
      isOpen={isOpen || false}
      className={classnames(className, styles.root)}
      form="StoreCreateForm"
    >
      <StoreCreateForm
        onSuccess={handleSuccess}
        defaultValues={defaultValues}
        hideFooter={true}
        brandId={brandId}
      />
    </Modal>
  );
}
