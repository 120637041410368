import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelectTaxRate } from '@components/atoms/FormSelectTaxRate/FormSelectTaxRate';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './ProductUpdateForm.module.css';

import {
  ProductUpdateFormFragment,
  ProductUpdateFormUpdateMutation,
  ProductUpdateFormUpdateMutationVariables,
  useProductUpdateFormUpdateMutation,
} from './ProductUpdateForm.generated';

export type ProductUpdateFormValues = {
  expiresAt?: Date | null;
  expiresInDays?: number | null;
  taxRateIds: string[];
};

export function ProductUpdateForm(props: ProductUpdateFormProps) {
  const { className, product, ...rest } = props;

  const { t } = useTranslation('products');

  const validationSchema = Yup.object({
    expiresAt: Yup.date().nullable(),
    expiresInDays: Yup.number().min(1).nullable(),
    taxRateIds: Yup.array(Yup.string().required()).required(),
  }).required();

  const taxRateIds = product.taxRatePagination?.nodes.map(
    (taxRate) => taxRate.id,
  );

  const form = useForm<ProductUpdateFormValues>({
    validationSchema,
    defaultValues: {
      expiresAt: product.expiresAt,
      expiresInDays: product.expiresInDays,
      taxRateIds,
    },
  });

  const [mutation] = useProductUpdateFormUpdateMutation();

  const handleSubmit: FormProps<ProductUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!product) return;

    const variables: ProductUpdateFormUpdateMutationVariables = {
      productId: product.id,
      input: {
        expiresAt: values.expiresAt ?? null,
        expiresInDays: values.expiresInDays ?? null,
        taxRateIds: values.taxRateIds,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="ProductUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.taxRates}
          required
          label={t('fields.taxRates')}
          name="taxRateIds"
        >
          <FormSelectTaxRate
            name="taxRateIds"
            multiple={true}
            clearable={true}
          />
        </FormItem>

        <FormItem
          className={styles.expiresAt}
          label={t('fields.expiresAt')}
          name="expiresAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => date.isBefore(new Date())}
            name="expiresAt"
          />
        </FormItem>

        <FormItem
          className={styles.expiresInDays}
          required
          label={t('fields.expiresInDays')}
          name="expiresInDays"
        >
          <FormInputNumber name="expiresInDays" min={0} />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type ProductUpdateFormProps = ChildFormProps<
  ProductUpdateFormValues,
  ProductUpdateFormUpdateMutation
> & {
  className?: string;
  product: ProductUpdateFormFragment;
};
