import { Tooltip } from 'antd';
import cx from 'classnames';
import Dinero, { Currency } from 'dinero.js';

import styles from './FundRepartitionBar.module.css';

const colors = [
  '#d9f7be',
  '#b7eb8f',
  '#95de64',
  '#73d13d',
  '#52c41a',
  '#389e0d',
  '#237804',
  '#135200',
];

type FundRepartitionBarProps = {
  totalAmount: number;
  values: {
    amount: number;
    currency: Currency;
    label: string;
  }[];
  className?: string;
};

export const FundRepartitionBar = (props: FundRepartitionBarProps) => {
  const { values, className, totalAmount } = props;

  const remainingAmount =
    totalAmount - values.reduce((acc, v) => acc + v.amount, 0);

  const remainingPercent = (remainingAmount / totalAmount) * 100;

  return (
    <div className={cx(styles.root, className)}>
      {values
        .filter((v) => v.amount > 0)
        .map((value, index) => {
          const width =
            (value.amount /
              props.values.reduce((acc, value) => acc + value.amount, 0)) *
            100;

          return (
            <Tooltip
              key={index}
              title={`${value.label} : ${Dinero({
                amount: value.amount,
                currency: value.currency,
              }).toFormat()}`}
            >
              <div
                key={index}
                className={styles.bar}
                style={{ width: `${width}%`, backgroundColor: colors[index] }}
              />
            </Tooltip>
          );
        })}
      <Tooltip
        title={`Montant restant : ${Dinero({
          amount: remainingAmount,
          currency: values[0]?.currency || 'EUR',
        }).toFormat()}`}
      >
        <div
          className={styles.bar}
          style={{ width: `${remainingPercent}%`, backgroundColor: 'red' }}
        />
      </Tooltip>
    </div>
  );
};
