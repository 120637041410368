import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './PublishedStatusTag.module.css';

export const PublishedStatusTag: React.FC<PublishedStatusTagProps> = (
  props,
) => {
  const { className, published } = props;
  const { t } = useTranslation('campaigns');

  const publishedFormat = { label: t('status.published'), color: 'success' };
  const unpublishedFormat = { label: t('status.unpublished'), color: 'error' };

  return (
    <Tag
      className={classnames(className, styles.root)}
      color={published ? publishedFormat.color : unpublishedFormat.color}
    >
      {published ? publishedFormat.label : unpublishedFormat.label}
    </Tag>
  );
};

export type PublishedStatusTagProps = {
  className?: string;
  published: boolean;
};
