import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

import { I18nLink } from '@atoms/Link/Link';
import { TableRendererAvatar } from '@molecules/TableRendererAvatar/TableRendererAvatar';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ArticleCategoriesTableQueryVariables,
  ArticleCategoryTableRecordFragment,
  useArticleCategoriesTableQuery,
  useArticleCategoriesTableRemoveMutation,
} from './ArticleCategoriesTable.generated';

type RecordType = ArticleCategoryTableRecordFragment;

type Column = 'cover' | 'name' | 'articlesCount' | 'parent';

export function ArticleCategoriesTable(props: ArticleCategoriesTableProps) {
  const { className, filter, sorter, data: propData, ...rest } = props;

  const { t } = useTranslation('communication');

  const [removeMutation] = useArticleCategoriesTableRemoveMutation();

  const query = useArticleCategoriesTableQuery({
    skip: !!rest.query,
    variables: {
      skip: 0,
      take: 10,
      filter,
      sorter,
    },
  });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'cover',
      title: t('categories.table.headers.cover'),
      dataIndex: ['cover'],
      width: 64,
      render: (cover, node) => (
        <TableRendererAvatar picture={cover} placeholder={node.name[0]} />
      ),
    },
    {
      key: 'name',
      title: t('categories.table.headers.name'),
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/communication/articles/categories/${node.id}`}>
          {name}
        </I18nLink>
      ),
    },
    {
      key: 'articlesCount',
      title: t('categories.table.headers.articlesCount'),
      dataIndex: ['articlesPagination', 'totalCount'],
    },
    {
      key: 'parent',
      title: t('categories.table.headers.parent'),
      dataIndex: ['parent'],
      render: (parent) =>
        parent && (
          <I18nLink to={`/communication/articles/categories/${parent.id}`}>
            {parent.name}
          </I18nLink>
        ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ArticleCategoriesTable"
        query={query}
        columns={columns}
        data={propData || query.data?.articleCategoriesPagination}
        action={(record) => (
          <Popconfirm
            title="Attention, si vous supprimez cette catégorie, tous les articles associés seront aussi supprimés !"
            okText="Supprimer"
            okType="danger"
            onConfirm={handleDelete(record.id)}
          >
            <Button type="text" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
        {...rest}
      />
    </div>
  );

  function handleDelete(recordId: string) {
    return async () => {
      await removeMutation({
        variables: {
          articleCategoryId: recordId,
        },
      });
      await query.refetch();
    };
  }
}

export type ArticleCategoriesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  filter?: ArticleCategoriesTableQueryVariables['filter'];
  sorter?: ArticleCategoriesTableQueryVariables['sorter'];
};
