import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import {
  useVoucherPercentDiscountUpdateFormMutation,
  VoucherPercentDiscountFragment,
  VoucherPercentDiscountUpdateFormMutation,
  VoucherPercentDiscountUpdateFormMutationVariables,
} from '../VoucherUpdateForm.generated';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from '../VoucherUpdateForm.module.css';

export type VoucherPercentDiscountUpdateFormValues = {
  amount: number;
};

export function VoucherPercentDiscountUpdateForm(
  props: VoucherPercentDiscountUpdateFormProps,
) {
  const { className, voucher, ...rest } = props;

  const { t } = useTranslation('voucher');

  const validationSchema = Yup.object({
    amount: Yup.number().required(),
  }).required();

  const form = useForm<VoucherPercentDiscountUpdateFormValues>({
    validationSchema,
    defaultValues: {
      amount: voucher.amountPercentDiscount,
    },
  });

  const [mutation] = useVoucherPercentDiscountUpdateFormMutation();

  const handleSubmit: FormProps<VoucherPercentDiscountUpdateFormValues>['onValid'] =
    async (values) => {
      if (!voucher) return;

      const variables: VoucherPercentDiscountUpdateFormMutationVariables = {
        voucherId: voucher.id,
        input: {
          amount: values.amount,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <div className={classnames(className, styles.root)}>
      <Form
        id="VoucherPercentDiscountUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          required
          className={styles.amount}
          label={t('fields.amountPercent')}
          name="amount"
        >
          <FormInputNumber name="amount" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export type VoucherPercentDiscountUpdateFormProps = ChildFormProps<
  VoucherPercentDiscountUpdateFormValues,
  VoucherPercentDiscountUpdateFormMutation
> & {
  className?: string;
  voucher: VoucherPercentDiscountFragment;
};
