import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { excludeEntityActions } from '@components/views/CompanyExcludedView/CompanyExcludedView';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyExcludeEntityForm.module.css';

export type CompanyExcludeEntityFormValues = {
  companyId: string;
};

export function CompanyExcludeEntityForm(props: CompanyExcludeEntityFormProps) {
  const { className, hideFooter, entityType, entityId, ...rest } = props;

  const { t } = useTranslation('companies');

  const validationSchema = Yup.object({
    companyId: Yup.string().required(),
  }).required();

  const form = useForm<CompanyExcludeEntityFormValues>({ validationSchema });

  const [createMutation] = excludeEntityActions[entityType].create();

  const handleSubmit: FormProps<CompanyExcludeEntityFormValues>['onValid'] =
    async (values) => {
      const companyId = values.companyId;

      let input;

      if (entityType === 'article') {
        input = { articleIds: [entityId] };

        const { data } = await createMutation({
          variables: { companyId, input },
        });

        return data;
      }

      throw new Error('Problewm with entity');
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyExcludeEntityForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('CompanyExcludeEntityForm.fields.name.label')}
          name="companyId"
        >
          <FormSelectCompany name="companyId" />
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('CompanyExcludeEntityForm.buttons.submit.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CompanyExcludeEntityFormProps =
  ChildFormProps<CompanyExcludeEntityFormValues> & {
    className?: string;
    defaultValues?: DeepPartial<CompanyExcludeEntityFormValues>;
    hideFooter?: boolean;
    entityId: string;
    entityType: 'article';
  };
