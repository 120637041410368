import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import styles from './StripeRefundStatusTag.module.css';

import { StripeRefundStatusTagFragment } from './StripeRefundStatusTag.generated';

export const StripeRefundStatusTag: React.FC<StripeRefundStatusTagProps> = (
  props,
) => {
  const { t } = useTranslation('stripeRefund');

  const { className, stripeRefund } = props;

  if (!stripeRefund) return <Tag>{t('status.none', 'néant')}</Tag>;

  const colors: Record<typeof stripeRefund.status, string> = {
    canceled: 'red',
    failed: 'red',
    pending: 'orange',
    requires_action: 'orange',
    succeeded: 'green',
  };

  const labels: Record<typeof stripeRefund.status, string> = {
    canceled: t('status.canceled'),
    failed: t('status.failed'),
    pending: t('status.pending'),
    requires_action: t('status.requires_action'),
    succeeded: t('status.succeeded'),
  };

  return (
    <Tag
      title={labels[stripeRefund.status]}
      className={classnames(className, styles.root)}
      color={colors[stripeRefund.status]}
    >
      {labels[stripeRefund.status]}
    </Tag>
  );
};

export type StripeRefundStatusTagProps = {
  className?: string;
  stripeRefund?: StripeRefundStatusTagFragment | null;
};
