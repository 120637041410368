import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import classnames from 'classnames';

import styles from './NotFoundView.module.css';

export const NotFoundView: React.FC<NotFoundView> = (props) => {
  const { className, returnTo = '/' } = props;

  return (
    <Result
      className={classnames(className, styles.root)}
      status="404"
      title="Page introuvable"
      subTitle="Désolé, la page que vous avez visitée n'existe pas."
      extra={
        <Link to={returnTo}>
          <Button type="primary">Beam me up, Scotty</Button>
        </Link>
      }
    />
  );
};

export type NotFoundView = {
  className?: string;
  returnTo?: string;
};
