import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { CampaignSelectionsTable } from '@components/tables/CampaignSelectionsTable/CampaignSelectionsTable';

import styles from './CompanyIdCampaignSelectionsIncludedView.module.css';

export const CompanyIdCampaignSelectionsIncludedView: React.FC<CompanyIdCampaignSelectionsIncludedViewProps> =
  (props) => {
    const { className, companyId } = props;
    const { t } = useTranslation('companies');
    return (
      <div className={classnames(className, styles.root)}>
        <CampaignSelectionsTable
          title={() => (
            <Typography.Title level={3}>
              {' '}
              {t('includedCompanies.campaignSelections.name')}
            </Typography.Title>
          )}
          activeColumns={['id', 'name']}
          filter={{ includedCompanies: { id: { is: companyId } } }}
        />
      </div>
    );
  };

export type CompanyIdCampaignSelectionsIncludedViewProps = {
  companyId: string;
  className?: string;
};
