import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { CampaignsTable } from '@components/tables/CampaignsTable/CampaignsTable';

import styles from '../Redbin.module.css';

import { useRedBinCampaignsWithoutCoverQuery } from './CampaignsWithoutCover.generated';

export const CampaignsWithoutCover: React.FC<CampaignsWithoutCoverProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinCampaignsWithoutCoverQuery({
    variables: { take: 10, skip: 0 },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('CampaignsWithoutCover.title')} />
      <CampaignsTable
        query={query}
        data={query.data?.redBinCampaignsWithoutCover}
        activeColumns={[
          'name',
          'offerCount',
          'status',
          'startsAt',
          'expiresAt',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type CampaignsWithoutCoverProps = {
  className?: string;
};
