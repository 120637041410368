import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Card, Spin } from 'antd';
import classnames from 'classnames';

import { HappeningIdRegistrationsView } from '../HappeningIdRegistrationsView/HappeningIdRegistrationsView';
import { HappeningUpdateView } from '../HappeningUpdateView/HappeningUpdateView';

import { HappeningConfirmTransferButton } from '@components/organisms/HappeningConfirmTransferButton/HappeningConfirmTransferButton';
import { HappeningHeader } from '@components/organisms/HappeningHeader/HappeningHeader';
import { TabsRouter } from '@components/organisms/TabsRouter/TabsRouter';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './HappeningIdView.module.css';

import { useHappeningIdViewQuery } from './HappeningIdView.generated';

export const HappeningIdView: React.FC<HappeningIdViewProps> = (props) => {
  const { className, match } = props;

  const {
    params: { happeningId },
  } = match;

  const { t } = useTranslation('happenings');
  const happeningQuery = useHappeningIdViewQuery({
    variables: { happeningId },
    notifyOnNetworkStatusChange: true,
  });

  const happening = happeningQuery?.data?.happening;

  if (happeningQuery.loading && !happening) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!happening) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <HappeningHeader happening={happening} />
      <TabsRouter
        replace
        match={match}
        className={styles.router}
        navClassName={styles.nav}
      >
        <TabsRouter.Route
          path={`${match.url}`}
          exact
          label={t('HappeningIdView.tabs.details')}
        >
          <Card>
            <HappeningConfirmTransferButton happening={happening} />
          </Card>
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/update`}
          exact
          label={t('HappeningIdView.tabs.update')}
        >
          <HappeningUpdateView happening={happening} />
        </TabsRouter.Route>
        <TabsRouter.Route
          path={`${match.url}/registrations`}
          exact
          label={t('HappeningIdView.tabs.registrations')}
        >
          <HappeningIdRegistrationsView happeningId={happening.id} />
        </TabsRouter.Route>
      </TabsRouter>
    </div>
  );
};

export type HappeningIdViewProps = RouteComponentProps<{
  happeningId: string;
}> & {
  className?: string;
};
