import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';
import { FormSelect } from '@components/atoms/FormSelect/FormSelect';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferCouponClaimableUpdateForm.module.css';

import {
  OfferCouponClaimableUpdateFormFragment,
  OfferCouponClaimableUpdateFormUpdateMutation,
  OfferCouponClaimableUpdateFormUpdateMutationVariables,
  useOfferCouponClaimableUpdateFormUpdateMutation,
} from './OfferCouponClaimableUpdateForm.generated';

export type OfferCouponClaimableUpdateFormValues = {
  claimCountMax?: number | null;
  claimInterval: number;
};

export function OfferCouponClaimableUpdateForm(
  props: OfferCouponClaimableUpdateFormProps,
) {
  const { className, offer, ...rest } = props;

  const { t } = useTranslation('offers');

  const validationSchema = Yup.object({
    claimCountMax: Yup.number().positive().nullable(),
    claimInterval: Yup.number().positive().required(),
  }).required();

  const form = useForm<OfferCouponClaimableUpdateFormValues>({
    validationSchema,
    defaultValues: {
      claimCountMax: offer.claimCountMax,
      claimInterval: offer.claimInterval || undefined,
    },
  });

  const [mutation] = useOfferCouponClaimableUpdateFormUpdateMutation();

  const handleSubmit: FormProps<OfferCouponClaimableUpdateFormValues>['onValid'] =
    async (values) => {
      if (!offer) return;

      const variables: OfferCouponClaimableUpdateFormUpdateMutationVariables = {
        offerId: offer.id,
        input: {
          claimCountMax: values.claimCountMax,
          claimInterval: values.claimInterval,
        },
      };

      const { data } = await mutation({ variables });

      return data;
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferCouponClaimableUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.interval}
          label="Interval de revendication"
          help="Temps à attendre entre deux revendications"
          name="claimInterval"
        >
          <FormSelect
            name="claimInterval"
            options={[
              {
                label: 'Un toutes les heures',
                value: 60,
              },
              {
                label: 'Un tous les jours',
                value: 60 * 24,
              },
              {
                label: 'Un toutes les semaines',
                value: 60 * 24 * 7,
              },
              {
                label: 'Un toutes les deux semaines',
                value: 60 * 24 * 14,
              },
              {
                label: 'Un tous les mois',
                value: 60 * 24 * 30,
              },
              {
                label: 'Un tous les trimestres',
                value: 60 * 24 * 30 * 3,
              },
              {
                label: 'Un tous les ans',
                value: 60 * 24 * 30 * 12,
              },
            ]}
          />
        </FormItem>
        <FormItem
          className={styles.count}
          label="Revendication maximum"
          help="Nombre de fois qu'un utilisateur peut revendiquer un coupon"
          name="claimCountMax"
        >
          <FormInputNumber min={0} name="claimCountMax" />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type OfferCouponClaimableUpdateFormProps = ChildFormProps<
  OfferCouponClaimableUpdateFormValues,
  OfferCouponClaimableUpdateFormUpdateMutation
> & {
  className?: string;
  offer: OfferCouponClaimableUpdateFormFragment;
};
