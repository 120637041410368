import { useTranslation } from 'react-i18next';

import { HappeningRegistrationStatusTag } from '@components/atoms/HappeningRegistrationStatus/HappeningRegistrationStatus';
import { I18nLink } from '@components/atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  HappeningRegistrationTableRecordFragment,
  useHappeningRegistrationsTableQuery,
} from './HappeningRegistrationsTable.generated';

type RecordType = HappeningRegistrationTableRecordFragment;

type Column =
  | 'id'
  | 'user'
  | 'happening'
  | 'status'
  | 'createdAt'
  | 'updatedAt';

export function HappeningRegistrationsTable(
  props: HappeningRegistrationsTableProps,
) {
  const {
    className,
    happeningId,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('happeningRegistrations');

  const query =
    propQuery ||
    useHappeningRegistrationsTableQuery({
      variables: {
        happeningId: happeningId ?? 'never',
        skip: 0,
        take: 10,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'user',
      title: t('fields.user'),
      dataIndex: ['user', 'email'],
      render: (_, registration) => (
        <I18nLink
          copyable
          ellipsis={true}
          to={`/users/list/${registration.user.id}`}
        >
          {registration.user.email}
        </I18nLink>
      ),
    },
    {
      key: 'happening',
      title: t('fields.happening'),
      dataIndex: ['happening', 'name'],
      render: (_, registration) => (
        <I18nLink to={`/happenings/list/${registration.happening.id}`}>
          {registration.happening.name}
        </I18nLink>
      ),
    },
    {
      key: 'status',
      title: t('fields.status'),
      render: (happeningRegistration) => (
        <HappeningRegistrationStatusTag
          happeningRegistration={happeningRegistration}
        />
      ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="HappeningRegistrationsTable"
        // @ts-expect-error typing error after lib update
        query={query}
        columns={columns}
        data={propData || query.data?.happening?.registrationsPagination}
        {...rest}
      />
    </div>
  );
}

export type HappeningRegistrationsTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  happeningId?: string;
};
