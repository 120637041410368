import { FileType } from '@graphql/generated/types';

import { PageHeader } from '@molecules/PageHeader/PageHeader';

import {
  CategoryHeaderFragment,
  useCategoryHeaderUpdateMutation,
} from './CategoryHeader.generated';

export const CategoryHeader: React.FC<CategoryHeaderProps> = (props) => {
  const { className, category } = props;

  const [mutation] = useCategoryHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        categoryId: category.id,
        input: {
          iconId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      logo={category.icon}
      logoProps={{ mode: 'contain' }}
      className={className}
      showNav
      uploadLogoProps={{
        fileType: FileType.CategoryIcon,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Icone',
        picture: category.icon,
        info: 'Résolution recommandée 512x512, max 1mo',
      }}
    >
      {category.name}
    </PageHeader>
  );
};

export type CategoryHeaderProps = {
  className?: string;
  category: CategoryHeaderFragment;
};
