import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { SortDirection } from '@graphql/generated/types';

import { TableRendererDateTimeFactory } from '@components/molecules/TableRendererDateTime/TableRendererDateTime';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  mapperSorter,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  ProductArticlesTableQueryVariables,
  ProductArticlesTableRecordFragment,
  useProductArticlesTableQuery,
} from './ProductArticlesTable.generated';

type RecordType = ProductArticlesTableRecordFragment;

type Column = 'id' | 'createdAt' | 'expiresAt' | 'productVariant';

export function ProductArticlesTable(props: ProductArticlesTableProps) {
  const {
    className,
    filter,
    sorter,
    query: propQuery,
    data: propData,
    ...rest
  } = props;

  const { t } = useTranslation('productArticles');

  const query =
    propQuery ||
    useProductArticlesTableQuery({
      variables: {
        skip: 0,
        take: 10,
        sorter,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'expiresAt',
      title: t('table.headers.expiresAt'),
      dataIndex: ['expiresAt'],
      render: TableRendererDateTimeFactory(),
    },
    {
      key: 'productVariant',
      title: t('table.headers.productVariant'),
      dataIndex: ['productVariant', 'name'],
      ellipsis: true,
      render: (name, node) =>
        node.productVariant ? (
          <I18nLink to={`/product-variants/list/${node.productVariant.id}`}>
            {name}
          </I18nLink>
        ) : (
          'N/A'
        ),
    },
    {
      key: 'fileName',
      title: t('table.headers.fileName'),
      dataIndex: ['file'],
      ellipsis: true,
      sorter: true,
      defaultSortOrder: mapperSorter[sorter?.fileName as SortDirection],
      render: (file) => {
        if (file)
          return (
            <a href={file.url} key="download" target="__blank">
              {file.originalName}
            </a>
          );
      },
    },
    {
      key: 'isUsed',
      title: t('table.headers.isUsed'),
      dataIndex: ['orderItem'],
      ellipsis: true,
      sorter: true,
      render: (orderItem) => (
        <Tag color={orderItem ? 'green' : 'red'}>
          {orderItem ? 'Oui' : 'Non'}
        </Tag>
      ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="ProductArticlesTable"
        query={query}
        columns={columns}
        data={propData || query.data?.productArticlePagination}
        sorter={sorter}
        {...rest}
      />
    </div>
  );
}

export type ProductArticlesTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  sorter?: ProductArticlesTableQueryVariables['sorter'];
  filter?: ProductArticlesTableQueryVariables['filter'];
};
