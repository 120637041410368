import { useState } from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Modal, Select } from 'antd';

import { Typography } from '@components/atoms/Typography/Typography';
import { TablePaginationColumn } from '@components/organisms/TablePagination/TablePagination';

import styles from './TablePaginationModal.module.css';

export function TablePaginationModal(props: TablePaginationModalProps) {
  const { columns, isVisible, onClose, onSubmit } = props;

  const [selectedColumns, setSelectedColumns] = useState(props.selectedColumns);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(selectedColumns);
    }
  };

  const handleChange = (key: string) => () => {
    if (selectedColumns.includes(key)) {
      setSelectedColumns(selectedColumns.filter((k) => k !== key));
    } else {
      setSelectedColumns([...selectedColumns, key]);
    }
  };

  const handleDragEnd: DragDropContextProps['onDragEnd'] = ({
    destination,
    source,
  }) => {
    if (!destination) return;

    const result = Array.from(selectedColumns);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    setSelectedColumns(result);
  };

  return (
    <Modal
      onCancel={onClose}
      visible={isVisible}
      onOk={handleSubmit}
      title="Personnaliser l'affichage"
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tablePaginationModal">
          {(droppableProvided: DroppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {selectedColumns.map((key, i) => {
                const column = columns.find((col) => col.key === key);

                if (!column) return null;
                return (
                  <Draggable
                    index={i}
                    draggableId={column.key}
                    key={column.key}
                  >
                    {(
                      provided: DraggableProvided,
                      snapshot: DraggableStateSnapshot,
                    ) => (
                      <TablePaginationModalRow
                        label={column.title}
                        value={column.key}
                        onRemove={handleChange(column.key)}
                        provided={provided}
                        snapshot={snapshot}
                      />
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Select
        className={styles.select}
        placeholder="Ajouter une colonne"
        onSelect={(_, option) =>
          setSelectedColumns(
            [...selectedColumns, option.value].filter(Boolean) as string[],
          )
        }
        value={[]}
      >
        {columns
          .filter((col) => !selectedColumns.includes(col.key))
          .map((col) => (
            <Select.Option value={col.key} key={col.key}>
              {col.title}{' '}
              <Typography.Text type="secondary">({col.key})</Typography.Text>
            </Select.Option>
          ))}
      </Select>
    </Modal>
  );
}

function TablePaginationModalRow(props: TablePaginationModalRowProps) {
  const { label, onRemove, provided, snapshot } = props;

  return (
    <div
      ref={provided.innerRef}
      className={styles.row}
      style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}
      {...provided.draggableProps}
    >
      <div {...provided.dragHandleProps} className={styles.handle}>
        <MenuOutlined />
      </div>
      <div className={styles.label}>{label}</div>
      <div onClick={onRemove} className={styles.remove}>
        <DeleteOutlined />
      </div>
    </div>
  );
}

type TablePaginationModalRowProps = {
  onRemove: () => void;
  value: string;
  label: string;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
};

export type TablePaginationModalProps = {
  isVisible?: boolean;
  onClose?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: TablePaginationColumn<any>[];
  onSubmit?: (columns: string[]) => void;
  selectedColumns: string[];
};
