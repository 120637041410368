import { MouseEventHandler } from 'react';
import { Button } from 'antd';

import styles from './CatalogEntitySelectionHeader.module.css';

type CatalogEntitySelectionHeaderProps = {
  count?: number;
  label: string;
  cta: string;
  onCtaClick: () => void;
};

export const CatalogEntitySelectionHeader = (
  props: CatalogEntitySelectionHeaderProps,
) => {
  const { count = 0, label, cta, onCtaClick } = props;

  const handleClick: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    onCtaClick();
  };

  return (
    <div className={styles.root}>
      <div>{`${label} (${count})`}</div>
      <Button onClick={handleClick}>{cta}</Button>
    </div>
  );
};
