import { Typography } from 'antd';

import { TranslationsBrandsView } from '../TranslationsBrandsView/TranslationsBrandsView';
import { TranslationsCampaignsView } from '../TranslationsCampaignsView/TranslationsCampaignsView';
import { TranslationsOffersView } from '../TranslationsOffersView/TranslationsOffersView';

export const TranslationsView = () => {
  return (
    <div>
      <Typography.Title level={2}>Marques</Typography.Title>
      <TranslationsBrandsView />
      <br />
      <Typography.Title level={2}>Campagnes</Typography.Title>
      <TranslationsCampaignsView />
      <br />
      <Typography.Title level={2}>Offres</Typography.Title>
      <TranslationsOffersView />
    </div>
  );
};
