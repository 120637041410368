import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import classnames from 'classnames';

import { CompanyMembershipRole } from '@graphql/generated/types';

import styles from './CompanyMembershipRoleTag.module.css';

import { CompanyMembershipRoleTagFragment } from './CompanyMembershipRoleTag.generated';

export const CompanyMembershipRoleTag: React.FC<CompanyMembershipRoleTagProps> =
  (props) => {
    const { className, membership } = props;
    const { t } = useTranslation('companies');

    const colors: Record<CompanyMembershipRole, string> = {
      [CompanyMembershipRole.Admin]: 'green',
      [CompanyMembershipRole.Member]: 'blue',
    };

    const labels: Record<CompanyMembershipRole, string> = {
      [CompanyMembershipRole.Admin]: t('companyMembershipRoleTag.ADMIN'),
      [CompanyMembershipRole.Member]: t('companyMembershipRoleTag.USER'),
    };

    return (
      <Tag
        className={classnames(className, styles.root)}
        color={colors[membership.role]}
      >
        {labels[membership.role]}
      </Tag>
    );
  };

export type CompanyMembershipRoleTagProps = {
  className?: string;
  membership: CompanyMembershipRoleTagFragment;
};
