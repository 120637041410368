import { useTranslation } from 'react-i18next';
import { PageHeader } from 'antd';
import classnames from 'classnames';

import { BrandsTable } from '@components/tables/BrandsTable/BrandsTable';

import styles from '../Redbin.module.css';

import { useRedBinBrandsWithoutLogoQuery } from './BrandsWithoutLogo.generated';

export const BrandsWithoutLogo: React.FC<BrandsWithoutLogoProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation('redbin');

  const query = useRedBinBrandsWithoutLogoQuery({
    variables: { take: 10, skip: 0 },
  });

  return (
    <div className={classnames(className, styles.root)}>
      <PageHeader ghost={false} title={t('BrandsWithoutLogo.title')} />
      <BrandsTable
        query={query}
        data={query.data?.redBinBrandsWithoutLogo}
        activeColumns={[
          'name',
          'campaignCount',
          'offerCount',
          'productCount',
          'createdAt',
          'updatedAt',
        ]}
      />
    </div>
  );
};

export type BrandsWithoutLogoProps = {
  className?: string;
};
