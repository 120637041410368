import { Button } from 'antd';
import classnames from 'classnames';
import qs from 'qs';

import { I18nLink } from '@atoms/Link/Link';
import { CampaignsTable } from '@tables/CampaignsTable/CampaignsTable';

import styles from './BrandIdCampaignsView.module.css';

export const BrandIdCampaignsView: React.FC<BrandIdCampaignsViewProps> = (
  props,
) => {
  const { className, brandId } = props;

  return (
    <div className={classnames(className, styles.root)}>
      <CampaignsTable
        activeColumns={[
          'name',
          'offerCount',
          'status',
          'startsAt',
          'expiresAt',
          'createdAt',
          'updatedAt',
        ]}
        filter={{ brand: { id: { is: brandId } } }}
        title={() => (
          <I18nLink
            to={{
              pathname: `/campaigns/create`,
              search: qs.stringify({ brandId }),
            }}
          >
            <Button>Ajouter une campagne</Button>
          </I18nLink>
        )}
        placeholder={{
          children: (
            <I18nLink
              to={{
                pathname: `/campaigns/create`,
                search: qs.stringify({ brandId }),
              }}
            >
              <Button>Créer une campagne</Button>
            </I18nLink>
          ),
        }}
      />
    </div>
  );
};

export type BrandIdCampaignsViewProps = {
  brandId: string;
  className?: string;
};
