import { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormSelectCompany } from '@components/atoms/FormSelectCompany/FormSelectCompany';
import { includeEntityActions } from '@components/views/CompanyIncludedView/CompanyIncludedView';
import { CreateExclusiveArticle2MutationVariables } from '@components/views/CompanyIncludedView/CompanyIncludedView.generated';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './CompanyIncludeEntityForm.module.css';

import { CreateExclusiveCampaignSelectionMutationVariables } from './CompanyIncludeEntityForm.generated';

export type CompanyIncludeEntityFormValues = {
  companyId: string;
};

export function CompanyIncludeEntityForm(props: CompanyIncludeEntityFormProps) {
  const { className, hideFooter, entityType, entityId, ...rest } = props;

  const { t } = useTranslation('companies');

  const validationSchema = Yup.object({
    companyId: Yup.string().required(),
  }).required();

  const form = useForm<CompanyIncludeEntityFormValues>({
    validationSchema,
  });

  const [createMutation] = includeEntityActions[entityType].create();

  const handleSubmit: FormProps<CompanyIncludeEntityFormValues>['onValid'] =
    async (values) => {
      const companyId = values.companyId;

      let input;
      if (entityType === 'campaignSelection') {
        input = {
          campaignSelectionIds: [entityId],
        };

        const variables: CreateExclusiveCampaignSelectionMutationVariables = {
          companyId,
          input,
        };

        const { data } = await createMutation({ variables });

        return data;
      } else if (entityType === 'article') {
        input = {
          articleIds: [entityId],
        };

        const variables: CreateExclusiveArticle2MutationVariables = {
          companyId,
          input,
        };

        const { data } = await createMutation({ variables });

        return data;
      }
      throw new Error('Problewm with entity');
    };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="CompanyIncludeEntityForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.name}
          required
          label={t('CompanyIncludeEntityForm.fields.name.label')}
          name="companyId"
        >
          <FormSelectCompany name="companyId" />
        </FormItem>
        {hideFooter !== true && (
          <div className={styles.footer}>
            <Button
              loading={form.formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('CompanyIncludeEntityForm.buttons.submit.label')}
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
}

export type CompanyIncludeEntityFormProps =
  ChildFormProps<CompanyIncludeEntityFormValues> & {
    className?: string;
    defaultValues?: DeepPartial<CompanyIncludeEntityFormValues>;
    hideFooter?: boolean;
    entityId: string;
    entityType: 'campaignSelection' | 'article';
  };
