import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Spin } from 'antd';
import classnames from 'classnames';

import { HighlightTargetType } from '@graphql/generated/types';

import { handleMutationErrors } from '@utils/handleMutationErrors';

import styles from './HighlightButton.module.css';

import {
  HighlightButtonHighlightMutationVariables,
  HighlightButtonUnhighlightMutationVariables,
  useArticleHighlightButtonQuery,
  useCategoryHighlightButtonQuery,
  useCompanyDocumentHighlightButtonQuery,
  useCompanyFolderHighlightButtonQuery,
  useHappeningHighlightButtonQuery,
  useHighlightButtonHighlightMutation,
  useHighlightButtonUnhighlightMutation,
  usePublicationHighlightButtonQuery,
} from './HighlightButton.generated';

export const HighlightButton: React.FC<HighlightButtonProps> = (props) => {
  const { className, companyId, targetId, targetType } = props;
  const { t } = useTranslation('highlights');

  const [isSubmitting, setSubmitting] = useState(false);
  const [highlightMutation] = useHighlightButtonHighlightMutation();
  const [unhighlightMutation] = useHighlightButtonUnhighlightMutation();

  const queries: Partial<Record<HighlightTargetType, boolean | undefined>> = {
    [HighlightTargetType.Article]: useArticleHighlightButtonQuery({
      variables: { articleId: targetId },
    }).data?.article?.isHighlighted,
    [HighlightTargetType.Category]: useCategoryHighlightButtonQuery({
      variables: { categoryId: targetId },
    }).data?.category?.isHighlighted,
    [HighlightTargetType.CompanyDocument]:
      useCompanyDocumentHighlightButtonQuery({
        variables: { companyDocumentId: targetId },
      }).data?.companyDocument.isHighlighted,
    [HighlightTargetType.CompanyFolder]: useCompanyFolderHighlightButtonQuery({
      variables: { companyFolderId: targetId },
    }).data?.companyFolder?.isHighlighted,
    [HighlightTargetType.Publication]: usePublicationHighlightButtonQuery({
      variables: { publicationId: targetId },
    }).data?.publication?.isHighlighted,
    [HighlightTargetType.Happening]: useHappeningHighlightButtonQuery({
      variables: { happeningId: targetId },
    }).data?.happening?.isHighlighted,
  };

  const isHighlighted = queries[targetType];

  if (typeof isHighlighted !== 'boolean') {
    return <Spin size="large" className={styles.spinner} />;
  }

  const handleHighlight = async () => {
    if (isSubmitting) return;

    setSubmitting(true);

    const variables: HighlightButtonHighlightMutationVariables = {
      companyId,
      input: {
        targetId,
        targetType,
      },
    };

    try {
      await highlightMutation({
        variables,
        refetchQueries: [
          'ArticleHighlightButton',
          'CategoryHighlightButton',
          'CompanyDocumentHighlightButton',
          'CompanyFolderHighlightButton',
          'PublicationHighlightButton',
        ],
      });
    } catch (err) {
      handleMutationErrors(err, {
        'highlight/target-not-found': t('error:highlight/target-not-found'),
        'highlight/is-already-highlighted': t(
          'error:highlight/is-already-highlighted',
        ),
      });
    }

    setSubmitting(false);
  };

  const handleUnhighlight = async () => {
    if (isSubmitting) return;

    setSubmitting(true);

    const variables: HighlightButtonUnhighlightMutationVariables = {
      highlightableId: targetId,
      highlightableType: targetType,
    };

    try {
      await unhighlightMutation({
        variables,
        refetchQueries: [
          'ArticleHighlightButton',
          'CategoryHighlightButton',
          'CompanyDocumentHighlightButton',
          'CompanyFolderHighlightButton',
          'PublicationHighlightButton',
        ],
      });
    } catch (err) {
      handleMutationErrors(err, {
        'highlight/target-not-found': t('error:highlight/target-not-found'),
      });
    }

    setSubmitting(false);
  };

  return (
    <>
      {!isHighlighted ? (
        <Popconfirm
          onConfirm={handleHighlight}
          title={t('highlightButton.highlight.confirm')}
        >
          <Button
            loading={isSubmitting}
            type="primary"
            className={classnames(className, styles.root)}
          >
            {t('highlightButton.highlight.label')}
          </Button>
        </Popconfirm>
      ) : (
        <Popconfirm
          onConfirm={handleUnhighlight}
          title={t('highlightButton.unhighlight.confirm')}
        >
          <Button
            loading={isSubmitting}
            className={classnames(className, styles.root)}
          >
            {t('highlightButton.unhighlight.label')}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export type HighlightButtonProps = {
  className?: string;
  companyId?: string;
  targetId: string;
  targetType: HighlightTargetType;
};
