import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import classnames from 'classnames';

import {
  GameUpdateForm,
  GameUpdateFormProps,
} from '@components/forms/GameUpdateForm/GameUpdateForm';

import styles from './GameUpdateView.module.css';

import { GameUpdateViewFragment } from './GameUpdateView.generated';

export const GameUpdateView: React.FC<GameUpdateViewProps> = (props) => {
  const { className, game } = props;

  const { t, i18n } = useTranslation('games');
  const history = useHistory();

  const handleSuccess: GameUpdateFormProps['onSuccess'] = (result) => {
    history.push(
      `/${i18n.language}/games/list/${result.gameUpdate.game.id}/update`,
    );
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.wrapper}>
        <Typography.Title>{t('GameUpdateView.title')}</Typography.Title>
        <GameUpdateForm game={game} onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export type GameUpdateViewProps = {
  className?: string;
  game: GameUpdateViewFragment;
};
