import { useTranslation } from 'react-i18next';
import { Descriptions, Typography } from 'antd';

import { FileType } from '@graphql/generated/types';

import { COVER_RESOLUTION_INFO, COVER_SIZE_INFO } from '@utils/constants';

import { ArticleAccessModeTag } from '@components/atoms/ArticleAccessModeTag/ArticleAccessModeTag';
import { PageHeader } from '@molecules/PageHeader/PageHeader';

import {
  ArticleHeaderFragment,
  useArticleHeaderUpdateMutation,
} from './ArticleHeader.generated';

export const ArticleHeader: React.FC<ArticleHeaderProps> = (props) => {
  const { className, article } = props;
  const { t } = useTranslation(['communication', 'common']);

  const [mutation] = useArticleHeaderUpdateMutation();

  const handleUpdate = async (pictureId: string) => {
    await mutation({
      variables: {
        articleId: article.id,
        input: {
          coverId: pictureId,
        },
      },
    });
  };

  return (
    <PageHeader
      cover={article.cover}
      logoProps={{ mode: 'contain' }}
      className={className}
      showNav
      uploadCoverProps={{
        fileType: FileType.ArticleCover,
        onUploadPicture: (pictureId) => handleUpdate(pictureId),
        title: 'Couverture',
        picture: article.cover,
        info: t('picture.advice', {
          resolution: COVER_RESOLUTION_INFO,
          size: COVER_SIZE_INFO,
          ns: 'common',
        }),
      }}
    >
      <Typography.Title level={2} ellipsis={{ rows: 2 }}>
        {article.title}
      </Typography.Title>

      <Descriptions column={1} size="small">
        <Descriptions.Item label={t('articles.fields.accessMode')}>
          <ArticleAccessModeTag fragment={article} />
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export type ArticleHeaderProps = {
  className?: string;
  article: ArticleHeaderFragment;
};
