import { Spin } from 'antd';
import classnames from 'classnames';

import { CampaignUpdateForm } from '@components/forms/CampaignUpdateForm/CampaignUpdateForm';

import { NotFoundView } from '@views/Errors/NotFoundView/NotFoundView';

import styles from './CampaignIdUpdateView.module.css';

import { useCampaignIdUpdateViewQuery } from './CampaignIdUpdateView.generated';

export const CampaignIdUpdateView: React.FC<CampaignIdUpdateViewProps> = (
  props,
) => {
  const { className, campaignId } = props;

  const query = useCampaignIdUpdateViewQuery({
    variables: { campaignId },
    notifyOnNetworkStatusChange: true,
  });

  const campaign = query?.data?.campaign;

  if (query.loading && !campaign) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!campaign) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <CampaignUpdateForm campaign={campaign} />
    </div>
  );
};

export type CampaignIdUpdateViewProps = {
  className?: string;
  campaignId: string;
};
