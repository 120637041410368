import { useTranslation } from 'react-i18next';

import { I18nLink } from '@components/atoms/Link/Link';
import {
  ControllerChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@components/organisms/TablePagination/TablePagination';

import { SubventionSchemasTableRecordFragment } from './SubventionSchemasTable.generated';

type RecordType = SubventionSchemasTableRecordFragment;

type Column = 'name' | 'status';

export const SubventionSchemasTable = (props: SubventionSchemasTableProps) => {
  const { data, query, ...rest } = props;

  const { t } = useTranslation('subvention-schemas');

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('tables.headers.name'),
      dataIndex: ['name'],
      render: (_, schema) => (
        <I18nLink to={`/subventions/schemas/${schema.id}`}>
          {schema.name}
        </I18nLink>
      ),
    },
    {
      key: 'status',
      title: t('tables.headers.status'),
      dataIndex: ['status'],
    },
  ];

  return (
    <div>
      <TablePagination
        id="SubventionSchemasTable"
        query={query}
        data={data}
        columns={columns}
        {...rest}
      />
    </div>
  );
};

export type SubventionSchemasTableProps = ControllerChildTablePaginationProps<
  RecordType,
  Column
>;
