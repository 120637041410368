import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import classnames from 'classnames';

import { CompanyIncludeEntityModal } from '@components/modals/CompanyIncludeEntityModal/CompanyIncludeEntityModal';
import { IsExclusiveToggle } from '@components/organisms/IsExclusiveToggle/IsExclusiveToggle';
import { CompaniesTable } from '@components/tables/CompaniesTable/CompaniesTable';
import { useCompaniesTableQuery } from '@components/tables/CompaniesTable/CompaniesTable.generated';

import styles from './CompanyIncludedView.module.css';

import {
  useCreateExclusiveArticle2Mutation,
  useCreateExclusiveCampaignSelection2Mutation,
  useRemoveExclusiveArticle2Mutation,
  useRemoveExclusiveCampaignSelection2Mutation,
} from './CompanyIncludedView.generated';

type EntityType = 'campaignSelection' | 'article';
type ActionType = 'create' | 'remove';
export const includeEntityActions: Record<
  EntityType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<ActionType, any>
> = {
  campaignSelection: {
    create: useCreateExclusiveCampaignSelection2Mutation,
    remove: useRemoveExclusiveCampaignSelection2Mutation,
  },
  article: {
    create: useCreateExclusiveArticle2Mutation,
    remove: useRemoveExclusiveArticle2Mutation,
  },
};
export const CompanyIncludedView: React.FC<CompanyIncludedViewProps> = (
  props,
) => {
  const { className, entityId, entityType } = props;
  const [isOpen, setOpen] = useState(false);
  const [removeMutation] = includeEntityActions[entityType].remove();
  const { t } = useTranslation('companies');
  let filter;

  if (entityType === 'campaignSelection') {
    filter = {
      includedCampaignSelections: { SOME: { id: { is: entityId } } },
    };
  } else if (entityType === 'article') {
    filter = {
      includedArticles: { SOME: { id: { is: entityId } } },
    };
  }

  const query = useCompaniesTableQuery({
    variables: {
      filter,
      take: 10,
      skip: 0,
    },
  });

  const handleDelete = (companyId: string) => async () => {
    let input;

    if (entityType === 'campaignSelection') {
      input = {
        campaignSelectionIds: [entityId],
      };
    } else if (entityType === 'article') {
      input = {
        articleIds: [entityId],
      };
    }

    await removeMutation({
      variables: {
        companyId,
        input,
      },
    });

    await query.refetch();
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div>
        <CompaniesTable
          title={() => (
            <Space>
              {entityType !== 'article' && (
                <IsExclusiveToggle
                  entityType={entityType}
                  entityId={entityId}
                />
              )}
              <Button
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => setOpen(true)}
              >
                Inclure une société
              </Button>
            </Space>
          )}
          activeColumns={['id', '__typename', 'createdAt', 'updatedAt']}
          query={query}
          action={(record) => (
            <Popconfirm
              title={t('includedCompanies.action.delete.warning')}
              okText={t('includedCompanies.action.delete.button')}
              okType="danger"
              onConfirm={handleDelete(record.id)}
            >
              <Button type="text" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        />
        <CompanyIncludeEntityModal
          entityId={entityId}
          entityType={entityType}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      </div>
    </div>
  );
};

export type CompanyIncludedViewProps = {
  className?: string;
  entityId: string;
  entityType: EntityType;
};
