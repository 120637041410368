import { Controller } from 'react-hook-form';
import { Button } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import classnames from 'classnames';
import * as Yup from 'yup';

import { OfferCommonFormValues } from '../OfferCommonForm/OfferCommonForm';

import { ProductType } from '@graphql/generated/types';

import { ProductsTable } from '@components/tables/ProductsTable/ProductsTable';
import { Card } from '@atoms/Card/Card';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferTicketForm.module.css';

import {
  OfferTicketFormCreateMutation,
  OfferTicketFormCreateMutationVariables,
  useOfferTicketFormCreateMutation,
} from './OfferTicketForm.generated';

type OfferTicketValues = {
  productId: string;
};

export function OfferTicketForm(props: OfferTicketProps) {
  const { className, commonValues, onBack, ...rest } = props;

  const validationSchema = Yup.object({
    productId: Yup.string().required(),
  }).required();

  const form = useForm<OfferTicketValues>({
    validationSchema,
    defaultValues: { productId: props.commonValues.productId },
  });

  const [mutation] = useOfferTicketFormCreateMutation();

  const handleSubmit: FormProps<OfferTicketValues>['onValid'] = async (
    values,
  ) => {
    const variables: OfferTicketFormCreateMutationVariables = {
      input: {
        brandId: commonValues.brandId,
        campaignId: commonValues.campaignId,
        categoryIds: commonValues.categoryIds,
        localeId: commonValues.localeId,
        url: commonValues.url,
        name: commonValues.name,
        descriptionDelta: commonValues.description,
        storeIds: commonValues.storeIds,
        productId: values.productId,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferTicketForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.product}
          label="Sélectionnez un produit"
          name="productId"
        >
          <Controller
            name="productId"
            render={({ field }) => (
              <ProductsTable
                filter={{
                  brand: { id: { is: commonValues.brandId } },
                  type: { in: [ProductType.Ticket] },
                  offer: { id: { is: null } },
                }}
                activeColumns={['name']}
                selectable={{
                  type: 'radio',
                  onChange: (keys) => {
                    field.onChange(keys[0]);
                    field.onBlur();
                  },
                  value: field.value ? [field.value] : [],
                }}
              />
            )}
          />
        </FormItem>
        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Créer l&apos;offre
          </Button>
          {!!onBack && (
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onBack}
              htmlType="button"
            >
              Revenir en arrière
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
}

export type OfferTicketProps = ChildFormProps<
  OfferTicketValues,
  OfferTicketFormCreateMutation
> & {
  className?: string;
  onBack?: () => void;
  commonValues: OfferCommonFormValues;
};
