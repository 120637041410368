import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Typography } from 'antd';
import classnames from 'classnames';

import { CampaignSelectionCampaignAddModal } from '@components/modals/CampaignSelectionCampaignAddModal/CampaignSelectionCampaignAddModal';
import { CampaignsTable } from '@components/tables/CampaignsTable/CampaignsTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './CampaignSelectionIdCampaignsView.module.css';

import {
  useCampaignSelectionIdCampaignsRemoveMutation,
  useCampaignSelectionIdCampaignsViewQuery,
} from './CampaignSelectionIdCampaignsView.generated';

export const CampaignSelectionIdCampaignsView: React.FC<CampaignSelectionIdCampaignsViewProps> =
  (props) => {
    const { className, campaignSelectionId } = props;
    const [isOpen, setOpen] = useState(false);

    const [removeMutation] = useCampaignSelectionIdCampaignsRemoveMutation();

    const query = useCampaignSelectionIdCampaignsViewQuery({
      variables: { campaignSelectionId },
      notifyOnNetworkStatusChange: true,
    });

    const handleDelete = (campaignId: string) => async () => {
      await removeMutation({
        variables: {
          campaignSelectionId,
          campaignIds: [campaignId],
        },
      });
      await query.refetch();
    };

    const campaignSelection = query?.data?.campaignSelection;

    if (query.loading && !campaignSelection) {
      return <Spin size="large" className={styles.spinner} />;
    } else if (!campaignSelection) {
      return <NotFoundView />;
    }

    return (
      <div className={classnames(className, styles.root)}>
        <Typography.Title level={3}>Campagnes</Typography.Title>
        <CampaignsTable
          query={query}
          title={() => (
            <Button onClick={() => setOpen(true)}>Ajouter une campagne</Button>
          )}
          data={campaignSelection.campaignPagination}
          activeColumns={['name', 'brand']}
          action={(record) => (
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer cette campagne ?"
              okText="Supprimer"
              okType="danger"
              onConfirm={handleDelete(record.id)}
            >
              <Button type="text" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        />
        <CampaignSelectionCampaignAddModal
          campaignSelectionId={campaignSelection.id}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
        />
      </div>
    );
  };

export type CampaignSelectionIdCampaignsViewProps = {
  campaignSelectionId: string;
  className?: string;
};
