import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';

import { generateColumnFilter } from '../_Filters/FilterDropdown/FilterDropdown';

import {
  AdminCommentTargetType,
  BrandsArgsFilter,
} from '@graphql/generated/types';

import { BrandMergeDuplicateFormProps } from '@components/forms/BrandMergeDuplicateForm/BrandMergeDuplicateForm';
import { BrandMergeDuplicateModal } from '@components/modals/BrandMergeDuplicateModal/BrandMergeDuplicateModal';
import { I18nLink } from '@atoms/Link/Link';
import { TableRendererAvatar } from '@molecules/TableRendererAvatar/TableRendererAvatar';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  BrandsTableRecordFragment,
  useBrandsTableQuery,
} from './BrandsTable.generated';

type RecordType = BrandsTableRecordFragment;

type Column = 'name' | 'logo' | 'offerCount' | 'campaignCount' | 'productCount';

export function BrandsTable(props: BrandsTableProps) {
  const {
    className,
    filter,
    query: propQuery,
    data: propData,
    title,
    ...rest
  } = props;
  const { t, i18n } = useTranslation('brands');
  const history = useHistory();

  const [isMergeDuplicateModalOpen, setIsMergeDuplicateModalOpen] =
    useState(false);

  const query =
    propQuery ||
    useBrandsTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'logo',
      title: t('table.headers.logo'),
      dataIndex: ['logo'],
      width: 64,
      render: (logo, node) => (
        <TableRendererAvatar picture={logo} placeholder={node.name[0]} />
      ),
    },
    {
      key: 'name',
      title: t('table.headers.name'),
      sorter: true,
      dataIndex: ['name'],
      render: (name, node) => (
        <I18nLink to={`/brands/list/${node.id}`}>{name}</I18nLink>
      ),
      ...generateColumnFilter(query, 'name', 'search'),
    },
    {
      key: 'offerCount',
      title: t('table.headers.offerCount'),
      dataIndex: ['offersPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'campaignCount',
      title: t('table.headers.campaignCount'),
      dataIndex: ['campaignPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'productCount',
      title: t('table.headers.productCount'),
      dataIndex: ['productPagination', 'totalCount'],
      sorter: true,
    },
  ];

  const handleMergeDuplicateSuccess: BrandMergeDuplicateFormProps['onSuccess'] =
    (result) => {
      history.push(
        `/${i18n.language}/brands/list/${result.brandMergeDuplicate.brand.id}`,
      );
    };

  return (
    <div className={className}>
      <TablePagination
        id="BrandsTable"
        query={query}
        columns={columns}
        data={propData || query.data?.brandsPagination}
        title={
          title ||
          (() => (
            <>
              <Space>
                <Button onClick={() => setIsMergeDuplicateModalOpen(true)}>
                  {t('buttons.mergeDuplicate')}
                </Button>
              </Space>
            </>
          ))
        }
        adminCommentTargetType={AdminCommentTargetType.Brand}
        {...rest}
      />

      <BrandMergeDuplicateModal
        isOpen={isMergeDuplicateModalOpen}
        onClose={() => {
          setIsMergeDuplicateModalOpen(false);
        }}
        onSuccess={handleMergeDuplicateSuccess}
      />
    </div>
  );
}

export type BrandsTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  filter?: BrandsArgsFilter;
};
