import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import classNames from 'classnames';
import Dinero from 'dinero.js';

import styles from './SubventionConsumptionCard.module.css';

import { SubventionConsumptionCardFragment } from './SubventionConsumptionCard.generated';

type SubventionConsumptionCardProps = {
  className?: string;
  subvention: SubventionConsumptionCardFragment;
};

export function SubventionConsumptionCard(
  props: SubventionConsumptionCardProps,
) {
  const { className, subvention } = props;
  const { t } = useTranslation('subventions');

  const progress = subvention.budgetConsumed / subvention.budgetTotal;

  return (
    <Card
      size="small"
      title="Consommation"
      className={classNames(styles.root, className)}
    >
      <div className={styles.content}>{Math.round(progress * 100)}%</div>
      <div className={styles.footer}>
        <div className={styles.progress}>
          <div className={styles.progressValues}>
            <div className={styles.progressValueCurrent}>
              {t('subventionConsumptionCard.budgetConsumed', {
                amount: Dinero({
                  amount: subvention.budgetConsumed,
                  currency: 'EUR',
                }),
              })}
            </div>
            <div className={styles.progressValueEnd}>
              {t('subventionConsumptionCard.budgetTotal', {
                amount: Dinero({
                  amount: subvention.budgetTotal,
                  currency: 'EUR',
                }),
              })}
            </div>
            <div className={styles.progressValueSeparator}>
              <RightOutlined />
            </div>
          </div>
          <div className={styles.progressBar}>
            <div
              style={{ width: progress * 100 + '%' }}
              className={styles.progressFill}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
