import { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button } from 'antd';

import { Modal } from '@components/organisms/Modal/Modal';

import styles from './OfferMetadataPreviewButton.module.css';

import { useOfferMetadataPreviewButtonQuery } from './OfferMetadataPreviewButton.generated';

export const OfferMetadataPreviewButton = (
  props: OfferMetadataPreviewButtonProps,
) => {
  const { className, offerId } = props;

  const { data, loading } = useOfferMetadataPreviewButtonQuery({
    variables: { offerId },
  });

  const [isOpen, setOpen] = useState(false);

  const metadata = data?.offer?.metadata.provider;

  return (
    <>
      <Button
        className={className}
        htmlType="button"
        loading={loading}
        disabled={!metadata?.raw}
        onClick={() => setOpen(true)}
      >
        Données fournisseur
      </Button>
      <Modal
        title="Données fournisseur"
        isOpen={isOpen}
        width={700}
        onClose={() => setOpen(false)}
        footer={[
          <Button onClick={() => setOpen(false)} key="cancel" htmlType="button">
            Fermer
          </Button>,
        ]}
      >
        {!!metadata?.raw?.name && (
          <div className={styles.name}>
            <div className={styles.label}>Nom</div>
            <div className={styles.value}>{metadata.raw.name}</div>
          </div>
        )}
        {!!metadata?.raw?.description && (
          <div className={styles.description}>
            <div className={styles.label}>Description</div>
            <ReactQuill
              modules={{ toolbar: false }}
              readOnly
              value={metadata.raw.description}
              theme="snow"
              className={styles.value}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export type OfferMetadataPreviewButtonProps = {
  className?: string;
  offerId: string;
};
