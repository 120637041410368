import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactQuill from 'react-quill';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { QueryResult } from '@apollo/client';
import { Button, List, Skeleton } from 'antd';
import classnames from 'classnames';
import { format } from 'date-fns';

import { AdminCommentTargetType, Maybe } from '@graphql/generated/types';

import { useAuth } from '@hooks/auth/auth.hooks';
import { Avatar } from '@components/atoms/Avatar/Avatar';
import { I18nLink } from '@components/atoms/Link/Link';
import { AdminCommentModal } from '@components/modals/AdminCommentModal/AdminCommentModal';

import styles from './AdminCommentList.module.css';

import {
  AdminCommentListQuery,
  AdminCommentListQueryVariables,
  AdminCommentRecordFragment,
  useAdminCommentListDeleteMutation,
  useAdminCommentListQuery,
} from './AdminCommentList.generated';

export const ADMIN_COMMENT_PAGE_SIZE = 25;

type RecordType = AdminCommentRecordFragment;

type AdminCommentListProps = {
  className?: string;
  targetType: AdminCommentTargetType;
  targetId: string;
  query?: QueryResult<
    AdminCommentListQuery,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AdminCommentListQueryVariables & any
  >;
  data?: Maybe<{
    totalCount: number;
    nodes: RecordType[];
  }>;
};

const AdminCommentList = (props: AdminCommentListProps) => {
  const {
    className,
    query: propQuery,
    data: propData,
    targetId,
    targetType,
  } = props;

  const { viewer } = useAuth();

  const { t } = useTranslation('adminComments');

  const [recordToUpdate, setRecordToUpdate] = useState<RecordType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState<RecordType[]>(propData?.nodes || []);

  const [deleteMutation] = useAdminCommentListDeleteMutation();

  const query =
    propQuery ||
    useAdminCommentListQuery({
      variables: {
        skip: 0,
        take: ADMIN_COMMENT_PAGE_SIZE,
      },
    });

  const handleDelete = async (adminCommentId: string) => {
    if (!confirm(t('delete.confirm.label'))) return;

    await deleteMutation({ variables: { adminCommentId } });
    await query.refetch(query.variables);
  };

  useEffect(() => {
    if (query.data && query.data.adminCommentPagination.nodes.length > 0) {
      setItems(query.data?.adminCommentPagination.nodes || []);
    }
  }, [query.data?.adminCommentPagination.nodes]);

  const loadMore = () => {
    query.fetchMore({
      variables: { skip: ADMIN_COMMENT_PAGE_SIZE * currentPage },
    });
    setCurrentPage(currentPage + 1);
  };

  return (
    <div
      className={classnames(className, styles.root)}
      id="scrollableAdminCommentList"
    >
      <InfiniteScroll
        dataLength={items.length}
        next={loadMore}
        className={styles.infiniteScrollList}
        inverse={true}
        hasMore={
          !!(
            query.data &&
            items.length < query.data?.adminCommentPagination.totalCount
          )
        }
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableAdminCommentList"
      >
        {items.map((item) => (
          <List.Item
            key={item.id}
            actions={
              viewer?.id === item.author.id
                ? [
                    <Button
                      key="admin-comment-list-edit"
                      type="text"
                      onClick={() => setRecordToUpdate(item)}
                    >
                      <EditOutlined />
                    </Button>,
                    <Button
                      key="admin-comment-list-delete"
                      type="text"
                      danger
                      onClick={() => handleDelete(item.id)}
                    >
                      <DeleteOutlined />
                    </Button>,
                  ]
                : []
            }
          >
            <List.Item.Meta
              avatar={<Avatar avatar={item.author.avatar} />}
              title={
                <I18nLink to={`/users/list/${item.author.id}`}>
                  {item.author.firstName} {item.author.lastName}
                </I18nLink>
              }
              description={format(item.createdAt, 'dd-MM-yyyy HH:mm')}
            />
            <ReactQuill
              value={item.contentDelta}
              readOnly={true}
              modules={{ toolbar: false }}
            />
          </List.Item>
        ))}
      </InfiniteScroll>

      <AdminCommentModal
        isOpen={!!recordToUpdate}
        onClose={() => setRecordToUpdate(undefined)}
        targetId={targetId}
        targetType={targetType}
        defaultValues={recordToUpdate}
      />
    </div>
  );
};

export default AdminCommentList;
