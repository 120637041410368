import { noop } from 'lodash';

import { SSOProviderOIDCForm } from '@components/forms/SSOProviderOIDCForm/SSOProviderOIDCForm';
import { Modal } from '@components/organisms/Modal/Modal';

import { SsoProviderOidcModalFragment } from './SSOProviderOIDCModal.generated';

export type SSOProviderOIDCModalProps = {
  companyId: string;
  ssoProvider?: SsoProviderOidcModalFragment;
  onClose?: () => void;
  isOpen?: boolean;
};

export function SSOProviderOIDCModal(props: SSOProviderOIDCModalProps) {
  const { companyId, ssoProvider, onClose = noop, isOpen = false } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = async () => {
    handleClose();
  };

  return (
    <Modal
      title="SSOProviderOIDCModal"
      onClose={onClose}
      isOpen={isOpen}
      form="SSOProviderOIDCForm"
    >
      <SSOProviderOIDCForm
        companyId={companyId}
        ssoProvider={ssoProvider}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
}
