import { TranslationGenerator } from '@components/organisms/TranslationGenerator/TranslationGenerator';

import {
  useGeneratedTranslationOffersCountQuery,
  useMissingTranslationOffersCountQuery,
  useOfferTranslationGenerateMutation,
  useTranslationOffersCountQuery,
} from './TranslationsOffersView.generated';

export const TranslationsOffersView = () => {
  const countQuery = useTranslationOffersCountQuery();
  const [translationMutation] = useOfferTranslationGenerateMutation();

  return (
    <TranslationGenerator
      useMissingQuery={useMissingTranslationOffersCountQuery}
      useGeneratedQuery={useGeneratedTranslationOffersCountQuery}
      countQuery={countQuery}
      generateMutation={translationMutation}
    />
  );
};
