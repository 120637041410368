import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import classnames from 'classnames';

import styles from './ServerErrorView.module.css';

export const ServerErrorView: React.FC<ServerErrorView> = (props) => {
  const { className, returnTo = '/' } = props;

  return (
    <Result
      className={classnames(className, styles.root)}
      status="500"
      title="Erreur inattendue"
      subTitle="Une erreur inattendue est survenue"
      extra={
        <Link to={returnTo}>
          <Button type="primary">Beam me up, Scotty</Button>
        </Link>
      }
    />
  );
};

export type ServerErrorView = {
  className?: string;
  returnTo?: string;
};
