import { useTranslation } from 'react-i18next';

import { TableRendererLocationFactory } from '@components/molecules/TableRendererLocation/TableRendererLocation';
import { I18nLink } from '@atoms/Link/Link';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  StoresTableQueryVariables,
  StoresTableRecordFragment,
  useStoresTableQuery,
} from './StoresTable.generated';

type RecordType = StoresTableRecordFragment;

type Column =
  | 'brand'
  | 'offerCount'
  | 'address1'
  | 'address2'
  | 'city'
  | 'zip'
  | 'province'
  | 'country'
  | 'formatted'
  | 'latitude'
  | 'longitude'
  | 'coordinate';

export function StoresTable(props: StoresTableProps) {
  const {
    className,
    query: propQuery,
    data: propData,
    filter,
    ...rest
  } = props;

  const { t } = useTranslation('stores');

  const query =
    propQuery ||
    useStoresTableQuery({
      variables: {
        skip: 0,
        take: 10,
        filter,
      },
    });

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'brand',
      title: t('table.headers.brand'),
      dataIndex: ['brand', 'name'],
      render: (name, node) => (
        <I18nLink to={`/brands/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'offerCount',
      title: t('table.headers.offerCount'),
      dataIndex: ['offerPagination', 'totalCount'],
      sorter: true,
    },
    {
      key: 'address1',
      title: t('table.headers.address1'),
      dataIndex: ['address', 'address1'],
    },
    {
      key: 'address2',
      title: t('table.headers.address2'),
      dataIndex: ['address', 'address2'],
    },
    {
      key: 'city',
      title: t('table.headers.city'),
      dataIndex: ['address', 'city'],
    },
    {
      key: 'zip',
      title: t('table.headers.zip'),
      dataIndex: ['address', 'zip'],
    },
    {
      key: 'province',
      title: t('table.headers.province'),
      dataIndex: ['address', 'province'],
    },
    {
      key: 'country',
      title: t('table.headers.country'),
      dataIndex: ['address', 'country'],
    },
    {
      key: 'formatted',
      title: t('table.headers.formatted'),
      dataIndex: ['address', 'formattedAddress'],
      render: (name, node) => (
        <I18nLink to={`/stores/list/${node.id}`}>{name}</I18nLink>
      ),
    },
    {
      key: 'latitude',
      title: t('table.headers.latitude'),
      dataIndex: ['address', 'latitude'],
    },
    {
      key: 'longitude',
      title: t('table.headers.longitude'),
      dataIndex: ['address', 'longitude'],
    },
    {
      key: 'coordinate',
      title: t('table.headers.coordinate'),
      dataIndex: ['address'],
      render: TableRendererLocationFactory(),
    },
  ];

  console.log(query);

  return (
    <div className={className}>
      <TablePagination
        id="StoresTable"
        query={query}
        columns={columns}
        data={propData || query.data?.storePagination}
        {...rest}
      />
    </div>
  );
}

export type StoresTableProps = ChildTablePaginationProps<RecordType, Column> & {
  className?: string;
  filter?: StoresTableQueryVariables['filter'];
};
