import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Popconfirm } from 'antd';

import {
  Company,
  QuestionCategory as QuestionCategory,
} from '@graphql/generated/types';

import { QuestionCategoryCreateModal } from '@components/modals/QuestionCategoryCreateModal/QuestionCategoryCreateModal';
import { QuestionCategoryUpdateModal } from '@components/modals/QuestionCategoryUpdateModal/QuestionCategoryUpdateModal';
import {
  ChildTablePaginationProps,
  TablePagination,
  TablePaginationColumn,
} from '@organisms/TablePagination/TablePagination';

import {
  QuestionCategoryDeleteFormMutationVariables,
  QuestionCategoryTableRecordFragment,
  useQuestionCategoryDeleteFormMutation,
  useQuestionCategoryTableQuery,
} from './QuestionCategoryTable.generated';

type RecordType = QuestionCategoryTableRecordFragment;

type Column = 'createdAt' | 'name' | 'description' | 'active' | 'actions';

export function QuestionCategoryTable(props: QuestionCategoryTableProps) {
  const { className, companyId, query: propQuery, ...rest } = props;

  const { t } = useTranslation('questionCategory');

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [currentUpdateRow, setCurrentUpdateRow] =
    useState<QuestionCategoryTableRecordFragment | null>(null);

  const query =
    propQuery ||
    useQuestionCategoryTableQuery({
      variables: {
        companyId,
        skip: 0,
        take: 10,
      },
    });

  const [mutation] = useQuestionCategoryDeleteFormMutation();

  const handleDelete = async (
    companyQuestionCategoryId: QuestionCategory['id'],
  ) => {
    const variables: QuestionCategoryDeleteFormMutationVariables = {
      questionCategoryId: companyQuestionCategoryId,
    };

    await mutation({ variables });

    await query.refetch(query.variables);
  };

  const columns: TablePaginationColumn<RecordType>[] = [
    {
      key: 'name',
      title: t('fields.name'),
      dataIndex: ['name'],
    },
    {
      key: 'description',
      title: t('fields.description'),
      dataIndex: ['description'],
    },
    {
      key: 'active',
      title: t('fields.active'),
      render: (_, questionCategory) => (
        <Checkbox checked={questionCategory.active} disabled />
      ),
    },
    {
      key: 'actions',
      title: t('table.headers.actions'),
      render: (_, node) => (
        <>
          <Button type="default" onClick={() => setCurrentUpdateRow(node)}>
            {t('buttons.update.label')}
          </Button>

          <Popconfirm
            title={t('buttons.delete.confirm')}
            okText={t('buttons.delete.ok')}
            okType="danger"
            onConfirm={() => handleDelete(node.id)}
          >
            <Button type="default" danger>
              {t('buttons.delete.label')}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className={className}>
      <TablePagination
        id="QuestionCategoryTable"
        // @ts-expect-error typing error after lib update
        query={query}
        columns={columns}
        data={query.data?.company?.questionCategoryPagination}
        title={() => (
          <Button onClick={() => setIsCreateOpen(true)}>
            {t('buttons.create.label')}
          </Button>
        )}
        {...rest}
      />
      <QuestionCategoryCreateModal
        isOpen={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onSuccess={async () => {
          await query.refetch(query.variables);
        }}
        subprops={{ defaultValues: { companyId } }}
      />
      {currentUpdateRow && (
        <QuestionCategoryUpdateModal
          isOpen={!!currentUpdateRow}
          onClose={() => setCurrentUpdateRow(null)}
          onSuccess={async () => {
            await query.refetch(query.variables);
          }}
          subprops={{ questionCategory: currentUpdateRow }}
        />
      )}
    </div>
  );
}

export type QuestionCategoryTableProps = ChildTablePaginationProps<
  RecordType,
  Column
> & {
  className?: string;
  companyId: Company['id'];
};
