import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Space, Spin } from 'antd';
import classnames from 'classnames';

import { Offer } from '@graphql/generated/types';

import { StoreCreateFormProps } from '@components/forms/StoreCreateForm/StoreCreateForm';
import { StoreCreateModal } from '@components/modals/StoreCreateModal/StoreCreateModal';
import { StoreSelectModal } from '@components/modals/StoreSelectModal/StoreSelectModal';
import {
  StoreRemoveOfferButton,
  StoreRemoveOfferButtonProps,
} from '@components/organisms/StoreRemoveOfferButton/StoreRemoveOfferButton';
import { StoresTable } from '@components/tables/StoresTable/StoresTable';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './OfferIdStoresView.module.css';

import {
  useOfferAddStoresMutation,
  useOfferIdStoresViewQuery,
} from './OfferIdStoresView.generated';

export const OfferIdStoresView: React.FC<OfferIdStoresViewProps> = (props) => {
  const { t } = useTranslation('stores');

  const { className, offerId } = props;

  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);

  const [isAdding, setAdding] = useState(false);
  const [storesAdd] = useOfferAddStoresMutation({
    refetchQueries: ['OfferIdView'],
  });

  const query = useOfferIdStoresViewQuery({
    variables: {
      offerId,
      take: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const offer = query?.data?.offer;

  if (query.loading && !offer) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!offer) {
    return <NotFoundView />;
  }

  const handleCreateStoreSuccess: StoreCreateFormProps['onSuccess'] =
    async () => {
      setIsCreateStoreModalOpen(false);
      await query.refetch();
    };

  const handleAdd = async (storeIds: string[]) => {
    try {
      await storesAdd({
        variables: {
          input: {
            offerIds: [offer.id],
            storeIds,
          },
        },
      });
      setAdding(false);
      await query.refetch();
    } catch (err) {
      message.error('Une erreur est survenue');
      console.error(err);
    }
  };

  const handleRemoveSuccess: StoreRemoveOfferButtonProps['onSuccess'] =
    async () => {
      await query.refetch();
    };

  return (
    <div className={classnames(className, styles.root)}>
      <StoreCreateModal
        isOpen={isCreateStoreModalOpen}
        onClose={() => {
          setIsCreateStoreModalOpen(false);
        }}
        onSuccess={handleCreateStoreSuccess}
        brandId={offer.brand.id}
      />

      <StoreSelectModal
        isOpen={isAdding}
        onClose={() => setAdding(false)}
        onSubmit={handleAdd}
        filter={{
          AND: [
            { brand: { id: { is: offer.brand.id } } },
            { offers: { id: { isNot: offer.id } } },
          ],
        }}
      />

      <StoresTable
        activeColumns={[
          'address1',
          'address2',
          'zip',
          'city',
          'country',
          'province',
          'formatted',
        ]}
        query={query}
        data={query?.data?.offer?.storePagination}
        title={() => (
          <Space>
            <Button onClick={() => setIsCreateStoreModalOpen(true)}>
              {t('buttons.create')}
            </Button>
            <Button onClick={() => setAdding(true)}>
              <PlusOutlined /> {t('buttons.addStores')}
            </Button>
          </Space>
        )}
        action={(store) => (
          <StoreRemoveOfferButton
            store={store}
            offer={offer}
            mode="iconAndText"
            onSuccess={handleRemoveSuccess}
          />
        )}
      />
    </div>
  );
};

export type OfferIdStoresViewProps = {
  className?: string;
  offerId: Offer['id'];
};
