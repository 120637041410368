import { QueryResult } from '@apollo/client';
import { Progress, Spin, Tooltip, Typography } from 'antd';

import styles from './TranslationGeneratorRow.module.css';

import {
  TranslationGeneratorModal,
  TranslationGeneratorModalProps,
} from './TranslationGeneratorModal';

export const TranslationGeneratorRow = (
  props: TranslationGeneratorRowProps,
) => {
  const {
    locale,
    useMissingQuery,
    useGeneratedQuery,
    totalCount,
    generateMutation,
  } = props;

  const missingQuery = useMissingQuery({
    variables: { localeId: locale.code },
  });

  const generatedQuery = useGeneratedQuery({
    variables: { localeId: locale.code },
  });

  const translationsCount =
    totalCount - (missingQuery.data?.entities.totalCount || 0);

  const isLoading = missingQuery.loading && !missingQuery.data;

  const translationCount =
    totalCount - (missingQuery.data?.entities.totalCount || 0);
  const generatedCount = generatedQuery.data?.entities.totalCount || 0;
  const manualCount = translationCount - generatedCount;

  const totalPercent = (translationCount / totalCount) * 100;
  const manualPercent = (manualCount / totalCount) * 100;

  const handleRefetch = async () => {
    await Promise.all([missingQuery.refetch(), generatedQuery.refetch()]);
  };

  return (
    <div>
      <div className={styles.header}>
        <Typography.Title className={styles.title} level={4}>
          {locale.name}
        </Typography.Title>
        <span
          className={styles.count}
        >{`${translationsCount} / ${totalCount}`}</span>
        {totalPercent < 100 && (
          <TranslationGeneratorModal
            generateMutation={generateMutation}
            useMissingQuery={useMissingQuery}
            onClose={handleRefetch}
            locale={locale}
          />
        )}
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Spin />
        ) : (
          <Tooltip
            title={[
              manualCount > 0 && `${manualCount} manuelles`,
              generatedCount > 0 && `${generatedCount} générées`,
            ]
              .filter(Boolean)
              .join(' / ')}
          >
            <Progress
              percent={totalPercent}
              format={(v) => (v !== undefined ? Math.trunc(v) : 0) + '%'}
              success={{
                percent: manualPercent,
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export type TranslationGeneratorRowProps = {
  totalCount: number;
  locale: {
    code: string;
    name: string;
  };
  useMissingQuery: (options: { variables: { localeId: string } }) => Pick<
    QueryResult<
      {
        entities: { totalCount: number; nodes: { id: string }[] };
      },
      { localeId: string }
    >,
    'loading' | 'data' | 'error' | 'refetch'
  >;
  useGeneratedQuery: (options: { variables: { localeId: string } }) => Pick<
    QueryResult<
      {
        entities: { totalCount: number };
      },
      { localeId: string }
    >,
    'loading' | 'data' | 'error' | 'refetch'
  >;
  generateMutation: TranslationGeneratorModalProps['generateMutation'];
};
