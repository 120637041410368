import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Typography } from '@components/atoms/Typography/Typography';

import styles from './TableRendererDateTime.module.css';

export const TableRendererDateTime: React.FC<TableRendererDateTimeProps> = (
  props,
) => {
  const { className, value } = props;
  const { t } = useTranslation();

  return (
    <div className={classnames(className, styles.root)}>
      {value && (
        <>
          <Typography.Text>
            {t('formats.dateTime', { date: new Date(value) })}
          </Typography.Text>
          <br />
          <Typography.Text type="secondary">
            {t('formats.fromNow', { date: new Date(value) })}
          </Typography.Text>
        </>
      )}
    </div>
  );
};

export type TableRendererDateTimeProps = {
  className?: string;
  value?: string | Date;
};

export function TableRendererDateTimeFactory(
  props: TableRendererDateTimeProps = {},
) {
  return (value: string | Date) => (
    <TableRendererDateTime {...props} value={value} />
  );
}
