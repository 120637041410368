import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  FormatPainterOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  message,
  Popconfirm,
  Space,
  Spin,
  Typography,
} from 'antd';

import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import {
  useUserIdCareViewAuthenticationForceResetPasswordMutation,
  useUserIdCareViewPasswordForgottenMutation,
  useUserIdCareViewQuery,
  useUserIdCareViewSessionRevokeMutation,
} from './UserIdCareView.generated';

type UserIdCareViewProps = {
  userId: string;
};

export const UserIdCareView = (props: UserIdCareViewProps) => {
  const { userId } = props;
  const { data, loading } = useUserIdCareViewQuery({ variables: { userId } });
  const [passwordForgotten, { loading: passwordForgottenLoading }] =
    useUserIdCareViewPasswordForgottenMutation();

  const [forceResetPassword, { loading: forceResetPasswordLoading }] =
    useUserIdCareViewAuthenticationForceResetPasswordMutation();

  const [revokeSession, { loading: revokeSessionLoading }] =
    useUserIdCareViewSessionRevokeMutation();

  const { t } = useTranslation('users');

  const handlePasswordForgottentClick = async () => {
    try {
      const { data } = await passwordForgotten({
        variables: {
          userId,
        },
      });

      const url = data?.authenticationGeneratePasswordResetToken?.url;

      if (!url) throw new Error('No URL retrieved');

      await navigator.clipboard.writeText(url);

      message.success(
        'Code généré avec succès et copier dans votre presse-papier',
      );
      message.warning(
        "ATTENTION: Le lien est sensible et ne doit être transmis qu'à l'utilisateur concerné",
      );
    } catch (err) {
      message.error('Une erreur est survenue lors de la génération du code');
    }
  };

  const handleForceResetPasswordClick = async () => {
    try {
      await forceResetPassword({
        variables: {
          userId,
        },
      });

      await revokeSession({
        variables: {
          userId,
        },
      });

      message.success('Mot de passe réinitialisé avec succès');
    } catch (err) {
      message.error('Une erreur est survenue lors de la réinitialisation');
    }
  };

  const handleRevokeSessionClick = async () => {
    try {
      await revokeSession({
        variables: {
          userId,
        },
      });

      message.success('Sessions révoquées avec succès');
    } catch (err) {
      message.error('Une erreur est survenue lors de la révocation');
    }
  };

  if (loading && !data?.user) return <Spin />;
  else if (!data?.user) return <NotFoundView />;

  const user = data?.user;

  return (
    <Space direction="vertical" size={'middle'}>
      <Card
        size="small"
        title={t('care.bloc.user_password.label', 'Mot de passe utilisateur')}
      >
        {user.isSSOEnabled && (
          <Typography>
            {t(
              'care.actions.change_password.disabled',
              "ATTENTION, l'utilisateur est en SSO",
            )}
          </Typography>
        )}

        <Space direction="vertical" size={'middle'}>
          <Button
            onClick={handlePasswordForgottentClick}
            loading={passwordForgottenLoading}
            icon={<LinkOutlined />}
          >
            {t(
              'care.actions.change_password.label',
              'Générer un lien de changement de mot de passe',
            )}
          </Button>

          <Popconfirm
            title="Êtes-vous sur de vouloir réinitialiser le mot de passe de l'utilisateur ?"
            onConfirm={handleForceResetPasswordClick}
          >
            <Button
              loading={forceResetPasswordLoading}
              icon={<FormatPainterOutlined />}
            >
              {t(
                'care.actions.reset_force_password.label',
                'Réinitialiser de force le mot de passe',
              )}
            </Button>
          </Popconfirm>
        </Space>
      </Card>

      <Card
        size="small"
        title={t('care.bloc.user_session.label', 'Session utilisateur')}
      >
        <Popconfirm
          title="Êtes-vous sur de vouloir révoquer les sessions de l'utilisateur ?"
          onConfirm={handleRevokeSessionClick}
        >
          <Button loading={revokeSessionLoading} icon={<DeleteOutlined />}>
            {t(
              'care.actions.revoke_session.label',
              'Révoquer toutes les sessions',
            )}
          </Button>
        </Popconfirm>
      </Card>
    </Space>
  );
};
