import { DeepPartial } from 'react-hook-form';
import { Table, Typography } from 'antd';

import { OrderRefundCreateFormValue } from '../../OrderRefundCreateForm';

import { FormInputNumber } from '@components/atoms/FormInputNumber/FormInputNumber';

import { OrderRefundCreateOrderItemQuantitySelectorOrderItemFragment } from './OrderRefundCreateOrderItemQuantitySelector.generated';

type OrderRefundCreateOrderItemQuantitySelectorProps = {
  items: DeepPartial<OrderRefundCreateFormValue['items'][number]>[];
  orderItems: OrderRefundCreateOrderItemQuantitySelectorOrderItemFragment[];
};

export const OrderRefundCreateOrderItemQuantitySelector = (
  props: OrderRefundCreateOrderItemQuantitySelectorProps,
) => {
  const { items, orderItems } = props;

  return (
    <div>
      <Typography.Title level={4}>Quantité à rembourser :</Typography.Title>
      <Table
        pagination={false}
        dataSource={items}
        rowKey="orderItemId"
        columns={[
          {
            title: 'Quantité',
            dataIndex: 'quantity',
            width: 100,
            render: (_, node) => {
              const orderItem = orderItems.find(
                (oi) => oi.id === node.orderItemId,
              );
              const itemIndex = items.findIndex(
                (i) => i.orderItemId === orderItem?.id,
              );
              return (
                <FormInputNumber
                  min={0}
                  max={orderItem?.quantity || 0}
                  name={`items.${itemIndex}.quantity`}
                />
              );
            },
          },
          {
            title: 'Produit',
            render: (_, node) => {
              const orderItem = orderItems.find(
                (oi) => oi.id === node.orderItemId,
              );

              return orderItem?.productVariant.product.name;
            },
          },
        ]}
      />
    </div>
  );
};
