import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classnames from 'classnames';

import { TaxRatesTable } from '@components/tables/TaxRatesTable/TaxRatesTable';

import styles from './TaxRatesView.module.css';

export const TaxRatesView: React.FC<TaxRatesViewProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation('taxRates');

  return (
    <div className={classnames(className, styles.root)}>
      <Typography.Title>{t('taxRatesView.title')}</Typography.Title>
      <TaxRatesTable
        activeColumns={['displayName', 'percent', 'country', 'state']}
      />
    </div>
  );
};

export type TaxRatesViewProps = {
  className?: string;
};
