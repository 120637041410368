import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import * as Yup from 'yup';

import { FormCheckbox } from '@components/atoms/FormCheckbox/FormCheckbox';
import { FormInput } from '@components/atoms/FormInput/FormInput';
import { FormInputDate } from '@components/atoms/FormInputDate/FormInputDate';
import { FormSelectCampaign } from '@components/atoms/FormSelectCampaign/FormSelectCampaign';
import { FormSelectCategory } from '@components/atoms/FormSelectCategory/FormSelectCategory';
import { Card } from '@atoms/Card/Card';
import { FormItem } from '@atoms/FormItem/FormItem';
import { ChildFormProps, Form, FormProps, useForm } from '@organisms/Form/Form';

import styles from './OfferUpdateForm.module.css';

import {
  OfferUpdateFormFragment,
  OfferUpdateFormUpdateMutation,
  OfferUpdateFormUpdateMutationVariables,
  useOfferUpdateFormUpdateMutation,
} from './OfferUpdateForm.generated';

export type OfferUpdateFormValues = {
  startsAt?: Date | null;
  endsAt?: Date | null;
  categoryIds: string[];
  availableOnline?: boolean | null;
  availableCountry?: boolean | null;
  campaignId?: string | null;
  url?: string | null;
};

export function OfferUpdateForm(props: OfferUpdateFormProps) {
  const { className, offer, ...rest } = props;

  const { t } = useTranslation('offers');

  const validationSchema = Yup.object({
    startsAt: Yup.date().nullable(),
    endsAt: Yup.date().nullable(),
    categoryIds: Yup.array(Yup.string().required()).min(1).required(),
    availableOnline: Yup.boolean().optional(),
    availableCountry: Yup.boolean().optional(),
    campaignId: Yup.string().required(),
    // Only used for the conditional validation below
    offerType: Yup.string().optional(),
    url: Yup.string().when('offerType', {
      is: 'OfferUrl',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  }).required();

  const categoryIds = offer.categoriesPagination.nodes.map(
    (category) => category.id,
  );

  // We reconstruct the URL as it is easier to understand for the care team
  // If we use `href` directly we get a secured proxy URL pointing towards happypal.fr
  const offerUrl = offer.url
    ? `${offer.url.origin}${offer.url.pathname}${offer.url.search ?? ''}${
        offer.url.hash ?? ''
      }`
    : null;

  const form = useForm<OfferUpdateFormValues & { offerType?: string }>({
    validationSchema,
    defaultValues: {
      offerType: offer.__typename,
      startsAt: offer.startsAt,
      endsAt: offer.endsAt,
      categoryIds,
      availableOnline: offer.availableOnline,
      availableCountry: offer.availableCountry,
      campaignId: offer.campaign?.id,
      url: offerUrl,
    },
  });

  const [mutation] = useOfferUpdateFormUpdateMutation();

  const handleSubmit: FormProps<OfferUpdateFormValues>['onValid'] = async (
    values,
  ) => {
    if (!offer) return;

    const variables: OfferUpdateFormUpdateMutationVariables = {
      offerId: offer.id,
      input: {
        startsAt: values.startsAt ?? null,
        endsAt: values.endsAt ?? null,
        categoryIds: values.categoryIds,
        availableOnline: values.availableOnline,
        availableCountry: values.availableCountry,
        campaignId: values.campaignId,
        url: values.url,
      },
    };

    const { data } = await mutation({ variables });

    return data;
  };

  const { startsAt, endsAt } = form.getValues();

  return (
    <Card className={classnames(className, styles.root)}>
      <Form
        id="OfferUpdateForm"
        className={styles.form}
        onValid={handleSubmit}
        form={form}
        {...rest}
      >
        <FormItem
          className={styles.campaign}
          required
          label={t('fields.campaign')}
          name="campaignId"
        >
          <FormSelectCampaign
            filter={{ brand: { id: { in: [offer.brand?.id] } } }}
            name="campaignId"
          />
        </FormItem>

        <FormItem
          required={offer.__typename === 'OfferUrl'}
          className={styles.url}
          label={t('fields.url')}
          name="url"
        >
          <FormInput name="url" />
        </FormItem>

        <FormItem
          className={styles.categories}
          label={t('fields.categories')}
          name="categoryIds"
          required
        >
          <FormSelectCategory multiple name="categoryIds" />
        </FormItem>

        <FormItem
          className={styles.availableOnline}
          label={t('fields.availableOnline')}
          name="categoryIds"
          required
        >
          <FormCheckbox name="availableOnline" />
        </FormItem>

        <FormItem
          className={styles.availableCountry}
          label={t('fields.availableCountry')}
          name="categoryIds"
          required
        >
          <FormCheckbox name="availableCountry" />
        </FormItem>

        <FormItem
          className={styles.startsAt}
          label={t('fields.startsAt')}
          name="startsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) => (endsAt ? date.isAfter(endsAt) : false)}
            name="startsAt"
          />
        </FormItem>

        <FormItem
          className={styles.endsAt}
          label={t('fields.endsAt')}
          name="endsAt"
        >
          <FormInputDate
            showTime={true}
            disabledDate={(date) =>
              startsAt ? date.isBefore(startsAt) : false
            }
            name="endsAt"
          />
        </FormItem>

        <div className={styles.footer}>
          <Button
            loading={form.formState.isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {t('buttons.submit.update')}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export type OfferUpdateFormProps = ChildFormProps<
  OfferUpdateFormValues,
  OfferUpdateFormUpdateMutation
> & {
  className?: string;
  offer: OfferUpdateFormFragment;
};
