import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { FormSelect } from '../FormSelect/FormSelect';

import { ArticleAccessMode } from '@graphql/generated/types';

import styles from './FormSelectArticleAccessMode.module.css';

export const FormSelectArticleAccessMode: React.FC<FormSelectArticleAccessModeProps> =
  (props) => {
    const { className, name } = props;
    const { t } = useTranslation('communication');

    const options: {
      label: string;
      value: ArticleAccessMode;
    }[] = [
      {
        value: ArticleAccessMode.Whitelist,
        label: t('articles.accessMode.Whitelist'),
      },
      {
        value: ArticleAccessMode.Blacklist,
        label: t('articles.accessMode.Blacklist'),
      },
    ];

    return (
      <FormSelect
        name={name}
        className={classnames(className, styles.root)}
        options={options}
      />
    );
  };

export type FormSelectArticleAccessModeProps = {
  name: string;
  className?: string;
};
