import { Form, InputNumber, InputNumberProps } from 'antd';

import styles from './FilterDropdownNumber.module.css';

// FIXME: Refactor

export function FilterDropdownNumber(props: FilterDropdownNumberProps) {
  const { onChange, values } = props;

  const handleChangeMin: InputNumberProps['onChange'] = (e) => {
    onChange({
      gte: Number(e),
    });
  };

  const handleChangeMax: InputNumberProps['onChange'] = (e) => {
    onChange({
      lte: Number(e),
    });
  };

  return (
    <Form className={styles.root}>
      <div className={styles.labelGroup}>
        <p className={styles.label}>Min</p>
        <InputNumber
          onChange={handleChangeMin}
          value={values?.min}
          min={0}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
        />
      </div>
      <div className={styles.labelGroup}>
        <p className={styles.label}>Max</p>
        <InputNumber onChange={handleChangeMax} value={values?.max} min={0} />
      </div>
    </Form>
  );
}

export type FilterDropdownNumberProps = {
  onChange: ({ gte, lte }: { gte?: number; lte?: number }) => void;
  values: { min?: number | string; max?: number | string };
};
