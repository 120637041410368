import { Spin } from 'antd';
import classnames from 'classnames';

import { Store } from '@graphql/generated/types';

import { StoreUpdateForm } from '@components/forms/StoreUpdateForm/StoreUpdateForm';
import { NotFoundView } from '@components/views/Errors/NotFoundView/NotFoundView';

import styles from './StoreIdUpdateView.module.css';

import { useStoreIdUpdateViewQuery } from './StoreIdUpdateView.generated';

export const StoreIdUpdateView: React.FC<StoreIdUpdateViewProps> = (props) => {
  const { className, storeId } = props;

  const query = useStoreIdUpdateViewQuery({
    variables: { storeId },
    notifyOnNetworkStatusChange: true,
  });

  const store = query?.data?.store;

  if (query.loading && !store) {
    return <Spin size="large" className={styles.spinner} />;
  } else if (!store) {
    return <NotFoundView />;
  }

  return (
    <div className={classnames(className, styles.root)}>
      <StoreUpdateForm store={store} />
    </div>
  );
};

export type StoreIdUpdateViewProps = {
  className?: string;
  storeId: Store['id'];
};
